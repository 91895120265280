import { mergeRight, path, pathOr } from 'ramda'
import { WatchLocationNamespace } from './constants'
import { Action } from './types'

export const SET_COOR = `${WatchLocationNamespace}/SET_COOR`
export const SET_AVAILABLE = `${WatchLocationNamespace}/SET_AVAILABLE`
export const SET_CITY = `${WatchLocationNamespace}/SET_CITY`

const INITIAL_STATE = {
  latitude: NaN,
  longitude: NaN,
  accuracy: 100000,
  available: 'unknown',
}

export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action

  switch (type) {
    case SET_COOR:
    case SET_AVAILABLE:
      return mergeRight(state, payload)
    default:
      return state
  }
}

export const setCoor = ({ latitude, longitude, accuracy }: GeolocationCoordinates) => ({
  type: SET_COOR,
  payload: { latitude, longitude, accuracy }
})

export const setAvailable = (available: string) => ({
  type: SET_AVAILABLE,
  payload: { available }
})

export const selectors = ({
  watchLocation: pathOr<typeof INITIAL_STATE>(INITIAL_STATE, [WatchLocationNamespace]),
  longitude: path([WatchLocationNamespace, 'longitude']),
  latitude: path([WatchLocationNamespace, 'latitude']),
  accuracy: path([WatchLocationNamespace, 'accuracy']),
  available: path([WatchLocationNamespace, 'available'])
})
