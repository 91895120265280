import React from 'react';
import { PiiTextField } from '@components/common';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Props, connector } from './props';
import './RipaSearchDescriptionForm.scss';

const RipaSearchDescriptionForm = ({ searchDescription, labels, currentSubloop, useSameActionTakenForAll, numberOfPeople, setSearchDescription, useSameActionForAllPersonIndex }: Props) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;

  return (
    <div className="ripa-search-description-form" data-testid="ripa-search-description-form">
      <div className={cn('ripa-search-description-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-search-description-form__person-label">{labels?.[currentSubloop] || currentSubloop + 1}</div>
        <div className="ripa-search-description-form__person-same-for-all">Same For All</div>
        <div className="ripa-search-description-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-search-description-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-search-description-form__title">Brief description of the search?</div>
      <div className="ripa-search-description-form__field-container">
        <PiiTextField
          placeholder="Enter brief, 250 character maximum, explanation..."
          onChange={({ target: { value } }) => (value.length <= 250 ? setSearchDescription(value, personIndex) : null)}
          value={searchDescription?.[personIndex]}
          data-testid="ripa-search-description-form-input"
        />
        <div className="ripa-search-description-form__field-remaining">
          <b>{`${250 - searchDescription?.[personIndex]?.length || 0} `}</b>
          Remaining
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      {
        'This explanation should include detail beyond what was selected previously. \
      Do not include any PII of the person stopped or unique identifying information \
      of any officer in this description.'
      }
    </div>
  </div>
);

const Warning = () => {
  const useSameActionTakenForAll = useSelector((state: any) => state.Form.useSameActionTakenForAll);
  const useSameActionForAllPersonIndex = useSelector((state: any) => state.Form.useSameActionForAllPersonIndex);
  const currentSubloop = useSelector((state: any) => state.NewReport.currentSubloop);
  const subloopActionIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;
  const consentGiven = useSelector((state: any) => state.Form.person[subloopActionIndex].consentGiven);
  const searchBasis = useSelector((state: any) => state.Form.person[subloopActionIndex].searchBasis);
  const { pre2024 } = useSelector((state: any) => state.Form.flags);

  const consentToSearch = consentGiven['search person'] || consentGiven['search property'];
  const containsConditionOfParole = searchBasis.includes('Condition of parole / probation/ PRCS / mandatory supervision');
  if (!(consentToSearch || containsConditionOfParole) || !pre2024) {
    return <></>;
  }
  return (
    <div className="ripa-form-container__warning-box ripa-search-description-form__warning">
      <div className="ripa-form-container__warning-box-text">
        {consentToSearch && (
          <span>
            <div className="material-icons ripa-search-description-form__warning-icon">
              priority_high
            </div>
            <b>Note: </b>
            <span>
              Include a description  of the specific conduct of the stopped person (i.e. the specific verbal statements, physical movements, or other behavior)
              that was reasonably interpreted by the officer as consent.
            </span>
          </span>
        )}
        {consentToSearch && containsConditionOfParole && (
          <>
            <br />
            <br />
          </>
        )}
        {containsConditionOfParole && (
          <span>
            <div className="material-icons ripa-search-description-form__warning-icon">
              priority_high
            </div>
            <b>Note: </b>
            <span>
              Include the facts about how the officer learned of the condition of parole/probation/PRCS/mandatory supervision.
              For example, if the officer learned of the condition status after checking with dispatch, the officer would indicate that.
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

RipaSearchDescriptionForm.helper = Helper;
RipaSearchDescriptionForm.warning = Warning;
export default connector(RipaSearchDescriptionForm);
