import React, { useState } from 'react';
import cn from 'classnames';
import { TextField } from '@material-ui/core';
import { ConfirmDialog, createSnackNotification, AlertLevel } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import { Props, composer } from './props';
import './LoginDialog.scss';

export const LoginDialog = ({ login, loginError, errorMessage, online, loginDialogOpen, setLoginDialogOpen }: Props) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  return (
    <ConfirmDialog
      className="login-dialog"
      heading="LOGIN"
      open={loginDialogOpen}
      closeDialog={() => setLoginDialogOpen({ open: false })}
      negativeAction={() => setLoginDialogOpen({ open: false })}
      positiveAction={() => {
        if (online) {
          login({ username, password, usingDialog: true });
        } else {
          createSnackNotification(AlertLevel.Warning, 'Unable to log in', 'Lost network connection');
        }
      }}
      positiveText="Login"
      negativeText="Continue Offline"
    >
      <div className="login-dialog" data-testid="login-dialog">
        <div className={cn('login-dialog__login-error', { 'has-error': loginError })} data-testid="login-login-error">
          {errorMessage}
        </div>
        <form
          autoComplete="on"
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
            if (online) {
              login({ username, password, usingDialog: true });
            } else {
              createSnackNotification(AlertLevel.Warning, 'Unable to log in', 'Lost network connection');
            }
          }}
        >
          <div className="login-dialog__username-label">
            <div className="login-dialog__username-label-text">Username</div>
            <div className="login-dialog__username-label-required">REQUIRED</div>
          </div>
          <TextField
            className="login-dialog__username-field"
            variant="outlined"
            autoComplete="username"
            type="text"
            onChange={({ target: { value } }) => setUsername(value)}
            inputProps={{
              'data-testid': 'login-username-field',
            }}
          />
          <div className="login-dialog__pw-label">
            <div className="login-dialog__pw-label-text">Password</div>
            <div className="login-dialog__pw-label-required">REQUIRED</div>
          </div>
          <TextField
            className="login-dialog__pw-field"
            variant="outlined"
            type="password"
            autoComplete="current-password"
            onChange={({ target: { value } }) => setPassword(value)}
            onKeyUp={onEnter(() => {
              if (online) {
                login({ username, password, usingDialog: true });
              } else {
                createSnackNotification(AlertLevel.Warning, 'Unable to log in', 'Lost network connection');
              }
            })}
            inputProps={{
              'data-testid': 'login-pw-field',
            }}
          />
        </form>
      </div>
    </ConfirmDialog>
  );
};

export default composer(LoginDialog);
