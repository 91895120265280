import {
  SET_USER_FORM_STOP_DESCRIPTION,
  SET_USER_FORM_SEARCH_DESCRIPTION,
  SET_USER_FORM_LOCATION,
  updateForm,
  reducer
} from '@ducks/review'
import { ReviewNamespace } from '@ducks/constants'
import { throttle } from 'lodash'

const sendStopUpdate = throttle((action, dispatch, state) => {
  const { id } = action.payload
  const { stopDescription } = reducer(state, action).userForms?.find(f => f.id === id).contents || {}
  dispatch(updateForm({ updates: { stopDescription }, id }))
}, 2000, { trailing: true, leading: false })

const sendSearchUpdate = throttle((action, dispatch, state) => {
  const { id } = action.payload
  const { person } = reducer(state, action).userForms?.find(f => f.id === id).contents || {}
  dispatch(updateForm({ updates: { person }, id }))
}, 2000, { trailing: true, leading: false })

const sendLocationUpdate = throttle((action, dispatch, state) => {
  const { id } = action.payload
  const { location } = reducer(state, action).userForms?.find(f => f.id === id).contents || {}
  dispatch(updateForm({ updates: { location }, id }))
}, 500, { trailing: true, leading: false })

const reviewUpdateMiddleware = ({ dispatch, getState }) => next => action => {
  if (SET_USER_FORM_SEARCH_DESCRIPTION === action.type) {
    const state = getState()[ReviewNamespace]
    sendSearchUpdate(action, dispatch, state)
  }
  if (SET_USER_FORM_STOP_DESCRIPTION === action.type) {
    const state = getState()[ReviewNamespace]
    sendStopUpdate(action, dispatch, state)
  }
  if (SET_USER_FORM_LOCATION === action.type) {
    const state = getState()[ReviewNamespace]
    sendLocationUpdate(action, dispatch, state)
  }
  next(action)
}

export default reviewUpdateMiddleware
