import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Box } from '@material-ui/core';
import cn from 'classnames';
import { Button, createSnackNotification, AlertLevel } from '@components/common';
import { userSettingsErrorMessage } from '@utility/userSettingsErrorMessage';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import { Props, connector } from './props';
import './RipaFormContainer.scss';

const debouncedSubmitForm = debounce(() => decoupledDispatch('Form.submitFormForReview'), 8000, { trailing: false, leading: true });

const RipaFormContainer = ({ children, next, back, currentSubstep, currentStep, currentSubloop, nSteps, form, enumMap, checkValidation, Helper, HelperDisplayText, Warning, WarningDisplayText, flags: { pre2024 }, officerAssignment, isUserSettingsValid }: Props) => {
  const isSubmitStep = currentSubstep === (nSteps?.[currentStep]?.nSubsteps || 0) - 1 && currentStep === (nSteps?.length || 0) - 1;
  const nextStep = () => next({ ...form, currentSubloop, enumMap });
  const [disableContinue, setDisableContinue] = useState(false);

  useEffect(() => {
    document.getElementById('ripa-form-container__content')?.scrollTo(0, 0);
  }, [currentSubstep]);

  const submitForm = () => {
    if (pre2024 && form?.assignment === 'Other' && form?.assignmentDescription?.length < 3) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Other Assignment must contain at minimum 3 characters.');
    } else if (!pre2024 && form?.assignment === 'Other' && form?.otherAssignment?.length < 3) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Other Assignment must contain at minimum 3 characters.');
    } else if (!isUserSettingsValid) {
      createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to submit a report.`)
    } else {
      if (form) {
      const officerAssignmentVal = officerAssignment?.OfficerTypeofAssignment?.possibleValues;
      const roadblockDUISobrietyCheckpoint = officerAssignmentVal?.RoadblockDUISobrietyCheckpoint.value;
      const { assignment } = form;
      if (assignment === roadblockDUISobrietyCheckpoint) {
        const { stopHappenedAtPublicSchool, school, city, location } = form;
        localStorage.setItem('lastRoadblockOrDuiLocation', JSON.stringify({ stopHappenedAtPublicSchool, school, city, location }))
      }
    }
      debouncedSubmitForm();
    }
  };

  return (
    <div className="ripa-form-container" data-testid="ripa-form-container">
      <div
        id="ripa-form-container__content"
        className={
          cn('ripa-form-container__content', {
            'ripa-form-container-added-space__content': !!Warning && !!Helper
          })}
        data-testid="ripa-form-container-content">
        {React.Children.map(children, (child) =>
          // Disable goToNext behavior for now since it caused GLC-1969
          // React.cloneElement(child, { goToNext, setDisableContinue })
          React.cloneElement(child, { setDisableContinue })
        )}
      </div>
      <Box display="flex" flexDirection="column" className="ripa-form-container__footer">
        {Warning && <Warning WarningTextInfo={WarningDisplayText} />}
        {Helper && <Helper HelperTextInfo={HelperDisplayText} />}
        <div className="ripa-form-container__buttons">
          <Button
            className="ripa-form-container__back"
            priority="secondary"
            aria-label="Previous question button"
            data-testid="ripa-form-container-back"
            onClick={back}
            disabled={currentSubstep === 0 && currentStep === 0 && currentSubloop === 0}
          >
            BACK
          </Button>
          <Button
            disabled={disableContinue}
            className="ripa-form-container__next"
            priority="primary"
            aria-label="Next question button"
            data-testid="ripa-form-container-continue"
            onClick={() => (isSubmitStep ? submitForm() : checkValidation(nextStep))}
          >
            {isSubmitStep ? 'SUBMIT' : 'CONTINUE'}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default connector(RipaFormContainer);
