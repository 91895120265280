import React, { useState } from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import * as Organizations from '@ducks/organizations';
import { LabelField, LabelDropdown, Button, createSnackNotification, AlertLevel, Loading } from '@components/common';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { onEnter } from '@utility/keypressHelpers';
import { oneloginTemplate, azureadTemplate } from '@utility/authSettingsTemplates';
import { isImage } from '@utility/typeGuards';
import checker from './checker.png';
import './EditOrg.scss';

interface Props extends PropsFromRedux {
  setPositiveAction?: (next: Function) => void;
}

const EditOrg = ({ editOrgDialog, setEditOrgField, setPositiveAction, editOrg, loading }: Props) => {
  const {
    ori,
    organizationName,
    subdomain,
    state,
    auth_provider,
    auth_settings,
    smallLogoImage, largeLogoImage, loginBackgroundImage,
    smallLogoImageUrl, largeLogoImageUrl, loginBackgroundImageUrl,
    sftpHostname,
    sftpUsername,
    doj_production,
    // pre2024,
    // earlyPost2024,
    // testingBanner,
    // has2024TestTemplate
  } = editOrgDialog

  const [errors, setErrors] = useState<{
    organizationName?: string;
    subdomain?: string;
    authProvider?: string;
    state?: string;
    authSettings?: string;
    sftpUsername?: string;
    sftpHostname?: string;
    sftpKey?: string;
  }>({});
  // const { enablePre2024Switch } = env().REACT_APP_FEATURE_FLAGS;
  // const { enableForceEarlyPost2024Switch } = env().REACT_APP_FEATURE_FLAGS;

  const [originalOri] = useState<string>(ori);
  const [originalDojProduction] = useState<boolean>(doj_production);
  const hasLoginBackground = Boolean(loginBackgroundImageUrl || loginBackgroundImage) && loginBackgroundImage !== 'delete';
  const hasSmallLogo = Boolean(smallLogoImageUrl || smallLogoImage) && smallLogoImage !== 'delete';
  const hasLargeLogo = Boolean(largeLogoImageUrl || largeLogoImage) && largeLogoImage !== 'delete';
  // const [disablePre2024] = useState(pre2024 ?? false);
  // const [disableEarlyPost2024] = useState(earlyPost2024 ?? false);

  const setFieldAndCheckValidation = (fieldValue: any, submit?: boolean) => {
    const key = Object.keys(fieldValue)[0];
    const getValue = (param: string) => submit ? editOrgDialog[param] : fieldValue[key];
    const fieldHasKey = (k: string) => fieldValue[k] !== undefined;
    setEditOrgField(fieldValue);

    if (fieldHasKey('organizationName') || submit) {
      errors.organizationName = getValue('organizationName').length === 0 ? 'Cannot be blank' : undefined;
    }
    if (fieldHasKey('subdomain') || submit) {
      const condition = getValue('subdomain').length === 0;
      errors.subdomain = condition ? 'Cannot be blank' : undefined;
    }
    if (fieldHasKey('state') || submit) {
      const condition = !getValue('state');
      errors.state = condition ? 'Cannot be blank' : undefined;
    }
    if (fieldHasKey('subdomain') || submit) {
      const valid = getValue('subdomain')?.match(/^[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?$/);
      errors.subdomain = valid ? errors.subdomain : 'Can only contain characters a-z 0-9 or - ';
    }
    if (fieldHasKey('auth_provider') || submit) {
      errors.authProvider = ['', 'OneLogin', 'AzureAD'].includes(getValue('auth_provider')) ? undefined : 'Cannot be blank';
    }
    if (fieldHasKey('auth_settings') || submit) {
      try {
        JSON.parse(getValue('auth_settings'));
        errors.authSettings = undefined;
      } catch {
        errors.authSettings = 'Must be valid JSON';
      }
    }

    setErrors(errors)
    return errors
  }
  setPositiveAction?.((next: Function) => {
    if (!loading.addOrganizations) {
      const e = setFieldAndCheckValidation(editOrgDialog, true);
      const hasErrors = Object.values(e).filter((v) => v !== undefined).length > 0;
      if (!hasErrors) {
        editOrg();
      }
      next();
    }
  })

  // const onChangePre2024 = (value: boolean) => {
  //   setEditOrgField({ pre2024: value })
  // }
  // const onChangeEarlyPost2024 = (value: boolean) => {
  //   setEditOrgField({ earlyPost2024: value })
  // }
  // const onChangeTestingBanner = (value: boolean) => {
  //   setEditOrgField({ testingBanner: value })
  // }
  return (
    <div className="edit-org" data-testid="edit-org">
      <div className={cn('edit-org__upload-spinner', { uploading: loading.addOrganizations })}>
        <div className="edit-org__upload-spinner-loading">
          <div> Modifying... </div>
          <Loading />
        </div>
      </div>
      <LabelField
        label="Organization Name"
        className="edit-org__name"
        placeholder="Springfield PD"
        error={Boolean(errors.organizationName)}
        errorMessage={errors.organizationName}
        defaultValue={organizationName}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ organizationName: value })}
        inputProps={{
          'data-testid': 'edit-org-name',
        }}
      />
      <LabelField
        label="Subdomain"
        className="edit-org__subdomain"
        placeholder="sfpd"
        error={Boolean(errors.subdomain)}
        errorMessage={errors.subdomain}
        defaultValue={subdomain}
        disabled
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ subdomain: value })}
        inputProps={{
          'data-testid': 'edit-org-subdomain',
        }}
      />
      <LabelField
        label="ORI"
        className="edit-org__ori"
        defaultValue={ori}
        disabled={!!originalOri}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ ori: value })}
        inputProps={{
          'data-testid': 'edit-org-ori',
        }}
      />
      <FormControlLabel
        control={<Checkbox
          disabled={originalDojProduction}
          checked={doj_production}
          onChange={({ target: { checked } }) => setFieldAndCheckValidation({ doj_production: checked })}
        />}
        label="Production Mode"
      />
      <LabelDropdown
        className="edit-org__dropdown-state"
        data-testid="edit-org-dropdown-state"
        label="State"
        error={Boolean(errors.state)}
        errorMessage={errors.state}
        disabled={true}
        values={Organizations.AllowedStates.map((y) => ({ value: y, text: y }))}
        onChange={({ target: { value } }) => {
          setFieldAndCheckValidation({ state: value });
        }}
        renderValue={(selected) => selected}
        value={state}
      />
      <LabelDropdown
        className="edit-org__dropdown-auth-providers"
        data-testid="edit-org-dropdown-auth-providers"
        label="Auth Providers"
        error={Boolean(errors.authProvider)}
        errorMessage={errors.authProvider}
        values={[{ value: 'Basic', text: 'Basic' }, { value: 'OneLogin', text: 'OneLogin' }, { value: 'AzureAD', text: 'AzureAD' }]}
        onChange={({ target: { value } }) => {
          setFieldAndCheckValidation({ auth_provider: value === 'Basic' ? '' : value });
          setFieldAndCheckValidation({ auth_settings: '{}' });
        }}
        renderValue={(selected: any) => selected}
        value={auth_provider === '' ? 'Basic' : auth_provider}
      />
      <div className="edit-org__2024-flags">
        {/* <div className="edit-org__2024-flags-heading">
          Enable early 2024 changes (WARNING: Cannot be turned off)
        </div>
        <Switch
          className="edit-org__2024-flags-switch"
          offText="OFF"
          onText="ON"
          value={pre2024}
          disabled={!window.allow2024Switch && disablePre2024 || !enablePre2024Switch}
          onChange={onChangePre2024}
        /> */}
        {/* <div className="edit-org__2024-flags-heading">
          Enable early post 2024 changes (WARNING: Cannot be turned off)
        </div>
        <Switch
          className="edit-org__2024-flags-switch"
          offText="OFF"
          onText="ON"
          value={earlyPost2024}
          disabled={!window.forceEarlyPost2024Switch && disableEarlyPost2024 || !enableForceEarlyPost2024Switch}
          onChange={onChangeEarlyPost2024}
        /> */}
        {/* <div className="edit-org__2024-flags-heading">
          Enable 2024 Testing Banner
        </div>
        <Switch
          className="edit-org__2024-flags-switch"
          offText="OFF"
          onText="ON"
          value={testingBanner}
          onChange={onChangeTestingBanner}
          disabled={!has2024TestTemplate}
        /> */}
      </div>

      {['OneLogin', 'AzureAD'].includes(auth_provider) && (
        <LabelField
          label="AUTH SETTINGS INPUT"
          multiline
          minRows={4}
          className="edit-org__auth-settings-input"
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ auth_settings: value })}
          error={Boolean(errors.authSettings)}
          errorMessage={errors.authSettings}
          value={auth_settings !== '{}' ? auth_settings : auth_provider === 'OneLogin' ? oneloginTemplate : azureadTemplate}
        />
      )}
      <div className="edit-org__image-upload">
        <div className="edit-org__image-upload-heading">Login Background</div>
        <div className={cn('edit-org__image-preview-container', { 'has-image': hasLoginBackground })} style={{ backgroundImage: hasLoginBackground ? `url(${checker})` : undefined }}>
          <img id="login-background-preview-edit" className="edit-org__image-preview" src={loginBackgroundImageUrl} alt="Preview of image upload" />
          <div className="edit-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setEditOrgField({ loginBackgroundImageUrl: undefined });
                setEditOrgField({ loginBackgroundImage: 'delete' });
              }}
              onKeyUp={onEnter(() => {
                setEditOrgField({ loginBackgroundImageUrl: undefined });
                setEditOrgField({ loginBackgroundImage: 'delete' });
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="edit-org__browse-container">
          <Button className="edit-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e.target.files?.[0];
                  setEditOrgField({ loginBackgroundImage: file });
                  setEditOrgField({ loginBackgroundImageUrl: undefined });
                  const preview = document.getElementById('login-background-preview-edit');
                  if (preview && file && isImage(preview)) {
                    preview.src = URL.createObjectURL(file);
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src);
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br>  <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="edit-org__browse-types-container">
            <div className="edit-org__browse-types-label">File Types:</div>
            <div className="edit-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
      <div className="edit-org__image-upload">
        <div className="edit-org__image-upload-heading">Small Logo</div>
        <div className={cn('edit-org__image-preview-container', { 'has-image': hasSmallLogo })} style={{ backgroundImage: hasSmallLogo ? `url(${checker})` : undefined }}>
          <img id="small-logo-preview-edit" className="edit-org__image-preview" src={smallLogoImageUrl} alt="Preview of image upload" />
          <div className="edit-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setEditOrgField({ smallLogoImageUrl: undefined })
                setEditOrgField({ smallLogoImage: 'delete' })
              }}
              onKeyUp={onEnter(() => {
                setEditOrgField({ smallLogoImageUrl: undefined })
                setEditOrgField({ smallLogoImage: 'delete' })
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="edit-org__browse-container">
          <Button className="edit-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e.target.files?.[0];
                  setEditOrgField({ smallLogoImage: file });
                  setEditOrgField({ smallLogoImageUrl: undefined });
                  const preview = document.getElementById('small-logo-preview-edit');
                  if (isImage(preview) && file) {
                    preview.src = URL.createObjectURL(file);
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src);
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br>  <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="edit-org__browse-types-container">
            <div className="edit-org__browse-types-label">File Types:</div>
            <div className="edit-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
      <div className="edit-org__image-upload">
        <div className="edit-org__image-upload-heading">Large Logo</div>
        <div className={cn('edit-org__image-preview-container', { 'has-image': hasLargeLogo })} style={{ backgroundImage: hasLargeLogo ? `url(${checker})` : undefined }}>
          <img id="large-logo-preview-edit" className="edit-org__image-preview" src={largeLogoImageUrl} alt="Preview of image upload" />
          <div className="edit-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setEditOrgField({ largeLogoImageUrl: undefined })
                setEditOrgField({ largeLogoImage: 'delete' })
              }}
              onKeyUp={onEnter(() => {
                setEditOrgField({ largeLogoImageUrl: undefined })
                setEditOrgField({ largeLogoImage: 'delete' })
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="edit-org__browse-container">
          <Button className="edit-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e?.target?.files?.[0];
                  setEditOrgField({ largeLogoImage: file });
                  setEditOrgField({ largeLogoImageUrl: undefined });
                  const preview = document.getElementById('large-logo-preview-edit');
                  if (isImage(preview) && file) {
                    preview.src = URL.createObjectURL(file)
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src)
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br>  <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="edit-org__browse-types-container">
            <div className="edit-org__browse-types-label">File Types:</div>
            <div className="edit-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
      <LabelField
        label="SFTP Hostname"
        multiline
        placeholder="DOJ submission hostname..."
        className="edit-org__sftp-settings-input"
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ sftpHostname: value })}
        error={Boolean(errors.sftpHostname)}
        errorMessage={errors.sftpHostname}
        defaultValue={sftpHostname}
      />
      <LabelField
        label="SFTP Username"
        placeholder="DOJ submission username..."
        className="edit-org__sftp-settings-input"
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ sftpUsername: value })}
        error={Boolean(errors.sftpUsername)}
        errorMessage={errors.sftpUsername}
        defaultValue={sftpUsername}
      />
      <LabelField
        label="SFTP Key"
        multiline
        placeholder="Private: write only"
        minRows={4}
        className="edit-org__sftp-settings-input"
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ sftpKey: value })}
        error={Boolean(errors.sftpKey)}
        errorMessage={errors.sftpKey}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  editOrgDialog: Organizations.selectors.editOrgDialog(state),
  loading: Organizations.selectors.loading(state),
  orgTemplates: Organizations.selectors.orgTemplates(state)
})

const mapDispatchToProps = {
  setEditOrgField: Organizations.setEditOrgField,
  editOrg: Organizations.editOrg
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditOrg)
