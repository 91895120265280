import React, { useState } from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import * as Users from '@ducks/users';
import * as User from '@ducks/user';
import { Role } from '@ducks/constants'
import { LabelField, Checkbox, LabelDropdown } from '@components/common'
import { capitalize } from '@utility/stringTransform'
import './AddUser.scss'

interface Props extends PropsFromRedux {
  setPositiveAction?: (next: Function) => void;
}

const AddUser = ({ assignedRoles, addUserDialog, setAddUserField, setPositiveAction, reviewerDropdowns, addUser }: Props) => {
  const [errors, setErrors] = useState<any>({})
  const [newPassword, setNewPassword] = useState('');
  const [selectedReviewers, setSelectedReviewers] = useState<string[]>([]);
  type FieldValue = Partial<typeof addUserDialog & { confirmPassword: string }>;
  const setFieldAndCheckValidation = (fieldValue: FieldValue, submit?: boolean
  ) => {
    const key = Object.keys(fieldValue)[0] as keyof FieldValue;
    const getValue: {
      (param: KeysMatching<Required<FieldValue>, string>): string;
      (param: KeysMatching<Required<FieldValue>, boolean>): boolean;
      (param: KeysMatching<Required<FieldValue>, any[]>): any[];
    } = (param: keyof FieldValue) => submit ? (addUserDialog as any)?.[param] : fieldValue[key]
    const fieldHasKey = (k: keyof FieldValue) => fieldValue[k] !== undefined
    setAddUserField(fieldValue);

    if (fieldHasKey('firstname') || submit) {
      errors.firstname = getValue('firstname').length === 0 ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('lastname') || submit) {
      const condition = getValue('lastname').length === 0
      errors.lastname = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('email')) {
      errors.email = undefined;
    }
    if (fieldHasKey('email') && submit) {
      const matchAry = getValue('email')?.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ?? []
      errors.email = matchAry?.length === 0 ? 'Email is invalid' : undefined
    }
    if (fieldHasKey('email') || submit) {
      const condition = getValue('email').length === 0
      errors.email = condition ? 'Cannot be blank' : errors.email
    }
    if (fieldHasKey('username') || submit) {
      const condition = getValue('username').length === 0
      errors.username = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('officerId') || submit) {
      const condition = getValue('officerId').length !== 0 && !getValue('officerId').match(/^[0-9a-zA-Z]{9}$/);
      errors.officerId = condition ? 'Must be 9 alphanumeric characters or blank' : undefined;
    }
    // if (fieldHasKey('assignedReviewers') || submit) {
    //   const condition = getValue('assignedReviewers').length === 0
    //   errors.assignedReviewers = condition ? 'Reviewer cannot be blank' : undefined
    // }
    if (fieldHasKey('yearsOfExperience') || submit) {
      const condition = getValue('yearsOfExperience') === ''
      errors.yearsOfExperience = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('yearsOfExperience') || submit) {
      const num = getValue('yearsOfExperience');
      const condition = (+num) < 1 || (+num) > 50;
      errors.yearsOfExperience = condition ? 'Invalid Years of Experience' : undefined;
    }
    if (fieldHasKey('password') || submit) {
      const condition = getValue('password').length >= 8
      errors.password = { ...errors.password, hasEight: condition }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[0-9]/g) ?? []
      errors.password = { ...errors.password, hasNumber: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[A-Z]/g) ?? []
      errors.password = { ...errors.password, hasUppercase: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g) ?? []
      errors.password = { ...errors.password, hasSpecial: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      setNewPassword(getValue('password'));
    }
    if (fieldHasKey('confirmPassword' || submit)) {
      errors.password = { ...errors.password, matching: getValue('confirmPassword') === newPassword };
    }
    if (fieldHasKey('password') && submit) {
      const { hasSpecial, hasUppercase, hasNumber, hasEight, matching } = errors.password
      errors.password = { ...errors.password, any: !hasSpecial || !hasUppercase || !hasNumber || !hasEight || !matching }
    }
    setErrors(errors)
    return errors
  }
  setPositiveAction?.(async (next: Function) => {
    if (!addUserDialog) { return }

    const e = setFieldAndCheckValidation(addUserDialog, true)
    const hasErrors = Object.values(e).filter((v: any) => v?.any !== undefined ? v.any : v !== undefined).length > 0
    if (!hasErrors) {
      const response = await addUser()

      if (response !== undefined) {
        const updatedErrors = { ...errors }
        Object.entries(response).forEach(([key, value]) => updatedErrors[key] = `${capitalize(key)} ${value}`)
        setErrors(updatedErrors)
      }
    }
    next();
  });

  const updatedReviewerDropdowns = [
    { value: 'all', text: 'All Reviewers' },
    ...(reviewerDropdowns?.map(({ id: reviewerId, name }) => ({
      value: reviewerId,
      text: name,
    })) || []),
  ];
  const renderSelectedReviewers = (selected: any) => {
    const filteredSelected = selected.filter((s: any) => s !== 'all');
    return filteredSelected.map((s: any) => updatedReviewerDropdowns?.find((r) => r.value === s)?.text).join('; ');
  };
  const handleSelectChange = ({ target: { value } }: any) => {
    const reviewerDropdownValues = updatedReviewerDropdowns.map((item) => item.value);
    const hasAllInSelectedReviewers = selectedReviewers?.includes('all');
    const hasAllInValue = value.includes('all');
    if (hasAllInSelectedReviewers) {
      if (!hasAllInValue) {
        setSelectedReviewers([]);
        setFieldAndCheckValidation({ assignedReviewers: [] });
      } else if (selectedReviewers !== value) {
        const newValue = value.filter((s: any) => s !== 'all');
        setSelectedReviewers(newValue);
        setFieldAndCheckValidation({ assignedReviewers: newValue });
      }
    } else if (hasAllInValue || value.length === reviewerDropdowns.length) {
      setSelectedReviewers(reviewerDropdownValues);
      const newValue = reviewerDropdownValues.filter((s: any) => s !== 'all');
      setFieldAndCheckValidation({ assignedReviewers: newValue });
    } else {
      setSelectedReviewers(value);
      const newValue = value.filter((s: any) => s !== 'all');
      setFieldAndCheckValidation({ assignedReviewers: newValue });
    }
  };

  return (
    <div className="add-user" data-testid="add-user">
      <div className="add-user__name-container">
        <LabelField
          label="First Name"
          className="add-user__name-first-name"
          placeholder="Add Name..."
          error={Boolean(errors.firstname)}
          errorMessage={errors.firstname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ firstname: value })}
          inputProps={{
            'data-testid': 'add-user-name-first-name'
          }}
        />
        <LabelField
          label="Last Name"
          className="add-user__name-last-name"
          placeholder="Add Name..."
          error={Boolean(errors.lastname)}
          errorMessage={errors.lastname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ lastname: value })}
          inputProps={{
            'data-testid': 'add-user-name-last-name'
          }}
        />
      </div>
      <LabelField
        label="Email Address"
        className="add-user__email"
        placeholder="Enter..."
        type="email"
        error={Boolean(errors.email)}
        errorMessage={errors.email}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ email: value })}
        inputProps={{
          'data-testid': 'add-user-email'
        }}
      />
      <div className="add-user__username-and-officer-id-container">
        <LabelField
          label="Username"
          placeholder="Enter..."
          className="add-user__username"
          error={Boolean(errors.username)}
          errorMessage={errors.username}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ username: value })}
          inputProps={{
            'data-testid': 'add-user-username'
          }}
        />
        <LabelField
          label="DOJ Officer ID"
          placeholder="Leave blank to auto-set"
          className="add-user__officer-id"
          error={Boolean(errors.officerId)}
          errorMessage={errors.officerId}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ officerId: value })}
          inputProps={{
            pattern: '[0-9a-zA-Z]{9}',
            'data-testid': 'add-user-officer-id'
          }}
        />
      </div>
      <div className="add-user__hr" />
      <LabelField
        label="Years of Experience"
        placeholder="Enter Number..."
        type="number"
        className="add-user__years"
        error={Boolean(errors.yearsOfExperience)}
        errorMessage={errors.yearsOfExperience}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ yearsOfExperience: value })}
        inputProps={{
          min: 1,
          max: 50,
          'data-testid': 'add-user-years'
        }}
      />
      <div className="add-user__hr" />
      <div className="add-user__roles-heading">
        <div className="add-user__roles-text">
          USER ROLES
        </div>
      </div>
      <Checkbox
        defaultValue={true}
        className="add-user__checkbox-officer"
        label="Officer"
        onChange={(isOfficerRole) => setAddUserField({ isOfficerRole })}
      />
      <Checkbox
        className="add-user__checkbox-reviewer"
        label="Reviewer"
        onChange={(isReviewerRole) => setAddUserField({ isReviewerRole })}
      />
      <Checkbox
        className="add-user__checkbox-analyst"
        label="Analyst"
        onChange={(isAnalystRole) => setAddUserField({ isAnalystRole })}
      />
      {assignedRoles?.filter(r => r === Role.SuperAdmin || r === Role.Admin) &&
      <Checkbox
        className="add-user__checkbox-admin"
        label="Admin"
        onChange={(isAdminRole) => setAddUserField({ isAdminRole })}
      />
      }
      {!assignedRoles?.includes(Role.SuperAdmin) && <LabelDropdown
        className="add-user__dropdown-reviewer"
        data-testid="add-user-dropdown-reviewer"
        label="Assigned Reviewers"
        multiple
        error={Boolean(errors.assignedReviewers)}
        errorMessage={errors.assignedReviewers}
        values={updatedReviewerDropdowns}
        onChange={handleSelectChange}
        renderValue={renderSelectedReviewers}
        value={selectedReviewers}
      />}
      <div className="add-user__hr" />
      <LabelField
        label="Password"
        className="add-user__password"
        placeholder="Enter..."
        error={errors?.password?.any}
        onChange={({ target: { value } }: { target: { value: string }}) => setFieldAndCheckValidation({ password: value })}
        type="password"
        inputProps={{
          'data-testid': 'add-user-password',
          autocomplete: 'new-password'
        }}
      />
      <LabelField
        label="Confirm Password"
        className="add-user__confirm-password"
        placeholder="Enter..."
        error={Boolean(newPassword && !errors?.password?.matching)}
        onChange={({ target: { value } }: { target: { value: string }}) => setFieldAndCheckValidation({ confirmPassword: value })}
        type="password"
        inputProps={{
          'data-testid': 'add-user-confirm-password',
          autocomplete: 'new-password'
        }}
      />
      {newPassword && <div className={cn('reset-password-confirm-error', { 'no-error': errors?.password?.matching })}>
        <div className="material-icons">
          error
        </div>
        <div className="reset-password-confirm-error-text">
          Both passwords must match
        </div>
      </div>}
      <div className="add-user__password-validation-text">
        Password must contain the following:
      </div>
      <div className="add-user__password-validation-checks">
        <div className="add-user__password-validation-check">
          <div
            className={cn('add-user__box', { checked: errors?.password?.hasEight })}
            data-testid="add-user-has-eight"
          >
            <div className="material-icons">
              {errors?.password?.hasEight ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-user__password-validation-check-label">
            8 CHARACTERS
          </div>
        </div>
        <div className="add-user__password-validation-check">
          <div
            className={cn('add-user__box', { checked: errors?.password?.hasNumber })}
            data-testid="add-user-has-number"
          >
            <div className="material-icons">
              {errors?.password?.hasNumber ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-user__password-validation-check-label">
            1 NUMBER
          </div>
        </div>
        <div className="add-user__password-validation-check">
          <div
            className={cn('add-user__box', { checked: errors?.password?.hasUppercase })}
            data-testid="add-user-has-uppercase"
          >
            <div className="material-icons">
              {errors?.password?.hasUppercase ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-user__password-validation-check-label">
            1 UPPERCASE
          </div>
        </div>
        <div className="add-user__password-validation-check">
          <div
            className={cn('add-user__box', { checked: errors?.password?.hasSpecial })}
            data-testid="add-user-has-special"
          >
            <div className="material-icons">
              {errors?.password?.hasSpecial ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-user__password-validation-check-label">
            1 SPECIAL CHARACTER
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  addUserDialog: Users.selectors.addUserDialog(state),
  reviewerDropdowns: Users.selectors.reviewerDropdowns(state),
  assignedRoles: User.selectors.roles(state)
});

const mapDispatchToProps = {
  setAddUserField: Users.setAddUserField,
  addUser: Users.addUser
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddUser)
