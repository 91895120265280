import React, { useEffect, useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { ReviewExpandSvg } from '@components/svg';
import { throttle } from 'lodash';
import { Status, StatusString } from '@ducks/constants';
import { Tooltip } from '@material-ui/core';
import { AlertLevel, PiiTextField, createSnackNotification } from '@components/common';
import { range } from 'ramda';
import { Props, connector } from './props';
import './ReviewRow.scss';

export const ReviewRow = ({
  colData,
  rowData,
  checkReviewRow,
  checkedReviewRows,
  expandedRow,
  setExpandedRow,
  setStopDescription,
  setSearchDescription,
  userForms,
  addReviewTimeSpent,
  openReviewDialog,
  approveReport,
  rowIndex,
  setDojErrorsDialogOpen,
  setReviewFeedbackDialogOpen,
  orgCanSubmitToDOJ,
  userId,
  setHasEdit,
  versionFormForReview,
}: Props) => {
  const { id, status, deleted_at } = rowData;
  const userForm = userForms.find((f: any) => f.id === id);
  const { numberOfPeople, person, useSameActionTakenForAll, useSameActionForAllPersonIndex, useSamePrimaryReasonForAll } = userForm.contents;
  const searchDescription = person?.map((p: any) => p.searchDescription);
  const [nPeopleHasSearch, setNPeopleHasSearch] = useState(0);

  const nPeople = useSameActionTakenForAll ? 1 : numberOfPeople ?? 1;
  const nStopDescriptions = useSamePrimaryReasonForAll ? 1 : nPeople;

  const initialStopDescriptionCountAdjustment = Array(nStopDescriptions).fill(0);
  const [stopDescriptionCountAdjustment, setStopDescriptionCountAdjustment] = useState(initialStopDescriptionCountAdjustment);
  const initialSearchDescriptionCountAdjustment = Array(nPeople).fill(0);
  const [searchDescriptionCountAdjustment, setSearchDescriptionCountAdjustment] = useState(initialSearchDescriptionCountAdjustment);

  const onCheck = (e: any) => {
    const { selectAllChecked } = e?.detail ?? false;
    if (status === Status.Approved || !selectAllChecked) {
      checkReviewRow({ id, checked: selectAllChecked ?? !checkedReviewRows.includes(id) });
    }
  };

  const onFieldBlur = useCallback(() => {
    versionFormForReview({ id });
  }, [versionFormForReview, id]);

  useEffect(() => {
    window.addEventListener('select-all-review-rows', onCheck);

    return () => {
      window.removeEventListener('select-all-review-rows', onCheck);
    };
  }, []);

  useEffect(() => {
    const n = person?.filter((p: any) => p.actionTaken?.filter((v: any) => v.match(/search.+conducted/gi)).length > 0).length;
    setNPeopleHasSearch(useSameActionTakenForAll && n > 0 ? 1 : n);
  }, []);

  const maxInactivity = 5000;
  const userActivityEvents = ['keydown', 'mousedown', 'mousemove', 'scroll', 'touchstart'];
  const rowContent = useRef<HTMLDivElement>(null);
  const millsSinceLastActivity = useRef<number>(0);
  const lastActivityTime = useRef<number>(Date.now());

  const userActivityCbs = userActivityEvents.reduce(
    (acc, event) => ({
      ...acc,
      [event]: useCallback(
        throttle(
          () => {
            millsSinceLastActivity.current = Date.now() - lastActivityTime.current;
            if (millsSinceLastActivity.current < maxInactivity) {
              addReviewTimeSpent({ id, addTimeSec: millsSinceLastActivity.current / 1000, reviewerId: userId });
            }
            lastActivityTime.current = Date.now();
          },
          2000,
          { trailing: false, leading: true }
        ),
        [event]
      ),
    }),
    []
  ) as any;

  useEffect(() => {
    if (expandedRow === id) {
      userActivityEvents.forEach((event) => {
        rowContent.current?.addEventListener(event, userActivityCbs[event]);
      });
    } else {
      userActivityEvents.forEach((event) => {
        rowContent.current?.removeEventListener(event, userActivityCbs[event]);
      });
    }
  }, [expandedRow, id]);

  useEffect(() => {
    // If the row is expanded, and the status changes, collapse the row
    if (expandedRow !== -1) {
      setExpandedRow(-1);
    }
  }, [status]);

  /* eslint-disable default-case, consistent-return */
  const renderCell = ({ dataKey }: { dataKey: string }) => {
    switch (dataKey) {
      case 'select':
        return (
          <div className="review-row__cell-select">
            <div
              className={cn('review-row__cell-select-checkbox', {
                checked: checkedReviewRows.includes(id),
                disabled: status !== Status.Approved || !orgCanSubmitToDOJ,
              })}
              data-testid="review-row-cell-select-checkbox"
              role="button"
              tabIndex={0}
              aria-label="Check this row"
              onClick={onCheck}
              onKeyUp={onEnter(onCheck)}
            >
              <div className="material-icons">done</div>
            </div>
            <ReviewExpandSvg
              className={cn('review-row__cell-select-expand', { expanded: expandedRow === id })}
              data-testid="review-row-cell-select-expand"
              role="button"
              tabIndex={0}
              aria-label="Expand this row"
              onClick={() => setExpandedRow(expandedRow !== id ? id : -1)}
              onKeyUp={onEnter(() => setExpandedRow(expandedRow !== id ? id : -1))}
            />
          </div>
        );
      case 'officer':
        return (
          <div role="cell" className={cn('review-row__cell-officer', { pending: !rowData[dataKey] })} data-testid="review-row-cell-officer">
            <strong>{rowData[dataKey]}</strong>
          </div>
        );
      case 'location':
        return (
          <div role="cell" className={cn('review-row__cell-location', { pending: !rowData[dataKey] })}>
            <strong>{rowData?.[dataKey] || 'No location provided'}</strong>
            {rowData?.school ? (<div className="review-school">{rowData?.school}</div>) : null}
          </div>
        );
      case 'assignment':
        return (
          <div role="cell" className="review-row__cell-assignment">
            {rowData?.[dataKey]}
          </div>
        );
      case 'district':
        return (
          <div role="cell" className={cn('review-row__cell-district', { pending: !rowData[dataKey] })}>
            {rowData?.[dataKey] || 'Pending'}
          </div>
        );
      case 'beat':
        return (
          <div role="cell" className={cn('review-row__cell-beat', { pending: !rowData[dataKey] })}>
            {rowData?.[dataKey] || 'Pending'}
          </div>
        );
      case 'time':
        return (
          <div role="cell" className="review-row__cell-time">
            {rowData[dataKey]?.format?.('MM/DD/YYYY h:mm A')}
          </div>
        );
      case 'status':
        return (
          <div
            role="cell"
            className={cn('review-row__cell-status', {
              removed: deleted_at,
              draft: rowData?.[dataKey] === Status.Draft,
              under_review: rowData?.[dataKey] === Status.UnderReview,
              approved: rowData?.[dataKey] === Status.Approved,
              rejected: rowData?.[dataKey] === Status.Rejected,
              denied: rowData?.[dataKey] === Status.DeniedByDOJ,
              submitted: [Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(rowData?.[dataKey]),
            })}
            data-testid="review-row-cell-status"
          >
            {deleted_at ? 'Removed' : StatusString?.[rowData?.[dataKey]]}
          </div>
        );
      case 'actions':
        return (
          <div role="cell" className="review-row__cell-actions">
            {deleted_at ? (
              ''
            ) : [Status.UnderReview, Status.Rejected, Status.Approved, Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(status) ? (
              <>
                {(userForm.reviewer_notes?.noteForReviewee !== undefined || userForm.reviewer_notes?.noteHistory) && Status.Rejected && (
                  <Tooltip title="View Changes Requested">
                    <div
                      className="review-row__table-cell-actions-comments-dialog material-icons"
                      role="button"
                      tabIndex={0}
                      data-testid="dashboard-table-cell-comments-dialog"
                      aria-label="Show rejection notes"
                      onClick={() => setReviewFeedbackDialogOpen({ open: true, formId: rowData.id, isReadOnly: true })}
                      onKeyUp={onEnter(() => setReviewFeedbackDialogOpen({ open: true, formId: rowData.id, isReadOnly: true }))}
                    >
                      priority_high
                    </div>
                  </Tooltip>
                )}
                <Tooltip title="Deny report">
                  <div
                    className={cn('review-row__table-cell-actions-cancel material-icons', { disabled: [Status.Rejected, Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(status) })}
                    role="button"
                    tabIndex={0}
                    data-testid="review-row-table-cell-deny"
                    aria-label="Deny this report"
                    onClick={() => setReviewFeedbackDialogOpen({ open: true, formId: id, isReadOnly: undefined })}
                    onKeyUp={onEnter(() => setReviewFeedbackDialogOpen({ open: true, formId: id, isReadOnly: undefined }))}
                  >
                    cancel
                  </div>
                </Tooltip>
                <Tooltip title="Approve report">
                  <div
                    className={cn('review-row__table-cell-actions-approve material-icons', { disabled: [Status.Approved, Status.SubmittedToDOJ, Status.SubmittingToDOJ].includes(status) })}
                    role="button"
                    tabIndex={0}
                    data-testid="review-row-table-cell-approve"
                    aria-label="Approve this report"
                    onClick={() => approveReport({ id })}
                    onKeyUp={onEnter(() => approveReport({ id }))}
                  >
                    done
                  </div>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="View DOJ errors">
                <div
                  className="review-row__table-cell-actions-doj-rejection-dialog material-icons"
                  role="button"
                  tabIndex={0}
                  data-testid="review-row-table-cell-doj-rejection-dialog"
                  aria-label="Open dialog showing errors from DOJ"
                  onClick={() => setDojErrorsDialogOpen({ open: true, formId: id })}
                  onKeyUp={onEnter(() => setDojErrorsDialogOpen({ open: true, formId: id }))}
                >
                  priority_high
                </div>
              </Tooltip>
            )}
            <div
              className="review-row__table-cell-actions-review"
              data-testid="review-row-table-cell-actions-review"
              role="button"
              tabIndex={0}
              aria-label="Continue to work on this report"
              onClick={() => openReviewDialog(id)}
              onKeyUp={onEnter(() => openReviewDialog(id))}
            >
              REVIEW
            </div>
          </div>
        );
    }
  };

  return (
    <div
      role="row"
      className={cn('review-row', {
        checked: checkedReviewRows.includes(id),
        warning: status === Status.DeniedByDOJ || status === Status.Rejected,
        testcase2024: !!rowData?.testcase2024,
      })}
      data-testid={`review-row-${rowIndex}-${rowData.id}`}
      ref={rowContent}
    >
      <div className={cn('review-row__row', { checked: checkedReviewRows.includes(id) })} data-testid="review-row-row">
        {colData.map(({ grow, dataKey, width, minWidth }, index) => (
          <div
            className={`review-row__cell review-row__cell_${dataKey}`}
            data-testid={`review-row-cell-${rowIndex}-${id}-${index}`}
            key={`ReviewRowCell-${id}-${rowIndex}-${index}-${dataKey}`}
            style={{
              flexGrow: grow,
              maxWidth: width,
              minWidth,
            }}
          >
            {renderCell({ dataKey })}
          </div>
        ))}
      </div>
      {expandedRow === id && (
        <div
          className={cn('review-row__row-details', { expanded: expandedRow === id })}
          style={{ height: expandedRow === id ? nStopDescriptions * 123 + nPeopleHasSearch * 123 + (nPeopleHasSearch === 0 ? 0 : 25) + 25 : 0 }}
        >
          <div className="review-row__row-details-line" style={{ height: expandedRow === id ? nStopDescriptions * 123 + nPeopleHasSearch * 123 + (nPeopleHasSearch === 0 ? 0 : 25) + 25 : 0 }} />
          <div className="review-row__row-details-stop-label">DESCRIPTION OF THE STOP</div>
          {range(0, nStopDescriptions).map((n) => {
            const reasonIndex = useSamePrimaryReasonForAll ? 0 : n;
            const stopDescription = person?.[reasonIndex]?.stopDescription;

            return (
              <div className="review-row__row-details-stop" key={`ReviewRowStopDesciption-${rowIndex}-${n}`}>
                <div className={cn('review-row__row-details-number stop-description', { all: useSamePrimaryReasonForAll })}>{useSamePrimaryReasonForAll ? 'All' : n + 1}</div>
                <div className="review-row__row-details-stop-field">
                  <PiiTextField
                    placeholder="Enter brief, 250 character maximum, explanation..."
                    disabled={status !== Status.UnderReview}
                    onBlur={onFieldBlur}
                    onChange={({ target: { value } }) => {
                      if (value.trim().length <= 250 && value.trim().length >= 5) {
                        setHasEdit('Reviewer changes stop description');
                        setStopDescription({ id, value, reasonIndex });
                      }
                      if (value.trim().length >= 5) {
                        setStopDescriptionCountAdjustment(prevState => prevState.map((count, index) => index === reasonIndex ? 0 : count));
                      } else {
                        setStopDescriptionCountAdjustment(prevState => prevState.map((count, index) => index === reasonIndex ? stopDescription?.length - value.length : count));
                        createSnackNotification(AlertLevel.Error, 'Error', 'Description of the stop must contain at minimum 5 characters.')
                      }
                    }}
                    value={stopDescription}
                    data-testid="review-row-row-details-stop-field-input"
                    charMin={5}
                    charLimit={250}
                  />
                  <div className="review-row__row-details-stop-remaining" data-testid="review-row-row-details-stop-remaining">
                    <b>{`${250 - ((stopDescription?.length - stopDescriptionCountAdjustment[reasonIndex]) ?? 0)} `}</b>
                    Remaining
                  </div>
                </div>
              </div>
            );
          })}

          {nPeopleHasSearch > 0 ? <div className="review-row__row-details-search-label">DESCRIPTION OF THE SEARCH</div> : ''}
          {range(0, nPeople).map((n) => {
            const actionTaken = person?.map((p: any) => p.actionTaken)[n];
            const searchBasis = person?.map((p: any) => p.searchBasis)[n];
            const searchDescriptionIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : n;
            const searchDescriptionValue = searchDescription?.[searchDescriptionIndex];
            const hasSearch = actionTaken?.filter((v: any) => v.match(/search.+conducted/gi)).length > 0;

            const hasConditionOfParole = searchBasis?.filter((v: any) => v.match(/Condition of parole/gi)).length > 0;
            const hasOnlyOneSearchBasis = searchBasis?.length === 1;
            const isConditionOfParoleSearch = hasConditionOfParole && hasOnlyOneSearchBasis;

            if (hasSearch) {
              return (
                <div key={`ReviewSearchDesc-${n}-${id}`} className="review-row__row-details-search">
                  <div className={cn('review-row__row-details-number', { all: useSameActionTakenForAll })}>{useSameActionTakenForAll ? 'All' : n + 1}</div>
                  <div className="review-row__row-details-search-field">
                    <PiiTextField
                      placeholder="Enter brief, 250 character maximum, explanation..."
                      disabled={status !== Status.UnderReview || isConditionOfParoleSearch}
                      onBlur={onFieldBlur}
                      onChange={({ target: { value } }) => {
                        if (value.trim().length <= 250 && value.trim().length >= 5) {
                          setHasEdit('Reviewer changes search description');
                          setSearchDescription({
                            id,
                            value,
                            personIndex: searchDescriptionIndex,
                          });
                        }
                        if (value.trim().length >= 5) {
                          setSearchDescriptionCountAdjustment(prevState => prevState.map((count, index) => index === searchDescriptionIndex ? 0 : count));
                        } else {
                          setSearchDescriptionCountAdjustment(prevState => prevState.map((count, index) => index === searchDescriptionIndex ? searchDescriptionValue?.length - value.length : count));
                          createSnackNotification(AlertLevel.Error, 'Error', 'Description of the search must contain at minimum 5 characters.')
                        }
                      }}
                      value={isConditionOfParoleSearch ? 'No search description required when search basis is Condition of Parole' : hasSearch ? searchDescriptionValue : 'No search conducted'}
                      data-testid="review-row-row-details-search-field-input"
                      charMin={5}
                      charLimit={250}
                    />
                    {!isConditionOfParoleSearch && (
                      <div className="review-row__row-details-search-remaining" data-testid="review-row-row-details-search-remaining">
                        <b>{`${250 - ((searchDescriptionValue?.length - searchDescriptionCountAdjustment[searchDescriptionIndex]) ?? 0)} `}</b>
                        Remaining
                      </div>
                    )}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default connector(ReviewRow);
