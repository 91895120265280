import { createStore, applyMiddleware } from 'redux';
import HttpClient from './dependencies/httpClient';
import localForageInstances from './dependencies/localforage';
import createMiddleware from './middleware';
import rootReducer from './reducer';

export default env => {
  const defaultHttp = new HttpClient(env);

  return createStore(
    rootReducer,
    applyMiddleware(
      ...createMiddleware({
        http: defaultHttp,
        ...localForageInstances,
      }),
    ),
  );
}
