import { Box, Dialog, DialogContent } from '@material-ui/core';
import { Button } from '@components/common';
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import './DateRangePicker.scss';

const DateRangePicker = ({ open, onAccept, onCancel }: Props) => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDateSelect, setStartDateSelect] = useState<Boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleSave = () => {
    onAccept(startDate.format('MM/DD/YYYY'), endDate.format('MM/DD/YYYY'));
    onCancel();
  };

  const handlePickerChange = (value: Date | null) => {
    setSelectedDate(value);
    if (startDateSelect) {
      setStartDate(dayjs(value));
    } else {
      setEndDate(dayjs(value));
    }
  };

  const handleChangeStart = (value: Date | null) => {
    setStartDate(dayjs(value));
  };

  const handleChangeEnd = (value: Date | null) => {
    setEndDate(dayjs(value));
  };

  return (
    <Dialog aria-labelledby="date-range-picker-dialog-title" className="date-range-picker" open={open} onClose={onCancel}>
      <DialogContent>
        <Box>
          <Box>
            <KeyboardDatePicker
              className="date-range-picker__date-picker"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start Date"
              value={startDate}
              onChange={handleChangeStart}
              onFocus={() => setStartDateSelect(true)}
              autoFocus
            />
          </Box>
          <Box>
            <KeyboardDatePicker
              className="date-range-picker__date-picker"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              label="End Date"
              value={endDate}
              onChange={handleChangeEnd}
              onFocus={() => setStartDateSelect(false)}
            />
          </Box>
        </Box>
      </DialogContent>
      <div className="date-range-picker__content">
        <DatePicker value={selectedDate} onChange={handlePickerChange} variant="static" disableToolbar />
      </div>
      <div className="date-range-picker__button-row">
        <Button priority="secondary" aria-label="Deny action" data-testid="date-range-picker-no-button" className="date-range-picker__no" onClick={onCancel}>
          Cancel
        </Button>
        <Button aria-label="Confirm action" data-testid="date-range-picker-yes-button" className="date-range-picker__yes" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Dialog>
  );
};

interface Props {
  open: boolean;
  onAccept: (start: string, end: string) => void;
  onCancel: () => void;
}

export default DateRangePicker;
