import React from 'react';
import Logo from './RGB_veritone_logo_primary_TM.png';

/* eslint-disable */

const VeritoneColorSvg = (props: { className?: string }) => (
  <div className="logo-container">
    <img
      style={{
        width: '115px',
      }}
      src={Logo}
      alt="Veritone Logo"
      className={props.className}
    />
  </div>
);

export default VeritoneColorSvg;
