import React from 'react'
import cn from 'classnames'
import { FormControl, Select, MenuItem, ListItemText, Checkbox as MuiCheckbox, SelectProps } from '@material-ui/core'
import { omit } from 'ramda'
import './LabelDropdown.scss'

/* eslint-disable react/no-unused-prop-types */

const LabelDropdown = (labelDropdownProps: Props & SelectProps) => {
  const { label, className, onChange, value, values, error, errorMessage, multiple, placeholder, disableAll } = labelDropdownProps
  const props = omit(['label', 'disableAll', 'className', 'error', 'errorMessage', 'value'], labelDropdownProps);

  return <div className={cn('label-dropdown', className)}>
    <div className="label-dropdown__label">
      {label}
    </div>
    <FormControl variant="outlined" className="label-dropdown__dropdown">
      <Select
        disabled={disableAll}
        value={value ?? []}
        onChange={onChange}
        error={error}
        displayEmpty={!!placeholder}
        renderValue={val => <div className="label-dropdown__dropdown-value">{values?.find(v => v.value === val)?.text ?? placeholder ?? ''}</div>}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              width: 250
            }
          }
        }}
        {...props}
      >
        {values && values.map((v, i) => <MenuItem disabled={disableAll} key={`LabelDrodown-${v?.value}-${v?.text}-${i}`} value={v?.value}>
          {multiple && <MuiCheckbox checked={value?.includes?.(v?.value)} />}
          <ListItemText primary={v?.text} />
        </MenuItem>)}
      </Select>
    </FormControl>
    {error && <div className="label-dropdown__error">
      {errorMessage}
    </div>}
  </div>
}

interface Props {
  label?: string;
  className: string;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disableAll?: boolean;
  value: any;
  values: {
    value: string;
    text: string;
  }[];
}
export default LabelDropdown
