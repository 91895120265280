import { connect, ConnectedProps } from 'react-redux';
import {
    CustomQuestions as CustomQuestionsDuck,
    Organizations as OrganizationsDuck
} from '@ducks';

const mapStateToProps = (state: any) => ({
    json: CustomQuestionsDuck.selectors.json(state),
    orgId: CustomQuestionsDuck.selectors.orgId(state),
    jsonDialogJson: CustomQuestionsDuck.selectors.jsonDialogJson(state),
    jsonDialogOpen: CustomQuestionsDuck.selectors.jsonDialogOpen(state),
    allowJsonDialogButton: CustomQuestionsDuck.selectors.allowJsonDialogButton(state),
    organizations: OrganizationsDuck.selectors.orgs(state)
  });

  const mapDispatchToProps = {
    setJson: CustomQuestionsDuck.setJson,
    setOrgId: CustomQuestionsDuck.setOrgId,
    getQuestions: CustomQuestionsDuck.getQuestions,
    setQuestions: CustomQuestionsDuck.setQuestions,
    setJsonDialogOpen: CustomQuestionsDuck.setJsonDialogOpen,
    saveJsonDialog: CustomQuestionsDuck.saveJsonDialog,
    setJsonDialogJson: CustomQuestionsDuck.setJsonDialogJson,
    getOrganizations: OrganizationsDuck.getOrganizations
  };

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
