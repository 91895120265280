import getEnv from '@engine/env';

export const DEFAULT_ONLINE_TIMEOUT = 8500;

const getOnlineTimeoutByEffectiveType = () => {
    const { REACT_APP_FEATURE_FLAGS: { onlineCheckTimeout } } = getEnv()
    if (navigator.connection?.effectiveType) {
      if (navigator.connection.effectiveType in onlineCheckTimeout) {
        return onlineCheckTimeout[navigator.connection.effectiveType] as number;
      }
    }
    // Default to 8.5 seconds
    return DEFAULT_ONLINE_TIMEOUT;
}

export default getOnlineTimeoutByEffectiveType;
