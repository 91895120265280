import React, { useState } from 'react';
import cn from 'classnames';
import { range, remove, insert } from 'ramda';
import dayjs from 'dayjs';
import { Button, createSnackNotification, AlertLevel } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import { userSettingsErrorMessage } from '@utility/userSettingsErrorMessage';
import { selectors as FormSelectors } from '@ducks/form';
import { FormNamespace } from '@ducks/constants';
import { Props, connector } from './props';
import './RipaReviewForm.scss';

const RipaReviewForm = ({ form, back, customQuestions, submitFormForReview, steps, flags: { pre2024, post2024 }, isUserSettingsValid }: Props) => {
  const [accordionOpen, setAccordionOpen] = useState<number[]>([]);
  const {
    numberOfPeople,
    stopDateTime,
    duration,
    useSameActionTakenForAll,
    person,
    school,
    id,
    useSameActionForAllPersonIndex,
    useSameRaceForAll,
    useSameGenderForAll,
    useSamePrimaryReasonForAll,
    officerWorksWithNonPrimaryAgency,
    stopDuringWellnessCheck,
    assignment,
    assignmentDescription,
    typeOfStop,
    stopHappenedAtPublicSchool,
  } = form || {};
  const locationString = FormSelectors.locationString({ [FormNamespace]: form });
  const resultPathMap: Record<string, string> = (steps || []).reduce(
    (acc, s) => ({
      ...acc,
      ...s.substeps.reduce((map, ss) => {
        if ((ss as any)?.props) {
          // TODO: finish defining Substep type
          return { ...map, [(ss as any).props?.resultPath]: (ss as any).props?.title };
        }
        return map;
      }, {}),
    }),
    {}
  );

  const toggleAccordion = (i: number) => {
    const ai = accordionOpen.findIndex((a) => a === i);
    if (ai === -1) {
      setAccordionOpen(insert(0, i, accordionOpen));
    } else {
      setAccordionOpen(remove(ai, 1, accordionOpen));
    }
  };

  const submitForm = () => {
    if (pre2024 && form && form.assignment === 'Other' && form.assignmentDescription.length < 3) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Other Assignment must contain at minimum 3 characters.');
    } else if (!pre2024 && form && form.assignment === 'Other' && form.otherAssignment.length < 3) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Other Assignment must contain at minimum 3 characters.');
    } else if (!isUserSettingsValid) {
      createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to submit a new report.`)
    } else {
      submitFormForReview();
    }
  };

  return (
    <div className="ripa-review-form" data-testid="ripa-review-form">
      <div className="ripa-review-form__review-and-submit">
        <div className="ripa-review-form__review-and-submit-title">Review and Submit</div>
        <div className="ripa-review-form__review-and-submit-nav">
          <Button className="ripa-review-form__review-and-submit-nav-back" data-testid="ripa-review-form-back" priority="secondary" aria-label="Back a step" onClick={back}>
            BACK
          </Button>
          <Button className="ripa-review-form__review-and-submit-nav-submit" priority="primary" aria-label="Submit the form" data-testid="ripa-review-form-submit" onClick={submitForm}>
            SUBMIT
          </Button>
        </div>
      </div>
      <div className="ripa-review-form__content">
        <div className="ripa-review-form__stop-id">
          <div className="material-icons">description</div>
          <div>
            <div className="ripa-review-form__stop-id-text">STOP ID</div>
            <div className="ripa-review-form__stop-id-value" data-testid="ripa-review-form-stop-id">{id}</div>
          </div>
        </div>
        <div className="ripa-review-form__people">
          <div className="material-icons">person</div>
          <div>
            <div className="ripa-review-form__people-text">PEOPLE</div>
            <div className="ripa-review-form__people-value">{numberOfPeople}</div>
          </div>
        </div>
        <div className="ripa-review-form__location">
          <div className="material-icons">place</div>
          <div>
            <div className="ripa-review-form__location-text">LOCATION</div>
            <div className="ripa-review-form__location-value">
              {locationString}
            </div>
          </div>
        </div>
        <div className="ripa-review-form__date-time-duration">
          <div className="ripa-review-form__date">
            <div className="ripa-review-form__date-text">DATE</div>
            <div className="ripa-review-form__date-value">{dayjs(stopDateTime).format('MM/DD/YYYY')}</div>
          </div>
          <div className="ripa-review-form__time">
            <div className="ripa-review-form__time-text">TIME</div>
            <div className="ripa-review-form__time-value">{dayjs(stopDateTime).format('hh:mm A')}</div>
          </div>
          <div className="ripa-review-form__duration">
            <div className="ripa-review-form__duration-text">DURATION</div>
            <div className="ripa-review-form__duration-value">{`${duration} minutes`}</div>
          </div>
        </div>
        {pre2024 && (
          <>
            {(assignment ?? '').length > 0 && (
              <div className="ripa-review-form__type-of-assignment">
                <div className="ripa-review-form__container">
                  <div className="ripa-review-form__type-of-assignment-text">ASSIGNMENT</div>
                  <div className="ripa-review-form__type-of-assignment-value">{assignment}</div>
                </div>
              </div>
            )}
            {(assignmentDescription ?? '').length > 0 && (
              <div className="ripa-review-form__type-of-assignment">
                <div className="ripa-review-form__container">
                  <div className="ripa-review-form__type-of-assignment-text">DESCRIPTION OF ASSIGNMENT</div>
                  <div className="ripa-review-form__type-of-assignment-value">{assignmentDescription}</div>
                </div>
              </div>
            )}
          </>
        )}
        {Object.entries(customQuestions ?? {}).map(([key, value]) => {
          if (resultPathMap[key] && key !== 'person') {
            return (
              <div className="ripa-review-form__custom-question" key={`ReviewFormCustomQuestion-${key}`}>
                <div className="ripa-review-form__custom-question-label">
                  {resultPathMap?.[key] ||
                    key
                      .replace(/([a-z])([A-Z])/g, '$1 $2')
                      .replace(/([A-Z])([A-Z])/g, '$1 $2')
                      .replace(/<.+>/, '')}
                </div>
                <div className="ripa-review-form__custom-question-value">{Array.isArray(value) ? value.join(', ') : value || 'N/A'}</div>
              </div>
            );
          }
          return <></>;
        })}
        <div className="ripa-review-form__people_accordion">
          {person &&
            range(0, numberOfPeople || 0).map((pi) => {
              const { label, age } = person[pi];
              const gender = person?.[useSameGenderForAll ? 0 : pi].gender;
              const race = person?.[useSameRaceForAll ? 0 : pi].race;
              const disabilities = person?.[pi].disabilities;

              const { lgbt } = gender?.startsWith('Trans') ? { lgbt: true } : person?.[pi];
              const { sexualOrientation } = person?.[pi];
              const { english } = person?.[pi];
              const { disabled } = person?.[pi];
              const { unhoused } = person?.[pi];

              const actionIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : pi
              const actionTaken = person?.[actionIndex]?.actionTaken;
              const consentType = person?.[actionIndex]?.consentType;
              const searchBasis = person?.[actionIndex]?.searchBasis;
              const seizedPropertyBasis = person?.[actionIndex]?.seizedPropertyBasis;
              const seizedPropertyType = person?.[actionIndex]?.seizedPropertyType;
              const contraband = person?.[actionIndex]?.anyContrabandFound ? person?.[actionIndex]?.contraband : [];
              const resultOfStop = person?.[actionIndex]?.anyResultOfStop ? person?.[actionIndex]?.resultOfStop : [];
              const resultOfStopCodeSection = person?.[actionIndex]?.anyResultOfStop ? person?.[actionIndex]?.resultOfStopCodeSection : {};
              const searchDescription = person?.[actionIndex]?.searchDescription;

              const stoppedPassenger = person?.[pi]?.stoppedPassenger;
              const stoppedInsideResidence = person?.[pi]?.stoppedInsideResidence;
              const reasonIndex = useSamePrimaryReasonForAll ? 0 : pi;
              const primaryReason = person?.[reasonIndex]?.primaryReason;
              const probableCause = person?.[reasonIndex]?.probableCause;
              const probableCauseCode = person?.[reasonIndex]?.probableCauseCode;
              const reasonGivenStoppedPerson = person?.[reasonIndex]?.reasonGivenStoppedPerson;
              const stopDescription = person?.[reasonIndex]?.stopDescription;
              const unknownGender = 'Gender Non-Conforming';

              return (
                <div className="ripa-review-form__accordion" key={`RipaReviewForm-Accordion-${pi}`}>
                  <div className="ripa-review-form__accordion-header">
                    <div className="ripa-review-form__accordion-header-label">
                      <div className="material-icons">person</div>
                      <div className="ripa-review-form__accordion-header-label-text" data-testid={`ripa-review-form-accordian-header-label-${pi}`}>{label || pi + 1}</div>
                    </div>
                    <div className="ripa-review-form__accordion-header-controls">
                      <div
                        role="button"
                        tabIndex={0}
                        data-testid={`ripa-review-form-accordion-header-controls-expand-${pi}`}
                        className={cn('ripa-review-form__accordion-header-controls-expand material-icons', { open: accordionOpen.includes(pi) })}
                        onClick={() => toggleAccordion(pi)}
                        onKeyUp={onEnter(() => toggleAccordion(pi))}
                      >
                        expand_less
                      </div>
                    </div>
                  </div>
                  <div className={cn('ripa-review-form__accordion-content', { open: accordionOpen.includes(pi) })}>
                    <div className="ripa-review-form__accordion-content-meta">
                      <div className="ripa-review-form__accordion-content-gender">
                        <div className="ripa-review-form__accordion-content-gender-text">GENDER</div>
                        <div className="ripa-review-form__accordion-content-gender-value">{gender || unknownGender}</div>
                      </div>
                      <div className="ripa-review-form__accordion-content-age">
                        <div className="ripa-review-form__accordion-content-age-text">AGE</div>
                        <div className="ripa-review-form__accordion-content-age-value">{age}</div>
                      </div>
                      <div className="ripa-review-form__accordion-content-ethnicity">
                        <div className="ripa-review-form__accordion-content-ethnicity-text">ETHNICITY</div>
                        <div className="ripa-review-form__accordion-content-ethnicity-value">{race?.join(', ') || 'N/A'}</div>
                      </div>
                    </div>
                    <div className="ripa-review-form__accordion-content-meta">
                      {post2024 ? (
                        <div className="ripa-review-form__accordion-content-lgbt">
                          <div className="ripa-review-form__accordion-content-lgbt-text">PERCEIVED SEXUAL ORIENTATION</div>
                          <div className="ripa-review-form__accordion-content-lgbt-value">{sexualOrientation}</div>
                        </div>
                      ) : (
                        <div className="ripa-review-form__accordion-content-lgbt">
                          <div className="ripa-review-form__accordion-content-lgbt-text">LGBT</div>
                          <div className="ripa-review-form__accordion-content-lgbt-value">{lgbt ? 'Yes' : 'No'}</div>
                        </div>
                      )}
                      <div className="ripa-review-form__accordion-content-english">
                        <div className="ripa-review-form__accordion-content-english-text">LIMITED ENGLISH FLUENCY</div>
                        <div className="ripa-review-form__accordion-content-english-value">{english ? 'Yes' : 'No'}</div>
                      </div>
                    </div>
                    {pre2024 && (
                      <>
                        <div className="ripa-review-form__accordion-content-unhoused-label">PERCEIVED TO BE UNHOUSED</div>
                        <div className="ripa-review-form__accordion-content-unhoused-value">{unhoused ? 'Yes' : 'No'}</div>
                      </>
                    )}
                    <div className="ripa-review-form__accordion-content-disability-label">DISABILITY</div>
                    <div className="ripa-review-form__accordion-content-disability-value">{disabled && disabilities && disabilities.length > 0 ? disabilities.join(', ') : 'None'}</div>
                    {pre2024 && (
                      <>
                        <div className="ripa-review-form__accordion-content-officer-non-primary-label">OFFICER WORKS WITH NON PRIMARY AGENCY</div>
                        <div className="ripa-review-form__accordion-content-officer-non-primary-value">{officerWorksWithNonPrimaryAgency ? 'True' : 'False'}</div>
                        <div className="ripa-review-form__accordion-content-stop-during-wellness-check-label">STOP DURING WELLNESS CHECK</div>
                        <div className="ripa-review-form__accordion-content-stop-during-wellness-check-value">{stopDuringWellnessCheck ? 'Yes' : 'No'}</div>
                      </>
                    )}
                    {stopHappenedAtPublicSchool && (
                      <>
                        <div className="ripa-review-form__accordion-content-school-label">NAME OF SCHOOL</div>
                        <div className="ripa-review-form__accordion-content-school-value">{school || 'N/A'}</div>
                      </>
                    )}
                    <div className="ripa-review-form__accordion-content-primary-reason-label">PRIMARY REASON</div>
                    <div className="ripa-review-form__accordion-content-primary-reason-value">{primaryReason}</div>
                    {
                      pre2024 && primaryReason.includes('Probable cause to arrest or search') && (
                        <>
                          <div className="ripa-review-form__accordion-content-probable-cause-label">PROBABLE CAUSE</div>
                          <div className="ripa-review-form__accordion-content-probable-cause-value">{probableCause}</div>
                          <div className="ripa-review-form__accordion-content-probable-cause-label">PROBABLE CAUSE CODE</div>
                          <div className="ripa-review-form__accordion-content-probable-cause-value">{probableCauseCode}</div>
                        </>
                      )
                    }
                    <div className="ripa-review-form__accordion-content-actions-label">ACTIONS</div>
                    <div className="ripa-review-form__accordion-content-actions-value">{actionTaken?.map((r) => r?.replaceAll(/<[^>]*>/g, ''))?.join(', ') || 'N/A'}</div>

                    <div className="ripa-review-form__accordion-content-search-basis-label">BASIS FOR SEARCH</div>
                    <div className="ripa-review-form__accordion-content-search-basis-value">{searchBasis?.join(', ') || 'N/A'}</div>
                    {
                      pre2024 && searchBasis.includes('Consent given') && (
                        <>
                          <div className="ripa-review-form__accordion-content-search-basis-consent-type-label ">BASIS FOR SEARCH CONSENT TYPE</div>
                          <div className="ripa-review-form__accordion-content-search-basis-consent-type-value">{consentType}</div>
                        </>
                      )
                    }
                    <div className="ripa-review-form__accordion-content-property-seizure-label">BASIS FOR PROPERTY SEIZURE</div>
                    <div className="ripa-review-form__accordion-content-property-seizure-value">{seizedPropertyBasis?.join(', ') || 'N/A'}</div>
                    <div className="ripa-review-form__accordion-content-type-seizure-label">TYPE OF PROPERTY SEIZURE</div>
                    <div className="ripa-review-form__accordion-content-type-seizure-value">{seizedPropertyType?.join(', ') || 'N/A'}</div>
                    <div className="ripa-review-form__accordion-content-contraband-label">CONTRABAND OR EVIDENCE DISCOVERED</div>
                    <div className="ripa-review-form__accordion-content-contraband-value">{contraband?.join(', ') || 'None'}</div>
                    {pre2024 && (
                      <>
                        <div className="ripa-review-form__accordion-content-type-of-stop-label">TYPE OF STOP</div>
                        <div className="ripa-review-form__accordion-content-type-of-stop-value">{typeOfStop}</div>
                      </>
                    )}
                    <div className="ripa-review-form__accordion-content-result-of-stop-label">RESULT OF STOP</div>
                    <div className="ripa-review-form__accordion-content-result-of-stop-value" data-testid={`ripa-review-form-accordion-content-result-of-stop-value-${pi}`}>
                      {resultOfStop
                        ?.map((r) => {
                          if (resultOfStopCodeSection && resultOfStopCodeSection?.[r]) {
                            const codeSection = resultOfStopCodeSection[r];
                            return `${r} (${Array.isArray(codeSection)
                              ? codeSection.map((code) => code.split(' - ')?.[0])
                              : codeSection?.split(' - ')?.[0]
                              })`;
                          }
                          return r;
                        })
                        ?.map((r) => r?.replaceAll(/<[^>]*>/g, ''))
                        .join(', ') || 'N/A'}
                      {/* eslint-enable */}
                    </div>
                    {pre2024 && (
                      <>
                        <div className="ripa-review-form__accordion-content-stopped-passenger-label">STOPPED PERSON WAS A PASSENGER</div>
                        <div className="ripa-review-form__accordion-content-stopped-passenger-value" data-testid={`ripa-review-form-accordian-content-stopped-passenger-${pi}`}>{stoppedPassenger ? 'Yes' : 'No'}</div>
                        <div className="ripa-review-form__accordion-content-stopped-inside-residence-label">STOPPED PERSON WAS INSIDE A RESIDENCE</div>
                        <div className="ripa-review-form__accordion-content-stopped-inside-residence-value">{stoppedInsideResidence ? 'Yes' : 'No'}</div>
                        <div className="ripa-review-form__accordion-content-stopped-inside-residence-label">REASON GIVEN TO STOPPED PERSON</div>
                        <div className="ripa-review-form__accordion-content-stopped-inside-residence-value">{reasonGivenStoppedPerson.join(', ')}</div>
                      </>
                    )}
                    <div className="ripa-review-form__accordion-content-stop-description-label">DESCRIPTION OF THE STOP</div>
                    <div className="ripa-review-form__accordion-content-stop-description-value">{stopDescription || 'N/A'}</div>
                    <div className="ripa-review-form__accordion-content-search-description-label">DESCRIPTION OF THE SEARCH</div>
                    <div className="ripa-review-form__accordion-content-search-description-value">{searchDescription || 'N/A'}</div>
                    {Object.entries(customQuestions?.person?.[pi] ?? {}).map(([key, value]) => {
                      if (resultPathMap[key]) {
                        return (
                          <React.Fragment key={`ReviewFormCustomQuestionPerson-${key}`}>
                            <div className="ripa-review-form__accordion-content-custom-question-label">
                              {resultPathMap?.[key] ||
                                key
                                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                                  .replace(/([A-Z])([A-Z])/g, '$1 $2')
                                  .replace(/<.+>/, '')}
                            </div>
                            <div className="ripa-review-form__accordion-content-custom-question-value">{Array.isArray(value) ? value.join(', ') : value || 'N/A'}</div>
                          </React.Fragment>
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default connector(RipaReviewForm);
