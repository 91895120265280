import React from 'react'
import { onEnter } from '@utility/keypressHelpers'
import './Dialog.scss'

const Dialog = ({ open, children, onClickOff }: Props) =>
  <div
    className="dialog"
    role="dialog"
    data-testid="dialog"
    // @ts-ignore
    open={open}
  >
    <div className="dialog__backdrop" role="button" onClick={onClickOff} onKeyUp={onEnter(onClickOff)} tabIndex={0} />
    {open && <div className="dialog__content">
      {children}
    </div>}
  </div>

interface Props {
  open: boolean;
  children: React.ReactNode;
  onClickOff?: React.EventHandler<any>;
}

export default Dialog
