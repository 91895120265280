export default {
  oneLine: ({
    city,
    streetNumber,
    street,
    premise
  }: {
    city?: string;
    streetNumber?: number | string;
    street?: string;
    premise?: string;
  },
    includeStreetNumber = true
  ) => {
    streetNumber = Number(streetNumber);
    const blockOrStreetNumber = (streetNumber && streetNumber >= 100)
      ?
      `${Math.floor(streetNumber / 100) * 100} Block`
      :
      (streetNumber ? '0-100 Block' : '');
    if (street) {
      return `${includeStreetNumber ? blockOrStreetNumber || '' : ''} ${street}`.trim();
    }
    return premise || city || '';
  }
}
