// lat/long boundaries list for states/provinces.
// https://anthonylouisdagostino.com/bounding-boxes-for-all-us-states/
//   ymin, xmin, ymax, xmax

export default {
  AL: [
    30.223334,
    -88.473227,
    35.008028,
    -84.88908
  ],
  AK: [
    51.214183,
    -179.148909,
    71.365162,
    179.77847
  ],
  AS: [
    -14.548699,
    -171.089874,
    -11.046934,
    -168.1433
  ],
  AZ: [
    31.332177,
    -114.81651,
    37.00426,
    -109.045223
  ],
  AR: [
    33.004106,
    -94.617919,
    36.4996,
    -89.644395
  ],
  CA: [
    32.534156,
    -124.409591,
    42.009518,
    -114.131211
  ],
  CO: [
    36.992426,
    -109.060253,
    41.003444,
    -102.041524
  ],
  MP: [
    14.110472,
    144.886331,
    20.553802,
    146.064818
  ],
  CT: [
    40.980144,
    -73.727775,
    42.050587,
    -71.786994
  ],
  DE: [
    38.451013,
    -75.788658,
    39.839007,
    -75.048939
  ],
  DC: [
    38.791645,
    -77.119759,
    38.99511,
    -76.909395
  ],
  FL: [
    24.523096,
    -87.634938,
    31.000888,
    -80.031362
  ],
  GA: [
    30.357851,
    -85.605165,
    35.000659,
    -80.839729
  ],
  GU: [
    13.234189,
    144.618068,
    13.654383,
    144.956712
  ],
  HI: [
    18.910361,
    -178.334698,
    28.402123,
    -154.806773
  ],
  ID: [
    41.988057,
    -117.243027,
    49.001146,
    -111.043564
  ],
  IL: [
    36.970298,
    -91.513079,
    42.508481,
    -87.494756
  ],
  IN: [
    37.771742,
    -88.09776,
    41.760592,
    -84.784579
  ],
  IA: [
    40.375501,
    -96.639704,
    43.501196,
    -90.140061
  ],
  KS: [
    36.993016,
    -102.051744,
    40.003162,
    -94.588413
  ],
  KY: [
    36.497129,
    -89.571509,
    39.147458,
    -81.964971
  ],
  LA: [
    28.928609,
    -94.043147,
    33.019457,
    -88.817017
  ],
  ME: [
    42.977764,
    -71.083924,
    47.459686,
    -66.949895
  ],
  MD: [
    37.911717,
    -79.487651,
    39.723043,
    -75.048939
  ],
  MA: [
    41.237964,
    -73.508142,
    42.886589,
    -69.928393
  ],
  MI: [
    41.696118,
    -90.418136,
    48.2388,
    -82.413474
  ],
  MN: [
    43.499356,
    -97.239209,
    49.384358,
    -89.491739
  ],
  MS: [
    30.173943,
    -91.655009,
    34.996052,
    -88.097888
  ],
  MO: [
    35.995683,
    -95.774704,
    40.61364,
    -89.098843
  ],
  MT: [
    44.358221,
    -116.050003,
    49.00139,
    -104.039138
  ],
  NE: [
    39.999998,
    -104.053514,
    43.001708,
    -95.30829
  ],
  NV: [
    35.001857,
    -120.005746,
    42.002207,
    -114.039648
  ],
  NH: [
    42.69699,
    -72.557247,
    45.305476,
    -70.610621
  ],
  NJ: [
    38.928519,
    -75.559614,
    41.357423,
    -73.893979
  ],
  NM: [
    31.332301,
    -109.050173,
    37.000232,
    -103.001964
  ],
  NY: [
    40.496103,
    -79.762152,
    45.01585,
    -71.856214
  ],
  NC: [
    33.842316,
    -84.321869,
    36.588117,
    -75.460621
  ],
  ND: [
    45.935054,
    -104.0489,
    49.000574,
    -96.554507
  ],
  OH: [
    38.403202,
    -84.820159,
    41.977523,
    -80.518693
  ],
  OK: [
    33.615833,
    -103.002565,
    37.002206,
    -94.430662
  ],
  OR: [
    41.991794,
    -124.566244,
    46.292035,
    -116.463504
  ],
  PA: [
    39.7198,
    -80.519891,
    42.26986,
    -74.689516
  ],
  PR: [
    17.88328,
    -67.945404,
    18.515683,
    -65.220703
  ],
  RI: [
    41.146339,
    -71.862772,
    42.018798,
    -71.12057
  ],
  SC: [
    32.0346,
    -83.35391,
    35.215402,
    -78.54203
  ],
  SD: [
    42.479635,
    -104.057698,
    45.94545,
    -96.436589
  ],
  TN: [
    34.982972,
    -90.310298,
    36.678118,
    -81.6469
  ],
  TX: [
    25.837377,
    -106.645646,
    36.500704,
    -93.508292
  ],
  VI: [
    17.673976,
    -65.085452,
    18.412655,
    -64.564907
  ],
  UT: [
    36.997968,
    -114.052962,
    42.001567,
    -109.041058
  ],
  VT: [
    42.726853,
    -73.43774,
    45.016659,
    -71.464555
  ],
  VA: [
    36.540738,
    -83.675395,
    39.466012,
    -75.242266
  ],
  WA: [
    45.543541,
    -124.763068,
    49.002494,
    -116.915989
  ],
  WV: [
    37.201483,
    -82.644739,
    40.638801,
    -77.719519
  ],
  WI: [
    42.491983,
    -92.888114,
    47.080621,
    -86.805415
  ],
  WY: [
    40.994746,
    -111.056888,
    45.005904,
    -104.05216
  ]
}
