import { connect, ConnectedProps } from 'react-redux';
import { Config, Template, Form } from '@engine/ducks';

const mapStateToProps = (state: any) => ({
  typeOfAssignmentOfficer: Form.selectors.typeOfAssignmentOfficer(state),
  assignment: Form.selectors.assignment(state),
  assignmentDescription: Form.selectors.assignmentDescription(state),
  officerAssignment: Template.selectors.enumMap(state),
  breakpoint: Config.selectors.breakpoint(state)
});

const mapDispatchToProps = {
  setTypeOfAssignmentOfficer: Form.setFormField({ path: ['typeOfAssignmentOfficer'] }),
  setAssignment: Form.setFormField({ path: ['assignment'] }),
  setAssignmentDescription: (value: string) => Form.setFormField({ path: ['assignmentDescription'] })(value)
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
