import { createInstance } from 'localforage'

export class LocalforageSafariFix {

  retryCount = 20;

  requestTimeout = 2000;

  instance: LocalForage;

  defaultValueMap: Record<string, any>;

  constructor(localforageConfig: LocalForageOptions, defaultValueMap?: Record<string, any>) {
    this.instance = createInstance(localforageConfig)
    this.defaultValueMap = defaultValueMap ?? {}
  }

  retryRequestAnimationFrame = (fn: Promise<unknown>, attemptsRemaining = this.retryCount) => new Promise((resolve, reject) => {
    try {
      window.requestAnimationFrame(() => {
        try {
          resolve(fn)
        } catch (e) {
          if (attemptsRemaining > 0) {
            resolve(this.retryRequestAnimationFrame(fn, attemptsRemaining - 1))
          } else {
            reject(e)
          }
        }
      })
    } catch (e) {
      try {
        resolve(fn)
      } catch (e2) {
        reject(e2)
      }
    }
  })

  getItem(key: string) {
    return new Promise(resolve => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.getItem(key)))
      } catch (e) {
        console.error('IndexDB failure:', 'getItem', key)
        resolve(this.defaultValueMap[key] ?? null)
      }
    })
  }

  setItem(key: string, data: any) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.setItem(key, data)))
      } catch (e) {
        console.error('IndexDB failure:', 'setItem', key)
        reject(e)
      }
    })
  }

  iterate(fn: any) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.iterate(fn)))
      } catch (e) {
        console.error('IndexDB failure:', 'iterate')
        reject(e)
      }
    })
  }

  removeItem(key: string) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.removeItem(key)))
      } catch (e) {
        console.error('IndexDB failure:', 'removeItem')
        reject(e)
      }
    })
  }

  keys() {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.keys()))
      } catch (e) {
        console.error('IndexDB failure:', 'keys')
        reject(e)
      }
    })
  }

  length() {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.retryRequestAnimationFrame(this.instance.length()))
      } catch (e) {
        console.error('IndexDB failure:', 'length')
        reject(e)
      }
    })
  }
}

// TODO: Daniel - please confirm if this change to add the second parameter is correct
// It was being called from the offlineConfig in in localforage.ts with a second parameter which wasn't getting used
export const createInstanceSafariFix = (config: LocalForageOptions, options?: Record<string, any>) => new LocalforageSafariFix(config, options)
