import React, { useState } from 'react';
import cn from 'classnames';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './RipaCodeSectionForm.scss';

const RipaCodeSectionForm = ({
  EC_Subdivision,
  DisciplineUnderEC,
  setCodeSection48900,
  setCodeSection48900Subdivision,
  codeSection48900,
  codeSection48900Subdivision,
  labels,
  numberOfPeople,
  useSamePrimaryReasonForAll,
  currentSubloop,
}: Props) => {
  const [open, setOpen] = useState(false);
  const subloopIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;

  return (
    <div className="ripa-code-section-form" data-testid="ripa-code-section-form">
      <div className={cn('ripa-code-section-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-code-section-form__person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-code-section-form__person-same-for-all">Same For All</div>
        <div className="ripa-code-section-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-code-section-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-code-section-form__title">What is the code section?</div>
      <div className="ripa-code-section-form__select-box-container">
        <div className="ripa-code-section-form__select-box-content">
          {[DisciplineUnderEC.c48900, DisciplineUnderEC.c489002, DisciplineUnderEC.c489003, DisciplineUnderEC.c489004, DisciplineUnderEC.c489007].map(({ value }, vi) => (
            <div
              className={cn('ripa-code-section-form__select-box', { active: codeSection48900[subloopIndex] === value })}
              key={`RipaSuspicionFormBox-${vi}}`}
              onClick={() => setCodeSection48900(value, subloopIndex)}
              onKeyUp={onEnter(() => setCodeSection48900(value, subloopIndex))}
              data-testid={`ripa-code-section-form-box-${vi}`}
              role="button"
              tabIndex={0}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ))}
          {codeSection48900[subloopIndex] === DisciplineUnderEC.c48900.value && (
            <Autocomplete
              autoHighlight
              autoSelect
              className="ripa-code-section-form__autocomplete"
              data-testid="ripa-code-section-form-autocomplete"
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onInputChange={(_, v) => setCodeSection48900Subdivision(v, subloopIndex)}
              defaultValue={codeSection48900Subdivision}
              options={Object.values(EC_Subdivision).map((v: any) => v.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Specify the subdivision of EC 4890..."
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'ripa-code-section-form-autocomplete-input',
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connector(RipaCodeSectionForm);
