import { connect, ConnectedProps } from 'react-redux';
import { Form, NewReport } from '@ducks';
import React from 'react';

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  currentPerson: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
});

const mapDispatchToProps = {
  onChange: (value: string, pth: (string | number)[]) => Form.setFormField({ path: pth })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  title: string;
  options: any[];
  setValidationErrors: (param: any) => void;
  resultPath: string;
  style?: React.CSSProperties;
  isAll: boolean;
  dropdownLabel: string;
  placeholder: string;
  autocomplete: boolean;
  hasHelper: boolean;
};
