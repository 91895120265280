import { path } from 'ramda'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import { createSnackNotification, AlertLevel } from '@components/common'
import { CustomQuestionsNamespace } from './constants'
import { GetState, ThunkDeps } from './types'

export const INITIAL_STATE = {
  orgId: '',
  json: '',
  noRecord: true,
  jsonDialogJson: '',
  jsonDialogOpen: false,
  allowJsonDialogButton: false
}

export const SET_ORG_ID = `${CustomQuestionsNamespace}/SET_ORG_ID`
export const SET_JSON = `${CustomQuestionsNamespace}/SET_JSON`
export const SET_NO_RECORD = `${CustomQuestionsNamespace}/SET_NO_RECORD`
export const SET_JSON_DIALOG_OPEN = `${CustomQuestionsNamespace}/SET_JSON_DIALOG_OPEN`
export const SET_JSON_DIALOG_JSON = `${CustomQuestionsNamespace}/SET_JSON_DIALOG_JSON`
export const SET_ALLOW_JSON_DIALOG_BUTTON = `${CustomQuestionsNamespace}/SET_ALLOW_JSON_DIALOG_BUTTON`

export const reducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action

  switch (type) {
    case SET_ORG_ID:
      return {
        ...state,
        orgId: payload.orgId
      }
    case SET_JSON:
      return {
        ...state,
        json: payload.json
      }
    case SET_NO_RECORD:
      return {
        ...state,
        noRecord: payload.noRecord
      }
    case SET_JSON_DIALOG_OPEN:
      return {
        ...state,
        jsonDialogOpen: payload.open,
        jsonDialogJson: payload.json ?? state.jsonDialogJson
      }
    case SET_JSON_DIALOG_JSON:
      return {
        ...state,
        jsonDialogJson: payload.json
      }
    case SET_ALLOW_JSON_DIALOG_BUTTON:
      return {
        ...state,
        allowJsonDialogButton: payload.allowJsonDialogButton
      }
    default:
      return state
  }
}

export const setOrgId = (orgId: number) => ({
  type: SET_ORG_ID,
  payload: { orgId }
})

export const setJson = (json: string) => ({
  type: SET_JSON,
  payload: { json }
})

export const setNoRecord = (noRecord: boolean) => ({
  type: SET_NO_RECORD,
  payload: { noRecord }
})

export const setJsonDialogOpen = ({ open, json = '' }: { open: boolean; json?: string }) => ({
  type: SET_JSON_DIALOG_OPEN,
  payload: { open, json }
})

export const setJsonDialogJson = (json: string) => ({
  type: SET_JSON_DIALOG_JSON,
  payload: { json }
})

// Only for use in window.allowJsonDialog()
export const setAllowJsonDialogButton = (allowJsonDialogButton: boolean) => ({
  type: SET_ALLOW_JSON_DIALOG_BUTTON,
  payload: { allowJsonDialogButton }
})

export const getQuestions = () => (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
  const { orgId } = getState()[CustomQuestionsNamespace]

  return http
    .get<{ questions: any }>(`/organizations/${orgId}/custom_questions`)
    .then(({ data }) => {
      if (!data?.questions) {
        dispatch(setNoRecord(true))
        dispatch(setJson(JSON.stringify({ all: { title: 'Per-person Custom', questions: [] }, individual: { title: 'Per-stop Custom', questions: [] }, isLive: false })))
      } else {
        dispatch(setNoRecord(false))
        dispatch(setJson(JSON.stringify(data.questions)))
      }
    })
    .catch(httpErrorHandler('Failed to get org questions'));
}

export const setQuestions = (newJson: string) => (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
  const { orgId, noRecord } = getState()[CustomQuestionsNamespace]
  if (noRecord) {
    return http
      .post(`/organizations/${orgId}/custom_questions`, { questions: JSON.parse(newJson) })
      .then(() => {
        createSnackNotification(AlertLevel.Success, 'Success', 'Questions saved');
        dispatch(setJson(newJson))
        dispatch(setNoRecord(false))
      })
      .catch(httpErrorHandler('Failed to create org questions'));
  }
  return http
    .patch(`/organizations/${orgId}/custom_questions`, { questions: JSON.parse(newJson) })
    .then(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Questions saved');
      dispatch(setJson(newJson))
    })
    .catch(httpErrorHandler('Failed to set org questions'));
}

export const saveJsonDialog = () => (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
  const { jsonDialogJson, noRecord, orgId } = getState()[CustomQuestionsNamespace]
  if (noRecord) {
    return http
      .post(`/organizations/${orgId}/custom_questions`, { questions: JSON.parse(jsonDialogJson) })
      .then(() => {
        dispatch(setNoRecord(false))
        createSnackNotification(AlertLevel.Success, 'Success', 'Question json saved');
      })
      .catch(httpErrorHandler('Failed to create org question json'));
  }
  return http
    .patch(`/organizations/${orgId}/custom_questions`, { questions: JSON.parse(jsonDialogJson) })
    .then(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Question json saved');
    })
    .catch(httpErrorHandler('Failed to set org question json'));
}

// State Selectors
export const selectors = {
  orgId: path<number>([CustomQuestionsNamespace, 'orgId']),
  json: path<string>([CustomQuestionsNamespace, 'json']),
  jsonDialogJson: path<string>([CustomQuestionsNamespace, 'jsonDialogJson']),
  jsonDialogOpen: path<boolean>([CustomQuestionsNamespace, 'jsonDialogOpen']),
  allowJsonDialogButton: path<boolean>([CustomQuestionsNamespace, 'allowJsonDialogButton'])
}

export default reducer
