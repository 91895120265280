import React from 'react';
import './TrainingModeBanner.scss';

const TrainingModeBanner = ({ breakpoint }: Props) => (
  <div className="training-mode-banner">
    <span className="material-icons">priority_high</span>
    {breakpoint === 'xs' ? (
      <>
        <span>
          <b>Training Mode Activated:</b>
          &nbsp;Reports will not be submitted.
        </span>
      </>
    ) : (
      <>
        <span>
          <b>Training Mode Activated:</b>
          &nbsp;You have Training Mode turned on. All reports created and modified will remain in Training Mode and will not be saved or submitted.
        </span>
      </>
    )}
  </div>
);
interface Props {
  breakpoint: string;
}

export default TrainingModeBanner;
