import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from '@components/custom';
import { paths as Paths } from '@components/custom/Routes/paths';
import { assignRoute } from '@utility/assignRoute';
import { Config } from '@ducks';
import './ReviewUnavailable.scss';

export const ReviewUnavailable = ({ breakpoint }) => {
  useEffect(() => {
    if (breakpoint !== 'xs') {
      assignRoute(Paths.Review.path);
    }
  }, [breakpoint]);

  return (
    <Header title="Contact">
      <p className="review-unavailable__help-text" data-testid="review-unavailable">
        The review page is not available for mobile. Use desktop app or switch to desktop mode.
      </p>
    </Header>
  );
}

ReviewUnavailable.propTypes = {
  breakpoint: PropTypes.string
};

const mapStateToProps = state => ({
  breakpoint: Config.selectors.breakpoint(state),
});

export default connect(mapStateToProps)(ReviewUnavailable);
