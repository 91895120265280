import { Form } from '@engine/ducks/types';
import { dissoc } from 'ramda';

const legacyValues = [
  { legacy: 'primaryReason', current: 'primaryReason' },
  { legacy: 'trafficViolation', current: 'trafficViolation' },
  { legacy: 'trafficViolationCode', current: 'trafficViolationCode' },
  { legacy: 'resonableSuspicion', current: 'reasonableSuspicion' },
  { legacy: 'resonableSuspicionCode', current: 'reasonableSuspicionCode' },
  { legacy: 'codeSection48900', current: 'codeSection48900' },
  { legacy: 'codeSection48900Subdivision', current: 'codeSection48900Subdivision' },
  { legacy: 'stopDescription', current: 'stopDescription' }
];

export default (formContents: Form) => {
  legacyValues.forEach((val) => {
    if (val.legacy in formContents) {
      formContents.person[0] = {
        ...formContents.person[0],
        [val.current]: (formContents as any)[val.legacy]
      };

      formContents = dissoc(val.legacy as any, formContents) as Form;
      formContents.useSamePrimaryReasonForAll = true;
    }
  });

  if (!formContents?.flags) {
    formContents.flags = {
      pre2024: false,
      post2024: false,
      is2024Test: false
    };
  }

  return formContents;
}
