import { Template, ThunkDeps } from '@engine/ducks/types';
import { chain } from 'lodash';

// interface for build details object
interface BuildDetailsObject {
    hash: string;
    date: string;
}

// get form template data from cache if available, otherwise fetch from server
export default async function getFormTemplatesCached(http: ThunkDeps['http'], offlineTemplates: ThunkDeps['offlineTemplates']): Promise<Template[]> {
    const getLatestOfflineTemplates = async () => {
        const offlineTemplatesData: Template[] = [];
        try {
            await offlineTemplates.iterate((t: Template) => { offlineTemplatesData.push(t) });
            return chain(offlineTemplatesData)
                // sorting by version in desc order so most recent dates appear first
                .sortBy(['name'], template => -new Date(template.version))
                .uniqBy('name')
                .value();
        } catch (error) {
            console.error('Error getting offline templates', error);
            return [];
        }
    }

    const latestOfflineTemplates = await getLatestOfflineTemplates();
    const currentTime = new Date();
    const time_8_hours_ago = new Date(currentTime.getTime() - (8 * 60 * 60 * 1000)).toISOString();

    return new Promise<Template[]>((resolve, reject) => {
        const prevBuildDetailsObject: BuildDetailsObject = JSON.parse(localStorage.getItem('contact_offline_prev_template_build_details') || '{}');

        // Use Cache for offline templates if available, build details match and not older than 8 hours
        if (latestOfflineTemplates.length &&
           !!prevBuildDetailsObject &&
           prevBuildDetailsObject.date > time_8_hours_ago &&
           !!prevBuildDetailsObject.hash &&
           prevBuildDetailsObject.hash === window?.buildDetails?.hash) {
            resolve(latestOfflineTemplates);
           } else {
            http.get<Template[]>('/form_templates?latest=true').then((response) => {
                const buildDetailsObject: BuildDetailsObject = { hash: window?.buildDetails?.hash || '', date: currentTime.toISOString() };
                localStorage.setItem('contact_offline_prev_template_build_details', JSON.stringify(buildDetailsObject));
                const templates = response.data;
                // offline templates saved to cache
                templates.forEach((t: Template) =>
                    offlineTemplates.setItem(`${t.id}`, t)
                );
                resolve(templates);
            }).catch((error: any) => {
                console.error('Error getting form templates', error);
                reject(error);
            });
           }
    });
}
