import { createInstanceSafariFix } from '@utility/createInstanceSafariFix'

export const offlineTemplates = createInstanceSafariFix({
  name: 'contact_offline_templates',
  storeName: 'contact_offline_templates',
  version: 1.0
})

export const offlineCustomQuestions = createInstanceSafariFix({
  name: 'contact_offline_custom_questions',
  storeName: 'contact_offline_custom_questions',
  version: 1.0
})

export const offlineConfig = createInstanceSafariFix({
  name: 'contact_offline_config',
  storeName: 'contact_offline_config',
  version: 1.0
}, {
  'work-offline': false
})

export default { offlineTemplates, offlineConfig, offlineCustomQuestions }
