import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import * as NewReport from '@ducks/newReport';
import * as User from '@ducks/user';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  reasonableSuspicion: Form.selectors.reasonableSuspicion(state),
  reasonableSuspicionCode: Form.selectors.reasonableSuspicionCode(state),
  errors: Form.selectors.stepErrors(state),
  ReasonableSuspicion: get(Template.selectors.enumMap(state), 'ReasonableSuspicion.possibleValues', {}),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  flags: Form.selectors.flags(state),
  organizations: Form.selectors.flags(state),
  clipboardCodeFeature: User.selectors.clipboardCodeFeature(state),
});

const mapDispatchToProps = {
  setReasonableSuspicion: (value: string[], personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'reasonableSuspicion'] })(value),
  setReasonableSuspicionCode: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'reasonableSuspicionCode'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
