import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'

export default class HttpClient {
  env: Record<string, any> | undefined;

  defaultHeaders: RawAxiosRequestHeaders;

  constructor(env?: Record<string, any>) {
    this.env = env
    this.defaultHeaders = { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }
  }

  buildUrl(relativeUrl: string) {
    const { protocol, hostname } = window.location
    const apiPort = this.env?.REACT_APP_API_PORT ? `:${this.env.REACT_APP_API_PORT}` : ''
    const baseUrl = `${protocol}//${hostname}${apiPort}/api/v1`

    return relativeUrl.startsWith('/')
      ? baseUrl + relativeUrl
      : `${baseUrl}/${relativeUrl}`
  }

  buildHeaders(headers?: RawAxiosRequestHeaders): RawAxiosRequestHeaders {
    return { ...this.defaultHeaders, ...headers }
  }

  get<R>(relativeUrl: string, headers?: RawAxiosRequestHeaders, config: Partial<AxiosRequestConfig> = {}) {
    return this.makeRequest<R>({ relativeUrl, headers, method: 'get', config })
  }

  post<R>(relativeUrl: string, body?: any, headers?: RawAxiosRequestHeaders) {
    return this.makeRequest<R>({ relativeUrl, headers, body, method: 'post' })
  }

  patch<R>(relativeUrl: string, body: any, headers?: RawAxiosRequestHeaders) {
    return this.makeRequest<R>({ relativeUrl, body, headers, method: 'patch' })
  }

  delete<R=never>(relativeUrl: string, body?: any, headers?: RawAxiosRequestHeaders) {
    return this.makeRequest<R>({ relativeUrl, body, headers, method: 'delete' })
  }

  raw<R>({
    url,
    data = {},
    headers = {},
    method = 'GET',
  }: {
    url: string;
    data?: any;
    headers?: RawAxiosRequestHeaders;
    method?: string;
  }) {
    return axios.request<R>({
      url,
      headers,
      data,
      method
    })
  }

  makeRequest<R>(
    {
      relativeUrl,
      body,
      headers,
      method,
      config,
    }: {
      relativeUrl: string;
      body?: any;
      headers?: RawAxiosRequestHeaders;
      method: string;
      config?: Partial<AxiosRequestConfig>;
    }) {
    return axios.request<R>({
      url: this.buildUrl(relativeUrl),
      headers: this.buildHeaders(headers),
      data: body,
      withCredentials: true,
      method,
      maxRedirects: 0,
      ...config
    })
  }
}
