import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  contrabandOrEvidenceDiscovered: Form.selectors.contrabandOrEvidenceDiscovered(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
});

const mapDispatchToProps = {
  setContrabandOrEvidenceDiscovered: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'contrabandOrEvidenceDiscovered'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  goToNext: () => void;
};
