import React, { Component, Children } from 'react';
import cn from 'classnames';
import dataHash from '@utility/dataHash';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { Column, Loading, ASCENDING_SORT, NO_SORT, DESCENDING_SORT } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import './Table.scss';

interface Props {
  setSort?: ({ column, direction }: any) => void;
  dataQuery?: ({ page, limit }: any) => void;
  pagination?: any;
  setPagination?: ({ pageSize, currentPage }: any) => void;
  children?: any;
  RowComponent?: any;
  rowData?: any;
  emptyMessage?: string;
  loading?: boolean;
  disableOnlineFeatures?: boolean;
  colRefs?: any;
  trainingMode?: any;
}

class Table extends Component<Props> {
  static defaultProps = {
    children: (props: any, propName: any, componentName: any) => {
      const children = props[propName]

      const hasBadType = React.Children
        .toArray(children)
        .find((child: any) => child.type !== Column)

      return hasBadType ? new Error(`${componentName} only accepts "<Column />" elements`) : null
    },
    colRefs: []
  };

  state = {
    activeColumnSortOrder: 0,
    activeColumnSortIndex: 0,
    colData: this.setColData(),
  }

  componentDidMount() {
    window.addEventListener('resize', this.getColWidths);
    setTimeout(this.getColWidths, 1);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getColWidths);
  }

  setColData() {
    const { children } = { ...this.props };
    if (children && children.length > 0) {
      return Children.toArray(children).map(({ props }: any) => ({
        minWidth: props.minWidth,
        grow: props.grow,
        dataKey: props.dataKey,
        sortFn: props.sort
      }))
    }
    return [];
  }

  getColWidths = () => {
    const { colData } = this.state;
    const props = { ...this.props };
    this.setState({ colData: colData.map((d: any, i: number) => ({ ...d, width: props.colRefs?.[i]?.getBoundingClientRect().width ?? 10000 })) });
  }

  onColumnClick = (columnIndex: number) => () => {
    const { activeColumnSortIndex, activeColumnSortOrder, colData } = this.state;
    const { setSort } = this.props;

    // Default case and last return are impossible and untestable
    /* eslint-disable default-case, consistent-return */
    const getNextSortState = (sortOrder: number) => {
      switch (sortOrder) {
        case DESCENDING_SORT:
          return ASCENDING_SORT
        case ASCENDING_SORT:
          return NO_SORT
        case NO_SORT:
          return DESCENDING_SORT
      }
    }
    this.setState(() => {
      if (activeColumnSortIndex === columnIndex) {
        const nextSortOrder = getNextSortState(activeColumnSortOrder);
        const direction = nextSortOrder === ASCENDING_SORT ? 'asc' : nextSortOrder === DESCENDING_SORT ? 'desc' : undefined;
        setSort?.({ column: direction ? colData[activeColumnSortIndex].dataKey : undefined, direction });
        return { activeColumnSortOrder: nextSortOrder };
      }
      setSort?.({ column: colData[columnIndex].dataKey, direction: 'desc' });
      return { activeColumnSortOrder: DESCENDING_SORT, activeColumnSortIndex: columnIndex };
    })
  }

  setPageSize = (pageSize: number) => {
    const { dataQuery, setPagination } = this.props;
    if (setPagination && dataQuery) {
      setPagination({ pageSize, currentPage: 1 });
      dataQuery({ page: 1, limit: pageSize });
    }
  }

  nextPage = () => {
    const { dataQuery, pagination, setPagination } = this.props;
    const { pageSize, currentPage, totalPages } = pagination;

    if (setPagination && dataQuery && totalPages && currentPage && currentPage < totalPages) {
      setPagination({ pageSize, currentPage: currentPage + 1 });
      dataQuery({ page: currentPage + 1, limit: pageSize });
    }
  }

  prevPage = () => {
    const { dataQuery, setPagination, pagination } = this.props;
    const { pageSize, currentPage } = pagination;

    if (setPagination && dataQuery && currentPage && currentPage > 1) {
      setPagination({ pageSize, currentPage: currentPage - 1 });
      dataQuery({ page: currentPage - 1, limit: pageSize });
    }
  }

  setColRef = (ref: any, index: number) => {
    const { colRefs } = { ...this.props };
    if (ref) {
      colRefs[index] = ref;
    }
  }

  render() {
    const { children, RowComponent, rowData, trainingMode, pagination, emptyMessage, loading, disableOnlineFeatures } = this.props;
    const { activeColumnSortOrder, activeColumnSortIndex, colData } = this.state;

    // pagination is optional if it is not provided set default values
    let paginationData = pagination;
    let isPagination = true;
    if (!pagination) {
      paginationData = {
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
        totalPages: 0
      }
      isPagination = false;
    }

    const { totalCount, pageSize, currentPage, totalPages } = paginationData;
    const currentPageIndex = currentPage - 1;
    const { colRefs } = { ...this.props };

    return (
      <div className="table" data-testid="table" role="table">
        <div className="table__column-header" role="columnheader">
          {React.Children.toArray(children).map((child: any, index: number) =>
            child.type === Column ? React.cloneElement(child, {
              onClick: this.onColumnClick(index),
              sortOrder: activeColumnSortIndex === index ? activeColumnSortOrder : NO_SORT,
              setColRef: (r: any) => this.setColRef(r, index),
              disableOnlineFeatures,
              index
            }) : null)}
        </div>
        {loading && <div className="table__loading">
          <Loading />
        </div>}
        {!loading && rowData?.length === 0 && <div className="table__content-empty">
          {emptyMessage}
        </div>}
        {!loading && rowData?.length > 0 && <div className="table__content">
          {rowData.map((data: any, rowIndex: number) => RowComponent ?
            <RowComponent key={`Table-Row-${data.id}`} colData={colData} colRef={colRefs} rowData={data} rowIndex={rowIndex} />
            :
            <div data-testid="table-text-row" key={`Table-Row-${dataHash(data)}`}>
              {JSON.stringify(data)}
            </div>
          )}
        </div>}
        {isPagination && rowData && <div className="table__pagination">
          <FormControl className="table__pagination-page-size" variant="outlined" size="small">
            <Select
              value={pageSize}
              onChange={({ target: { value } }: any) => this.setPageSize(value)}
              data-testid="table-pagination-page-size"
            >
              <MenuItem value="10" data-testid="table-pagination-page-size-menu-item-10">
                10 Per Page
              </MenuItem>
              <MenuItem value="50" data-testid="table-pagination-page-size-menu-item-50">
                50 Per Page
              </MenuItem>
              <MenuItem value="100" data-testid="table-pagination-page-size-menu-item-100">
                100 Per Page
              </MenuItem>
            </Select>
          </FormControl>
          <div className="table__pagination-page-selector">
            <div
              tabIndex={0}
              role="button"
              className={cn('table__pagination-page-selector-back material-icons', { enabled: currentPageIndex > 0 })}
              aria-label="Previous page"
              data-testid="table-pagination-page-selector-back"
              onClick={this.prevPage}
              onKeyUp={onEnter(this.prevPage)}
            >
              chevron_left
            </div>
            <div className="table__pagination-page-selector-text" data-testid="table-pagination-page-selector-text">
              <b>{`${trainingMode ? 0 : totalCount === 0 ? 0 : currentPageIndex * pageSize + 1} - ${trainingMode ? 0 : (currentPageIndex + 1) * pageSize > totalCount ? totalCount : (currentPageIndex + 1) * pageSize} `}</b>
              {` of ${trainingMode ? 0 : totalCount}`}
            </div>
            <div
              tabIndex={0}
              role="button"
              aria-label="Next page"
              className={cn('table__pagination-page-selector-next material-icons', { enabled: currentPage < totalPages })}
              data-testid="table-pagination-page-selector-next"
              onClick={this.nextPage}
              onKeyUp={onEnter(this.nextPage)}
            >
              chevron_right
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default Table;
