import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import { Snackbar, AlertLevel, createSnackNotification } from '@components/common/Snackbar/Snackbar';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { VeritoneColorSvg } from '@components/svg';
import { Button } from '@components/common';
import getOnlineTimeoutByEffectiveType from '@utility/getOnlineTimeoutByEffectiveType';
import { onEnter } from '@utility/keypressHelpers';
import { offlineTemplates, offlineConfig } from '@engine/dependencies/localforage';
import noElasticScroll from 'inobounce';
import * as Config from '@ducks/config';
import * as User from '@ducks/user';
import { DispatchThunk } from '@ducks/types';
import Default_BG from './Default_BG.png';
import Default_Logo from './Default_Logo.png';
import './Login.scss';

export const Login = ({ sendUserEmail, login, loginError, errorMessage, loginBackground, largeLogo, getOrganization, online, workOffline, checkOnlineStatus, workOnline }: Props) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [passResetClicked, setPassResetClicked] = useState(false);
  const [moreInstructionsClicked, setMoreInstructionsClicked] = useState(false);
  const [canGoOffline, setCanGoOffline] = useState(false);

  useEffect(() => {
    const runSetCanGoOffline = async () => {
      const user = await offlineConfig.getItem('user').then((u: any) => u !== null);
      const templates = await offlineTemplates.length().then((t: any) => (t ?? 0) > 0);
      setCanGoOffline(user && templates);
    };

    workOnline(undefined);
    checkOnlineStatus(undefined);
    getOrganization(false);
    runSetCanGoOffline();
  }, [online]);

  useEffect(() => {
    const checkOnlineInterval = setInterval(() => {
      if (!online) {
        checkOnlineStatus(undefined);
      }
    }, getOnlineTimeoutByEffectiveType());

    /* Disable 'no elastic scroll' on Safari iOS */
    noElasticScroll.disable();
    return () => {
      /* Enable 'no elastic scroll' on Safari iOS */
      noElasticScroll.enable();
      clearInterval(checkOnlineInterval);
    };
  }, []);

  return (
    <div className="login" data-testid="login">
      <div className="login__header">
        <div className="login__header-title">CONTACT</div>
        <div className="login__header-logo" />
      </div>
      <div className="login__bg">
        <img src={loginBackground || Default_BG} alt="Respresentation of the police department" />
      </div>
      {!passResetClicked && !moreInstructionsClicked && (
        <div className="login__dialog" data-testid="login-dialog">
          <div className="login__title_logo_container">
            <div className="login__title">CONTACT</div>
            <div className="login__line" />
            <div className="login__logo">
              <img src={largeLogo || Default_Logo} alt="Police Department logo" />
            </div>
          </div>
          <div className={cn('login__login-error', { 'has-error': loginError })} data-testid="login-login-error">
            {errorMessage}
          </div>
          <form
            autoComplete="on"
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              if (online) {
                login({ username, password });
              } else if (canGoOffline) {
                workOffline(undefined);
              } else {
                createSnackNotification(AlertLevel.Warning, 'Cannot go offline', 'Offline mode requires a successful login');
              }
            }}
          >
            <div className="login__username-label">
              <div className="login__username-label-text">Username</div>
              <div className="login__username-label-required">REQUIRED</div>
            </div>
            <TextField
              className="login__username-field"
              variant="outlined"
              autoComplete="username"
              type="text"
              disabled={!online}
              onChange={({ target: { value } }) => setUsername(value)}
              inputProps={{ 'data-testid': 'login-username-field' }}
            />
            <div className="login__pw-label">
              <div className="login__pw-label-text">Password</div>
              <div className="login__pw-label-required">REQUIRED</div>
            </div>
            <TextField
              className="login__pw-field"
              variant="outlined"
              type="password"
              autoComplete="current-password"
              disabled={!online}
              onChange={({ target: { value } }) => setPassword(value)}
              inputProps={{ 'data-testid': 'login-pw-field' }}
            />
              <div
                className="login__forgot-pw"
                data-testid="login-forgot-pw"
                role="button"
                tabIndex={0}
                onClick={() => {
                  setUsername('');
                  setPassword('');
                  setPassResetClicked(true);
                }}
                onKeyUp={onEnter(() => {
                  setUsername('');
                  setPassword('');
                  setPassResetClicked(true);
                })}
              >
                Forgot your password?
              </div>
            {!online && !canGoOffline && (
              <div className="login__need-login" data-testid="login__need-login">
                Offline mode requires a successful login
              </div>
            )}
            <input className={cn('login__submit', { disabled: !online && !canGoOffline })} data-testid="login-submit" type="submit" value={online ? 'LOGIN' : 'CONTINUE OFFLINE'} />
          </form>
        </div>
      )}
      {passResetClicked && !moreInstructionsClicked && (
        <div className="login__pass-reset-dialog" data-testid="login-pass-reset-dialog">
          <div
            className="login__pass-reset-back-to-login"
            data-testid="login-pass-reset-back-to-login"
            role="button"
            tabIndex={0}
            onClick={() => {
              setUsername('');
              setPassResetClicked(false);
            }}
            onKeyUp={onEnter(() => {
              setUsername('');
              setPassResetClicked(false);
            })}
          >
            <div className="material-icons">navigate_before</div>
            <div className="login__pass-reset-back-to-login-text">LOGIN</div>
          </div>
          <div className="login__pass-reset-title">Reset Password</div>
          <div className="login__pass-reset-description">
            {/* Enter your registered email or username, and we&apos;ll send an email with instructions to reset your
          password. */}
            Enter your registered email and we&apos;ll send an email with instructions to reset your password.
          </div>
          <div className="login__pass-reset-username-label">
            <div className="login__pass-reset-username-label-text">
              {/* Email or Username */}
              Email
            </div>
            <div className="login__pass-reset-username-label-required">REQUIRED</div>
          </div>
          <TextField
            className="login__pass-reset-username-field"
            variant="outlined"
            autoComplete="email"
            type="text"
            onChange={({ target: { value } }) => setEmail(value)}
            onKeyUp={onEnter(() => {
              if (email.length) {
                sendUserEmail({ email });
                setPassResetClicked(false);
                setMoreInstructionsClicked(true);
              } else {
                createSnackNotification(AlertLevel.Error, 'Error', 'Email field must be populated');
              }
            })}
            inputProps={{
              'data-testid': 'login-pass-reset-username-field',
            }}
          />
          <Button
            className="login__pass-reset-submit"
            data-testid="login-pass-reset-submit"
            onClick={() => {
              if (email.length) {
                sendUserEmail({ email });
                setPassResetClicked(false);
                setMoreInstructionsClicked(true);
              } else {
                createSnackNotification(AlertLevel.Error, 'Error', 'Email field must be populated');
              }
            }}
          >
            SEND INSTRUCTIONS
          </Button>
        </div>
      )}
      {!passResetClicked && moreInstructionsClicked && (
        <div className="login__more-instructions-dialog" data-testid="login-more-instructions-dialog">
          <div
            className="login__pass-reset-back-to-login"
            data-testid="login-pass-reset-back-to-login"
            role="button"
            tabIndex={0}
            onClick={() => {
              setUsername('');
              setMoreInstructionsClicked(false);
            }}
            onKeyUp={onEnter(() => {
              setUsername('');
              setMoreInstructionsClicked(false);
            })}
          >
            <div className="material-icons">navigate_before</div>
            <div className="login__pass-reset-back-to-login-text">LOGIN</div>
          </div>
          <div className="login__more-instructions-title">Check Your Email</div>
          <div className="login__more-instructions-description">
            We have sent password recovery instructions to the provided email address. Didn&apos;t receive an email? Check your spam folder or click below to resend recovery email.
          </div>
          <Button
            className="login__more-instructions-resubmit"
            data-testid="login-more-instructions-resubmit"
            onClick={() => {
              sendUserEmail({ email });
            }}
          >
            RESEND EMAIL
          </Button>
        </div>
      )}
      <div className="login__bs">
        <div className="login__bs-text">&#169; 2024 Veritone, Inc. All Rights Reserved.</div>
        <div className="login__bs-powered-by">POWERED BY</div>
        <div className="login__bs-vtn">
          <VeritoneColorSvg />
        </div>
      </div>
      <Snackbar className="login__snackbar" timeout={5000} />
    </div>
  );
};

interface Props {
  loginError: boolean;
  largeLogo: string;
  loginBackground: string;
  errorMessage: string;
  online: boolean;

  workOffline: DispatchThunk<typeof User.workOffline>;
  checkOnlineStatus: DispatchThunk<typeof Config.checkOnlineStatus>;
  workOnline: DispatchThunk<typeof User.workOnline>;
  sendUserEmail: DispatchThunk<typeof User.sendUserEmail>;
  getOrganization: DispatchThunk<typeof User.getOrganization>;
  login: DispatchThunk<typeof User.login>;
}

const mapDispatchToProps = {
  login: User.login,
  getOrganization: User.getOrganization,
  workOffline: User.workOffline,
  checkOnlineStatus: Config.checkOnlineStatus,
  workOnline: User.workOnline,
  sendUserEmail: User.sendUserEmail,
};

const mapStateToProps = (state: any) => ({
  loginError: User.selectors.loginError(state),
  largeLogo: User.selectors.largeLogo(state),
  loginBackground: User.selectors.loginBackground(state),
  online: Config.selectors.online(state),
  errorMessage: User.selectors.errorMessage(state),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Login);
