import React from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './UsersTableSelectColumn.scss';

export const UsersTableSelectColumn = ({ selectAllChecked, selectAllUsersRows }: Props) => {
  const selectAll = () => {
    selectAllUsersRows();
    window.dispatchEvent(new CustomEvent('select-all-users-rows', { detail: { selectAllChecked: !selectAllChecked } }));
  };

  return (
    <div className="users-table-select-column">
      <div
        role="button"
        tabIndex={0}
        className={cn('users-table-select-column__cell-select-checkbox', { checked: selectAllChecked })}
        data-testid="users-table-select-column-cell-select-checkbox"
        onClick={selectAll}
        onKeyUp={onEnter(selectAll)}
      >
        <div className="material-icons">done</div>
      </div>
    </div>
  );
};

export default connector(UsersTableSelectColumn);
