import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import { TemplateName2024, checkAfter2024 } from '@engine/ducks/constants';
import { AlertLevel, createSnackNotification } from '@components/common';
import './RipaCalendarForm.scss'
import { User } from '@engine/ducks';

dayjs.extend(customParseFormat)
dayjs.extend(minMax)

/* eslint-disable no-empty */

interface Props extends PropsFromRedux {
  checkValidation: any;
  setDisableContinue: (disableContinue: boolean) => void;
}

const RipaCalendarForm = ({
  stopDate,
  setStopDateAndRun2024Conditions,
  createdAt,
  offlineCreatedAt,
  errors,
  checkValidation,
  templateName,
  setDisableContinue,
}: Props) => {
  const actualCreatedAt = offlineCreatedAt || createdAt;

  useEffect(() => {
    if (checkAfter2024(stopDate) && templateName !== TemplateName2024) {
      createSnackNotification(AlertLevel.Warning, 'Cannot load template', 'Organization is not provisioned for 2024 forms. Please Contact Veritone.');
    }
  }, [stopDate, templateName])

  useEffect(() => {
    try {
      setDisableContinue(true);
      setStopDateAndRun2024Conditions({ stopDateValue: dayjs(stopDate).toISOString(), setDisableContinue });
    } catch (e) { }
  }, [])

  return (
    <div className="ripa-calendar-form" data-testid="ripa-calendar-form">
      <div className="ripa-calendar-form__title">
        What day did the stop happen?
      </div>
      <div className="ripa-calendar-form__date-picker-group">
        <div className="ripa-calendar-form__date-picker-text-line" />
        <KeyboardDatePicker
          className="ripa-calendar-form__date-picker"
          disableToolbar
          disableFuture
          variant="dialog"
          format="MM/dd/yyyy"
          inputProps={{
            inputMode: 'numeric',
            'data-testid': 'ripa-calendar-form-date-picker'
          }}
          onFocus={(e) => e.target.select()}
          margin="normal"
          id="date-picker-inline"
          error={errors?.stopDateTime ? errors.stopDateTime > 0 : false}
          minDate={dayjs().subtract(1, 'year')
            .month(0)
            .date(1)
            .hour(0)
            .minute(0)}
          maxDate={dayjs(actualCreatedAt)}
          keyboardIcon={<div className="material-icons" data-testid="ripa-calendar-form-change-date">date_range</div>}
          inputVariant="outlined"
          value={dayjs(stopDate)}
          onChange={(v) => {
            try {
              setDisableContinue(true);
              setStopDateAndRun2024Conditions({ stopDateValue: dayjs(v).toISOString(), setDisableContinue });
            } catch (e) { }
          }}
          autoOk={true}
          showTodayButton={true}
          onBlur={() => checkValidation()}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>
    </div>)
};

const mapStateToProps = (state: any) => ({
  stopDate: Form.selectors.stopDateTime(state),
  errors: Form.selectors.stepErrors(state),
  templateName: Template.selectors.name(state),
  pre2024: User.selectors.pre2024(state),
  createdAt: Form.selectors.createdAt(state),
  offlineCreatedAt: Form.selectors.offlineCreatedAt(state)
})

const mapDispatchToProps = {
  setStopDate: Form.setFormField({ path: ['stopDateTime'] }),
  setPost2024Flag: Form.setFlags,
  updateSteps: Form.updateSteps,
  checkValidation: Form.checkValidation,
  resetForm: Form.resetForm,
  setStopDateAndRun2024Conditions: Form.setStopDateAndRun2024Conditions
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaCalendarForm);
