import { dispatchCustomEvent } from '@utility/customEvent'

export function decoupledDispatch(dispatchFn, params, params2) {
  dispatchCustomEvent('decoupled-dispatch', { dispatchFn, params, params2 });
}

window.decoupledDispatch = decoupledDispatch;

window.allowJsonDialog = () => decoupledDispatch('CustomQuestions.setAllowJsonDialogButton', true);

window.submitForReview = () => decoupledDispatch('Form.submitFormForReview');
