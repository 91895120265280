import React, { useEffect } from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Tooltip } from '@material-ui/core';
import { Props, connector } from './props';
import './UsersRow.scss';

export const UsersRow = ({ colData, rowData, rowIndex, checkUsersRow, checkedUsersRows, setEditUserDialogOpen, userId, setDisableUserDialogOpen }: Props) => {
  const { id, disabled } = rowData;
  const onCheck = (e: any) => {
    checkUsersRow({ id, checked: e?.detail?.selectAllChecked ?? !checkedUsersRows?.includes(id) });
  };
  useEffect(() => {
    window.addEventListener('select-all-users-rows', onCheck);

    return () => {
      window.removeEventListener('select-all-users-rows', onCheck);
    };
  }, []);

  /* eslint-disable default-case, consistent-return */
  const renderCell = ({ dataKey }: { dataKey: string }) => {
    switch (dataKey) {
      case 'select':
        return (
          <div className="users-row__cell-select">
            <div
              className={cn('users-row__cell-select-checkbox', { checked: checkedUsersRows?.includes(id) })}
              data-testid="users-row-cell-select-checkbox"
              role="button"
              tabIndex={0}
              aria-label="Check this row"
              onClick={onCheck}
              onKeyUp={onEnter(onCheck)}
            >
              <div className="material-icons">done</div>
            </div>
          </div>
        );
      case 'name':
        return (
          <div role="cell" className="users-row__cell-name">
            <strong>{rowData[dataKey]}</strong>
          </div>
        );
      case 'orgId':
        if (!rowData[dataKey]) {
          return <></>
        }
        return (
          <div role="cell" className="users-row__cell-org-id">
            <strong>{rowData[dataKey]}</strong>
          </div>
        );
      case 'orgName':
        if (!rowData[dataKey]) {
          return <></>
        }
        return (
          <div role="cell" className="users-row__cell-org-name">
            <strong>{rowData[dataKey]}</strong>
          </div>
        );
      case 'username':
        return (
          <div role="cell" className="users-row__cell-username">
            <strong>{rowData[dataKey]}</strong>
          </div>
        );
      case 'officerId':
        return (
          <div role="cell" className="users-row__cell-officer-id">
            {rowData?.[dataKey]}
          </div>
        );
      case 'reviewers':
        return (
          <Tooltip title={`${rowData?.[dataKey]?.map((d: any) => d?.name)?.join(', ')}`}>
            <div role="cell" className="users-row__cell-reviewer">
              {`${rowData?.[dataKey]?.last()?.name ?? 'None'} ${rowData?.[dataKey]?.length > 1 ? ` +${rowData?.[dataKey]?.length - 1}` : ''}`}
            </div>
          </Tooltip>
        );
      case 'roles':
        return (
          <div role="cell" className="users-row__cell-roles">
            {rowData?.[dataKey]?.map((role: any, index: number) => (
              <div
                key={`UsersRowRole-${id}-${rowIndex}-${index}-${dataKey}`}
                className={cn('users-row__cell-roles-role', {
                  'officer-role': role === 'officer',
                  'reviewer-role': role === 'reviewer',
                  'admin-role': role === 'super admin', // This is SuperAdmin(0) role, REMAPPED IN users.js
                  'org-role': role === 'admin', // This is Admin(3) role, REMAPPED IN users.js
                  'analyst-role': role === 'analyst', // This is Analyst(4) role, REMAPPED in users.js
                })}
              >
                {role}
              </div>
            ))}
          </div>
        );
      case 'status':
        return (
          <div role="cell" className="users-row__cell-status">
            {disabled ? 'Disabled' : 'Active'}
          </div>
        );
      case 'created':
        return (
          <div role="cell" className="users-row__cell-created">
            {rowData[dataKey]?.format?.('MM/DD/YYYY h:mm A')}
          </div>
        );
      case 'actions':
        return (
          <div role="cell" className="users-row__cell-actions">
            <Tooltip title="Edit user">
              <div
                className="users-row__table-cell-actions-edit"
                data-testid={`users-row-table-cell-actions-edit-${id}`}
                role="button"
                tabIndex={0}
                aria-label="Edit this user"
                onClick={() => setEditUserDialogOpen({ open: true, id })}
                onKeyUp={onEnter(() => setEditUserDialogOpen({ open: true, id }))}
              >
                <div className="material-icons"> edit </div>
              </div>
            </Tooltip>
            <Tooltip title="Disable User">
              <div
                className={cn('users-row__table-cell-actions-cancel material-icons', { disabled: userId === id || disabled })}
                role="button"
                tabIndex={0}
                data-testid="users-row-table-cell-deny"
                aria-label="Disable this user"
                onClick={() => setDisableUserDialogOpen({ open: true, id })}
                onKeyUp={onEnter(() => setDisableUserDialogOpen({ open: true, id }))}
              >
                remove_circle
              </div>
            </Tooltip>
          </div>
        );
    }
  };

  return (
    <div role="row" className={cn('users-row', { checked: checkedUsersRows?.includes(id) })} data-testid={`users-row-${rowIndex}-${rowData.id}`}>
      <div className={cn('users-row__row', { checked: checkedUsersRows?.includes(id) })} data-testid="users-row-row">
        {colData.map(({ grow, dataKey, width, minWidth }, index) => (
          <div
            className="users-row__cell"
            data-testid={`users-row-cell-${rowIndex}-${id}-${index}`}
            key={`UsersRowCell-${id}-${rowIndex}-${index}-${dataKey}`}
            style={{
              flexGrow: grow,
              maxWidth: width,
              minWidth,
            }}
          >
            {renderCell({ dataKey })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default connector(UsersRow);
