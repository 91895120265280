import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { connect, ConnectedProps } from 'react-redux';
import * as Dashboard from '@ducks/dashboard';
import { Status, StatusString } from '@ducks/constants';
import { offlineConfig } from '@engine/dependencies/localforage';
import { assignRoute } from '@utility/assignRoute';
import './ReportRow.scss';
import { Tooltip } from '@material-ui/core';
import { ColRef, ColumnData } from '@engine/ducks/types';

interface Props extends PropsFromRedux {
  colData: ColumnData[];
  rowData: any;
  colRef: ColRef[];
  rowIndex: number;
  key: string;
}

export const ReportRow = ({ colData, rowData, colRef, deleteForm, rowIndex, setReviewFeedbackDialogOpen, showOffline, key }: Props) => {
  const { status, offlineStatus } = rowData;
  const [isWorkingOffline, setIsWorkingOffline] = useState(false);
  const showOfflineFeatures = isWorkingOffline || showOffline;

  async function loadingWorkOfflineStatus() {
    // IndexedDB observers are currently experimental
    const workOfflineStatus = await offlineConfig.getItem('work-offline')
      .then(wo => String(wo) === 'true');
    setIsWorkingOffline(workOfflineStatus);
  }

  useEffect(() => {
    loadingWorkOfflineStatus();
  })

  /* eslint-disable default-case, consistent-return */
  const renderCell = ({ dataKey }: any) => {
    switch (dataKey) {
      case 'location':
        return <div role="cell" className={cn('report-row__cell-location', { pending: !rowData[dataKey] })}>
          <strong>{rowData[dataKey] || 'Pending'}</strong>
        </div>
      case 'assignment':
        return <div role="cell" className="report-row__cell-assignment">
          {rowData?.[dataKey]}
        </div>
      case 'district':
        return <div role="cell" className={cn('report-row__cell-district', { pending: !rowData[dataKey] })}>
          {rowData?.[dataKey] || 'Pending'}
        </div>
      case 'beat':
        return <div role="cell" className={cn('report-row__cell-beat', { pending: !rowData[dataKey] })}>
          {rowData?.[dataKey] || 'Pending'}
        </div>
      case 'time':
        return <div role="cell" className="report-row__cell-time">
          {rowData[dataKey]?.format('MM/DD/YYYY h:mm A')}
        </div>
      case 'people':
        return <div role="cell" className="report-row__cell-people">
          {rowData?.[dataKey]}
        </div>
      case 'progress':
        return <div
          role="cell"
          className={cn('report-row__cell-progress', {
            red: rowData[dataKey] < 50,
            orange: rowData[dataKey] < 80 && rowData[dataKey] >= 50,
            green: rowData[dataKey] >= 80
          })}
        >
          {`${rowData[dataKey]}%`}
        </div>
      case 'status':
        return <div
          role="cell"
          className={cn('report-row__cell-status', {
            offline: status === Status.Offline,
            draft: status === Status.Draft,
            under_review: status === Status.UnderReview,
            approved: status === Status.Approved,
            rejected: status === Status.Rejected,
            denied: status === Status.DeniedByDOJ,
            submitted: status === Status.SubmittedToDOJ || status === Status.SubmittingToDOJ,
            syncing: offlineStatus === Status.Syncing,
            synced: offlineStatus === Status.Synced,
            syncfailed: offlineStatus === Status.SyncFailed
          })}>
          {showOfflineFeatures ? StatusString?.[offlineStatus] : StatusString?.[status]}
        </div>
      case 'actions':
        return <div role="cell" className="report-row__cell-actions">
          {([Status.Draft].includes(status) || offlineStatus === Status.Synced) && <div
            className={cn('dashboard__table-cell-actions-cancel material-icons', { disabled: offlineStatus === Status.Syncing })}
            role="button"
            tabIndex={0}
            data-testid="dashboard-table-cell-delete"
            aria-label="Delete this report"
            onClick={() => deleteForm(rowData.id)}
            onKeyUp={onEnter(() => deleteForm(rowData.id))}
          >
            cancel
          </div>}
          {[Status.Rejected].includes(status) &&
            <Tooltip title="View Changes Requested">
              <div
                className="dashboard__table-cell-actions-rejected-dialog material-icons"
                role="button"
                tabIndex={0}
                data-testid="dashboard-table-cell-rejected-dialog"
                aria-label="Show rejection notes"
                onClick={() => setReviewFeedbackDialogOpen({ open: true, formId: rowData.id })}
                onKeyUp={onEnter(() => setReviewFeedbackDialogOpen({ open: true, formId: rowData.id }))}
              >
                priority_high
              </div>
            </Tooltip>
          }
          {[Status.Draft, Status.Rejected].includes(status) &&
            ![Status.Synced].includes(offlineStatus) &&
            <div
              className={cn('dashboard__table-cell-actions-continue', { disabled: offlineStatus === Status.Syncing })}
              data-testid="dashboard-table-cell-actions-continue"
              role="button"
              tabIndex={0}
              aria-label="Continue to work on this report"
              onClick={() => assignRoute(`/new-report/${rowData.id}`)}
              onKeyUp={onEnter(() => assignRoute(`/new-report/${rowData.id}`))}
            >
              CONTINUE
            </div>}
          {[Status.Draft, Status.Offline, Status.Rejected].includes(status) &&
            ![Status.Synced].includes(offlineStatus) &&
            <div
              className={cn('dashboard__table-cell-actions-continue-small', { disabled: offlineStatus === Status.Syncing })}
              data-testid="dashboard-table-cell-actions-continue-small"
              role="button"
              tabIndex={0}
              aria-label="Continue to work on this report"
              onClick={() => assignRoute(`/new-report/${rowData.id}`)}
              onKeyUp={onEnter(() => assignRoute(`/new-report/${rowData.id}`))}
            >
              <div className="material-icons">
                edit
              </div>
            </div>}
        </div>
    }
  }
  return (
    <div
      role="rowgroup"
      className={cn('report-row', {
        error: status === Status.Rejected || offlineStatus === Status.SyncFailed,
        syncing: offlineStatus === Status.Syncing,
        testcase2024: !!rowData?.testcase2024
      })}
      data-testid={`report-row-${rowIndex}-${rowData.id}`}
      key={key}
    >
      <div
        role="row"
        className="report-row__row"
        data-testid="report-row-row"
        tabIndex={0}
      >
        {colData.map(({ grow, dataKey, width, minWidth }: ColumnData, index: number) =>
          <div
            className="report-row__cell"
            data-testid={`report-row-cell-${rowIndex}-${rowData.id}-${index}`}
            key={`ReportRowCell-${rowData.id}-${index}`}
            style={{
              flexGrow: grow,
              maxWidth: colRef[index] ? colRef[index].clientWidth : width,
              minWidth
            }}>
            {renderCell({ dataKey })}
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  deleteForm: Dashboard.deleteForm,
  setReviewFeedbackDialogOpen: Dashboard.setReviewFeedbackDialogOpen
}

const mapStateToProps = (state: any) => ({
  showOffline: Dashboard.selectors.showOffline(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportRow);
