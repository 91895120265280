/* eslint-disable object-property-newline */

import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { path as rPath, pathOr as rPathOr, assocPath, remove, assoc, map, range } from 'ramda'
import { set, get } from 'lodash';
import { createSnackNotification, AlertLevel } from '@components/common'
import { decoupledDispatch } from '@utility/decoupledDispatch'
import { assignRoute } from '@utility/assignRoute'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import customQuestionValidation from '@utility/customQuestionValidation'
import getFormTemplatesCached from '@utility/getFormTemplatesCached';
import migrateForm from '@utility/migrateForm'
import sleep from '@utility/sleep'
import { userSettingsErrorMessage } from '@utility/userSettingsErrorMessage';
import StepsFunction from '@pages/NewReport/Steps'
import { NewReportNamespace, FormNamespace, Status, TemplateNamespace, UserNamespace, isAfter2024, LocationType } from './constants'
import { selectors as UserSelectors } from './user';
import {
  Action, Dispatch, GetState, Form, Person, HistoryRecord, HasError, ThunkDeps, Step, StepErrors,
  Snack, EnumMap, Template, CustomQuestionSections, Substep, CustomQuestion, ArrayValueKeys, ServerCustomQuestions, ServerForm
} from './types';

export const SET_FORM_FIELD = `${FormNamespace}/SET_FORM_FIELD`
export const TOGGLE_RACE = `${FormNamespace}/TOGGLE_RACE`
export const TOGGLE_DISABILITY = `${FormNamespace}/TOGGLE_DISABILITY`
export const TOGGLE_ACTION_TAKEN = `${FormNamespace}/TOGGLE_ACTION_TAKEN`
export const TOGGLE_SEARCH_BASIS = `${FormNamespace}/TOGGLE_SEARCH_BASIS`
export const TOGGLE_REASON_GIVEN_STOPPED_PERSON = `${FormNamespace}/TOGGLE_REASON_GIVEN_STOPPED_PERSON`
export const TOGGLE_SEIZED_PROPERTY_BASIS = `${FormNamespace}/TOGGLE_SEIZED_PROPERTY_BASIS`
export const TOGGLE_SEIZED_PROPERTY_TYPE = `${FormNamespace}/TOGGLE_SEIZED_PROPERTY_TYPE`
export const TOGGLE_REASONABLE_SUSPICION = `${FormNamespace}/TOGGLE_REASONABLE_SUSPICION`
export const TOGGLE_CONTRABAND = `${FormNamespace}/TOGGLE_CONTRABAND`
export const TOGGLE_RESULT_OF_STOP = `${FormNamespace}/TOGGLE_RESULT_OF_STOP`
export const SET_FORM_LOADING = `${FormNamespace}/SET_FORM_LOADING`
export const MERGE_FORM = `${FormNamespace}/MERGE_FORM`
export const SET_USE_SAME_FOR_ALL_PERSON = `${FormNamespace}/SET_USE_SAME_FOR_ALL_PERSON`
export const SET_RECALL_STEP = `${FormNamespace}/SET_RECALL_STEP`
export const SUBMITTING_FOR_REVIEW = `${FormNamespace}/SUBMITTING_FOR_REVIEW`
export const SET_VALIDATION_ERRORS = `${FormNamespace}/SET_VALIDATION_ERRORS`
export const ADD_OFFICER_TIME_SPENT = `${FormNamespace}/ADD_OFFICER_TIME_SPENT`
export const ADD_OFFICER_TIME_SPENT_CUSTOM = `${FormNamespace}/ADD_OFFICER_TIME_SPENT_CUSTOM`
export const RESET_INITIAL_STATE = `${FormNamespace}/RESET_INITIAL_STATE`
export const RESET_FORM_DATA = `${FormNamespace}/RESET_FORM_DATA`
export const SET_FLAGS = `${FormNamespace}/SET_FLAGS`

export const getInitialPerson = (): Person => ({
  label: '',
  gender: '',
  nonConforming: false,
  age: 0,
  student: false,
  race: [],
  lgbt: false,
  sexualOrientation: '',
  english: false,
  disabled: false,
  unhoused: false,
  disabilities: [],
  actionTaken: [],
  actionTakenBool: true,
  consentGiven: {
    'search person': false,
    'search property': false,
  },
  consentType: '',
  searchBasis: [],
  searchDescription: '',
  seizedPropertyBasis: [],
  seizedPropertyType: [],
  anyContrabandFound: true,
  anyResultOfStop: true,
  contraband: [],
  resultOfStop: [],
  resultOfStopCodeSection: {},
  contrabandOrEvidenceDiscovered: true,
  primaryReason: '',
  reasonGivenStoppedPerson: [],
  trafficViolation: '',
  trafficViolationCode: '',
  reasonableSuspicion: [],
  reasonableSuspicionCode: '',
  probableCause: [],
  probableCauseCode: '',
  codeSection48900: '',
  codeSection48900Subdivision: '',
  stopDescription: '',
  stoppedPassenger: false,
  stoppedInsideResidence: false,
});

export const getInitialState = ({ pre2024, post2024, is2024Test }: { pre2024: boolean; post2024: boolean; is2024Test: boolean }): Form => ({
  ...(is2024Test ? { testCaseId: 'TC#' } : {}),
  debug: {
    creation: {
      buildDetails: {},
      offlineCount: 0,
      onlineCount: 0
    },
    reset: {
      buildDetails: {},
      offlineCount: 0,
      onlineCount: 0
    },
    update: {
      buildDetails: {},
      offlineCount: 0,
      onlineCount: 0
    },
    submitForReview: {
      buildDetails: {},
      offlineCount: 0,
      onlineCount: 0
    },
  },
  flags: {
    pre2024,
    post2024,
    is2024Test
  },
  version: '1',
  id: '',
  status: '',
  recallStep: {
    maxStepReached: 1,
    currentStep: 0,
    currentSubstep: 0,
    history: [],
  },
  progress: 0,
  yearsOfExperience: 1,
  raceOfOfficer: [''],
  assignment: '', // Default to patrol,
  assignmentDescription: '',
  otherAssignment: '',
  typeOfAssignmentOfficer: '',
  stopDateTime: dayjs().toISOString(),
  responseToCall: null,
  officerWorksWithNonPrimaryAgency: null,
  stopDuringWellnessCheck: null,
  duration: '',
  isCustomDuration: false,
  numberOfPeople: 1,
  locationType: '',
  schoolAddress: '',
  otherLocation: '',
  gpsAddress: '',
  location: '',
  city: '',
  school: '',
  block: null,
  street: '',
  crossStreet1: '',
  crossStreet2: '',
  highway: '',
  exit: '',
  stopHappenedAtPublicSchool: false,
  useSameRaceForAll: false, // Take the race of the first person
  useSameGenderForAll: false, // Take the gender of the first person
  useSamePrimaryReasonForAll: false, // Take the primaryReason of the first person
  useSameActionTakenForAll: false, // Take the useSameActionForAllPersonIndex's actions
  useSameActionForAllPersonIndex: -1, // Take useSameActionForAllPersonIndex's actions if useSameActionTakenForAll
  person: [getInitialPerson()],
  validation: {
    stepErrors: {},
  },
  officerTimeSpent: 0,
  officerTimeSpentCustom: 0,
  reviewerTimeSpent: 0,
  reviewerId: -1,
  coordinates: {
    latitude: -1,
    longitude: -1,
    accuracy: -1
  },
  custom: {},
  typeOfStop: '',
  offlineCreatedAt: '',
  createdAt: ''
});

export const reducer = (state = getInitialState({ pre2024: false, post2024: false, is2024Test: false }), action: Action<any>) => {
  const { type, payload } = action;

  const updatePersonIndexedFormFieldArray = (formField: ArrayValueKeys<Person>) => {
    // This just toggles on/off a field in a 2d array (arrays within the person array)
    const { personIndex, value }: { personIndex: number; value: any } = payload;

    const rIndex = (state.person?.[personIndex]?.[formField])?.findIndex(v => v === value);

    if (rIndex === -1) {
      return assocPath(['person', personIndex, formField], [...state.person[personIndex][formField], value], state);
    }
    if (rIndex === undefined) {
      return assocPath(['person', personIndex, formField], [value], state);
    }
    return assocPath(['person', personIndex, formField], remove(rIndex, 1, state.person[personIndex][formField]), state);
  };

  switch (type) {
    case SET_FORM_FIELD:
      return assocPath(payload.path, payload.value, state);
    case MERGE_FORM:
      return { ...state, ...payload.form };
    case TOGGLE_RACE:
      return updatePersonIndexedFormFieldArray('race');
    case TOGGLE_DISABILITY:
      return updatePersonIndexedFormFieldArray('disabilities');
    case TOGGLE_ACTION_TAKEN:
      return updatePersonIndexedFormFieldArray('actionTaken');
    case TOGGLE_SEARCH_BASIS:
      return updatePersonIndexedFormFieldArray('searchBasis');
    case TOGGLE_REASON_GIVEN_STOPPED_PERSON:
      return updatePersonIndexedFormFieldArray('reasonGivenStoppedPerson');
    case TOGGLE_SEIZED_PROPERTY_BASIS:
      return updatePersonIndexedFormFieldArray('seizedPropertyBasis');
    case TOGGLE_SEIZED_PROPERTY_TYPE:
      return updatePersonIndexedFormFieldArray('seizedPropertyType');
    case TOGGLE_CONTRABAND:
      return updatePersonIndexedFormFieldArray('contraband');
    case TOGGLE_RESULT_OF_STOP:
      return updatePersonIndexedFormFieldArray('resultOfStop')
    case SET_FORM_LOADING:
      return assoc('formLoading', payload.formLoading, state);
    case SET_USE_SAME_FOR_ALL_PERSON:
      return assoc('useSameActionForAllPersonIndex', payload.useSameActionForAllPersonIndex === -1 || state.useSameActionForAllPersonIndex === -1 ?
        payload.useSameActionForAllPersonIndex : state.useSameActionForAllPersonIndex, state);
    case ADD_OFFICER_TIME_SPENT_CUSTOM:
      return assoc('officerTimeSpentCustom', payload.additionalTime + state.officerTimeSpentCustom, state);
    case ADD_OFFICER_TIME_SPENT:
      return assoc('officerTimeSpent', payload.additionalTime + state.officerTimeSpent, state);
    case SET_RECALL_STEP:
      return assoc('recallStep', payload, state);
    case SET_VALIDATION_ERRORS:
      return assocPath(['validation', 'stepErrors'], payload.stepErrors, state);
    case RESET_INITIAL_STATE:
      return { ...getInitialState(state.flags) }
    case RESET_FORM_DATA: {
      const isOnline = localStorage?.getItem('contact-is-online') === 'true'
      const onlineCountOld = get(state, 'debug.reset.onlineCount', 0)
      const offlineCountOld = get(state, 'debug.reset.offlineCount', 0)

      const offlineCount = isOnline ? offlineCountOld : offlineCountOld + 1;
      const onlineCount = isOnline ? onlineCountOld + 1 : onlineCountOld;

      return {
        ...getInitialState({ post2024: payload.post2024, pre2024: payload.pre2024, is2024Test: false }), id: state.id, status: 'draft', stopDateTime: payload.stopDateValue, raceOfOfficer: state.raceOfOfficer, yearsOfExperience: state.yearsOfExperience,
        debug: { ...state.debug, reset: { offlineCount, onlineCount, buildDetails: { ...window.buildDetails } } }
      }
    }
    case SET_FLAGS:
      return assoc('flags', payload, state);
    default:
      return state;
  }
};

export const resetForm = ({ pre2024, post2024, stopDateValue }: { pre2024: boolean; post2024: boolean; stopDateValue: string }) => ({
  type: RESET_FORM_DATA,
  payload: { pre2024, post2024, stopDateValue },
})

export const resetInitialState = () => ({
  type: RESET_INITIAL_STATE
});

export const setFlags = ({ pre2024, post2024 }: { pre2024: Boolean; post2024: Boolean }) => ({
  type: SET_FLAGS,
  payload: { pre2024, post2024 },
});

export const setFormField = <T = any>({ path }: { path: (string | number)[] }) => (value: T) => ({
  type: SET_FORM_FIELD,
  payload: { path, value },
});

export const mergeForm = (form: Form) => ({
  type: MERGE_FORM,
  payload: { form },
});

export const toggleRace = ({ personIndex, value }: { personIndex: number; value: string }) => ({
  type: TOGGLE_RACE,
  payload: { personIndex, value },
});

export const toggleDisability = ({ personIndex, value }: { personIndex: number; value: string }) => ({
  type: TOGGLE_DISABILITY,
  payload: { personIndex, value },
});

export const toggleActionTaken = ({ personIndex, value }: { personIndex: number; value: string }) => ({
  type: TOGGLE_ACTION_TAKEN,
  payload: { personIndex, value },
});

export const toggleSearchBasis = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_SEARCH_BASIS,
  payload: { value, personIndex },
});

export const toggleReasonGivenStoppedPerson = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_REASON_GIVEN_STOPPED_PERSON,
  payload: { value, personIndex },
});

export const toggleSeizedPropertyBasis = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_SEIZED_PROPERTY_BASIS,
  payload: { value, personIndex },
});

export const toggleSeizedPropertyType = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_SEIZED_PROPERTY_TYPE,
  payload: { value, personIndex },
});

export const toggleContraband = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_CONTRABAND,
  payload: { value, personIndex },
});

export const toggleResultOfStop = ({ value, personIndex }: { personIndex: number; value: string }) => ({
  type: TOGGLE_RESULT_OF_STOP,
  payload: { value, personIndex },
});

export const setFormLoading = (isLoading: boolean) => ({
  type: SET_FORM_LOADING,
  payload: { formLoading: isLoading },
});

export const setUseSameForAllPerson = (useSameActionForAllPersonIndex: number) => ({
  type: SET_USE_SAME_FOR_ALL_PERSON,
  payload: { useSameActionForAllPersonIndex },
});

export const setRecallStep = ({ currentStep, currentSubstep, history, maxStepReached, currentComponent }: { currentStep: number; currentSubstep: number; history: HistoryRecord[]; maxStepReached: number; currentComponent: string }) => ({
  type: SET_RECALL_STEP,
  payload: { currentStep, currentSubstep, history, maxStepReached, currentComponent },
});

export const setValidationErrors = (stepErrors: any) => ({
  type: SET_VALIDATION_ERRORS,
  payload: { stepErrors },
});

export const addOfficerTimeSpent = (additionalTime: number) => ({
  type: ADD_OFFICER_TIME_SPENT,
  payload: { additionalTime },
});

export const addOfficerTimeSpentCustom = (additionalTime: number) => ({
  type: ADD_OFFICER_TIME_SPENT_CUSTOM,
  payload: { additionalTime },
});

export const setStopDateAndRun2024Conditions = ({ stopDateValue, setDisableContinue }: { stopDateValue: string; setDisableContinue: (disableContinue: boolean) => void }) => async (dispatch: Dispatch<any>, getState: GetState) => {
  const { name: templateName } = getState()[TemplateNamespace];
  const { flags } = getState()[FormNamespace];

  dispatch(setFormField({ path: ['stopDateTime'] })(stopDateValue));

  const after2024 = isAfter2024(stopDateValue, templateName);

  if (flags.post2024 !== after2024) {
    const resetFormState = reducer(getState()[FormNamespace], resetForm({ pre2024: after2024, post2024: after2024, stopDateValue }))
    updateSteps(undefined, undefined, true, { pre2024: after2024, post2024: after2024 }, true)(dispatch, () => ({ ...getState(), [FormNamespace]: resetFormState }));
  }

  await sleep(200);

  setDisableContinue(false);
};

export const addFormTimeSpent = ({ addTimeSec }: { addTimeSec: number }) => (dispatch: Dispatch<any>, getState: GetState) => {
  const { steps, currentStep, currentSubstep } = getState()[NewReportNamespace];
  const { isCustom } = steps?.[currentStep]?.substeps?.[currentSubstep];

  if (isCustom) {
    dispatch(addOfficerTimeSpentCustom(addTimeSec))
  } else {
    dispatch(addOfficerTimeSpent(addTimeSec))
  }
};

export const removeStudentAndResetForm = () => (_: AnalyserNode, getState: GetState, { http }: ThunkDeps) => {
  let raceOfOfficer;
  const { assignment, duration, id, isCustomDuration, location, numberOfPeople, flags: { pre2024, post2024, is2024Test },
    person, stopDescription, stopDateTime, version, yearsOfExperience } = getState()[FormNamespace]
  const { removeStudentDialogPersonId } = getState()[NewReportNamespace]
  const newPersonArray = person.reduce((ac: Person[], cv: Person, i: number) => ([...ac, {
    label: cv.label,
    nonConforming: cv.nonConforming,
    gender: cv.gender,
    student: removeStudentDialogPersonId === i ? !cv.student : cv.student,
    age: cv.age,
    race: cv.race,
    lgbt: cv.lgbt,
    sexualOrientation: cv.sexualOrientation,
    disabled: cv.disabled,
    unhoused: cv.unhoused,
    english: cv.english,
  }]), []);

  if (pre2024 || post2024) {
    raceOfOfficer = getState()[UserNamespace]?.user?.race;
  }

  return http
    .patch(`/form_data/${id}`, {
      contents: {
        ...getInitialState({ pre2024, post2024, is2024Test }), assignment, duration,
        id, isCustomDuration, location, numberOfPeople, person: newPersonArray,
        stopDescription, stopDateTime, version, yearsOfExperience, raceOfOfficer,
      }, status: Status.Draft,
    })
    .then(() => {
      decoupledDispatch('NewReport.setRemoveStudentDialogOpen', { open: false })
      createSnackNotification(AlertLevel.Success, 'Success', 'Report reset')
      setTimeout(() => assignRoute(`/new-report/${id}`), 1000)
    })
    .catch(httpErrorHandler('Failed to reset form'));
};

export const checkValidation = (onPass?: () => void, alwaysCallback?: boolean, wait?: boolean) => (dispatch: Dispatch<any>, getState: GetState) => setTimeout(() => {
  const { currentStep, currentSubstep, currentSubloop, totalSteps, steps, nSteps } = getState()[NewReportNamespace];
  const { validations, isAll } = steps?.[currentStep]?.substeps?.[currentSubstep] ?? {};
  const form = getState()[FormNamespace];
  const { enumMap } = getState()[TemplateNamespace];

  let stepErrors = {};

  if (validations) {
    stepErrors = Object.keys(validations).reduce((acc, fieldFieldPath) => ({
      ...acc,
      ...validations[fieldFieldPath].reduce((ac: any, { hasError, snack }: { hasError: HasError; snack: Snack }) => {
        const pathAry = fieldFieldPath.split('.');
        const param = { ...form, currentStep, currentSubstep, currentSubloop, totalSteps, nSteps, enumMap };
        const pathParam = pathAry[pathAry[0] === 'custom' ? 1 : 0];
        let errorCount = 0;

        /* eslint-disable no-lonely-if */
        if (pathAry[0] === 'custom') {
          if (isAll) {
            errorCount = range(0, form.numberOfPeople).reduce((ec: number, _: any, i: number) => i <= currentSubloop && hasError(param, i) ? ec + 1 : ec, 0);
          } else {
            errorCount = hasError(param) ? 1 : 0;
          }
        } else {
          if (Array.isArray(form[pathParam])) {
            errorCount = form[pathParam].reduce((ec: number, _: any, i: number) => hasError(param, i) ? ec + 1 : ec, 0);
          } else {
            errorCount = hasError(param) ? 1 : 0;
          }
        }
        if (snack && errorCount) {
          const { level, title, message } = snack;
          createSnackNotification(level, title, message);
        }
        return { ...ac, [fieldFieldPath]: ac[fieldFieldPath] ? ac[fieldFieldPath] + errorCount : errorCount };
      }, {}),
    }), {});
  }

  // If no errors, call onPass
  if (Object.values<number>(stepErrors).filter(e => e > 0).length === 0 || alwaysCallback) {
    onPass?.();
  }

  dispatch(setValidationErrors(stepErrors));
}, wait ? 100 : 0);

const fetchStateData = (_: any, stateOfUser: string, { http, offlineConfig }: ThunkDeps) =>
  http.raw<{
    offenseCodes: any[];
    schools: any[];
    cities: any[];
  }>({ url: `/data/${stateOfUser.toLowerCase()}_assets.json` }).then(async ({ data }) => {
    if (data) {
      data.offenseCodes.forEach((o: any) => o.label = `${o.Statute ?? o.OffenseStatute} - ${o.Literal ?? o.offenseliteral}`);
      data.schools = data.schools.filter((s: any) => s.School !== 'No Data');
      window.cities = data.cities;
      window.offenseCodes = data.offenseCodes;
      window.schools = data.schools;
      await offlineConfig.setItem('cities', data.cities);
      await offlineConfig.setItem('offenseCodes', data.offenseCodes);
      await offlineConfig.setItem('schools', data.schools);
    }
  }).catch(async () => {
    await offlineConfig.getItem('cities').then((cities: any) => window.cities = cities);
    await offlineConfig.getItem('offenseCodes').then((offenseCodes: any) => window.offenseCodes = offenseCodes);
    await offlineConfig.getItem('schools').then((schools: any) => window.schools = schools);
  });

export const getStateData = () => async (dispatch: Dispatch<any>, getState: GetState, deps: ThunkDeps) => {
  let state = getState()[UserNamespace]?.user?.organization?.state;

  if (!state) {
    state = await deps.offlineConfig.getItem('user').then((u: any) => u.organization.state);
  }

  return fetchStateData(dispatch, state, deps)
}

export const getForm = ({ id }: { id: string }) => async (dispatch: Dispatch<any>, getState: GetState, { http, offlineTemplates, offlineCustomQuestions, offlineConfig }: ThunkDeps) => {
  const { training_mode } = getState()[UserNamespace].user;
  let state = getState()[UserNamespace]?.user?.organization?.state;

  if (!state) {
    state = await offlineConfig.getItem('user').then((u: any) => u.organization.state);
  }

  const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true')

  dispatch(setFormLoading(true))
  decoupledDispatch('Template.setTemplateLoading', true)

  await fetchStateData(dispatch, state, { http, offlineTemplates, offlineCustomQuestions, offlineConfig })

  let status: string;
  let createdAt: string;
  let formTemplateId: number;
  let enumMap: EnumMap | void;
  let offlineCreatedAt: string;

  try {

    if (training_mode && !workOffline) {
      enumMap = await getFormTemplatesCached(http, offlineTemplates)
        .then(async (templates) => {
          const defaultTemplateName = getState()[UserNamespace]?.organizationInfo?.defaultTemplateName ?? 'CA_2024_ripa';
          const templateData = templates.find((t: Template) => t.name === defaultTemplateName);

          if (templateData) {
            const { answers, name, id: formTemplateRecordId, version } = templateData;
            let pre2024 = getState()[UserNamespace]?.organizationInfo?.pre2024 ?? false;
            const post2024 = isAfter2024();
            if (post2024) {
              pre2024 = true;
            }
            const contents = getInitialState({ pre2024, post2024, is2024Test: false });

            decoupledDispatch('Template.setEnumMap', { enumMap: answers, id: formTemplateRecordId, formTemplateId: formTemplateRecordId, name, version });
            dispatch(mergeForm({ ...contents, id: 'Training', status, createdAt: dayjs().toISOString() }));
            decoupledDispatch('NewReport.recallStep', contents.recallStep ?? {});

            return answers
          }

          const message = `Cannot create new form. Missing data, please contact Veritone (${workOffline ? 0 : 1}c)`;
          createSnackNotification(AlertLevel.Warning, 'Error', message);
          Sentry.captureMessage(message, {
            tags: {
              ...JSON.parse(localStorage.getItem('sentry-user') || '{}')
            }
          })
          setTimeout(() => assignRoute('/dashboard'), 1000);
        })
    } else {
      let contents: Form;
      formTemplateId = await http.get<ServerForm>(`/form_data/${id}`)
        .then(({ data }) => {
          status = data.status
          createdAt = data.created_at ?? ''
          offlineCreatedAt = data.contents.offlineCreatedAt ?? ''

          contents = migrateForm(data.contents)
          return data.form_template_id
        })
      enumMap = await http.get<Template>(`/form_templates/${formTemplateId}`)
        .then(async ({ data: templateData }) => {
          const { answers, name, id: formTemplateRecordId, version } = templateData
          decoupledDispatch('Template.setEnumMap', { enumMap: answers, id: formTemplateRecordId, formTemplateId, name, version })
          dispatch(mergeForm({ ...contents, id, status, createdAt, offlineCreatedAt }))
          decoupledDispatch('NewReport.recallStep', contents.recallStep ?? {})

          await offlineTemplates.setItem(`${formTemplateId}`, templateData)

          return answers
        }).catch(httpErrorHandler('Failed to load template data', () => {
          setTimeout(() => assignRoute('/dashboard'), 1000);
        }))
    }

    let { user: { organization_id } } = getState()[UserNamespace];

    if (!organization_id) {
      organization_id = await offlineConfig.getItem('user').then((u: any) => u.organization.id);
    }

    const { data } = await http.get<ServerCustomQuestions>(`/organizations/${organization_id}/custom_questions/`);
    const questions: CustomQuestionSections = data?.questions ?? {};

    await offlineCustomQuestions.setItem('questions', questions);
    updateSteps(enumMap, questions, false)(dispatch, getState);

  } catch (e) {
    httpErrorHandler('Failed to get form', () => {
      setTimeout(() => assignRoute('/dashboard'), 1000);
    })(e)
  } finally {
    dispatch(setFormLoading(false));
    decoupledDispatch('Template.setTemplateLoading', false);
  }
}

export const updateSteps = (enumMap?: EnumMap | void, customQuestions?: CustomQuestionSections, setTemplateLoading = true, flags?: { pre2024: boolean; post2024: boolean }, shouldResetForm?: boolean) => (dispatch: Dispatch<any>, getState: GetState) => {
  if (setTemplateLoading) {
    decoupledDispatch('Template.setTemplateLoading', true);
  }
  const enums: EnumMap = enumMap ?? getState()[TemplateNamespace].enumMap;
  const questions: CustomQuestionSections = customQuestions ?? getState()[NewReportNamespace].customQuestions;
  const form: Form = getState()[FormNamespace];
  const templateName: string = getState()[TemplateNamespace].name;
  const earlyPost2024: boolean = getState()[UserNamespace].organizationInfo?.earlyPost2024;

  if (shouldResetForm) {
    dispatch(mergeForm(getState()[FormNamespace]))
  }

  let steps: Step[] = StepsFunction(enums, { ...(flags ?? getState()[FormNamespace].flags), earlyPost2024 }, form, templateName, questions);

  if (questions?.isLive) {
    Object.entries(questions).forEach(([key, questionSection]) => {
      if (key === 'all') {
        const newSection = {
          title: questionSection.title,
          loop: {
            iterations: 'numberOfPeople'
          },
          substeps: questionSection.questions.map((q: CustomQuestion) => ({
            ...q,
            isAll: key === 'all',
            isCustom: true,
            validations: customQuestionValidation({
              ...q,
              props: { ...q.props, isAll: key === 'all', hasHelper: q.helperText !== '' }
            }),
            getProps: () => ({ ...q.props, isAll: key === 'all', hasHelper: q.helperText !== '' })
          })).filter((q: CustomQuestion) => !q.disabled)
        }
        if (newSection.substeps.length > 0) {
          steps.splice(steps.length - 1, 0, newSection);
        }
      }
      if (key === 'individual') {
        const newSection = {
          title: questionSection.title,
          substeps: questionSection.questions.map((q: CustomQuestion) => ({
            ...q,
            isCustom: true,
            validations: customQuestionValidation({
              ...q,
              isAll: false,
              props: { ...q.props, isAll: false, hasHelper: q.helperText !== '' }
            }),
            getProps: () => ({ ...q.props, isAll: false, hasHelper: q.helperText !== '' })
          })).filter((q: CustomQuestion) => !q.disabled)
        }
        if (newSection.substeps.length > 0) {
          steps.splice(steps.length - 1, 0, newSection);
        }
      }
    });
  }

  const prunedSubsteps = steps.map((step: Step) => ({ ...step, substeps: step.substeps.filter((s: Substep) => s.hasMap ? s.hasMap({ enumMap: enums }) : true) }));
  steps = steps.filter((_: any, i: number) => prunedSubsteps[i].substeps.length > 0);

  decoupledDispatch('NewReport.registerSteps', {
    steps,
    nSteps: steps.map(({ substeps, title, loop }: Step) => (
      { title, loop, nSubsteps: substeps.length, preconditions: substeps.map(s => s.precondition) }
    )),
    totalSteps: steps.length + steps.reduce((ac: number, cv: Step) => ac + cv.substeps.length, 0)
  });
  if (setTemplateLoading) {
    decoupledDispatch('Template.setTemplateLoading', false);
  }
}

export const saveAndQuit = () => (_: any, getState: GetState, { http }: ThunkDeps) => {
  const form = getState()[FormNamespace];

  if (form.id === 'Training') {
    return new Promise(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Report not saved');
      setTimeout(() => assignRoute('/dashboard'), 1000);
    });
  }

  return http
    .patch(`/form_data/${form.id}`, { contents: form, status: form.status })
    .then(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Report saved');
      setTimeout(() => assignRoute('/dashboard'), 1000);
    })
    .catch(httpErrorHandler('Failed to update form'));
};

export const startOver = ({ formId }: { formId: string }) => (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
  let raceOfOfficer;
  const yearsOfExperience = getState()[UserNamespace].user.years_of_experience <= 0 ? 1 : getState()[UserNamespace].user.years_of_experience;
  const { assignment, status, stopDateTime, debug, flags: { pre2024, post2024, is2024Test } } = getState()[FormNamespace];

  if (pre2024 || post2024) {
    raceOfOfficer = getState()[UserNamespace]?.user?.race;
  }
  if (formId === 'Training') {
    return new Promise(() => {
      dispatch(resetInitialState())
      dispatch(setFormLoading(true));
      decoupledDispatch('NewReport.setStartOverDialogOpen', { open: false })
      createSnackNotification(AlertLevel.Success, 'Success', 'Report cleared')
      setTimeout(() => assignRoute(`/new-report/${formId}`), 1000)
    });
  }

  return http
    .patch(`/form_data/${formId}`, { contents: { ...getInitialState({ pre2024, post2024, is2024Test }), assignment, raceOfOfficer, yearsOfExperience, debug, stopDateTime }, status })
    .then(() => {
      dispatch(resetInitialState())
      dispatch(setFormLoading(true));
      decoupledDispatch('NewReport.setStartOverDialogOpen', { open: false })
      createSnackNotification(AlertLevel.Success, 'Success', 'Report cleared')
      setTimeout(() => assignRoute(`/new-report/${formId}`), 1000)
    })
    .catch(httpErrorHandler('Failed to clear form'));
};

export const updateForm = ({ value, id }: { value: Form; id: string }) => (_: any, getState: GetState, { http }: ThunkDeps) => {
  const form = getState()[FormNamespace];
  const { id: form_template_id } = getState()[TemplateNamespace];

  const isOnline = localStorage?.getItem('contact-is-online') === 'true'
  const onlineCount = get(value, 'debug.update.onlineCount', 0)
  const offlineCount = get(value, 'debug.update.offlineCount', 0)

  set(value, 'debug.update.onlineCount', isOnline ? onlineCount + 1 : onlineCount)
  set(value, 'debug.update.offlineCount', isOnline ? offlineCount : offlineCount + 1)
  set(value, 'debug.update.buildDetails', { ...window.buildDetails })

  return http
    .patch(`/form_data/${id}`, { form_template_id, contents: value, status: form.status })
    .catch(httpErrorHandler('Failed to update form'));
};

export const versionForm = ({ id, type }: { id: string; type: string }) => (_: any, __: GetState, { http }: ThunkDeps) => {
  if (!id || !type) {
    console.error('Form versioning requires id and event type!');
    return
  }
  return http.post(`/form_data/${id}/version?event=${type}`)
    .catch(httpErrorHandler('Failed to version form'));
}
export const submitFormForReview = () => async (dispatch: Dispatch<any>, getState: GetState, { http, offlineConfig }: ThunkDeps) => {
  dispatch({ type: SUBMITTING_FOR_REVIEW });
  const contents = getState()[FormNamespace];
  const { user } = getState()[UserNamespace];
  const isUserSettingsValid = UserSelectors.isUserSettingsValid(getState()[UserNamespace]);
  const raceOfOfficer = user.race;
  const yearsOfExperience = user.years_of_experience;
  const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true');
  contents.progress = 100;

  const isOnline = localStorage?.getItem('contact-is-online') === 'true'
  const onlineCount = get(contents, 'debug.submitForReview.onlineCount', 0)
  const offlineCount = get(contents, 'debug.submitForReview.offlineCount', 0)

  set(contents, 'debug.submitForReview.onlineCount', isOnline ? onlineCount + 1 : onlineCount)
  set(contents, 'debug.submitForReview.offlineCount', isOnline ? offlineCount : offlineCount + 1)
  set(contents, 'debug.submitForReview.buildDetails', { ...window.buildDetails })

  if (!isUserSettingsValid) {
    createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to submit a report.`)
    return;
  }

  if (user?.training_mode && !workOffline) {
    createSnackNotification(AlertLevel.Info, 'Training Mode', 'Form is not submitted in training mode');
    setTimeout(() => assignRoute('/dashboard'), 2000);
    return;
  }
  if (contents.flags.is2024Test) {
    return http
      .patch(`/form_data/${contents.id}`, { contents: { ...contents, raceOfOfficer, yearsOfExperience }, status: Status.Approved })
      .then(() => {
        dispatch(setFormField({ path: ['status'] })(Status.Approved))
        createSnackNotification(AlertLevel.Success, 'Success', 'Test case submitted');
        setTimeout(() => assignRoute('/dashboard'), 1000);
      })
      .catch(httpErrorHandler('Failed to submit test case'));
  }

  return http
    .patch(`/form_data/${contents.id}`, { contents: { ...contents, raceOfOfficer, yearsOfExperience }, status: Status.UnderReview })
    .then(() => {
      dispatch(setFormField({ path: ['status'] })(Status.UnderReview))
      createSnackNotification(AlertLevel.Success, 'Success', 'Form submitted for review');
      setTimeout(() => assignRoute('/dashboard'), 1000);
    })
    .catch(httpErrorHandler('Failed to submit for review'));
};

export const syncCurrentOfflineForm = () => async (dispatch: Dispatch<any>, getState: GetState, { http, offlineConfig }: ThunkDeps) => {
  dispatch(setFormLoading(true));
  const form = getState()[FormNamespace];
  const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true');

  if (workOffline) {
    return;
  }

  const removeOfflineFormSW = (removeId: string) => http
    .delete(`/remove_offline_synced/${removeId}`, {}, { 'x-use-storage': true })
    .catch(e => console.error('Failed to remove offline form synced', e));

  return http
    .get<ServerForm>(`/form_data/${form.id}`, { 'x-use-storage': true })
    .then(async ({ data: { form_template_id, contents } }) => {

      if (form.id.startsWith('ol-')) {
        await http.post<ServerForm>('/form_data', { status: Status.Draft, contents: { ...contents }, form_template_id }, {})
          .then(async ({ data: formData }) => {
            await removeOfflineFormSW(form.id)
            assignRoute(`/new-report/${formData.id}`)
          })
          .catch(e => console.error('Something went wrong while going online', e))
      } else {
        await http.patch(`/form_data/${form.id}`, { status: Status.Draft, contents, form_template_id }, {})
          .then(async () => {
            await removeOfflineFormSW(form.id)
          })
          .catch(e => console.error('Something went wrong while going online', e))
      }
    })
    .catch(e => console.error('Something went wrong while going online', e))
    .finally(() => {
      dispatch(setFormLoading(false))
    })
}

export const selectors = ({
  form: rPathOr<Form>(getInitialState({ pre2024: true, post2024: true, is2024Test: false }), [FormNamespace]),
  id: rPath<string>([FormNamespace, 'id']),
  stopId: rPath<string>([FormNamespace, 'id']),
  person: rPath<any>([FormNamespace, 'person']),
  isCustomDuration: rPathOr<boolean>(false, [FormNamespace, 'isCustomDuration']),
  stopDateTime: rPathOr<string>('', [FormNamespace, 'stopDateTime']),
  createdAt: rPathOr<string>('', [FormNamespace, 'createdAt']),
  offlineCreatedAt: rPathOr<string>('', [FormNamespace, 'offlineCreatedAt']),
  assignment: rPath<string>([FormNamespace, 'assignment']),
  assignmentDescription: rPath<string>([FormNamespace, 'assignmentDescription']),
  otherAssignment: rPath([FormNamespace, 'otherAssignment']),
  typeOfAssignmentOfficer: rPath<string>([FormNamespace, 'typeOfAssignmentOfficer']),
  yearsOfExperience: rPathOr<number>(1, [FormNamespace, 'yearsOfExperience']),
  duration: rPathOr<number>(0, [FormNamespace, 'duration']),
  numberOfPeople: rPathOr<number>(1, [FormNamespace, 'numberOfPeople']),
  locationType: rPath<string>([FormNamespace, 'locationType']),
  otherLocation: rPathOr<string>('', [FormNamespace, 'otherLocation']),
  gpsAddress: rPathOr<string>('', [FormNamespace, 'gpsAddress']),
  schoolAddress: rPathOr<string>('', [FormNamespace, 'schoolAddress']),
  location: rPath<string>([FormNamespace, 'location']),
  city: rPathOr<string>('', [FormNamespace, 'city']),
  block: rPath<number>([FormNamespace, 'block']),
  street: rPathOr<string>('', [FormNamespace, 'street']),
  crossStreet1: rPathOr<string>('', [FormNamespace, 'crossStreet1']),
  crossStreet2: rPathOr<string>('', [FormNamespace, 'crossStreet2']),
  highway: rPathOr<string>('', [FormNamespace, 'highway']),
  exit: rPathOr<string>('', [FormNamespace, 'exit']),
  school: rPathOr<string>('', [FormNamespace, 'school']),
  stopHappenedAtPublicSchool: rPath<boolean>([FormNamespace, 'stopHappenedAtPublicSchool']),
  coordinates: rPathOr<Form['coordinates']>({
    latitude: -1,
    longitude: -1,
    accuracy: -1
  }, [FormNamespace, 'coordinates']),
  useSameGenderForAll: rPathOr<boolean>(false, [FormNamespace, 'useSameGenderForAll']),
  useSameRaceForAll: rPathOr<boolean>(false, [FormNamespace, 'useSameRaceForAll']),
  useSamePrimaryReasonForAll: rPathOr<boolean>(false, [FormNamespace, 'useSamePrimaryReasonForAll']),
  personTabIndex: rPath([FormNamespace, 'personTabIndex']),
  useSameActionTakenForAll: rPathOr<boolean>(false, [FormNamespace, 'useSameActionTakenForAll']),
  useSameActionForAllPersonIndex: rPathOr<number>(0, [FormNamespace, 'useSameActionForAllPersonIndex']),
  typeOfStop: rPath<string>([FormNamespace, 'typeOfStop']),
  anyResultOfStop: (state: any): boolean[] => map(({ anyResultOfStop }) => anyResultOfStop, state[FormNamespace].person),
  searchDescription: (state: any) => map(({ searchDescription }) => searchDescription, state[FormNamespace].person),
  labels: (state: any) => range(0, state[FormNamespace].numberOfPeople).map(i => <string>state[FormNamespace].person?.[i]?.label ?? ''),
  race: (state: any) => map(({ race }) => race, state[FormNamespace].person),
  gender: (state: any) => map(({ gender }) => gender, state[FormNamespace].person),
  nonConforming: (state: any) => map(({ nonConforming }) => nonConforming, state[FormNamespace].person),
  student: (state: any) => map<any, boolean>(({ student }) => student, state[FormNamespace].person),
  age: (state: any) => map(({ age }) => age, state[FormNamespace].person),
  lgbt: (state: any) => map(({ lgbt }) => lgbt, state[FormNamespace].person),
  sexualOrientation: (state: any) => map(({ sexualOrientation }) => sexualOrientation, state[FormNamespace].person),
  english: (state: any) => map(({ english }) => english, state[FormNamespace].person),
  disabled: (state: any) => map(({ disabled }) => disabled, state[FormNamespace].person),
  unhoused: (state: any) => map(({ unhoused }) => unhoused, state[FormNamespace].person),
  disabilities: (state: any) => map(({ disabilities }) => disabilities, state[FormNamespace].person),
  consentGiven: (state: any) => map(({ consentGiven }) => consentGiven, state[FormNamespace].person),
  consentType: (state: any) => map(({ consentType }) => consentType, state[FormNamespace].person),
  actionTakenBool: (state: any) => map(({ actionTakenBool }) => actionTakenBool, state[FormNamespace].person),
  actionTaken: (state: any) => map(({ actionTaken }) => actionTaken, state[FormNamespace].person),
  resultOfStopCodeSection: (state: any) => map(({ resultOfStopCodeSection }) => resultOfStopCodeSection, state[FormNamespace].person),
  searchBasis: (state: any) => map(({ searchBasis }) => searchBasis, state[FormNamespace].person),
  reasonGivenStoppedPerson: (state: any) => map(({ reasonGivenStoppedPerson }) => reasonGivenStoppedPerson, state[FormNamespace].person),
  seizedPropertyBasis: (state: any) => map(({ seizedPropertyBasis }) => seizedPropertyBasis, state[FormNamespace].person as Person[]),
  seizedPropertyType: (state: any) => map(({ seizedPropertyType }) => seizedPropertyType, state[FormNamespace].person),
  contraband: (state: any) => map(({ contraband }) => contraband, state[FormNamespace].person),
  anyContrabandFound: (state: any) => map(({ anyContrabandFound }) => anyContrabandFound, state[FormNamespace].person),
  resultOfStop: (state: any) => map(({ resultOfStop }) => resultOfStop, state[FormNamespace].person),
  primaryReason: (state: any) => map(({ primaryReason }) => primaryReason, state[FormNamespace].person),
  trafficViolation: (state: any) => map(({ trafficViolation }) => trafficViolation, state[FormNamespace].person),
  trafficViolationCode: (state: any) => map(({ trafficViolationCode }) => trafficViolationCode, state[FormNamespace].person),
  reasonableSuspicion: (state: any) => map(({ reasonableSuspicion }) => reasonableSuspicion, state[FormNamespace].person),
  reasonableSuspicionCode: (state: any) => map(({ reasonableSuspicionCode }) => reasonableSuspicionCode, state[FormNamespace].person),
  probableCause: (state: any) => map(({ probableCause }) => probableCause, state[FormNamespace].person),
  probableCauseCode: (state: any) => map(({ probableCauseCode }) => probableCauseCode, state[FormNamespace].person),
  codeSection48900: (state: any) => map(({ codeSection48900 }) => codeSection48900, state[FormNamespace].person),
  codeSection48900Subdivision: (state: any) => map(({ codeSection48900Subdivision }) => codeSection48900Subdivision, state[FormNamespace].person),
  stopDescription: (state: any) => map<any, string>(({ stopDescription }) => stopDescription, state[FormNamespace].person),
  stoppedPassenger: (state: any) => map<any, boolean>(({ stoppedPassenger }) => stoppedPassenger, state[FormNamespace].person),
  stoppedInsideResidence: (state: any) => map<any, boolean>(({ stoppedInsideResidence }) => stoppedInsideResidence, state[FormNamespace].person),
  currentSubloop: rPath([FormNamespace, 'currentSubloop']),
  formLoading: rPath([FormNamespace, 'formLoading']),
  stepErrors: rPathOr<StepErrors>({}, [FormNamespace, 'validation', 'stepErrors']),
  responseToCall: rPath<boolean>([FormNamespace, 'responseToCall']),
  officerWorksWithNonPrimaryAgency: rPath<boolean>([FormNamespace, 'officerWorksWithNonPrimaryAgency']),
  stopDuringWellnessCheck: rPath<boolean>([FormNamespace, 'stopDuringWellnessCheck']),
  contrabandOrEvidenceDiscovered: (state: any) => map(({ contrabandOrEvidenceDiscovered }) => contrabandOrEvidenceDiscovered, state[FormNamespace].person),
  officerTimeSpent: rPath([FormNamespace, 'officerTimeSpent']),
  officerTimeSpentCustom: rPath([FormNamespace, 'officerTimeSpentCustom']),
  reviewerTimeSpent: rPath([FormNamespace, 'reviewerTimeSpent']),
  customQuestionAnswers: rPath<any>([FormNamespace, 'custom']),
  flags: rPath<any>([FormNamespace, 'flags']),
  testCaseId: rPathOr<string>('', [FormNamespace, 'testCaseId']),
  locationString: (state: any) => {
    const schoolAddress = state[FormNamespace]?.schoolAddress;
    const gpsAddress = state[FormNamespace]?.gpsAddress;
    const otherLocation = state[FormNamespace]?.otherLocation;
    const locationType = state[FormNamespace]?.locationType;
    const highway = state[FormNamespace]?.highway;
    const exit = state[FormNamespace]?.exit;
    const crossStreet1 = state[FormNamespace]?.crossStreet1;
    const crossStreet2 = state[FormNamespace]?.crossStreet2;
    const street = state[FormNamespace]?.street;
    const block = state[FormNamespace]?.block;
    const location = state[FormNamespace]?.location;
    const pre2024 = state[FormNamespace]?.flags?.pre2024 ?? false;

    if (pre2024) {
      switch (locationType) {
        case LocationType.GPS:
          return gpsAddress
        case LocationType.Block:
          return block && street !== '' ? `${block} Block ${street}` : ''
        case LocationType.Intersection:
          return crossStreet1 !== '' && crossStreet2 !== '' ? `${crossStreet1} & ${crossStreet2}` : '';
        case LocationType.School:
          return `${schoolAddress}`
        case LocationType.HighwayExit:
          return highway !== '' && exit !== '' ? `${highway} Exit ${exit}` : '';
        case LocationType.Other:
          return `${otherLocation}`
        default:
          break;
      }
    }
    return location ?? '';
  },

})

export default reducer;
