import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as CsvReportsDuck from '@ducks/csvReports';
import { User } from '@ducks'
import { onEnter } from '@utility/keypressHelpers'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import dayjs from 'dayjs';
import './CsvRow.scss'
import { Role } from '@engine/ducks/constants';

export const CsvRow = ({ colData, rowData, rowIndex, csvReportRows, downloadCsvReport, setRemoveCsvReportDialogOpen, userId, assignedRoles }) => {
  useEffect(() => {
  }, [])

  const downloadReport = (reportId) => {
    downloadCsvReport(reportId);
  };

  const validateReportDelete = () => {
    const currentCsvRow = csvReportRows.find((csvReportRow => rowData.id === csvReportRow.id));
    return assignedRoles?.includes(Role.Admin) || userId === currentCsvRow.createdId;
  }

  /* eslint-disable default-case, consistent-return */
  const renderCell = ({ dataKey }) => {
    switch (dataKey) {
      case 'id':
        return <div role="cell" className="csv-reports-row__cell-id">
          {rowData?.[dataKey]}
        </div>
      case 'createdAt':
        return <div role="cell" className="csv-reports-row__cell-id">
          {dayjs(rowData[dataKey])?.format?.('MM/DD/YYYY h:mm A')}
        </div>
      case 'startTime':
        return <div role="cell" className="csv-reports-row__cell-startTime">
          {dayjs(rowData[dataKey])?.format?.('MM/DD/YYYY h:mm A')}
        </div>
      case 'endTime':
        return <div role="cell" className="csv-reports-row__cell-endTime">
          {dayjs(rowData[dataKey])?.format?.('MM/DD/YYYY h:mm A')}
        </div>
      case 'exportType':
        return <div role="cell" className="csv-reports-row__cell-exportType">
          {rowData?.[dataKey]}
        </div>
      case 'status':
        return <div role="cell" className="csv-reports-row__cell-status">
          {rowData?.[dataKey] ? 'Complete' : 'Incomplete'}
        </div>
      case 'actions':
        return (
          <div role="cell" className="csv-reports-row__cell-actions">
            {rowData.status && validateReportDelete() && (
            <Tooltip title="Delete CSV Report">
              <div
                className={cn('csv-reports-row__table-cell-actions-cancel material-icons')}
                data-testid={`csv-reports-row-table-cell-actions-cancel-${rowData.id}`}
                role="button"
                tabIndex="0"
                aria-label="Delete CSV Report"
                onClick={() => setRemoveCsvReportDialogOpen({ open: true, id: rowData.id })}
                onKeyUp={onEnter(() => setRemoveCsvReportDialogOpen({ open: true, id: rowData.id }))}
              >
                clear
              </div>
            </Tooltip>
            )}
            {rowData.status && (
            <Tooltip title="Download CSV Report">
              <div
                className="csv-reports-row__table-cell-actions-edit"
                data-testid={`csv-reports-row-table-cell-actions-edit-${rowData.id}`}
                role="button"
                tabIndex="0"
                onClick={() => downloadReport(rowData.id)}
                onKeyUp={onEnter(() => downloadReport(rowData.id))}
                aria-label="Download CSV Report"
              >
                <div className="material-icons"> download </div>
              </div>
            </Tooltip>
            )}
          </div>
        );
    }
  }

  return (
    <div
      role="row"
      className={cn('csv-reports-row')}
      data-testid={`csv-reports-row-${rowIndex}-${rowData.id}`}
    >
      <div
        className={cn('csv-reports-row__row')}
        data-testid="csv-reports-row-row"
      >
        {colData.map(({ grow, dataKey, width, minWidth }, index) => <div
          className="csv-reports-row__cell"
          data-testid={`csv-reports-row-cell-${rowIndex}-${index}`}
          key={`CsvReportsRowCell-${rowIndex}-${index}-${dataKey}`}
          style={{
            flexGrow: grow,
            maxWidth: width,
            minWidth
          }}>
          {renderCell({ dataKey })}
        </div>
        )}
      </div>
    </div>
  )
}

CsvRow.propTypes = {
  colData: PropTypes.array,
  rowData: PropTypes.object,
  rowIndex: PropTypes.number,
  csvReportRows: PropTypes.array,
  downloadCsvReport: PropTypes.func,
  setRemoveCsvReportDialogOpen: PropTypes.func,
  userId: PropTypes.number,
  assignedRoles: PropTypes.array,
}

const mapDispatchToProps = {
  downloadCsvReport: CsvReportsDuck.downloadCsvReport,
  setRemoveCsvReportDialogOpen: CsvReportsDuck.setRemoveCsvReportDialogOpen
}

const mapStateToProps = state => ({
  csvReportRows: CsvReportsDuck.selectors.csvReportRows(state),
  userId: User.selectors.userId(state),
  assignedRoles: User.selectors.roles(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(CsvRow)
