import env from '@engine/env'

interface Intersection {
  street1: string;
  street2: string;
  postalcode: string;
  adminCode1: string;
  adminName2: string;
  placename: string;
}

export default async function getIntersection(
  { latitude, longitude }: { latitude: number; longitude: number},
  setLoading?: (loading: boolean) => void
): Promise<Intersection | undefined> {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 1500);
  const { intersection } = await fetch(
    `https://secure.geonames.org/findNearestIntersectionJSON?lat=${latitude}&lng=${longitude}&username=${env().REACT_APP_GEONAMES_USERNAME}`,
    { signal: controller.signal }
  ).then(r => {
    setLoading?.(false);
    return r.json();
  })
  clearTimeout(id);
  return intersection
}
