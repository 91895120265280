import { AlertLevel } from '@components/common'
import { path } from 'ramda'

interface Props {
  props: {
    resultPath: string;
    multiple?: boolean;
    isAll?: boolean;
    format?: string;
  };
  isAll?: boolean;
  required?: boolean;
}

export default (customQuestion: Props) => ({
  [`custom.${customQuestion.props.resultPath}`]: [
    {
      hasError: (validationData: any, personIndex: number) => {
        const { props, required } = customQuestion;
        const { resultPath, multiple, isAll } = props;
        const customForm = validationData?.custom;

        if (!validationData?.custom) {
          // Must have custom field
          return true;
        }
        if (!required) {
          return false;
        }

        if (isAll) {
          if (multiple) {
            const result = path<any[]>(['person', personIndex, resultPath], customForm);
            return !result || result.length === 0 || result.some(r => typeof r === 'number' ? r === undefined : !r);
          }
          const result = path(['person', personIndex, resultPath], customForm);
          return typeof result === 'number' ? result === undefined : !result;
        }
        if (multiple) {
          const result = path<any[]>([resultPath], customForm);
          return !result || result.length === 0 || result.some(r => typeof r === 'number' ? r === undefined : !r);
        }

        const result = path([resultPath], customForm);
        return typeof result === 'number' ? result === undefined : !result;
      },
      snack: {
        level: AlertLevel.Error,
        title: 'Response required',
        message: 'Please select an answer'
      },
    },
    {
      hasError: (validationData: any, personIndex: number) => {
        const { props, required } = customQuestion;
        const { resultPath, isAll } = props;
        const customForm = validationData?.custom;

        if (!validationData?.custom) {
          // Must have custom field
          return true;
        }

        const result = isAll ?
          path<string>(['person', personIndex, resultPath], customForm)
        :
          path<string>([resultPath], customForm);

        if ((!required && !result) || !props.format) {
          return false;
        }

        const reg = new RegExp(`^${props.format}$`)
        return !reg.test(result || '')
      },
      snack: {
        level: AlertLevel.Error,
        title: 'Invalid format',
        message: 'Please enter valid input'
      },
    },
  ],
})
