import React from 'react'
import cn from 'classnames'
import { TextField, TextFieldProps } from '@material-ui/core'
import { omit } from 'ramda'
import './LabelField.scss'

const LabelField = (labelFieldProps: Props & TextFieldProps) => {
  const { label, className, error, errorMessage, disabled } = labelFieldProps
  const props = omit(['label', 'className', 'error', 'errorMessage'], labelFieldProps);

  return (
    <div className={cn('label-field', className, { disabled })}>
      <div className="label-field__label">
        {label}
      </div>
      <TextField
        className="label-field__field"
        variant="outlined"
        error={error}
        {...props}
      />
      {error && <div className="label-field__error" data-testid="label-field-error">
        {errorMessage}
      </div>}
    </div>
  )
}

// LabelField.propTypes = {
//   label: PropTypes.string,
//   className: PropTypes.string
// }
interface Props {
  label?: string;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}
export default LabelField
