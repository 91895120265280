// Need to uniquely identify keys, agnostic of data definitions
export default (data: any) => {
  const str = JSON.stringify(data);
  let hash = 0;

  for (let i = 0; i < str.length; i = i + 1) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}
