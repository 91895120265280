import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Switch } from '@components/common';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { onEnter } from '@utility/keypressHelpers';
import './RipaContrabandForm.scss';

const RipaContrabandForm = ({
  contrabandEnum,
  contraband,
  toggleContraband,
  anyContrabandFound,
  setAnyContrabandFound,
  currentSubloop,
  labels,
  numberOfPeople,
  useSameActionTakenForAll,
  useSameActionForAllPersonIndex,
  seizedPropertyBasis,
  seizureBasis,
}: PropsFromRedux) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop
  const contrabandRelevantBasis = [seizureBasis.Contraband.value, seizureBasis.Evidence.value];

  return (
    <div className="ripa-contraband-form" data-testid="ripa-contraband-form">
      <div
        className={cn('ripa-contraband-form__person', {
          'same-for-all': useSameActionTakenForAll,
        })}
      >
        <div className="material-icons">person</div>
        <div className="ripa-contraband-form__person-label">
          {labels?.[currentSubloop] || currentSubloop + 1}
        </div>
        <div className="ripa-contraband-form__person-same-for-all">
          Same For All
        </div>
        <div className="ripa-contraband-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-contraband-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-contraband-form__title">
        What contraband or evidence was discovered?
      </div>
      <div className="ripa-contraband-form__any-found">
        <div className="ripa-contraband-form__any-found-line" />
        <div className="ripa-contraband-form__any-found-text">
          CONTRABAND OR EVIDENCE FOUND
        </div>
        <Switch
          className="ripa-contraband-form__any-found-switch"
          data-testid="ripa-contraband-form-any-found-switch"
          onText="YES"
          offText="NO"
          value={anyContrabandFound[personIndex]}
          onChange={(v) => setAnyContrabandFound(v, personIndex)}
          disabled={(seizedPropertyBasis[personIndex] || []).some((basis) =>
            contrabandRelevantBasis.includes(basis)
          )}
        />
        <div className="ripa-contraband-form__any-found-line" />
      </div>
      <div className="ripa-contraband-form__selection">
        <div
          className={cn('ripa-contraband-form__select-box-content', {
            hidden: !anyContrabandFound[personIndex],
          })}
        >
          {[
            contrabandEnum.Firearm,
            contrabandEnum.Drugs,
            contrabandEnum.Ammunition,
            contrabandEnum.DrugParaphernalia,
            contrabandEnum.Weapon,
            contrabandEnum.Alcohol,
            contrabandEnum.SuspectedStolenProperty,
            contrabandEnum.CellPhoneOrDevice,
            contrabandEnum.Money,
            contrabandEnum.Other,
          ].map(({ value }, ci) => (
            <div
              className={cn('ripa-contraband-form__select-box', {
                active: contraband?.[personIndex]?.includes(value),
              })}
              key={`RipaContrabandFormBox-${ci}`}
              onClick={() => toggleContraband(value, personIndex)}
              onKeyUp={onEnter(() => toggleContraband(value, personIndex))}
              data-testid={`ripa-contraband-form-box-${ci}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
        </div>
        <div
          className={cn('ripa-contraband-form__no-contraband', {
            hidden: anyContrabandFound[personIndex],
          })}
        >
          No contraband or evidence.
        </div>
      </div>
    </div>
  );
}

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Select ALL that apply.
    </div>
  </div>
)

RipaContrabandForm.helper = Helper

const mapStateToProps = (state: any) => ({
  contraband: Form.selectors.contraband(state),
  anyContrabandFound: Form.selectors.anyContrabandFound(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  contrabandEnum: Template.selectors.enumMap(state)?.contrabandOrEvidence?.possibleValues,
  seizedPropertyBasis: Form.selectors.seizedPropertyBasis(state),
  seizureBasis: Template.selectors.enumMap(state)?.basisForPropertySeizure?.possibleValues,
})

const mapDispatchToProps = {
  toggleContraband: (value: string, personIndex: number) => Form.toggleContraband({ value, personIndex }),
  setAnyContrabandFound: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'anyContrabandFound'] })(value)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaContrabandForm)
