import { filter } from 'ramda';
import dayjs from 'dayjs';

// (via Kenneth.Keating@doj.ca.gov)
// The code is available for use for 480 days after the repeal date,
// then it is no longer available.  This allows for the code to be accepted
// by the system until April 1st of the following year which is the last
// day to submit the previous year’s data.

export default (today: string | dayjs.Dayjs) =>
  filter((oc) => {
    const { Repealed, OffenseRepealed } = oc;
    const repeal_date = Repealed ?? OffenseRepealed;
    // 480days after OffenseRepealed it is no longer valid
    return repeal_date ? dayjs(today) < dayjs(repeal_date).add(480, 'day') : true;
  }, window.offenseCodes ?? []);
