import React, { useState } from 'react'
import cn from 'classnames'
import { onEnter } from '@utility/keypressHelpers'
import './Checkbox.scss'

/* eslint-disable react/no-unused-prop-types */

export const Checkbox = (checkboxProps: Props) => {
  const { onChange, defaultValue, label, className, disabled, 'data-testid': dataTestID } = checkboxProps

  const [checked, setChecked] = useState(defaultValue)
  const toggleCheck = () => {
    setChecked(!checked)
    onChange?.(!checked)
  }

  return (
    <div className={cn('checkbox', className, { disabled })}>
      <div
        role="button"
        tabIndex={0}
        className={cn('checkbox__box', { checked })}
        data-testid={dataTestID}
        onClick={toggleCheck}
        onKeyUp={onEnter(toggleCheck)}
      >
        <div className="material-icons">
          done
        </div>
      </div>
      <div className={cn('checkbox__label', { checked })}>
        {label}
      </div>
    </div>
  )
}

interface Props {
  defaultValue?: boolean | 'true';
  onChange?: (val: boolean) => void;
  className: string;
  label: string;
  disabled?: boolean | 'true';
  'data-testid'?: string;
}
export default Checkbox
