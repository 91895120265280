import { connect, ConnectedProps } from 'react-redux';
import * as ConfigDuck from '@ducks/config';

const mapStateToProps = (state: any) => ({
  breakpoint: ConfigDuck.selectors.breakpoint(state),
  pageWidth: ConfigDuck.selectors.pageWidth(state),
});

const mapDispatchToProps = {
  updatePageWidth: ConfigDuck.updatePageWidth,
  checkOnlineStatus: ConfigDuck.checkOnlineStatus,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
