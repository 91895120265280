import React, { useEffect } from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { range } from 'ramda';
import { get } from 'lodash';
import { Switch, createSnackNotification, AlertLevel } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import * as Form from '@ducks/form';
import './RipaDisabilityForm2024.scss';
import { FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { Template } from '@engine/ducks';

const RipaDisabilityForm2024 = ({
  labels,
  numberOfPeople,
  gender,
  lgbt,
  sexualOrientation,
  sexualOrientationValues,
  english,
  disabled,
  unhoused,
  setLgbt,
  setSexualOrientation,
  setEnglish,
  setDisabled,
  setUnhoused,
  useSameGenderForAll,
  flags: { post2024 }
}: PropsFromRedux) => {

  // Check if LGBT was selected if so update perceived sexual orientation
  useEffect(() => {
    if (post2024) {
      range(0, numberOfPeople).forEach(personIndex => {
        const isTrans = gender[useSameGenderForAll ? 0 : personIndex]?.startsWith('Trans');
        if (isTrans && !sexualOrientation[personIndex]) {
          setSexualOrientation(personIndex, sexualOrientationValues['LGB+'].value);
        }
      })
    }
  }, [])

  const handleLGBT = (index: number, val: boolean, isTrans: boolean) => {
    if (isTrans) {
      createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')
    } else {
      setLgbt(index, val)
    }
  }

  function getSexualOrientation(personIndex: number) {
    if (sexualOrientation[personIndex]) {
      return sexualOrientation[personIndex];
    }
    // Old form loaded set new value from old value
    const sexualOrientationVal = lgbt[personIndex] ? 'LGB+' : '';
    if (sexualOrientationVal) {
      setSexualOrientation(personIndex, sexualOrientationVal);
    }
    return sexualOrientationVal;
  }

  return (
    <div className="ripa-disability-form-2024" data-testid="ripa-disability-form-2024">
      <div className="ripa-disability-form-2024__title">
        Were they perceived to be any of these?
      </div>
      <div className="ripa-disability-form-2024__labels-container">
        <div className="ripa-disability-form-2024__labels">
          {
            range(0, numberOfPeople).map((_, i) => {
              const isTrans = gender[useSameGenderForAll ? 0 : i]?.startsWith('Trans');
              return (
                <div className="ripa-disability-form-2024__selection" key={`RipaDisabilityFormLabels-${i}`}>
                  <div className="ripa-disability-form-2024__labels-title">
                    <div className="ripa-disability-form-2024__label">
                      <div className="material-icons">person</div>
                      <div className="ripa-disability-form-2024__label-text">{labels[i] || i + 1}</div>
                    </div>
                  </div>
                  <div className="ripa-disability-form-2024__labels-header">
                    {post2024 === false && (
                      <div className={cn('ripa-disability-form-2024__labels-header-label', { 'ripa-disability-form-2024__additional-padding': post2024 })}>
                        <div className="ripa-disability-form-2024__labels-header-title">
                          LGBT
                        </div>
                        <div
                          className="ripa-disability-form-2024__labels-header-switch-container"
                          onClick={() => isTrans && createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')}
                          role="presentation"
                        >
                          <Switch
                            className="ripa-disability-form__lgbt-switch"
                            data-testid={`ripa-disability-form-2024-lgbt-switch-${i}`}
                            onText="YES"
                            offText="NO"
                            value={Boolean(lgbt[i])}
                            onChange={(v) => setLgbt(i, v)}
                            disabled={isTrans}
                          />
                        </div>
                      </div>
                    )}
                    <div className={cn('ripa-disability-form-2024__labels-header-label', { 'ripa-disability-form-2024__additional-padding': post2024 })}>
                      <div className="ripa-disability-form-2024__labels-header-title">
                        LIMITED ENGLISH FLUENCY
                      </div>
                      <div
                        className="ripa-disability-form-2024__labels-header-switch-container"
                      >
                        <Switch
                          data-testid={`ripa-disability-form-2024-english-switch-${i}`}
                          onText="YES"
                          offText="NO"
                          value={Boolean(english[i])}
                          onChange={(v) => setEnglish(i, v)}
                        />
                      </div>
                    </div>
                    <div className={cn('ripa-disability-form-2024__labels-header-label', { 'ripa-disability-form-2024__additional-padding': post2024 })}>
                      <div className="ripa-disability-form-2024__labels-header-title">
                        DISABLED
                      </div>
                      <div
                        className="ripa-disability-form-2024__labels-header-switch-container"
                      >
                        <Switch
                          data-testid={`ripa-disability-form-2024-disability-switch-person-${i}`}
                          onText="YES"
                          offText="NO"
                          value={Boolean(disabled[i])}
                          onChange={(v) => setDisabled(i, v)}
                        />
                      </div>
                    </div>
                    <div className={cn('ripa-disability-form-2024__labels-header-label', { 'ripa-disability-form-2024__additional-padding': post2024 })}>
                      <div className="ripa-disability-form-2024__labels-header-title">
                        PERCEIVED TO BE UNHOUSED
                      </div>
                      <div
                        className="ripa-disability-form-2024__labels-header-switch-container"
                      >
                        <Switch
                          data-testid={`ripa-disability-form-2024-unhoused-switch-person-${i}`}
                          onText="YES"
                          offText="NO"
                          value={Boolean(unhoused[i])}
                          onChange={(v) => setUnhoused(i, v)}
                        />
                      </div>
                    </div>
                  </div>
                  {post2024 && (
                    <div className="ripa-disability-form-2024__sexual-orientation-container">
                      <FormControl
                        size="small"
                        variant="outlined"
                        className="ripa-disability-form-2024__sexual-orientation-form"
                        data-testid="ripa-disability-form-2024__sexual-orientation-form"
                        onClick={() => isTrans && createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')}
                      >
                        <div className="ripa-disability-form-2024__sexual-orientation-label">
                          PERCEIVED SEXUAL ORIENTATION
                        </div>
                        <Select
                          value={isTrans ? 'LGB+' : getSexualOrientation(i)}
                          disabled={isTrans}
                          renderValue={value => value === '' ? 'Select Perceived Sexual Orientation...' : value}
                          onChange={({ target: { value } }: any) => setSexualOrientation(i, value)}
                          displayEmpty
                          style={{ maxHeight: 36 }}
                        >
                          {[sexualOrientationValues['LGB+'], sexualOrientationValues.Straight].map(({ value }, si) => (
                            <MenuItem key={`${value}-${si}`} value={value}>
                              <ListItemText primary={value} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <div className="ripa-disability-form-2024__select-box-content">
                    {post2024 ? (
                      <div className="ripa-disability-form-2024__sexual-orientation-container-mobile">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="ripa-disability-form-2024__sexual-orientation-form"
                          data-testid="ripa-disability-form-2024__sexual-orientation-mobile-form"
                          onClick={() => isTrans && createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')}
                        >
                          <Select
                            value={isTrans ? 'LGB+' : getSexualOrientation(i)}
                            disabled={isTrans}
                            displayEmpty
                            style={{ maxHeight: 50, width: '100%' }}
                          >
                            <MenuItem value="" disabled>
                              Perceived Sexual Orientation
                            </MenuItem>
                          {[sexualOrientationValues['LGB+'], sexualOrientationValues.Straight].map(({ value }, si) => (
                            <MenuItem key={`${value}-${si}`} value={value} onClick={() => setSexualOrientation(i, value)}>
                              <ListItemText primary={value} />
                            </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                      </div>
                    ) : (
                      <div
                        className={cn('ripa-disability-form-2024__select-box', { active: isTrans || lgbt?.[i] })}
                        key={`RipaDisabilityFormBox-lgbt-${i}`}
                        onClick={() => handleLGBT(i, !lgbt?.[i], isTrans)}
                        onKeyUp={onEnter(() => handleLGBT(i, !lgbt?.[i], isTrans))}
                        data-testid={`ripa-disability-form-2024-lgbt-box-${i}`}
                        role="button"
                        tabIndex={0}
                      >
                        LGBT
                      </div>
                    )}
                    <div
                      className={cn('ripa-disability-form-2024__select-box', { active: english?.[i] })}
                      key={`RipaDisabilityFormBox-english-${i}`}
                      onClick={() => setEnglish(i, !english?.[i])}
                      onKeyUp={onEnter(() => setEnglish(i, !english?.[i]))}
                      data-testid={`ripa-disability-form-2024-english-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      LIMITED OR NO ENGLISH FLUENCY
                    </div>
                    <div
                      className={cn('ripa-disability-form-2024__select-box', { active: disabled?.[i] })}
                      key={`RipaDisabilityFormBox-disabled-${i}`}
                      onClick={() => setDisabled(i, !disabled?.[i])}
                      onKeyUp={onEnter(() => setDisabled(i, !disabled?.[i]))}
                      data-testid={`ripa-disability-form-2024-disabled-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      DISABLED
                    </div>
                    <div
                      className={cn('ripa-disability-form-2024__select-box', { active: unhoused?.[i] })}
                      key={`RipaDisabilityFormBox-unhoused-${i}`}
                      onClick={() => setUnhoused(i, !unhoused?.[i])}
                      onKeyUp={onEnter(() => setUnhoused(i, !unhoused?.[i]))}
                      data-testid={`ripa-disability-form-2024-unhoused-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      PERCEIVED TO BE UNHOUSED
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      <b>
        {'Select all that apply. '}
      </b>
      If they were perceived to be disabled, you will be given more options to select from after this screen.
    </div>
  </div>
);

RipaDisabilityForm2024.helper = Helper;

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  gender: Form.selectors.gender(state),
  lgbt: Form.selectors.lgbt(state),
  sexualOrientation: Form.selectors.sexualOrientation(state),
  english: Form.selectors.english(state),
  disabled: Form.selectors.disabled(state),
  unhoused: Form.selectors.unhoused(state),
  useSameGenderForAll: Form.selectors.useSameGenderForAll(state),
  sexualOrientationValues: get(Template.selectors.enumMap(state), 'SexualOrientation.possibleValues', {}),
  flags: Form.selectors.flags(state),
});

const mapDispatchToProps = {
  setUnhoused: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'unhoused'] })(value),
  setDisabled: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'disabled'] })(value),
  setEnglish: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'english'] })(value),
  setLgbt: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'lgbt'] })(value),
  setSexualOrientation: (personIndex: number, value: string) => Form.setFormField({ path: ['person', personIndex, 'sexualOrientation'] })(value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaDisabilityForm2024);
