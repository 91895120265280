import { connect, ConnectedProps } from 'react-redux';
import { User, Review } from '@ducks';
import { ColumnData } from '@engine/ducks/types';

const mapStateToProps = (state: any) => ({
  checkedReviewRows: Review.selectors.checkedReviewRows(state),
  userForms: Review.selectors.userForms(state),
  orgCanSubmitToDOJ: User.selectors.canSubmitToDOJ(state),
  expandedRow: Review.selectors.expandedRow(state),
  userId: User.selectors.userId(state),
  hasEdit: Review.selectors.hasEdit(state),
});

const mapDispatchToProps = {
  checkReviewRow: Review.checkReviewRow,
  setStopDescription: Review.setUserFormStopDescription,
  setSearchDescription: Review.setUserFormSearchDescription,
  approveReport: Review.approveReport,
  setReviewFeedbackDialogOpen: Review.setReviewFeedbackDialogOpen,
  setDojErrorsDialogOpen: Review.setDojErrorsDialogOpen,
  openReviewDialog: (formId: string) => Review.setReviewDialogOpen({ open: true, formId }),
  setExpandedRow: Review.setExpandedRow,
  addReviewTimeSpent: Review.addReviewTimeSpent,
  versionFormForReview: Review.versionFormForReview,
  setHasEdit: Review.setHasEdit,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  colData: ColumnData[];
  rowData: any;
  rowIndex: number;
};
