const getBoundingClientRect = function (ref: HTMLElement) {
  return ref.getBoundingClientRect()
}

const isInDocumentLayout = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  return rect.x !== 0 && rect.y !== 0
}

export {
  getBoundingClientRect,
  isInDocumentLayout
}
