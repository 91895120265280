import { assignRoute } from '@utility/assignRoute';
import { createSnackNotification, AlertLevel } from '@components/common';

import DynamicNumericForm from '@components/form/DynamicNumericForm/DynamicNumericForm';
import DynamicSelectForm from '@components/form/DynamicSelectForm/DynamicSelectForm';
import DynamicSelectFormLegacy from '@components/form/DynamicSelectFormLegacy/DynamicSelectFormLegacy';
import DynamicDropdownForm from '@components/form/DynamicDropdownForm/DynamicDropdownForm';
import DynamicTextForm from '@components/form/DynamicTextForm/DynamicTextForm';
import RipaCalendarForm from '@components/form/RipaCalendarForm/RipaCalendarForm';
import RipaTimeForm from '@components/form/RipaTimeForm/RipaTimeForm';
import RipaDurationForm from '@components/form/RipaDurationForm/RipaDurationForm';
import RipaLocationForm from '@components/form/RipaLocationForm/RipaLocationForm';
import RipaTypeOfAssignmentForm from '@components/form/RipaTypeOfAssignmentForm/RipaTypeOfAssignmentForm';
import RipaPeopleForm from '@components/form/RipaPeopleForm/RipaPeopleForm';
import RipaLabelForm from '@components/form/RipaLabelForm/RipaLabelForm';
import RipaGenderForm from '@components/form/RipaGenderForm/RipaGenderForm';
import RipaAgeForm from '@components/form/RipaAgeForm/RipaAgeForm';
import RipaRaceForm from '@components/form/RipaRaceForm/RipaRaceForm';
import RipaDisabilityForm from '@components/form/RipaDisabilityForm/RipaDisabilityForm';
import RipaDisabilityForm2024 from '@components/form/RipaDisabilityForm2024/RipaDisabilityForm2024';
import RipaDisabilityDetailsForm from '@components/form/RipaDisabilityDetailsForm/RipaDisabilityDetailsForm';
import RipaPrimaryReasonForm from '@components/form/RipaPrimaryReasonForm/RipaPrimaryReasonForm';
import RipaTrafficViolationForm from '@components/form/RipaTrafficViolationForm/RipaTrafficViolationForm';
import RipaSuspicionForm from '@components/form/RipaSuspicionForm/RipaSuspicionForm';
import RipaSearchDescriptionForm from '@components/form/RipaSearchDescriptionForm/RipaSearchDescriptionForm';
import RipaCodeSectionForm from '@components/form/RipaCodeSectionForm/RipaCodeSectionForm';
import RipaStoppedPassengerForm from '@components/form/RipaStoppedPassengerForm/RipaStoppedPassengerForm';
import RipaReasonGivenStoppedPersonForm from '@components/form/RipaReasonGivenStoppedPersonForm/RipaReasonGivenStoppedPersonForm';
import RipaStoppedInsideResidenceForm from '@components/form/RipaStoppedInsideResidenceForm/RipaStoppedInsideResidenceForm';
import RipaDescriptionForm from '@components/form/RipaDescriptionForm/RipaDescriptionForm';
import RipaActionTakenForm from '@components/form/RipaActionTakenForm/RipaActionTakenForm';
import RipaSearchBasisForm from '@components/form/RipaSearchBasisForm/RipaSearchBasisForm';
import RipaSeizureTypeForm from '@components/form/RipaSeizureTypeForm/RipaSeizureTypeForm';
import RipaSeizureBasisForm from '@components/form/RipaSeizureBasisForm/RipaSeizureBasisForm';
import RipaContrabandForm from '@components/form/RipaContrabandForm/RipaContrabandForm';
import RipaResultOfStopForm from '@components/form/RipaResultOfStopForm/RipaResultOfStopForm';
import RipaReviewForm from '@components/form/RipaReviewForm/RipaReviewForm';
import RipaResponseToCallForm from '@components/form/RipaResponseToCallForm/RipaResponseToCallForm';
import RipaContrabandOrEvidenceForm from '@components/form/RipaContrabandOrEvidenceForm/RipaContrabandOrEvidenceForm';
import RipaTabbedLocationForm from '@components/form/RipaTabbedLocationForm/RipaTabbedLocationForm';
import RipaStopForm from '@components/form/RipaStopForm/RipaStopForm';
import RipaTestCaseIdForm from '@components/form/RipaTestCaseIdForm/RipaTestCaseIdForm';
import RipaProbableCauseForm from '@components/form/RipaProbableCauseForm/RipaProbableCauseForm';

const getComponent = componentName => {
  if (!componentName) {
    return null;
  }
  switch (componentName) {
    case 'RipaTabbedLocationForm':
      return RipaTabbedLocationForm;
    case 'DynamicDropdownForm':
      return DynamicDropdownForm;
    case 'DynamicNumericForm':
      return DynamicNumericForm;
    case 'DynamicSelectForm':
      return DynamicSelectForm;
    case 'DynamicTextForm':
      return DynamicTextForm;
    case 'DynamicSelectFormLegacy':
      return DynamicSelectFormLegacy;
    case 'SearchDescription':
      return SearchDescription;
    case 'RipaReviewForm':
      return RipaReviewForm;
    case 'RipaCalendarForm':
      return RipaCalendarForm;
    case 'RipaTimeForm':
      return RipaTimeForm;
    case 'RipaDurationForm':
      return RipaDurationForm;
    case 'RipaLocationForm':
      return RipaLocationForm;
    case 'RipaTypeOfAssignmentForm':
      return RipaTypeOfAssignmentForm;
    case 'RipaPeopleForm':
      return RipaPeopleForm;
    case 'RipaLabelForm':
      return RipaLabelForm;
    case 'RipaGenderForm':
      return RipaGenderForm;
    case 'RipaAgeForm':
      return RipaAgeForm;
    case 'RipaSchoolForm':
      return RipaSchoolForm;
    case 'RipaRaceForm':
      return RipaRaceForm;
    case 'RipaDisabilityForm':
      return RipaDisabilityForm;
    case 'RipaDisabilityForm2024':
      return RipaDisabilityForm2024;
    case 'RipaDisabilityDetailsForm':
      return RipaDisabilityDetailsForm;
    case 'RipaPrimaryReasonForm':
      return RipaPrimaryReasonForm;
    case 'RipaReasonGivenStoppedPersonForm':
      return RipaReasonGivenStoppedPersonForm;
    case 'RipaTrafficViolationForm':
      return RipaTrafficViolationForm;
    case 'RipaSuspicionForm':
      return RipaSuspicionForm;
    case 'RipaSearchDescriptionForm':
      return RipaSearchDescriptionForm;
    case 'RipaCodeSectionForm':
      return RipaCodeSectionForm;
    case 'RipaStoppedPassengerForm':
      return RipaStoppedPassengerForm;
    case 'RipaStoppedInsideResidenceForm':
      return RipaStoppedInsideResidenceForm;
    case 'RipaDescriptionForm':
      return RipaDescriptionForm;
    case 'RipaActionTakenForm':
      return RipaActionTakenForm;
    case 'RipaSearchBasisForm':
      return RipaSearchBasisForm;
    case 'RipaSeizureTypeForm':
      return RipaSeizureTypeForm;
    case 'RipaSeizureBasisForm':
      return RipaSeizureBasisForm;
    case 'RipaContrabandForm':
      return RipaContrabandForm;
    case 'RipaResultOfStopForm':
      return RipaResultOfStopForm;
    case 'RipaResponseToCallForm':
      return RipaResponseToCallForm;
    case 'RipaContrabandOrEvidenceForm':
      return RipaContrabandOrEvidenceForm;
    case 'RipaStopForm':
      return RipaStopForm;
    case 'RipaTestCaseIdForm':
      return RipaTestCaseIdForm;
    case 'RipaProbableCauseForm':
      return RipaProbableCauseForm;
    default: {
      console.error('Could not find ', componentName);
      createSnackNotification(AlertLevel.Error, 'Failure', 'Report could not be loaded');
      setTimeout(() => assignRoute('/dashboard'), 1500);
      return null;
    }
  }
};

export { getComponent };
