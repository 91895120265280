import { AlertLevel, createSnackNotification } from '@components/common';
import { paths as Paths } from '@components/custom/Routes/paths';
import getEnv from '@engine/env';
import { Loader } from '@googlemaps/js-api-loader';
import breakpoint, { DEFAULT_BREAKPOINT, DEFAULT_WIDTH } from '@utility/breakpoint';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import getOnlineTimeoutByEffectiveType from '@utility/getOnlineTimeoutByEffectiveType';
import { clearTimeLimit, getOfflineTimeEnd, hasTimeLimit } from '@utility/timeLimitHelpers';
import { path, pathOr, prop } from 'ramda';
import { ConfigNamespace, UserNamespace } from './constants';
import { Action, Dispatch, GetState, ThunkDeps } from './types';

const DATE_LIMIT = 2;

// Initial State
export const INITIAL_STATE = {
  breakpoint: DEFAULT_BREAKPOINT,
  pageWidth: DEFAULT_WIDTH,
  online: 'unknown',
  lockOnlineState: false,
  forceUpdateSeed: 0,
  counter: 0,
  intervalId: null,
};

// Action Constants
export const UPDATE_PAGE_WIDTH = `${ConfigNamespace}/UPDATE_PAGE_WIDTH`;
export const UPDATE_ONLINE_STATUS = `${ConfigNamespace}/UPDATE_ONLINE_STATUS`;
export const FORCE_UPDATE = `${ConfigNamespace}/FORCE_UPDATE`;
export const UPDATE_COUNTER = `${ConfigNamespace}/UPDATE_COUNTER`;
export const SET_INTERVAL_ID = `${ConfigNamespace}/SET_INTERVAL_ID`;

// Reducer
export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ONLINE_STATUS:
      return {
        ...state,
        online: state.lockOnlineState ? state.online : payload.isOnline,
        lockOnlineState: payload.lockOnlineState,
      };

    case UPDATE_PAGE_WIDTH:
      return {
        ...state,
        pageWidth: payload.width,
        breakpoint: breakpoint(payload.width),
      };
    case FORCE_UPDATE:
      return {
        ...state,
        forceUpdateSeed: payload.seed,
      };
    case UPDATE_COUNTER:
      return {
        ...state,
        counter: payload.counter,
      };
    case SET_INTERVAL_ID:
      return {
        ...state,
        intervalId: payload.id,
      };
    default:
      return state;
  }
};

// Action Creators
export const updatePageWidth = (width: number) => ({
  type: UPDATE_PAGE_WIDTH,
  payload: { width },
});

export const updateOnlineStatus = (isOnline: boolean) => ({
  type: UPDATE_ONLINE_STATUS,
  payload: { isOnline },
});

export const updateOnlineStatusWithLock = ({ isOnline, lockOnlineState }: { isOnline: boolean; lockOnlineState: boolean }) => ({
  type: UPDATE_ONLINE_STATUS,
  payload: { isOnline, lockOnlineState },
});

export const forceUpdate = (seed: number) => ({
  type: FORCE_UPDATE,
  payload: { seed },
});

export const updateCounter = (counter: number) => ({
  type: UPDATE_COUNTER,
  payload: { counter },
});

export const setIntervalId = (id: NodeJS.Timer | null) => ({
  type: SET_INTERVAL_ID,
  payload: { id },
});

export const checkOnlineStatus =
  () =>
  (dispatch: Dispatch<any>, getState: GetState, { http, offlineConfig }: ThunkDeps) => {
    const {
      REACT_APP_FEATURE_FLAGS: { serviceWorkerFeature },
      REACT_APP_GOOGLE_API_KEY,
    } = getEnv();
    const offlineModeAllowed = localStorage.getItem('contact-offline-mode-allowed') === 'true';

    if (!serviceWorkerFeature || !offlineModeAllowed) {
      // Should behave as always online if no service worker
      offlineConfig.setItem('work-offline', 'false');
      localStorage.setItem('contact-is-online', 'true');
      dispatch(updateOnlineStatus(true));
      clearTimeLimit();
      return;
    }

    // Wait 3 seconds to check if connection is stable and open login dialog or check session
    const checkForLogin = () =>
      setTimeout(async () => {
        const isOnline = localStorage.getItem('contact-is-online') === 'true';
        const workingOffline = await offlineConfig.getItem('work-offline').then((w) => String(w) === 'true');
        const {
          authenticated,
          organizationInfo: { auth_provider },
        } = getState()[UserNamespace];
        const isNewReportPage = location.href.includes(Paths.NewReport.path.split('/')[1]);
        const isNotSSO = auth_provider !== 'AzureAD' && auth_provider !== 'OneLogin';

        if (isOnline && isNotSSO && workingOffline && authenticated !== 'success' && isNewReportPage) {
          decoupledDispatch('User.setLoginDialogOpen', { open: true });
        }
        if (isOnline && workingOffline && authenticated === 'success') {
          decoupledDispatch('User.checkSession', { forceOnline: true });
        }
        if (!workingOffline && !window.google) {
          const loader = REACT_APP_GOOGLE_API_KEY
            ? new Loader({
                apiKey: REACT_APP_GOOGLE_API_KEY,
                version: '3.45',
                libraries: ['places'],
              })
            : null;
          if (loader) {
            loader.load();
          }
        }
      }, 3000);

    const showMessage = () => {
      const isOnline = localStorage.getItem('contact-is-online') === 'true';
      if (!isOnline && location.href.includes(Paths.Dashboard.path)) {
        createSnackNotification(AlertLevel.Info, 'Internet connected!');
      }
    };

    const countdownCallback = () => {
      const timeEnd = getOfflineTimeEnd();
      if (timeEnd) {
        const newTimeLeft = timeEnd.getTime() - new Date().getTime();
        dispatch(updateCounter(newTimeLeft >= 0 ? newTimeLeft : 0));
      }
    };

    return http
      .get('/users/session', { 'x-use-network': true }, { timeout: getOnlineTimeoutByEffectiveType() })
      .then(async () => {
        showMessage();
        localStorage.setItem('contact-is-online', 'true');
        dispatch(updateOnlineStatus(true));
        checkForLogin();

        if (hasTimeLimit()) {
          const intervalInStorage = localStorage.getItem('intervalId');
          const timeLimit = getOfflineTimeEnd();
          const timeLeft = timeLimit ? timeLimit.getTime() - new Date().getTime() : 0;
          dispatch(updateCounter(timeLeft));
          clearInterval(intervalInStorage && intervalInStorage !== 'null' ? parseInt(intervalInStorage, 10) : 0);

          const id = setInterval(countdownCallback, 1000);
          dispatch(setIntervalId(id));
          localStorage.setItem('intervalId', id.toString());
        }
      })
      .catch(async (e) => {
        if (e?.message?.includes('401')) {
          showMessage();
          checkForLogin();
          localStorage.setItem('contact-is-online', 'true');
          dispatch(updateOnlineStatus(true));

          return;
        }

        localStorage.setItem('contact-is-online', 'false');
        await offlineConfig.setItem('work-offline', 'true');
        dispatch(updateOnlineStatus(false));

        const offlineTimeEnd = new Date();
        // offlineTimeEnd.setSeconds(offlineTimeEnd.getSeconds() + 50); // for experimental purpose, set to 20 seconds
        offlineTimeEnd.setDate(offlineTimeEnd.getDate() + DATE_LIMIT); // uncomment if testing had been done
        const intervalInStorage = localStorage.getItem('intervalId');

        if (!hasTimeLimit()) {
          // if there is no time limit, start time limiter
          clearTimeLimit();
          localStorage.setItem('offline-time-end', offlineTimeEnd.toString());
          const currentDate = new Date();
          if (offlineTimeEnd) {
            const timeLeft = offlineTimeEnd.getTime() - currentDate.getTime();
            dispatch(updateCounter(timeLeft));

            const id = setInterval(countdownCallback, 1000);
            dispatch(setIntervalId(id));
            localStorage.setItem('intervalId', id.toString());
          }
        } else if (intervalInStorage) {
          // if there is time limit and interval id in storage, reuse interval id, and start time limiter
          // => this is for when user refreshes, redirect the page
          const timeLimit = getOfflineTimeEnd();
          const timeLeft = timeLimit ? timeLimit.getTime() - new Date().getTime() : 0;
          clearInterval(intervalInStorage && intervalInStorage !== 'null' ? parseInt(intervalInStorage, 10) : 0);
          dispatch(updateCounter(timeLeft));

          const id = setInterval(countdownCallback, 1000);
          dispatch(setIntervalId(id));
          localStorage.setItem('intervalId', id.toString());
        }

        decoupledDispatch('Dashboard.gotoOfflineMode');
        decoupledDispatch('User.checkSession', { forceOnline: false, forceStorage: true });
      });
  };

// State Selectors
export const selectors = {
  config: prop(ConfigNamespace),
  online: path([ConfigNamespace, 'online']),
  breakpoint: pathOr<string>(DEFAULT_BREAKPOINT, [ConfigNamespace, 'breakpoint']),
  pageWidth: path([ConfigNamespace, 'pageWidth']),
  forceUpdateSeed: pathOr<number>(0, [ConfigNamespace, 'forceUpdateSeed']),
  counter: path<number>([ConfigNamespace, 'counter']),
  intervalId: path<NodeJS.Timer | null>([ConfigNamespace, 'intervalId']),
};

export default reducer;
