import { connect, ConnectedProps } from 'react-redux';
import { Users } from '@ducks';

const mapStateToProps = (state: any) => ({
  selectAllChecked: Users.selectors.selectAllChecked(state),
});

const mapDispatchToProps = {
  selectAllUsersRows: Users.selectAllUsersRows,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
