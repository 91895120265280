import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { range } from 'ramda';
import { Switch, createSnackNotification, AlertLevel } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import * as Form from '@ducks/form';
import './RipaDisabilityForm.scss';

const RipaDisabilityForm = ({
  labels,
  numberOfPeople,
  gender,
  lgbt,
  english,
  disabled,
  setLgbt,
  setEnglish,
  setDisabled,
  useSameGenderForAll,
}: PropsFromRedux) => {
  const handleLGBT = (index: number, val: boolean, isTrans: boolean) => {
    if (isTrans) {
      createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')
    } else {
      setLgbt(index, val)
    }
  }

  return (
    <div className="ripa-disability-form" data-testid="ripa-disability-form">
      <div className="ripa-disability-form__title">
        Were they perceived to be any of these?
      </div>
      <div className="ripa-disability-form__labels-container">
        <div className="ripa-disability-form__labels-header">
          <div className="ripa-disability-form__labels-header-person">
            PERSON
        </div>
          <div className="ripa-disability-form__labels-header-lgbt">
            LGBT
        </div>
          <div className="ripa-disability-form__labels-header-english">
            LIMITED ENGLISH FLUENCY
        </div>
          <div className="ripa-disability-form__labels-header-disabled">
            DISABLED
        </div>
        </div>
        <div className="ripa-disability-form__labels">
          {
            range(0, numberOfPeople).map((_, i) => {
              const isTrans = gender[useSameGenderForAll ? 0 : i]?.startsWith('Trans');
              return (
                <div className="ripa-disability-form__selection" key={`RipaDisabilityFormLabels-${i}`}>
                  <div className="ripa-disability-form__label">
                    <div className="material-icons">person</div>
                    <div className="ripa-disability-form__label-text">{labels[i] || i + 1}</div>
                  </div>
                  <div className="ripa-disability-form__select-box-content">
                    <div
                      className={cn('ripa-disability-form__select-box', { active: isTrans || lgbt?.[i] })}
                      key={`RipaDisabilityFormBox-lgbt-${i}`}
                      onClick={() => handleLGBT(i, !lgbt?.[i], isTrans)}
                      onKeyUp={onEnter(() => handleLGBT(i, !lgbt?.[i], isTrans))}
                      data-testid={`ripa-disability-form-lgbt-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      LGBT
                  </div>
                    <div
                      className={cn('ripa-disability-form__select-box', { active: english?.[i] })}
                      key={`RipaDisabilityFormBox-english-${i}`}
                      onClick={() => setEnglish(i, !english?.[i])}
                      onKeyUp={onEnter(() => setEnglish(i, !english?.[i]))}
                      data-testid={`ripa-disability-form-english-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      LIMITED OR NO ENGLISH FLUENCY
                  </div>
                    <div
                      className={cn('ripa-disability-form__select-box', { active: disabled?.[i] })}
                      key={`RipaDisabilityFormBox-disabled-${i}`}
                      onClick={() => setDisabled(i, !disabled?.[i])}
                      onKeyUp={onEnter(() => setDisabled(i, !disabled?.[i]))}
                      data-testid={`ripa-disability-form-disabled-box-${i}`}
                      role="button"
                      tabIndex={0}
                    >
                      DISABLED
                  </div>
                  </div>
                  <div
                    onClick={() => isTrans && createSnackNotification(AlertLevel.Info, 'LGBT cannot be changed', 'Person marked as transgender on previous step.')}
                    role="presentation"
                  >
                    <Switch
                      className="ripa-disability-form__lgbt-switch"
                      data-testid={`ripa-disability-form-lgbt-switch-${i}`}
                      onText="YES"
                      offText="NO"
                      value={Boolean(lgbt[i])}
                      onChange={(v) => setLgbt(i, v)}
                      disabled={isTrans}
                    />
                  </div>
                  <Switch
                    className="ripa-disability-form__english-switch"
                    data-testid={`ripa-disability-form-english-switch-${i}`}
                    onText="YES"
                    offText="NO"
                    value={Boolean(english[i])}
                    onChange={(v) => setEnglish(i, v)}
                  />
                  <Switch
                    className="ripa-disability-form__disability-switch"
                    data-testid={`ripa-disability-form-disability-switch-person-${i}`}
                    onText="YES"
                    offText="NO"
                    value={Boolean(disabled[i])}
                    onChange={(v) => setDisabled(i, v)}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      <b>
        {'Select all that apply. '}
      </b>
      If they were perceived to be disabled, you will be given more options to select from after this screen.
    </div>
  </div>
);

RipaDisabilityForm.helper = Helper;

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  gender: Form.selectors.gender(state),
  lgbt: Form.selectors.lgbt(state),
  english: Form.selectors.english(state),
  disabled: Form.selectors.disabled(state),
  useSameGenderForAll: Form.selectors.useSameGenderForAll(state),
});

const mapDispatchToProps = {
  setDisabled: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'disabled'] })(value),
  setEnglish: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'english'] })(value),
  setLgbt: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'lgbt'] })(value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaDisabilityForm);
