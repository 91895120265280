import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  codeSection48900: Form.selectors.codeSection48900(state),
  codeSection48900Subdivision: Form.selectors.codeSection48900Subdivision(state),
  EC_Subdivision: get(Template.selectors.enumMap(state), 'EC_Subdivision.possibleValues', {}),
  DisciplineUnderEC: get(Template.selectors.enumMap(state), 'DisciplineUnderEC.possibleValues', {}),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
});

const mapDispatchToProps = {
  setCodeSection48900: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900'] })(value),
  setCodeSection48900Subdivision: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900Subdivision'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
