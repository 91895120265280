import React, { useState, useLayoutEffect } from 'react';
import { omit } from 'ramda';
import classNames from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import './Switch.scss';

type SwitchProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> & Props
const Switch = (switchProps: SwitchProps) => {
        const {
        onChange,
        offText,
        onText,
        className,
        value,
        defaultValue,
        disabled,
        disableChangeOnMount,
        'data-testid': dataTestID
    } = switchProps;
    const [switchOn, setSwitch] = useState(value ?? defaultValue ?? true);

    const toggleSwitch = () => {
        setSwitch(!(value ?? switchOn));
        onChange?.(!value);
    }
    const props = omit(['onClick', 'onChange', 'offText', 'onText', 'defaultValue', 'value', 'disabled', 'disableChangeOnMount'], switchProps);

    if (!disableChangeOnMount) {
        useLayoutEffect(() => {
            onChange?.(switchOn);
        }, []);
    }

    const classes = classNames(
        'switch',
        {
            'has-off': Boolean(offText),
            'has-on': Boolean(onText),
            'is-on': value ?? switchOn,
            disabled,
        },
        className);

    return (
        <div
          {...props}
          data-testid={dataTestID || 'switch'}
          tabIndex={0}
          role="button"
          className={classes}
          onKeyUp={onEnter(toggleSwitch)}
          onClick={toggleSwitch}
        >
            <div className="switch__text-on">
                {onText}
            </div>
            <div className="switch__thumb" />
            <div className="switch__text-off">
                {offText}
            </div>
        </div>
    );
}

interface Props {
    onClick?: Function;
    className?: string;
    offText: string;
    onText: string;
    defaultValue?: boolean | 'true';
    disableChangeOnMount?: boolean;
    value: boolean;
    'data-testid'?: string;
    disabled?: boolean | 'true';
    onChange?: (val: boolean) => void;
}

export default Switch;
