/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Papa from 'papaparse';
import { createSnackNotification, AlertLevel, Loading } from '@components/common';
import { connect, ConnectedProps } from 'react-redux';
import { Organizations, Config } from '@ducks';
import Handsontable from 'opentable';
import 'opentable/dist/handsontable.full.css';
import './UploadUserCsvDialog.scss';
import { uniq } from 'lodash';

type ValidatorCallback = (valid: boolean) => void;
type Validator = (value: string, cb: ValidatorCallback) => void

// TODO: figure out why the OpenTable types don't show validators as valid
((table: any) => {
  const emailValidator: Validator = (value, callback) =>
    callback(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value));

  const uidValidator: Validator = (value, callback) => callback(/^$|^[a-zA-Z0-9]{9}$/.test(value));

  const usernameValidator: Validator = (value, callback) => callback(!/^$|\s+/.test(value));

  const nameValidator: Validator = (value, callback) => callback(/^[a-zA-Z 0-9.'-]+$/.test(value));

  const yearsofexperienceValidator: Validator = (value, callback) => callback(/^[0-9]+$/.test(value));

  const rolesValidator: Validator = (value, callback) =>
    callback(/^(admin|reviewer|user|officer|analyst)((,|,\s)(?!\1)(admin|reviewer|user|officer|analyst))?((,|,\s)(?!\4)(?!\1)(admin|reviewer|user|officer|analyst))?((,|,\s)(?!\4)(?!\1)(?!\7)(admin|reviewer|user|officer|analyst))?((,|,\s)(?!\4)(?!\1)(?!\7)(?!\10)(admin|reviewer|user|officer|analyst))?$/.test(value));

  const passwordValidator: Validator = (value, callback) => callback(/[0-9]/g.test(value) && /[A-Z]/g.test(value) && /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g.test(value));

  table.validators.registerValidator('emailValidator', emailValidator);
  table.validators.registerValidator('uidValidator', uidValidator);
  table.validators.registerValidator('usernameValidator', usernameValidator);
  table.validators.registerValidator('firstnameValidator', nameValidator);
  table.validators.registerValidator('lastnameValidator', nameValidator);
  table.validators.registerValidator('yearsofexperienceValidator', yearsofexperienceValidator);
  table.validators.registerValidator('rolesValidator', rolesValidator);
  table.validators.registerValidator('passwordValidator', passwordValidator);
})(Handsontable as any);

const columnValidation = (columns: any[]) => {
  const validationColumn = [] as any[];
  columns.forEach((column, index) => {
    validationColumn.push({ data: index, validator: `${column}Validator` });
  });
  return validationColumn;
};

interface Props extends PropsFromRedux {
  setPositiveAction?: (action: () => void) => void;
  setNegativeAction?: (action: (next: () => void) => void) => void;
}

export const UploadUserCsvDialog = ({
  setUploadUserDialogUsers,
  uploadUserCsvDialog,
  forceUpdate,
  setPositiveAction,
  uploadUsers,
  clearUploadDialogData,
  setNegativeAction,
  setUploadDialogConfirmOpen,
  setUploadUserCSVDialogValidation,
}: Props) => {
  const { userList, columns, complete, failed, orgId, orgName, errors, uploading, total, progress, hasConfirmed } = uploadUserCsvDialog;
  const [, setTable] = useState<Handsontable>();
  const [hasFile, setHasFile] = useState(false);
  const [inValidCells, setInvalidCells] = useState<{[key:number]: {row: number; col: string[]}}>({});
  const [completeValidation, setCompleteValidation] = useState(false);
  const [initialValidation, setInitialValidation] = useState(false);

  const afterValidation = (isValid:boolean, _value:string, row:number, prop:string | number) => {
    const col = columns[prop];
    if (!isValid) {
      setInvalidCells(state => ({
          ...state,
          [row]: { row, col: state?.[row]?.col ? uniq([...state?.[row].col, col]) : [col] }
        })
      );
    } else {
      setInvalidCells(state => {
        if (state[row]?.col.length > 0 && state[row]) {
          const index = state[row].col.indexOf(columns[prop]);
          if (index !== -1) {
            state[row].col.splice(index, 1);
          }
          if (state[row].col.length === 0) {
            delete state[row]
          }
        }
        return state;
      });
    }
    setCompleteValidation(true);
    if (prop === 0 && row === 0) {
      setInitialValidation(true);
    }
  }

  const afterChange = (changes:[number, string | number, string, string][]) => {
    const newUserList = userList;
    changes?.forEach(([row, col, , newValue]) => {
      newUserList[row] = { ...newUserList[row], [columns[col]]: newValue }
    });
    setUploadUserDialogUsers({ users: newUserList, columns });
  }

  useEffect(() => {
    if (completeValidation && initialValidation) {
      setUploadUserCSVDialogValidation(inValidCells);
      setCompleteValidation(false);
    }
  }, [completeValidation, initialValidation])

  useEffect(() => {
    function errorRowRenderer(_: any, td: HTMLElement) {
      // @ts-ignore
      (Handsontable.renderers.TextRenderer as any).apply(this, arguments);
      td.style.background = 'rgb(255, 161, 161)';
      td.classList.add('error-highlight');
    }

    const container = document.getElementById('upload-user-csv-dialog-table');
    if (container && userList.length > 0) {
      container.innerHTML = '';
      const table = new Handsontable(container, {
        data: userList.map((u: any) => Object.values(u)),
        colHeaders: columns,
        rowHeaders: true,
        height: 300,
        cells(row) {
          const cellProperties = {};
          if (Object.keys(errors).includes(`${row}`) && progress) {
            (cellProperties as any).renderer = errorRowRenderer;
          }
          return cellProperties;
        },
        afterChange: (changes) => afterChange(changes),
        columns: columnValidation(columns),
        afterValidate: (isValid, _value, row, prop) => afterValidation(isValid, _value, row, prop),
      });
      (table as any).validateCells();
      setTable(table);
      forceUpdate(userList.length + failed);
    }
  }, [userList, progress]);

  setPositiveAction?.(() => {
    if (!hasFile && !hasConfirmed) {
      createSnackNotification(AlertLevel.Warning, 'No input file', 'Please select a user CSV to upload');
      return;
    }
    if (failed > 0) {
      createSnackNotification(AlertLevel.Warning, 'Validation error', 'Please correct the invalid field');
      return;
    }
    if (hasConfirmed) {
      uploadUsers(Object.keys(errors));
    } else {
      setUploadDialogConfirmOpen({ open: true, confirmed: false });
    }
  });

  setNegativeAction?.((next) => {
    clearUploadDialogData();
    setInvalidCells([]);
    setCompleteValidation(false);
    next();
  });

  return (
    <div className="upload-user-csv-dialog" data-testid="upload-user-csv-dialog">
      <div className={cn('upload-user-csv-dialog__upload-spinner', { uploading })} data-testid="upload-user-csv-dialog-upload-spinner">
        <div className="upload-user-csv-dialog__upload-spinner-loading">
          <div>{`Uploading... (${progress}/${total})`}</div>
          <Loading />
        </div>
      </div>
      <div className="upload-user-csv-dialog__input-and-org">
        <input
          className="upload-user-csv-dialog__input"
          data-testid="upload-user-csv-dialog-input"
          type="file"
          accept=".csv"
          onChange={(ev) => {
            const reader = new FileReader();
            reader.onload = function () {
              try {
                const users = Papa.parse<any>(reader.result as string, { header: true, skipEmptyLines: true }).data;
                const parsedColumns = Array.from(users.reduce((acc, u) => new Set([...acc, ...Object.keys(u)]), []));
                const required = ['email', 'username', 'firstname', 'lastname'];
                const whitelist = [...required, 'uid', 'yearsofexperience', 'roles', 'password'];

                parsedColumns.forEach((c) => {
                  if (!whitelist.includes(c as string)) {
                    throw new Error(`Column '${c}' not allowed`);
                  }
                });
                required.forEach((r) => {
                  if (!parsedColumns.includes(r)) {
                    throw new Error(`Column '${r}' is required`);
                  }
                });

                setUploadUserDialogUsers({ users, columns: parsedColumns });
                setHasFile(true);
              } catch (e1: any) {
                createSnackNotification(AlertLevel.Error, 'Error', `Parsing csv failed </br> <small>${e1?.message ?? ''}</small>`);
                setHasFile(false);
              }
            };
            try {
              if (ev.target.files) {
                reader.readAsText(ev.target.files[0]);
              }
            } catch (e2: any) {
              createSnackNotification(AlertLevel.Error, 'Error', `Unable to read file </br> <small>${e2?.message ?? ''}</small>`);
              setHasFile(false);
            }
          }}
        />
        <div className="upload-user-csv-dialog__org">{`${orgName} (${orgId})`}</div>
      </div>

      <div id="upload-user-csv-dialog-table" />
      {complete && failed > 0 && (
        <div className="upload-user-csv-dialog__errors">
          <div className="upload-user-csv-dialog__errors-title">Errors</div>
          {Object.entries(errors).map((v, i) => {
            const [rowIndex, e] = v;
            return (
              <div className="upload-user-csv-dialog__errors-row" key={`UploadoUserCsvDialogError-${i}`}>
                <p>{`Row ${parseInt(rowIndex, 10) + 1}: ${(e as any).message}`}</p>
                {(e as any).errors &&
                  Object.entries((e as any).errors).map((vv, ii) => {
                    const [param, msg] = vv;
                    return (
                      <p key={`UploadoUserCsvDialogErrorDetails-${ii}`}>
                        <small>{`${param}: ${msg}`}</small>
                      </p>
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
      {complete && failed === 0 && !progress && <div className="upload-user-csv-dialog__success">User validation successful</div>}
      {complete && failed === 0 && progress && <div className="upload-user-csv-dialog__success">User creation successful</div>}
    </div>
  );
};

// UploadUserCsvDialog.propTypes = {
//   forceUpdate: PropTypes.func,
//   clearUploadDialogData: PropTypes.func,
//   setUploadUserDialogUsers: PropTypes.func,
//   setUploadDialogConfirmOpen: PropTypes.func,
//   setPositiveAction: PropTypes.func,
//   setNegativeAction: PropTypes.func,
//   uploadUsers: PropTypes.func,
//   uploadUserCsvDialog: PropTypes.object,
//   setUploadUserCSVDialogValidation: PropTypes.func,
// };

const mapDispatchToProps = {
  setUploadUserDialogUsers: Organizations.setUploadUserDialogUsers,
  uploadUsers: Organizations.uploadUsers,
  forceUpdate: Config.forceUpdate,
  clearUploadDialogData: Organizations.clearUploadDialogData,
  setUploadDialogConfirmOpen: Organizations.setUploadDialogConfirmOpen,
  setUploadUserCSVDialogValidation: Organizations.setUploadUserCSVDialogValidation,
};

const mapStateToProps = (state: any) => ({
  uploadUserCsvDialog: Organizations.selectors.uploadUserCsvDialog(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UploadUserCsvDialog);
