import { AlertLevel, DrawerManager, Snackbar, createSnackNotification } from '@components/common';
import { hasPath } from '@components/custom/Routes/Routes';
import { paths as Paths } from '@components/custom/Routes/paths';
import UserSettingsDialog from '@components/custom/UserSettingsDialog/UserSettingsDialog';
import * as Config from '@ducks/config';
import { RoleNumber } from '@ducks/constants';
import * as User from '@ducks/user';
import { offlineConfig } from '@engine/dependencies/localforage';
import { assignRoute } from '@utility/assignRoute';
import { onEnter } from '@utility/keypressHelpers';
import { calculateTime, counterEffect, formatTime, hasTimeLimit } from '@utility/timeLimitHelpers';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import Default_Icon from './Default_Icon.png';
import './Header.scss';
import veritoneIcon from './V_Icon.png';

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */

interface Props extends PropsFromRedux {
  children?: React.ReactNode | React.ReactNode[];
  orgPhoto?: string;
  title?: string;
  drawerOpenDefault?: boolean;
  className?: string;
}

const Header = ({
  children,
  orgPhoto,
  title,
  drawerOpenDefault,
  breakpoint,
  logout,
  assignedRoles,
  className,
  smallLogo,
  online,
  workOnline,
  state,
  setUserSettingsDialogOpen,
  counter,
  intervalId,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(drawerOpenDefault ?? false);
  const [navOpen, setNavOpen] = useState(false);
  const [isWorkingOffline, setIsWorkingOffline] = useState(false);
  const [cachedRoles, setCachedRoles] = useState([]);
  const [timeLeftMessage, setTimeLeftMessage] = useState('');

  async function loadingOfflineStatus() {
    // IndexedDB observers are currently experimental
    const workOfflineStatus = await offlineConfig.getItem('work-offline')
      .then(wo => String(wo) === 'true')
    setIsWorkingOffline(workOfflineStatus)
    const mappedRoles = await offlineConfig.getItem('user')
      .then((user: any) => user?.roles?.map((role: any) => RoleNumber[role]))
    setCachedRoles(mappedRoles)
  }

  useEffect(() => {
    loadingOfflineStatus();
  }, [navOpen, online])
  useEffect(() => {
    setDrawerOpen(breakpoint === 'lg');
  }, [breakpoint]);

  useEffect(() => {
    const exceedTimeLimitCallback = () => {
      if (hasTimeLimit() && isWorkingOffline) {
        createSnackNotification(AlertLevel.Warning, 'Your offline time has expired', 'Please connect to the internet to continue using the application');
        setTimeLeftMessage('Login to create');
      }
    };
    const stillCountingCallback = (timeLeft: number) => {
      const { hours, minutes, seconds } = calculateTime(timeLeft);
      setTimeLeftMessage(`${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`);
    };
    counterEffect(counter, intervalId, exceedTimeLimitCallback, stillCountingCallback);
  }, [counter, intervalId]);

  // @ts-ignore
  const dmProps = React.Children.toArray(children).find((child: any) => child.type === DrawerManager)?.props;
  const hasDrawer = dmProps !== undefined;
  const roles = assignedRoles ?? cachedRoles ?? [];

  return (
    <div className={`header ${className ?? ''}`} data-testid="header">
      <header>
        {hasDrawer && (
          <div
            className="header__drawer-button material-icons"
            role="button"
            tabIndex={0}
            aria-label="Open site drawer"
            data-testid="header-drawer-button"
            onClick={() => setDrawerOpen(!drawerOpen)}
            onKeyUp={onEnter(() => setDrawerOpen(!drawerOpen))}
            aria-pressed={drawerOpen}
          >
            menu
          </div>
        )}
        <img
          className="header__veritone-logo"
          src={veritoneIcon}
          alt="Veritone logo"
          role="button"
          tabIndex={0}
          onClick={() => assignRoute('/dashboard')}
          onKeyUp={onEnter(() => assignRoute('/dashboard'))}
        />
        {title && (
          <div
            className={cn('header__title', { 'no-drawer': !hasDrawer })}
            aria-label="site title"
            data-testid="header-title"
            role="link"
            tabIndex={0}
            onClick={() => assignRoute('/dashboard')}
            onKeyUp={onEnter(() => assignRoute('/dashboard'))}
          >
            {title}
          </div>
        )}
        <div className="header__grow">
          {timeLeftMessage && hasTimeLimit() && <div className="header__time">{(breakpoint !== 'xs' && timeLeftMessage !== 'Login to create' ? 'Time Left: ' : '') + timeLeftMessage}</div>}
        </div>
        {orgPhoto && (
          <div className="header__org-logo" data-testid="header-org-photo">
            <img alt="Your organization's logo" src={orgPhoto} />
          </div>
        )}
        <div className="header__veritone-logo-nav">
          <div
            onClick={() => setNavOpen(true)}
            onKeyUp={onEnter(() => setNavOpen(true))}
            role="button"
            aria-label="Open navigation"
            data-testid="header-navigation"
            className={cn('header__veritone-logo-button', { active: navOpen })}
            tabIndex={0}
          >
            <img
              className="header__department-logo"
              src={smallLogo || Default_Icon}
              alt="Police department logo"
            />
          </div>
          <div
            className={cn('header__veritone-nav-clickoff', { open: navOpen })}
            onClick={() => setNavOpen(false)}
            onKeyUp={onEnter(() => setNavOpen(false))}
            role="button"
            aria-label="Close navigation"
            tabIndex={0}
          />
          <div className={cn('header__veritone-nav', { open: navOpen })}>
            <div
              className={cn('header__veritone-nav-my-reports', {
                active: location.href.includes(Paths.Dashboard.path),
                show: hasPath({ path: Paths.Dashboard, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              data-testid="header__veritone-nav-my-reports"
              onClick={() => assignRoute(Paths.Dashboard.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.Dashboard.path))}
              role="button"
              aria-label="Navigate to dashboard page"
              tabIndex={0}
            >
              My Reports
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.Dashboard, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div
              className={cn('header__veritone-nav-reviewer', {
                active: location.href.includes(Paths.Review.path),
                show: hasPath({ path: Paths.Review, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              onClick={() => assignRoute(Paths.Review.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.Review.path))}
              role="button"
              aria-label="Navigate to review page"
              tabIndex={0}
            >
              Review
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.Review, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div
              className={cn('header__veritone-nav-visualization', {
                active: location.href.includes(Paths.Visualization.path),
                show: hasPath({ path: Paths.Visualization, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              onClick={() => assignRoute(Paths.Visualization.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.Visualization.path))}
              role="button"
              aria-label="Navigate to Visualization page"
              tabIndex={0}
            >
              Visualization
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.Visualization, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div
              className={cn('header__veritone-nav-user-man', {
                active: location.href.includes(Paths.Users.path),
                show: hasPath({ path: Paths.Users, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              onClick={() => assignRoute(Paths.Users.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.Users.path))}
              role="button"
              aria-label="Navigate to user management page"
              tabIndex={0}
            >
              User Management
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.Users, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div
              className={cn('header__veritone-nav-global-config', {
                active: location.href.includes(Paths.GlobalConfiguration.path),
                show: hasPath({ path: Paths.GlobalConfiguration, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              onClick={() => assignRoute(Paths.GlobalConfiguration.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.GlobalConfiguration.path))}
              role="button"
              aria-label="Navigate to global configuration page"
              tabIndex={0}
            >
              Global Configuration
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.GlobalConfiguration, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div className="header__veritone-nav-hr" />
            <div
              className={cn('header__veritone-nav-user-man', {
                active: location.href.includes(Paths.Organizations.path),
                show: hasPath({ path: Paths.Organizations, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              onClick={() => assignRoute(Paths.Organizations.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.Organizations.path))}
              role="button"
              aria-label="Navigate to organizations page"
              tabIndex={0}
            >
              Organizations
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.Organizations, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            <div
              className={cn('header__veritone-nav-user-man', {
                active: location.href.includes(Paths.CustomQuestions.path),
                show: hasPath({ path: Paths.CustomQuestions, state, assignedRoles: roles, isWorkingOffline, breakpoint })
              })}
              data-testid="header-veritone-nav-question-designer"
              onClick={() => assignRoute(Paths.CustomQuestions.path)}
              onKeyUp={onEnter(() => assignRoute(Paths.CustomQuestions.path))}
              role="button"
              aria-label="Navigate to Custom Questions page"
              tabIndex={0}
            >
              Question Designer
            </div>
            <div className={cn('header__veritone-nav-hr', { show: hasPath({ path: Paths.CustomQuestions, state, assignedRoles: roles, isWorkingOffline, breakpoint }) })} />
            {!isWorkingOffline && <div
              className="header__veritone-nav-settings"
              data-testid="header-veritone-nav-settings"
              onClick={() => setUserSettingsDialogOpen({ open: true })}
              onKeyUp={onEnter(() => setUserSettingsDialogOpen({ open: true }))}
              tabIndex={0}
              aria-label="User Settings"
              role="button"
            >
              User Settings
            </div>}
            <div
              className="header__veritone-nav-signout"
              data-testid="header-veritone-nav-signout"
              onClick={() => isWorkingOffline ? workOnline() : logout()}
              onKeyUp={onEnter(() => isWorkingOffline ? workOnline() : logout())}
              tabIndex={0}
              aria-label={isWorkingOffline ? 'Sign Out' : 'Login'}
              role="button"
            >
              {isWorkingOffline ? 'Login' : 'Sign Out'}
            </div>
          </div>
        </div>
      </header>
      {hasDrawer ?
        <div className="header__content drawer">
          <DrawerManager
            {...dmProps}
            open={drawerOpen}
            closeDrawer={() => setDrawerOpen(false)}
          >
            {!isWorkingOffline && <UserSettingsDialog closeNav={() => setNavOpen(false)} />}
            {dmProps.children}
          </DrawerManager>
        </div>
        : <>
          {!isWorkingOffline && <UserSettingsDialog closeNav={() => setNavOpen(false)} />}
          {children}
        </>}
      <Snackbar className="header__snackbar" timeout={3000} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  assignedRoles: User.selectors.roles(state),
  state: User.selectors.state(state),
  smallLogo: User.selectors.smallLogo(state),
  online: Config.selectors.online(state),
  breakpoint: Config.selectors.breakpoint(state),
  counter: Config.selectors.counter(state),
  intervalId: Config.selectors.intervalId(state),
});

const mapDispatchToProps = {
  workOnline: User.workOnline,
  logout: User.logout,
  setUserSettingsDialogOpen: User.setUserSettingsDialogOpen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
