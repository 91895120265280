import React from 'react';

const ReviewExpandSvg = (props: { className: string; role: string; tabIndex: number; onClick: () => void; onKeyUp: (e: any) => void }) => (
  <svg {...props} id="Group_8049" data-name="Group 8049" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      id="Path_5139"
      data-name="Path 5139"
      d="M3.659,0H16.341A3.667,3.667,0,0,1,20,3.659V16.341a3.636,3.636,0,0,1-1.076,2.583h0A3.647,3.647,0,0,1,16.341,20H3.659A3.667,3.667,0,0,1,0,16.341V3.659A3.658,3.658,0,0,1,1.072,1.076v0A3.66,3.66,0,0,1,3.659,0ZM16.341,1.562H3.659a2.091,2.091,0,0,0-1.481.615h0a2.091,2.091,0,0,0-.615,1.481V16.341a2.1,2.1,0,0,0,2.1,2.1H16.341a2.091,2.091,0,0,0,1.481-.615h0a2.091,2.091,0,0,0,.615-1.481V3.659a2.1,2.1,0,0,0-2.1-2.1ZM5.568,6.245a.781.781,0,1,1,0-1.562h8.863a.781.781,0,1,1,0,1.562Zm0,4.535a.779.779,0,1,1,0-1.559H10a.779.779,0,0,1,0,1.559Zm0,4.538a.781.781,0,1,1,0-1.562h8.863a.781.781,0,1,1,0,1.562Z"
      fillRule="evenodd"
    />
  </svg>
);

export default ReviewExpandSvg;
