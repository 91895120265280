import React, { useEffect } from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Template, User } from '@ducks';
import './RaceorEthnicityofOfficerSelect.scss';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import { filter, find } from 'lodash';

interface Props extends PropsFromRedux {
  raceOfOfficer: string[];
  error?: boolean;
  errorMessage?: string;
  className?: string;
  label: string;
  raceEnum: any;
}

const RaceorEthnicityofOfficerSelect = ({ raceOfOfficer, error, errorMessage, className, label, raceEnum, setRaceOfOfficer }: Props) => {
  const raceEnumVal: { [race: string]: { key: string; value: string } } = raceEnum.Race?.possibleValues || {};

  const handleRaceOfOfficerClick = (value: string) => {
    if (!raceOfOfficer[0]) {
      setRaceOfOfficer({ raceOfOfficer: [value] });
    } else if (raceOfOfficer.includes(value)) {
      setRaceOfOfficer({ raceOfOfficer: filter(raceOfOfficer, (race) => race !== value) });
    } else {
      setRaceOfOfficer({
        raceOfOfficer: [...raceOfOfficer, value].sort((a, b) => {
          const valA = find(raceEnumVal, { value: a })?.key;
          const valB = find(raceEnumVal, { value: b })?.key;

          return (valA ? parseInt(valA, 10) : 0) - (valB ? parseInt(valB, 10) : 1)
        })
      });
    }
  }

  const raceLabel = (raceValue: string[]) => raceValue.join(', ');

  useEffect(() => {
    if (raceOfOfficer.includes('Hispanic/Latino(a)') && Object.values(raceEnumVal).map(r => r.value).includes('Hispanic/Latine(x)')) {
      setRaceOfOfficer({ raceOfOfficer: raceOfOfficer.map(r => r === 'Hispanic/Latino(a)' ? 'Hispanic/Latine(x)' : r) });
    }
  }, [raceOfOfficer, raceEnumVal])

  return (
    <div className={cn('race-of-officer-select', className)}>
      <div className="race-of-officer-select__label">{label}</div>
      <FormControl variant="outlined" className="race-of-officer-select__dropdown">
        <Select
          multiple
          error={error}
          value={raceOfOfficer}
          renderValue={value => <div className="race-of-officer-select__dropdown-value">{raceLabel(value as string[])}</div>}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                width: 250,
                height: 232,
              },
            },
          }}
        >
          {Object.values(raceEnumVal).map((race) => <MenuItem key={race.key} value={race.value} onClick={() => handleRaceOfOfficerClick(race.value)}>
            <Checkbox checked={raceOfOfficer.includes(race.value)} />
            <ListItemText primary={race.value} />
          </MenuItem>
          )}
        </Select>
      </FormControl>
      {error && <div className="race-of-officer-select__error" data-testid="race-of-officer-error">
        {errorMessage}
      </div>}
    </div>
  );
};

const mapDispatchToProps = {
  setRaceOfOfficer: User.setRaceOfOfficer,
};

const mapStateToProps = (state: any) => ({
  raceEnum: Template.selectors.enumMap(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RaceorEthnicityofOfficerSelect);
