import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import cn from 'classnames'
import { ConfirmDialog, LabelField } from '@components/common'
import * as Users from '@ducks/users';
import './ChangePasswordDialog.scss'

export const ChangePasswordDialog = ({
  setChangePasswordDialogOpen,
  dialog,
  changePasswordDialog,
  setChangePasswordDialogField,
  submitChangePassword
}: PropsFromRedux) => {
  const [errors, setErrors] = useState<{
    password?: any;
    confirmPassword?: string;
  }>({})
  const setFieldAndCheckValidation = (fieldValue: any, submit?: boolean) => {
    const key = Object.keys(fieldValue)[0]
    const getValue = (param: keyof NonNullable<typeof changePasswordDialog>) => submit ? changePasswordDialog?.[param] : fieldValue[key]
    const fieldHasKey = (k: keyof NonNullable<typeof changePasswordDialog>) => fieldValue[k] !== undefined
    setChangePasswordDialogField(fieldValue)

    if (fieldHasKey('password') || submit) {
      const condition = getValue('password').length >= 8
      errors.password = { ...errors.password, hasEight: condition }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[0-9]/g) ?? []
      errors.password = { ...errors.password, hasNumber: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[A-Z]/g) ?? []
      errors.password = { ...errors.password, hasUppercase: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g) ?? []
      errors.password = { ...errors.password, hasSpecial: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') && submit) {
      const { hasSpecial, hasUppercase, hasNumber, hasEight } = errors.password
      errors.password = { ...errors.password, any: !hasSpecial || !hasUppercase || !hasNumber || !hasEight }
    }
    if (fieldHasKey('confirmPassword') && submit) {
      errors.confirmPassword = getValue('password') !== getValue('confirmPassword') ? 'Password does not match' : undefined
    }

    setErrors(errors)
    return errors
  }

  return (
    <ConfirmDialog
      heading="SET PASSWORD"
      open={Boolean(dialog?.changePasswordDialogOpen)}
      closeDialog={() => setChangePasswordDialogOpen({ open: false })}
      negativeAction={() => setChangePasswordDialogOpen({ open: false })}
      positiveAction={() => {
        const e = setFieldAndCheckValidation(changePasswordDialog, true)
        const hasErrors = Object.values(e).filter(v => v?.any !== undefined ? v.any : v !== undefined).length > 0

        if (!hasErrors) {
          submitChangePassword()
          setChangePasswordDialogOpen({ open: false })
        }
      }}
      positiveText="Save"
      negativeText="Cancel"
    >
      <LabelField
        label="Password"
        className="change-password-dialog__password"
        error={Boolean(errors?.password?.any)}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ password: value })}
        type="password"
        inputProps={{
          'data-testid': 'change-password-dialog-password',
          autocomplete: 'new-password'
        }}
      />
      <LabelField
        label="Enter Again"
        className="change-password-dialog__confirm-password"
        error={Boolean(errors?.confirmPassword)}
        errorMessage={errors?.confirmPassword}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ confirmPassword: value })}
        type="password"
        inputProps={{
          'data-testid': 'change-password-dialog-confirm-password',
          autocomplete: 'new-password'
        }}
      />
      <div className="change-password-dialog__password-validation-text">
        Password must contain the following:
      </div>
      <div className="change-password-dialog__password-validation-checks">
        <div className="change-password-dialog__password-validation-check">
          <div
            className={cn('change-password-dialog__box', { checked: errors?.password?.hasEight })}
            data-testid="change-password-dialog-has-eight"
          >
            <div className="material-icons">
              {errors?.password?.hasEight ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="change-password-dialog__password-validation-check-label">
            8 CHARACTERS
          </div>
        </div>
        <div className="change-password-dialog__password-validation-check">
          <div
            className={cn('change-password-dialog__box', { checked: errors?.password?.hasNumber })}
            data-testid="change-password-dialog-has-number"
          >
            <div className="material-icons">
              {errors?.password?.hasNumber ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="change-password-dialog__password-validation-check-label">
            1 NUMBER
          </div>
        </div>
        <div className="change-password-dialog__password-validation-check">
          <div
            className={cn('change-password-dialog__box', { checked: errors?.password?.hasUppercase })}
            data-testid="change-password-dialog-has-uppercase"
          >
            <div className="material-icons">
              {errors?.password?.hasUppercase ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="change-password-dialog__password-validation-check-label">
            1 UPPERCASE
          </div>
        </div>
        <div className="change-password-dialog__password-validation-check">
          <div
            className={cn('change-password-dialog__box', { checked: errors?.password?.hasSpecial })}
            data-testid="change-password-dialog-has-special"
          >
            <div className="material-icons">
              {errors?.password?.hasSpecial ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="change-password-dialog__password-validation-check-label">
            1 SPECIAL CHARACTER
          </div>
        </div>
      </div>
    </ConfirmDialog>
  )
}

const mapDispatchToProps = {
  setChangePasswordDialogOpen: Users.setChangePasswordDialogOpen,
  submitChangePassword: Users.submitChangePassword,
  setChangePasswordDialogField: Users.setChangePasswordDialogField
}

const mapStateToProps = (state: any) => ({
  changePasswordDialog: Users.selectors.changePasswordDialog(state),
  dialog: Users.selectors.dialog(state),
})

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChangePasswordDialog)
