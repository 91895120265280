import React from 'react';
import cn from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { onEnter } from '@utility/keypressHelpers';
import './RipaSearchBasisForm.scss';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Props, connector } from './props';

const RipaSearchBasisForm = ({
  basis,
  student,
  searchBasis,
  toggleSearchBasis,
  currentSubloop,
  labels,
  consentGiven,
  numberOfPeople,
  useSameActionForAllPersonIndex,
  useSameActionTakenForAll,
  person,
  SearchPropertyConducted,
  consentTypes,
  consentType,
  setConsentType,
  flags: { pre2024 },
}: Props) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;
  const consentTypesVal = consentTypes?.typeOfSearchConsent?.possibleValues;

  React.useEffect(() => {
    if (consentGiven[personIndex]) {
      if (consentGiven[personIndex]['search person'] === false && consentGiven[personIndex]['search property'] === false) {
        if (searchBasis?.[personIndex]?.find((e: any) => e.toString().includes('Consent given'))) {
          toggleSearchBasis('Consent given', personIndex);
        }
        setConsentType(personIndex, '');
      }
    }
    if (!person[personIndex]?.actionTaken?.includes(SearchPropertyConducted) && searchBasis?.[personIndex]?.includes(basis.VehicleInventory.value)) {
      toggleSearchBasis(basis.VehicleInventory.value, personIndex);
    }

  }, []);

  const toggleConsentGiven = (value: string) => {
    toggleSearchBasis(value, personIndex);
    setConsentType(personIndex, '');
  }

  return (
    <div className="ripa-search-basis-form" data-testid="ripa-search-basis-form">
      <div className={cn('ripa-search-basis-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-search-basis-form__person-label">{labels?.[currentSubloop] || currentSubloop + 1}</div>
        <div className="ripa-search-basis-form__person-same-for-all">Same For All</div>
        <div className="ripa-search-basis-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-search-basis-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-search-basis-form__title">{'What\'s the basis for the search?'}</div>
      <div className="ripa-search-basis-form__selection">
        <div className="ripa-search-basis-form__select-box-content">
          {(person[personIndex]?.actionTaken?.includes(SearchPropertyConducted)
            ? [
                basis.ConsentGiven,
                basis.OfficerSafety,
                basis.SearchWarrent,
                basis.ConditionOfParole,
                basis.SuspectedWeapon,
                basis.VisibleContraband,
                basis.OdorOfContraband,
                basis.CanineDetection,
                basis.EvidenceOfCrime,
                basis.IncidentToArrest,
                basis.Emergency,
                basis.VehicleInventory,
              ]
            : [
                basis.ConsentGiven,
                basis.OfficerSafety,
                basis.SearchWarrent,
                basis.ConditionOfParole,
                basis.SuspectedWeapon,
                basis.VisibleContraband,
                basis.OdorOfContraband,
                basis.CanineDetection,
                basis.EvidenceOfCrime,
                basis.IncidentToArrest,
                basis.Emergency,
              ]
          ).map(({ value }, si) => {
            if (value === 'Consent given' && person[personIndex]?.consentGiven['search person'] === false && person[personIndex]?.consentGiven['search property'] === false) {
              return (
                // eslint-disable-next-line react/jsx-key
                <Tooltip title="Search consent was not given" key={`RipaSearchBasisFormBox-${si}-tooltip`}>
                  <div
                    className={cn('ripa-search-basis-form__select-box', {
                      active: searchBasis?.[personIndex]?.includes(value),
                      disable: value === 'Consent given' && person[personIndex]?.consentGiven['search person'] === false && person[personIndex]?.consentGiven['search property'] === false,
                    })}
                    key={`RipaSearchBasisFormBox-${si}`}
                    data-testid={`ripa-search-basis-form-box-${si}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                  </div>
                </Tooltip>
              );
            }
            if (pre2024 && value === 'Consent given' && (person[personIndex]?.consentGiven['search person'] === true || person[personIndex]?.consentGiven['search property'] === true) && consentTypes.typeOfSearchConsent) {
              return (
                <React.Fragment key={`RipaSearchBasisFormBox-${si}-container`}>
                  <div
                    className={cn('ripa-search-basis-form__select-box', {
                      active: searchBasis?.[personIndex]?.includes(value),
                      disable: !pre2024,
                      fullwidth: searchBasis?.[personIndex]?.includes(value),
                    })}
                    key={`RipaSearchBasisFormBox-${si}`}
                    onClick={() => toggleConsentGiven(value)}
                    onKeyUp={onEnter(() => toggleConsentGiven(value))}
                    data-testid={`ripa-search-basis-form-box-${si}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                  </div>
                  {searchBasis?.[personIndex]?.includes(value) &&
                    <div
                      className={cn('ripa-search-basis-form__consent-type-content')}
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          onChange={(_, v) => setConsentType(personIndex, v)}
                          value={consentType?.[personIndex]}
                          >
                          {Object.keys(consentTypesVal).map((types: string, i) =>
                            <FormControlLabel
                              key={i}
                              value={types}
                              control={<Radio required data-testid={`ripa-search-basis-form-consent-type-radio-${i}`} />}
                              label={<Typography>{types}</Typography>} />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>}
                </React.Fragment>
              );
            }
            return (
              <div
                className={cn('ripa-search-basis-form__select-box', {
                  active: searchBasis?.[personIndex]?.includes(value),
                })}
                key={`RipaSearchBasisFormBox-${si}`}
                onClick={() => toggleSearchBasis(value, personIndex)}
                onKeyUp={onEnter(() => toggleSearchBasis(value, personIndex))}
                data-testid={`ripa-search-basis-form-box-${si}`}
                role="button"
                tabIndex={0}
              >
                {value}
              </div>
            );
          })}
          {student.filter((s: any) => Boolean(s)).length > 0 && (
            <div
              className={cn('ripa-search-basis-form__select-box k12', { active: searchBasis?.[personIndex]?.includes(basis.SchoolPolicy.value) })}
              onClick={() => toggleSearchBasis(basis.SchoolPolicy.value, personIndex)}
              onKeyUp={onEnter(() => toggleSearchBasis(basis.SchoolPolicy.value, personIndex))}
              data-testid="ripa-search-basis-form-box-sus-vio"
              role="button"
              tabIndex={0}
            >
              {basis.SchoolPolicy.value}
              <div className="ripa-search-basis-form__select-box-k12">K-12</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select ALL that apply.</div>
  </div>
);

RipaSearchBasisForm.helper = Helper;

export default connector(RipaSearchBasisForm);
