import React from 'react';
import cn from 'classnames';
import { path } from 'ramda';
import { Switch } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './DynamicSelectFormLegacy.scss';

const DynamicSelectFormLegacy = ({
  resultPath,
  title,
  skipText,
  options,
  useSkip,
  form,
  skip,
  onSkipChange,
  onChange,
  setValidationErrors,
  selectBoxStyle,
  DynamicSelectFormStyle,
  goToNext,
}: Props) => {
  const selected = path(resultPath, form);
  const setValueAndClearValidation = (value: string) => {
    onChange(value, resultPath);
    setValidationErrors?.({});
    goToNext?.();
  };

  return (
    <div
      className="dynamic-select-form-legacy"
      data-testid="dynamic-select-form-legacy"
      style={DynamicSelectFormStyle}
      key={Array.isArray(resultPath) ? resultPath.join('.') : resultPath}
    >
      <div className="dynamic-select-form-legacy__title">
        {title}
      </div>
      {useSkip && <div className="dynamic-select-form-legacy__skip-switch" data-testid="skip-switch">
        <div className="dynamic-select-form-legacy__skip-switch-line" />
        <div className="dynamic-select-form-legacy__skip-switch-text">
          {skipText}
        </div>
        <Switch
          className="dynamic-select-form-legacy__skip-switch-switch"
          data-testid="dynamic-select-form-legacy-skip-switch-switch"
          onText="YES"
          offText="NO"
          value={skip}
          onChange={onSkipChange}
        />
        <div className="dynamic-select-form-legacy__skip-switch-line" />
      </div>}
      <div className="dynamic-select-form-legacy__select-boxes">
        {options.map((opt, gi) => <div
          className={cn('dynamic-select-form-legacy__select-box', { active: selected === opt?.value })}
          style={selectBoxStyle}
          onClick={() => setValueAndClearValidation(opt?.value)}
          onKeyUp={onEnter(() => setValueAndClearValidation(opt?.value))}
          key={`DynamicSelectFormLegacy-${gi}`}
          data-testid={`dynamic-select-form-legacy-box-${gi}`}
          role="button"
          tabIndex={0}
          >
            {opt?.value}
          </div>
        )}
      </div>
    </div>
  );
};

const Helper = ({ HelperTextInfo }: { HelperTextInfo: string }) => {
  if (HelperTextInfo) {
    return (
      <div className="ripa-form-container__helper-box">
        <div className="material-icons">
          help
        </div>
        <div className="ripa-form-container__helper-box-text">
          {HelperTextInfo}
        </div>
      </div>
    );
  }
  return null;
};

DynamicSelectFormLegacy.helper = Helper;

export default connector(DynamicSelectFormLegacy);
