export const clearTimeLimit = () => {
  localStorage.removeItem('offline-time-end');
  localStorage.removeItem('counter');
  localStorage.removeItem('intervalId');
};
export const hasTimeLimit = (): boolean => {
  try {
    const timeEnd = localStorage.getItem('offline-time-end') || '';
    return timeEnd !== '' ? !!new Date(timeEnd) : false;
  } catch {
    return false;
  }
};
export const isExceedTimeLimit = (): boolean => {
  try {
    const currentDate = new Date();
    const timeEnd = getOfflineTimeEnd();
    return timeEnd ? Number(timeEnd) - Number(currentDate) <= 0 : false;
  } catch {
    return false;
  }
};
// Helper function to calculate hours, minutes, and seconds
export function calculateTime(time: number) {
  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor((time % 3600000) / 60000);
  const seconds = Math.floor((time % 60000) / 1000);
  return { hours, minutes, seconds };
}

// Helper function to format time
export function formatTime(time: number) {
  return time < 10 ? `0${time}` : time;
}
export function getOfflineTimeEnd(): Date | null {
  try {
    const timeInStorage = localStorage.getItem('offline-time-end');
    if (timeInStorage) {
      return new Date(timeInStorage);
    }
    return null;
  } catch {
    return null;
  }
}

export const counterEffect = (counter: number | undefined, intervalId: NodeJS.Timer | null | undefined, exceedTimeCallback: () => void, stillCountingCallback: (timeLeft: number) => void) => {
  if (counter !== undefined && intervalId) {
    if (counter <= 0) {
      clearInterval(intervalId);
      exceedTimeCallback();
    } else {
      stillCountingCallback(counter);
    }
  }
};
