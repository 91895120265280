import React from 'react'
import { range } from 'ramda'
import { connect, ConnectedProps } from 'react-redux'
import { TextField } from '@material-ui/core'
import * as Form from '@ducks/form';
import './RipaLabelForm.scss'

const RipaLabelForm = ({ labels, numberOfPeople, setLabel }: PropsFromRedux) =>
  <div className="ripa-label-form" data-testid="ripa-label-form">
    <div className="ripa-label-form__title">
      You can add optional labels to help you remember each of them.
      </div>
    <div className="ripa-label-form__labels-container">
      <div className="ripa-label-form__labels">
        {range(0, numberOfPeople).map((_, i) =>
          <div className="ripa-label-form__label" key={`RipaLabelForm-input-${i}`}>
            <div className="material-icons">person</div>
            <div className="ripa-label-form__label-number">{i + 1}</div>
            <TextField
              className="ripa-label-form__label-input"
              placeholder="EG: Tall man with hat..."
              variant="outlined"
              onChange={({ target: { value } }) => value.length < 50 ? setLabel(i, value) : null}
              value={labels[i] || ''}
              inputProps={{
                'data-testid': `ripa-label-form-label-input-${i}`
              }}
            />
          </div>
        )}
      </div>
    </div>
  </div>

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Adding labels only helps you pair the correct information for each person and will not be
      submitted in the end. This is to only help you.
    </div>
  </div>
)

RipaLabelForm.helper = Helper

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
})

const mapDispatchToProps = {
  setLabel: (personIndex: number, value: string) => Form.setFormField({ path: ['person', personIndex, 'label'] })(value)
}
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaLabelForm);
