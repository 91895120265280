import { connect, ConnectedProps } from 'react-redux';
import { User, Form, Template } from '@ducks';

const mapDispatchToProps = {
  setUserSettingsDialogOpen: User.setUserSettingsDialogOpen,
  setYearsOfExperience: User.setYearsOfExperience,
  setTrainingMode: User.setTrainingMode,
  saveUserSettings: User.saveUserSettings,
  setTheme: User.setTheme,
  getFormTemplate: Template.getFormTemplate,
};

const mapStateToProps = (state: any) => ({
  userSettingsDialog: User.selectors.userSettingsDialog(state),
  pre2024: User.selectors.pre2024(state),
  earlyPost2024: User.selectors.earlyPost2024(state),
  profile: User.selectors.profile(state),
  assignedRoles: User.selectors.roles(state),
  testingBanner: User.selectors.testingBanner(state),
  stopDateTime: Form.selectors.stopDateTime(state),
  templateName: Template.selectors.name(state),
  enforceOfficerDemographics: User.selectors.enforceOfficerDemographics(state),
  defaultTemplateName: User.selectors.defaultTemplateName(state),
  userId: User.selectors.userId(state),
  isUserSettingsValid: User.selectors.isUserSettingsValid(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  closeNav: () => void;
};
