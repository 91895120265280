/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { adjust } from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { onEnter } from '@utility/keypressHelpers'
import { dispatchCustomEvent } from '@utility/customEvent'
import dataHash from '@utility/dataHash'
import style from '@theme/global.module.scss'
import alterError from './alert-error.svg'
import alterSuccess from './alert-success.svg'
import alterInfo from './alert-info.svg'
import alterWarning from './alert-warning.svg'
import './Snackbar.scss'

declare global {
  interface WindowEventMap {
    'create-snack-notification': CustomEvent<SnackEvent>;
  }
}

export const AlertLevel = {
  Success: {
    type: 'success',
    icon: alterSuccess
  },
  Warning: {
    type: 'warning',
    icon: alterWarning
  },
  Error: {
    type: 'error',
    icon: alterError
  },
  Info: {
    type: 'info',
    icon: alterInfo
  }
}

export function createSnackNotification(
  level: SnackEvent['level'],
  title: SnackEvent['title'],
  body?: SnackEvent['body']
) {
  dispatchCustomEvent('create-snack-notification', { level, title, body })
}

// States used by snacks in the Snackbar
const Lifecycle = {
  // The snack is rendered out of frame and waiting to transition into the view
  Init: 0,
  // The snack is within it's current timeout and should be displayed normally.
  Alive: 1,
  // The snack has surpassed it's timeout period and should be in the process of disappearing.
  Fadeout: 2,
  // The user is currently hovering over the snack. It's timeout is reset, and it does not move.
  Resurrected: 3,
  // The snack is invisible and will be removed on the next tick.
  Dead: 4
}

interface Snack {
  key: number;
  level: typeof AlertLevel[keyof typeof AlertLevel];
  title: string;
  body: string;
  remainingTime: number;
  state: typeof Lifecycle[keyof typeof Lifecycle];
  id: string;
}

interface SnackEvent {
  level: Snack['level'];
  title: string;
  body: string;
}

interface SnackbarState {
  snackQueue: Snack[];
}

export class Snackbar extends React.Component<Props, SnackbarState> {
  state: SnackbarState = {
    snackQueue: []
  }

  intervalId?: NodeJS.Timer;

  componentDidMount() {
    const { tickTime, fadeTime } = this.props;
    window.addEventListener('create-snack-notification', this.addNewSnack);
    clearInterval(this.intervalId);
    this.intervalId = this.ticker(tickTime ?? 250, fadeTime ?? 2000);
  }

  componentWillUnmount() {
    window.removeEventListener('create-snack-notification', this.addNewSnack);
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }

  addNewSnack = (e: CustomEvent) => {
    const { snackQueue } = this.state
    const { level, title, body } = e.detail
    const { timeout: remainingTime } = this.props
    const state = Lifecycle.Init
    const key = dataHash({ level, title, body })

    // Lets not add identical snacks go on screen at the same time
    if (!snackQueue.find(snack => snack.key === key && snack.state !== Lifecycle.Dead)) {
      this.setState(prevState => ({ snackQueue: [{ key, level, title, body, remainingTime, state, id: uuidv4() }, ...prevState.snackQueue] }))
    }
  }

  setSnackState = (id: string, state: number) => () => {
    const { snackQueue } = this.state
    const snackIndex = snackQueue.findIndex(s => s.id === id)
    const newQueue = adjust(snackIndex, s => ({ ...s, state }), snackQueue)
    this.setState({ snackQueue: newQueue })
  }

  ticker = (tickTime: number, fadeTime: number) => setInterval(() => {
    // Remove dead snacks
    const snackQueue = this.state.snackQueue.filter(({ state }) => state !== Lifecycle.Dead)

    // Check on active snacks
    /* eslint-disable default-case */
    snackQueue.forEach(snack => {
      switch (snack.state) {
        case Lifecycle.Init:
          snack.state = Lifecycle.Alive
          break
        case Lifecycle.Alive:
          snack.remainingTime -= tickTime
          if (snack.remainingTime <= 0) {
            snack.state = Lifecycle.Fadeout
          }
          break
        case Lifecycle.Fadeout:
          snack.remainingTime -= tickTime
          if (snack.remainingTime <= -fadeTime) {
            snack.state = Lifecycle.Dead
          }
          break
        case Lifecycle.Resurrected:
          snack.remainingTime = this.props.timeout
          break
      }
    })

    if (this.state.snackQueue.length !== 0) {
      this.setState({ snackQueue })
    }
  }, tickTime)

  renderSnackbox = ({ level, title, body, state, id }: Snack, position: number) => {
    // How many frame snacks are not currently being displayed
    const outOfFrameSnacks = this.state.snackQueue.filter(s => s.state === Lifecycle.Init).length

    // Where the snack in the stack when is in the frame ( 1 is the bottom, 0 is out of frame )
    const inFramePosition = state === Lifecycle.Init ? 0 : position - outOfFrameSnacks + 1

    // The position of a snack in the stack relative to the one below it
    const stackDistance = Number(style.snackHeight) + Number(style.snackMargin)

    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/alt-text */
    return <React.Fragment key={position}>
      <div
        className="snackbar__mobile-clickoff"
        onClick={this.setSnackState(id, Lifecycle.Dead)}
        onKeyUp={onEnter(this.setSnackState(id, Lifecycle.Dead))}
      />
      <div
        className={classNames(
          'snackbar__box',
          level.type,
          { fading: state === Lifecycle.Fadeout },
          { dead: state === Lifecycle.Dead }
        )}
        data-testid={`snackbar-box-${inFramePosition}-${state}`}
        style={{ top: `-${inFramePosition * stackDistance}px` }}
        key={`Snackbar-box-${id}`}
        // This will only 'resurrect' if the cursor is over the snack within the tick interval (resetting timeout)
        onMouseEnter={this.setSnackState(id, Lifecycle.Resurrected)}
        onMouseLeave={this.setSnackState(id, Lifecycle.Alive)}
        onFocus={this.setSnackState(id, Lifecycle.Resurrected)}
        onBlur={this.setSnackState(id, Lifecycle.Alive)}
        tabIndex={0}
      >
        <div
          className="snackbar__box-close material-icons"
          onClick={this.setSnackState(id, Lifecycle.Dead)}
          onKeyUp={onEnter(this.setSnackState(id, Lifecycle.Dead))}
          data-testid="snackbar-box-close"
          tabIndex={0}
        >
          close
        </div>
        <img className="snackbar__box-icon" src={level.icon} data-testid={`snackbar-box-${level.type}`} />
        <h5 className="snackbar__box-title">
          {title}
        </h5>
        <div className="snackbar__box-body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </React.Fragment>
  }

  render() {
    const { className } = this.props
    const { snackQueue } = this.state

    return (
      <div className={`snackbar ${className || ''}`} data-testid="snackbar">
        {snackQueue.map((snack, position) => this.renderSnackbox(snack, position))}
      </div>
    )
  }
}

// Snackbar.propTypes = {
//   className: PropTypes.string,
//   timeout: PropTypes.number,
//   tickTime: PropTypes.number,
//   fadeTime: PropTypes.number,
// }
interface Props {
  className: string;
  timeout: number;
  tickTime?: number;
  fadeTime?: number;
}

export default Snackbar
