import { connect, ConnectedProps } from 'react-redux';
import { User, Users } from '@ducks';
import { ColumnData } from '@engine/ducks/types';

const mapDispatchToProps = {
  checkUsersRow: Users.checkUsersRow,
  setEditUserDialogOpen: Users.setEditUserDialogOpen,
  setDisableUserDialogOpen: Users.setDisableUserDialogOpen,
};

const mapStateToProps = (state: any) => ({
  userId: User.selectors.userId(state),
  checkedUsersRows: Users.selectors.checkedUsersRows(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  colData: ColumnData[];
  rowData: any;
  rowIndex: number;
};
