import React, { Fragment, useState, useEffect } from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { FormControl, Select, MenuItem, Tooltip } from '@material-ui/core'
import { Switch, AlertLevel, createSnackNotification } from '@components/common'

import { Form, NewReport, Template, Config } from '@ducks'
import { Person } from '@ducks/types';
import { onEnter } from '@utility/keypressHelpers'
import './RipaActionTakenForm.scss'

const RipaActionTakenForm = ({ stopActions, labels, currentSubloop, numberOfPeople, setUseSameForAllPerson, actionTakenBool, flags: { pre2024, post2024 },
  stepErrors, useSameActionForAllPersonIndex, useSameActionTakenForAll, setUseSameActionTakenForAll, setActionTakenBool, student,
  primaryReason, consentGiven, actionTaken, setConsentGiven, toggleActionTaken, setForceLastSubloop, person, toggleSeizedPropertyBasis,
  setSearchDescription, searchDescription, ReasonsForStop, useSamePrimaryReasonForAll, toggleSearchBasis, toggleSeizedPropertyType, breakpoint, typeOfStop }: PropsFromRedux) => {
  const [actionTabIndex, setActionTabIndex] = useState(0);
  const subloopActionIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop
  const subloopReasonIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop
  const stopActionsVal = stopActions?.actionsTakenDuringStop?.possibleValues;
  const nonForceActions2024 = stopActions?.NonForceActionsTaken?.possibleValues;
  const forceActions2024 = stopActions?.ForceActionsTaken?.possibleValues;
  const ReasonsForStopVal = ReasonsForStop?.ReasonsForStop?.possibleValues;
  const isVehicularStop = typeOfStop === stopActions?.TypeOfStop?.possibleValues?.Vehicular.value;
  const basis = stopActions?.basisForSearch?.possibleValues;

  useEffect(() => {
    if (useSameActionTakenForAll) {
      setForceLastSubloop(currentSubloop)
      setUseSameForAllPerson(currentSubloop)
    } else {
      setForceLastSubloop(-1)
      setUseSameForAllPerson(-1)
    }
  }, [useSameActionTakenForAll])

  useEffect(() => {
    const notHasSearch = actionTaken[subloopActionIndex]?.filter((v: any) => v.match(/search.+conducted/gi)).length === 0;
    if (notHasSearch && searchDescription[subloopActionIndex] !== '') {
      setSearchDescription('', subloopActionIndex)
    }
    if (notHasSearch) {
      person?.[subloopActionIndex]?.searchBasis?.forEach((e: any) => {
        toggleSearchBasis({ personIndex: subloopActionIndex, value: e })
      });
    }
    if (consentGiven[subloopActionIndex]) {
      const personConsentGiven = person[subloopActionIndex]?.actionTaken?.toString();
      if (!personConsentGiven?.includes('search person') && consentGiven[subloopActionIndex]['search person'] === true) {
        setConsentGiven(subloopActionIndex, 'search person', false)
      } else if (!personConsentGiven?.includes('search property') && consentGiven[subloopActionIndex]['search property'] === true) {
        setConsentGiven(subloopActionIndex, 'search property', false)
      }
    }
  }, [actionTaken])

  useEffect(() => {
    if (
      stepErrors &&
      ((stepErrors['person.consensualEncounter'] !== 0 && primaryReason?.[subloopReasonIndex]?.toString().includes(ReasonsForStopVal?.ConsensualEncounterSearch.value)) ||
      ('person.searchOfPerson' in stepErrors && stepErrors['person.searchOfPerson'] !== 0))
    ) {
      setActionTabIndex(4)
    } else {
      setActionTabIndex(0)
    }
  }, [stepErrors]);

  useEffect(() => {
    if (!useSamePrimaryReasonForAll) {
      setUseSameActionTakenForAll(false)
    }
  }, [])

  useEffect(() => {
    if (!actionTakenBool?.[subloopActionIndex]) {
      person?.[subloopActionIndex]?.actionTaken?.forEach((e: any) => {
        toggleActionTaken({ personIndex: subloopActionIndex, value: e })
      })
      person?.[subloopActionIndex]?.searchBasis?.forEach((e: any) => {
        toggleSearchBasis({ personIndex: subloopActionIndex, value: e })
      });
      person?.[subloopActionIndex]?.seizedPropertyType?.forEach((e: any) => {
        toggleSeizedPropertyType({ personIndex: subloopActionIndex, value: e })
      });
      person?.[subloopActionIndex]?.seizedPropertyBasis?.forEach((e: any) => {
        toggleSeizedPropertyBasis({ personIndex: subloopActionIndex, value: e })
      });
    }
  }, [actionTakenBool[subloopActionIndex]])

  useEffect(() => {
    if (!person[subloopActionIndex]?.actionTaken?.includes(stopActionsVal.SearchPropertyConducted.value) && person?.[subloopActionIndex]?.searchBasis?.includes(basis.VehicleInventory.value)) {
      toggleSearchBasis({ personIndex: subloopActionIndex, value: basis.VehicleInventory.value })
    }
  }, [person[subloopActionIndex]?.actionTaken]);

  const tabs = student[subloopActionIndex] ?
    ['VEHICLE', 'DETENTION', 'WEAPON', 'CANINE', 'SEARCH', 'K12', 'OTHER'] :
    ['VEHICLE', 'DETENTION', 'WEAPON', 'CANINE', 'SEARCH', 'OTHER']

  let vechicleActions = [stopActionsVal.RemovedFromVehicleByOrder, stopActionsVal.RemovedFromVehicleByContact, stopActionsVal.SobrietyTestConducted, stopActionsVal.Impounded];
  let detentionActions = [stopActionsVal.CurbsideDetention, stopActionsVal.Handcuffed, stopActionsVal.CarDetention];
  let weaponActions = [stopActionsVal.FirearmPointed, stopActionsVal.FirearmDischarged, stopActionsVal.ElectronicControlDeviceUsed, stopActionsVal.ImpactProjectileUsed, stopActionsVal.BatonUsed, stopActionsVal.SprayUsed];
  let canineActions = [stopActionsVal.CanineUsed, stopActionsVal.CanineBit];
  let searchActions = [stopActionsVal.AskedConsentToSearchPerson, stopActionsVal.SearchPersonConducted, stopActionsVal.AskedConsentToSearchProperty, stopActionsVal.SearchPropertyConducted, stopActionsVal.PropertySeized];
  let studentActions = [stopActionsVal.AdmissionByStudent];
  let otherActions = [stopActionsVal.PhysicalVehicleContact, stopActionsVal.Photographed];

  if (pre2024) {
    vechicleActions = [stopActionsVal.RemovedFromVehicleByOrder, stopActionsVal.RemovedFromVehicleByContact, stopActionsVal.SobrietyTestConducted, stopActionsVal.Impounded, nonForceActions2024.IDofPassenger, nonForceActions2024.RanNameOfPassenger];
    detentionActions = [stopActionsVal.CurbsideDetention, stopActionsVal.Handcuffed, stopActionsVal.CarDetention];
    weaponActions = [forceActions2024.FirearmPointed, stopActionsVal.FirearmDischarged, stopActionsVal.ElectronicControlDeviceUsed, stopActionsVal.ImpactProjectileUsed, stopActionsVal.BatonUsed, stopActionsVal.SprayUsed, forceActions2024.FirearmUnholstered, forceActions2024.BatonDrawn, forceActions2024.ElectronicDevicePointed, forceActions2024.ElectronicUsedStunMode, forceActions2024.ElectronicUsedDartMode, forceActions2024.ImpactProjectileWeaponPointed, forceActions2024.ImpactProjectileDischarged];
    canineActions = [stopActionsVal.CanineUsed, stopActionsVal.CanineBit, forceActions2024.CanineRemoved];
    searchActions = [stopActionsVal.AskedConsentToSearchPerson, stopActionsVal.SearchPersonConducted, stopActionsVal.AskedConsentToSearchProperty, stopActionsVal.SearchPropertyConducted, stopActionsVal.PropertySeized, nonForceActions2024.TerryOhioFrisk];
    studentActions = [stopActionsVal.AdmissionByStudent];
    otherActions = [stopActionsVal.PhysicalVehicleContact, stopActionsVal.Photographed, nonForceActions2024.AskedWhetherPersonOnParole];
  }

  if (post2024) {
    vechicleActions = [nonForceActions2024.RemovedFromVehicleByOrder, forceActions2024.PersonRemovedFromVehicle, forceActions2024.PhysicalComplianceTactics, nonForceActions2024.FieldSobrietyTest, nonForceActions2024.VehicleImpounded, nonForceActions2024.IDofPassenger, nonForceActions2024.RanNameOfPassenger];
    detentionActions = [nonForceActions2024.CurbsideDetention, forceActions2024.Handcuffed, nonForceActions2024.PatrolCarDetention];
    weaponActions = [forceActions2024.FirearmPointed, forceActions2024.FirearmDischarged, forceActions2024.FirearmUnholstered, forceActions2024.ElectronicDevicePointed, forceActions2024.ElectronicUsedStunMode, forceActions2024.ElectronicUsedDartMode, forceActions2024.BatonUsed, forceActions2024.BatonDrawn, forceActions2024.ChemicalUsed, forceActions2024.ImpactProjectileWeaponPointed, forceActions2024.ImpactProjectileDischarged];
    canineActions = [nonForceActions2024.CanineUsedToSearch, forceActions2024.CanineBit, forceActions2024.CanineRemoved];
    searchActions = [nonForceActions2024.AskedForConsentSearchPerson, nonForceActions2024.SearchPersonConducted, nonForceActions2024.AskedForConsentSearchProperty, nonForceActions2024.SearchPropertyConducted, nonForceActions2024.PropertyWasSeized, nonForceActions2024.TerryOhioFrisk];
    studentActions = [nonForceActions2024.AdmissionOrWrittenStatementStudent];
    otherActions = [forceActions2024.UseOfVehicle, nonForceActions2024.PersonPhotographed, nonForceActions2024.AskedWhetherPersonOnParole];
  }

  const tabContent = student[subloopActionIndex] ?
    [vechicleActions, detentionActions, weaponActions, canineActions, searchActions, studentActions, otherActions]
    : [vechicleActions, detentionActions, weaponActions, canineActions, searchActions, otherActions]

  return (
    <div className="ripa-action-taken-form" data-testid="ripa-action-taken-form">
      <div className={cn('ripa-action-taken-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">
          person
        </div>
        <div className="ripa-action-taken-form__person-label">
          {labels?.[subloopActionIndex] || subloopActionIndex + 1}
        </div>
        <div className="ripa-action-taken-form__person-same-for-all">
          Same For All
        </div>
        <div className="ripa-action-taken-form__person-progression">
          <b>{`${subloopActionIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-action-taken-form__person-progression-all">
          ALL
        </div>
      </div>
      <div className="ripa-action-taken-form__title">
        What actions were taken?
      </div>
      <div className="ripa-action-taken-form__switch-container">
        {primaryReason?.[subloopReasonIndex]?.toString().includes(ReasonsForStopVal?.ConsensualEncounterSearch.value) ||
          (useSamePrimaryReasonForAll ? person.some((p: Person) => p.primaryReason?.includes('Consensual Encounter resulting in a search')) : false) ? (
          <Tooltip
            title="When “Consensual Encounter resulting in a search” is selected, you must select a search action."
          >
            <div className="ripa-action-taken-form__action-taken-switch-container">
              <Switch
                data-testid="ripa-action-taken-form-action-taken-switch"
                onText="YES"
                offText="NO"
                defaultValue="true"
                disabled={true}
                value={Boolean(actionTakenBool[subloopActionIndex] ?? true)}
                onChange={v => setActionTakenBool(v, subloopActionIndex)}
              />
              <div className="ripa-action-taken-form__action-taken-switch-label">ACTION TAKEN</div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title="When 'no action taken' is selected, the action taken item(s) will be deselected."
          >
            <div className="ripa-action-taken-form__action-taken-switch-container">
              <Switch
                data-testid="ripa-action-taken-form-action-taken-switch"
                onText="YES"
                offText="NO"
                defaultValue="true"
                value={Boolean(actionTakenBool[subloopActionIndex] ?? true)}
                onChange={v => setActionTakenBool(v, subloopActionIndex)}
              />
              <div className="ripa-action-taken-form__action-taken-switch-label">ACTION TAKEN</div>
            </div>
          </Tooltip>
        )}
        <div className="ripa-action-taken-form__use-same-switch-container">
          <div className="ripa-action-taken-form__use-same-switch-label">USE SAME FOR ALL</div>
          <Switch
            data-testid="ripa-action-taken-form-use-same-switch"
            onText="YES"
            offText="NO"
            disabled={!useSamePrimaryReasonForAll || currentSubloop > 0}
            value={Boolean(useSameActionTakenForAll)}
            onChange={setUseSameActionTakenForAll}
            defaultValue="true"
          />
        </div>
      </div>
      <div className="ripa-action-taken-form__tab-container">
        {tabs.map((tab, tabIndex) =>
          <div
            role="button"
            aria-label={`Action tab ${tab}`}
            tabIndex={0}
            className={cn('ripa-action-taken-form__action-tab', { active: actionTabIndex === tabIndex && actionTakenBool[subloopActionIndex] })}
            key={`RipaActionTakenFormTab-${tabIndex}`}
            data-testid={`ripa-action-taken-form-action-tab-${tabIndex}`}
            onClick={() => setActionTabIndex(tabIndex)}
            onKeyUp={onEnter(() => setActionTabIndex(tabIndex))}
          >
            {tab}
          </div>
        )}
        <FormControl variant="outlined" className="ripa-action-taken-form__action-dropdown">
          <Select
            value={actionTabIndex}
            native={breakpoint === 'xs'}
            onChange={({ target: { value } }: any) => setActionTabIndex(parseInt(value, 10))}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {tabs.map((tab, tabIndex) => {
              const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
              return (<CompName key={`RipaActionTakenFormActionDropdownItem-${tabIndex}`} value={tabIndex}>
                {tab}
              </CompName>);
            })}
          </Select>
        </FormControl>
      </div>
      {actionTakenBool[subloopActionIndex] && <div className="ripa-action-taken-form__select-box-content">
        {tabContent[actionTabIndex].map(({ value }, actionTakenIndex) => {
          let is2024Value = false;
          if (!post2024 && pre2024) {
            const isInForceActions2024 = !!Object.values(forceActions2024).find((a: any) => a.value === value)
            const isInNonForceActions2024 = !!Object.values(nonForceActions2024).find((a: any) => a.value === value)
            const isInOldActions = !!Object.values(stopActionsVal).find((a: any) => a.value === value)
            is2024Value = (isInForceActions2024 || isInNonForceActions2024) && !isInOldActions;
          }
          const disabledPre2024 = pre2024 && !isVehicularStop && (value === nonForceActions2024.IDofPassenger.value || value === nonForceActions2024.RanNameOfPassenger.value);
          return <Fragment key={`RipaActionTakenFormBox-${actionTabIndex}-${actionTakenIndex}`}>
            <div
              className={cn('ripa-action-taken-form__select-box', {
                active: actionTaken[subloopActionIndex]?.includes(value),
                'has-consent': actionTabIndex === 4 && (actionTakenIndex === 0 || actionTakenIndex === 2),
                // Is value a 2024 value, and is not in old values
                'highlight-2024': is2024Value,
                disable: disabledPre2024,
              })}
              onClick={() => !disabledPre2024 ? toggleActionTaken({ personIndex: subloopActionIndex, value }) : createSnackNotification(AlertLevel.Error, 'Error', 'Type of stop must be a vehicular stop')}
              onKeyUp={onEnter(() => !disabledPre2024 ? toggleActionTaken({ personIndex: subloopActionIndex, value }) : createSnackNotification(AlertLevel.Error, 'Error', 'Type of stop must be a vehicular stop'))}
              data-testid={`ripa-action-taken-form-box-${actionTabIndex}-${actionTakenIndex}`}
              role="button"
              tabIndex={0}
            >
              <div dangerouslySetInnerHTML={{ __html: value }} />
              {is2024Value && <div className="ripa-action-taken-form__select-box-2024">2024</div>}
            </div>
            <div className={cn('ripa-action-taken-form__consent-container', {
              'has-consent': actionTabIndex === 4 && (actionTakenIndex === 0 || actionTakenIndex === 2),
              disabled: !actionTaken[subloopActionIndex]?.includes(value)
            })}>
              <div className="ripa-action-taken-form__consent-label">CONSENT GIVEN</div>
              <Switch
                className={cn('ripa-action-taken-form__consent-switch', { available: actionTabIndex === 4 && (actionTakenIndex === 0 || actionTakenIndex === 2) })}
                data-testid={`ripa-action-taken-form-consent-switch-${actionTabIndex}-${actionTakenIndex}`}
                onText="YES"
                offText="NO"
                disabled={!actionTaken[subloopActionIndex]?.includes(value)}
                value={Boolean(consentGiven?.[subloopActionIndex]?.[actionTakenIndex === 0 ? 'search person' : 'search property'])}
                onChange={v => setConsentGiven(subloopActionIndex, actionTakenIndex === 0 ? 'search person' : 'search property', v)}
              />
            </div>
          </Fragment>
        })}
      </div>}
      {!actionTakenBool[subloopActionIndex] && <div className="ripa-action-taken-form__no-action-text">
        No actions were taken.
      </div>}
    </div>
  )
}

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Select ALL that apply. Flip through the category tabs to see all options.
    </div>
  </div>
)

RipaActionTakenForm.helper = Helper

const mapStateToProps = (state: any) => ({
  consentGiven: Form.selectors.consentGiven(state),
  actionTaken: Form.selectors.actionTaken(state),
  actionTakenBool: Form.selectors.actionTakenBool(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  student: Form.selectors.student(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  stopActions: Template.selectors.enumMap(state),
  searchDescription: Form.selectors.searchDescription(state),
  primaryReason: Form.selectors.primaryReason(state),
  ReasonsForStop: Template.selectors.enumMap(state),
  stepErrors: Form.selectors.stepErrors(state),
  person: Form.selectors.person(state),
  breakpoint: Config.selectors.breakpoint(state),
  flags: Form.selectors.flags(state),
  typeOfStop: Form.selectors.typeOfStop(state),
})

const mapDispatchToProps = {
  toggleActionTaken: Form.toggleActionTaken,
  toggleSearchBasis: Form.toggleSearchBasis,
  toggleSeizedPropertyType: Form.toggleSeizedPropertyType,
  toggleSeizedPropertyBasis: Form.toggleSeizedPropertyBasis,
  setUseSameActionTakenForAll: Form.setFormField({ path: ['useSameActionTakenForAll'] }),
  setActionTakenBool: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'actionTakenBool'] })(value),
  setConsentGiven: (personIndex: number, searchType: any, value: any) => Form.setFormField({ path: ['person', personIndex, 'consentGiven', searchType] })(value),
  setUseSameForAllPerson: Form.setUseSameForAllPerson,
  setForceLastSubloop: NewReport.setForceLastSubloop,
  setSearchDescription: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'searchDescription'] })(value)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaActionTakenForm)
