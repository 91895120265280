import { createSnackNotification, AlertLevel } from '@components/common'
import { decoupledDispatch } from '@utility/decoupledDispatch'
import { assignRoute } from '@utility/assignRoute'
import { offlineConfig } from '@engine/dependencies/localforage'
import * as Sentry from '@sentry/react'

const httpErrorHandler = (unknownErrorMessage: string, errorHandler?: (e: any) => void) => async (e: any) => {
  console.error(e)

  const workOffline = await offlineConfig.getItem('work-offline').then(wo => String(wo) === 'true')

  if (workOffline) {
    return
  }

  if (e?.message?.includes('Network Error')) {
    createSnackNotification(AlertLevel.Error, 'Network Error', 'Are you connected to the internet?')
    decoupledDispatch('Config.checkOnlineStatus')
  } else if (e?.message?.includes('Unauthorized') || e?.message?.includes('401') || e?.status === 401) {
    if (e?.response?.data?.redirect_url) {
      window.location.href = e.response.data.redirect_url
    } else {
      assignRoute('/log_in')
    }
  } else {
    createSnackNotification(AlertLevel.Error, 'Error', `${unknownErrorMessage} </br> <small>${e?.message ?? ''}</small>`)
  }

  Sentry.captureException(e, {
    tags: {
      unknownErrorMessage,
      ...JSON.parse(localStorage.getItem('sentry-user') || '{}')
    }
  })

  return errorHandler?.(e);
}

export { httpErrorHandler }
