import { connect, ConnectedProps } from 'react-redux';
import { User, Review, NewReport } from '@ducks';

const mapStateToProps = (state: any) => ({
  reviewDialogOpen: Review.selectors.reviewDialogOpen(state),
  userForms: Review.selectors.userForms(state),
  reviewDialogId: Review.selectors.reviewDialogId(state),
  userId: User.selectors.userId(state),
  steps: NewReport.selectors.registeredSteps(state),
  customQuestions: Review.selectors.customQuestions(state),
});

const mapDispatchToProps = {
  closeReviewDialog: () => Review.setReviewDialogOpen({ open: false, formId: -1 }),
  approveReport: Review.approveReport,
  removeReport: Review.removeReport,
  activateReport: Review.activateReport,
  setReviewFeedbackDialogOpen: Review.setReviewFeedbackDialogOpen,
  addReviewTimeSpent: Review.addReviewTimeSpent,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
