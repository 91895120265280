import { KeyboardEvent } from 'react'

export const onEnter = <T=Element>(func?: React.KeyboardEventHandler<T>) => (e: KeyboardEvent<T>) => {
  e.stopPropagation()

  if (e.key === 'Enter' || e.keyCode === 13) {
    func?.(e)
  }
}

// export const onSpace = func => e => {
//   e.stopPropagation()

//   if (e.keyCode === 32) {
//     func(e)
//   }
// }

// export const onBackspace = func => e => {
//   e.stopPropagation()

//   if (e.keyCode === 8) {
//     func(e)
//   }
// }

// export const onEsc = func => e => {
//   e.stopPropagation()

//   if (e.keyCode === 27) {
//     func(e)
//   }
// }

// export const onTab = func => e => {
//   e.stopPropagation()

//   if (e.keyCode === 9) {
//     func(e)
//   }
// }
