import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { User, Template, Dashboard as DashboardDuck, Config, Form } from '@ducks';

const mapDispatchToProps = {
  createNewForm: DashboardDuck.createNewForm,
  getUserForms: DashboardDuck.getUserForms,
  deleteForm: DashboardDuck.deleteForm,
  setPagination: DashboardDuck.setPagination,
  toggleFilterAndSearch: DashboardDuck.toggleFilterAndSearch,
  setSort: DashboardDuck.setSortAndSearch,
  setSearch: DashboardDuck.setSearch,
  getStats: DashboardDuck.getStats,
  getFormTemplate: Template.getFormTemplate,
  syncOfflineForms: DashboardDuck.syncOfflineForms,
  setReviewFeedbackDialogOpen: DashboardDuck.setReviewFeedbackDialogOpen,
  getOrgCustomQuestions: DashboardDuck.getOrgCustomQuestions,
  setAssignment: Form.setFormField({ path: ['assignment'] }),
  setOtherAssignment: Form.setFormField({ path: ['otherAssignment'] }),
  setUserSettingsDialogOpen: User.setUserSettingsDialogOpen,
  workOnline: User.workOnline,
};

const mapStateToProps = (state: any) => ({
  assignedRoles: User.selectors.roles(state),
  assignment: Form.selectors.assignment(state),
  otherAssignment: Form.selectors.otherAssignment(state),
  officerAssignment: Template.selectors.enumMap(state),
  loading: DashboardDuck.selectors.loading(state),
  searchAndFilter: DashboardDuck.selectors.searchAndFilter(state),
  breakpoint: Config.selectors.breakpoint(state),
  reportData: DashboardDuck.selectors.reportData(state),
  pagination: DashboardDuck.selectors.pagination(state),
  reviewFeedbackDialogOpen: DashboardDuck.selectors.reviewFeedbackDialogOpen(state),
  reviewFeedbackDialogContent: DashboardDuck.selectors.reviewFeedbackDialogContent(state),
  online: Config.selectors.online(state),
  showOffline: DashboardDuck.selectors.showOffline(state),
  statistics: DashboardDuck.selectors.statistics(state),
  hasTemplate: Template.selectors.hasTemplate(state),
  trainingMode: User.selectors.trainingMode(state),
  profile: User.selectors.profile(state),
  testingBanner: User.selectors.testingBanner(state),
  isUserSettingsValid: User.selectors.isUserSettingsValid(state),
  counter: Config.selectors.counter(state),
  intervalId: Config.selectors.intervalId(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export const composer = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export type Props = ConnectedProps<typeof connector>;
