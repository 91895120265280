/* eslint-disable prefer-const, arrow-body-style */

import dayjs from 'dayjs'
import qs from 'qs'
import * as Sentry from '@sentry/react'
import { assoc, assocPath, mergeRight, compose, map, filter, path } from 'ramda'
import { forEachSeries } from 'p-iteration'
import { createSnackNotification, AlertLevel } from '@components/common'
import { decoupledDispatch } from '@utility/decoupledDispatch'
import { paths as Paths } from '@components/custom/Routes/paths'
import { assignRoute } from '@utility/assignRoute'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import { userSettingsErrorMessage } from '@utility/userSettingsErrorMessage'
import sleep from '@utility/sleep'
import getEnv from '@engine/env'
import { offlineConfig } from '@engine/dependencies/localforage'
import getFormTemplatesCached from '@utility/getFormTemplatesCached'
import { selectors as UserSelectors } from './user'
import { getInitialState as getFormInitialState, selectors as FormSelectors } from './form'
import { UserNamespace, DashboardNamespace, Status, Filters, DashboardFilterValues, ConfigNamespace, isAfter2024, FormNamespace } from './constants'

export const SET_USER_FORMS = `${DashboardNamespace}/SET_USER_FORMS`
export const SET_MORE_USER_FORMS = `${DashboardNamespace}/SET_MORE_USER_FORMS`
export const SET_PAGE_META = `${DashboardNamespace}/SET_PAGE_META`
export const SET_PAGINATION = `${DashboardNamespace}/SET_PAGINATION`
export const SET_SORT = `${DashboardNamespace}/SET_SORT`
export const TOGGLE_FILTER = `${DashboardNamespace}/TOGGLE_FILTER`
export const SET_SEARCH = `${DashboardNamespace}/SET_SEARCH`
export const SET_LOADING_USER_FORMS = `${DashboardNamespace}/SET_LOADING_USER_FORMS`
export const SET_STATISTICS = `${DashboardNamespace}/SET_STATISTICS`
export const SET_REVIEW_FEEDBACK_DIALOG_OPEN = `${DashboardNamespace}/SET_REVIEW_FEEDBACK_DIALOG_OPEN`
export const ADD_SYNC_COMPLETE = `${DashboardNamespace}/ADD_SYNC_COMPLETE`
export const ADD_SYNC_FAILED = `${DashboardNamespace}/ADD_SYNC_FAILED`
export const ADD_SYNCING = `${DashboardNamespace}/ADD_SYNCING`
export const SET_OFFLINE_STATUS = `${DashboardNamespace}/SET_OFFLINE_STATUS`

const INITIAL_STATE = {
  loading: {
    getUserForms: false
  },
  pagination: {
    totalPages: 1,
    totalCount: 0,
    currentPage: 1,
    pageSize: 10
  },
  searchAndFilter: {
    search: undefined,
    filterSelected: [], // Put here a default like [Filters.CreatedToday]
    sort: {
      column: undefined,
      direction: undefined
    }
  },
  reviewFeedbackDialogOpen: false,
  reviewFeedbackDialogContent: {},
  statistics: {
    underReviewStatus: 0,
    rejectedStatus: 0,
    approvedStatus: 0,
    deniedByDojStatus: 0,
    offlineForms: 0,
    last24HourForms: 0,
    submittedToDojStatus: 0,
    submissionErrorFromDojStatus: 0
  },
  userForms: [],
  showOffline: false
}

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING_USER_FORMS:
      return mergeRight(state, { loading: { ...state.loading, getUserForms: payload.isLoading } })
    case SET_USER_FORMS:
      return assoc('userForms', payload.userForms, state)
    case SET_MORE_USER_FORMS:
      return mergeRight(state, { userForms: [...state.userForms, ...payload.userForms] })
    case SET_PAGE_META:
      return mergeRight(state, { pagination: { ...state.pagination, totalPages: Number(payload.totalPages), totalCount: Number(payload.totalCount) } })
    case SET_PAGINATION:
      return mergeRight(state, { pagination: { ...state.pagination, currentPage: Number(payload.currentPage), pageSize: Number(payload.pageSize) } })
    case SET_SORT:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, sort: payload } })
    case SET_SEARCH:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, search: payload.search } })
    case TOGGLE_FILTER: {
      const { searchAndFilter: { filterSelected } } = state
      const hasFilter = payload.filterIndex === filterSelected?.[0]
      return assocPath(['searchAndFilter', 'filterSelected'], hasFilter ? [] : [payload.filterIndex], state)
    }
    case SET_REVIEW_FEEDBACK_DIALOG_OPEN:
      return mergeRight(state, {
        reviewFeedbackDialogOpen: payload.open,
        reviewFeedbackDialogContent: state.userForms.find(uf => uf.id === payload.formId)?.reviewer_notes
      })
    case SET_STATISTICS:
      return mergeRight(state, { statistics: { ...payload } })
    case SET_OFFLINE_STATUS: {
      const updateIndex = state.userForms.findIndex(f => f.id === payload.formId)

      if (updateIndex >= 0) {
        return assocPath(['userForms', updateIndex, 'offlineStatus'], payload.olStatus, state)
      }
      return state
    }
    default:
      return state
  }
}

export const setReviewFeedbackDialogOpen = ({ open, formId }) => ({
  type: SET_REVIEW_FEEDBACK_DIALOG_OPEN,
  payload: { open, formId }
})

export const setUserForms = userForms => ({
  type: SET_USER_FORMS,
  payload: { userForms }
})

export const setMoreUserForms = userForms => ({
  type: SET_MORE_USER_FORMS,
  payload: { userForms }
})

export const setPageMeta = ({ totalPages, totalCount }) => ({
  type: SET_PAGE_META,
  payload: { totalPages, totalCount }
})

export const setPagination = ({ currentPage, pageSize }) => ({
  type: SET_PAGINATION,
  payload: { currentPage, pageSize }
})

export const toggleFilter = filterIndex => ({
  type: TOGGLE_FILTER,
  payload: { filterIndex }
})

export const setSort = ({ column, direction }) => ({
  type: SET_SORT,
  payload: { column, direction }
})

export const setSearch = search => ({
  type: SET_SEARCH,
  payload: { search }
})

export const setLoadingUserForms = isLoading => ({
  type: SET_LOADING_USER_FORMS,
  payload: { isLoading }
})

export const setStatistics = stats => ({
  type: SET_STATISTICS,
  payload: { ...stats }
})

export const addSyncComplete = formId => ({
  type: ADD_SYNC_COMPLETE,
  payload: { formId }
})

export const addSyncFailed = formId => ({
  type: ADD_SYNC_FAILED,
  payload: { formId }
})

export const addSyncing = formId => ({
  type: ADD_SYNCING,
  payload: { formId }
})

export const setOfflineStatus = ({ olStatus, formId, onlineId }) => ({
  type: SET_OFFLINE_STATUS,
  payload: { olStatus, formId, onlineId }
})

export const createTestForm = () => async (_, getState, { http }) => {
  const yearsOfExperience = getState()[UserNamespace].user.years_of_experience <= 0 ? 1 : getState()[UserNamespace].user.years_of_experience;
  const raceOfOfficer = getState()[UserNamespace].user.race;
  const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true')

  if (workOffline) {
    createSnackNotification(AlertLevel.Warning, 'Error', 'Cannot create test form while working offline');
    return;
  }

  return http
    .get('/form_templates?latest=true')
    .then(({ data }) => {
      const templateId = data.find(t => t.name === 'CA_doj_ripa_2024_testing')?.id;
      if (!templateId) {
        createSnackNotification(AlertLevel.Warning, 'Cannot create form', 'Organization is not provisioned for test forms.');
        return;
      }

      const assignment = localStorage.getItem('defaultAssignment') ?? data?.last()?.answers?.OfficerTypeofAssignment?.possibleValues?.Patrol?.value ?? ''
      return http.post('/form_data', { status: Status.Draft, contents: { ...getFormInitialState({ pre2024: true, post2024: true, is2024Test: true }), assignment, raceOfOfficer, yearsOfExperience }, form_template_id: templateId }, {})
        .then(({ data: formData }) => {
          assignRoute(`/new-report/${formData.id}`)
        })
        .catch(httpErrorHandler('Failed to create form'))
    }).catch(httpErrorHandler('Failed to get form templates'))
}

export const createNewForm = () => async (_, getState, { http, offlineTemplates }) => {
  let raceOfOfficer;
  const yearsOfExperience = getState()[UserNamespace].user.years_of_experience <= 0 ? 1 : getState()[UserNamespace].user.years_of_experience;
  const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true')
  const { training_mode } = getState()[UserNamespace].user;
  let pre2024 = getState()[UserNamespace]?.organizationInfo?.pre2024 ?? false;
  const defaultTemplateName = getState()[UserNamespace]?.organizationInfo?.defaultTemplateName ?? 'CA_2024_ripa';
  const isUserSettingsValid = UserSelectors.isUserSettingsValid(getState()[UserNamespace]);

  if (training_mode && !workOffline) {
    // Skip creating a form on the server for training mode
    assignRoute('/new-report/training')
    return
  }

  if (!isUserSettingsValid) {
    createSnackNotification(AlertLevel.Warning, 'User Settings Are Required', `${userSettingsErrorMessage()} to create a new report.`)
    return;
  }

  return getFormTemplatesCached(http, offlineTemplates)
    .then((templates) => {
      let templateId = templates?.find((t) => t.name === defaultTemplateName)?.id;

      if (!templateId) {
        decoupledDispatch('Template.setHasTemplate', false)

        const message = `Cannot create new form. Missing data, please contact Veritone (${workOffline ? 0 : 1}a)`;
        Sentry.captureMessage(message, {
          tags: {
            ...JSON.parse(localStorage.getItem('sentry-user') || '{}')
          }
        })
        createSnackNotification(AlertLevel.Warning, 'Error', message);
        return
      }

      const post2024 = isAfter2024(dayjs(), templates.find(t => t.id === templateId).name);
      if (post2024) {
        pre2024 = true;
      }
      if (pre2024 || post2024) {
        raceOfOfficer = getState()[UserNamespace]?.user?.race;
      }

      const contents = {
        ...getFormInitialState({ pre2024, post2024 }),
        raceOfOfficer,
        yearsOfExperience,
      }

      contents.debug.creation.offlineCount = workOffline ? 1 : 0;
      contents.debug.creation.onlineCount = workOffline ? 0 : 1;
      contents.debug.creation.buildDetails = { ...window.buildDetails };

      return http.post('/form_data', {
        status: Status.Draft, contents, form_template_id: templateId
      }, {})
        .then(({ data: formData }) => {
          assignRoute(`/new-report/${formData.id}`)
        })
        .catch(httpErrorHandler('Failed to create form'))
    }).catch(httpErrorHandler('Failed to get form templates'))
}

export const getOrgCustomQuestions = () => async (_, getState, { http, offlineCustomQuestions }) => {
  let { user: { organization_id } } = getState()[UserNamespace];

  if (!organization_id) {
    organization_id = await offlineConfig.getItem('user').then((u) => u.organization.id);
  }

  return http.get(`/organizations/${organization_id}/custom_questions/`).then(async ({ data }) => {
    await offlineCustomQuestions.setItem('questions', data?.questions ?? {}, e => e && console.error('Failed to save custom questions', e));
  }).catch(httpErrorHandler('Failed to get forms'))
}

export const getUserForms = ({ searchAndFilter, pagination, isFetchMore, forceOffline }) => async (dispatch, getState, { http }) => {
  dispatch(setLoadingUserForms(true))
  const { breakpoint } = getState()[ConfigNamespace]
  const { currentPage, pageSize } = pagination
  const { search, filterSelected, sort: { column, direction } } = searchAndFilter
  const workOffline = await offlineConfig?.getItem('work-offline').then(wo => String(wo) === 'true')

  let formField
  switch (column) {
    case 'progress':
      formField = 'progress'
      break;
    case 'people':
      formField = 'numberOfPeople'
      break;
    case 'flag':
      formField = 'flag'
      break;
    case 'time':
      formField = 'stopDateTime'
      break;
    case 'status':
    default:
      formField = undefined
      break;
  }

  const query = qs.stringify({
    page: currentPage,
    limit: pageSize,
    form_fields: [formField], // form_fields is fields for sorting
    status: filterSelected?.[0] === Filters.CreatedToday ? undefined : DashboardFilterValues?.[filterSelected?.[0]],
    hours: filterSelected.includes(Filters.CreatedToday) ? 24 : undefined,
    dir: direction,
    order: column === 'status' ? 'status' : undefined,
    relation: 'mine',
    search
  }, { arrayFormat: 'brackets' })

  const offlineForms = []
  if (breakpoint === 'xs' && !workOffline) {
    await http
      .get(`/form_data?${qs.stringify({
        page: 1,
        limit: 10000,
        relation: 'mine'
      }, { arrayFormat: 'brackets' })}`, { 'x-use-storage': true })
      .then(({ data }) => data.forms.forEach(f => offlineForms.push(f)))
  }

  return http
    .get(`/form_data?${query}`, { 'x-use-storage': forceOffline })
    .then(({ data }) => {
      if (data.forms && data.pagination) {
        if (isFetchMore) {
          dispatch(setMoreUserForms(data.forms))
          dispatch(setPagination({ currentPage: Number(data.pagination.page), pageSize: INITIAL_STATE.pagination.pageSize }))
        } else {
          const forms = data.forms.filter(f => !!f.contents)
          forms.forEach((form, updateIndex) => {
            const offlineIndex = offlineForms.findIndex(f => f.formId === form.id || f?.onlineId === form.id)

            if (offlineIndex > -1) {
              forms[updateIndex].offlineStatus = offlineForms[offlineIndex]?.offlineStatus
              offlineForms.splice(offlineIndex, 1)
            }
          })
          dispatch(setUserForms(forms))
          dispatch(setPageMeta({ totalPages: Number(data.pagination.pages), totalCount: Number(data.pagination.count) }))
        }
      }
    })
    .catch(httpErrorHandler('Failed to get forms'))
    .finally(() => {
      setTimeout(() => dispatch(setLoadingUserForms(false)), 200)
    })
}

export const flagForm = id => async (dispatch, getState, { http }) => {
  const { contents, status, offlineStatus } = getState()[DashboardNamespace].userForms.find(f => f.id === id)
  const { showOffline } = getState()[DashboardNamespace]
  const workOffline = await offlineConfig.getItem('work-offline')
    .then(wo => String(wo) === 'true')

  return http
    .patch(`/form_data/${id}`, { contents: { ...contents, flag: !contents.flag }, status, offlineStatus }, { 'x-use-storage': showOffline || workOffline })
    .then(() => {
      const { pagination, searchAndFilter } = getState()[DashboardNamespace]
      dispatch(getUserForms({ pagination, searchAndFilter, forceOffline: showOffline || workOffline }))
    })
    .catch(httpErrorHandler('Failed to flag form'))
}

export const getStats = params => (dispatch, _, { http }) => http
  .get('/statistics/forms?relation=mine', { 'x-use-storage': params?.forceOffline })
  .then(({ data: { statistics: { draft_status, flagged_forms, today_forms, last_24hour_forms, submission_error_from_doj_status,
    submitted_to_doj_status, denied_by_doj_status, rejected_status, under_review_status, approved_status, offlineForms } } }) => {

    dispatch(setStatistics({
      draftStatus: draft_status ?? 0,
      flaggedForms: flagged_forms ?? 0,
      todayForms: today_forms ?? 0,
      last24HourForms: last_24hour_forms ?? 0,
      underReviewStatus: under_review_status ?? 0,
      rejectedStatus: rejected_status ?? 0,
      deniedByDojStatus: denied_by_doj_status ?? 0,
      approvedStatus: approved_status ?? 0,
      submittedToDojStatus: submitted_to_doj_status ?? 0,
      submissionErrorFromDojStatus: submission_error_from_doj_status ?? 0,
      offlineForms: offlineForms ?? 0
    }))
  })
  .catch(httpErrorHandler('Failed to get form stats'))

export const deleteForm = id => async (dispatch, getState, { http }) => {
  const { showOffline } = getState()[DashboardNamespace]
  const workOffline = await offlineConfig.getItem('work-offline')
    .then(wo => String(wo) === 'true')

  return http
    .delete(`/form_data/${id}`, {}, { 'x-use-storage': showOffline || workOffline })
    .then(() => {
      dispatch(getStats({ forceOffline: showOffline || workOffline }));
      createSnackNotification(AlertLevel.Success, 'Success', 'Report deleted')
      const { pagination, searchAndFilter } = getState()[DashboardNamespace]
      dispatch(getUserForms({ pagination, searchAndFilter, forceOffline: showOffline || workOffline }))
    })
    .catch(httpErrorHandler('Failed to delete form'))
}

export const gotoOfflineMode = () => dispatch => {
  if (location.href.includes(Paths.Dashboard.path)) {
    const getUserFormParams = { searchAndFilter: INITIAL_STATE.searchAndFilter, pagination: INITIAL_STATE.pagination, isFetchMore: false, forceOffline: true }
    dispatch(getUserForms(getUserFormParams))
    createSnackNotification(AlertLevel.Info, 'You are now working offline')
  } else if (location.href.includes('/new-report/')) {
    createSnackNotification(AlertLevel.Info, 'You are now working offline')
  }
}

export const syncOfflineForms = (params) => async (dispatch, getState, { http }) => {
  const { authenticated } = getState()[UserNamespace]
  const { REACT_APP_FEATURE_FLAGS: { serviceWorkerFeature } } = getEnv()
  let { currentPage, itemsProcessed, totalCount, syncedToRemove } = params ?? { currentPage: 1, itemsProcessed: 0, totalCount: undefined, syncedToRemove: [] }
  const workOfflineStatus = await offlineConfig.getItem('work-offline').then(wo => String(wo) === 'true')

  if (!serviceWorkerFeature) {
    console.warn('Service worker feature disabled')
    return
  }
  if (workOfflineStatus) {
    console.error('Cannot sync forms while working offline')
    return
  }
  if (authenticated !== 'success') {
    createSnackNotification(AlertLevel.Error, 'User must be authenticated to sync forms')
    return
  }

  const query = qs.stringify({
    page: currentPage,
    limit: 2,
    relation: 'mine'
  }, { arrayFormat: 'brackets' })

  const setOfflineStatusSW = (olStatus, updateId, onlineId) => http
    .patch(`/set_offline_status/${updateId}`, { offlineStatus: olStatus, onlineId }, { 'x-use-storage': true })
    .then(() => {
      dispatch(setOfflineStatus({ olStatus, formId: updateId, onlineId }))
    })
    .catch(e => console.error('Failed to change offline form status', e))

  const removeOfflineFormSW = (removeId) => http
    .delete(`/remove_offline_synced/${removeId}`, {}, { 'x-use-storage': true })
    .catch(e => console.error('Failed to remove offline form synced', e))

  return http
    .get(`/form_data?${query}`, { 'x-use-storage': true })
    .then(async ({ data, headers }) => {
      if (headers['x-from-service-worker'] !== 'true') {
        console.warn('Response not from service-worker, stopping sync')
        return
      }
      if (data.forms && data.pagination) {
        await forEachSeries(data.forms, async form => {
          if (form.offlineStatus === Status.Synced) {
            return
          }

          const idFromStorage = String(form.id ?? form.contents.id)
          await setOfflineStatusSW(Status.Syncing, idFromStorage)
          await sleep(500)

          if (idFromStorage.startsWith('ol-')) {
            // Form was created offline
            await http.post('/form_data', { status: form.status, contents: { ...form.contents }, form_template_id: form.form_template_id }, {})
              .then(postdata => {
                syncedToRemove.push(idFromStorage)
                setOfflineStatusSW(Status.Synced, idFromStorage, postdata.data.id)
              })
              .catch(() => {
                console.error('Failed to sync offline form')
                setOfflineStatusSW(Status.SyncFailed, form.id)
              })
          } else {
            // Form was updated offline
            await http
              .patch(`/form_data/${idFromStorage}`, { contents: form.contents, status: form.status })
              .then(() => {
                syncedToRemove.push(idFromStorage)
                setOfflineStatusSW(Status.Synced, idFromStorage, idFromStorage)
              })
              .catch(() => {
                console.error('Failed to sync offline form')
                setOfflineStatusSW(Status.SyncFailed, idFromStorage)
              })
          }
        })
        const { count, items } = data.pagination
        totalCount = totalCount ?? count
        itemsProcessed = itemsProcessed + items

        if (itemsProcessed < totalCount) {
          decoupledDispatch('Dashboard.syncOfflineForms', { currentPage: currentPage + 1, itemsProcessed, totalCount, syncedToRemove })
        }
      } else {
        console.error('Service worker response malformed')
      }
      if (syncedToRemove.length > 0 && itemsProcessed >= totalCount) {
        await forEachSeries(syncedToRemove, async storageId => {
          await removeOfflineFormSW(storageId)
        })
        const { pagination, searchAndFilter } = getState()[DashboardNamespace]
        dispatch(getUserForms({ pagination, searchAndFilter }))
        dispatch(getStats())
      }
    })
    .catch(httpErrorHandler('Failed to get forms'))
    .finally(() => {
      setTimeout(() => dispatch(setLoadingUserForms(false)), 100)
    })
}

export const toggleFilterAndSearch = filterIndex => (dispatch, getState, { http }) => {
  const { searchAndFilter, pagination } = reducer(getState()[DashboardNamespace], toggleFilter(filterIndex))
  dispatch(toggleFilter(filterIndex))
  dispatch(setPagination({ currentPage: 1, pageSize: INITIAL_STATE.pagination.pageSize }))
  return getUserForms({ searchAndFilter, pagination: { ...pagination, currentPage: 1 } })(dispatch, getState, { http })
}

export const setSortAndSearch = ({ column, direction }) => (dispatch, getState, { http }) => {
  const { searchAndFilter, pagination } = reducer(getState()[DashboardNamespace], setSort({ column, direction }))
  dispatch(setSort({ column, direction }))
  return getUserForms({ searchAndFilter, pagination })(dispatch, getState, { http })
}

export const selectors = ({
  reviewFeedbackDialogOpen: path([DashboardNamespace, 'reviewFeedbackDialogOpen']),
  reviewFeedbackDialogContent: path([DashboardNamespace, 'reviewFeedbackDialogContent']),
  showOffline: path([DashboardNamespace, 'showOffline']),
  statistics: path([DashboardNamespace, 'statistics']),
  loading: path([DashboardNamespace, 'loading']),
  searchAndFilter: path([DashboardNamespace, 'searchAndFilter']),
  pagination: path([DashboardNamespace, 'pagination']),
  openReports: compose(a => a.length,
    filter(({ status }) => status === Status.Draft),
    path([DashboardNamespace, 'userForms'])),
  reportsToday: compose(
    a => a.length,
    filter(({ created_at }) => dayjs(created_at).format('YYYYMMDD') === dayjs().format('YYYYMMDD')),
    path([DashboardNamespace, 'userForms'])),
  importantFlagged: compose(
    a => a.length,
    filter(({ contents }) => Boolean(contents?.flag)),
    path([DashboardNamespace, 'userForms'])),
  submittedReports: compose(
    a => a.length,
    filter(({ status }) => status === Status.UnderReview),
    path([DashboardNamespace, 'userForms'])),
  reportData: compose(
    map(({ id, contents, status, offlineStatus, created_at, updated_at, user_id }) => ({
      id,
      status,
      offlineStatus: offlineStatus ?? '',
      created_at,
      updated_at,
      user_id,
      location: FormSelectors.locationString({ [FormNamespace]: contents }),
      assignment: contents?.assignment ?? '',
      district: '',
      beat: '',
      time: dayjs(contents?.stopDateTime),
      people: contents?.numberOfPeople ?? 0,
      k12: contents?.person?.filter(s => s.student).length > 0,
      progress: contents?.progress ?? 0,
      flag: contents?.flag ?? false,
      testcase2024: contents?.flags?.is2024Test ?? false
    })),
    path([DashboardNamespace, 'userForms']))
})
