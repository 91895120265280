import React, { useState } from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import { onEnter } from '@utility/keypressHelpers'
import { TextField } from '@material-ui/core'
import * as Form from '@ducks/form';
import './RipaDurationForm.scss'
import { StepErrors } from '@engine/ducks/types'

const MAX_DURATION = 60 * 24 // one day

interface Props {
  duration: number;
  setDuration: (value: number) => void;
  isCustomDuration: boolean;
  setIsCustomDuration: (value: any) => void;
  errors: StepErrors;
  goToNext: () => void;
}

const RipaDurationForm = ({ duration, setDuration, isCustomDuration, setIsCustomDuration, errors, goToNext }: Props) => {
  const [fieldValue, setFieldValue] = useState(isCustomDuration ? duration : '')
  const setButtonDuration = (dur: number) => () => {
    setDuration(dur)
    setFieldValue('')
    setIsCustomDuration(false)
    goToNext?.()
  }

  return (
    <div className="ripa-duration-form" data-testid="ripa-duration-form">
      <div className="ripa-duration-form__title">
        How many minutes did it last?
      </div>
      <div className="ripa-duration-form__content">
        {[5, 10, 15, 20, 30].map((d: number) =>
          <div
            className={cn('ripa-duration-form__select-box', { active: duration === d && !isCustomDuration })}
            onClick={setButtonDuration(d)}
            onKeyUp={onEnter(setButtonDuration(d))}
            data-testid={`ripa-duration-form-${d}`}
            role="button"
            tabIndex={0}
            key={`ripa-duration-form-${d}`}
          >
            {`${d} Min`}
          </div>
        )}
        <TextField
          className={cn('ripa-duration-form__select-box-custom', { error: Number(errors?.duration || '0') > 0, 'is-custom': isCustomDuration })}
          inputProps={{
            'data-testid': 'ripa-duration-form-custom-input',
            type: 'number',
            inputMode: 'numeric',
            min: 0,
            max: MAX_DURATION
          }}
          placeholder="Enter other..."
          variant="outlined"
          value={fieldValue}
          onChange={({ target: { value } }: { target: { value: string } }) => {
            const charCode = value.charCodeAt(value.length - 1)
            if ((charCode >= 48 && charCode <= 57) || value === '') {
              let valNumber = Number(value);
              if (valNumber > MAX_DURATION) {
                valNumber = MAX_DURATION;
              }
              if (valNumber < 0) {
                valNumber = 0;
              }
              setFieldValue(valNumber.toString())
              setDuration(valNumber)
              setIsCustomDuration(true)
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  duration: Form.selectors.duration(state),
  isCustomDuration: Form.selectors.isCustomDuration(state),
  errors: Form.selectors.stepErrors(state) as StepErrors,
})

const mapDispatchToProps = {
  setDuration: Form.setFormField({ path: ['duration'] }),
  setIsCustomDuration: Form.setFormField({ path: ['isCustomDuration'] }),
  checkValidation: Form.checkValidation
}

export default connect(mapStateToProps, mapDispatchToProps)(RipaDurationForm)
