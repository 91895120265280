import { connect, ConnectedProps } from 'react-redux';
import { User, Review } from '@ducks';

const mapStateToProps = (state: any) => ({
  selectAllChecked: Review.selectors.selectAllChecked(state),
  orgCanSubmitToDOJ: User.selectors.canSubmitToDOJ(state),
});

const mapDispatchToProps = {
  selectAllReviewRows: Review.selectAllReviewRows,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
