import React from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './RipaContrabandOrEvidenceForm.scss';

const RipaContrabandOrEvidenceForm = ({ setContrabandOrEvidenceDiscovered, contrabandOrEvidenceDiscovered, currentSubloop, useSameActionForAllPersonIndex, useSameActionTakenForAll, labels, numberOfPeople, goToNext }: Props) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop

  return (
    <div className="ripa-contraband-or-evidence-form" data-testid="ripa-contraband-or-evidence-form">
      <div className={cn('ripa-contraband-or-evidence-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">
          person
        </div>
        <div className="ripa-contraband-or-evidence-form__person-label">
          {labels?.[currentSubloop] || currentSubloop + 1}
        </div>
        <div className="ripa-contraband-or-evidence-form__person-same-for-all">
          Same For All
        </div>
        <div className="ripa-contraband-or-evidence-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-contraband-or-evidence-form__person-progression-all">
          ALL
        </div>
      </div>
      <div className="ripa-contraband-or-evidence-form__title">
        Was there contraband or evidence discovered?
      </div>
      <div className="ripa-contraband-or-evidence-form__select-box-container">
        <div
          className={cn('ripa-contraband-or-evidence-form__select-box', { active: contrabandOrEvidenceDiscovered[personIndex] })}
          data-testid="ripa-contraband-or-evidence-form-yes"
          onClick={() => {
            setContrabandOrEvidenceDiscovered(true, personIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setContrabandOrEvidenceDiscovered(true, personIndex))}
          role="button"
          tabIndex={0}
        >
          Yes
     </div>
        <div
          className={cn('ripa-contraband-or-evidence-form__select-box', { active: contrabandOrEvidenceDiscovered[personIndex] === false })}
          data-testid="ripa-contraband-or-evidence-form-no"
          onClick={() => {
            setContrabandOrEvidenceDiscovered(false, personIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setContrabandOrEvidenceDiscovered(false, personIndex))}
          role="button"
          tabIndex={0}
        >
          No
      </div>
      </div>
    </div>
  )
}

export default connector(RipaContrabandOrEvidenceForm)
