import React from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { range } from 'ramda'
import { Switch } from '@components/common'
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import { onEnter } from '@utility/keypressHelpers'
import './RipaDisabilityDetailsForm.scss'

interface Props extends PropsFromRedux {
  Disability: {
    Disability?: {
      possibleValues: Record<string, { key: number; value: string }>;
    };
  };
}

const RipaDisabilityDetailsForm = ({
  Disability,
  labels,
  numberOfPeople,
  disabled,
  student,
  setDisabled,
  disabilities,
  toggleDisability,
}: Props) => {
  const disabilityVal = Disability?.Disability?.possibleValues || {};
  return (
    <div className="ripa-disability-details-form" data-testid="ripa-disability-details-form">
      <div className="ripa-disability-details-form__title">
        What perceived or known disabilities?
      </div>
      <div className="ripa-disability-details-form__labels-container">
        <div className="ripa-disability-details-form__labels">
          {range(0, numberOfPeople).map((_, pi) =>
            <div className="ripa-disability-details-form__selection" key={`RipaDisabilityDetailsFormLabels-${pi}`}>
              <div className="ripa-disability-details-form__label-container">
                <div className="ripa-disability-details-form__label">
                  <div className="material-icons">person</div>
                  <div className="ripa-disability-details-form__label-text">{labels[pi] || pi + 1}</div>
                </div>
                <div className="ripa-disability-details-form__disability-switch-container">
                  <div className="ripa-disability-details-form__disability-switch-text">
                    DISABLED
                  </div>
                  <Switch
                    className="ripa-disability-details-form__disability-switch"
                    data-testid={`ripa-disability-details-form-disability-switch-${pi}`}
                    onText="YES"
                    offText="NO"
                    value={Boolean(disabled[pi])}
                    onChange={v => setDisabled(pi, v)}
                  />
                </div>
              </div>
              {disabled[pi] && <div className="ripa-disability-details-form__select-box-content">
                {/* TODO: We need to fix the spelling of Speach here -- will require updating all form tempaltes though */}
                {[disabilityVal.Deafness, disabilityVal.Speach, disabilityVal.Blind, disabilityVal.MentalHealthCondition,
                disabilityVal.Intellectual, disabilityVal.Other].map(({ value }, rvi) =>
                  <div
                    className={cn('ripa-disability-details-form__select-box', { active: disabilities[pi]?.includes(value) })}
                    key={`RipaRaceFormBox-${pi}-${rvi}`}
                    onClick={() => toggleDisability(pi, value)}
                    onKeyUp={onEnter(() => toggleDisability(pi, value))}
                    data-testid={`ripa-disability-details-form-box-${pi}-${rvi}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                  </div>
                )}
                {student[pi] && <div
                  className={cn('ripa-disability-details-form__select-box k12', { active: disabilities[pi]?.includes(disabilityVal.Hyperactivity.value) })}
                  onClick={() => toggleDisability(pi, disabilityVal.Hyperactivity.value)}
                  onKeyUp={onEnter(() => toggleDisability(pi, disabilityVal.Hyperactivity.value))}
                  data-testid={`ripa-disability-details-form-hyper-${pi}`}
                  role="button"
                  tabIndex={0}
                >
                  {disabilityVal.Hyperactivity.value}
                  <div className="ripa-disability-details-form__select-box-k12">
                    K-12
                  </div>
                </div>}
              </div>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      Select all that apply that you could perceive or were made known about.
    </div>
  </div>
);

RipaDisabilityDetailsForm.helper = Helper

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  disabled: Form.selectors.disabled(state),
  disabilities: Form.selectors.disabilities(state),
  student: Form.selectors.student(state),
  Disability: Template.selectors.enumMap(state) as any,
})

const mapDispatchToProps = {
  setDisabled: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'disabled'] })(value),
  toggleDisability: (personIndex: number, value: string) => Form.toggleDisability({ personIndex, value })
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaDisabilityDetailsForm);
