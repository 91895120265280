/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import cn from 'classnames'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell, Legend, Tooltip } from 'recharts';
import { MenuItem, Select, FormControl } from '@material-ui/core'
import './Chart.scss'

const DATAPOINTS_TO_TRIGGER_LEGEND = 9;
const barColors = ['#3eb2c9', '#cc4a4a', '#a1d455', '#5244cc', '#4781be', '#43c98e', '#d4bc47', '#1f77b4', '#ff7f0e', '#2ca02c']

const filterList = {
  OfficerTypeofAssignment: {
    name: 'Officer’s Type of Assignment',
    value: 'OfficerTypeofAssignment'
  },
  Resp_to_svc_call: {
    name: 'Response to Call For Service ',
    value: 'Resp_to_svc_call '
  },
  student: {
    name: 'Is Person Stopped a Student',
    value: 'student'
  },
  Race: {
    name: 'Perceived Race or Ethnicity',
    value: 'Race'
  },
  Gender: {
    name: 'Perceived Gender',
    value: 'Gender'
  },
  LGBT: {
    name: 'Perceived to be LGBT',
    value: 'LGBT'
  },
  Age: {
    name: 'Perceived Age',
    value: 'Age'
  },
  Limitedenglish: {
    name: 'Limited or No English Fluency',
    value: 'Limitedenglish'
  },
  Disability: {
    name: 'Perceived or Known Disability',
    value: 'Disability'
  },
  ReasonsForStop: {
    name: 'Reason For Stop',
    value: 'ReasonsForStop'
  },
  actionsTakenDuringStop: {
    name: 'Actions Taken',
    value: 'actionsTakenDuringStop'
  },
  basisForSearch: {
    name: 'Basis For Search',
    value: 'basisForSearch'
  },
  contrabandOrEvidence: {
    name: 'Contraband/Evidence Discovered',
    value: 'contrabandOrEvidence'
  },
  resultOfStop: {
    name: 'Result of Stop',
    value: 'resultOfStop'
  }
}

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
  index: number;
}

const CustomizedAxisTick = (props: CustomizedAxisTickProps) => {
  const { x, y, payload, index } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={12}
        // @ts-ignore
        scaleToFit={true} // eslint-disable-line react/no-unknown-property
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill={barColors[index % 10]}
      >
        {payload.value.replace(/([A-Z])/g, ' $1').trim()}
      </text>
    </g>
  );
};

const CustomTooltip = (param: { active?: boolean; payload?: any[] }) => {
  const { active, payload } = param

  if (active && payload && payload.length) {
    return (
      <div className="chart__custom-tooltip">
        <p className="chart__custom-tooltip-label">{payload[0].payload.name.replace(/([A-Z])/g, ' $1').trim()}</p>
        <p className="chart__custom-tooltip-value">{`Count : ${payload[0].payload.value}`}</p>
      </div>
    );
  }

  return null;
};

const yesNoDataEntry = (
  personParam: string,
  peopleArray: any[][],
  arrToPushTo: {
    name: 'Yes' | 'No';
    value: number;
  }[]
) => {
  const obj = { Yes: 0, No: 0 }
  peopleArray.forEach(arr => {
    arr.forEach(person => {
      if (person[personParam]) {
        obj.Yes += 1
      } else {
        obj.No += 1
      }
    })
  })
  arrToPushTo.push({
    name: 'Yes',
    value: obj.Yes
  }, {
    name: 'No',
    value: obj.No
  })
}

const dataEntry = (
  personParam: string,
  peopleArray: any[][],
  arrToPushTo: {
    name: string;
    value: number;
  }[],
  listOfKeys: Record<string, any>
) => {
  Object.keys(listOfKeys).forEach(key => {
    const obj: Record<string, number> = {}
    obj[key] = 0
    const keyName = listOfKeys[key].value
    peopleArray.forEach(arr => {
      arr.forEach(person => {
        if (person[personParam]?.includes(keyName)) {
          obj[key] += 1
        }
      })
    })
    arrToPushTo.push({
      name: key,
      value: obj[key]
    })
  })
}

const Chart = ({ formData, formTemplates }: Props) => {
  const [filter, setFilter] = useState('OfficerTypeofAssignment');
  const [data, setData] = useState<any[]>([]);

  const handleFilterchange = (value: string) => {
    setFilter(value)
  }

  const calculateData = (_formData: any[]) => {
    let listOfKey = formTemplates[filter]?.possibleValues || []
    const result: any[] = [];

    const stopPeople: any[][] = [];
    _formData.map((item: any) => {
      const tmp = [...item.contents.person];
      tmp.forEach(person => person.id = item.id)
      return stopPeople.push([...tmp])
    })

    switch (filter) {
      case 'Age': {
        const obj: Record<string, any> = {};
        stopPeople.forEach(arr => {
          arr.forEach(person => {
            const ageRange = `${Math.floor(person.age / 5) * 5}-${(Math.floor(person.age / 5) + 1) * 5}`
            obj[ageRange] = (obj[ageRange] || 0) + 1
          })
        })
        Object.entries(obj).forEach(entry => result.push({
          name: entry[0],
          value: entry[1]
        }))
        break
      }
      case 'Gender': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          stopPeople.forEach((arr, idx) => {
            if (_formData[idx].contents.useSameGenderForAll && arr[0].gender?.includes(keyName)) {
              return obj[key] += arr.length
            }
            arr.forEach(person => {
              if (person.gender?.includes(keyName)) {
                obj[key] += 1
              }
            })
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      case 'student': {
        yesNoDataEntry('student', stopPeople, result)
        break
      }
      case 'LGBT': {
        yesNoDataEntry('lgbt', stopPeople, result)
        break
      }
      case 'Limitedenglish': {
        yesNoDataEntry('english', stopPeople, result)
        break
      }
      case 'Race': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          stopPeople.forEach((arr, idx) => {
            if (_formData[idx].contents.useSameRaceForAll) {
              if (arr[0].race?.includes(keyName)) {
                obj[key] += arr.length
              }
              return
            }
            arr.forEach(person => {
              if (person.race?.includes(keyName)) {
                obj[key] += 1
              }
            })
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      case 'contrabandOrEvidence': {
        dataEntry('contraband', stopPeople, result, listOfKey)
        break
      }
      case 'actionsTakenDuringStop': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          stopPeople.forEach((arr, idx) => {
            if (_formData[idx].contents.useSameActionTakenForAll) {
              if (arr[0].actionTaken?.includes(keyName)) {
                obj[key] += arr.length
              }
              return
            }
            arr.forEach(person => {
              if (person.actionTaken?.includes(keyName)) {
                obj[key] += 1
              }
            })
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      case 'basisForSearch': {
        dataEntry('searchBasis', stopPeople, result, listOfKey)
        break
      }
      case 'Disability': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          stopPeople.forEach(arr => {
            arr.forEach(person => {
              if (!person.disabled) {
                obj.None += 1
                return
              }
              if (person.disabilities?.includes(keyName)) {
                obj[key] += 1
              }
            })
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      case 'resultOfStop': {
        dataEntry('resultOfStop', stopPeople, result, listOfKey)
        break
      }
      case 'OfficerTypeofAssignment': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          _formData.forEach(item => {
            if (Object.values(item.contents).includes(keyName)) {
              obj[key] += 1
            }
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      case 'ReasonsForStop': {
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          const keyName = listOfKey[key].value;
          stopPeople.forEach((arr, idx) => {
            if (_formData[idx].contents.useSamePrimaryReasonForAll) {
              if (arr[0].primaryReason?.includes(keyName)) {
                obj[key] += arr.length
              }
              return
            }
            arr.forEach(person => {
              if (person.primaryReason?.includes(keyName)) {
                obj[key] += 1
              }
            })
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
      default: { // Resp_to_svc_call
        listOfKey = {
          Yes: 'Y',
          No: 'N'
        }
        Object.keys(listOfKey).forEach(key => {
          const obj: Record<string, number> = {};
          obj[key] = 0;
          _formData.forEach(item => {
            if (item.contents.responseToCall) {
              obj.Yes += 1
            } else {
              obj.No += 1
            }
          })
          result.push({
            name: key,
            value: obj[key]
          })
        })
        break
      }
    }
    return result.filter(item => item.value > 0)
  }

  useEffect(() => {
    const chartData = calculateData(formData)
    setData(chartData)
  }, [formData, filter])

  const legendFormatter = (value: string, _: any, idx: number) => <span data-testid={`chart-barchart-legend-entry-${idx}`} style={{ marginRight: '3px' }}>{value.replace(/([A-Z])/g, ' $1').trim()}</span>;

  return (
    <div className={cn('chart', { empty: data.length === 0 })} style={data.length >= DATAPOINTS_TO_TRIGGER_LEGEND ? { height: '300px' } : {}}>
      <div>
        <FormControl variant="outlined">
          <Select
            className="chart__filter-select"
            data-testid="chart-filter-select"
            value={filter}
            onChange={({ target: { value } }) => handleFilterchange(value as string)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {/* {Object.keys(filterList).map((k, i) => */}
            {Object.values(filterList).map((v, i) =>
              <MenuItem key={`ReviewPageReviewerDrop-${i}`} value={v.value}>{v.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      {data.length > 0 && <ResponsiveContainer>
        <BarChart
          width={200}
          height={50}
          data={data}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {data.length >= DATAPOINTS_TO_TRIGGER_LEGEND &&
            <Legend
              layout="horizontal"
              align="center"
              verticalAlign="top"
              height={100}
              payload={data.map((v, idx) => ({ value: v.name, color: barColors[idx % 10] }))}
              formatter={legendFormatter}
            />}
            <Tooltip
              active={true}
              content={<CustomTooltip />}
            />
          <CartesianGrid strokeDasharray="3 3" />
          {data.length < DATAPOINTS_TO_TRIGGER_LEGEND ?
            data.map((entry, index) => (
              entry.value !== 0 && <XAxis key={`tick-${index}`} dataKey="name" tick={<CustomizedAxisTick index={index} />} />
            )) : ''
          }
          <YAxis />
          <Bar dataKey="value" fill="#82ca9d" barSize={50}>
            {
              data.map((entry, index) => (
                entry.value !== 0 && <Cell key={`cell-${index}`} fill={barColors[index % 10]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>}
      <hr className="chart__end-line" />
    </div>
  )
}

interface Props {
  formData: any[];
  formTemplates: Record<string, any>;
}

export default Chart;
