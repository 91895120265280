import { connect, ConnectedProps } from 'react-redux';
import { get } from 'lodash';
import { Config, Template, NewReport, Form } from '@engine/ducks';

const mapStateToProps = (state: any) => ({
  contraband: Form.selectors.contraband(state),
  anyResultOfStop: Form.selectors.anyResultOfStop(state),
  resultOfStop: Form.selectors.resultOfStop(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  student: Form.selectors.student(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  resultOfStopCodeSection: Form.selectors.resultOfStopCodeSection(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  resultOfStopEnum: get(Template.selectors.enumMap(state), 'resultOfStop.possibleValues', {}),
  flags: Form.selectors.flags(state),
  breakpoint: Config.selectors.breakpoint(state)
});

const mapDispatchToProps = {
  setAnyResultOfStop: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'anyResultOfStop'] })(value),
  toggleResultOfStop: (value: string, personIndex: number) => Form.toggleResultOfStop({ value, personIndex }),
  setResultOfStopCodeSection: (resultOfStopLabel: any, value: string[], personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'resultOfStopCodeSection', resultOfStopLabel] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
