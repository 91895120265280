import { DateTimePicker } from '@material-ui/pickers';
import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { MenuItem, MenuList, Select, FormControl, ButtonGroup, Popper, Paper, ClickAwayListener } from '@material-ui/core';

import { Button, ConfirmDialog, Chart, Alert, AlertLevel, createSnackNotification, Column, Table } from '@components/common';
import { Header } from '@components/custom';
import * as ReviewDuck from '@ducks/review';
import * as CsvReportsDuck from '@ducks/csvReports';
import * as Users from '@ducks/users';
import * as Template from '@ducks/template';
import { OnlineStatus, StatusString } from '@ducks/constants';
import './Visualization.scss';
import { CSVReportRow } from '@engine/ducks/types';
import CsvRow from '@components/custom/CsvReportRow/CsvRow';

dayjs.extend(utc);
dayjs.extend(timezone);

const allStatuses = Object.values(OnlineStatus).filter((value) => value !== OnlineStatus.Draft);

const dataRequestInterval = 30000; // 30 seconds
const rowLimit = 100;

interface Props {
  getAllUserForms: any;
  getAllOfficers: any;
  getStats: any;
  getAllReviewers: any;
  formData: Array<any>;
  csvReportRows: Array<CSVReportRow>;
  formTemplates: object;
  postExportCsvReports: any;
  getExportCsvReports: Function;
  loading: {
    getCsvReports: boolean;
  };
  removeCsvReportDialogOpen: boolean;
  setRemoveCsvReportDialogOpen: any;
  deleteCsvReport: any;
}

export const Visualization = ({
  postExportCsvReports,
  getExportCsvReports,
  getAllOfficers,
  getAllUserForms,
  getAllReviewers,
  formData,
  csvReportRows,
  formTemplates,
  getStats,
  loading,
  removeCsvReportDialogOpen,
  setRemoveCsvReportDialogOpen,
  deleteCsvReport
}: Props) => {
  const [startDate, setStartDate] = useState<string | Date>(dayjs().startOf('day').subtract(2, 'weeks').format('MM/DD/YYYY H:mm'));
  const [endDate, setEndDate] = useState<string | Date>(dayjs().endOf('day').format('MM/DD/YYYY H:mm'));
  const [status, setStatus] = useState('all');
  const [filterType, setFilterType] = useState('created');
  const [chartsDisabled, setChartsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    getAllOfficers();
    getAllReviewers();
    getStats({ relation: 'all' });
    getExportCsvReports();
  }, []);

  useEffect(() => {
    const tz = dayjs.tz.guess();
    const startDateISO = dayjs(startDate).tz(tz).toISOString();
    const endDateISO = dayjs(endDate).tz(tz).toISOString();
    const months = dayjs(endDate).diff(dayjs(startDate), 'month', true);

    if (months <= 2) {
      setChartsDisabled(false);
      getAllUserForms({
        startDate: startDateISO,
        endDate: endDateISO,
        status: status === 'all' ? allStatuses : [status],
        filterType,
      });
    } else {
      setChartsDisabled(true);
      createSnackNotification(AlertLevel.Warning, 'Charts Disabled', 'Enter a date range of 2 months or less to view chart analytics');
    }
  }, [startDate, endDate, status, filterType]);

  useEffect(() => {
    const interval = setInterval(() => {
      getExportCsvReports();
    }, dataRequestInterval);
    return () => clearInterval(interval);
  }, []);

  const getExportedTypeBySelectedIndex = () => {
    switch (selectedIndex) {
      case 0:
        return 'stop data';
      case 1:
        return 'time spent';
      case 2:
        return 'officer audit';
      default:
        return 'time spent';
    }
  };

  const handleExportCsvAsync = () => {
    const startDateISO = dayjs(startDate).toISOString();
    const endDateISO = dayjs(endDate).toISOString();
    const tz = dayjs.tz.guess();

    postExportCsvReports({
      startDate: startDateISO,
      endDate: endDateISO,
      status: status === 'all' ? allStatuses : [status],
      exportType: getExportedTypeBySelectedIndex(),
      tz,
      filterType
    });
  };

  const handleSetStartDate = (newStartDate: Date | null) => {
    if (!newStartDate) { return }
    const date1 = dayjs(newStartDate);
    const date2 = dayjs(endDate);

    if (date1.diff(date2) > 0) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Start date must be before end date');
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleSetEndDate = (newEndDate: Date | null) => {
    if (!newEndDate) { return }
    const date1 = dayjs(startDate);
    const date2 = dayjs(newEndDate);

    if (date1.diff(date2) > 0) {
      createSnackNotification(AlertLevel.Error, 'Error', 'End date must be after start date');
    } else {
      setEndDate(newEndDate);
    }
  };

  const handleToggleExportMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleExportMenuItemClick = (_event: any, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  // TODO: ButtonGroup can take a generic type spec - need to play with and see if we can actually type this reference
  const anchorRef = useRef<any>(null);

  const handleExportMenuClose = (event: any) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Header title="Contact">
        <div className="visualization" data-testid="visualization">
          <div className="visualization__background">
            <div className="visualization__options">
              <div className="visualization__filters">
              <div
                className="visualization__filters-status"
                data-testid="visualization-filters-officer"
                aria-label="Filter ripa report for ripa reports that are made by the selected officer"
              >
                <FormControl variant="outlined">
                  <Select
                    className="visualization__result-select"
                    value={filterType}
                    disabled={selectedIndex === 2}
                    onChange={({ target: { value } }: { target: any }) => setFilterType(value)}
                    data-testid="visualization-dropdown-type-date"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value="created">Created Date</MenuItem>
                    <MenuItem value="stop">Stop Date</MenuItem>
                  </Select>
                </FormControl>
              </div>
                <div className="visualization__filters-label">FROM</div>
                <div
                  className="visualization__filters-date-range"
                  aria-label="Filter ripa report for ripa reports that are in the date range values"
                  data-testid="visualization-filters-date-range"
                >
                  <DateTimePicker
                    className="date-range-picker__date-picker"
                    inputVariant="outlined"
                    ampm={false}
                    format="MM/dd/yyyy 'at' hh:mm a"
                    label="Start Date"
                    value={startDate}
                    onChange={handleSetStartDate}
                    maxDate={dayjs()}
                  />
                </div>
                <div className="visualization__filters-label">TO</div>
                <div
                  className="visualization__filters-date-range"
                  aria-label="Filter ripa report for ripa reports that are in the date range values"
                  data-testid="visualization-filters-date-range"
                >
                  <DateTimePicker
                    className="date-range-picker__date-picker"
                    inputVariant="outlined"
                    ampm={false}
                    format="MM/dd/yyyy 'at' hh:mm a"
                    label="End Date"
                    value={endDate}
                    onChange={handleSetEndDate}
                    maxDate={dayjs()}
                  />
                </div>
              </div>
              <div className="visualization__filter-space" />
              <div
                className="visualization__filters-status"
                data-testid="visualization-filters-officer"
                aria-label="Filter ripa report for ripa reports that are made by the selected officer"
              >
                <FormControl variant="outlined">
                  <Select
                    className="visualization__result-select"
                    value={status}
                    disabled={selectedIndex === 2}
                    onChange={({ target: { value } }: { target: any }) => setStatus(value)}
                    data-testid="visualization-dropdown-status"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value="all">All Statuses</MenuItem>
                    {Object.values(OnlineStatus).map((statusValue, index) =>
                      <MenuItem
                        key={`ReviewPageReviewerDrop-${index}`}
                        value={statusValue}
                      >
                        {StatusString[statusValue]}
                      </MenuItem>,
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="visualization__filter-space" />
              <ButtonGroup
                className="visualization__export-report-group"
                ref={anchorRef}
                variant="contained"
                data-testid="visualization-filters-export-reports"
                aria-label="export reports"
              >
                <Button
                  className="visualization__export-report"
                  data-testid="visualization-export-report"
                  priority="primary"
                  materialIcon="add"
                  onClick={handleExportCsvAsync}
                >
                  { `GENERATE ${getExportedTypeBySelectedIndex().toUpperCase()}` }
                </Button>
                <Button
                  className="visualization__split-button-arrow"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="export options menu"
                  aria-haspopup="menu"
                  onClick={handleToggleExportMenu}
                >
                  <div className="material-icons">arrow_drop_down</div>
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                role={undefined}
                anchorEl={anchorRef.current}
                placement="bottom-end"
                style={{ width: '150px' }}
              >
                <Paper style={{ color: '#003d7e' }}>
                  {/* Breaks when clicking too on the page fast only use when menu is open */}
                  {open && (
                    <ClickAwayListener onClickAway={handleExportMenuClose}>
                      <MenuList id="split-button-menu">
                        {['Stop Data', 'Time Spent', 'Officer Audit'].map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) => handleExportMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  )}
                </Paper>
              </Popper>
            </div>

            {/* CSV Table */}
            {csvReportRows.length > 0 && (
              <>
                <hr className="csv-reports-line-break" />
                <div className="csv-reports__table">
                  <Table
                    RowComponent={CsvRow}
                    rowData={csvReportRows.filter((_row, index) => index < rowLimit)}
                    loading={loading.getCsvReports}
                  >
                    <Column title="ID" dataKey="id" grow={1} minWidth={75} />
                    <Column title="Created At" dataKey="createdAt" grow={3} minWidth={75} />
                    <Column title="Start Time" dataKey="startTime" grow={3} minWidth={75} />
                    <Column title="End Time" dataKey="endTime" grow={3} minWidth={75} />
                    <Column title="Export Type" dataKey="exportType" grow={2} minWidth={75} />
                    <Column title="Status" dataKey="status" grow={2} minWidth={75} />
                    <Column title="Actions" dataKey="actions" grow={2} minWidth={75} />
                  </Table>
                </div>
              </>
            )}
          </div>
          {chartsDisabled && <div className="visualization__date-range-warning">
            Reduce date range to enable charts
          </div>}
          {!chartsDisabled && <>
            <div className="visualization__header">
              <div className="visualization__header-title">
                Aggregated Report Statistics
              </div>
              <div
                className="visualization__header-reports"
                data-testid="visualization-filters-reports"
                aria-label="Filter ripa report for reports"
              >
                <div className="visualization__header-reports-value">
                  {formData.length}
                </div>
                <div className="visualization__header-reports-label">
                  {formData.length === 1 ? 'REPORT' : 'REPORTS'}
                </div>
              </div>
            </div>
            {status === 'draft' ?
              <Alert>
                Only report data with one of the following status will be included in the aggregated data results: Under
                Review, Submitted to DOJ, Denied by DOJ, Approved, Rejected
              </Alert>
              :
              <>
                <Chart formData={formData} formTemplates={formTemplates} />
                <Chart formData={formData} formTemplates={formTemplates} />
                <Chart formData={formData} formTemplates={formTemplates} />
              </>
            }
          </>}
        </div>
        <ConfirmDialog
          heading="Remove CSV Report"
          open={removeCsvReportDialogOpen}
          closeDialog={() => setRemoveCsvReportDialogOpen({ open: false })}
          negativeAction={() => setRemoveCsvReportDialogOpen({ open: false })}
          positiveAction={deleteCsvReport}
          positiveText="Delete"
          positiveActionTestId="csv-reports-remove-confirm-dialog-yes-button"
          negativeText="Cancel"
        >
          <div className="csv-reports-remove-dialog" data-testid="csv-reports-remove-dialog">
            Are you sure you want to remove CSV Report?
          </div>
        </ConfirmDialog>
      </Header>

      {/* Don't remove - Future use case */}
      {/* <ConfirmDialog */}
      {/*  heading="Generate Report Data" */}
      {/*  open={isReportModalOpen} */}
      {/*  closeDialog={() => setReportModalOpen(false)} */}
      {/*  negativeAction={() => setReportModalOpen(false)} */}
      {/*  positiveAction={handleExportCSV} */}
      {/*  positiveText="Export" */}
      {/*  negativeText="Cancel" */}
      {/*  data-testid="confirm-delete-report-dialog" */}
      {/* > */}
      {/*  <div className="generate-report-data"> */}
      {/*    The report data generated will contain records based on the filter settings. */}
      {/*  <div className="generate-report-data__report-indicator"> */}
      {/*    Please indicate which you would like the report to contain: */}
      {/*    <FormControl class="export-csv__form-control"> */}
      {/*      <RadioGroup */}
      {/*        defaultValue={exportOption} */}
      {/*        aria-label="export_options" */}
      {/*        className="radio-group" */}
      {/*      > */}
      {/*        <FormControlLabel */}
      {/*          value="report_data_records" */}
      {/*          control={<Radio classes={{ root: 'radio', checked: 'radio__checked' }} />} */}
      {/*          className="form-control" */}
      {/*          classes={{ label: `radio-label${exportOption === 'report_data_records' ? '__checked' : ''}` }} */}
      {/*          onChange={({ target: { value } }) => setExportOption(value)} */}
      {/*          label="Report Data Records" */}
      {/*        /> */}
      {/*        <Tooltip title="This feature is not currently available."> */}
      {/*          <FormControlLabel */}
      {/*            value="aggregated_report_stats" */}
      {/*            control={<Radio classes={{ root: 'radio', checked: 'radio__checked' }} />} */}
      {/*            className="form-control" */}
      {/*            classes={{ label: `radio-label${exportOption === 'aggregated_report_stats' ? '__checked' : ''}` }} */}
      {/*            onChange={({ target: { value } }) => setExportOption(value)} */}
      {/*            label="Aggregated Report Statistics" */}
      {/*          /> */}
      {/*        </Tooltip> */}
      {/*      </RadioGroup> */}
      {/*    </FormControl> */}
      {/*  </div> */}
      {/*  </div> */}
      {/* </ConfirmDialog> */}
    </>
  );
};

export const mapDispatchToProps = {
  getAllUserForms: ReviewDuck.getAllUserForms,
  getStats: ReviewDuck.getStats,
  getExportCsvReports: CsvReportsDuck.getExportCsvReports,
  getAllOfficers: ReviewDuck.getAllOfficers,
  getAllReviewers: Users.getAllReviewers,
  postExportCsvReports: CsvReportsDuck.postExportCsvReports,
  setRemoveCsvReportDialogOpen: CsvReportsDuck.setRemoveCsvReportDialogOpen,
  deleteCsvReport: CsvReportsDuck.deleteCsvReport
};

export const mapStateToProps = (state: any) => ({
  statistics: ReviewDuck.selectors.statistics(state),
  formData: ReviewDuck.selectors.userForms(state),
  csvReportRows: CsvReportsDuck.selectors.csvReportRows(state),
  formTemplates: Template.selectors.enumMap(state),
  loading: CsvReportsDuck.selectors.loading(state),
  removeCsvReportDialogOpen: CsvReportsDuck.selectors.removeCsvReportDialogOpen(state)
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Visualization);
