/* eslint-disable no-continue */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */

interface ElementPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

/**
 * Get the offset coordinates of the element.
 * @param {Object} element The element.
 * @return {Object} An object with four properties named x1, x2, y1 and y2 containing the elements left, right, top and bottom edges respectively.
 */
export function getElementPosition(element: HTMLElement): ElementPosition {
  let elementX = 0;
  let elementY = 0;
  let elementW = element.offsetWidth;
  let elementH = element.offsetHeight;

  while (element.offsetParent) {
    elementX += element.offsetLeft;
    elementY += element.offsetTop;
    element = element.offsetParent as HTMLElement; // Explicitly type element as HTMLElement
  }

  elementX += element.offsetLeft;
  elementY += element.offsetTop;
  elementW += elementX;
  elementH += elementY;

  return { x1: elementX, y1: elementY, x2: elementW, y2: elementH };
}
/**
 * Finds all elements that intersects with the given coordinates.
 * @param {Object} doc The document DOM object.
 * @param {Integer} x The X coordinate of the doc.
 * @param {Integer} y The Y coordinate of the doc.
 * @param {String} [tagName] An optional tag name filter.
 * @param {Function} [cmpCallback] A call back function to test whether or not an element should be included in the results (such as testing element id's, values, etc). The call back should return true or false.
 * @return {Array} Returns a primitive array of elements that intersects with point (x, y).
 */
export function getElementsIntersectingPoint(doc: Document, x: number, y: number, tagName = '*') {
  let elements: HTMLCollectionOf<Element> | [] = [];
  const results = [];
  let i = 0;
  let element = null;
  let pt = null;

  if (tagName == undefined || tagName == null) {
    tagName = '*';
  }

  elements = doc.getElementsByTagName(tagName);

  for (i = 0; i < elements.length; i++) {
    element = elements[i] as HTMLElement; // Explicitly type element as HTMLElement

    pt = getElementPosition(element);

    if (x >= pt.x1 && x <= pt.x2 && y >= pt.y1 && y <= pt.y2) {
      results[results.length] = element;
      continue;
    }
  }

  return results;
}
