import { connect, ConnectedProps } from 'react-redux';
import { Form, NewReport } from '@ducks';

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  currentPerson: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
});

const mapDispatchToProps = {
  onChange: (value: string | string[], pth: (string | number)[]) => Form.setFormField<string | string[]>({ path: pth })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  title: string;
  options: any[];
  setValidationErrors: (param: any) => void;
  resultPath: string;
  style: object;
  goToNext: () => void;
  multiple: boolean;
  isAll: boolean;
  boxHeight: number;
  boxWidth: number;
  boxPadding: number;
};
