import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';

const mapStateToProps = (state: any) => ({
  searchDescription: Form.selectors.searchDescription(state),
  labels: Form.selectors.labels(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
});

const mapDispatchToProps = {
  setSearchDescription: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'searchDescription'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
