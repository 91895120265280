import { isString } from 'lodash';

const getEnv = () => Object.keys(window.config ?? {}).length > 0 ? window.config : process.env;

const parseEnv = () => {
  const {
    REACT_APP_BASE_URL,
    NODE_ENV,
    REACT_APP_GOOGLE_API_KEY,
    REACT_APP_GEONAMES_USERNAME,
    REACT_APP_API_PORT,
    REACT_APP_SENTRY_DSN,
    REACT_APP_FEATURE_FLAGS,
  } = getEnv();

  const default_feature_flags = {
    enablePre2024Switch: false,
    enableForceEarlyPost2024Switch: false,
    serviceWorkerFeature: location.href.includes('ddns.net') || location.href.includes('contact-dev'),
    previewPost2024: '2024-01-01T08:00:00Z',
    userSettingsRequiredFields: [
      'race',
      // 'gender',
    ],
    onlineCheckTimeout: {
      '4g': 7500,
      '3g': 8500,
      '2g': 10000,
      'slow-2g': 12000,
    },
  };

  const feature_flags = Object.assign(
    default_feature_flags,
    isString(REACT_APP_FEATURE_FLAGS) ?
      JSON.parse(REACT_APP_FEATURE_FLAGS) :
      REACT_APP_FEATURE_FLAGS || {},
  );

  window.isDevelopment = NODE_ENV === 'development'

  return {
    REACT_APP_BASE_URL,
    NODE_ENV,
    REACT_APP_GOOGLE_API_KEY,
    REACT_APP_GEONAMES_USERNAME,
    REACT_APP_API_PORT,
    REACT_APP_SENTRY_DSN,
    REACT_APP_FEATURE_FLAGS: {
      ...feature_flags,
      // Turn off service worker feature if no sw controller exists
      // Turn off service worker feature if service worker not supported
      // Turn off service worker feature if env=development (production builds only)
      serviceWorkerFeature: !('serviceWorker' in navigator) || NODE_ENV === 'development' ? false : feature_flags.serviceWorkerFeature,
    },
  };
};

window.parseEnv = parseEnv;

export default parseEnv;
