import { connect, ConnectedProps } from 'react-redux';
import { User, Review } from '@ducks';

const mapDispatchToProps = {
  setReviewFeedbackDialogOpen: Review.setReviewFeedbackDialogOpen,
  setReviewFeedback: Review.setReviewFeedback,
  submitReviewFeedback: Review.submitReviewFeedback,
  updateForm: Review.updateForm,
};

const mapStateToProps = (state: any) => ({
  reviewFeedbackDialog: Review.selectors.reviewFeedbackDialog(state),
  userId: User.selectors.userId(state),
  userForms: Review.selectors.userForms(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
