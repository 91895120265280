import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'ramda'
import { connect, ConnectedProps } from 'react-redux'
import { TextField, InputAdornment } from '@material-ui/core'
import { sortText } from '@utility/sort'
import { Table, Column, ConfirmDialog } from '@components/common'
import { Header, OrganizationsRow, AddOrg, EditOrg, AddOrgUser, UploadUserCsvDialog } from '@components/custom'
import * as OrganizationsDuck from '@ducks/organizations'
import './Organizations.scss'

export const Organizations = ({ orgRows, getOrganizations, pagination, setPagination,
  setDeleteOrgDialogOpen, setUploadUserCsvDialogOpen, dialog, searchAndFilter, setSort, setSearch,
  loading, setAddOrgDialogOpen, setEditOrgDialogOpen, deleteOrg, setAddUserDialogOpen, uploadUserCsvDialog,
  setUploadDialogConfirmOpen, uploadUsers }: PropsFromRedux) => {
  const { sort } = searchAndFilter
  useEffect(() => {
    getOrganizations({ pagination, searchAndFilter })
  }, [])

  return (
    <Header title="Contact">
      <div className="organizations" data-testid="organizations">
        <div className="organizations__search-container">
          <TextField
            className="organizations__search"
            variant="outlined"
            placeholder="Search..."
            onChange={({ target: { value } }) => setSearch(value || undefined)}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <div className="material-icons">search</div>
              </InputAdornment>
            }}
            inputProps={{
              'data-testid': 'organizations-search'
            }}
          />
          <div className="organizations__actions">
            <div
              className="organizations__add-org"
              aria-label="Approve selected report rows"
              data-testid="organizations-add-org"
              role="button"
              tabIndex={0}
              onClick={() => setAddOrgDialogOpen({ open: true })}
              onKeyUp={() => setAddOrgDialogOpen({ open: true })}
            >
              <div className="material-icons">
                add
              </div>
              <div className="organizations__add-org-text">
                ADD ORGANIZATION
              </div>
            </div>
          </div>
        </div>
        <div className="organizations__table">
          <Table
            RowComponent={OrganizationsRow}
            rowData={orgRows}
            dataQuery={({ page, limit }: { page: number; limit: number }) => getOrganizations({
              searchAndFilter: { ...searchAndFilter, sort },
              pagination: { currentPage: page, pageSize: limit }
            })}
            loading={loading.getOrganizations}
            pagination={pagination}
            setSort={setSort}
            setPagination={setPagination}
            emptyMessage="There are no organizations"
          >
            <Column title="Organization" dataKey="name" grow={2} minWidth={100} sort={sortText('name')} />
            <Column title="Subdomain" dataKey="subdomain" grow={2} minWidth={100} sort={sortText('subdomain')} />
            <Column title="State" dataKey="state" grow={1} minWidth={60} sort={sortText('state')} />
            <Column title="Org ID" dataKey="id" grow={1} minWidth={100} sort={sortText('id')} />
            <Column title="Prod" dataKey="doj_production" grow={1} minWidth={60} />
            <Column title="Created" dataKey="created" grow={1} minWidth={100} />
            <Column title="Actions" dataKey="actions" grow={1} minWidth={170} />
          </Table>
        </div>
      </div>
      <ConfirmDialog
        heading="Add Org Admin"
        open={dialog.addUserDialogOpen}
        closeDialog={() => setAddUserDialogOpen({ open: false })}
        negativeAction={() => setAddUserDialogOpen({ open: false })}
        positiveText="Save"
        negativeText="Cancel"
      >
        <AddOrgUser />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Add Org"
        open={dialog.addOrgDialogOpen}
        closeDialog={() => setAddOrgDialogOpen({ open: false })}
        negativeAction={() => setAddOrgDialogOpen({ open: false })}
        positiveText="Save"
        negativeText="Cancel"
      >
        <AddOrg />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Edit Org"
        open={dialog.editOrgDialogOpen}
        closeDialog={() => setEditOrgDialogOpen({ open: false })}
        negativeAction={() => setEditOrgDialogOpen({ open: false })}
        positiveText="Save"
        negativeText="Cancel"
      >
        <EditOrg />
      </ConfirmDialog>
      <ConfirmDialog
        heading="Remove Org"
        open={dialog.deleteOrgDialogOpen}
        closeDialog={() => setDeleteOrgDialogOpen({ open: false })}
        negativeAction={() => setDeleteOrgDialogOpen({ open: false })}
        positiveAction={deleteOrg}
        positiveText="Remove"
        negativeText="Cancel"
      >
        <div>Are you sure you want to remove this org?</div>
      </ConfirmDialog>
      <ConfirmDialog
        className="organizations__upload-csv-dialog"
        heading="Upload User CSV"
        open={dialog.uploadUserCsvDialogOpen}
        closeDialog={() => setUploadUserCsvDialogOpen({ open: false })}
        negativeAction={() => setUploadUserCsvDialogOpen({ open: false })}
        negativeText="Cancel"
        positiveText={uploadUserCsvDialog.complete && uploadUserCsvDialog.failed > 0 ? 'Retry Errors' : 'Upload'}
        disabledPositive={uploadUserCsvDialog.complete ? Object.keys(uploadUserCsvDialog.errors) ? Object.keys(uploadUserCsvDialog.errors).length > 0 : false : true}
      >
        <UploadUserCsvDialog />
      </ConfirmDialog>
      <ConfirmDialog
        className="organizations__upload-csv-dialog-confirm"
        heading="Confirm Upload"
        open={dialog.uploadUserCsvDialogConfirmOpen}
        closeDialog={() => setUploadDialogConfirmOpen({ open: false, confirmed: false })}
        negativeAction={() => setUploadDialogConfirmOpen({ open: false, confirmed: false })}
        positiveAction={() => {
          setUploadDialogConfirmOpen({ open: false, confirmed: true });
          setUploadUserCsvDialogOpen({ open: true, orgId: uploadUserCsvDialog.orgId, orgName: uploadUserCsvDialog.orgName });
          uploadUsers();
        }}
        negativeText="Cancel"
        positiveText="Upload"
      >
        <div data-testid="organizations-upload-csv-dialog-confirm">
          <p>
            {`This will create ${uploadUserCsvDialog.userList.length} users in ${uploadUserCsvDialog.orgName} (subdomain: ${uploadUserCsvDialog.subdomain}, id: ${uploadUserCsvDialog.orgId}).`}
          </p>
          <p>
            This action cannot be undone. Are you sure?
          </p>
        </div>
      </ConfirmDialog>
    </Header>
  )
}

const mapDispatchToProps = {
  setPagination: OrganizationsDuck.setPagination,
  setSort: OrganizationsDuck.setSortAndSearch,
  setSearch: OrganizationsDuck.setSearch,
  getOrganizations: OrganizationsDuck.getOrganizations,
  setAddOrgDialogOpen: OrganizationsDuck.setAddOrgDialogOpen,
  setEditOrgDialogOpen: OrganizationsDuck.setEditOrgDialogOpen,
  setDeleteOrgDialogOpen: OrganizationsDuck.setDeleteOrgDialogOpen,
  setAddUserDialogOpen: OrganizationsDuck.setAddUserDialogOpen,
  setUploadUserCsvDialogOpen: OrganizationsDuck.setUploadUserCsvDialogOpen,
  setUploadDialogConfirmOpen: OrganizationsDuck.setUploadDialogConfirmOpen,
  deleteOrg: OrganizationsDuck.deleteOrg,
  uploadUsers: OrganizationsDuck.uploadUsers
}

const mapStateToProps = (state: any) => ({
  dialog: OrganizationsDuck.selectors.dialog(state),
  loading: OrganizationsDuck.selectors.loading(state),
  searchAndFilter: OrganizationsDuck.selectors.searchAndFilter(state),
  pagination: OrganizationsDuck.selectors.pagination(state),
  orgRows: OrganizationsDuck.selectors.orgRows(state),
  uploadUserCsvDialog: OrganizationsDuck.selectors.uploadUserCsvDialog(state)
})

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Organizations)
