import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  seizedPropertyBasis: Form.selectors.seizedPropertyBasis(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  seizureBasis: get(Template.selectors.enumMap(state), 'basisForPropertySeizure.possibleValues', {}),
  student: Form.selectors.student(state),
  anyContrabandFound: Form.selectors.anyContrabandFound(state),
});

const mapDispatchToProps = {
  toggleSeizedPropertyBasis: (value: string, personIndex: number) => Form.toggleSeizedPropertyBasis({ value, personIndex }),
  setAnyContrabandFound: (value: boolean, personIndex: any) => Form.setFormField({ path: ['person', personIndex, 'anyContrabandFound'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
