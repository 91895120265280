import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { User, Config } from '@ducks';

const mapDispatchToProps = {
  login: User.login,
  getOrganization: User.getOrganization,
  setLoginDialogOpen: User.setLoginDialogOpen,
};

const mapStateToProps = (state: any) => ({
  loginError: User.selectors.loginError(state),
  online: Config.selectors.online(state),
  loginDialogOpen: User.selectors.loginDialogOpen(state),
  errorMessage: User.selectors.errorMessage(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export const composer = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export type Props = ConnectedProps<typeof connector>;
