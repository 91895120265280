import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import { User } from '@ducks';

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  customQuestions: Form.selectors.customQuestionAnswers(state),
  steps: NewReport.selectors.registeredSteps(state),
  flags: Form.selectors.flags(state),
  isUserSettingsValid: User.selectors.isUserSettingsValid(state),
});

const mapDispatchToProps = {
  back: NewReport.back,
  submitFormForReview: Form.submitFormForReview,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
