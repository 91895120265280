import { assoc, mergeRight, path } from 'ramda';
import { debounce } from 'lodash';
import { AlertLevel, createSnackNotification } from '@components/common';
import { httpErrorHandler } from '@utility/httpErrorHandler';
import {
  GlobalConfigurationNamespace,
  UserNamespace,
  isAfter2024,
} from './constants';
import { setFlags } from './form';

export const SET_ORGANIZATION = `${GlobalConfigurationNamespace}/SET_ORGANIZATION`;
export const SET_LOADING_ORG = `${GlobalConfigurationNamespace}/SET_LOADING_ORG`;
export const SET_UPLOADING_ORG = `${GlobalConfigurationNamespace}/SET_UPLOADING_ORG`;
export const SET_CONFIRM_SUBMIT_DIALOG_OPEN = `${GlobalConfigurationNamespace}/SET_CONFIRM_SUBMIT_DIALOG_OPEN`;
export const SET_CONFIRM_SFTP_SETTINGS_DIALOG_OPEN = `${GlobalConfigurationNamespace}/SET_CONFIRM_SFTP_SETTINGS_DIALOG_OPEN`;
export const SET_CONFIRM_SCHEDULE_DIALOG_OPEN = `${GlobalConfigurationNamespace}/SET_CONFIRM_SCHEDULE_DIALOG_OPEN`;

const INITIAL_STATE = {
  organization: {
    id: null,
    sftp_credential: {
      hostname: null,
      username: null,
      key: null,
      password: null,
    },
    sftp_credentials_set: false,
    job_submission_schedule: {
      mode: null,
      frequency: null,
      start_date: null,
    },
    clipboardCodeFeature: false,
    enforceOfficerDemographics: true,
  },
  loading: {
    getOrganization: false,
    uploadingOrganization: false,
  },
  confirmSubmitDialogOpen: false,
  confirmSftpSettingsDialogOpen: false,
  confirmScheduleDialogOpen: false
};

// takes a {} object and returns a FormData object
const objectToFormData = (obj, formData, namespace) => {
  const fd = formData || new FormData();
  if (obj === null) {
    return fd;
  }

  for (const [key, value] of Object.entries(obj)) {
    const formKey = namespace ? `${namespace}[${key}]` : key;
    // non-file object => recurse
    if (typeof value === 'object' && !(value instanceof File)) {
      objectToFormData(value, fd, formKey);
    } else {
      fd.append(formKey, value);
    }
  }
  return fd;
};

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ORGANIZATION: {
      return mergeRight(state, {
        organization: {
          ...state.organization,
          ...payload.organization,
          sftp_credential: {
            ...state.organization.sftp_credential,
            ...payload?.organization?.sftp_credential,
          },
          job_submission_schedule: {
            ...state.organization.job_submission_schedule,
            ...payload?.organization?.job_submission_schedule,
          }
        },
      });
    }
    case SET_LOADING_ORG:
      return mergeRight(state, { loading: { ...state.loading, getOrganization: payload.isLoading } });
    case SET_UPLOADING_ORG:
      return mergeRight(state, { loading: { ...state.loading, uploadOrganization: payload.isLoading } });
    case SET_CONFIRM_SUBMIT_DIALOG_OPEN:
      return assoc('confirmSubmitDialogOpen', payload, state);
    case SET_CONFIRM_SFTP_SETTINGS_DIALOG_OPEN:
      return assoc('confirmSftpSettingsDialogOpen', payload, state);
    case SET_CONFIRM_SCHEDULE_DIALOG_OPEN:
      return assoc('confirmScheduleDialogOpen', payload, state);
    default:
      return state;
  }
};

export const setOrganization = organization => ({
  type: SET_ORGANIZATION,
  payload: { organization },
});

export const setLoadingOrganization = isLoading => ({
  type: SET_LOADING_ORG,
  payload: { isLoading },
});

export const setUploadingOrganization = isLoading => ({
  type: SET_UPLOADING_ORG,
  payload: { isLoading },
});

export const setConfirmSubmitDialogOpen = isOpen => ({
  type: SET_CONFIRM_SUBMIT_DIALOG_OPEN,
  payload: isOpen
})

export const setConfirmSftpSettingsDialogOpen = isOpen => ({
  type: SET_CONFIRM_SFTP_SETTINGS_DIALOG_OPEN,
  payload: isOpen
})

export const setConfirmScheduleDialogOpen = isOpen => ({
  type: SET_CONFIRM_SCHEDULE_DIALOG_OPEN,
  payload: isOpen
})

export const getOrganization = () => (dispatch, getState, { http }) => {
  dispatch(setLoadingOrganization(true));

  const { organization_id } = getState()[UserNamespace].user;

  return http
    .get(`/organizations/${organization_id}`)
    .then(({ data }) => {
      if (data) {
        const { organization } = data;
        dispatch(setOrganization(organization));
        let pre2024 = organization?.pre2024 ?? false;
        const earlyPost2024 = organization?.earlyPost2024 ?? false;
        const post2024 = earlyPost2024 || isAfter2024();
        if (post2024) {
          pre2024 = true;
        }
        dispatch(setFlags({ pre2024, post2024 }))
      } else {
        throw new Error('Organization doesn\'t exist');
      }
    })
    .catch(httpErrorHandler('Failed to get organization'))
    .finally(() => {
      setTimeout(() => dispatch(setLoadingOrganization(false)), 100);
    });
};

export const uploadOrganization = (settingType) => (dispatch, getState, { http }) => {
  dispatch(setUploadingOrganization(true));

  const { organization_id } = getState()[UserNamespace].user;
  const { organization: { sftp_credential, job_submission_schedule, clipboardCodeFeature } } = getState()[GlobalConfigurationNamespace];

  const form = objectToFormData({
    organization: settingType === 'jobSchedule' ? {
      job_submission_schedule_attributes: job_submission_schedule,
      clipboardCodeFeature
    } : {
      sftp_credential_attributes: sftp_credential,
      clipboardCodeFeature
    }
  });

  return http
    .patch(`/organizations/${organization_id}`, form)
    .then(() => {
      if (settingType === 'jobSchedule') {
        createSnackNotification(AlertLevel.Success, 'Success', 'DOJ Submission Schedule Updated');
      } else {
        createSnackNotification(AlertLevel.Success, 'Success', 'SFTP Settings Updated');
      }
    })
    .catch(httpErrorHandler('Failed to update org configuration'))
    .finally(() => {
      setTimeout(() => {
        dispatch(getOrganization());
        dispatch(setUploadingOrganization(false));
        dispatch(setConfirmSftpSettingsDialogOpen(false));
        dispatch(setConfirmScheduleDialogOpen(false));
      }, 500);
    });
};

const debouncedSubmitApprovedToDoj = debounce(({ http, dispatch }) => http
  .patch('/submit_approved_to_doj')
  .then(() => {
    createSnackNotification(AlertLevel.Success, 'Success', 'Submission initiated.');
    dispatch(setConfirmSubmitDialogOpen(false));
  })
  .catch(httpErrorHandler('Failed to submit approved forms')), 5000, { leading: true });

export const submitApprovedToDoj = () => (dispatch, getState, { http }) =>
  debouncedSubmitApprovedToDoj({ dispatch, http });

export const selectors = ({
  organization: path([GlobalConfigurationNamespace, 'organization']),
  confirmSubmitDialogOpen: path([GlobalConfigurationNamespace, 'confirmSubmitDialogOpen']),
  confirmSftpSettingsDialogOpen: path([GlobalConfigurationNamespace, 'confirmSftpSettingsDialogOpen']),
  confirmScheduleDialogOpen: path([GlobalConfigurationNamespace, 'confirmScheduleDialogOpen'])
});
