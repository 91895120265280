import React, { useState } from 'react'
import cn from 'classnames'
import { LabelField, LabelDropdown, Button, createSnackNotification, AlertLevel, Loading, Switch } from '@components/common'
import { connect, ConnectedProps } from 'react-redux'
import * as Organizations from '@ducks/organizations';
import { onEnter } from '@utility/keypressHelpers'
import { oneloginTemplate, azureadTemplate } from '@utility/authSettingsTemplates'
import { isImage } from '@utility/typeGuards';
import './AddOrg.scss'
import checker from './checker.png'

interface Props extends PropsFromRedux {
  setPositiveAction?: (next: Function) => void;
  auth_provider?: string;
}

const AddOrg = ({ addOrgDialog, setAddOrgField, setPositiveAction, addOrg, loading }: Props) => {
  const [errors, setErrors] = useState<{
    organizationName?: string;
    subdomain?: string;
    authProvider?: string;
    state?: string;
    authSettings?: string;
  }>({})
  const { smallLogoImage, state, loginBackgroundImage, largeLogoImage, auth_provider, auth_settings, pre2024 } = addOrgDialog
  const hasLoginBackground = Boolean(loginBackgroundImage)
  const hasSmallLogo = Boolean(smallLogoImage)
  const hasLargeLogo = Boolean(largeLogoImage)
  const [disablePre2024] = useState(pre2024 ?? false);

  type FieldValue = Partial<typeof addOrgDialog>;
  const setFieldAndCheckValidation = (fieldValue: FieldValue, submit?: boolean) => {
    const key = Object.keys(fieldValue)[0]
    const getValue = (param: string) => submit ? addOrgDialog[param] : fieldValue[key]
    const fieldHasKey = (k: string) => fieldValue[k] !== undefined
    setAddOrgField(fieldValue)

    if (fieldHasKey('organizationName') || submit) {
      errors.organizationName = getValue('organizationName').length === 0 ? 'Cannot be blank' : undefined
    }

    if (fieldHasKey('subdomain') || submit) {
      const condition = getValue('subdomain').length === 0
      errors.subdomain = condition ? 'Cannot be blank' : undefined
    }

    if (fieldHasKey('subdomain') || submit) {
      const valid = getValue('subdomain')?.match(/^[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?$/)
      errors.subdomain = valid ? errors.subdomain : 'Can only contain characters a-z 0-9 or - '
    }

    if (fieldHasKey('auth_provider') || submit) {
      errors.authProvider = ['', 'OneLogin', 'AzureAD'].includes(getValue('auth_provider')) ? undefined : 'Cannot be blank';
    }

    if (fieldHasKey('state') || submit) {
      const condition = !getValue('state')
      errors.state = condition ? 'Cannot be blank' : undefined
    }

    if (fieldHasKey('auth_settings') || submit) {
      try {
        if (getValue('auth_settings').length) JSON.parse(getValue('auth_settings'));
        errors.authSettings = undefined;
      } catch {
        errors.authSettings = 'Must be valid JSON';
      }
    }

    setErrors(errors)
    return errors
  }
  setPositiveAction?.((next: Function) => {
    if (!loading.addOrganizations) {
      const e = setFieldAndCheckValidation(addOrgDialog, true)
      const hasErrors = Object.values(e).filter((v: any) => v?.any !== undefined ? v.any : v !== undefined).length > 0

      if (!hasErrors) {
        addOrg()
      }
      next()
    }
  })

  const onChangePre2024 = (value: boolean) => {
    setAddOrgField({ pre2024: value })
  }

  return (
    <div className="add-org" data-testid="add-org">
      <div className={cn('add-org__upload-spinner', { uploading: loading.addOrganizations })}>
        <div className="add-org__upload-spinner-loading">
          <div> Uploading... </div>
          <Loading />
        </div>
      </div>
      <LabelField
        label="Organization Name"
        className="add-org__name"
        placeholder="Springfield PD"
        error={Boolean(errors.organizationName)}
        errorMessage={errors.organizationName}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ organizationName: value })}
        inputProps={{
          'data-testid': 'add-org-name',
        }}
      />
      <LabelField
        label="Subdomain"
        className="add-org__subdomain"
        placeholder="sfpd"
        error={Boolean(errors.subdomain)}
        errorMessage={errors.subdomain}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ subdomain: value })}
        inputProps={{
          'data-testid': 'add-org-subdomain',
        }}
      />
      <LabelField
        label="ORI"
        className="add-org__ori"
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ ori: value })}
        inputProps={{
          'data-testid': 'add-org-ori',
        }}
      />
      <LabelDropdown
        className="add-org__dropdown-state"
        data-testid="add-org-dropdown-state"
        label="State"
        error={Boolean(errors.state)}
        errorMessage={errors.state}
        values={Organizations.AllowedStates.map((y) => ({ value: y, text: y }))}
        onChange={({ target: { value } }) => {
          setFieldAndCheckValidation({ state: value })
        }}
        renderValue={(selected: any) => selected}
        value={state}
      />
      <LabelDropdown
        className="add-org__dropdown-auth-providers"
        data-testid="add-org-dropdown-auth-providers"
        label="Auth Provider"
        error={Boolean(errors.authProvider)}
        errorMessage={errors.authProvider}
        values={['Basic', 'OneLogin', 'AzureAD'].map((y) => ({ value: y, text: y }))}
        onChange={({ target: { value } }) => {
          setFieldAndCheckValidation({ auth_provider: value === 'Basic' ? '' : value });
          setFieldAndCheckValidation({ auth_settings: {} })
        }}
        renderValue={(selected: any) => selected}
        value={auth_provider === '' ? 'Basic' : auth_provider}
      />
      <div className="add-org__2024-flags">
        <div className="add-org__2024-flags-heading">
          Enable early 2024 changes (WARNING: Cannot be turned off)
        </div>
        <Switch
          className="add-org__2024-flags-switch"
          offText="OFF"
          onText="ON"
          value={pre2024}
          disabled={disablePre2024}
          onChange={onChangePre2024}
        />
      </div>
      {['OneLogin', 'AzureAD'].includes(auth_provider) && (
        <LabelField
          className="add-org__auth-settings-input"
          multiline
          minRows={4}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ auth_settings: value })}
          error={Boolean(errors.authSettings)}
          errorMessage={errors.authSettings}
          value={auth_settings.length ?
            auth_settings :
            (auth_provider === 'OneLogin' ? oneloginTemplate : azureadTemplate)
          }
        />
      )}
      <div className="add-org__image-upload">
        <div className="add-org__image-upload-heading">Login Background</div>
        <div className={cn('add-org__image-preview-container', { 'has-image': hasLoginBackground })} style={{ backgroundImage: hasLoginBackground ? `url(${checker})` : undefined }}>
          <img id="login-background-preview" className="add-org__image-preview" alt="Preview of image upload" />
          <div className="add-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setAddOrgField({ loginBackgroundImage: undefined })
              }}
              onKeyUp={onEnter(() => {
                setAddOrgField({ loginBackgroundImage: undefined })
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="add-org__browse-container">
          <Button className="add-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e.target.files?.[0]
                  setAddOrgField({ loginBackgroundImage: file })
                  const preview = document.getElementById('login-background-preview')
                  if (preview && file && isImage(preview)) {
                    preview.src = URL.createObjectURL(file)
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src)
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br>  <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="add-org__browse-types-container">
            <div className="add-org__browse-types-label">File Types:</div>
            <div className="add-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
      <div className="add-org__image-upload">
        <div className="add-org__image-upload-heading">Small Logo</div>
        <div className={cn('add-org__image-preview-container', { 'has-image': hasSmallLogo })} style={{ backgroundImage: hasSmallLogo ? `url(${checker})` : undefined }}>
          <img id="small-logo-preview" className="add-org__image-preview" alt="Preview of image upload" />
          <div className="add-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setAddOrgField({ smallLogoImage: undefined })
              }}
              onKeyUp={onEnter(() => {
                setAddOrgField({ smallLogoImage: undefined })
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="add-org__browse-container">
          <Button className="add-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e.target.files?.[0]
                  setAddOrgField({ smallLogoImage: file })
                  const preview = document.getElementById('small-logo-preview')
                  if (isImage(preview) && file) {
                    preview.src = URL.createObjectURL(file)
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src)
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br> <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="add-org__browse-types-container">
            <div className="add-org__browse-types-label">File Types:</div>
            <div className="add-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
      <div className="add-org__image-upload">
        <div className="add-org__image-upload-heading">Large Logo</div>
        <div className={cn('add-org__image-preview-container', { 'has-image': hasLargeLogo })} style={{ backgroundImage: hasLargeLogo ? `url(${checker})` : undefined }}>
          <img id="large-logo-preview" className="add-org__image-preview" alt="Preview of image upload" />
          <div className="add-org__image-preview-delete">
            <div
              className="material-icons"
              role="button"
              tabIndex={0}
              aria-label="Delete this logo"
              onClick={() => {
                setAddOrgField({ largeLogoImage: undefined })
              }}
              onKeyUp={onEnter(() => {
                setAddOrgField({ largeLogoImage: undefined })
              })}
            >
              cancel
            </div>
          </div>
        </div>
        <div className="add-org__browse-container">
          <Button className="add-org__browse-button" aria-label="Browse for an image">
            <input
              type="file"
              accept=".svg,.jpg,.jpeg,.png"
              onChange={(e) => {
                try {
                  const file = e.target.files?.[0]
                  setAddOrgField({ largeLogoImage: file })
                  const preview = document.getElementById('large-logo-preview')
                  if (isImage(preview) && file) {
                    preview.src = URL.createObjectURL(file)
                    preview.onload = () => {
                      URL.revokeObjectURL(preview.src)
                    }
                  }
                } catch (err: any) {
                  createSnackNotification(AlertLevel.Error, 'Something went wrong', `Please try again </br> <small>${err.message}</small>`)
                }
              }}
            />
            Browse
          </Button>
          <div className="add-org__browse-types-container">
            <div className="add-org__browse-types-label">File Types:</div>
            <div className="add-org__browse-types">.svg, .png, .jpg</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  addOrgDialog: Organizations.selectors.addOrgDialog(state),
  loading: Organizations.selectors.loading(state)
})

const mapDispatchToProps = {
  setAddOrgField: Organizations.setAddOrgField,
  addOrg: Organizations.addOrg
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddOrg)
