import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Form, NewReport } from '@ducks';
import { onEnter } from '@utility/keypressHelpers';
import './RipaStoppedPassengerForm.scss';

interface Props extends PropsFromRedux {
  goToNext: () => void;
}

const RipaStoppedPassengerForm = ({ setStoppedPassenger, numberOfPeople, labels, stoppedPassenger, currentSubloop, goToNext }: Props) => {
  const subloopIndex = currentSubloop;

  return (
    <div className="ripa-stopped-passenger-form" data-testid="ripa-stopped-passenger-form">
      <div className={cn('ripa-stopped-passenger-form__person')}>
        <div className="material-icons">person</div>
        <div className="ripa-stopped-passenger-form__person-label" data-testid="ripa-stopped-passenger-form-person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-stopped-passenger-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-stopped-passenger-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-stopped-passenger-form__title">
        Stopped person is a passenger in a vehicle?
      </div>
      <div className="ripa-stopped-passenger-form__select-box-container">
        <div
          className={cn('ripa-stopped-passenger-form__select-box', { active: stoppedPassenger[subloopIndex] })}
          onClick={() => {
            setStoppedPassenger(true, subloopIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setStoppedPassenger(true, subloopIndex))}
          role="button"
          tabIndex={0}
          data-testid="ripa-stopped-passenger-yes"
        >
            Yes
        </div>
        <div
          className={cn('ripa-stopped-passenger-form__select-box', { active: stoppedPassenger[subloopIndex] === false })}
          onClick={() => {
            setStoppedPassenger(false, subloopIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setStoppedPassenger(false, subloopIndex))}
          role="button"
          tabIndex={0}
          data-testid="ripa-stopped-passenger-no"
        >
          No
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  stoppedPassenger: Form.selectors.stoppedPassenger(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  labels: Form.selectors.labels(state),
})

const mapDispatchToProps = {
  setStoppedPassenger: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'stoppedPassenger'] })(value),
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaStoppedPassengerForm)
