import React, { useState } from 'react'
import cn from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import * as Organizations from '@ducks/organizations';
import { LabelField, Checkbox } from '@components/common'
import './AddOrgUser.scss'

interface Props extends PropsFromRedux {
  setPositiveAction?: (next: Function) => void;
}

const AddOrgUser = ({ addUserDialog, setAddOrgUserField, setPositiveAction, addUser }: Props) => {
  const [errors, setErrors] = useState<{
    yearsOfExperience?: string;
    officerId?: string;
    password?: Record<string, any>;
    firstname?: string;
    lastname?: string;
    email?: string;
    username?: string;
  }>({})
  const setFieldAndCheckValidation = (fieldValue: any, submit?: boolean) => {
    const key = Object.keys(fieldValue)[0]
    const getValue = (param: string) => submit ? addUserDialog[param] : fieldValue[key]
    const fieldHasKey = (k: string) => fieldValue[k] !== undefined
    setAddOrgUserField(fieldValue)

    if (fieldHasKey('firstname') || submit) {
      errors.firstname = getValue('firstname').length === 0 ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('lastname') || submit) {
      const condition = getValue('lastname').length === 0
      errors.lastname = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('email')) {
      errors.email = undefined;
    }
    if (fieldHasKey('email') && submit) {
      const matchAry = getValue('email')?.toLowerCase()?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ?? []
      errors.email = matchAry?.length === 0 ? 'Email is invalid' : undefined
    }
    if (fieldHasKey('email') || submit) {
      const condition = getValue('email').length === 0
      errors.email = condition ? 'Cannot be blank' : errors.email
    }
    if (fieldHasKey('username') || submit) {
      const condition = getValue('username').length === 0
      errors.username = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('officerId') || submit) {
      const condition = getValue('officerId').length && !getValue('officerId').match(/^[0-9a-zA-Z]{9}$/);
      errors.officerId = condition ? 'Must be 9 alphanumeric characters or blank' : undefined;
    }
    if (fieldHasKey('yearsOfExperience') || submit) {
      const condition = getValue('yearsOfExperience') === ''
      errors.yearsOfExperience = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('password') || submit) {
      const condition = getValue('password').length >= 8
      errors.password = { ...errors.password, hasEight: condition }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[0-9]/g) ?? []
      errors.password = { ...errors.password, hasNumber: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[A-Z]/g) ?? []
      errors.password = { ...errors.password, hasUppercase: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') || submit) {
      const matchAry = getValue('password')?.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g) ?? []
      errors.password = { ...errors.password, hasSpecial: matchAry.length !== 0 }
    }
    if (fieldHasKey('password') && submit) {
      const { hasSpecial, hasUppercase, hasNumber, hasEight } = errors.password || {}
      errors.password = { ...errors.password, any: !hasSpecial || !hasUppercase || !hasNumber || !hasEight }
    }
    setErrors(errors)
    return errors
  }
  setPositiveAction?.((next: Function) => {
    const e = setFieldAndCheckValidation(addUserDialog, true)
    // TODO: Work out what the `.any` is checking for and either remove of fix type so it's valid
    const hasErrors = Object.values(e).filter((v: any) => v?.any !== undefined ? v.any : v !== undefined).length > 0

    if (!hasErrors) {
      addUser()
    }
    next()
  })

  return (
    <div className="add-org-user" data-testid="add-org-user">
      <div className="add-org-user__name-container">
        <LabelField
          label="First Name"
          className="add-org-user__name-first-name"
          placeholder="Clancy"
          error={Boolean(errors.firstname)}
          errorMessage={errors.firstname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ firstname: value })}
          inputProps={{
            'data-testid': 'add-org-user-name-first-name'
          }}
        />
        <LabelField
          label="Last Name"
          className="add-org-user__name-last-name"
          placeholder="Wiggum"
          error={Boolean(errors.lastname)}
          errorMessage={errors.lastname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ lastname: value })}
          inputProps={{
            'data-testid': 'add-org-user-name-last-name'
          }}
        />
      </div>
      <LabelField
        label="Email Address"
        className="add-org-user__email"
        placeholder="clancy.wiggum@emailaddress.com"
        type="email"
        error={Boolean(errors.email)}
        errorMessage={errors.email}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ email: value })}
        inputProps={{
          'data-testid': 'add-org-user-email'
        }}
      />
      <LabelField
        label="Username"
        placeholder="cwiggum"
        className="add-org-user__username"
        error={Boolean(errors.username)}
        errorMessage={errors.username}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ username: value })}
        inputProps={{
          'data-testid': 'add-org-user-username'
        }}
      />
      <LabelField
        label="DOJ Officer ID"
        placeholder="Leave blank to auto-set"
        className="add-org-user__officerId"
        error={Boolean(errors.officerId)}
        errorMessage={errors.officerId}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ officerId: value })}
        inputProps={{
          pattern: '[0-9a-zA-Z]{9}',
          'data-testid': 'add-org-user-officerId'
        }}
      />
      <div className="add-org-user__hr" />
      <div className="add-org-user__service-container">
        <LabelField
          label="Years of Experience"
          placeholder="Enter Number..."
          type="number"
          className="add-org-user__years"
          error={Boolean(errors.yearsOfExperience)}
          errorMessage={errors.yearsOfExperience}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ yearsOfExperience: value })}
          inputProps={{
            min: 0,
            max: 51,
            'data-testid': 'add-org-user-years'
          }}
        />
      </div>
      <div className="add-org-user__roles-heading">
        <div className="add-org-user__roles-text">
          ROLES
        </div>
        <Checkbox className="add-org-user__checkbox-admin" label="Admin" defaultValue="true" disabled="true" />
      </div>
      <div className="add-org-user__hr" />
      <LabelField
        label="Password"
        className="add-org-user__password"
        error={errors?.password?.any}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ password: value })}
        type="password"
        inputProps={{
          'data-testid': 'add-org-user-password',
        }}
      />
      <div className="add-org-user__password-validation-text">
        Password must contain the following:
      </div>
      <div className="add-org-user__password-validation-checks">
        <div className="add-org-user__password-validation-check">
          <div
            className={cn('add-org-user__box', { checked: errors?.password?.hasEight })}
            data-testid="add-org-user-has-eight"
          >
            <div className="material-icons">
              {errors?.password?.hasEight ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-org-user__password-validation-check-label">
            8 CHARACTERS
          </div>
        </div>
        <div className="add-org-user__password-validation-check">
          <div
            className={cn('add-org-user__box', { checked: errors?.password?.hasNumber })}
            data-testid="add-org-user-has-number"
          >
            <div className="material-icons">
              {errors?.password?.hasNumber ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-org-user__password-validation-check-label">
            1 NUMBER
          </div>
        </div>
        <div className="add-org-user__password-validation-check">
          <div
            className={cn('add-org-user__box', { checked: errors?.password?.hasUppercase })}
            data-testid="add-org-user-has-uppercase"
          >
            <div className="material-icons">
              {errors?.password?.hasUppercase ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-org-user__password-validation-check-label">
            1 UPPERCASE
          </div>
        </div>
        <div className="add-org-user__password-validation-check">
          <div
            className={cn('add-org-user__box', { checked: errors?.password?.hasSpecial })}
            data-testid="add-org-user-has-special"
          >
            <div className="material-icons">
              {errors?.password?.hasSpecial ? 'done' : 'not_interested'}
            </div>
          </div>
          <div className="add-org-user__password-validation-check-label">
            1 SPECIAL CHARACTER
          </div>
        </div>
      </div>
    </div>
  )
}

// AddOrgUser.propTypes = {
//   setAddOrgUserField: PropTypes.func,
//   addUser: PropTypes.func,
//   addUserDialog: PropTypes.bool,
//   setPositiveAction: PropTypes.func
// }

const mapStateToProps = (state: any) => ({
  addUserDialog: Organizations.selectors.addUserDialog(state)
})

const mapDispatchToProps = {
  setAddOrgUserField: Organizations.setAddOrgUserField,
  addUser: Organizations.addUser
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddOrgUser);
