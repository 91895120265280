import { connect, ConnectedProps } from 'react-redux';
import { Config, Form, Template } from '@ducks';

const mapStateToProps = (state: any) => ({
  typeOfStop: Form.selectors.typeOfStop(state),
  stopTypes: Template.selectors.enumMap(state),
  breakpoint: Config.selectors.breakpoint(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  actionTaken: Form.selectors.actionTaken(state),
});

const mapDispatchToProps = {
  setTypeOfStop: Form.setFormField({ path: ['typeOfStop'] }),
  setStoppedPassenger: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'stoppedPassenger'] })(value),
  setStoppedInsideResidence: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'stoppedInsideResidence'] })(value),
  toggleActionTaken: Form.toggleActionTaken,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
