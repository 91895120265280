import React from 'react';
import cn from 'classnames';
import { TextField, Tooltip } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { onEnter } from '@utility/keypressHelpers';
import offenseCode from '@utility/offenseCode';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { Props, connector } from './props';
import './RipaTrafficViolationForm.scss';

type Offense = ReturnType<typeof offenseCode>[0];

const filterOptions = createFilterOptions<Offense>({
  matchFrom: 'any',
  limit: 50,
});

const RipaTrafficViolationForm = ({
  TrafficViolation,
  setTrafficViolation,
  setTrafficViolationCode,
  trafficViolation,
  trafficViolationCode,
  errors,
  labels,
  numberOfPeople,
  useSamePrimaryReasonForAll,
  currentSubloop,
  clipboardCodeFeature
}: Props) => {
  const subloopIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;

  const copyAndSetTrafficViolationCode = (value: string, index: number) => {
    if (clipboardCodeFeature) {
      navigator.clipboard.writeText(value);
    }
    setTrafficViolationCode(value, index);
  }

  return (
    <div className="ripa-traffic-violation-form" data-testid="ripa-traffic-violation-form">
      <div className={cn('ripa-traffic-violation-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-traffic-violation-form__person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-traffic-violation-form__person-same-for-all">Same For All</div>
        <div className="ripa-traffic-violation-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-traffic-violation-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-traffic-violation-form__title">What type of traffic violation?</div>
      <div className="ripa-traffic-violation-form__select-box-container">
        <div className="ripa-traffic-violation-form__select-box-content">
          {[TrafficViolation.Moving, TrafficViolation.Equipment, TrafficViolation.NonMoving].map(({ value }, vi) => (
            <div
              className={cn('ripa-traffic-violation-form__select-box', {
                active: trafficViolation[subloopIndex] === value,
              })}
              key={`RipaTrafficViolationFormBox-${vi}}`}
              onClick={() => setTrafficViolation(value, subloopIndex)}
              onKeyUp={onEnter(() => setTrafficViolation(value, subloopIndex))}
              data-testid={`ripa-traffic-violation-form-box-${vi}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
          <Autocomplete<Offense>
            autoHighlight
            autoSelect
            className="ripa-traffic-violation-form__autocomplete"
            classes={{ option: 'code' }}
            data-testid="ripa-traffic-violation-form-autocomplete"
            onInputChange={(_, v) => copyAndSetTrafficViolationCode(v, subloopIndex)}
            blurOnSelect={true}
            getOptionLabel={(o) => o.label}
            defaultValue={offenseCode(dayjs()).find((o) => o.label === trafficViolationCode[subloopIndex])}
            renderOption={(o) => (
              <>
                {o.Repealed !== '' ? (
                  <Tooltip title={`Offense Code Repealed ${o.Repealed}`}>
                    <span data-type={o.TypeOfCharge ?? (o as any).OffenseTypeofCharge} style={{ textDecoration: 'line-through' }}>
                      {o.label}
                      {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                    </span>
                  </Tooltip>
                ) : (
                  <span data-type={o.TypeOfCharge ?? (o as any).OffenseTypeofCharge}>
                    {o.label}
                    {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                  </span>
                )}
              </>
            )}
            options={offenseCode(dayjs())}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={get(errors, 'trafficViolationCode', 0) > 0}
                placeholder="Code section related to violation..."
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'ripa-traffic-violation-form-autocomplete-input',
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default connector(RipaTrafficViolationForm);
