import getAddressFromComponents from './getAddressFromComponents'
import addressToString from './addressToString'
import stateBounds from './stateBounds'
import { isInput } from './typeGuards';

function getStateBounds(state: keyof(typeof stateBounds)) {
  if (google?.maps?.LatLng && google?.maps?.LatLngBounds && state) {
    return new google.maps.LatLngBounds(new google.maps.LatLng(stateBounds[state][0], stateBounds[state][1]), new google.maps.LatLng(stateBounds[state][2], stateBounds[state][3]))
  }
}

export function initAutocomplete(
  id: string,
  state: keyof(typeof stateBounds),
  onPlaceChanged: (street: string, city: string) => void,
  includeStreetNumber = true
) {
  const el = document.getElementById(id);
  if (window.google && isInput(el)) {
    const autocomplete = new google.maps.places.Autocomplete(el, {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
      bounds: getStateBounds(state),
      strictBounds: true,
    })

    autocomplete.setFields(['address_components'])
    autocomplete.addListener('place_changed', () => {
      const { address_components } = autocomplete.getPlace()
      if (address_components) {
        const addressObject = getAddressFromComponents(address_components)
        const addressString = addressToString.oneLine(addressObject, includeStreetNumber)
        onPlaceChanged(addressString, addressObject.city)
      }
    })

    return autocomplete
  }
}
