import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { get } from 'lodash';
import { Person } from '@ducks/types';

const mapStateToProps = (state: any) => ({
  student: Form.selectors.student(state),
  searchBasis: Form.selectors.searchBasis(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  basis: get(Template.selectors.enumMap(state), 'basisForSearch.possibleValues', {}),
  person: Form.selectors.person(state) as Person[],
  SearchPropertyConducted: get(Template.selectors.enumMap(state), 'actionsTakenDuringStop.possibleValues.SearchPropertyConducted.value', ''),
  consentGiven: Form.selectors.consentGiven(state),
  consentTypes: Template.selectors.enumMap(state),
  consentType: Form.selectors.consentType(state),
  flags: Form.selectors.flags(state),
});

const mapDispatchToProps = {
  toggleSearchBasis: (value: string, personIndex: number) => Form.toggleSearchBasis({ value, personIndex }),
  setConsentType: (personIndex: number, value: any) => Form.setFormField({ path: ['person', personIndex, 'consentType'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
