import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { get } from 'lodash';
import { Person } from '@ducks/types';
import { Config } from '@engine/ducks';

const mapStateToProps = (state: any) => ({
  student: Form.selectors.student(state),
  reasonGivenStoppedPerson: Form.selectors.reasonGivenStoppedPerson(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  reasonGivenStoppedPersonValues: get(Template.selectors.enumMap(state), 'reasonGivenStoppedPerson.possibleValues', {}),
  person: Form.selectors.person(state) as Person[],
  flags: Form.selectors.flags(state),
  breakpoint: Config.selectors.breakpoint(state),
});

const mapDispatchToProps = {
  toggleReasonGivenStoppedPerson: (value: string, personIndex: number) => Form.toggleReasonGivenStoppedPerson({ value, personIndex }),
  setReasonGivenStoppedPerson: (value: string[], personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'reasonGivenStoppedPerson'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
