import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { TextField, InputAdornment, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { onEnter } from '@utility/keypressHelpers';
import { sortText, sortTime } from '@utility/sort';
import { Button, Table, Column, ConfirmDialog } from '@components/common';
import { Header, ReviewRow, ReviewTableSelectColumn, ReviewDialog, ReviewFeedbackDialog } from '@components/custom';
import * as User from '@ducks/user';
import { GlobalConfiguration as GlobalConfigDuck } from '@ducks';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import * as ReviewDuck from '@ducks/review';
import './Review.scss';

const debouncedBulksubmitForm = debounce(() => decoupledDispatch('Review.bulkSubmitToDoj'), 8000, { trailing: false, leading: true });

export const Review = ({
  organization,
  getOrganization,
  statistics,
  getAllOfficers,
  reportData,
  getUserForms,
  pagination,
  setPagination,
  setOfficerAndSearch,
  officerSelect,
  hasCheckedRows,
  searchAndFilter,
  reviewDialogOpen,
  toggleFilterAndSearch,
  setSort,
  setSearch,
  loading,
  officerDropdown,
  getStats,
  dojErrorsDialogOpen,
  setDojErrorsDialogOpen,
  dojErrorsDialogContent,
  dojErrorsDialogFormId,
  setReviewFeedbackDialogOpen,
  orgCanSubmitToDOJ,
  getOrgCustomQuestions,
  removedFormsSelected,
}: Props) => {
  const { underReviewStatus, rejectedStatus, approvedStatus, deniedByDojStatus, submittedToDojStatus } = statistics;
  const { filterSelected, sort } = searchAndFilter;

  const nextScheduleTime = {
    date: organization.job_submission_schedule.start_date,
    frequency: organization.job_submission_schedule.frequency,
    mode: organization.job_submission_schedule.mode,
  };

  useEffect(() => {
    getOrganization(undefined);
  }, []);

  useEffect(() => {
    if (organization.id) {
      getOrgCustomQuestions(organization.id);
    }
  }, [organization]);

  useEffect(() => {
    getUserForms({ pagination, searchAndFilter, officerSelect });
    getAllOfficers();
    getStats();
  }, [reviewDialogOpen, removedFormsSelected]);

  const initialComboBox: JSONObject = { value: 'all', label: 'All Officers' }

  const [comboBox, setComboBox] = useState(initialComboBox)

  const handleBlur = () => {
    if (!comboBox) {
      setComboBox(initialComboBox);
      setOfficerAndSearch('all');
    }
  };

  const handleChange = (_: any, newValue: any) => {
    if (newValue) {
      setComboBox(newValue);
      setOfficerAndSearch(newValue?.value);
    }
  };

  return (
    <Header title="Contact">
      <div className="review" data-testid="review">
        <div className="review__filters">
          <div
            className={cn('review__filters-under-review', { active: filterSelected.includes(0) })}
            aria-label="Filter ripa report for ripa reports that are under review"
            data-testid="review-filters-under-review"
            onClick={() => toggleFilterAndSearch(0)}
            onKeyUp={onEnter(() => toggleFilterAndSearch(0))}
            role="button"
            tabIndex={0}
          >
            <div className="review__filters-under-review-value">{underReviewStatus}</div>
            <div className="review__filters-under-review-label">UNDER REVIEW</div>
          </div>
          <div
            className={cn('review__filters-review-rejected', { active: filterSelected.includes(1) })}
            data-testid="review-filters-review-rejected"
            aria-label="Filter ripa report for rejected ripa reports"
            onClick={() => toggleFilterAndSearch(1)}
            onKeyUp={onEnter(() => toggleFilterAndSearch(1))}
            role="button"
            tabIndex={0}
          >
            <div className="review__filters-review-rejected-value">{rejectedStatus}</div>
            <div className="review__filters-review-rejected-label">REVIEW REJECTED</div>
          </div>
          <div
            className={cn('review__filters-review-approved', { active: filterSelected.includes(2) })}
            data-testid="review-filters-approved"
            aria-label="Filter ripa reports for approved reports"
            onClick={() => toggleFilterAndSearch(2)}
            onKeyUp={onEnter(() => toggleFilterAndSearch(2))}
            role="button"
            tabIndex={0}
          >
            <div className="review__filters-review-approved-value">{approvedStatus}</div>
            <div className="review__filters-review-approved-label">REVIEW APPROVED</div>
          </div>
          <div
            className={cn('review__filters-denied-reports', { active: filterSelected.includes(3) })}
            data-testid="review-filters-denied-reports"
            aria-label="Filter ripa report for denied reports"
            onClick={() => toggleFilterAndSearch(3)}
            onKeyUp={onEnter(() => toggleFilterAndSearch(3))}
            role="button"
            tabIndex={0}
          >
            <div className="review__filters-denied-reports-value">{deniedByDojStatus}</div>
            <div className="review__filters-denied-reports-label">DENIED BY DOJ</div>
          </div>
          <div
            className={cn('review__filters-submitted-doj-reports', { active: filterSelected.includes(4) })}
            data-testid="review-filters-submitted-doj-reports"
            aria-label="Filter ripa report for submitted to doj reports"
            onClick={() => toggleFilterAndSearch(4)}
            onKeyUp={onEnter(() => toggleFilterAndSearch(4))}
            role="button"
            tabIndex={0}
          >
            <div className="review__filters-submitted-doj-reports-value">{submittedToDojStatus}</div>
            <div className="review__filters-submitted-doj-reports-label">SUBMITTED TO DOJ</div>
          </div>
        </div>
        <div className="review__search-container">
          <div className="review__search-officer-select">
            <TextField
              className="review__search"
              variant="outlined"
              placeholder="Search..."
              onChange={({ target: { value } }) => setSearch(value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div className="material-icons">search</div>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                'data-testid': 'review-search',
              }}
            />
            <Autocomplete
              className="review__select"
              options={[
                { value: 'all', label: 'All Officers' },
                { value: 'removed', label: 'Removed Reports' },
                ...officerDropdown.map((r) => ({ value: r.id, label: r.name })),
              ]}
              blurOnSelect
              value={comboBox}
              onChange={handleChange}
              onBlur={handleBlur}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Officer"
                  data-testid="officerSelect"
                />
              )}
            />

          </div>
          {orgCanSubmitToDOJ && nextScheduleTime && (
            <div className="review__selected-actions">
              <div className="doj">
                <div className="doj__next-submission">NEXT SUBMISSION</div>
                <div className="doj__date-time" data-testid="date-time-schedule">
                  {nextScheduleTime.mode === 'disabled' ? 'No Schedule' : `${dayjs(nextScheduleTime.date).format('MM/DD/YYYY')} at ${dayjs(nextScheduleTime.date).format('hh:mm A')}`}
                </div>
              </div>
              <Button
                className={cn('review__submit-to-doj', { selected: hasCheckedRows })}
                aria-label="Submit selected reports"
                data-testid="review-approve-selected"
                priority="primary"
                onClick={debouncedBulksubmitForm}
                disabled={!hasCheckedRows}
              >
                {hasCheckedRows ? 'SUBMIT SELECTED' : 'SUBMIT TO DOJ'}
              </Button>
            </div>
          )}
        </div>
        <div className="review__table">
          <Table
            RowComponent={ReviewRow}
            rowData={reportData}
            dataQuery={({ page, limit }: { page: number; limit: number }) =>
              getUserForms({
                searchAndFilter: { ...searchAndFilter, sort },
                pagination: { currentPage: page, pageSize: limit },
                officerSelect
              })
            }
            loading={loading.getUserForms}
            pagination={pagination}
            setSort={setSort}
            setPagination={setPagination}
            emptyMessage="There are no forms"
          >
            <Column title="" dataKey="select" grow={1} minWidth={90} Component={ReviewTableSelectColumn} />
            <Column title="Officer" dataKey="officer" grow={3} minWidth={100} />
            <Column title="Location" dataKey="location" grow={5} minWidth={100} sort={sortText('location')} />
            <Column title="Assignment" dataKey="assignment" grow={3} minWidth={100} />
            {/* <Column title="District" dataKey="district" grow={3} minWidth={90} />
            <Column title="Beat" dataKey="beat" grow={1} /> */}
            <Column title="Time" dataKey="time" grow={2} minWidth={75} sort={sortTime('time')} />
            <Column title="Status" dataKey="status" grow={2} minWidth={100} sort={sortText('status')} />
            <Column title="Actions" dataKey="actions" grow={3} minWidth={170} />
          </Table>
        </div>
      </div>
      {reviewDialogOpen && <ReviewDialog />}
      <ReviewFeedbackDialog />
      <ConfirmDialog
        className="dashboard__doj-errors-dialog"
        heading="FORM ERRORS"
        open={dojErrorsDialogOpen}
        closeDialog={() => setDojErrorsDialogOpen({ open: false })}
        positiveAction={() => {
          setDojErrorsDialogOpen({ open: false });
          setReviewFeedbackDialogOpen({ open: true, formId: dojErrorsDialogFormId });
        }}
        positiveText="WRITE NOTE TO OFFICER"
      >
        <div className="dashboard__doj-errors-dialog-heading">The following are issues that need to be fixed</div>
        <div className="dashboard__doj-errors-dialog-content"><pre>{JSON.stringify(dojErrorsDialogContent).replace(/\\n/g, '\n').slice(1, -1).trim() || 'DOJ returned no explanation'}</pre></div>
      </ConfirmDialog>
    </Header>
  );
};

interface JSONObject {
  value: string;
  label: string;
}

interface Props {
  organization: any;
  dojErrorsDialogContent: string;
  dojErrorsDialogOpen: boolean;
  reportData: Array<any>;
  pagination: any;
  officerSelect: string;
  hasCheckedRows: boolean;
  searchAndFilter: any;
  loading: any;
  officerDropdown: Array<any>;
  statistics: any;
  dojErrorsDialogFormId: string;
  reviewDialogOpen: boolean;
  orgCanSubmitToDOJ: boolean;
  removedFormsSelected: boolean;
  getOrganization: any;
  setDojErrorsDialogOpen: any;
  getUserForms: any;
  setPagination: any;
  setOfficerAndSearch: any;
  toggleFilterAndSearch: any;
  setSort: any;
  setSearch: any;
  getAllOfficers: any;
  getStats: any;
  setReviewFeedbackDialogOpen: any;
  getOrgCustomQuestions: any;
}

const mapDispatchToProps = {
  getOrganization: GlobalConfigDuck.getOrganization,
  getUserForms: ReviewDuck.getUserForms,
  setPagination: ReviewDuck.setPagination,
  setOfficerAndSearch: ReviewDuck.setOfficerAndSearch,
  toggleFilterAndSearch: ReviewDuck.toggleFilterAndSearch,
  setSort: ReviewDuck.setSortAndSearch,
  setSearch: ReviewDuck.setSearch,
  getStats: ReviewDuck.getStats,
  getAllOfficers: ReviewDuck.getAllOfficers,
  setDojErrorsDialogOpen: ReviewDuck.setDojErrorsDialogOpen,
  setReviewFeedbackDialogOpen: ReviewDuck.setReviewFeedbackDialogOpen,
  getOrgCustomQuestions: ReviewDuck.getOrgCustomQuestions,
};

const mapStateToProps = (state: any) => ({
  organization: GlobalConfigDuck.selectors.organization(state),
  statistics: ReviewDuck.selectors.statistics(state),
  loading: ReviewDuck.selectors.loading(state),
  searchAndFilter: ReviewDuck.selectors.searchAndFilter(state),
  underReviewReports: ReviewDuck.selectors.underReviewReports(state),
  reportsToday: ReviewDuck.selectors.reportsToday(state),
  rejectedReports: ReviewDuck.selectors.rejectedReports(state),
  submittedReports: ReviewDuck.selectors.submittedReports(state),
  reportData: ReviewDuck.selectors.reportData(state),
  pagination: ReviewDuck.selectors.pagination(state),
  officerSelect: ReviewDuck.selectors.officerSelect(state),
  hasCheckedRows: ReviewDuck.selectors.hasCheckedRows(state),
  officerDropdown: ReviewDuck.selectors.officerDropdown(state),
  dojErrorsDialogContent: ReviewDuck.selectors.dojErrorsDialogContent(state),
  dojErrorsDialogOpen: ReviewDuck.selectors.dojErrorsDialogOpen(state),
  dojErrorsDialogFormId: ReviewDuck.selectors.dojErrorsDialogFormId(state),
  reviewDialogOpen: ReviewDuck.selectors.reviewDialogOpen(state),
  orgCanSubmitToDOJ: User.selectors.canSubmitToDOJ(state),
  removedFormsSelected: ReviewDuck.selectors.removedFormsSelected(state),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Review);
