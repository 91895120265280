import { connect, ConnectedProps } from 'react-redux';
import { Dashboard } from '@ducks';

const mapDispatchToProps = {
  createTestForm: Dashboard.createTestForm
};

export const connector = connect(null, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  createTestForm: () => void;
};
