import React from 'react';
import detectWidth from '@utility/window-width';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from '@components/custom';
import * as Sentry from '@sentry/react';
import { Props, connector } from './props';
import './Root.scss';

class Root extends React.Component<Props> {
  state = {
    hasError: false,
    error: undefined,
    info: undefined
  }

  componentDidMount() {
    window.addEventListener('resize', this.updatePageWidth)
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    this.updatePageWidth()
    this.updateOnlineStatus()
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error, info })
    Sentry.captureException(error, { tags: { info, ...JSON.parse(localStorage.getItem('sentry-user') || '{}') } })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageWidth)
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }

  updatePageWidth = () => {
    const { pageWidth, updatePageWidth } = this.props
    const newWidth = detectWidth()

    if (pageWidth !== newWidth) {
      updatePageWidth(newWidth)
    }

    const { activeElement } = document
    if (activeElement && ['INPUT', 'TEXTAREA'].includes(activeElement.tagName)) {
      activeElement.scrollIntoView({
        block: 'nearest'
      })
    }
  }

  updateOnlineStatus = () => {
    const { checkOnlineStatus } = this.props
    checkOnlineStatus()
  }

  render() {
    const { breakpoint } = this.props
    const { hasError, error, info } = this.state

    if (hasError) {
      return <div className="error-page">
        <h1> Whoops, that shouldn&apos;t have happened! </h1>
        <hr />
        <h2>Error</h2>
        <p>
          {JSON.stringify(error)}
        </p>
        <h2>Info</h2>
        <p>
          {JSON.stringify(info)}
        </p>
        <h2>Error</h2>
        <p>
          {JSON.stringify(console.errors)}
        </p>
        <h2>Log</h2>
        <p>
          {JSON.stringify(console.logs.reverse())}
        </p>
      </div>
    }

    return (
      <div className={breakpoint} id="root-content" data-testid="root">
        {/* Any components global to all pages should go here */}
        <Router basename="/">
          <Routes />
        </Router>
      </div>
    )
  }
}

export default connector(Root)
