import React, { useState } from 'react';
import { path } from 'ramda';
import { LabelDropdown } from '@components/common';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Props, connector } from './props';
import './DynamicDropdownForm.scss';

const DynamicDropdownForm = ({
  resultPath,
  isAll,
  title,
  options,
  form,
  currentPerson,
  placeholder,
  autocomplete,
  onChange,
  setValidationErrors,
  style,
  labels,
  numberOfPeople,
  dropdownLabel,
  hasHelper,
}: Props) => {
  let selected;
  const [open, setOpen] = useState<boolean>(false);

  if (isAll) {
    selected = path(['custom', 'person', currentPerson, resultPath], form) ?? [];
  } else {
    selected = path(['custom', resultPath], form) ?? [];
  }

  const setValueAndClearValidation = (value: string) => {
    if (isAll) {
      onChange(value, ['custom', 'person', currentPerson, resultPath]);
    } else {
      onChange(value, ['custom', resultPath]);
    }

    setValidationErrors?.({});
  }

  return (
    <div
      className="dynamic-dropdown-form"
      data-testid="dynamic-dropdown-form"
      style={{ ...style, paddingBottom: hasHelper ? '30px' : undefined }}
      key={`${resultPath}x${currentPerson}`}
    >
      {isAll && <div className="dynamic-dropdown-form__person" data-testid="dynamic-dropdown-form-person">
        <div className="material-icons">
          person
        </div>
        <div className="dynamic-dropdown-form__person-label">
          {labels?.[currentPerson] || currentPerson + 1}
        </div>
        <div className="dynamic-dropdown-form__person-same-for-all">
          Same For All
        </div>
        <div className="dynamic-dropdown-form__person-progression">
          <b>{`${currentPerson + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="dynamic-dropdown-form__person-progression-all">
          ALL
        </div>
      </div>}
      <div className="dynamic-dropdown-form__title">
        {title}
      </div>
      {autocomplete ?
        <Autocomplete
          autoHighlight
          autoSelect
          className="dynamic-dropdown-form__autocomplete"
          data-testid="dynamic-dropdown-form-autocomplete"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInputChange={(_, v) => setValueAndClearValidation(v)}
          defaultValue={selected}
          options={options}
          renderInput={params => <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              'data-testid': 'dynamic-dropdown-form-autocomplete-input'
            }}
          />}
        /> :
        <LabelDropdown
          className="dynamic-dropdown-form__dropdown"
          data-testid="dynamic-dropdown-form-dropdown"
          label={dropdownLabel}
          value={selected}
          values={options.map(o => ({ value: o, text: o }))}
          onChange={({ target: { value } }) => setValueAndClearValidation(value as string)}
          placeholder={placeholder}
        />}

    </div>
  );
}

const Helper = ({ HelperTextInfo }: {HelperTextInfo: string}) => {
  if (HelperTextInfo) {
    return (
      <div className="ripa-form-container__helper-box">
        <div className="material-icons">
          help
        </div>
        <div className="ripa-form-container__helper-box-text">
          {HelperTextInfo}
        </div>
      </div>
    )
  }
  return null;
};

DynamicDropdownForm.helper = Helper;

export default connector(DynamicDropdownForm);
