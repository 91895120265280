import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { ConfirmDialog } from '@components/common';
import { Status } from '@ducks/constants';
import { throttle } from 'lodash';
import dayjs from 'dayjs';
import { Props, connector } from './props';
import './ReviewFeedbackDialog.scss';

export const ReviewFeedbackDialog = ({ setReviewFeedbackDialogOpen, reviewFeedbackDialog, setReviewFeedback, submitReviewFeedback, userId, userForms, updateForm }: Props) => {
  const { open, reviewFeedback, formId, isReadOnly, reviewer_notes } = reviewFeedbackDialog
  const { reviewerTimeSpent } = userForms.find((f: any) => Number(f.id) === Number(formId))?.contents ?? {}
  const { status } = userForms.find((f: any) => Number(f.id) === Number(formId)) ?? {}

  let reviewerTime = reviewerTimeSpent || 0
  let updates: { reviewerTimeSpent: any; reviewerId: number | undefined } = { reviewerTimeSpent: reviewerTime, reviewerId: undefined }
  // Tracks time since last activity
  let secondsSinceLastActivity = 0
  // Max inactivity before no credit for time received
  const maxInactivity = 20
  // All the event triggers that define activity
  const userActivityEvents = ['keydown', 'mousedown', 'mousemove', 'scroll', 'touchstart']
  // Adds to total activity
  const [activityFn, setActivityFn] = useState<any>()
  // Set event listeners and interval and remove upon dismount
  const [trackActivityInterval, setTrackActivityInterval] = useState<any>(null)

  const startTrackingActivity = () => {
    const actFns = userActivityEvents.reduce(
      (acc: { [evnt: string]: () => void }, ev: string) => ({
        ...acc,
        [ev]: throttle(
          () => {
            reviewerTime += secondsSinceLastActivity
            updates = { reviewerTimeSpent: reviewerTime, reviewerId: userId }
            updateForm({ id: formId, updates })
            secondsSinceLastActivity = 0
          },
          2000,
          { trailing: false, leading: true }
        ),
      }),
      {}
    )
    setActivityFn(actFns)
    setTrackActivityInterval(
      setInterval(() => {
        if (secondsSinceLastActivity < maxInactivity) {
          secondsSinceLastActivity += 1
        }
      }, 1000)
    )
    userActivityEvents.forEach((event) => {
      window.addEventListener(event, actFns[event])
    })
  }

  const stopTrackingActivity = () => {
    clearInterval(trackActivityInterval)
    userActivityEvents.forEach((event) => {
      activityFn?.[event].cancel()
      window.removeEventListener(event, activityFn?.[event])
    })
  }

  useEffect(() => {
    if (open && [Status.UnderReview, Status.DeniedByDOJ].includes(status)) {
      startTrackingActivity()
    } else {
      stopTrackingActivity()
    }
  }, [open])

  return (
    <ConfirmDialog
      heading={isReadOnly ? 'REVIEWER FEEDBACK' : 'DESCRIBE ERRORS TO BE FIXED'}
      data-testid="review-feedback-dialog"
      open={open}
      closeDialog={() => setReviewFeedbackDialogOpen({ open: false, formId: undefined, isReadOnly: undefined })}
      negativeAction={() => setReviewFeedbackDialogOpen({ open: false, formId: undefined, isReadOnly: undefined })}
      positiveAction={() => {
        // // Might be using this for request queue implementation
        // updateForm({ id: formId, updates });
        submitReviewFeedback()
      }}
      noActions={isReadOnly}
      positiveText="Submit"
      negativeText="Cancel"
    >
      <div className="review-feedback-dialog">
        <div className="review-feedback-dialog__desc">{isReadOnly ? 'REVIEW COMMENT HISTORY' : 'ENTER A DESCRIPTION OF ERRORS THAT NEED TO BE FIXED'}</div>
        <div className="review-feedback-dialog__field-container">
          {isReadOnly ? (
            <div className="review-feedback-dialog__history">
              {reviewer_notes?.noteForReviewee && (
                <div className="review-feedback-dialog__history-content" data-testid="review-feedback-dialog-note-content-legacy">
                  {/* noteForReviewee is for legacy comment support */}
                  {reviewer_notes?.noteForReviewee?.trim() || 'No feedback given from reviewer'}
                </div>
              )}
              {reviewer_notes?.noteHistory?.map((note: any, i: number) => (
                <div className="review-feedback-dialog__history-content" key={`ReviewFeedbackDialogHistory-${i}`} data-testid="review-feedback-dialog-note-content-history">
                  <div className="review-feedback-dialog__history-content-date">{dayjs(note.date).format('MM/DD/YYYY h:mm A')}</div>
                  {note.reviewFeedback?.trim() || 'No feedback given from reviewer'}
                  <div className="review-feedback-dialog__history-content-reviewer">{`Reviewed by: ${note.reviewer.last_name}, ${note.reviewer.first_name}`}</div>
                </div>
              ))}
            </div>
          ) : (
            <TextField
              className="review-feedback-dialog__field"
              placeholder="Enter brief, 500 character maximum, explanation..."
              multiline
              rows={5}
              onChange={({ target: { value } }) => (value.length <= 500 ? setReviewFeedback(value) : null)}
              value={reviewFeedback}
              variant="outlined"
              inputProps={{
                'data-testid': 'review-feedback-dialog-input',
              }}
            />
          )}
          {!isReadOnly && (
            <div className="review-feedback-dialog__field-remaining">
              <b>{`${500 - reviewFeedback.length} `}</b>
              Remaining
            </div>
          )}
        </div>
      </div>
    </ConfirmDialog>
  )
}
export default connector(ReviewFeedbackDialog)
