import React from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './ReviewTableSelectColumn.scss';

export const ReviewTableSelectColumn = ({
                                          selectAllChecked,
                                          selectAllReviewRows,
                                          orgCanSubmitToDOJ,
                                        }: Props) => {
  const selectAll = () => {
    selectAllReviewRows();
    window.dispatchEvent(new CustomEvent('select-all-review-rows', { detail: { selectAllChecked: !selectAllChecked } }));
  };

  return (
    <div className="review-table-select-column">
      <div
        role="button"
        tabIndex={0}
        className={cn('review-table-select-column__cell-select-checkbox', { checked: selectAllChecked, disabled: !orgCanSubmitToDOJ })}
        data-testid="review-table-select-column-cell-select-checkbox"
        onClick={selectAll}
        onKeyUp={onEnter(selectAll)}
      >
        <div className="material-icons">
          done
        </div>
      </div>
    </div>
  );
};

export default connector(ReviewTableSelectColumn);
