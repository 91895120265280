import React, { useEffect } from 'react';
import cn from 'classnames';
import { all, none, range } from 'ramda';
import { isEmpty } from 'lodash';
import { onEnter } from '@utility/keypressHelpers';
import { Switch } from '@components/common';
import { Props, connector } from './props';
import './RipaPrimaryReasonForm.scss';

const RipaPrimaryReasonForm = ({
  setPrimaryReason,
  primaryReason,
  student,
  ReasonsForStop,
  labels,
  numberOfPeople,
  useSamePrimaryReasonForAll,
  currentSubloop,
  setUseSamePrimaryReasonForAll,
  setForceLastSubloop,
  enumMap,
  typeOfStop,
  flags: { post2024 },
  setCodeSection48900,
  setCodeSection48900Subdivision,
}: Props) => {
  const subloopIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;

  useEffect(() => {
    if (useSamePrimaryReasonForAll &&
      (typeOfStop !== enumMap?.TypeOfStop?.possibleValues?.Pedestrian.value
        && typeOfStop !== enumMap?.TypeOfStop?.possibleValues?.Vehicular.value)) {
      setForceLastSubloop(currentSubloop);
    } else {
      setForceLastSubloop(-1);
    }
  }, [useSamePrimaryReasonForAll]);

  useEffect(() => {
    if (!isEmpty(primaryReason)) {
      range(0, numberOfPeople).forEach(pi => {
        if (student[pi] === false) {
          if (primaryReason?.[pi]?.includes(ReasonsForStop.PossibleDisciplineEducationCode48900.value) ||
              primaryReason?.[pi]?.includes(ReasonsForStop.DetermineStudentViolatedSchoolPolicy.value)) {
                setPrimaryReason('', pi);
                setCodeSection48900('', pi);
                setCodeSection48900Subdivision('', pi);
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (useSamePrimaryReasonForAll) {
      const hasFalseStudent = range(0, numberOfPeople).some(pi => student[pi] === false);
      if (hasFalseStudent) {
        setUseSamePrimaryReasonForAll(false);
      }
    }
  }, []);

  return (
    <div className="ripa-primary-reason-form" data-testid="ripa-primary-reason-form">
      <div className={cn('ripa-primary-reason-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-primary-reason-form__person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-primary-reason-form__person-same-for-all">Same For All</div>
        <div className="ripa-primary-reason-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-primary-reason-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-primary-reason-form__title">What was the primary reason for stop?</div>
      <div className="ripa-primary-reason-form__use-same">
        <div className="ripa-primary-reason-form__use-same-line" />
        <div className="ripa-primary-reason-form__use-same-text">USE SAME FOR ALL</div>
        <Switch
          className="ripa-primary-reason-form__use-same-switch"
          data-testid="ripa-primary-reason-form-use-same-switch"
          onText="YES"
          offText="NO"
          disableChangeOnMount={true}
          disabled={numberOfPeople === 1 || currentSubloop > 0 || !(all((s) => s, student) || none((s) => s, student))}
          value={useSamePrimaryReasonForAll}
          onChange={setUseSamePrimaryReasonForAll}
        />
        <div className="ripa-primary-reason-form__use-same-line" />
      </div>
      <div className="ripa-primary-reason-form__select-box-container">
        <div className="ripa-primary-reason-form__select-box-content">
          {student[currentSubloop] &&
            [ReasonsForStop.PossibleDisciplineEducationCode48900, ReasonsForStop.DetermineStudentViolatedSchoolPolicy].map(({ value }, vi) => (
              <div
                className={cn('ripa-primary-reason-form__select-box k12', { active: primaryReason[subloopIndex] === value })}
                key={`RipaPrimaryReasonK12Box-${vi}`}
                onClick={() => setPrimaryReason(value, subloopIndex)}
                onKeyUp={onEnter(() => setPrimaryReason(value, subloopIndex))}
                data-testid={`ripa-primary-reason-form-box-k12-${vi}`}
                role="button"
                tabIndex={0}
              >
                {value}
                <div className="ripa-primary-reason-form__select-box-k12">K-12</div>
              </div>
            ))}
          {[
            ReasonsForStop.TrafficViolation,
            ReasonsForStop.ReasonableSuspicion,
            ReasonsForStop.KnownParole,
            ReasonsForStop.OutstandingArrest,
            ReasonsForStop.InvestigationTruant,
            ReasonsForStop.ConsensualEncounterSearch,
            ReasonsForStop.ProbableCauseToArrestOrSearch,
            ReasonsForStop.ProbableCauseCustodyUnder5150,
          ]
          .filter(reasonsForStop => post2024 ? true : (reasonsForStop?.key && ![9, 10].includes(reasonsForStop.key)))
          .map(({ value }, vi) => (
            <div
              className={cn('ripa-primary-reason-form__select-box', { active: primaryReason[subloopIndex] === value })}
              key={`RipaPrimaryReasonBox-${vi}}`}
              onClick={() => setPrimaryReason(value, subloopIndex)}
              onKeyUp={onEnter(() => setPrimaryReason(value, subloopIndex))}
              data-testid={`ripa-primary-reason-form-box-${vi}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select the primary reason for stop ONLY.</div>
  </div>
);

RipaPrimaryReasonForm.helper = Helper;

export default connector(RipaPrimaryReasonForm);
