import colors from '@theme/color';

export const setDarkMode = (isDarkMode: boolean) => {
  // Map colors
  if (isDarkMode) {
    document.querySelector('body')?.classList.add('dark-mode');
  } else {
    document.querySelector('body')?.classList.remove('dark-mode');
  }
  Object.entries(colors).forEach(e => {
    const [variable, { light, dark }] = e;
    document.querySelector<HTMLElement>(':root')?.style.setProperty(`--${variable}`, isDarkMode ? dark === 'same' ? light : dark : light);
  })
}

export const detectThemeChange = () => {
  if (window.matchMedia('(prefers-color-scheme: dark)').addEventListener) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setDarkMode(e.matches));
  } else if (window.matchMedia('(prefers-color-scheme: dark)').addListener) {
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => setDarkMode(e.matches));
  }
}
