import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as Form from '@ducks/form';
import { AlertLevel, createSnackNotification } from '@components/common'
import noElasticScroll from 'inobounce'
import './RipaTimeForm.scss'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { checkAfter2024, TemplateName2024 } from '@engine/ducks/constants';
import { Template } from '@engine/ducks';

dayjs.extend(customParseFormat)

interface Props extends PropsFromRedux {
  checkValidation: any;
  setDisableContinue: (disableContinue: boolean) => void;
}

export const RipaTimeForm = ({ stopTime, setDisableContinue, setStopDateAndRun2024Conditions, templateName, errors, checkValidation }: Props) => {

  useEffect(() => {
    if (checkAfter2024(stopTime) && templateName !== TemplateName2024) {
      createSnackNotification(AlertLevel.Warning, 'Cannot load template', 'Organization is not provisioned for 2024 forms. Please Contact Veritone.');
    }
  }, [stopTime, templateName])

  useEffect(() => {
    // Disable 'no elastic scroll' on Safari iOS
    noElasticScroll.enable();
    return () => {
      // Disable 'no elastic scroll' on Safari iOS
      noElasticScroll.disable();
    }
  }, [])

  useEffect(() => {
    try {
      setDisableContinue(true);
      setStopDateAndRun2024Conditions({ stopDateValue: dayjs(stopTime).toISOString(), setDisableContinue });
    } catch (e) { /* empty */ }
  }, [])

  return (
    <div className="ripa-time-form" data-testid="ripa-time-form">
      <div className="ripa-time-form__title">
        What time did the stop happen?
      </div>
      <div className="ripa-time-form__time-picker-group">
        <KeyboardTimePicker
          className="ripa-time-form__time-picker"
          variant="inline"
          mask="__:__"
          margin="normal"
          id="time-picker-inline"
          keyboardIcon={<div className="material-icons">alarm</div>}
          inputVariant="outlined"
          disableFuture
          inputProps={{
            inputMode: 'numeric',
            'data-testid': 'ripa-time-form-input'
          }}
          onFocus={(e) => e.target.select()}
          error={errors?.stopDateTime ? errors.stopDateTime > 0 : false}
          ampm={false}
          value={dayjs(stopTime)}
          onChange={(v) => {
            try {
              const initDate = dayjs(stopTime)
              const timeOnDate =
                dayjs(v)
                  .year(initDate.year())
                  .month(initDate.month())
                  .date(initDate.date());
                  setDisableContinue(true);
                  setStopDateAndRun2024Conditions({ stopDateValue: timeOnDate.toISOString(), setDisableContinue });
                  checkValidation(null, false, true);
            } catch (e: any) {
              if (e.message.includes('Invalid time')) {
                createSnackNotification(AlertLevel.Warning, 'Invalid time', 'Enter a valid time')
              }
             }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
            // @ts-ignore
            'data-testid': 'ripa-time-form-open-picker',
          }}
          PopoverProps={{
            className: 'ripa-time-form__time-picker-popover',
            // @ts-ignore
            'data-testid': 'ripa-time-form-picker-popover',
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  stopTime: Form.selectors.stopDateTime(state),
  templateName: Template.selectors.name(state),
  errors: Form.selectors.stepErrors(state),
})

const mapDispatchToProps = {
  setStopDateAndRun2024Conditions: Form.setStopDateAndRun2024Conditions,
  setResponseToCall: Form.setFormField({ path: ['responseToCall'] }),
  checkValidation: Form.checkValidation
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaTimeForm)
