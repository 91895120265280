import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import cn from 'classnames';
import { path } from 'ramda'
import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import { setValidationErrors } from '@ducks/form';
import { AlertLevel, createSnackNotification } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import './DynamicNumericForm.scss';

interface Props extends PropsFromRedux {
  title: string;
  resultPath: any;
  max: number;
  min: number;
  counterToggle: boolean;
  goToNext: any;
  style?: React.CSSProperties;
  isAll: boolean;
  defaultValue: string;
}

const DynamicNumericForm = ({ title, resultPath, max, min, counterToggle, form, onChange,
  goToNext, style, currentPerson, labels, numberOfPeople, isAll, defaultValue
}: Props) => {
  const [number, setNumber] = useState<any>();
  const [reset, setReset] = useState<boolean>();

  useEffect(() => {
    let n;
    if (isAll) {
      n = path(['custom', 'person', currentPerson, resultPath], form);
    } else {
      n = path(['custom', resultPath], form);
    }

    const defaultValueParsed = parseInt(defaultValue, 10);

    if (n === null || n === undefined) {
      n = !isNaN(defaultValueParsed) ? defaultValueParsed : counterToggle ? min : undefined;
    }

    setValueAndClearValidation(n);
    setReset(true)
  }, [resultPath, isAll, currentPerson, defaultValue, min, counterToggle]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const setValueAndClearValidation = (value: any) => {
    value = isNaN(value) ? undefined : value;
    if (value >= min && value <= max || value === undefined) {
      if (isAll) {
        setNumber(value);
        onChange(value, ['custom', 'person', currentPerson, resultPath]);
      } else {
        setNumber(value);
        onChange(value, ['custom', resultPath]);
      }
      setValidationErrors?.({});
      goToNext?.();
    } else {
      createSnackNotification(AlertLevel.Warning, 'Invalid input', `The input must be a number between ${min} and ${max}`);
    }
  }

  if (reset) {
    return <div />
  }

  return (
    <div
      className="dynamic-numeric-form"
      data-testid="dynamic-numeric-form"
      style={style}
      key={`${resultPath}x${currentPerson}`}
    >
      {isAll && <div className="dynamic-numeric-form__person">
        <div className="material-icons">
          person
        </div>
        <div className="dynamic-numeric-form__person-label">
          {labels?.[currentPerson] || currentPerson + 1}
        </div>
        <div className="dynamic-numeric-form__person-same-for-all">
          Same For All
        </div>
        <div className="dynamic-numeric-form__person-progression">
          <b>{`${currentPerson + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="dynamic-numeric-form__person-progression-all">
          ALL
        </div>
      </div>}
      <div className="dynamic-numeric-form__title">
        {title}
      </div>
      {counterToggle ?
        <div className="dynamic-numeric-form__counter-container">
          <div
            className="dynamic-numeric-form__counter"
            data-testid="dynamic-numeric-form-counter"
          >
            <div
              className={cn('dynamic-numeric-form__counter-minus material-icons', { enabled: number > min })}
              data-testid="dynamic-numeric-form-counter-minus"
              onClick={() => number > min && setValueAndClearValidation(number - 1)}
              onKeyUp={() => number > min && onEnter(() => setValueAndClearValidation(number - 1))}
              aria-label="Decrease the number by one"
              tabIndex={0}
              role="button"
            >
              horizontal_rule
          </div>
            <div
              className="dynamic-numeric-form__counter-number"
              data-testid="dynamic-numeric-form-counter-number"
            >
              {number}
            </div>
            <div
              className={cn('dynamic-numeric-form__counter-plus material-icons', { enabled: number < max })}
              data-testid="dynamic-numeric-form-counter-plus"
              onClick={() => number < max ? setValueAndClearValidation(number + 1) : null}
              onKeyUp={() => number < max ? onEnter(() => setValueAndClearValidation(number + 1)) : null}
              aria-label="Increase the number by one"
              tabIndex={0}
              role="button"
            >
              add
          </div>
          </div>
        </div>
        :
        <div
          className="dynamic-numeric-form__number"
          data-testid="dynamic-numeric-form-number"
        >
          <TextField
            className="dynamic-numeric-form__number-control"
            variant="outlined"
            value={number}
            onChange={({ target: { value } }) => setValueAndClearValidation(parseInt(value, 10))}
            inputProps={{
              type: 'number',
              min,
              max,
              inputMode: 'numeric',
              'data-testid': 'dynamic-numeric-form-input',
            }}
          />
        </div>
      }
    </div>
  );
};

const Helper = ({ HelperTextInfo }: any) => {
  if (HelperTextInfo) {
    return (
      <div className="ripa-form-container__helper-box">
        <div className="material-icons">
          help
        </div>
        <div className="ripa-form-container__helper-box-text">
          {HelperTextInfo}
        </div>
      </div>
    );
  }
  return null;
};

DynamicNumericForm.helper = Helper;

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  currentPerson: NewReport.selectors.currentSubloop(state)
});

const mapDispatchToProps = {
  onChange: (value: any, resultPath: any) => Form.setFormField({ path: resultPath })(value)
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DynamicNumericForm)
