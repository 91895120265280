import thunk from 'redux-thunk'
import logger from 'redux-logger'
import formUpdateMiddleware from './formUpdateMiddleware'
import reviewUpdateMiddleware from './reviewUpdateMiddleware'
import searchUpdateMiddleware from './searchUpdateMiddleware'

export default deps => {
  const middlewares = []
  middlewares.push(thunk.withExtraArgument(deps))
  middlewares.push(formUpdateMiddleware)
  middlewares.push(reviewUpdateMiddleware)
  middlewares.push(searchUpdateMiddleware)

  if (location.href.includes('stage') || location.href.includes('ddns') || location.href.includes('contact-dev') || process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
  }

  return middlewares
}
