import { Tooltip } from '@material-ui/core';
import { getElementsIntersectingPoint } from '@utility/elementIntersect';
import { isInDocumentLayout } from '@utility/getBoundingClientRect';
import { onEnter } from '@utility/keypressHelpers';
import cn from 'classnames';
import { replace, uniqueId, throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import './PiiTextField.scss';
import { connect, ConnectedProps } from 'react-redux';
import { User } from '@ducks';
import { v4 as uuidv4 } from 'uuid';

const NO_BREAK_SPACE = '\u00A0'

const REDACTION_TYPES = [
  'CREDIT_CARD_NUMBER',
  'STREET_ADDRESS',
  'ZIPCODE',
  'PHONE_NUMBER',
  // 'IP_ADDRESS',
  'US_SOCIAL_SECURITY_NUMBER',
  'EMAIL_ADDRESS',
  // 'URL',
  'CA_DRIVERS_LICENSE_NUMBER',
  'LICENSE_PLATE',
  'PERSON_NAME',
];

const PiiTextField = (props: Props) => {
  const { onBlur, placeholder, value, onChange, 'data-testid': dataTestId, disabled, charLimit, getPIIRedactionsFromText, piiTextRedactions, charMin } = props;
  const [id] = useState(`pii-text-field-field-${Math.random()}`);
  const characterLimit = charLimit ?? 250; // default 250 character limit
  const characterMinimum = charMin ?? 0; // default 0 character minimum
  const [inputHtml, setInputHtml] = useState('');
  const [piiTextId] = useState(uuidv4);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLElement | null>(null);
  const [hasMinCharError, setHasMinCharError] = useState(false);

  const showTooltip = !!tooltipAnchor && isInDocumentLayout(tooltipAnchor);

  const contentEditableHtml = useRef('');

  useEffect(() => {
    if (value) {
      onTextChange(value);
      onEditableHtmlChange(value);
    }
    // reset piiTextRedactions when unmount
    return () => {
      getPIIRedactionsFromText('', piiTextId);
    }
  }, []);

  useEffect(() => {
    if (contentEditableHtml.current?.trim()?.length >= characterMinimum || characterMinimum === 0) {
      setHasMinCharError(false);
    }
  }, [inputHtml, contentEditableHtml]);

  useEffect(() => {
    // Listens for PII matches from the service worker and highlights them
    const piiTextRedaction = piiTextRedactions?.[piiTextId];
    if (piiTextRedaction) {
      const { text, matches } = piiTextRedaction;
      let redactedText = text.replaceAll(/&nbsp;/g, NO_BREAK_SPACE);
      REDACTION_TYPES.forEach((rt) => {
        while (redactedText.includes(rt) && matches?.length > 0) {
          const matched = matches?.splice(0, 1)?.[0]?.trim();
          if (matched) {
            const matchedId = uniqueId('matched-text-');
            redactedText = redactedText.replace(
              rt,
              `<div id="${matchedId}" class="pii-text-field__pii" tooltip="Potential PII: ${rt
                .replace(/_/g, ' ')
                .toLowerCase()
                .toTitleCase()
                .replace('Us', 'US')
                .replace('Ca ', 'CA ')}"><span id="pii">${matched}</span></div>`
            );
          }
        }
      });
      setInputHtml(redactedText);
    }
  }, [piiTextRedactions?.[piiTextId]]);

  // function to remove special character (&nbsp or &amp) in text
  const cleanupText = (text: string) =>
   replace(text, /&(nbsp|amp);/g, (matched) => {
    switch (matched) {
      case '&nbsp;':
        return ' ';
      case '&amp;':
        return '&';
      default:
        return matched;
    }
  });

  const onTextChange = (textChars: string) => {
    // Get PII matches
    getPIIRedactionsFromText(textChars, piiTextId);
    onChange({
      target: {
        value: cleanupText(textChars),
      },
    });
  };

  const onEditableHtmlChange = (textSanitized: string) => {
    contentEditableHtml.current = textSanitized;
  };

  // Desktop specific mouse event handler to show tooltip
  const onHighlightMouseMove = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const intersected = getElementsIntersectingPoint(document, evt.clientX, evt.clientY);
    let noPii = true;
    for (const el of intersected) {
      if (el?.id === 'pii') {
        if (el.parentElement?.id !== tooltipAnchor?.parentElement?.id) {
          noPii = false;
          setTooltipAnchor(el);
          break;
        }
      }
    }
    if (noPii) {
      setTooltipAnchor(null);
    }
  };

  // Mobile specific touch event handler to show tooltip
  const onHighlightTouchMove = (evt: React.TouchEvent<HTMLDivElement>) => {
    const touch = evt.touches[0];
    if (touch) {
      const intersected = getElementsIntersectingPoint(document, touch.clientX, touch.clientY);
      let noPii = true;
      for (const el of intersected) {
        if (el?.id === 'pii') {
          if (el.parentElement?.id !== tooltipAnchor?.parentElement?.id) {
            noPii = false;
            setTooltipAnchor(el);
            break;
          }
        }
      }
      if (noPii) {
        setTooltipAnchor(null);
      }
    }
  };

  const onChangeTextField = (e: ContentEditableEvent) => {
    if (!disabled) {
      const textSanitized: string = e.currentTarget.innerHTML?.toString().replaceAll(/<[^>]*>/g, '') ?? '';
      const textChars = textSanitized;
      const newTextChars = textChars.replaceAll(/&nbsp;/g, NO_BREAK_SPACE).replaceAll(/&amp;/g, '&');
      if (newTextChars.trim().length < characterMinimum && characterMinimum > 0) {
        setHasMinCharError(true);
      }
      if (newTextChars.length <= characterLimit) {
        onTextChange(textChars);
        onEditableHtmlChange(textChars);
      }
    }
  }

  const throttleOnChangeTextField = throttle(onChangeTextField, 100, { trailing: false, leading: true });

  return (
    <div
      className={cn('pii-text-field', { disabled, error: hasMinCharError })}
      onClick={() => document.getElementById(id)?.focus()}
      onKeyUp={onEnter(() => document.getElementById(id)?.focus())}
      data-testid="pii-text-field"
      role="button"
      tabIndex={0}
    >
      {showTooltip && (
        <Tooltip
          open={showTooltip}
          onClose={() => setTooltipAnchor(null)}
          placement="top"
          arrow
          title={tooltipAnchor?.parentElement?.getAttribute('tooltip') ?? ''}
          PopperProps={{
            anchorEl: tooltipAnchor,
          }}
          classes={{
            tooltip: 'pii-text-field__tooltip',
            arrow: 'pii-text-field__tooltip-arrow',
          }}
        >
          <div />
        </Tooltip>
      )}
      <div className="pii-text-field__field-container" id="pii-container">
        <div className="pii-text-field__colors" data-testid="pii-text-field-html" dangerouslySetInnerHTML={{ __html: inputHtml }} />
        <ContentEditable
          className="pii-text-field__editable"
          disabled={disabled}
          id={id}
          data-testid={dataTestId}
          placeholder={placeholder}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              document.execCommand('insertLineBreak');
              e.preventDefault();
            }
            const text = (e.target as HTMLElement).innerText;
            const allowKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Enter'];
            if (text.length >= characterLimit && allowKeys.indexOf(e.key) === -1) {
              e.preventDefault();
            }
          }}
          onChange={throttleOnChangeTextField}
          html={contentEditableHtml.current ?? ''}
          onMouseMove={onHighlightMouseMove}
          onTouchMove={onHighlightTouchMove}
        />
      </div>
    </div>
  );
};

export interface Props extends PropsFromRedux{
  placeholder: string;
  value: string;
  onChange: ({ target: { value } }: { target: { value: string } }) => void;
  'data-testid': string;
  disabled?: boolean;
  onBlur?: () => void;
  charLimit?: number;
  charMin?: number;
}

const mapDispatchToProps = {
  getPIIRedactionsFromText: User.getPIIRedactionsFromText,
}

const mapStateToProps = (state: any) => ({
  assignedRoles: User.selectors.roles(state),
  piiTextRedactions: User.selectors.piiTextRedactions(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PiiTextField);
