import React from 'react';
import { matchPath } from 'react-router';
import { Switch } from 'react-router-dom';
import { AuthProvider } from '@components/custom';
import getPageComponent from './getPageComponent';
import { paths, unauthPaths } from './paths';

export const hasPath = ({ path, assignedRoles, isWorkingOffline, breakpoint, state }: {
  path: ValueOf<typeof paths> | ValueOf<typeof unauthPaths>;
  assignedRoles: string[];
  isWorkingOffline: boolean;
  breakpoint: string;
  state: any;
}) => {
  const offlineCondition = isWorkingOffline ? path.allowOffline : true;
  const hasRequiredRole = path?.requiredRole?.filter((r: string) => assignedRoles ? assignedRoles?.includes(r) : false)?.length > 0 ?? true;
  const hasAllowMobile = breakpoint === 'xs' ? (path?.allowMobile ?? true) : true;
  const hasRequiredState = state && path.requiredState ? path.requiredState.includes(state) : true;
  const hasExcludedRole = path?.excludedRole?.filter((r: string) => assignedRoles ? assignedRoles?.includes(r) : false)?.length > 0 ?? true;
  return !hasExcludedRole && offlineCondition && hasRequiredRole && hasAllowMobile && hasRequiredState;
};

export const isPath = (currentPath: string, path: string) => matchPath(currentPath, { path, exact: true, strict: false });

const props = { paths, unauthPaths, hasPath, isPath, getPageComponent }

const Routes = () => (
  <Switch>
    {/* These are top level routes that only link to pages. */}
    <AuthProvider {...props} />
  </Switch>
);

export default Routes;
