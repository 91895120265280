import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Root, DecoupledDispatchProvider } from '@components/custom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Loader } from '@googlemaps/js-api-loader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import configureMaterialTheme from '@theme/material';
import DateFnsUtils from '@date-io/date-fns';
import configureStore from '@engine/store';
import getEnv from '@engine/env';
import { setDarkMode, detectThemeChange } from '@utility/setDarkMode';
import * as Sentry from '@sentry/browser';
import { offlineConfig } from '@engine/dependencies/localforage';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.scss';

window.buildDetails = {
  hash: COMMIT_HASH,
  hashdate: LASTCOMMITDATETIME,
  builddate: BUILD_DATE
}

const offlineModeAllowed = localStorage.getItem('contact-offline-mode-allowed') === 'true';
const theme = localStorage.getItem('theme');

if (theme === 'Dark') {
  setDarkMode(true);
} else if (theme === 'Light') {
  setDarkMode(false);
} else {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  setDarkMode(isDark);
  detectThemeChange();
}

fetch(`${window.location.origin}/config.json`)
  .then(response => response.json())
  .then(data => window.config = data)
  .catch(() => window.config = {})
  .finally(async () => {
    const env = getEnv();
    try {
      if (!offlineModeAllowed || !env.REACT_APP_FEATURE_FLAGS.serviceWorkerFeature) {
        serviceWorkerRegistration.unregister();
        if (window.navigator && navigator.serviceWorker) {
          navigator.serviceWorker.getRegistrations()
            .then(registrations => {
              for (const registration of registrations) {
                registration.unregister();
              }
            });
        }
      }
      const workOfflineStatus = await offlineConfig.getItem('work-offline')
        .then(wo => String(wo) === 'true');
      const loader = env?.REACT_APP_GOOGLE_API_KEY && !workOfflineStatus ? new Loader({
        apiKey: env.REACT_APP_GOOGLE_API_KEY,
        version: '3.45',
        libraries: ['places']
      }) : null;

      if (loader) {
        try {
          await loader.load();
        } catch (e) {
          console.log("Google API didn't load", e);
        }
      }

      const dsn = env?.REACT_APP_SENTRY_DSN ?? '';

      if (dsn !== '') {
        console.log('sentry', 'init');
        Sentry.init({
          dsn,
          environment: window.location.href.includes('stage') ? 'stage' : 'production',
          tracesSampleRate: 1.0,
          ignoreErrors: [],
        });
      }
    } catch (e) {
      console.error('App boot error!', e)
    }

    const store = configureStore(env);
    ReactDOM.render(
      <MuiThemeProvider theme={configureMaterialTheme()}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider store={store}>
            <DecoupledDispatchProvider store={store}>
              <Root store={store} />
            </DecoupledDispatchProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>,
      document.getElementById('root')
    );

    if (window.Cypress) {
      window.store = store;
    }
  });

function clearCache(reloadAfterClear = true) {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach(async (name) => {
        await caches.delete(name);
      })
    })
    if (reloadAfterClear) {
      window.location.reload();
    }
  }
}

if (offlineModeAllowed) {
  serviceWorkerRegistration.register();
  serviceWorkerRegistration?.addEventListener('updatefound', () => {
    console.log('Service Worker update found!');
    // Wait for deployment to finish then clear cache and reload
    setTimeout(() => clearCache(), 60000);
  });
  setInterval(() => {
    serviceWorkerRegistration.update();
  }, 10000)
}

// Do a soft refresh if sw is registered but no sw controller
// Controllers are null after a hard refresh
navigator.serviceWorker?.getRegistration().then(reg => {
  // There's an active SW, but no controller for this tab.
  if (reg?.active && !navigator.serviceWorker?.controller) {
    window.location.reload();
  }
});

/* Extend array */
/* eslint-disable-next-line no-extend-native */
Array.prototype.last = function () {
  return this[this.length - 1];
};

/* Store console logs */
console.stdlog = console.log.bind(console);

console.logs = [];
console.errors = [];

console.log = function (...args) {
  console.logs.push(Array.from(args));
  console.logs = console.logs.length > 10 ? console.logs.slice(1) : console.logs;
  console.stdlog(...args);
};

console.stdErrorLog = console.error.bind(console);
console.error = function (...args) {
  console.errors.push(Array.from(args));
  console.errors = console.errors.length > 10 ? console.errors.slice(1) : console.errors;
  console.stdErrorLog(...args);
};
