import React from 'react';
import { onEnter } from '@utility/keypressHelpers';
import { connect, ConnectedProps } from 'react-redux';
import * as Organizations from '@ducks/organizations';
import { Tooltip } from '@material-ui/core';
import './OrganizationsRow.scss';
import { ColumnData } from '@engine/ducks/types';

interface Props extends PropsFromRedux {
  colData: ColumnData[];
  rowData: any;
  rowIndex: number;
  setDeleteOrgDialogOpen: any;
}

export const OrganizationRow = ({ colData, rowData, rowIndex, setEditOrgDialogOpen, setDeleteOrgDialogOpen, setUploadUserCsvDialogOpen, setAddUserDialogOpen }: Props) => {
  const { id, name, subdomain } = rowData;

  /* eslint-disable default-case, consistent-return */
  const renderCell = ({ dataKey }: any) => {
    switch (dataKey) {
      case 'name':
        return <div role="cell" className="organization-row__cell-name">
          <strong>{rowData[dataKey]}</strong>
        </div>
      case 'state':
        return <div role="cell" className="organization-row__cell-state">
          <strong>{rowData[dataKey]}</strong>
        </div>
      case 'subdomain':
        return <div role="cell" className="organization-row__cell-subdomain">
          <a href={`${location.protocol}//${rowData?.[dataKey]}.${location.host.split('.').slice(1).join('.')}`}>
            {rowData?.[dataKey]}
          </a>
        </div>
      case 'created':
        return <div role="cell" className="organization-row__cell-created">
          {rowData[dataKey]?.format?.('MM/DD/YYYY h:mm A')}
        </div>
      case 'id':
        return <div role="cell" className="organization-row__cell-id">
          {id}
        </div>
      case 'doj_production':
        return rowData[dataKey] ?
          <div role="cell" className="organization-row__cell-production">
            <div className="material-icons">
              done
            </div>
          </div>
          :
          null
      case 'actions':
        return <div role="cell" className="organization-row__cell-actions">
          <Tooltip title="Bulk user import">
            <div
              className="organization-row__table-cell-actions-upload-users material-icons"
              role="button"
              tabIndex={0}
              data-testid="organization-row-table-cell-upload-users"
              aria-label="Add bulk users to org"
              onClick={() => setUploadUserCsvDialogOpen({ open: true, orgId: id, orgName: name, subdomain })}
              onKeyUp={onEnter(() => setUploadUserCsvDialogOpen({ open: true, orgId: id, orgName: name, subdomain }))}
            >
              group_add
            </div>
          </Tooltip>
          <Tooltip title="Add Admin">
            <div
              className="organization-row__table-cell-actions-goto-org material-icons"
              role="button"
              tabIndex={0}
              data-testid="organization-row-table-cell-goto-org"
              aria-label="Add user to org"
              onClick={() => setAddUserDialogOpen({ open: true, orgId: id })}
              onKeyUp={onEnter(() => setAddUserDialogOpen({ open: true, orgId: id }))}
            >
              manage_accounts
            </div>
          </Tooltip>
          <Tooltip title="Edit org">
            <div
              className="organization-row__table-cell-actions-edit material-icons"
              role="button"
              tabIndex={0}
              data-testid="organization-row-table-cell-edit"
              aria-label="Edit this org"
              onClick={() => setEditOrgDialogOpen({ id, open: true })}
              onKeyUp={onEnter(() => setEditOrgDialogOpen({ id, open: true }))}
            >
              edit
            </div>
          </Tooltip>
          <Tooltip title="Delete org">
            <div
              className="organization-row__table-cell-actions-delete material-icons"
              role="button"
              tabIndex={0}
              data-testid="organization-row-table-cell-delete"
              aria-label="Delete this org"
              onClick={() => setDeleteOrgDialogOpen({ open: true, id, orgName: name })}
              onKeyUp={onEnter(() => setDeleteOrgDialogOpen({ open: true, id, orgName: name }))}
            >
              cancel
            </div>
          </Tooltip>
        </div>
    }
  }

  return (
    <div
      role="row"
      className="organization-row"
      data-testid={`organization-row-${rowIndex}-${rowData.id}`}
    >
      <div
        className="organization-row__row"
        data-testid="organization-row-row"
      >
        {colData.map(({ grow, dataKey, width, minWidth }, index) => <div
          className="organization-row__cell"
          data-testid={`organization-row-cell-${rowIndex}-${id}-${index}`}
          key={`OrganizationRowCell-${id}-${rowIndex}-${index}-${dataKey}`}
          style={{
            flexGrow: grow,
            maxWidth: width,
            minWidth
          }}>
          {renderCell({ dataKey })}
        </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setEditOrgDialogOpen: Organizations.setEditOrgDialogOpen,
  setDeleteOrgDialogOpen: Organizations.setDeleteOrgDialogOpen,
  setAddUserDialogOpen: Organizations.setAddUserDialogOpen,
  setUploadUserCsvDialogOpen: Organizations.setUploadUserCsvDialogOpen
}

const mapStateToProps = () => ({})

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrganizationRow);
