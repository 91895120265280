import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { get, toString } from 'lodash';
import { FormControl, Select, MenuItem, TextField, InputAdornment, CircularProgress, Tooltip, InputLabel } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { Switch, ConfirmDialog } from '@components/common';
import { onEnter } from '@utility/keypressHelpers'
import { locate2024, coordsToAddress } from '@utility/location';
import { initAutocomplete } from '@utility/autocomplete';
import { isAlphanumeric } from '@utility/isAlphanumeric';
import { offlineConfig } from '@engine/dependencies/localforage';
import { LocationType } from '@ducks/constants';
import { StepErrors } from '@ducks/types';
import { matchSorter } from 'match-sorter';
import { Props, connector } from './props';
import './RipaTabbedLocationForm.scss';

// Accuracy based on 2024 requirement 'There will be a maximum of 3 digits allowed after the decimal and a maximum of 3 decimals allowed before the decimal.'
// Source: https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
const MIN_ACCURACY = 110;

type SchoolT = NonNullable<typeof window.schools>[0];
type CityT = NonNullable<typeof window.cities>[0];

const SetCityComponent = ({ city, setCity, cityFilterOptions, errors, stopDateTime }: {
  city: string;
  setCity: (newCity: string) => void;
  cityFilterOptions: (options: CityT[], state: FilterOptionsState<CityT>) => CityT[];
  errors: StepErrors;
  stopDateTime: string;
}) =>
  <Autocomplete<CityT>
    inputValue={city}
    className="ripa-tabbed-location-form__city-autocomplete"
    data-testid="ripa-tabbed-location-form-city-autocomplete"
    defaultValue={window.cities?.find((c) => c.City.localeCompare(city as string, 'en', { sensitivity: 'base' }) === 0)}
    onInputChange={(_, o) => setCity(o)}
    blurOnSelect={true}
    getOptionLabel={(option) => option.City}
    renderOption={c => (
      <>
        {c.InactiveDate && dayjs(c.InactiveDate).isBefore(stopDateTime) ? (
          <Tooltip title={`City inactive as of ${c.InactiveDate}`}>
            <span className="ripa-location-form__city-autocomplete-option-inactive">
              {c.City}
            </span>
          </Tooltip>
        ) : (
          <span className="ripa-location-form__city-autocomplete-option-active">
            {c.City}
          </span>
        )}
      </>
    )}
    options={window.cities ?? []}
    filterOptions={cityFilterOptions}
    renderInput={(params) => (
      <TextField
        {...params}
        error={get(errors, 'city', 0) > 0}
        placeholder="Select city..."
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              <div className="ripa-tabbed-location-form__address-input-city-end-adornment-default">{params.InputProps.endAdornment}</div>
              <InputAdornment position="end" className="ripa-tabbed-location-form__address-input-city-end-adornment-city">
                <div className="ripa-tabbed-location-form__address-input-city">CITY</div>
              </InputAdornment>
            </>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          'data-testid': 'ripa-tabbed-location-form-city-autocomplete-input',
        }}
      />
    )}
  />

const RipaTabbedLocationForm = ({
  setSchool,
  setCoordinates,
  watchLocation,
  errors,
  setBlock,
  setStreet,
  street,
  block,
  school,
  setStopHappenedAtPublicSchool,
  city,
  setCity,
  userState,
  crossStreet1,
  crossStreet2,
  setCrossStreet1,
  setCrossStreet2,
  setHighway,
  setExit,
  highway,
  exit,
  locationType,
  setLocationType,
  otherLocation,
  setOtherLocation,
  gpsAddress,
  setGpsAddress,
  schoolAddress,
  setSchoolAddress,
  coordinates,
  stopDateTime,
  breakpoint
}: Props) => {
  const [tabWarningDialogOpen, setTabWarningDialogOpen] = useState({
    open: false,
    nextTab: ''
  });
  const [updateGPSConfirmDialog, setUpdateGPSConfirmDialog] = useState(false);
  const [tabHasData, setTabHasData] = useState(false);
  const [locationEnabled, setLocationEnabled] = useState(watchLocation.available);
  const [useCurrentLoc, setUseCurrentLoc] = useState(locationType === LocationType.GPS);
  const [locationLoading, setLocationLoading] = useState(false);
  const [isWorkingOffline, setIsWorkingOffline] = useState(false);
  const [tab, setTab] = useState(locationType || LocationType.Block);
  const [prevBlockValue, setPrevBlockValue] = useState(toString(block));

  const handleRelocate = () => {
    setGpsAddress('');
    setLocationLoading(true);
    locate2024(setLocationEnabled, setCoordinates);
  }

  const handleUseLocation = async (vUseCurrentLoc: boolean) => {
    setUseCurrentLoc(vUseCurrentLoc);
    if (coordinates.available || coordinates.latitude !== -1 || coordinates.longitude !== -1 || coordinates.accuracy !== -1) {
      setUpdateGPSConfirmDialog(vUseCurrentLoc);
    } else {
      setLocationLoading(true);
      setCoordinates(watchLocation);
    }

    if (!vUseCurrentLoc) {
      setTab(LocationType.Block);
    }
  };
  const handleSetSchoolName = (schoolName?: string) => {
    setSchool(schoolName);
  };
  const handleSetSchool = (selSchool: SchoolT | null) => {
    handleSetSchoolName(selSchool?.School);

    if (selSchool?.School) {
      const { Street, City, State, Zip } = selSchool;
      const addressTrim = `${Street || ''}${Street ? ',' : ''} ${City || ''}${City ? ',' : ''} ${State || ''} ${Zip || ''}`.trim();
      const cityTrim = (City || '')?.trim();
      if (addressTrim) {
        setSchoolAddress(addressTrim);
      }
      if (cityTrim) {
        setCity(cityTrim);
      }
      setUseCurrentLoc(false);
    }
  };

  const handleUpdateConfirm = () => {
    if (locationEnabled === 'available' && useCurrentLoc) {
      setGpsAddress('');
      setLocationLoading(true);
      setUseCurrentLoc(useCurrentLoc);
      setCoordinates(watchLocation);
    }
    setUpdateGPSConfirmDialog(false);
  };

  useEffect(() => {
    setLocationEnabled(watchLocation.available);
  }, [watchLocation]);

  useEffect(() => {
    if (locationEnabled === 'unavailable') {
      setUseCurrentLoc(false);
    }
  }, [locationEnabled]);

  useEffect(() => {
    if (useCurrentLoc) {
      setTab(LocationType.GPS);
    }
  }, [useCurrentLoc])

  useEffect(() => {
    setLocationType(tab);
  }, [tab])

  useEffect(() => {
    switch (tab) {
      case LocationType.GPS:
        setTabHasData(gpsAddress.trim() !== '' || city !== '');
        break;
      case LocationType.Block:
        setTabHasData(street.trim() !== '' || !!block || city !== '');
        break;
      case LocationType.Intersection:
        setTabHasData(crossStreet1.trim() !== '' || crossStreet2.trim() !== '' || city !== '');
        break;
      case LocationType.School:
        setTabHasData(school.trim() !== '' || schoolAddress.trim() !== '' || city !== '');
        break;
      case LocationType.HighwayExit:
        setTabHasData(highway.trim() !== '' || exit.trim() !== '' || city !== '');
        break;
      case LocationType.Other:
        setTabHasData(otherLocation.trim() !== '' || city !== '');
        break;
      default:
        break;
    }

  }, [tab, street, block, school, schoolAddress, otherLocation, crossStreet1, crossStreet2, highway, exit, gpsAddress, city])

  useEffect(() => {
    const runCoorToAddress = async () => {
      const setQueriedLocation = (q_street: string, q_city: string) => {
        setGpsAddress(q_street);
        setCity(q_city);
      };
      await coordsToAddress(coordinates, setQueriedLocation, setLocationLoading);
      setLocationLoading(false);
    }
    if (useCurrentLoc && coordinates.latitude !== -1 && coordinates.longitude !== -1 && coordinates.accuracy <= MIN_ACCURACY && !gpsAddress.trim()) {
      runCoorToAddress()
    }
  }, [useCurrentLoc, coordinates, gpsAddress])

  const iniAutocomplete = useCallback(async () => {
    const workOfflineStatus = await offlineConfig.getItem('work-offline').then((wo) => wo === 'true');
    setIsWorkingOffline(workOfflineStatus);
    if (!workOfflineStatus) {
      if (userState) {
        initAutocomplete('ripa-tabbed-location-form-autocomplete', userState, (loc, ci) => {
          switch (tab) {
            case LocationType.Block:
              setStreet(loc);
              break;
            case LocationType.Intersection:
              setCrossStreet1(loc);
              break;
            case LocationType.School:
              setSchoolAddress(loc);
              break;
            case LocationType.HighwayExit:
              setHighway(loc);
              break;
            case LocationType.Other:
              setOtherLocation(loc);
              break;
            default:
              break;
          }
          setCity(ci);
        }, LocationType.Other === tab);
        if (LocationType.Intersection === tab) {
          initAutocomplete('ripa-tabbed-location-form-autocomplete-2', userState, (loc, ci) => {
            setCrossStreet2(loc);
            setCity(ci);
          }, false);
        }
      }
    }
  }, [userState, tab])

  useEffect(() => {
    iniAutocomplete();
    setStopHappenedAtPublicSchool(tab === LocationType.School);
    if (tab !== LocationType.School) {
      setSchool('')
      setSchoolAddress('')
    }
  }, [userState, tab]);

  useEffect(() => {
    iniAutocomplete();
  }, []);

  const cityFilterOptions = createFilterOptions<CityT>({
    matchFrom: 'any',
    limit: 50,
  });

  const { accuracy } = coordinates;

  const tabs = (useCurrentLoc ? [LocationType.GPS, LocationType.Block, LocationType.School, LocationType.Intersection, LocationType.HighwayExit, LocationType.Other] :
    [LocationType.Block, LocationType.School, LocationType.Intersection, LocationType.HighwayExit, LocationType.Other])

  return (
    <div className="ripa-tabbed-location-form" data-testid="ripa-tabbed-location-form">
      <div className="ripa-tabbed-location-form__title">Where was the location?</div>

      <div
        className={cn('ripa-tabbed-location-form__switch', {
          searching: useCurrentLoc && accuracy > MIN_ACCURACY,
          active: useCurrentLoc && locationEnabled && accuracy <= MIN_ACCURACY,
          disabled: ['unavailable', 'unknown'].includes(locationEnabled),
          waiting: locationEnabled === 'unknown',
          unavailable: locationEnabled === 'unavailable',
        })}
      >
        <div className="ripa-tabbed-location-form__current-loc-switch-text">
          {isWorkingOffline
            ? 'OFFLINE'
            : useCurrentLoc && locationEnabled === 'available' && accuracy <= MIN_ACCURACY
              ? 'USE GPS'
              : useCurrentLoc && accuracy > MIN_ACCURACY
                ? 'SEARCHING LOCATION'
                : locationEnabled === 'unknown'
                  ? 'WAITING FOR GPS'
                  : locationEnabled === 'unavailable'
                    ? 'NO LOCATION'
                    : 'USE GPS'}
        </div>
        <Switch
          className={cn('ripa-tabbed-location-form__location-picker-toggle-switch', {
            disabled: ['unavailable', 'unknown'].includes(locationEnabled),
          })}
          data-testid="ripa-tabbed-location-form-location-switch"
          aria-label="shows if gps location is available"
          onText="YES"
          offText="NO"
          disabled={['unavailable', 'unknown'].includes(locationEnabled) || isWorkingOffline}
          value={useCurrentLoc}
          onChange={handleUseLocation}
          disableChangeOnMount={true}
        />
        <div className="ripa-tabbed-location-form__switch-mobile" />
      </div>
      <div className="ripa-tabbed-location-form__tab-container">
        {tabs.map((t, tabIndex) =>
          <div
            role="button"
            aria-label={`Tabbed location tab ${t}`}
            tabIndex={0}
            className={cn('ripa-tabbed-location-form__tab', { active: t === tab })}
            key={`RipaTabbedLocationForm-${tabIndex}`}
            data-testid={`ripa-action-taken-form-action-tab-${t.toLowerCase().replace(' ', '-')}`}
            onClick={() => tabHasData ? setTabWarningDialogOpen({ open: true, nextTab: t }) : setTab(t)}
            onKeyUp={onEnter(() => tabHasData ? setTabWarningDialogOpen({ open: true, nextTab: t }) : setTab(t))}
          >
            {t}
          </div>
        )}
        <FormControl variant="outlined" className="ripa-tabbed-location-form__tab-dropdown">
          <InputLabel>
            Location Type
          </InputLabel>
          <Select
            value={tab}
            native={breakpoint === 'xs'}
            onChange={({ target: { value } }: any) =>
              tabHasData ? setTabWarningDialogOpen({ open: true, nextTab: value }) : setTab(value)
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {tabs.map((t, tabIndex) => {
              const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
              return (<CompName key={`RipaTabbedLocationFormDropdownItem-${tabIndex}`} value={t}>
                {t}
              </CompName>);
            })}
          </Select>
        </FormControl>
      </div>
      {LocationType.GPS === tab && <div className={cn('ripa-tabbed-location-form__gps', { disabled: useCurrentLoc })}>
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="Getting Address from GPS..."
          variant="outlined"
          disabled={true}
          value={gpsAddress}
          error={get(errors, 'location', 0) > 0}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-gps'
            },
            endAdornment: (
              <InputAdornment position="end">
                <div
                  className={cn('ripa-tabbed-location-form-address-input__relocate material-icons', {
                    disabled: locationLoading || ['unavailable', 'unknown'].includes(locationEnabled),
                    off: !useCurrentLoc
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleRelocate}
                  onKeyUp={onEnter(handleRelocate)}
                >
                  refresh
                </div>
                {locationLoading ? (
                  <CircularProgress />
                ) : (
                  <div
                    className={cn('material-icons', {
                      disabled: !useCurrentLoc || ['unavailable', 'unknown'].includes(locationEnabled),
                    })}
                  >
                    place
                  </div>
                )}
              </InputAdornment>
            ),
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      {LocationType.Block === tab && <div className="ripa-tabbed-location-form__block">
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: 1200"
          variant="outlined"
          value={block}
          type="number"
          error={get(errors, 'block', 0) > 0}
          onChange={({ target: { value } }) => {
            if (value.match(/^(?!0$)[0-9]{0,8}$/) || (prevBlockValue.length > value.length && value !== '0')) {
              setBlock(value);
            }
          }}
          onBlur={() => {
            const blockStr = toString(block);
            if (block && prevBlockValue !== blockStr && blockStr.length >= 1) {
              const newBlockValue = blockStr.length === 1 ? blockStr.slice(0, blockStr.length).concat('0') : blockStr.length === 2 ? blockStr.slice(0, -1).concat('0') : blockStr.slice(0, -2).concat('00');
              setBlock(newBlockValue);
              setPrevBlockValue(newBlockValue);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-block',
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment">
              <div className="ripa-tabbed-location-form__input-adornment-text">BLOCK</div>
            </InputAdornment>
          }}
        />
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: Main St"
          variant="outlined"
          value={street}
          type="string"
          error={get(errors, 'street', 0) > 0}
          onChange={({ target: { value } }) => {
            if (isAlphanumeric(value, 0, 50)) {
              setStreet(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-street',
              id: 'ripa-tabbed-location-form-autocomplete',
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment">
              <div className="ripa-tabbed-location-form__input-adornment-text">STREET</div>
            </InputAdornment>
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      {LocationType.School === tab && <div className="ripa-tabbed-location-form__school">
        <Autocomplete<SchoolT>
          className="ripa-tabbed-location-form__school-autocomplete"
          data-testid="ripa-tabbed-location-form-school-autocomplete"
          classes={{ option: 'school' }}
          defaultValue={window.schools?.find(
            (s) =>
              school && city
              && s.School.localeCompare(school, 'en', { sensitivity: 'base' }) === 0
              && (s.City?.localeCompare(city, 'en', { sensitivity: 'base' }) ?? 0) === 0
          )}
          onChange={(_, o) => handleSetSchool(o)}
          blurOnSelect={true}
          getOptionDisabled={(option) => option.StatusType === 'Closed'}
          getOptionLabel={(option) => option.School}
          renderOption={(option) => (
            <>
              {option.StatusType === 'Closed' ? (
                <div>
                  <Tooltip title="School is closed and cannot be selected">
                    <span data-status={option.StatusType}>
                      {option.School}
                      {` ${option.Street || ''} ${option.City || ''}`}
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <span data-status={option.StatusType}>
                  {option.School}
                  {` ${option.Street || ''} ${option.City || ''}`}
                </span>
              )}
            </>
          )}
          options={window.schools ?? []}
          filterOptions={(options, state: FilterOptionsState<SchoolT>) => {
            const value = state.inputValue ? state.inputValue.trim() : school;
            const filtered = matchSorter(options, value, {
              keys: ['School'],
              threshold: matchSorter.rankings.STARTS_WITH
            });
            const containsMatches = matchSorter(options, value, {
              keys: ['School'],
              threshold: matchSorter.rankings.CONTAINS
            }).filter(item => !filtered.includes(item));
            return [...filtered, ...containsMatches].splice(0, 50);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={get(errors, 'school', 0) > 0}
              placeholder="Select school..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <div className="ripa-tabbed-location-form__address-input-school-end-adornment-default">{params.InputProps.endAdornment}</div>
                    <InputAdornment position="end" className="ripa-tabbed-location-form__address-input-school-end-adornment-school">
                      <div className="ripa-tabbed-location-form__address-input-school">SCHOOL</div>
                    </InputAdornment>
                  </>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'ripa-tabbed-location-form-school-autocomplete-input',
              }}
            />
          )}
        />
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: School St"
          variant="outlined"
          value={schoolAddress}
          type="string"
          error={get(errors, 'schoolAddress', 0) > 0}
          onChange={({ target: { value } }) => {
            setSchoolAddress(value);
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-school-address',
              id: 'ripa-tabbed-location-form-autocomplete'
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment-cross-street">
              <div className="ripa-tabbed-location-form__input-adornment-text-cross-street">SCHOOL ADDRESS</div>
            </InputAdornment>
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      {LocationType.Intersection === tab && <div className="ripa-tabbed-location-form__intersection">
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: Main St"
          variant="outlined"
          value={crossStreet1}
          type="string"
          error={get(errors, 'crossStreet1', 0) > 0}
          onChange={({ target: { value } }) => {
            if (isAlphanumeric(value, 0, 50)) {
              setCrossStreet1(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-cross-street-1',
              id: 'ripa-tabbed-location-form-autocomplete',
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment-cross-street">
              <div className="ripa-tabbed-location-form__input-adornment-text-cross-street">CROSS STREET 1</div>
            </InputAdornment>
          }}
        />
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: State St"
          variant="outlined"
          value={crossStreet2}
          type="string"
          error={get(errors, 'crossStreet2', 0) > 0}
          onChange={({ target: { value } }) => {
            if (isAlphanumeric(value, 0, 50)) {
              setCrossStreet2(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-cross-street-2',
              id: 'ripa-tabbed-location-form-autocomplete-2'
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment-cross-street">
              <div className="ripa-tabbed-location-form__input-adornment-text-cross-street">CROSS STREET 2</div>
            </InputAdornment>
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      {LocationType.HighwayExit === tab && <div className="ripa-tabbed-location-form__highway-exit">
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: I5"
          variant="outlined"
          value={highway}
          type="string"
          error={get(errors, 'highway', 0) > 0}
          onChange={({ target: { value } }) => {
            if (value.match(/^[a-zA-Z0-9 ]{0,75}$/)) {
              setHighway(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-highway',
              id: 'ripa-tabbed-location-form-highway'
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment-highway">
              <div className="ripa-tabbed-location-form__input-adornment-text">HIGHWAY</div>
            </InputAdornment>
          }}
        />
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="EG: 24"
          variant="outlined"
          value={exit}
          type="string"
          error={get(errors, 'exit', 0) > 0}
          onChange={({ target: { value } }) => {
            if (isAlphanumeric(value, 0, 50)) {
              setExit(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input-exit'
            },
            endAdornment: <InputAdornment position="end" className="ripa-tabbed-location-form__input-adornment-exit">
              <div className="ripa-tabbed-location-form__input-adornment-text">CLOSEST EXIT</div>
            </InputAdornment>
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      {LocationType.Other === tab && <div className="ripa-tabbed-location-form__other">
        <TextField
          className="ripa-tabbed-location-form__address-input"
          placeholder="Enter an approximate location of the stop..."
          variant="outlined"
          value={otherLocation}
          error={get(errors, 'otherLocation', 0) > 0}
          onChange={({ target: { value } }) => {
            if (isAlphanumeric(value, 0, 150)) {
              setOtherLocation(value);
            }
          }}
          InputProps={{
            inputProps: {
              'data-testid': 'ripa-tabbed-location-form-address-input',
              id: 'ripa-tabbed-location-form-autocomplete'
            },
            endAdornment: (
              <InputAdornment position="end">
                {locationLoading ? (
                  <CircularProgress />
                ) : (
                  <div
                    className={cn('material-icons', {
                      disabled: !useCurrentLoc || ['unavailable', 'unknown'].includes(locationEnabled),
                    })}
                  >
                    place
                  </div>
                )}
              </InputAdornment>
            ),
          }}
        />
        <SetCityComponent city={city} setCity={setCity} cityFilterOptions={cityFilterOptions} errors={errors} stopDateTime={stopDateTime} />
      </div>}
      <ConfirmDialog
        heading="Start Over"
        open={tabWarningDialogOpen.open}
        closeDialog={() => setTabWarningDialogOpen({ open: false, nextTab: '' })}
        negativeAction={() => setTabWarningDialogOpen({ open: false, nextTab: '' })}
        positiveAction={() => {
          setTab(tabWarningDialogOpen.nextTab);
          setTabWarningDialogOpen({ open: false, nextTab: '' });
        }}
        positiveText="Confirm"
        negativeText="Cancel"
        data-testid="new-report-start-over-dialog"
      >
        <div>
          {breakpoint === 'xs' ? 'The content of the current location will not be submitted.' : 'The content of the current tab will not be submitted.'}
          <br />
          <br />
          {breakpoint === 'xs' ? 'Continue?' : 'Continue to new tab?'}
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        heading="Update GPS Location"
        open={updateGPSConfirmDialog}
        closeDialog={() => setUpdateGPSConfirmDialog(false)}
        negativeAction={() => setUpdateGPSConfirmDialog(false)}
        positiveAction={handleUpdateConfirm}
        positiveText="Confirm"
        negativeText="Cancel"
        data-testid="new-report-update-gps-confirm-dialog"
      >
        <div>
          {breakpoint === 'xs' ? 'The gps location will be updated.' : 'The gps location will be updated.'}
          <br />
          <br />
          {breakpoint === 'xs' ? 'Continue?' : 'Continue update?'}
        </div>
      </ConfirmDialog>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      <b>Do not provide</b>
      &nbsp;the exact address of a residence. Please report a road marker, landmark, or other description. Report county if no approximate location is available.
      <br />
    </div>
  </div>
);

RipaTabbedLocationForm.helper = Helper;

export default connector(RipaTabbedLocationForm);
