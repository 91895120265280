import React from 'react';
import classNames from 'classnames';
import './Column.scss';

export const ASCENDING_SORT = 1;

export const NO_SORT = 0;

export const DESCENDING_SORT = -1;

export const Column = (props: Props) => {
  const { dataKey, minWidth, children, index, title, grow, sortOrder = 0, sort, onClick = () => {}, setColRef, Component, disableOnlineFeatures } = props;
  const sortable = typeof sort === 'function' && !disableOnlineFeatures;
  const sortOrderText = ASCENDING_SORT === sortOrder ? 'ascending' : DESCENDING_SORT === sortOrder ? 'descending' : 'none';
  const ariaLabel = `${title} column${sortable ? `, sorted in ${sortOrderText} order` : ''}`;

  return (
    <div
      className="column"
      role="columnheader"
      tabIndex={0}
      data-testid={`column-${index}`}
      key={dataKey}
      onClick={sortable ? onClick : undefined}
      onKeyUp={(e) => (e.keyCode === 13 && sortable ? onClick() : null)}
      aria-label={ariaLabel}
      aria-sort={sortOrderText}
      ref={setColRef}
      style={{
        minWidth,
        flexGrow: grow,
        cursor: sortable ? 'pointer' : 'initial',
      }}
    >
      {Component && <Component {...props} />}
      {!Component && (
        <>
          <div className={classNames('column__title', { active: [ASCENDING_SORT, DESCENDING_SORT].includes(sortOrder) })} data-testid="column-title">
            {title || children}
          </div>
          <div data-testid="column-sort-container" className={classNames('column__sort', 'material-icons', { sortable })}>
            <div data-testid="column-up-arrow" className={classNames('column__sort-asc', 'material-icons', { active: sortOrder === ASCENDING_SORT })}>
              chevron_left
            </div>
            <div data-testid="column-down-arrow" className={classNames('column__sort-dsc', 'material-icons', { active: sortOrder === DESCENDING_SORT })}>
              chevron_right
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface Props {
  Component?: React.ComponentType<any>;
  index?: number;
  dataKey: string;
  minWidth?: number;
  grow: number;
  title: string;
  sortOrder?: number;
  sort?: Function;
  onClick?: (event?: React.MouseEvent) => void;
  children?: React.ReactNode;
  setColRef?: any;
  disableOnlineFeatures?: boolean;
}

export default Column;
