/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */

import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { LabelField, Checkbox, LabelDropdown } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import { Users, User } from '@ducks';
import { Role, RoleString } from '@ducks/constants';
import cn from 'classnames';
import { capitalize } from '@utility/stringTransform';
import { User as UserProps } from '@engine/ducks/types';
import './EditUser.scss';

interface Props extends PropsFromRedux {
  setPositiveAction?: (next: Function) => void;
  calculateAndSetHeight?: any;
  setRemovingReviewerDialogOpen: any;
  editUserDialog: any;
  users: any;
  setDisableUserDialogOpen: any;
  updateUser: any;
}

const EditUser = ({
  editUserDialog,
  setEditUserField,
  setPositiveAction,
  calculateAndSetHeight,
  reviewerDropdowns,
  updateUser,
  getReviewees,
  assignedRoles,
  authProvider,
  setDisableUserDialogOpen,
  setRemovingReviewerDialogOpen,
  users,
  userId,
}: Props) => {
  const [errors, setErrors] = useState<any>({})
  const [changePassword, setChangePassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [lockedUID, setLockedUID] = useState(true)
  const { firstname, lastname, email, officerId, username, isOfficerRole, isReviewerRole, isAnalystRole, isAdminRole, assignedReviewers, yearsOfExperience, id, disabled } = editUserDialog
  const [selectedReviewers, setSelectedReviewers] = useState<string[]>(
    () => assignedReviewers.length === reviewerDropdowns.length
      ? ['all', ...assignedReviewers]
      : assignedReviewers
  );

  useEffect(() => {
    getReviewees()
  }, [])

  useEffect(() => {
    setTimeout(calculateAndSetHeight, 10)
  }, [changePassword])

  const setFieldAndCheckValidation = (fieldValue: any, submit?: boolean) => {
    const key = Object.keys(fieldValue)[0]
    const getValue = (param: string) => (submit ? editUserDialog[param] : fieldValue[key])
    const fieldHasKey = (k: string) => fieldValue[k] !== undefined
    const localAccount = !(authProvider && ['OneLogin', 'AzureAD'].includes(authProvider));
    setEditUserField(fieldValue)

    if ((fieldHasKey('firstname') || submit) && localAccount) {
      errors.firstname = getValue('firstname')?.length === 0 ? 'Cannot be blank' : undefined
    }
    if ((fieldHasKey('lastname') || submit) && localAccount) {
      const condition = getValue('lastname')?.length === 0
      errors.lastname = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('email')) {
      errors.email = undefined
    }
    if (fieldHasKey('email') && submit && localAccount) {
      const matchAry =
        getValue('email')
          ?.toLowerCase()
          ?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ?? []
      errors.email = matchAry?.length === 0 ? 'Email is invalid' : undefined
    }
    if ((fieldHasKey('email') || submit) && localAccount) {
      const condition = getValue('email')?.length === 0
      errors.email = condition ? 'Cannot be blank' : errors.email
    }
    if ((fieldHasKey('username') || submit) && localAccount) {
      const condition = getValue('username')?.length === 0
      errors.username = condition ? 'Cannot be blank' : undefined
    }
    if ((fieldHasKey('officerId') || submit) && localAccount) {
      const condition = getValue('officerId').length && !getValue('officerId').match(/^[0-9a-zA-Z]{9}$/)
      errors.officerId = condition ? 'Must be 9 alphanumeric characters' : undefined
    }
    if (fieldHasKey('yearsOfExperience') || submit) {
      const condition = getValue('yearsOfExperience') === ''
      errors.yearsOfExperience = condition ? 'Cannot be blank' : undefined
    }
    if (fieldHasKey('yearsOfExperience') || submit) {
      const num = getValue('yearsOfExperience')
      const condition = num < 1 || num > 50
      errors.yearsOfExperience = condition ? 'Invalid Years of Experience' : undefined
    }
    // if (fieldHasKey('assignedReviewers') || submit) {
    //   const condition = getValue('assignedReviewers')?.length === 0
    //   errors.assignedReviewers = condition ? 'Reviewer cannot be blank' : undefined
    // }
    if (changePassword && (fieldHasKey('password') || fieldHasKey('confirmPassword'))) {
      if (fieldHasKey('password') || submit) {
        const condition = getValue('password')?.length >= 8
        errors.password = { ...errors.password, hasEight: condition }
      }
      if (fieldHasKey('password') || submit) {
        const matchAry = getValue('password')?.match(/[0-9]/g) ?? []
        errors.password = { ...errors.password, hasNumber: matchAry.length !== 0 }
      }
      if (fieldHasKey('password') || submit) {
        const matchAry = getValue('password')?.match(/[A-Z]/g) ?? []
        errors.password = { ...errors.password, hasUppercase: matchAry.length !== 0 }
      }
      if (fieldHasKey('password') || submit) {
        const matchAry = getValue('password')?.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g) ?? []
        errors.password = { ...errors.password, hasSpecial: matchAry.length !== 0 }
      }
      if (fieldHasKey('password') || submit) {
        setNewPassword(getValue('password'))
      }
      if (fieldHasKey('confirmPassword' || submit)) {
        errors.password = { ...errors.password, matching: getValue('confirmPassword') === newPassword }
      }
      if (fieldHasKey('password') && submit) {
        const { hasSpecial, hasUppercase, hasNumber, hasEight, matching } = errors.password
        errors.password = {
          ...errors.password,
          any: !hasSpecial || !hasUppercase || !hasNumber || !hasEight || !matching,
        }
      }
    }

    setErrors(errors)
    return errors
  }

  setPositiveAction?.(async (next: Function) => {
    const e = setFieldAndCheckValidation(editUserDialog, true)
    const hasErrors = Object.values(e).filter((v: any) => (v?.any !== undefined ? v.any : v !== undefined)).length > 0

    const currentReviewerRole = users.find((u: UserProps) => u?.id === editUserDialog.id)?.roles.includes(RoleString[Role.Reviewer])

    if (!hasErrors) {
      if (editUserDialog.reviewees.length !== 0 && currentReviewerRole !== isReviewerRole && !isReviewerRole) {
        setRemovingReviewerDialogOpen({ open: true })
      } else {
        const response = await updateUser(changePassword)
        if (response !== undefined) {
          const updatedErrors = { ...errors }
          Object.entries(response).forEach(([key, value]) => (updatedErrors[key] = `${capitalize(key)} ${value}`))
          setErrors(updatedErrors)
        }
      }
    }
    next()
  })

  const unlockOfficerId = ({ shiftKey }: any) =>
    shiftKey &&
    assignedRoles?.some((r) => r === Role.SuperAdmin) &&
    // eslint-disable-next-line no-alert
    confirm('You are enabling editing of a UID. That is generally not a good idea. Are you sure?') &&
    setLockedUID(!lockedUID)

    const updatedReviewerDropdowns = [
      { value: 'all', text: 'All Reviewers' },
      ...(reviewerDropdowns?.map(({ id: reviewerId, name }) => ({
        value: reviewerId,
        text: name,
      })) || [])
    ];
    const renderSelectedReviewers = (selected: any) => {
      const filteredSelected = selected.filter((s: any) => s !== 'all');
      return filteredSelected
        .map((s: any) => updatedReviewerDropdowns?.find((r) => r.value === s)?.text)
        .join('; ');
    };
    const handleSelectChange = ({ target: { value } }: any) => {
      const reviewerDropdownValues = updatedReviewerDropdowns.map((item) => item.value);
      const hasAllInSelectedReviewers = selectedReviewers.includes('all');
      const hasAllInValue = value.includes('all');
      if (hasAllInSelectedReviewers) {
        if (!hasAllInValue) {
          setSelectedReviewers([]);
          setFieldAndCheckValidation({ assignedReviewers: [] });
        } else if (selectedReviewers !== value) {
          const newValue = value.filter((s: any) => s !== 'all');
          setSelectedReviewers(newValue);
          setFieldAndCheckValidation({ assignedReviewers: newValue });
        }
      } else if (hasAllInValue || value.length === reviewerDropdowns.length) {
        setSelectedReviewers(reviewerDropdownValues);
        const newValue = reviewerDropdownValues.filter((s: any) => s !== 'all');
        setFieldAndCheckValidation({ assignedReviewers: newValue });
      } else {
        setSelectedReviewers(value);
        const newValue = value.filter((s: any) => s !== 'all');
        setFieldAndCheckValidation({ assignedReviewers: newValue });
      }
    };

  return (
    <div className="edit-user" data-testid="edit-user">
      <div className="edit-user__name-container">
        <LabelField
          label="First Name"
          className="edit-user__name-first-name"
          disabled={authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false}
          placeholder="Add Name..."
          error={Boolean(errors.firstname)}
          errorMessage={errors.firstname}
          defaultValue={firstname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ firstname: value })}
          inputProps={{
            'data-testid': 'edit-user-name-first-name',
          }}
        />
        <LabelField
          label="Last Name"
          className="edit-user__name-last-name"
          disabled={authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false}
          placeholder="Add Name..."
          defaultValue={lastname}
          error={Boolean(errors.lastname)}
          errorMessage={errors.lastname}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ lastname: value })}
          inputProps={{
            'data-testid': 'edit-user-name-last-name',
          }}
        />
      </div>
      <LabelField
        label="Email Address"
        className="edit-user__email"
        disabled={authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false}
        placeholder="Enter..."
        type="email"
        defaultValue={email}
        error={Boolean(errors.email)}
        errorMessage={errors.email}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ email: value })}
        inputProps={{
          'data-testid': 'edit-user-email',
        }}
      />
      <div className="edit-user__username-and-officer-id-container">
        <LabelField
          label="Username"
          placeholder="Enter..."
          className="edit-user__username"
          disabled={authProvider ? ['OneLogin', 'AzureAD'].includes(authProvider) : false}
          defaultValue={username}
          error={Boolean(errors.username)}
          errorMessage={errors.username}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ username: value })}
          inputProps={{
            'data-testid': 'edit-user-username',
          }}
        />
        <LabelField
          label="DOJ Officer ID"
          placeholder="Enter..."
          className="edit-user__officer-id"
          defaultValue={officerId}
          error={Boolean(errors.officerId)}
          errorMessage={errors.officerId}
          onChange={({ target: { value } }) => setFieldAndCheckValidation({ officerId: value })}
          onClick={unlockOfficerId}
          inputProps={{
            pattern: '[0-9a-zA-Z]{9}',
            'data-testid': 'edit-user-officer-id',
            readOnly: lockedUID,
          }}
        />
      </div>
      <div className="edit-user__hr" />
      <LabelField
        label="Years of Experience"
        placeholder="Enter Number..."
        type="number"
        className="edit-user__years"
        defaultValue={yearsOfExperience}
        error={Boolean(errors.yearsOfExperience)}
        errorMessage={errors.yearsOfExperience}
        onChange={({ target: { value } }) => setFieldAndCheckValidation({ yearsOfExperience: value })}
        inputProps={{
          min: 1,
          max: 50,
          'data-testid': 'edit-user-years',
        }}
      />
      <div className="edit-user__hr" />
      <div className="edit-user__roles-heading">
        <div className="edit-user__roles-text">USER ROLES</div>
      </div>
      <Checkbox className="edit-user__checkbox-officer" label="Officer" onChange={(isRole) => setEditUserField({ isOfficerRole: isRole })} defaultValue={isOfficerRole} />
      <Checkbox
        className="edit-user__checkbox-reviewer"
        label="Reviewer"
        data-testid="edit-user-checkbox-reviewer"
        onChange={(isRole) => setEditUserField({ isReviewerRole: isRole })}
        defaultValue={isReviewerRole}
      />
      <Checkbox className="edit-user__checkbox-analyst" label="Analyst" onChange={(isRole) => setEditUserField({ isAnalystRole: isRole })} defaultValue={isAnalystRole} />
      {assignedRoles?.filter((r) => r === Role.SuperAdmin || r === Role.Admin) && (
        <Checkbox className="edit-user__checkbox-admin" label="Admin" onChange={(isRole) => setEditUserField({ isAdminRole: isRole })} defaultValue={isAdminRole} />
      )}
      <LabelDropdown
        className="edit-user__dropdown-reviewer"
        data-testid="edit-user-dropdown-reviewer"
        label="Assigned Reviewers"
        multiple
        error={Boolean(errors.assignedReviewers)}
        errorMessage={errors.assignedReviewers}
        disableAll={assignedRoles?.includes(Role.SuperAdmin)}
        values={updatedReviewerDropdowns}
        onChange={handleSelectChange}
        renderValue={renderSelectedReviewers}
        value={selectedReviewers}
      />
      {!(authProvider && ['OneLogin', 'AzureAD'].includes(authProvider)) && (
        <div className="edit-user__no-auth-provider-options">
          <div className="edit-user__hr" />
          <div className="edit-user__change-password-toggle" data-testid="edit-user-change-password-toggle" onClick={() => setChangePassword(!changePassword)}>
            <div className="edit-user__change-password-toggle-title">CHANGE PASSWORD</div>
            {changePassword ? <div className="material-icons">expand_less</div> : <div className="material-icons">expand_more</div>}
          </div>
          {changePassword && (
            <div className="edit-user__change-password-container">
              <LabelField
                label="New Password"
                className="edit-user__password"
                placeholder="Enter..."
                error={errors?.password?.any}
                onChange={({ target: { value } }) => setFieldAndCheckValidation({ password: value })}
                type="password"
                inputProps={{
                  'data-testid': 'edit-user-password',
                  autocomplete: 'new-password',
                }}
              />
              <LabelField
                label="Retype New Password"
                className="edit-user__confirm-password"
                placeholder="Enter..."
                error={Boolean(newPassword && !errors?.password?.matching)}
                onChange={({ target: { value } }) => setFieldAndCheckValidation({ confirmPassword: value })}
                type="password"
                inputProps={{
                  'data-testid': 'edit-user-confirm-password',
                  autocomplete: 'new-password',
                }}
              />
              {newPassword && (
                <div className={cn('reset-password-confirm-error', { 'no-error': errors?.password?.matching })}>
                  <div className="material-icons">error</div>
                  <div className="reset-password-confirm-error-text">Both passwords must match</div>
                </div>
              )}
              <div className="edit-user__password-validation-text">Password must contain the following:</div>
              <div className="edit-user__password-validation-checks">
                <div className="edit-user__password-validation-check">
                  <div className={cn('edit-user__box', { checked: errors?.password?.hasEight })} data-testid="edit-user-has-eight">
                    <div className="material-icons">{errors?.password?.hasEight ? 'done' : 'not_interested'}</div>
                  </div>
                  <div className="edit-user__password-validation-check-label">8 CHARACTERS</div>
                </div>
                <div className="edit-user__password-validation-check">
                  <div className={cn('edit-user__box', { checked: errors?.password?.hasNumber })} data-testid="edit-user-has-number">
                    <div className="material-icons">{errors?.password?.hasNumber ? 'done' : 'not_interested'}</div>
                  </div>
                  <div className="edit-user__password-validation-check-label">1 NUMBER</div>
                </div>
                <div className="edit-user__password-validation-check">
                  <div className={cn('edit-user__box', { checked: errors?.password?.hasUppercase })} data-testid="edit-user-has-uppercase">
                    <div className="material-icons">{errors?.password?.hasUppercase ? 'done' : 'not_interested'}</div>
                  </div>
                  <div className="edit-user__password-validation-check-label">1 UPPERCASE</div>
                </div>
                <div className="edit-user__password-validation-check">
                  <div className={cn('edit-user__box', { checked: errors?.password?.hasSpecial })} data-testid="edit-user-has-special">
                    <div className="material-icons">{errors?.password?.hasSpecial ? 'done' : 'not_interested'}</div>
                  </div>
                  <div className="edit-user__password-validation-check-label">1 SPECIAL CHARACTER</div>
                </div>
              </div>
            </div>
          )}
          <div className="edit-user__hr" />
          {/* <div className="edit-user__button_container">
          <div
            className="edit-user__change-password-button"
            data-testid="edit-user-change-password-button"
            tabIndex="0"
            aria-label="Open change password dialog"
            onClick={() => setChangePasswordDialogOpen({ closeOthers: true, open: true, userId: id })}
            onKeyUp={onEnter(() => setChangePasswordDialogOpen({ closeOthers: true, open: true, userId: id }))}
          >
            <div className="material-icons">edit</div>
            CHANGE PASSWORD
          </div>
        </div> */}
          {!disabled && userId !== id && (
            <div className="edit-user__delete-button-area">
              <div
                className="edit-user__delete-button"
                tabIndex={0}
                aria-label="Open disable user dialog"
                onClick={() => setDisableUserDialogOpen({ open: true, id })}
                onKeyUp={onEnter(() => setDisableUserDialogOpen({ open: true, id }))}
              >
                <div className="material-icons">remove_circle</div>
                DISABLE USER
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  editUserDialog: Users.selectors.editUserDialog(state),
  users: Users.selectors.users(state),
  reviewerDropdowns: Users.selectors.reviewerDropdowns(state),
  assignedRoles: User.selectors.roles(state),
  authProvider: User.selectors.authProvider(state),
  userId: User.selectors.userId(state),
})

const mapDispatchToProps = {
  setEditUserField: Users.setEditUserField,
  updateUser: Users.updateUser,
  getReviewees: Users.getReviewees,
  setDisableUserDialogOpen: Users.setDisableUserDialogOpen,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditUser);
