import dayjs from 'dayjs'
import qs from 'qs'
import { __, assoc, mergeRight, compose, curryN, map, filter, path, assocPath } from 'ramda'
import { sortBy } from 'lodash'
import { createSnackNotification, AlertLevel } from '@components/common'
import { assignRoute } from '@utility/assignRoute'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import migrateForm from '@utility/migrateForm'
import { selectors as FormSelectors } from './form'
import { Status, UserNamespace, ReviewNamespace, ReviewFilterValues, FormNamespace } from './constants'

export const SET_USER_FORMS = `${ReviewNamespace}/SET_USER_FORMS`
export const SET_PAGE_META = `${ReviewNamespace}/SET_PAGE_META`
export const SET_PAGINATION = `${ReviewNamespace}/SET_PAGINATION`
export const CHECK_REVIEW_ROW = `${ReviewNamespace}/CHECK_REVIEW_ROW`
export const SELECT_ALL_REVIEW_ROWS = `${ReviewNamespace}/SELECT_ALL_REVIEW_ROWS`
export const SET_EXPANDED_ROW = `${ReviewNamespace}/SET_EXPANDED_ROW`
export const SET_REVIEW_TIME_SPENT = `${ReviewNamespace}/SET_REVIEW_ROW_TIME_SPENT`
export const SET_USER_FORM_SEARCH_DESCRIPTION = `${ReviewNamespace}/SET_USER_FORM_SEARCH_DESCRIPTION`
export const SET_USER_FORM_STOP_DESCRIPTION = `${ReviewNamespace}/SET_USER_FORM_STOP_DESCRIPTION`
export const SET_OFFICER = `${ReviewNamespace}/SET_OFFICER`
export const SET_REVIEW_DIALOG_OPEN = `${ReviewNamespace}/SET_REVIEW_DIALOG_OPEN`
export const SET_SEARCH = `${ReviewNamespace}/SET_SEARCH`
export const SET_LOADING_USER_FORMS = `${ReviewNamespace}/SET_LOADING_USER_FORMS`
export const SET_SORT = `${ReviewNamespace}/SET_SORT`
export const TOGGLE_FILTER = `${ReviewNamespace}/TOGGLE_FILTER`
export const SET_LOADING_USERS = `${ReviewNamespace}/SET_LOADING_USERS`
export const SET_OFFICERS = `${ReviewNamespace}/SET_OFFICERS`
export const SET_STATISTICS = `${ReviewNamespace}/SET_STATISTICS`
export const SET_REVIEW_FEEDBACK = `${ReviewNamespace}/SET_REVIEW_FEEDBACK`
export const SET_REVIEW_FEEDBACK_DIALOG_OPEN = `${ReviewNamespace}/SET_REVIEW_FEEDBACK_DIALOG_OPEN`
export const SET_DOJ_ERRORS_DIALOG_OPEN = `${ReviewNamespace}/SET_DOJ_ERRORS_DIALOG_OPEN`
export const SET_USER_FORM_LOCATION = `${ReviewNamespace}/SET_USER_FORM_LOCATION`
export const SET_CUSTOM_QUESTIONS = `${ReviewNamespace}/SET_CUSTOM_QUESTIONS`
export const SET_REMOVED_FORMS_SELECTED = `${ReviewNamespace}/SET_REMOVED_FORMS_SELECTED`
export const SET_HAS_EDIT = `${ReviewNamespace}/SET_HAS_EDIT`

const INITIAL_STATE = {
  loading: {
    getUserForms: false,
    getUsers: false,
  },
  reviewDialogId: -1,
  reviewDialogOpen: false,
  officerSelect: 'all',
  selectAllChecked: false,
  expandedRow: -1,
  checkedReviewRows: [],
  dojErrorsDialogOpen: false,
  dojErrorsDialogContent: '',
  dojErrorsDialogFormId: -1,
  reviewFeedbackDialog: {
    open: false,
    reviewFeedback: '',
    formId: '-1',
  },
  pagination: {
    totalPages: 1,
    totalCount: 10,
    currentPage: 1,
    pageSize: 10,
  },
  searchAndFilter: {
    search: undefined,
    filterSelected: [], // Put here a default like [Filters.UnderReview]
    sort: {
      column: undefined,
      direction: undefined,
    },
  },
  statistics: {
    draftStatus: 0,
    flaggedForms: 0,
    todayForms: 0,
    underReviewStatus: 0,
    rejectedStatus: 0,
    deniedByDojStatus: 0,
    approvedStatus: 0,
    submittedToDojStatus: 0,
  },
  userForms: [],
  officers: [],
  removedFormsSelected: false,
  customQuestions: {},
  hasEdit: '',
}

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_REVIEW_DIALOG_OPEN:
      return mergeRight(state, { reviewDialogId: payload.formId, reviewDialogOpen: payload.open })
    case SET_OFFICER:
      return assoc('officerSelect', payload.userId, state)
    case SET_EXPANDED_ROW:
      return assoc('expandedRow', payload, state)
    case SET_REVIEW_TIME_SPENT: {
      const formIndex = state.userForms.findIndex((f) => f.id === payload.id)
      return assocPath(
        ['userForms', formIndex, 'contents'],
        {
          ...state.userForms[formIndex].contents,
          reviewerTimeSpent: payload.reviewerTimeSpent,
          reviewerId: payload.reviewerId,
        },
        state
      )
    }
    case SELECT_ALL_REVIEW_ROWS:
      return assoc('selectAllChecked', !state.selectAllChecked, state)
    case SET_USER_FORMS:
      return assoc('userForms', payload.userForms, state)
    case SET_USER_FORM_LOCATION: {
      const formIndex = state.userForms.findIndex((f) => f.id === payload.id)
      return {
        ...assocPath(['userForms', formIndex, 'contents', 'location'], payload.value, state),
        hasEdit: 'reviewer_edit_location',
      }
    }
    case SET_USER_FORM_SEARCH_DESCRIPTION: {
      const formIndex = state.userForms.findIndex((f) => f.id === payload.id)
      return {
        ...assocPath(['userForms', formIndex, 'contents', 'person', payload.personIndex, 'searchDescription'], payload.value, state),
        hasEdit: 'reviewer_edit_search_desc',
      }
    }
    case SET_USER_FORM_STOP_DESCRIPTION: {
      const formIndex = state.userForms.findIndex((f) => f.id === payload.id)
      return {
        ...assocPath(['userForms', formIndex, 'contents', 'person', payload.reasonIndex, 'stopDescription'], payload.value, state),
        hasEdit: 'reviewer_edit_stop_desc',
      }
    }
    case SET_PAGE_META:
      return mergeRight(state, {
        pagination: {
          ...state.pagination,
          totalPages: Number(payload.totalPages),
          totalCount: Number(payload.totalCount),
        },
      })
    case SET_PAGINATION:
      return mergeRight(state, {
        pagination: {
          ...state.pagination,
          currentPage: Number(payload.currentPage),
          pageSize: Number(payload.pageSize),
        },
      })
    case CHECK_REVIEW_ROW: {
      const { checkedReviewRows } = state
      if (!payload.checked) {
        checkedReviewRows.splice(
          checkedReviewRows.findIndex((r) => r === payload.id),
          1
        )
      } else if (!checkedReviewRows.includes(payload.id)) {
        checkedReviewRows.push(payload.id)
      }
      return mergeRight(state, { checkedReviewRows })
    }
    case SET_SORT:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, sort: payload } })
    case SET_SEARCH:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, search: payload.search } })
    case TOGGLE_FILTER: {
      const {
        searchAndFilter: { filterSelected },
      } = state
      const hasFilter = payload.filterIndex === filterSelected?.[0]
      return assocPath(['searchAndFilter', 'filterSelected'], hasFilter ? [] : [payload.filterIndex], state)
    }
    case SET_LOADING_USER_FORMS:
      return mergeRight(state, { loading: { ...state.loading, getUserForms: payload.isLoading } })
    case SET_LOADING_USERS:
      return mergeRight(state, { loading: { ...state.loading, getUsers: payload.isLoading } })
    case SET_OFFICERS:
      return assoc('officers', payload.officers, state)
    case SET_STATISTICS:
      return mergeRight(state, { statistics: { ...payload } })
    case SET_REVIEW_FEEDBACK:
      return mergeRight(state, {
        reviewFeedbackDialog: {
          ...state.reviewFeedbackDialog,
          reviewFeedback: payload.reviewFeedback,
        },
      })
    case SET_REVIEW_FEEDBACK_DIALOG_OPEN:
      return mergeRight(state, {
        reviewFeedbackDialog: {
          ...state.reviewFeedbackDialog,
          reviewFeedback: '',
          open: payload.open,
          isReadOnly: payload.isReadOnly,
          formId: payload.formId ?? state.reviewFeedbackDialog.formId,
          reviewer_notes: payload.open ? state.userForms.find((uf) => uf.id === payload.formId)?.reviewer_notes : {}
        },
      })
    case SET_DOJ_ERRORS_DIALOG_OPEN:
      return mergeRight(state, {
        dojErrorsDialogOpen: payload.open,
        dojErrorsDialogContent: payload.formId ? state.userForms.find((uf) => uf.id === payload.formId)?.doj_notes : state.dojErrorsDialogContent,
        dojErrorsDialogFormId: payload.formId ? payload.formId : state.dojErrorsDialogFormId,
      })
    case SET_CUSTOM_QUESTIONS:
      return mergeRight(state, {
        customQuestions: payload.customQuestions,
      })
    case SET_REMOVED_FORMS_SELECTED:
      return assoc('removedFormsSelected', payload, state)
    case SET_HAS_EDIT:
      return assoc('hasEdit', payload.hasEdit, state)
    default:
      return state
  }
}

export const setHasEdit = (hasEdit) => ({
  type: SET_HAS_EDIT,
  payload: { hasEdit },
})

export const setRemovedFormsSelected = (selected) => ({
  type: SET_REMOVED_FORMS_SELECTED,
  payload: selected,
})

export const toggleFilter = (filterIndex) => ({
  type: TOGGLE_FILTER,
  payload: { filterIndex },
})

export const setReviewDialogOpen = ({ open, formId }) => ({
  type: SET_REVIEW_DIALOG_OPEN,
  payload: { open, formId },
})

export const setOfficer = (userId) => ({
  type: SET_OFFICER,
  payload: { userId },
})

export const setUserFormStopDescription = ({ id, value, reasonIndex }) => ({
  type: SET_USER_FORM_STOP_DESCRIPTION,
  payload: { id, value, reasonIndex },
})

export const setUserFormSearchDescription = ({ id, value, personIndex }) => ({
  type: SET_USER_FORM_SEARCH_DESCRIPTION,
  payload: { id, value, personIndex },
})

export const setUserFormLocation = ({ id, value }) => ({
  type: SET_USER_FORM_LOCATION,
  payload: { id, value },
})

export const selectAllReviewRows = () => ({
  type: SELECT_ALL_REVIEW_ROWS,
  payload: {},
})

export const setExpandedRow = (rowId) => ({
  type: SET_EXPANDED_ROW,
  payload: rowId,
})

export const checkReviewRow = ({ id, checked }) => ({
  type: CHECK_REVIEW_ROW,
  payload: { id, checked },
})

export const setReviewTimeSpent = ({ id, reviewerTimeSpent, reviewerId }) => ({
  type: SET_REVIEW_TIME_SPENT,
  payload: { id, reviewerTimeSpent, reviewerId },
})

export const setUserForms = (userForms) => ({
  type: SET_USER_FORMS,
  payload: { userForms },
})

export const setPageMeta = ({ totalPages, totalCount }) => ({
  type: SET_PAGE_META,
  payload: { totalPages, totalCount },
})

export const setPagination = ({ currentPage, pageSize }) => ({
  type: SET_PAGINATION,
  payload: { currentPage, pageSize },
})

export const setSort = ({ column, direction }) => ({
  type: SET_SORT,
  payload: { column, direction },
})

export const setSearch = (search) => ({
  type: SET_SEARCH,
  payload: { search },
})

export const setLoadingUserForms = (isLoading) => ({
  type: SET_LOADING_USER_FORMS,
  payload: { isLoading },
})

export const setLoadingUsers = (isLoading) => ({
  type: SET_LOADING_USERS,
  payload: { isLoading },
})

export const setOfficers = (officers) => ({
  type: SET_OFFICERS,
  payload: { officers },
})

export const setStatistics = (stats) => ({
  type: SET_STATISTICS,
  payload: { ...stats },
})

export const setReviewFeedback = (reviewFeedback) => ({
  type: SET_REVIEW_FEEDBACK,
  payload: { reviewFeedback },
})

export const setReviewFeedbackDialogOpen = ({ open, formId, isReadOnly }) => ({
  type: SET_REVIEW_FEEDBACK_DIALOG_OPEN,
  payload: { open, formId, isReadOnly },
})

export const setDojErrorsDialogOpen = ({ open, formId }) => ({
  type: SET_DOJ_ERRORS_DIALOG_OPEN,
  payload: { open, formId },
})

export const setCustomQuestions = (customQuestions) => ({
  type: SET_CUSTOM_QUESTIONS,
  payload: { customQuestions },
})

export const versionFormForReview =
  ({ id }) =>
    (dispatch, getState, { http }) => {
      const { hasEdit } = getState()[ReviewNamespace]
      if (hasEdit !== '') {
        decoupledDispatch('Form.versionForm', { id, type: hasEdit })
        dispatch(setHasEdit(''))
      }
    }

export const getOrgCustomQuestions =
  (orgId) =>
    (dispatch, getState, { http }) =>
      http
        .get(`/organizations/${orgId}/custom_questions`)
        .then(({ data }) => {
          if (data?.questions) {
            dispatch(setCustomQuestions(data.questions))
          }
        })
        .catch(httpErrorHandler('Failed to get org questions'))

export const getStats =
  ({ relation = 'reviewees', searchAndFilter = {}, officerSelect, reviewerSelection } = {}) =>
    (dispatch, _, { http }) => {
      const { search } = searchAndFilter
      const query = qs.stringify(
        {
          user_id: officerSelect === 'removed' ? undefined : officerSelect === 'all' ? undefined : officerSelect,
          reviewer_id: reviewerSelection === 'all' ? undefined : reviewerSelection,
          deleted: officerSelect === 'removed' ? true : undefined,
          relation,
          search,
        },
        { arrayFormat: 'brackets' }
      )
      return http
        .get(`/statistics/forms?${query}`)
        .then(
          ({
            data: {
              statistics: {
                draft_status,
                flagged_forms,
                today_forms,
                submission_error_from_doj_status,
                submitted_to_doj_status,
                denied_by_doj_status,
                rejected_status,
                approved_status,
                under_review_status,
              },
            },
          }) => {
            dispatch(
              setStatistics({
                draftStatus: draft_status,
                flaggedForms: flagged_forms,
                todayForms: today_forms,
                underReviewStatus: under_review_status,
                rejectedStatus: rejected_status,
                approvedStatus: approved_status,
                deniedByDojStatus: denied_by_doj_status,
                submittedToDojStatus: submitted_to_doj_status + submission_error_from_doj_status,
              })
            )
          }
        )
        .catch(httpErrorHandler('Failed to get form stats'))
    }

export const createNewForm =
  () =>
    (_, getState, { http }) =>
      http
        .post('/form_data', { status: Status.Draft, contents: getState()[ReviewNamespace] }, {})
        .then(({ data: { id } }) => assignRoute(`/new-report/${id}`))
        .catch(httpErrorHandler('Failed to create form'))

export const getAllOfficers =
  () =>
    (dispatch, getState, { http }) => {
      const { user } = getState()[UserNamespace]
      dispatch(setLoadingUsers(true))
      const query = qs.stringify(
        {
          simple: true,
          page: 1,
          limit: 99999,
          order: 'last_name',
          dir: 'asc',
          reviewer_id: user?.id,
        },
        { arrayFormat: 'brackets' }
      )

      return http
        .get(`/users?${query}`)
        .then(({ data }) => {
          if (data.users) {
            dispatch(setOfficers(data.users))
          }
        })
        .catch(httpErrorHandler('Failed to get officers'))
        .finally(() => {
          setTimeout(() => dispatch(setLoadingUsers(false)), 100)
        })
    }

export const getAllUserForms =
  ({ officerSelect, reviewerSelection, startDate, endDate, status, filterType }) =>
    (dispatch, _, { http }) => {
      dispatch(setLoadingUserForms(true))
      const requestData = {
          page: 1,
          limit: 99999,
          user_id: officerSelect === 'removed' ? undefined : officerSelect === 'all' ? undefined : officerSelect,
          reviewer_id: reviewerSelection === 'all' ? undefined : reviewerSelection,
          start_time: startDate,
          end_time: endDate,
          status,
          deleted: officerSelect === 'removed' ? true : undefined,
          relation: 'all',
        }

      if (filterType === 'stop') {
        requestData.stop_start_time = startDate;
        delete requestData.start_time;
        requestData.stop_end_time = endDate;
        delete requestData.end_time;
      }

      const query = qs.stringify(requestData, { arrayFormat: 'brackets' })
      return http
        .get(`/form_data?${query}`)
        .then(({ data }) => {
          if (data.forms) {
            dispatch(setUserForms(data.forms))
          }
        })
        .catch(httpErrorHandler('Failed to get forms'))
        .finally(() => {
          setTimeout(() => dispatch(setLoadingUserForms(false)), 100)
        })
    }

export const getUserForms =
  ({ searchAndFilter, pagination, officerSelect, reviewerSelection }, startDate, endDate) =>
    (dispatch, _, { http }) => {
      dispatch(setLoadingUserForms(true))
      const { currentPage, pageSize } = pagination
      const {
        search,
        filterSelected,
        sort: { column, direction },
      } = searchAndFilter
      let formField
      switch (column) {
        case 'location':
          formField = 'location'
          break
        case 'time':
          formField = 'stopDateTime'
          break
        case 'status':
        default:
          formField = undefined
          break
      }

      const query = qs.stringify(
        {
          page: currentPage,
          limit: pageSize,
          form_fields: [formField],
          user_id: officerSelect === 'removed' ? undefined : officerSelect === 'all' ? undefined : officerSelect,
          reviewer_id: reviewerSelection === 'all' ? undefined : reviewerSelection,
          start_time: startDate,
          end_time: endDate,
          relation: 'reviewees',
          deleted: officerSelect === 'removed' ? true : undefined,
          status: ReviewFilterValues?.[filterSelected?.[0]],
          order: column === 'status' ? 'status' : undefined,
          dir: direction,
          search,
        },
        { arrayFormat: 'brackets' }
      )

      return http
        .get(`/form_data?${query}`)
        .then(({ data }) => {
          if (data.forms && data.pagination) {
            data.forms.forEach((form, index, forms) => (forms[index] = migrateForm(form)))
            dispatch(setUserForms(data.forms))
            dispatch(setPageMeta({ totalPages: Number(data.pagination.pages), totalCount: Number(data.pagination.count) }))
            dispatch(setPagination({ currentPage: Number(data.pagination.page), pageSize: Number(data.pagination.items) }))
          }
        })
        .catch(httpErrorHandler('Failed to get forms'))
        .finally(() => {
          setTimeout(() => dispatch(setLoadingUserForms(false)), 100)
        })
    }

export const updateForm =
  ({ id, updates }) =>
    (_, getState, { http }) => {
      const { contents, status } = getState()[ReviewNamespace]?.userForms?.find((f) => f.id === id) ?? {}
      if (contents && status) {
        return http.patch(`/form_data/${id}`, { contents: { ...contents, ...updates }, status }).catch(httpErrorHandler('Failed to update form'))
      }
    }

export const addReviewTimeSpent =
  ({ id, addTimeSec, reviewerId }) =>
    (dispatch, getState, { http }) => {
      const { contents, status, deleted_at } = getState()[ReviewNamespace]?.userForms?.find((f) => f.id === id) ?? {}
      if (contents && !deleted_at && status === Status.UnderReview) {
        return http
          .patch(`/form_data/${id}`, {
            contents: {
              ...contents,
              reviewerTimeSpent: contents.reviewerTimeSpent + addTimeSec,
              reviewerId,
            },
            status,
          })
          .then(() => {
            dispatch(
              setReviewTimeSpent({
                id,
                reviewerTimeSpent: contents.reviewerTimeSpent + addTimeSec,
                reviewerId,
              })
            )
          })
          .catch(httpErrorHandler('Failed to update form'))
      }
    }

export const approveReport =
  ({ id }) =>
    (dispatch, getState, { http }) => {
      const { contents } = getState()[ReviewNamespace].userForms.find((f) => f.id === id)
      const {
        user: { id: reviewerId },
      } = getState()[UserNamespace]

      dispatch(setReviewDialogOpen({ formId: -1, open: false }))

      return http
        .patch(`/form_data/${id}`, { contents: { ...contents, reviewerId }, status: Status.Approved })
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]

          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Form approved.')
        })
        .catch(httpErrorHandler('Failed to update form status'))
    }

export const removeReport =
  ({ id }) =>
    (dispatch, getState, { http }) => {
      dispatch(setReviewDialogOpen({ formId: -1, open: false }))

      return http
        .delete(`/form_data/${id}`)
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]

          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Form removed.')
          decoupledDispatch('Form.versionForm', { id, type: 'removed_by_reviewer' })
        })
        .catch(httpErrorHandler('Failed to update form status'))
    }

export const activateReport =
  ({ id }) =>
    (dispatch, getState, { http }) => {
      dispatch(setReviewDialogOpen({ formId: -1, open: false }))

      return http
        .patch(`/form_data/${id}/recover`)
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]

          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Form reactivated.')
          decoupledDispatch('Form.versionForm', { id, type: 'activated_by_reviewer' })
        })
        .catch(httpErrorHandler('Failed to reactivate form'))
    }

export const denyReport =
  (id) =>
    (dispatch, getState, { http }) => {
      const { contents } = getState()[ReviewNamespace].userForms.find((f) => f.id === id)
      const {
        user: { id: reviewerId },
      } = getState()[UserNamespace]
      dispatch(setReviewDialogOpen({ formId: -1, open: false }))

      return http
        .patch(`/form_data/${id}`, { contents: { ...contents, reviewerId }, status: Status.Rejected })
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]
          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Form rejected.')
        })
        .catch(httpErrorHandler('Failed to update form status'))
    }

export const bulkApproveReports =
  () =>
    (dispatch, getState, { http }) => {
      const { checkedReviewRows } = getState()[ReviewNamespace]
      const form_ids = getState()[ReviewNamespace].userForms.filter(({ id, status }) => checkedReviewRows.includes(id) && Status.UnderReview === status)
        .map(({ id }) => id)
      if (form_ids.length > 0) {
        // API returns 500 when array is empty
        return http
          .patch('/bulk_update_form_data', { form_ids, status: 'approved' })
          .then(() => {
            const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]
            dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
            dispatch(getStats({ searchAndFilter, officerSelect }))
            createSnackNotification(AlertLevel.Success, 'Success', 'Forms approved.')
          })
          .catch(httpErrorHandler('Failed to approve reports'))
      }
      createSnackNotification(AlertLevel.Info, 'No action', 'Forms must be in review status to be approved.')
    }

export const bulkDenyReports =
  () =>
    (dispatch, getState, { http }) => {
      const { checkedReviewRows } = getState()[ReviewNamespace]
      const form_ids = getState()[ReviewNamespace].userForms.filter(({ id, status }) => checkedReviewRows.includes(id) && [Status.UnderReview, Status.DeniedByDOJ].includes(status))
        .map(({ id }) => id)
      if (form_ids.length > 0) {
        // API returns 500 when array is empty
        return http
          .patch('/bulk_update_form_data', { form_ids, status: 'rejected' })
          .then(() => {
            const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]
            dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
            dispatch(getStats({ searchAndFilter, officerSelect }))
            createSnackNotification(AlertLevel.Success, 'Success', 'Forms rejected.')
          })
          .catch(httpErrorHandler('Failed to reject reports'))
      }
      createSnackNotification(AlertLevel.Info, 'No action', 'Forms must be in review or denied status to be rejected.')
    }

export const submitReviewFeedback =
  () =>
    (dispatch, getState, { http }) => {
      const { formId, reviewFeedback } = getState()[ReviewNamespace]?.reviewFeedbackDialog
      const { reviewer_notes } = getState()[ReviewNamespace].userForms.find((f) => f.id === formId)
      const { user: { first_name, last_name, officer_id } } = getState()[UserNamespace];

      return http
        .patch(`/form_data/${formId}`, {
          reviewer_notes: {
            noteHistory: [
              { reviewFeedback, reviewer: { first_name, last_name, officer_id }, date: dayjs().toISOString() },
              ...reviewer_notes?.noteHistory ?? []
            ]
          },
          status: Status.Rejected
        })
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]
          dispatch(setReviewFeedbackDialogOpen({ open: false }))
          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Feedback submitted.')
        })
        .catch(httpErrorHandler('Failed to update form'))
    }

export const submitToDoj =
  ({ id }) =>
    (dispatch, getState, { http }) =>
      http.patch('/submit_to_doj', { form_ids: [id] })
        .then(() => {
          const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]
          dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
          dispatch(getStats({ searchAndFilter, officerSelect }))
          dispatch(setReviewDialogOpen({ formId: -1, open: false }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Form submitted.')
        })
        .catch(httpErrorHandler('Failed to submit form'))

export const bulkSubmitToDoj =
  () =>
    (dispatch, getState, { http }) => {
      const { checkedReviewRows, selectAllChecked } = getState()[ReviewNamespace]
      const form_ids = getState()[ReviewNamespace].userForms.filter(
        ({
          id,
          status,
          //  }) => checkedReviewRows.includes(id) && ![Status.SubmittedToDOJ, Status.SubmittingToDOJ, Status.Draft].includes(status))
        }) => checkedReviewRows.includes(id) && [Status.Approved].includes(status)
      )
        .map(({ id }) => id)

      if (form_ids.length > 0) {
        // API returns 500 when array is empty
        return http
          .patch('/submit_to_doj', { form_ids })
          .then(() => {
            const { searchAndFilter, pagination, officerSelect } = getState()[ReviewNamespace]

            /* Unselect all ReviewRows */
            if (selectAllChecked) {
              dispatch(selectAllReviewRows())
            }
            window.dispatchEvent(new CustomEvent('select-all-review-rows', { detail: { selectAllChecked: false } }))
            dispatch(getUserForms({ searchAndFilter, pagination, officerSelect }))
            dispatch(getStats({ searchAndFilter, officerSelect }))
            createSnackNotification(AlertLevel.Success, 'Success', 'Forms submitted.')
          })
          .catch(httpErrorHandler('Failed to submit forms'))
      }
      return createSnackNotification(AlertLevel.Info, 'No action', 'Only approved forms can be submitted.')
    }

export const setOfficerAndSearch =
  (userId) =>
    (dispatch, getState, { http }) => {
      const { searchAndFilter, pagination, officerSelect } = reducer(getState()[ReviewNamespace], setOfficer(userId))
      dispatch(setPagination({ currentPage: 1, pageSize: INITIAL_STATE.pagination.pageSize }))
      dispatch(setOfficer(userId))
      dispatch(getStats({ searchAndFilter, officerSelect }))
      dispatch(setRemovedFormsSelected(false))
      return getUserForms({
        searchAndFilter,
        pagination: { ...pagination, currentPage: 1 },
        officerSelect
      })(dispatch, getState, { http })
    }

export const toggleFilterAndSearch =
  (filterIndex) =>
    (dispatch, getState, { http }) => {
      const { searchAndFilter, pagination, officerSelect } = reducer(getState()[ReviewNamespace], toggleFilter(filterIndex))
      if (reducer(getState()[ReviewNamespace], 'removedFormsSelected')) {
        dispatch(setRemovedFormsSelected(false))
      }
      dispatch(toggleFilter(filterIndex))
      dispatch(setPagination({ currentPage: 1, pageSize: INITIAL_STATE.pagination.pageSize }))
      return getUserForms({
        searchAndFilter,
        pagination: { ...pagination, currentPage: 1 },
        officerSelect,
      })(dispatch, getState, { http })
    }

export const setSortAndSearch =
  ({ column, direction }) =>
    (dispatch, getState, { http }) => {
      const { searchAndFilter, pagination, officerSelect } = reducer(
        getState()[ReviewNamespace],
        setSort({
          column,
          direction,
        })
      )
      dispatch(setSort({ column, direction }))
      return getUserForms({ searchAndFilter, pagination, officerSelect })(dispatch, getState, { http })
    }

export const exportCSV =
  ({ startDate, endDate, status, exportType }) =>
    (_dispatch, _getState, { http }) => {
      const query = qs.stringify(
        {
          start_time: startDate,
          end_time: endDate,
          status,
          exportType,
          relation: 'all',
        },
        { arrayFormat: 'brackets' }
      )

      const newLocation = http.buildUrl(`/form_data.csv?${query}`)
      window.open(newLocation, '_blank')
    }

export const selectors = {
  hasEdit: path([ReviewNamespace, 'hasEdit']),
  dojErrorsDialogOpen: path([ReviewNamespace, 'dojErrorsDialogOpen']),
  dojErrorsDialogContent: path([ReviewNamespace, 'dojErrorsDialogContent']),
  dojErrorsDialogFormId: path([ReviewNamespace, 'dojErrorsDialogFormId']),
  statistics: path([ReviewNamespace, 'statistics']),
  loading: path([ReviewNamespace, 'loading']),
  searchAndFilter: path([ReviewNamespace, 'searchAndFilter']),
  officerSelect: path([ReviewNamespace, 'officerSelect']),
  selectAllChecked: path([ReviewNamespace, 'selectAllChecked']),
  expandedRow: path([ReviewNamespace, 'expandedRow']),
  checkedReviewRows: path([ReviewNamespace, 'checkedReviewRows']),
  reviewDialogOpen: path([ReviewNamespace, 'reviewDialogOpen']),
  reviewDialogId: path([ReviewNamespace, 'reviewDialogId']),
  reviewFeedbackDialog: path([ReviewNamespace, 'reviewFeedbackDialog']),
  customQuestions: path([ReviewNamespace, 'customQuestions']),
  removedFormsSelected: path([ReviewNamespace, 'removedFormsSelected']),
  hasCheckedRows: compose((r) => r.length > 0, path([ReviewNamespace, 'checkedReviewRows'])),
  pagination: path([ReviewNamespace, 'pagination']),
  underReviewReports: compose(
    (a) => a.length,
    filter(({ status }) => status === Status.UnderReview),
    path([ReviewNamespace, 'userForms'])
  ),
  reportsToday: compose(
    (a) => a.length,
    filter(({ created_at }) => dayjs(created_at).format('YYYYMMDD') === dayjs().format('YYYYMMDD')),
    filter(({ status }) => status !== Status.Draft),
    path([ReviewNamespace, 'userForms'])
  ),
  rejectedReports: compose(
    (a) => a.length,
    filter(({ status }) => status === Status.Rejected),
    path([ReviewNamespace, 'userForms'])
  ),
  submittedReports: compose(
    (a) => a.length,
    filter(({ status }) => status === Status.Approved),
    path([ReviewNamespace, 'userForms'])
  ),
  userForms: path([ReviewNamespace, 'userForms']),
  reportData: compose(
    map(({ id, user, contents, status, created_at, updated_at, user_id, deleted_at }) => ({
      id,
      status,
      created_at,
      updated_at,
      deleted_at,
      user_id,
      officer: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim(),
      location: FormSelectors.locationString({ [FormNamespace]: contents }),
      assignment: contents?.assignment ?? '',
      district: contents?.district ?? '',
      beat: contents?.beat ?? '',
      time: contents?.stopDateTime ? dayjs(contents?.stopDateTime) : '',
      school: contents?.school ?? '',
      testcase2024: contents?.flags?.is2024Test ?? false,
    })),
    path([ReviewNamespace, 'userForms'])
  ),
  officerDropdown: compose(
    map(({ id, first_name, last_name }) => ({
      id: String(id),
      name: `${last_name}, ${first_name}`,
    })),
    curryN(2, sortBy)(__, ['last_name', 'first_name']),
    path([ReviewNamespace, 'officers'])
  ),
}
