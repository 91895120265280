import { LocalforageSafariFix } from '@utility/createInstanceSafariFix'
import HttpClient from '../dependencies/httpClient';

export interface Person {
  label: string;
  gender: string;
  nonConforming: boolean;
  age: number;
  student: boolean;
  race: string[];
  lgbt: boolean;
  sexualOrientation: string;
  english: boolean;
  disabled: boolean;
  unhoused: boolean;
  disabilities: string[];
  actionTaken: string[];
  actionTakenBool: true;
  consentGiven: {
    [searchPersonOrProperty: string]: boolean;
  };
  consentType: string;
  searchBasis: string[];
  searchDescription: string;
  seizedPropertyBasis: string[];
  seizedPropertyType: string[];
  anyContrabandFound: true;
  anyResultOfStop: true;
  contraband: string[];
  resultOfStop: string[];
  resultOfStopCodeSection: {
    [codeCategory: string]: string[] | string;
  };
  contrabandOrEvidenceDiscovered: true;
  primaryReason: string;
  reasonGivenStoppedPerson: string[];
  trafficViolation: string;
  trafficViolationCode: string;
  reasonableSuspicion: string[];
  reasonableSuspicionCode: string;
  probableCause: string[];
  probableCauseCode: string;
  codeSection48900: string;
  codeSection48900Subdivision: string;
  stoppedPassenger: boolean;
  stoppedInsideResidence: boolean;
  stopDescription: string;
}

export interface Form {
  debug?: {
    creation?: {
      buildDetails?: {
        hash?: string;
        hashdate?: string;
        builddate?: string;
      };
      onlineCount: number;
      offlineCount: number;
    };
    reset?: {
      buildDetails?: {
        hash?: string;
        hashdate?: string;
        builddate?: string;
      };
      onlineCount: number;
      offlineCount: number;
    };
    update?: {
      buildDetails?: {
        hash?: string;
        hashdate?: string;
        builddate?: string;
      };
      onlineCount: number;
      offlineCount: number;
    };
    submitForReview?: {
      buildDetails?: {
        hash?: string;
        hashdate?: string;
        builddate?: string;
      };
      onlineCount: number;
      offlineCount: number;
    };
  };
  testCaseId?: string;
  createdAt?: string;
  flags: {
    pre2024: boolean;
    post2024: boolean;
    is2024Test: boolean;
  };
  version: string;
  id: string;
  status: string;
  recallStep: {
    maxStepReached: number;
    currentStep: number;
    currentSubstep: number;
    history: HistoryRecord[];
  };
  progress: number;
  yearsOfExperience: number;
  raceOfOfficer: string[];
  assignment: string;
  assignmentDescription: string;
  otherAssignment: string;
  typeOfAssignmentOfficer: string;
  stopDateTime: string;
  responseToCall: null;
  officerWorksWithNonPrimaryAgency: null;
  stopDuringWellnessCheck: null;
  duration: string;
  isCustomDuration: boolean;
  numberOfPeople: number;
  location: string;
  schoolAddress: string;
  gpsAddress: string;
  otherLocation: string;
  locationType: string;
  city: string;
  school: string;
  block: number | null;
  street: string;
  crossStreet1: string;
  crossStreet2: string;
  highway: string;
  exit: string;
  stopHappenedAtPublicSchool: boolean;
  useSameRaceForAll: boolean;
  useSameGenderForAll: boolean;
  useSamePrimaryReasonForAll: boolean;
  useSameActionTakenForAll: boolean;
  useSameActionForAllPersonIndex: number;
  person: Person[];
  validation: {
    stepErrors: any;
  };
  officerTimeSpent: number;
  officerTimeSpentCustom: number;
  reviewerTimeSpent: number;
  reviewerId: number;
  coordinates: {
    latitude: number;
    longitude: number;
    accuracy: number;
    available?: string;
  };
  custom: any;
  typeOfStop: string;
  offlineCreatedAt: string | null;
}

export interface UserForms {
  id: string;
  contents: Contents;
  status: string;
  doj_notes?: string;
}

export interface Contents {
  version: string;
  id: string;
  searchParams: string;
  status: string;
  recallStep: RecallStep;
  progress: number;
  assignment: string;
  stopDateTime: string;
  responseToCall: boolean;
  officerWorksWithNonPrimaryAgency: boolean;
  stopDuringWellnessCheck: boolean;
  duration: number;
  numberOfPeople: number;
  location: string;
  school: string;
  useSameRaceForAll: boolean;
  useSameDisabilityForAll: boolean;
  useSameGenderForAll: boolean;
  useSamePrimaryReasonForAll: boolean;
  useSameActionTakenForAll: boolean;
  useSameActionForAllPersonIndex: number;
  person: Person[];
  formLoading: boolean;
  anyResultOfStop: boolean;
}

export interface RecallStep {
  maxStepReached: number;
  currentStep: number;
  currentSubstep: number;
  history: HistoryRecord[];
}

export interface User {
  created_at: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  officer_id: string;
  organization_id: number;
  race: string[];
  roles: string[];
  updated_at?: string;
  username: string;
  years_of_experience: number;
  usage?: string;
  training_mode: boolean;
  theme: string;
  deleted_at?: string;
  reviewers?: User[];
  password?: string;
  confirmPassword?: string;
  'access_locked?'?: boolean;
}

export interface Statistics {
  draftStatus: number;
  flaggedForms: number;
  todayForms: number;
  underReviewStatus: number;
  rejectedStatus: number;
  deniedByDojStatus: number;
  approvedStatus: number;
  submittedToDojStatus: number;
}

export interface SearchAndFilter {
  search?: string;
  filterSelected?: number[];
  sort: {
    column?: string;
    direction?: string;
  };
}

export interface Pagination {
  totalPages?: number;
  totalCount?: number;
  currentPage?: number;
  pageSize?: number;
}

export interface StepErrors {
  password?: PasswordRequired;
  confirmPassword?: boolean;
  unconfirmedPassword?: boolean;
  stopDateTime?: number;
  duration?: number;
  school?: number;
  location?: number;
  city?: number;
  reasonableSuspicionCode?: number;
  trafficViolationCode?: number;
  'person.actionTaken'?: number;
  'person.consensualEncounter'?: number;
  'person.searchOfPerson'?: number;
}

interface PasswordRequired {
  hasEight?: boolean;
  hasNumber?: boolean;
  hasUppercase?: boolean;
  hasSpecial?: boolean;
  matching?: boolean;
}

export interface School {
  CDSCode: string;
  StatusType: string;
  County: string;
  District: string;
  School: string;
  Street: string;
  StreetAbr: string;
  City: string;
  Zip: string;
  State: string;
}

export interface Path {
  appPath?: string;
  appNodeModules?: string;
  appSrc?: string;
  appTsConfig?: string;
  appJsConfig?: string;
  appPackageJson?: string;
  swSrc?: string;
  publicUrlOrPath?: string;
  appIndex?: string;
  moduleFileExtensions?: string;
  appHtml?: string;
  appPublic?: string;
  proxySetup?: string;
  dotenv?: string;
  appBuild?: string;
  appIndexJs?: string;
  yarnLockFile?: string;
  testsSetup?: string;
  Dashboard?: any;
  GlobalConfiguration?: any;
  Organizations?: any;
  CustomQuestions?: any;
  NewReport?: any;
  NotAvailable?: any;
  Review?: any;
  Visualization?: any;
  Users?: any;
  ReviewUnavailable?: any;
}

export interface Loading {
  getUsers: boolean;
  addReviewers: boolean;
}

export interface Dialog {
  addUserDialogOpen: boolean;
  editUserDialogOpen: boolean;
  removingReviewerDialogOpen: boolean;
  disableUserDialogOpen: boolean;
  changePasswordDialogOpen: boolean;
}

export interface UserDialog {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  officerId: string;
  yearsOfExperience: string;
  isOfficerRole: boolean;
  isReviewerRole: boolean;
  isAnalystRole: boolean;
  isAdminRole: boolean;
  assignedReviewers: Person[];
  reviewees: Person[];
  password: string;
  changePassword: string;
}

export interface Action<P> {
  readonly type: string;
  readonly payload?: P;
}

export type ArrayValueKeys<T extends object> = {
  [K in keyof T]: T[K] extends Array<any> ? K : never
}[keyof T];

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

export type ActionCreator<P> = (params: P) => Action<P>;

export type Dispatch<P> = (action: Action<P>) => void;

export type DispatchAction<T extends Function> = (params: ArgumentTypes<T>[0]) => void;

export type DispatchThunk<T extends Function> = (params?: ArgumentTypes<T>[0]) => (dispatch: Dispatch<any>, getState: GetState, dependencies: ThunkDeps) => any;

export type GetState = () => {
  [namespace: string]: any;
};
export interface Step {
  title: string;
  loop?: {
    iterations: string;
  };
  substeps: Substep[];
}

export interface Substep {
  component: string;
  helperText?: string;
  warningText?: string;
  isCustom?: boolean;
  getProps?: (formParams: any) => any;
  hasMap?: (formParams: any) => boolean;
  precondition?: (formParams: any) => boolean;
  validations?: {
    [formKey: string]: Array<Validation>;
  };
}

export interface Snack {
  readonly level: {
    type: string;
    icon: string;
  };
  readonly title: string;
  readonly message: string;
}

export type HasError = (formParams: any, personIndex?: number) => boolean;

export interface Validation {
  hasError?: HasError;
  snack: Snack;
}

export interface HistoryRecord {
  currentSubstep: number;
  currentStep: number;
  currentSubloop: number;
  forceLastSubloop: number;
  currentComponent: string;
}

export interface nStep {
  nSubsteps: number;
  preconditions: (((formParams: any) => boolean) | undefined)[];
  loop?: {
    iterations: string;
  };
  title: string;
}

export interface Enum {
  csvColumn: {
    header: string;
    position: number;
  };
  defaultValue: any;
  description: string;
  jsonStructure: {
    Age: string;
  };
  type: string;
}

export type EnumMap = Array<Enum>

export interface Template {
  readonly answers: EnumMap;
  readonly created_at: string;
  readonly deleted_at: string;
  readonly id: number;
  readonly name: string;
  readonly updated_at: string;
  readonly version: string;
}

export interface CustomQuestion {
  readonly value?: string;
  readonly text?: string;
  readonly props: any;
  readonly helperText: string;
  readonly disabled: boolean;
  disabledDate: string | null;
  readonly required: boolean;
  order?: number;
  readonly id?: string;
  readonly title?: string;
  readonly component?: string;
  readonly key?: string;
}

export interface Organization {
  name: string;
  state: string;
  ori: string;
  auth_provider: string;
  auth_settings: Object;
  id?: number;
}

export interface CustomQuestionSection {
  key?: string;
  order?: number;
  title: string;
  questions: CustomQuestion[];
}

export interface CustomQuestionSections {
  all: {
    readonly questions: Array<CustomQuestion>;
    readonly title: string;
  };
  individual: {
    readonly questions: Array<CustomQuestion>;
    readonly title: string;
  };
  isLive: boolean;
}
export interface ThunkDeps {
  http: HttpClient;
  offlineTemplates: LocalforageSafariFix;
  offlineCustomQuestions: LocalforageSafariFix;
  offlineConfig: LocalforageSafariFix;
}

export interface Match {
  params: any;
  isExact: boolean;
  path: string;
  url: string;
}

export interface CSVReport {
  id: number;
  created_by_id: number;
  params: {
    start_time?: string;
    end_time?: string;
    stop_start_time?: string;
    stop_end_time?: string;
    exportType: string;
  };
  complete: boolean;
  // exportType: string;
  created_at: string;
}

export interface CSVReportRow {
  id: number;
  createdAt: string;
  createdId: number;
  startTime: string;
  endTime: string;
  exportType: string;
  status: boolean;
}

export interface ServerForm {
  id: number;
  contents: Form;
  status: string;
  created_at: string;
  updated_at: string;
  user_id: number;
  reviewer_notes: Record<string, string> | null;
  doj_notes: Record<string, string> | null;
  deleted_at: string | null;
  form_template_id: number;
  aiware_sdo_ids: string[];
  lastRoadblockOrDuiLocation: LastRoadblockOrDuiLocation | null;
  offlineCreatedAt: string | null;
}

export interface ServerCustomQuestions {
  id: number;
  organization_id: number;
  questions: {
    isLive: boolean;
    all: {
      title: string;
      questions: {
        id: string;
        props: {
          title: string;
          options: string[];
          resultPath: string;
          placeholder: string;
          autocomplete: boolean;
        };
        disabled: boolean;
        required: boolean;
        component: string;
        helperText: string;
        disabledDate: string;
      }[];
    };
    individual: {
      title: string;
      questions: {
        id: string;
        props: {
          title: string;
          options: string[];
          resultPath: string;
          placeholder: string;
          autocomplete: boolean;
        };
        disabled: boolean;
        required: boolean;
        component: string;
        helperText: string;
        disabledDate: string;
      }[];
    };
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface ServerOrganization {
  organization: {
    id: number;
    name: string;
    auth_provider: string;
    auth_settings?: Record<string, any>;
    logo_url: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    subdomain: string;
    ori: string;
    settings: {
      doj_production: boolean;
      offline_mode: boolean;
      state: 'CA' | 'MI';
    };
    aiware_org_id: string | number | null;
    offline_mode: boolean;
    state: 'CA' | 'MI';
    job_submission_schedule: Record<string, string>;
    can_submit_to_doj: boolean;
    small_logo: string | null;
    large_logo: string | null;
    login_background: string | null;
    sftp_credentials_set: boolean;
    pre2024: boolean;
    post2024: boolean;
  };
}

export interface ServerUser {
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  organization_id?: number;
  username: string;
  first_name: string;
  last_name: string;
  roles: string[];
  officer_id: string;
  deleted_at: string | null;
  years_of_experience: number;
  training_mode: boolean;
  theme: string;
  organization?: {
    id: number;
    name: string;
    auth_provider: string;
    offline_mode: boolean;
    state: 'CA' | 'MI';
  };
}

export interface ServerUsers {
  users?: {
    id: number;
    email: string;
    created_at: string;
    updated_at: string;
    organization_id: number;
    username: string;
    first_name: string;
    last_name: string;
    roles: string[];
    officer_id: string;
    deleted_at: string | null;
    years_of_experience: number | null;
    'access_locked?': boolean;
    reviewers: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  }[];
  pagination?: {
    count: number;
    page: number;
    items: number;
    pages: number;
  };
}

export interface ReviewFeedbackNote {
  reviewFeedback: string;
  reviewer: {
    first_name: string;
    last_name: string;
    officer_id: string;
  };
  date: string;
}

export interface ColumnData {
  grow?: any;
  dataKey?: any;
  width?: number;
  minWidth?: number;
}

export interface RowData {
  id?: any;
  data?: any;
  rowIndex?: number;
  status?: boolean;
  offlineStatus?: boolean;
}

export interface ColRef {
  clientWidth?: number;
  clientHeight?: number;
}
export interface UserSettingsDialog {
  open: boolean;
  trainingMode: boolean;
  yearsOfExperience: number;
  theme: string;
  raceOfOfficer: string[];
}

export interface UserProfile {
  race: string[];
}
export interface LastRoadblockOrDuiLocation {
  city: string;
  location: string;
  school: string;
  stopHappenedAtPublicSchool: boolean;
}

export interface PIITextResult {
  [id: string]: {
    text: string;
    matches: string[];
  };
}
