import sortAlphaNum from '@utility/sortAlphaNum'
import dayjs from 'dayjs'

const sortText = (key: string) => (rowA: any, rowB: any) => sortAlphaNum(rowA[key], rowB[key])

const sortTime = (key: string) => (rowA: any, rowB: any) => dayjs(rowA[key]) > dayjs(rowB[key]) ? 1 : -1

const sortNumber = (key: string) => (rowA: any, rowB: any) => rowA[key] > rowB[key] ? 1 : -1

const sortBool = (key: string) => (rowA: any, rowB: any) => rowA[key] === rowB[key] ? 1 : -1

export { sortText, sortTime, sortNumber, sortBool }
