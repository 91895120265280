import React from 'react';

const Ducks = require('@ducks');

interface Props {
  children: React.ReactNode;
  store: any;
}

const dispatchFunctions = Object.keys(Ducks).reduce((dispatchAcc, duckKey) => ({
  ...dispatchAcc,
  ...Object.keys(Ducks[duckKey])
    .filter(fnKey => typeof Ducks[duckKey][fnKey] === 'function' && !['reducer', 'default', 'getInitialState'].includes(fnKey))
    .reduce((fnAcc, fnKey) => ({ ...fnAcc, [`${duckKey}.${fnKey}`]: Ducks[duckKey][fnKey] }), {})
}), {} as any);

class DecoupledDispatchProvider extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener('decoupled-dispatch', this.decoupledDispatch);
  }

  componentWillUnmount() {
    window.removeEventListener('decoupled-dispatch', this.decoupledDispatch);
  }

  decoupledDispatch = (e: CustomEvent<{ dispatchFn: string; params?: any; params2?: any }>) => {
    const { store: { dispatch } } = this.props;
    const { dispatchFn, params, params2 } = e.detail;

    try {
      if (!dispatchFunctions[dispatchFn]) {
        console.error(`Dispatch for '${dispatchFn}' does not exist`);
        return;
      }
      if (params !== undefined && params2 !== undefined) {
        dispatch(dispatchFunctions[dispatchFn](params)(params2));
        return;
      }
      if (params !== undefined) {
        dispatch(dispatchFunctions[dispatchFn](params));
        return;
      }
      dispatch(dispatchFunctions[dispatchFn]());
    } catch (ex) {
      console.error(`Decoupled dispatch provider error for ${JSON.stringify({ dispatchFn, params, params2 })}: ${ex}`);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default DecoupledDispatchProvider;
