import { connect, ConnectedProps } from 'react-redux';
import { Form } from '@ducks';
import React from 'react';

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
});

const mapDispatchToProps = {
  onChange: (value: string | string[], resultPath: (string | number)[]) => Form.setFormField({ path: resultPath })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  title: string;
  skipText: string;
  options: any[];
  skip: boolean;
  onSkipChange?: (param: boolean) => void;
  setValidationErrors?: (param: any) => void;
  useSkip: boolean;
  resultPath: string[];
  selectBoxStyle?: React.CSSProperties;
  DynamicSelectFormStyle?: React.CSSProperties;
  goToNext?: () => void;
};
