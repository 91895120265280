import React from 'react'
import './Loading.scss'

const Loading = () => (
  <div className="lds-ellipsis" data-testid="loading">
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loading
