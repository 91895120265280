import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { range } from 'ramda'
import { Box, TextField } from '@material-ui/core';
import { Switch } from '@components/common'
import * as Form from '@ducks/form';
import cn from 'classnames'
import './RipaAgeForm.scss'
import { get, isEmpty } from 'lodash';
import { Template } from '@engine/ducks';

interface Props {
  labels: string[];
  numberOfPeople: number;
  setAge: (idx: number, value: number) => unknown;
  age: number[];
  setStudent: (idx: number, value: any) => unknown;
  student: boolean[];
  stopHappenedAtPublicSchool?: boolean;
  useSamePrimaryReasonForAll: boolean;
  setUseSamePrimaryReasonForAll: (value: boolean) => unknown;
  primaryReason: string[];
  setPrimaryReason: (value: string, personIndex: number) => unknown;
  ReasonsForStop: { [key: string]: { value: string; key: number } };
  setCodeSection48900: (value: string, personIndex: number) => unknown;
  setCodeSection48900Subdivision: (value: string, personIndex: number) => unknown;
}

const RipaAgeForm = ({ labels, numberOfPeople, setAge, age, setStudent, student, stopHappenedAtPublicSchool, useSamePrimaryReasonForAll, setUseSamePrimaryReasonForAll, primaryReason, setPrimaryReason, ReasonsForStop, setCodeSection48900, setCodeSection48900Subdivision }: Props) => {
  useEffect(() => {
    range(0, numberOfPeople).forEach(pi => {
      if (!age?.[pi]) {
        setAge(pi, 0)
      }
      if (!stopHappenedAtPublicSchool) {
        setStudent(pi, false)
      }
    })
  }, [])

  useEffect(() => {
    const hasFalseStudent = range(0, numberOfPeople).some(pi => student[pi] === false);
    if (hasFalseStudent) {
      if (useSamePrimaryReasonForAll) {
        setUseSamePrimaryReasonForAll(false);
        setPrimaryReason('', 0);
        setCodeSection48900('', 0);
        setCodeSection48900Subdivision('', 0);
      }
      if (!isEmpty(primaryReason)) {
        range(0, numberOfPeople).forEach(pi => {
          if (student[pi] === false) {
            if (primaryReason?.[pi]?.includes(ReasonsForStop?.PossibleDisciplineEducationCode48900?.value) ||
                primaryReason?.[pi]?.includes(ReasonsForStop?.DetermineStudentViolatedSchoolPolicy?.value)) {
                  setPrimaryReason('', pi);
                  setCodeSection48900('', pi);
                  setCodeSection48900Subdivision('', pi);
            }
          }
        })
      }
    }
  }, [student]);

  return (
    <div className="ripa-age-form" data-testid="ripa-age-form">
      <div className="ripa-age-form__title">What age were they perceived?</div>
      <Box display="flex" justifyContent="center">
        {numberOfPeople > 1 && (
          <div className="ripa-age-form__labels-header">
            <div className="ripa-age-form__labels-header-person">PERSON</div>
            <div
              className={cn('ripa-age-form__labels-header-age', {
                right: !stopHappenedAtPublicSchool,
              })}
            >
              AGE
            </div>
            {stopHappenedAtPublicSchool && (
              <div className="ripa-age-form__labels-header-english">
                <div>STUDENT AT THIS K-12 PUBLIC SCHOOL</div>
              </div>
            )}
          </div>
        )}
      </Box>

      <div className="ripa-age-form__labels-container">
        <div
          className={cn('ripa-age-form__labels', {
            'no-student': !stopHappenedAtPublicSchool && numberOfPeople > 1,
            'one-people': numberOfPeople === 1,
          })}
        >
          {range(0, numberOfPeople).map((_, i) => (
            <div
              className={cn('ripa-age-form__selection', {
                middle: numberOfPeople === 1 && !stopHappenedAtPublicSchool,
              })}
              key={`RipaAgeFormLabels-${i}`}
            >
              {numberOfPeople > 1 && (
                <div className="ripa-age-form__label">
                  <div className="material-icons">person</div>
                  <div className="ripa-age-form__label-text">
                    {labels[i] || i + 1}
                  </div>
                </div>
              )}
              <TextField
                className="ripa-age-form__age-control"
                variant="outlined"
                value={age[i] || ''}
                onChange={({ target: { value } }) =>
                  setAge(i, parseInt(value, 10))
                }
                inputProps={{
                  type: 'number',
                  min: 1,
                  max: 120,
                  inputMode: 'numeric',
                  'data-testid': `ripa-age-form-input-${i}`,
                }}
              />
              {stopHappenedAtPublicSchool && (
                <div className="ripa-age-form__student-switch-container">
                  <div className="ripa-age-form__student-text">
                    {numberOfPeople === 1
                      ? 'STUDENT AT THIS K-12 PUBLIC SCHOOL'
                      : 'STUDENT'}
                  </div>
                  <Switch
                    className="ripa-age-form__student-switch"
                    data-testid={`ripa-age-form-student-switch-${i}`}
                    onText="YES"
                    offText="NO"
                    value={Boolean(student[i])}
                    onChange={(v) => setStudent(i, v)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Select approximate age and report as a whole number. Select the toggle if the person
      stopped was a student at a K-12 public school only.
    </div>
  </div>
)

RipaAgeForm.helper = Helper

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  age: Form.selectors.age(state),
  student: Form.selectors.student(state),
  stopHappenedAtPublicSchool: Form.selectors.stopHappenedAtPublicSchool(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  primaryReason: Form.selectors.primaryReason(state),
  ReasonsForStop: get(Template.selectors.enumMap(state), 'ReasonsForStop.possibleValues', {}),
})

const mapDispatchToProps = {
  setStudent: (personIndex: number, value: any) => Form.setFormField({ path: ['person', personIndex, 'student'] })(value),
  setAge: (personIndex: number, value: number) => Form.setFormField({ path: ['person', personIndex, 'age'] })(value),
  setUseSamePrimaryReasonForAll: Form.setFormField({ path: ['useSamePrimaryReasonForAll'] }),
  setPrimaryReason: (value: any, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'primaryReason'] })(value),
  setCodeSection48900: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900'] })(value),
  setCodeSection48900Subdivision: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900Subdivision'] })(value),
}

export default connect(mapStateToProps, mapDispatchToProps)(RipaAgeForm)
