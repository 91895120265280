import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { onEnter } from '@utility/keypressHelpers';
import { ConfirmDialog } from '@components/common';
import { Status } from '@ducks/constants';
import './MoreActions.scss';

export const MoreDashboardActions = ({ deleteForm, i, id, status }: Props) => {
  const [cardMenuOpen, setCardMenuOpen] = useState<any>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const toggleCardMenuOpen = (open: boolean) => (e?: any) => {
    setCardMenuOpen(open ? e.currentTarget : null);
  };
  const onClickDelete = () => {
    setConfirmDeleteOpen(true);
    toggleCardMenuOpen(false)();
  };

  const onCloseDialog = () => {
    setConfirmDeleteOpen(false);
  };

  const confirmDelete = () => {
    onCloseDialog();
    deleteForm(id);
  };

  return (
    <>
      <Menu
        anchorEl={cardMenuOpen}
        open={Boolean(cardMenuOpen) && cardMenuOpen?.id === `dashboard-mobile-card-action-${i}`}
        onClose={toggleCardMenuOpen(false)}
        data-testid={`dashboard-mobile-card-action-menu-${i}`}
      >
        <MenuItem
          data-testid="dashboard-mobile-menu-item-edit"
          onClick={(e) => {
            toggleCardMenuOpen(false)(e);
            window.location.assign(`/new-report/${id}`);
          }}
        >
          Edit
        </MenuItem>
        {status !== Status.Rejected && (
          <MenuItem data-testid="dashboard-mobile-menu-item-delete" onClick={onClickDelete}>
            Delete
          </MenuItem>
        )}
      </Menu>
      <div
        className="dashboard-mobile__card-actions material-icons"
        data-testid={`dashboard-mobile-card-actions-${i}`}
        id={`dashboard-mobile-card-action-${i}`}
        tabIndex={0}
        role="button"
        aria-label="Opens the ripa actions related to this card"
        aria-haspopup="true"
        onClick={toggleCardMenuOpen(true)}
        onKeyUp={onEnter(toggleCardMenuOpen(true))}
      >
        more_vert
      </div>

      <ConfirmDialog
        heading="Delete Form"
        open={confirmDeleteOpen}
        closeDialog={onCloseDialog}
        negativeAction={onCloseDialog}
        positiveAction={confirmDelete}
        positiveText="Delete Form"
        negativeText="Cancel"
        data-testid="confirm-delete-report-dialog"
      >
        <div>Are you sure you would like to delete this form and remove all progress made?</div>
      </ConfirmDialog>
    </>
  );
};

interface Props {
  i: number;
  id: string;
  status: string;
  deleteForm: (id: string) => void;
}

export default MoreDashboardActions;
