const history: string[] = [];
const assignRoute = (newRoute: string) => {
  history.push(newRoute)
  location.assign(newRoute)
}
const clearHistory = () => {
  history.splice(0, history.length)
}

export { assignRoute, history, clearHistory }
