import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import * as NewReport from '@ducks/newReport';
import * as User from '@ducks/user';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  probableCause: Form.selectors.probableCause(state),
  probableCauseCode: Form.selectors.probableCauseCode(state),
  errors: Form.selectors.stepErrors(state),
  ProbableCause: get(Template.selectors.enumMap(state), 'ProbableCause.possibleValues', {}),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  flags: Form.selectors.flags(state),
  clipboardCodeFeature: User.selectors.clipboardCodeFeature(state),
});

const mapDispatchToProps = {
  setProbableCause: (value: string[], personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'probableCause'] })(value),
  setProbableCauseCode: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'probableCauseCode'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
