import { combineReducers } from 'redux';
import * as constants from './ducks/constants';

import * as User from './ducks/user';
import * as Config from './ducks/config';
import * as NewReport from './ducks/newReport';
import * as Form from './ducks/form';
import * as GlobalConfiguration from './ducks/globalConfiguration';
import * as Dashboard from './ducks/dashboard';
import * as Review from './ducks/review';
import * as Users from './ducks/users';
import * as Template from './ducks/template';
import * as Organizations from './ducks/organizations';
import * as WatchLocation from './ducks/watchLocation';
import * as CustomQuestions from './ducks/customQuestions';
import * as CsvReports from './ducks/csvReports';

export default combineReducers({
  [constants.UserNamespace]: User.reducer,
  [constants.ConfigNamespace]: Config.reducer,
  [constants.NewReportNamespace]: NewReport.reducer,
  [constants.FormNamespace]: Form.reducer,
  [constants.GlobalConfigurationNamespace]: GlobalConfiguration.reducer,
  [constants.DashboardNamespace]: Dashboard.reducer,
  [constants.ReviewNamespace]: Review.reducer,
  [constants.CsvReportsNamespace]: CsvReports.reducer,
  [constants.UsersNamespace]: Users.reducer,
  [constants.TemplateNamespace]: Template.reducer,
  [constants.OrganizationsNamespace]: Organizations.reducer,
  [constants.WatchLocationNamespace]: WatchLocation.reducer,
  [constants.CustomQuestionsNamespace]: CustomQuestions.reducer
});
