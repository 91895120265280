import React from 'react';
import cn from 'classnames';
import { TextField, Tooltip } from '@material-ui/core';
import { get, xor } from 'lodash';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { onEnter } from '@utility/keypressHelpers';
import offenseCode from '@utility/offenseCode';
import dayjs from 'dayjs';
import { Props, connector } from './props';
import './RipaProbableCauseForm.scss';

type Offense = ReturnType<typeof offenseCode>[0];

const RipaProbableCauseForm = ({
  errors,
  setProbableCause,
  setProbableCauseCode,
  probableCause,
  probableCauseCode,
  ProbableCause,
  labels,
  numberOfPeople,
  useSamePrimaryReasonForAll,
  currentSubloop,
  flags: { pre2024 },
  clipboardCodeFeature
}: Props) => {
  const subloopIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;
  const matchedSuspectVehicleValue = pre2024 && ProbableCause?.MatchedDescriptionSuspectVehicle?.value;
  const probableCauseForPerson = Array.isArray(probableCause[subloopIndex]) ? probableCause[subloopIndex] : [probableCause[subloopIndex]];
  const filterOptions = createFilterOptions<Offense>({
    matchFrom: 'any',
    limit: 50,
  });
  const copyAndSetProbableCauseCode = (value: string, index: number) => {
    if (clipboardCodeFeature) {
      navigator.clipboard.writeText(value);
    }
    setProbableCauseCode(value, index);
  }
  return (
    <div className="ripa-probable-cause-form" data-testid="ripa-probable-cause-form">
      <div className={cn('ripa-probable-cause-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-probable-cause-form__person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-probable-cause-form__person-same-for-all">Same For All</div>
        <div className="ripa-probable-cause-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-probable-cause-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-probable-cause-form__title">What probable cause led to arrest or search?</div>
      <div className="ripa-probable-cause-form__select-box-container">
        <div className="ripa-probable-cause-form__select-box-content">
          {[
            ProbableCause.WitnessedCommission,
            ProbableCause.MatchedDescription,
            ProbableCause.WitnessVictimId,
            ProbableCause.CarryingSuspiciousObject,
            ProbableCause.ActionsIndicativeCasingVictimLocation,
            ProbableCause.ActingAsLookout,
            ProbableCause.IndicativeOfDrugTransaction,
            ProbableCause.IndicativeOfEngagingViolentCrime,
            ProbableCause.Other,
          ].map(({ value }, vi) => (
            <div
              className={cn('ripa-probable-cause-form__select-box', {
                active: probableCauseForPerson?.includes(value),
              })}
              key={`RipaProbableCauseFormBox-${vi}}`}
              onClick={() => setProbableCause(xor(probableCauseForPerson, [value]), subloopIndex)}
              onKeyUp={onEnter(() => setProbableCause(xor(probableCauseForPerson, [value]), subloopIndex))}
              data-testid={`ripa-probable-cause-form-box-${vi}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
          {pre2024 && (
            <div
              className={cn('ripa-probable-cause-form__select-box', {
                active: probableCauseForPerson?.includes(matchedSuspectVehicleValue),
              })}
              onClick={() => setProbableCause(xor(probableCauseForPerson, [matchedSuspectVehicleValue]), subloopIndex)}
              onKeyUp={onEnter(() => setProbableCause(xor(probableCauseForPerson, [matchedSuspectVehicleValue]), subloopIndex))}
              data-testid="ripa-probable-cause-form-box-MatchedDescriptionSuspectVehicle"
              role="button"
              tabIndex={0}
            >
              {matchedSuspectVehicleValue}
            </div>
          )}
          <Autocomplete<Offense>
            autoSelect
            autoHighlight
            className="ripa-probable-cause-form__autocomplete"
            classes={{ option: 'code' }}
            data-testid="ripa-probable-cause-form-autocomplete"
            onInputChange={(_, v) => copyAndSetProbableCauseCode(v, subloopIndex)}
            blurOnSelect={true}
            defaultValue={offenseCode(dayjs()).find((v) => v.label === probableCauseCode[subloopIndex])}
            getOptionLabel={(o) => o.label}
            renderOption={(o) => (
              <>
                {o.Repealed !== '' ? (
                  <Tooltip title={`Offense Code Repealed ${o.Repealed}`}>
                    <span data-type={o.TypeOfCharge ?? (o as any).OffenseTypeofCharge} style={{ textDecoration: 'line-through' }}>
                      {o.label}
                      {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                    </span>
                  </Tooltip>
                ) : (
                  <span data-type={o.TypeOfCharge ?? (o as any).OffenseTypeofCharge}>
                    {o.label}
                    {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                  </span>
                )}
              </>
            )}
            options={offenseCode(dayjs())}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={get(errors, 'probableCauseCode', 0) > 0}
                placeholder="If known, Code for suspected violation..."
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'ripa-probable-cause-form-autocomplete-input',
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select all that apply to describe the probable cause.</div>
  </div>
);

RipaProbableCauseForm.helper = Helper;

export default connector(RipaProbableCauseForm);
