import React from 'react'
import { connect,
  ConnectedProps
 } from 'react-redux'

import './RipaTestCaseIdForm.scss'
import { FormControl, MenuItem, Select } from '@material-ui/core';
import * as Form from '@ducks/form';
import { toString } from 'lodash';

const testCaseIds:string[] = [...Array(23).keys()].map(i => `TC#${toString(i + 1)}`);
testCaseIds.splice(3, 0, 'TC#3A');

const RipaTestCaseIdForm = ({ testCaseId, setTestCaseId }: PropsFromRedux) => (
  <div className="ripa-test-case-id-form" data-testid="ripa-test-case-id-form">
    <div className="ripa-test-case-id-form__title">
      What is the Test Case Number?
    </div>
    <div className="ripa-test-case-id-form__container">
      <FormControl variant="outlined">
        <Select
          className="ripa-test-case-id"
          value={testCaseId}
          renderValue={value => value === 'TC#' ? 'Select a Test Case' : value}
          onChange={({ target: { value } }: any) => setTestCaseId(value)}
        >
          {testCaseIds.map((testCases => <MenuItem key={testCases} value={testCases}>{testCases}</MenuItem>))}
        </Select>
      </FormControl>
    </div>
  </div>
);

const mapStateToProps = (state: any) => ({
    testCaseId: Form.selectors.testCaseId(state),
})

const mapDispatchToProps = {
  setTestCaseId: (value: string) => Form.setFormField({ path: ['testCaseId'] })(value)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaTestCaseIdForm);
