import React from 'react';
import './Button.scss';
import { omit } from 'ramda';
import classNames from 'classnames';
import { onEnter } from '@utility/keypressHelpers';

const Button = (buttonProps: Props) => {
  const {
    priority, className, children, large,
    disabled, materialIcon, onClick, allowClickOnDisable, 'data-testid': dataTestID
  } = buttonProps;

  const props = omit(['priority', 'className', 'children', 'disabled', 'materialIcon', 'allowClickOnDisable'], buttonProps);

  const classes = classNames({
    button__secondary: priority === 'secondary',
    button__primary: priority !== 'secondary',
    large,
    disabled,
    'alow-click-on-disable': Boolean(allowClickOnDisable)
  }, className);

  return (
    <div
      {...props}
      data-testid={dataTestID || `button-${priority ?? 'primary'}`}
      tabIndex={0}
      className={classes}
      role="button"
      onKeyUp={(e) => !!onClick && onEnter(onClick)(e)}
    >
      {materialIcon && <div className="material-icons">
        {materialIcon}
      </div>}
      <div className="button__text">
        {children}
      </div>
    </div>
  );
};

interface Props {
  priority?: string;
  onClick?: React.EventHandler<any>;
  disabled?: boolean;
  className: string;
  children: React.ReactNode;
  allowClickOnDisable?: boolean | 'true';
  'data-testid'?: string;
  materialIcon?: string;
  large?: boolean;
  size?: string;
  onKeyUp?: React.EventHandler<any>;
}

export default Button;
