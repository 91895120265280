import { path as rPath, mergeRight, assoc } from 'ramda'
import * as Sentry from '@sentry/react'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import { offlineConfig } from '@engine/dependencies/localforage';
import { AlertLevel, createSnackNotification } from '@components/common';
import getFormTemplatesCached from '@utility/getFormTemplatesCached';
import { Action, Dispatch, EnumMap, GetState, Template, ThunkDeps } from './types';
import { TemplateNamespace, UserNamespace } from './constants'

export const SET_ENUM_MAP = `${TemplateNamespace}/SET_ENUM_MAP`
export const SET_LOADING = `${TemplateNamespace}/SET_LOADING`
export const SET_HAS_TEMPLATE = `${TemplateNamespace}/SET_HAS_TEMPLATE`
export const SET_TEMPLATE_NAME = `${TemplateNamespace}/SET_TEMPLATE_NAME`

export const INITIAL_STATE = {
  isTemplateLoading: true,
  enumMap: window.testEnums?.answers ?? {},
  id: window.testEnums?.id ?? null,
  formTemplateId: null,
  version: window.testEnums?.version ?? '',
  name: window.testEnums?.name ?? '',
  hasTemplate: false
}

export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action

  switch (type) {
    case SET_ENUM_MAP:
      return mergeRight(state, payload)
    case SET_LOADING:
      return assoc('isTemplateLoading', payload.isLoading, state)
    case SET_HAS_TEMPLATE:
      return assoc('hasTemplate', payload.hasTemplate, state)
    case SET_TEMPLATE_NAME:
      return assoc('name', payload.templateName, state)
    default:
      return state
  }
}

export const setTemplateLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  payload: { isLoading }
})

export const setEnumMap = ({ enumMap, id, version, name, formTemplateId }: { enumMap: EnumMap; id?: number; version?: string; name?: string; formTemplateId?: number }) => ({
  type: SET_ENUM_MAP,
  payload: { enumMap, id, version, name, formTemplateId }
})

export const setHasTemplate = (hasTemplate: boolean) => ({
  type: SET_HAS_TEMPLATE,
  payload: { hasTemplate }
})

export const setTemplateName = (templateName: string) => ({
  type: SET_TEMPLATE_NAME,
  payload: { templateName }
})

export const getFormTemplate = () => (dispatch: Dispatch<any>, getState: GetState, { http, offlineTemplates }: ThunkDeps) =>
getFormTemplatesCached(http, offlineTemplates)
  .then(async (templates) => {
    const workOffline = await offlineConfig.getItem('work-offline').then(wo => wo === 'true')

    const defaultTemplateName = getState()[UserNamespace]?.organizationInfo?.defaultTemplateName ?? 'CA_2024_ripa';
    const template = templates?.find((t: Template) => t.name === defaultTemplateName);

    if (!template) {
      const message = `Cannot create new form. Missing data, please contact Veritone (${workOffline ? 0 : 1}b)`;
      createSnackNotification(AlertLevel.Warning, 'Error', message);
      Sentry.captureMessage(message, {
        tags: {
          ...JSON.parse(localStorage.getItem('sentry-user') || '{}')
        }
      })
      return
    }
    dispatch(setHasTemplate(true))
    dispatch(setEnumMap({ enumMap: template.answers, id: template.id, formTemplateId: template.id, name: template.name, version: template.version }))

  }).catch(httpErrorHandler('Failed to get form templates'))

export const selectors = ({
  template: rPath([TemplateNamespace]),
  // TODO - type this better
  enumMap: rPath<any>([TemplateNamespace, 'enumMap']),
  hasTemplate: rPath<boolean>([TemplateNamespace, 'hasTemplate']),
  isTemplateLoading: rPath<boolean>([TemplateNamespace, 'isTemplateLoading']),
  name: rPath<string>([TemplateNamespace, 'name']),
})

export default reducer
