import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as WatchLocation from '@ducks/watchLocation';
import * as User from '@ducks/user';
import { NewReport, Template } from '@engine/ducks';

const mapStateToProps = (state: any) => ({
  location: Form.selectors.location(state),
  city: Form.selectors.city(state),
  errors: Form.selectors.stepErrors(state),
  school: Form.selectors.school(state),
  student: Form.selectors.student(state),
  stopHappenedAtPublicSchool: Form.selectors.stopHappenedAtPublicSchool(state),
  watchLocation: WatchLocation.selectors.watchLocation(state),
  userState: User.selectors.state(state),
  stopDateTime: Form.selectors.stopDateTime(state),
  assignment: Form.selectors.assignment(state),
  officerAssignment: Template.selectors.enumMap(state),
  useLastLocation: NewReport.selectors.useLastLocation(state),
});

const mapDispatchToProps = {
  setSchool: Form.setFormField({ path: ['school'] }),
  setStopHappenedAtPublicSchool: Form.setFormField({ path: ['stopHappenedAtPublicSchool'] }),
  setLocation: Form.setFormField({ path: ['location'] }),
  setCity: Form.setFormField({ path: ['city'] }),
  setCoordinates: Form.setFormField({ path: ['coordinates'] }),
  setUseLastLocation: NewReport.setUseLastLocation,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
