// Ducks pattern: https://github.com/erikras/ducks-modular-redux/
import * as User from './user';
import * as Config from './config';
import * as NewReport from './newReport';
import * as Form from './form';
import * as GlobalConfiguration from './globalConfiguration.js';
import * as Dashboard from './dashboard';
import * as CsvReports from './csvReports';
import * as Review from './review.js';
import * as Users from './users';
import * as Template from './template';
import * as Organizations from './organizations';
import * as WatchLocation from './watchLocation';
import * as CustomQuestions from './customQuestions';
import * as Types from './types';

export { Types, CustomQuestions, User, Config, NewReport, Form, GlobalConfiguration, Dashboard, CsvReports, Review, Users, Template, Organizations, WatchLocation };
