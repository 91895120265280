import dayjs from 'dayjs'
import qs from 'qs'
import { assoc, mergeRight, compose, map, path } from 'ramda'
import { createSnackNotification, AlertLevel } from '@components/common'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import sleep from '@utility/sleep'
import { forEachSeries } from 'p-iteration'
import { customAlphabet } from 'nanoid'
import { Role } from '@ducks/constants'
import { OrganizationsNamespace, RoleNumber } from './constants'

export const AllowedStates = ['CA', 'MI']

export const SET_ORG_TEMPLATES = `${OrganizationsNamespace}/SET_ORG_TEMPLATES`
export const SET_ORGS = `${OrganizationsNamespace}/SET_ORGS`
export const SET_PAGE_META = `${OrganizationsNamespace}/SET_PAGE_META`
export const SET_PAGINATION = `${OrganizationsNamespace}/SET_PAGINATION`
export const CHECK_ORGS_ROW = `${OrganizationsNamespace}/CHECK_ORGS_ROW`
export const SELECT_ALL_ORGS_ROWS = `${OrganizationsNamespace}/SELECT_ALL_ORGS_ROWS`
export const EXPAND_ALL_ORGS_ROWS = `${OrganizationsNamespace}/EXPAND_ALL_ORGS_ROWS`
export const SET_ADD_ORG_DIALOG_OPEN = `${OrganizationsNamespace}/SET_ADD_ORG_DIALOG_OPEN`
export const SET_ADD_USER_DIALOG_OPEN = `${OrganizationsNamespace}/SET_ADD_USER_DIALOG_OPEN`
export const SET_ADD_USER_FIELD = `${OrganizationsNamespace}/SET_ADD_USER_FIELD`
export const SET_SEARCH = `${OrganizationsNamespace}/SET_SEARCH`
export const SET_LOADING_ORGS = `${OrganizationsNamespace}/SET_LOADING_ORGS`
export const SET_DELETE_ORG_DIALOG_OPEN = `${OrganizationsNamespace}/SET_DELETE_ORG_DIALOG_OPEN`
export const SET_SORT = `${OrganizationsNamespace}/SET_SORT`
export const SET_ADD_ORG_FIELD = `${OrganizationsNamespace}/SET_ADD_ORG_FIELD`
export const SET_EDIT_ORG_DIALOG_OPEN = `${OrganizationsNamespace}/SET_EDIT_ORG_DIALOG_OPEN`
export const SET_REVIEWERS = `${OrganizationsNamespace}/SET_REVIEWERS`
export const SET_BULK_REVIEWER_SELECTION = `${OrganizationsNamespace}/SET_BULK_REVIEWER_SELECTION`
export const SET_EDIT_ORG_FIELD = `${OrganizationsNamespace}/SET_EDIT_ORG_FIELD`
export const SET_UPLOADING_ORG = `${OrganizationsNamespace}/SET_UPLOADING_ORG`
export const SET_UPLOAD_USER_CSV_DIALOG_OPEN = `${OrganizationsNamespace}/SET_UPLOAD_USER_CSV_DIALOG_OPEN`
export const SET_UPLOAD_USER_DIALOG_USERS = `${OrganizationsNamespace}/SET_UPLOAD_USER_DIALOG_USERS`
export const SET_UPLOAD_DIALOG_UPLOAD_PROGRESS = `${OrganizationsNamespace}/SET_UPLOAD_DIALOG_UPLOAD_PROGRESS`
export const CLEAR_UPLOAD_DIALOG_DATA = `${OrganizationsNamespace}/CLEAR_UPLOAD_DIALOG_DATA`
export const SET_UPLOAD_DIALOG_CONFIRM_OPEN = `${OrganizationsNamespace}/SET_UPLOAD_DIALOG_CONFIRM_OPEN`
export const SET_UPLOAD_USER_CSV_DIALOG_VALIDATION = `${OrganizationsNamespace}/SET_UPLOAD_USER_CSV_DIALOG_VALIDATION`

const INITIAL_STATE = {
  addOrgDialog: {
    organizationName: '',
    subdomain: '',
    state: '',
    ori: null,
    auth_provider: '',
    auth_settings: {},
    smallLogoImage: '',
    largeLogoImage: '',
    loginBackgroundImage: '',
    sftpHostname: null,
    sftpUsername: null,
    sftpKey: null,
    pre2024: false,
    earlyPost2024: false,
    testingBanner: false
  },
  editOrgDialog: {
    id: '',
    ori: null,
    organizationName: '',
    state: '',
    subdomain: '',
    auth_provider: '',
    auth_settings: {},
    smallLogoImage: '',
    largeLogoImage: '',
    loginBackgroundImage: '',
    smallLogoImageUrl: '',
    largeLogoImageUrl: '',
    loginBackgroundImageUrl: '',
    sftpHostname: null,
    sftpUsername: null,
    sftpKey: null,
    doj_production: false,
    pre2024: false,
    earlyPost2024: false,
    testingBanner: false
  },
  addUserDialog: {
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    password: '',
    yearsOfExperience: '',
    organizationId: '',
    assignedReviewers: [],
    officerId: '',
  },
  uploadUserCsvDialog: {
    hasConfirmed: false,
    orgId: '',
    orgName: '',
    subdomain: '',
    userList: [],
    columns: [],
    total: 0,
    processed: 0,
    failed: 0,
    errors: {},
    complete: false,
    uploading: false,
  },
  loading: {
    getOrganizations: false,
    addOrganizations: false,
  },
  dialog: {
    uploadUserCsvDialogOpen: false,
    uploadUserCsvDialogConfirmOpen: false,
    addOrgDialogOpen: false,
    editOrgDialogOpen: false,
    deleteOrgDialogOpen: false,
    addUserDialogOpen: false,
  },
  pagination: {
    totalPages: 1,
    totalCount: 10,
    currentPage: 1,
    pageSize: 10,
  },
  searchAndFilter: {
    search: undefined,
    sort: {
      column: undefined,
      direction: undefined,
    },
  },
  orgs: [
    {
      id: '1',
      name: 'cityname police department',
      subdomain: 'veri-admin',
      created: dayjs(),
    },
  ],
  orgTemplates: []
}

export const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_ORG_TEMPLATES:
      return assoc('orgTemplates', payload.orgTemplates, state)

    case SET_EDIT_ORG_FIELD:
      return assoc('editOrgDialog', { ...state.editOrgDialog, ...payload.editOrgFormFieldUpdates }, state)
    case SET_ADD_ORG_FIELD:
      return assoc('addOrgDialog', { ...state.addOrgDialog, ...payload.newOrgFormFieldUpdates }, state)
    case SET_ADD_ORG_DIALOG_OPEN:
      return mergeRight(state, {
        dialog: { ...state.dialog, addOrgDialogOpen: payload.open },
        addOrgDialog: payload.open ? INITIAL_STATE.addOrgDialog : state.addOrgDialog,
      })
    case SET_ADD_USER_FIELD:
      return assoc('addUserDialog', { ...state.addUserDialog, ...payload.newUserFormFieldUpdates }, state)
    case SET_ADD_USER_DIALOG_OPEN:
      return mergeRight(state, {
        dialog: { ...state.dialog, addUserDialogOpen: payload.open },
        addUserDialog: payload.open ? { ...INITIAL_STATE.addUserDialog, organizationId: payload.orgId } : state.addUserDialog,
      })
    case SET_DELETE_ORG_DIALOG_OPEN:
      return payload.closeOthers
        ? mergeRight(state, {
          dialog: { ...INITIAL_STATE.dialog, deleteOrgDialogOpen: payload.open },
          deleteOrgDialogId: payload.id,
        })
        : mergeRight(state, {
          dialog: { ...state.dialog, deleteOrgDialogOpen: payload.open },
          deleteOrgDialogId: payload.id,
        })
    case SET_EDIT_ORG_DIALOG_OPEN: {
      const editOrg = state.orgs.find((u) => u?.id === payload.id)
      const has2024TestTemplate = !!state.orgTemplates?.find((t) => t?.organization_id === payload.id && t.name === 'CA_doj_ripa_2024_testing')

      return mergeRight(state, {
        dialog: { ...state.dialog, editOrgDialogOpen: payload.open },
        editOrgDialog: {
          ori: editOrg?.ori ?? null,
          organizationName: editOrg?.name,
          subdomain: editOrg?.subdomain,
          state: editOrg?.state,
          auth_provider: editOrg?.auth_provider,
          auth_settings: JSON.stringify(editOrg?.auth_settings, null, '\t'),
          smallLogoImageUrl: editOrg?.small_logo,
          largeLogoImageUrl: editOrg?.large_logo,
          loginBackgroundImageUrl: editOrg?.login_background,
          sftpHostname: editOrg?.sftp_credential?.hostname ?? null,
          sftpUsername: editOrg?.sftp_credential?.username ?? null,
          sftpKey: editOrg?.sftp_credential?.key ?? null,
          id: editOrg?.id,
          doj_production: editOrg?.doj_production ?? false,
          pre2024: editOrg?.pre2024,
          earlyPost2024: editOrg?.earlyPost2024,
          testingBanner: editOrg?.testingBanner,
          has2024TestTemplate
        },
      })
    }
    case SET_ORGS:
      return assoc('orgs', payload.orgs, state)
    case SET_PAGE_META:
      return mergeRight(state, { pagination: { ...state.pagination, totalPages: Number(payload.totalPages), totalCount: Number(payload.totalCount) } })
    case SET_PAGINATION:
      return mergeRight(state, { pagination: { ...state.pagination, currentPage: Number(payload.currentPage), pageSize: Number(payload.pageSize) } })
    case CHECK_ORGS_ROW: {
      const { checkedOrganizationsRows } = state
      if (!payload.checked) {
        checkedOrganizationsRows.splice(
          checkedOrganizationsRows.findIndex((r) => r === payload.id),
          1
        )
      } else if (!checkedOrganizationsRows.includes(payload.id)) {
        checkedOrganizationsRows.push(payload.id)
      }
      return mergeRight(state, { checkedOrganizationsRows })
    }
    case SET_SORT:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, sort: payload } })
    case SET_SEARCH:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, search: payload.search } })
    case SET_LOADING_ORGS:
      return mergeRight(state, { loading: { ...state.loading, getOrganizations: payload.isLoading } })
    case SET_UPLOADING_ORG:
      return mergeRight(state, { loading: { ...state.loading, addOrganizations: payload.isLoading } })
    case SET_UPLOAD_USER_CSV_DIALOG_OPEN: {
      if (payload.open) {
        return mergeRight(state, {
          dialog: { uploadUserCsvDialogOpen: payload.open },
          uploadUserCsvDialog: { ...state.uploadUserCsvDialog, orgId: payload.orgId, orgName: payload.orgName, subdomain: payload.subdomain },
        })
      }
      return mergeRight(state, { dialog: { uploadUserCsvDialogOpen: payload.open }, uploadUserCsvDialog: INITIAL_STATE.uploadUserCsvDialog })
    }
    case SET_UPLOAD_USER_DIALOG_USERS:
      return mergeRight(state, { uploadUserCsvDialog: { ...state.uploadUserCsvDialog, userList: payload.users, columns: payload.columns } })
    case SET_UPLOAD_DIALOG_UPLOAD_PROGRESS: {
      return mergeRight(state, { uploadUserCsvDialog: { ...state.uploadUserCsvDialog, ...payload } })
    }
    case CLEAR_UPLOAD_DIALOG_DATA: {
      return mergeRight(state, { uploadUserCsvDialog: INITIAL_STATE.uploadUserCsvDialog })
    }
    case SET_UPLOAD_DIALOG_CONFIRM_OPEN: {
      return mergeRight(state, { dialog: { uploadUserCsvDialogConfirmOpen: payload.open }, uploadUserCsvDialog: { ...state.uploadUserCsvDialog, hasConfirmed: payload.confirmed } })
    }
    case SET_UPLOAD_USER_CSV_DIALOG_VALIDATION: {
      const failed = Object.keys(payload.inValidCells).length
      const errorMessages = {
        email: 'Must be a valid email format',
        username: 'Must not be blank',
        firstname: 'Must not be blank and contain alphanumeric characters',
        lastname: 'Must not be blank and contain alphanumeric characters',
        uid: 'Must be 9 alphanumeric characters',
        yearsofexperience: 'Must be numeric characters',
        roles: 'Must be either admin, reviewer, user, officer, or analyst',
        password: 'Cannot be blank and must contain at least 8 alphanumeric characters with special characters',
      }

      const errors = Object.values(payload.inValidCells).map(({ row, col }) => ({
        [row]: {
          errors: Object.assign({}, ...col.map((column) => ({
            [column]: [errorMessages[column]]
          }))),
          message: 'Invalid create parameters',
        }
      }))

      return mergeRight(state, {
        uploadUserCsvDialog: {
          ...state.uploadUserCsvDialog,
          failed,
          complete: true,
          errors: Object.assign({}, ...errors)
        }
      });
    }

    default:
      return state
  }
}
export const setOrgTemplates = (orgTemplates) => ({
  type: SET_ORG_TEMPLATES,
  payload: { orgTemplates },
})

export const setAddOrgField = (newOrgFormFieldUpdates) => ({
  type: SET_ADD_ORG_FIELD,
  payload: { newOrgFormFieldUpdates },
})

export const setEditOrgField = (editOrgFormFieldUpdates) => ({
  type: SET_EDIT_ORG_FIELD,
  payload: { editOrgFormFieldUpdates },
})

export const setDeleteOrgDialogOpen = ({ closeOthers = undefined, open, id = undefined }) => ({
  type: SET_DELETE_ORG_DIALOG_OPEN,
  payload: { closeOthers, open, id },
})

export const setEditOrgDialogOpen = ({ open, id = undefined }) => ({
  type: SET_EDIT_ORG_DIALOG_OPEN,
  payload: { open, id },
})

export const setAddOrgDialogOpen = ({ open }) => ({
  type: SET_ADD_ORG_DIALOG_OPEN,
  payload: { open },
})

export const setOrganizations = (orgs) => ({
  type: SET_ORGS,
  payload: { orgs },
})

export const setAddUserDialogOpen = ({ open, orgId = undefined }) => ({
  type: SET_ADD_USER_DIALOG_OPEN,
  payload: { open, orgId },
})

export const setAddOrgUserField = (newUserFormFieldUpdates) => ({
  type: SET_ADD_USER_FIELD,
  payload: { newUserFormFieldUpdates },
})

export const setPageMeta = ({ totalPages, totalCount }) => ({
  type: SET_PAGE_META,
  payload: { totalPages, totalCount },
})

export const setPagination = ({ currentPage, pageSize }) => ({
  type: SET_PAGINATION,
  payload: { currentPage, pageSize },
})

export const setSort = ({ column, direction }) => ({
  type: SET_SORT,
  payload: { column, direction },
})

export const setSearch = (search) => ({
  type: SET_SEARCH,
  payload: { search },
})

export const setLoadingOrganizations = (isLoading) => ({
  type: SET_LOADING_ORGS,
  payload: { isLoading },
})

export const setUploadingOrg = (isLoading) => ({
  type: SET_UPLOADING_ORG,
  payload: { isLoading },
})

export const setUploadUserCsvDialogOpen = ({ open, orgId = undefined, orgName = undefined, subdomain = undefined }) => ({
  type: SET_UPLOAD_USER_CSV_DIALOG_OPEN,
  payload: { open, orgId, orgName, subdomain },
})

export const setUploadUserDialogUsers = ({ users, columns }) => ({
  type: SET_UPLOAD_USER_DIALOG_USERS,
  payload: { users, columns },
})

export const setUploadDialogConfirmOpen = ({ open, confirmed }) => ({
  type: SET_UPLOAD_DIALOG_CONFIRM_OPEN,
  payload: { open, confirmed },
})

export const setUploadDialogUploadProgress = ({ progress, total, failed, complete, uploading, errors }) => ({
  type: SET_UPLOAD_DIALOG_UPLOAD_PROGRESS,
  payload: { progress, total, failed, complete, uploading, errors },
})

export const setUploadUserCSVDialogValidation = (inValidCells) => ({
  type: SET_UPLOAD_USER_CSV_DIALOG_VALIDATION,
  payload: { inValidCells },
})

export const clearUploadDialogData = () => ({
  type: CLEAR_UPLOAD_DIALOG_DATA,
})

export const addOrg =
  () =>
    (dispatch, getState, { http }) => {
      const { testingBanner, organizationName, subdomain, ori, auth_provider, auth_settings, smallLogoImage, largeLogoImage, loginBackgroundImage, pre2024, state } = getState()[OrganizationsNamespace].addOrgDialog
      let authSettings = null
      try {
        authSettings = JSON.parse(auth_settings)
      } catch {
        // Just swallow invalid JSON error
      }

      const form = objectToFormData({
        organization: {
          name: organizationName,
          state,
          subdomain,
          ori,
          auth_provider,
          auth_settings: authSettings,
          pre2024,
          testingBanner
        },
      })
      if (smallLogoImage) {
        form.append('organization[small_logo]', smallLogoImage)
      }
      if (largeLogoImage) {
        form.append('organization[large_logo]', largeLogoImage)
      }
      if (loginBackgroundImage) {
        form.append('organization[login_background]', loginBackgroundImage)
      }

      if (smallLogoImage || largeLogoImage || loginBackgroundImage) {
        dispatch(setUploadingOrg(true))
      }

      return http
        .post('/organizations', form, { 'Content-Type': 'multipart/form-data' })
        .then(() => {
          createSnackNotification(AlertLevel.Success, 'Success', 'Organization created')
        })
        .catch((error) => {
          let detailedError = error?.message ?? ''

          if (error?.response?.data?.exception?.includes('already exists') || error?.response?.data?.subdomain[0]?.includes('has already been taken')) {
            detailedError = 'Subdomain already exists.'
          }
          const messageError = `Failed to create org </br> <small>${detailedError}</small>`
          createSnackNotification(AlertLevel.Error, 'Error', messageError)
        })
        .finally(() => {
          setTimeout(() => {
            const { searchAndFilter, pagination } = getState()[OrganizationsNamespace]
            dispatch(getOrganizations({ searchAndFilter, pagination }))
            dispatch(setAddOrgDialogOpen({ open: false }))
            dispatch(setUploadingOrg(false))
          }, 500)
        })
    }

export const editOrg =
  () =>
    (dispatch, getState, { http }) => {
      const { testingBanner, ori, organizationName, subdomain, state, auth_provider, auth_settings, smallLogoImage, largeLogoImage, loginBackgroundImage, sftpHostname, sftpUsername, sftpKey, id, doj_production, pre2024, earlyPost2024 } =
        getState()[OrganizationsNamespace].editOrgDialog

      let authSettings = null
      try {
        authSettings = JSON.parse(auth_settings)
      } catch {
        // Just swallow invalid JSON error
      }

      const form = objectToFormData({
        organization: {
          name: organizationName,
          subdomain,
          ori,
          state,
          auth_provider,
          auth_settings: authSettings,
          sftp_credential_attributes: {
            hostname: sftpHostname,
            username: sftpUsername,
            key: sftpKey,
          },
          doj_production,
          pre2024,
          earlyPost2024,
          testingBanner
        },
      })

      if (smallLogoImage) {
        form.append('organization[small_logo]', smallLogoImage)
      }
      if (largeLogoImage) {
        form.append('organization[large_logo]', largeLogoImage)
      }
      if (loginBackgroundImage) {
        form.append('organization[login_background]', loginBackgroundImage)
      }
      if (smallLogoImage || largeLogoImage || loginBackgroundImage) {
        dispatch(setUploadingOrg(true))
      }

      return http
        .patch(`/organizations/${id}`, form, { 'Content-Type': 'multipart/form-data' })
        .then(() => {
          createSnackNotification(AlertLevel.Success, 'Success', 'Organization Updated')
        })
        .catch(httpErrorHandler('Failed to update org'))
        .finally(() => {
          setTimeout(() => {
            const { searchAndFilter, pagination } = getState()[OrganizationsNamespace]
            dispatch(getOrganizations({ searchAndFilter, pagination }))
            dispatch(setEditOrgDialogOpen({ open: false }))
            dispatch(setUploadingOrg(false))
          }, 500)
        })
    }

// takes a {} object and returns a FormData object
const objectToFormData = (obj, formData, namespace) => {
  const fd = formData || new FormData()
  if (obj === null) {
    return fd
  }

  for (const [key, value] of Object.entries(obj)) {
    const formKey = namespace ? `${namespace}[${key}]` : key
    // non-file object => recurse
    if (typeof value === 'object' && !(value instanceof File)) {
      objectToFormData(value, fd, formKey)
    } else {
      fd.append(formKey, value)
    }
  }
  return fd
}

export const addUser =
  () =>
    (dispatch, getState, { http }) => {
      const { firstname, lastname, email, username, password, yearsOfExperience, organizationId, officerId: enteredOfficerId } = getState()[OrganizationsNamespace].addUserDialog
      const officerId = enteredOfficerId || customAlphabet('1234567890qwertyuioplkjhgfdsazxcvbnm', 9)().toUpperCase()

      return http
        .post('/users', {
          email,
          password,
          password_confirmation: password,
          years_of_experience: yearsOfExperience,
          username,
          first_name: firstname,
          last_name: lastname,
          officer_id: officerId,
          organization_id: organizationId,
          roles: [Role.Officer, Role.Admin],
        })
        .then(({ data: { id } }) => {
          createSnackNotification(AlertLevel.Success, 'Success', `User created </br> <small>${id}-${officerId}</small>`)
        })
        .catch(httpErrorHandler('Failed to create user'))
        .finally(() => {
          const { searchAndFilter, pagination } = getState()[OrganizationsNamespace]
          dispatch(getOrganizations({ searchAndFilter, pagination }))
          dispatch(setAddUserDialogOpen({ open: false }))
        })
    }

export const getOrganizations = ({ searchAndFilter, pagination }) => async (dispatch, getState, { http }) => {
  dispatch(setLoadingOrganizations(true))
  const { currentPage, pageSize } = pagination
  const {
    search,
    sort: { column, direction },
  } = searchAndFilter

  const query = qs.stringify(
    {
      page: currentPage,
      limit: pageSize,
      order: column === 'created' ? 'created_at' : column,
      dir: direction,
      search,
    },
    { arrayFormat: 'brackets' }
  )

  const { data: templateData } = await http.get('/form_templates/all')
  dispatch(setOrgTemplates(templateData))

  return http
    .get(`/organizations?${query}`)
    .then(({ data }) => {
      if (data.organizations && data.pagination) {
        dispatch(setOrganizations(data.organizations))
        dispatch(setPageMeta({ totalPages: Number(data.pagination.pages), totalCount: Number(data.pagination.count) }))
      }
    })
    .catch(httpErrorHandler('Failed to get orgs'))
    .finally(() => {
      setTimeout(() => dispatch(setLoadingOrganizations(false)), 100)
    })
}

export const deleteOrg =
  () =>
    (dispatch, getState, { http }) => {
      const { deleteOrgDialogId } = getState()[OrganizationsNamespace]
      return http
        .delete(`/organizations/${deleteOrgDialogId}`)
        .then(() => {
          const { searchAndFilter, pagination } = getState()[OrganizationsNamespace]
          dispatch(getOrganizations({ searchAndFilter, pagination }))
          dispatch(setDeleteOrgDialogOpen({ open: false, id: null }))
          createSnackNotification(AlertLevel.Success, 'Success', 'Org Removed')
        })
        .catch(httpErrorHandler('Failed to delete user'))
    }

export const uploadUsers =
  (userRowIndicies) =>
    async (dispatch, getState, { http }) => {
      const {
        uploadUserCsvDialog: { orgId, columns },
      } = getState()[OrganizationsNamespace]
      let {
        uploadUserCsvDialog: { userList },
      } = getState()[OrganizationsNamespace]
      if (userRowIndicies && userRowIndicies.length > 0) {
        userList = userList.filter((_, ui) => userRowIndicies.includes(`${ui}`))
      }

      const total = userList.length
      let progress = 0
      let failed = 0
      let errors = {}

      dispatch(setUploadUserDialogUsers({ users: userList, columns }))
      dispatch(setUploadDialogUploadProgress({ uploading: true, complete: false, progress, total, failed, errors }))

      await forEachSeries(userList, async (user, userListIndex) => {
        const { email, username, uid, firstname, lastname, yearsofexperience, roles, password } = user
        const rolesEnum = []
        roles?.split(',').forEach((r) => {
          if (Object.keys(RoleNumber).includes(r?.trim())) {
            rolesEnum.push(RoleNumber[r?.trim()])
          }
        })
        await http
          .post('/users', {
            email,
            username,
            years_of_experience: yearsofexperience,
            first_name: firstname,
            last_name: lastname,
            password,
            password_confirmation: password,
            officer_id: uid,
            organization_id: orgId,
            roles: rolesEnum,
          })
          .then(() => {
            progress += 1
            dispatch(setUploadDialogUploadProgress({ progress, total, failed, errors, uploading: true, complete: false }))
          })
          .catch((e) => {
            progress += 1
            failed += 1
            errors = { ...errors, [userListIndex]: { message: e?.response?.data?.error?.message, errors: e?.response?.data?.errors } }
            dispatch(setUploadDialogUploadProgress({ progress, total, failed, errors, uploading: true, complete: false }))
          })
        await sleep(300)
      })
      if (failed > 0) {
        createSnackNotification(AlertLevel.Error, 'Creation failures', `There were ${failed} errors while creating users`)
      } else if (total - failed > 0) {
        createSnackNotification(AlertLevel.Success, 'Creation Succesful', `${total - failed} users created`)
      }
      dispatch(setUploadDialogUploadProgress({ uploading: false, complete: true, progress, total, failed, errors }))
    }

export const setSortAndSearch =
  ({ column, direction }) =>
    (dispatch, getState, { http }) => {
      const { searchAndFilter, pagination } = reducer(getState()[OrganizationsNamespace], setSort({ column, direction }))
      dispatch(setSort({ column, direction }))
      return getOrganizations({ searchAndFilter, pagination })(dispatch, getState, { http })
    }

export const selectors = {
  addUserDialog: path([OrganizationsNamespace, 'addUserDialog']),
  addOrgDialog: path([OrganizationsNamespace, 'addOrgDialog']),
  editOrgDialog: path([OrganizationsNamespace, 'editOrgDialog']),
  uploadUserCsvDialog: path([OrganizationsNamespace, 'uploadUserCsvDialog']),
  dialog: path([OrganizationsNamespace, 'dialog']),
  loading: path([OrganizationsNamespace, 'loading']),
  searchAndFilter: path([OrganizationsNamespace, 'searchAndFilter']),
  reviewerSelection: path([OrganizationsNamespace, 'reviewerSelection']),
  addOrgDialogOpen: path([OrganizationsNamespace, 'addOrgDialogOpen']),
  pagination: path([OrganizationsNamespace, 'pagination']),
  orgs: path([OrganizationsNamespace, 'orgs']),
  orgTemplates: path([OrganizationsNamespace, 'orgTemplates']),
  orgRows: compose(
    map(({ id, name, state, subdomain, doj_production, created_at }) => ({
      id,
      name,
      state,
      subdomain,
      doj_production,
      created: dayjs(created_at),
      actions: '',
    })),
    path([OrganizationsNamespace, 'orgs'])
  ),
}
