import React from 'react';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import './Alert.scss';

const Alert = ({ children, message }: Props) => (
  <div
    className="alert"
    data-testid="alert"
  >
    <ErrorRoundedIcon />
    <div className="message">
      {message || children}
    </div>
  </div>
);

interface Props {
  children: string;
  message?: string;
}
export default Alert;
