import React from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './RipaSeizureBasisForm.scss';

const RipaSeizureBasisForm = ({
  seizureBasis,
  seizedPropertyBasis,
  toggleSeizedPropertyBasis,
  currentSubloop,
  labels,
  numberOfPeople,
  useSameActionTakenForAll,
  useSameActionForAllPersonIndex,
  student,
  setAnyContrabandFound,
  anyContrabandFound,
}: Props) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;
  const arraySelectBoxContent = [seizureBasis.Safekeeping, seizureBasis.Contraband, seizureBasis.Evidence, seizureBasis.ImpoundVehicle, seizureBasis.AbandonedProperty];
  const contrabandRelevantBasis = [seizureBasis.Contraband.value, seizureBasis.Evidence.value];
  const handleToggleSeizedPropertyBasis = (value: string) => {
    toggleSeizedPropertyBasis(value, personIndex);
    if (contrabandRelevantBasis.includes(value) && !anyContrabandFound[personIndex]) {
      setAnyContrabandFound(true, personIndex);
    }
  };

  return (
    <div className="ripa-seizure-basis-form" data-testid="ripa-seizure-basis-form">
      <div className={cn('ripa-seizure-basis-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-seizure-basis-form__person-label">{labels?.[currentSubloop] || currentSubloop + 1}</div>
        <div className="ripa-seizure-basis-form__person-same-for-all">Same For All</div>
        <div className="ripa-seizure-basis-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-seizure-basis-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-seizure-basis-form__title">{'What\'s the basis for property seizure?'}</div>
      <div className="ripa-seizure-basis-form__selection">
        <div className="ripa-seizure-basis-form__select-box-content">
          {arraySelectBoxContent.map(({ value }, si) => (
            <div
              className={cn('ripa-seizure-basis-form__select-box', { active: seizedPropertyBasis?.[personIndex]?.includes(value) })}
              key={`RipaSeizureTypeFormBox-${si}`}
              onClick={() => handleToggleSeizedPropertyBasis(value)}
              onKeyUp={onEnter(() => handleToggleSeizedPropertyBasis(value))}
              data-testid={`ripa-seizure-basis-form-box-${si}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
          {student.includes(true) && (
            <div
              className={cn('ripa-seizure-basis-form__select-box k12', { active: seizedPropertyBasis?.[personIndex]?.includes(seizureBasis.SuspectedViolationSchoolProperty.value) })}
              onClick={() => handleToggleSeizedPropertyBasis(seizureBasis.SuspectedViolationSchoolProperty.value)}
              onKeyUp={onEnter(() => handleToggleSeizedPropertyBasis(seizureBasis.SuspectedViolationSchoolProperty.value))}
              data-testid={`ripa-seizure-basis-form-box-${arraySelectBoxContent.length}`}
              role="button"
              tabIndex={0}
            >
              {seizureBasis.SuspectedViolationSchoolProperty.value}
              <div className="ripa-seizure-basis-form__select-box-k12">K-12</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select ALL that apply.</div>
  </div>
);

RipaSeizureBasisForm.helper = Helper;

export default connector(RipaSeizureBasisForm);
