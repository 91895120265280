import React from 'react';
import { Button } from '@components/common'
import { Props, connector } from './props';
import './TestModeBanner.scss';

const TestModeBanner = ({ createTestForm }: Props) => (
  <div className="test-mode-banner">
    <span className="test-mode-banner__text-container">
      <span className="material-icons">
        priority_high
      </span>
      <b>Test forms are available</b>
    </span>
    <span>
      <Button onClick={createTestForm} priority="secondary" className="test-mode-banner__start-test"> Start a test form </Button>
    </span>
  </div>
);

export default connector(TestModeBanner);
