import { throttle } from 'lodash'
import { SUBMITTING_FOR_REVIEW, SET_FORM_LOADING, RESET_INITIAL_STATE, updateForm, reducer } from '@ducks/form'
import { FormNamespace, UserNamespace } from '@ducks/constants'
import { offlineConfig } from '@engine/dependencies/localforage'

const sendUpdate = throttle(
  (form, action, dispatch) => {
    dispatch(updateForm({ value: reducer(form, action), id: form.id }))
  },
  localStorage?.getItem('contact-is-online') === 'true' ? 2000 : 100,
  { trailing: true, leading: false }
)

const formUpdateMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action)

    if (action.type.includes(FormNamespace) && action.type !== SET_FORM_LOADING) {
      if (action.type === SUBMITTING_FOR_REVIEW || action.type === RESET_INITIAL_STATE) {
        sendUpdate.cancel()
      } else {
        const form = getState()[FormNamespace]
        const {
          user: { training_mode },
        } = getState()[UserNamespace]
        const workOffline = await offlineConfig.getItem('work-offline').then((wo) => wo === 'true')
        const shouldSendUpdate = !training_mode || workOffline
        if (form.id && shouldSendUpdate) {
          sendUpdate(form, action, dispatch)
        }
      }
    }
  }

export default formUpdateMiddleware
