import { connect, ConnectedProps } from 'react-redux';
import { Template, Form, NewReport } from '@ducks';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  seizedPropertyType: Form.selectors.seizedPropertyType(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSameActionTakenForAll: Form.selectors.useSameActionTakenForAll(state),
  useSameActionForAllPersonIndex: Form.selectors.useSameActionForAllPersonIndex(state),
  typeSeized: get(Template.selectors.enumMap(state), 'typeOfPropertySeized.possibleValues', {}),
});

const mapDispatchToProps = {
  toggleSeizedPropertyType: (value: string, personIndex: number) => Form.toggleSeizedPropertyType({ value, personIndex }),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
