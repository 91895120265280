import {
  getUserForms as getUserFormsDashboard,
  reducer as reducerDashboard,
  SET_SEARCH as SET_SEARCH_DASHBOARD,
} from '@ducks/dashboard'
import {
  getUserForms as getUserFormsReview,
  reducer as reducerReview,
  SET_SEARCH as SET_SEARCH_ADMIN
} from '@ducks/review'
import {
  getUsers,
  reducer as reducerUsers,
  SET_SEARCH as SET_SEARCH_USERS,
  SET_ROLE, SET_REVIEWER,
  setPagination
} from '@ducks/users'
import {
  getOrganizations,
  reducer as reducerOrganizations,
  SET_SEARCH as SET_SEARCH_ORGS
} from '@ducks/organizations'
import { DashboardNamespace, ReviewNamespace, UsersNamespace, OrganizationsNamespace } from '@ducks/constants'
import { throttle } from 'lodash'

const searchAgainDashboard = throttle((state, action, dispatch) => {
  const { searchAndFilter, pagination } = reducerDashboard(state, action)
  dispatch(getUserFormsDashboard({ searchAndFilter, pagination: { ...pagination, currentPage: 1 } }))
}, 1000, { trailing: true, leading: false })

const searchAgainReview = throttle((state, action, dispatch) => {
  const { searchAndFilter, pagination, officerSelect } = reducerReview(state, action)
  dispatch(getUserFormsReview({ searchAndFilter, officerSelect, pagination: { ...pagination, currentPage: 1 } }))
}, 1000, { trailing: true, leading: false })

const searchAgainUsers = (timeout = 1000) => throttle((state, action, dispatch) => {
  const { searchAndFilter, pagination } = reducerUsers(state, action)
  dispatch(getUsers({ searchAndFilter, pagination: { ...pagination, currentPage: 1 } }))
  dispatch(setPagination({ ...pagination, currentPage: 1 }))
}, timeout, { trailing: true, leading: false })

const searchOrganizations = throttle((state, action, dispatch) => {
  const { searchAndFilter, pagination } = reducerOrganizations(state, action)
  dispatch(getOrganizations({ searchAndFilter, pagination: { ...pagination, currentPage: 1 } }))
}, 1000, { trailing: true, leading: false })

const searchUpdateMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === SET_SEARCH_DASHBOARD) {
    const state = getState()[DashboardNamespace]
    searchAgainDashboard(state, action, dispatch)
  }
  if (action.type === SET_SEARCH_ADMIN) {
    const state = getState()[ReviewNamespace]
    searchAgainReview(state, action, dispatch)
  }
  if ([SET_SEARCH_USERS, SET_ROLE, SET_REVIEWER].includes(action.type)) {
    const state = getState()[UsersNamespace]
    const timeout = [SET_ROLE, SET_REVIEWER].includes(action.type) ? 10 : 1000
    searchAgainUsers(timeout)(state, action, dispatch)
  }
  if (action.type === SET_SEARCH_ORGS) {
    const state = getState()[OrganizationsNamespace]
    searchOrganizations(state, action, dispatch)
  }
  next(action)
}

export default searchUpdateMiddleware
