import { mergeRight, path, pathOr } from 'ramda';
import { get, find, findLast } from 'lodash';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import { httpErrorHandler } from '@utility/httpErrorHandler'
import { NewReportNamespace } from './constants';
import { Action, Dispatch, GetState, Step, Substep, HistoryRecord, nStep, CustomQuestionSections, ThunkDeps, ServerCustomQuestions } from './types';

export const NEXT = `${NewReportNamespace}/NEXT`
export const BACK = `${NewReportNamespace}/BACK`
export const REGISTER_STEPS = `${NewReportNamespace}/REGISTER_STEPS`
export const REGISTER_SUBLOOP = `${NewReportNamespace}/REGISTER_SUBLOOP`
export const SET_STEP = `${NewReportNamespace}/SET_STEP`
export const RESET_HISTORY = `${NewReportNamespace}/RESET_HISTORY`
export const SET_FORCE_LAST_SUBLOOP = `${NewReportNamespace}/SET_FORCE_LAST_SUBLOOP`
export const RECALL_STEP = `${NewReportNamespace}/RECALL_STEP`
export const SET_START_OVER_DIALOG_OPEN = `${NewReportNamespace}/SET_START_OVER_DIALOG_OPEN`
export const SET_REMOVE_STUDENT_DIALOG_OPEN = `${NewReportNamespace}/SET_REMOVE_STUDENT_DIALOG_OPEN`
export const SET_CUSTOM_QUESTIONS = `${NewReportNamespace}/SET_CUSTOM_QUESTIONS`;
export const SET_USE_LAST_LOCATION = `${NewReportNamespace}/SET_USE_LAST_LOCATION`;

/* eslint-disable no-unused-vars */

const INITIAL_STATE = {
  currentStep: 0,
  currentSubstep: 0,
  currentSubloop: 0,
  currentComponent: '',
  maxStepReached: 1,
  totalSteps: 0,
  forceLastSubloop: -1,
  history: [] as HistoryRecord[],
  startOverDialogOpen: false,
  startOverDialogFormId: '',
  removeStudentDialogOpen: false,
  removeStudentDialogPersonId: '',
  steps: [] as Step[],
  customQuestions: {} as CustomQuestionSections,
  nSteps: [{
    nSubsteps: 0
  }] as nStep[],
  useLastLocation: false,
};

export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action;
  const { currentSubloop, currentSubstep, nSteps, forceLastSubloop, maxStepReached, history, steps } = state;
  const { currentStep } = state;

  switch (type) {
    case SET_CUSTOM_QUESTIONS:
      return mergeRight(state, {
        customQuestions: payload.customQuestions
      })
    case NEXT: {
      const { nSubsteps, preconditions, loop } = nSteps[currentStep];
      let nextSubstep = 0;

      const subloopLength = loop?.iterations ? payload.preconditionData[loop?.iterations] : 0;

      // Check for next substep preconditions within the current step.
      for (let si = currentSubstep; si < nSubsteps; si = si + 1) {
        const nextPreconditionSatisfied = preconditions?.[si + 1] ? preconditions?.[si + 1]?.(payload.preconditionData) : true;
        if (nextPreconditionSatisfied) {
          nextSubstep = si + 1;
          break;
        }
      }

      let nextStepSubstep = 0;
      // Check for next step subloop preconditions before.
      if (loop && nextSubstep >= nSubsteps && currentSubloop < subloopLength - 1) {
        nextStepSubstep = 0;
        const nextStepPreconditions = nSteps[currentStep]?.preconditions;
        const nextSubloopPreconditions = { ...payload.preconditionData, currentSubloop: payload.preconditionData.currentSubloop + 1 };
        if (nextStepPreconditions && nextStepPreconditions[0] && !nextStepPreconditions[0]?.(nextSubloopPreconditions)) {
          const nextStepNsubsteps = nSteps[currentStep]?.nSubsteps || 0;
          for (let si = nextStepSubstep; si < nextStepNsubsteps; si = si + 1) {
            const nextStepPreconditionSatisfied = nextStepPreconditions?.[si + 1] ? nextStepPreconditions?.[si + 1]?.(nextSubloopPreconditions) : true;
            if (nextStepPreconditionSatisfied) {
              nextStepSubstep = si + 1;
              break;
            }
          }
        }
      }

      const currentMaxStepReached = history.length + 1 > maxStepReached ? history.length + 1 : maxStepReached;
      const next = nextSubstep >= nSubsteps && (forceLastSubloop === currentSubloop || currentSubloop >= subloopLength - 1) ? {
        currentSubstep: 0,
        currentStep: currentStep + 1,
        currentSubloop: 0,
        forceLastSubloop: -1,
        currentComponent: '',
      } : {
        currentSubstep: nextSubstep < nSubsteps ? nextSubstep : nextStepSubstep,
        currentStep: nextSubstep < nSubsteps ? currentStep : loop ? (currentSubloop < subloopLength - 1 && nextSubstep >= nSubsteps) ? currentStep : currentStep + 1 : currentStep + 1,
        currentSubloop: loop ? currentSubloop < subloopLength - 1 && nextSubstep >= nSubsteps ? currentSubloop + 1 : currentSubloop : 0,
        forceLastSubloop,
      }

      next.currentComponent = steps?.[next.currentStep]?.substeps?.[next.currentSubstep]?.component;

      return mergeRight(state, { ...next, maxStepReached: currentMaxStepReached, history: [...state.history, next] });
    }
    case BACK: {
      const backOneEntry = history.length - 2 >= 0 ? history[history.length - 2] : {
        currentStep: 0, currentSubstep: 0, currentSubloop: 0, forceLastSubloop: -1,
      };
      const newHistory = history.slice(0, history.length - 1);

      return mergeRight(state, { ...backOneEntry, history: newHistory });
    }
    case REGISTER_STEPS:
      return mergeRight(state, { nSteps: payload.nSteps, totalSteps: payload.totalSteps, steps: payload.steps });
    case SET_STEP: {
      return mergeRight(state, {
        currentStep: payload.step,
        currentSubstep: payload.substep,
        currentSubloop: payload.subloop,
        currentComponent: payload.component,
        forceLastSubloop: -1,
      });
    }
    case RESET_HISTORY: {
      const newHistory = payload.ssi === false ? history.reduce(
        (res: HistoryRecord[], item: HistoryRecord) => {
          if (get(item, 'currentStep', 0) < payload.si) {
            res.push(item);
          }
          return res;
        },
        []
      ) : history.reduce(
        (res: HistoryRecord[], item: HistoryRecord) => {
          if (get(item, 'currentStep', 0) < payload.si || (get(item, 'currentStep', 0) === payload.si && get(item, 'currentSubstep', 0) <= payload.ssi)) {
            res.push(item);
          }
          return res;
        },
        []
      );
      let lastHistory = findLast(newHistory, ['currentStep', payload.si]);
      if (payload.ssi === false) {
        lastHistory = find(history, ['currentStep', payload.si])
        if (lastHistory) {
          lastHistory = { ...lastHistory, currentSubstep: 0 };
          newHistory.push(lastHistory);
        }
      }
      return mergeRight(state, { ...lastHistory, history: newHistory });
    }
    case SET_FORCE_LAST_SUBLOOP:
      return mergeRight(state, { forceLastSubloop: payload.forceLastSubloop });
    case SET_START_OVER_DIALOG_OPEN:
      return mergeRight(state, {
        startOverDialogOpen: payload.open,
        startOverDialogFormId: payload.open ? payload.formId : '',
      });
    case SET_REMOVE_STUDENT_DIALOG_OPEN:
      return mergeRight(state, {
        removeStudentDialogOpen: payload.open,
        removeStudentDialogPersonId: payload.open ? payload.personId : '',
      });
    case RECALL_STEP:
      return mergeRight(state, {
        currentSubstep: payload.currentSubstep ?? 0,
        currentStep: payload.currentStep ?? 0,
        history: payload.history ?? [],
        maxStepReached: payload.maxStepReached ?? 1,
        currentComponent: payload.currentComponent ?? ''
      });
    case SET_USE_LAST_LOCATION:
      return mergeRight(state, {
        useLastLocation: payload.useLastLocation
      })
    default:
      return state;
  }
};

export const setRemoveStudentDialogOpen = ({ open, personId }: { open: boolean; personId?: string }) => ({
  type: SET_REMOVE_STUDENT_DIALOG_OPEN,
  payload: { personId, open },
});

export const setStartOverDialogOpen = ({ open, formId }: { open: boolean; formId?: string }) => ({
  type: SET_START_OVER_DIALOG_OPEN,
  payload: { open, formId },
});

export const registerSteps = ({ nSteps, totalSteps, steps }: { nSteps: nStep[]; totalSteps: number; steps: Step[] }) => ({
  type: REGISTER_STEPS,
  payload: { nSteps, totalSteps, steps },
});

export const setStep = ({ step, substep, subloop, component }: { step: nStep[]; substep: Substep; subloop: number; component: string }) => ({
  type: SET_STEP,
  payload: { step, substep, subloop, component },
});

export const resetHistory = (si: number, ssi: number | false) => ({
  type: RESET_HISTORY,
  payload: { si, ssi },
});

export const recallStep = ({ currentStep, currentSubstep, history, currentComponent, maxStepReached }: { currentStep?: number; currentSubstep?: number; history?: HistoryRecord[]; currentComponent?: string; maxStepReached?: number }) => ({
  type: RECALL_STEP,
  payload: { currentStep, currentSubstep, history, currentComponent, maxStepReached },
});

export const setForceLastSubloop = (forceLastSubloop: number) => ({
  type: SET_FORCE_LAST_SUBLOOP,
  payload: { forceLastSubloop },
});

export const backSync = () => ({
  type: BACK,
});

export const nextSync = (preconditionData: any) => ({
  type: NEXT,
  payload: { preconditionData },
});

export const setCustomQuestions = (customQuestions: CustomQuestionSections) => ({
  type: SET_CUSTOM_QUESTIONS,
  payload: { customQuestions },
})

export const setUseLastLocation = (useLastLocation: boolean) => ({
  type: SET_USE_LAST_LOCATION,
  payload: { useLastLocation }
})

export const setYearsOfExperience = ({ yearsOfExperience }: { yearsOfExperience: number }) => () => {
  decoupledDispatch('Form.setFormField', { path: ['yearsOfExperience'] }, yearsOfExperience)
  decoupledDispatch('User.updateYearsOfExp', { yearsOfExperience })
};

export const getOrgCustomQuestions = (orgId: string) => (dispatch: Dispatch<any>, _: GetState, { http }: ThunkDeps) => http
  .get<ServerCustomQuestions>(`/organizations/${orgId}/custom_questions`)
  .then(({ data }) => {
    if (data?.questions) {
      dispatch(setCustomQuestions(data.questions))
    }
  })
  .catch(httpErrorHandler('Failed to get org questions'));

export const back = () => (dispatch: Dispatch<any>, getState: GetState) => {
  const {
    totalSteps,
    currentStep,
    currentSubstep,
    nSteps,
    history,
    maxStepReached,
    currentComponent
  } = reducer(getState()[NewReportNamespace], backSync());
  const currentStepProgress = currentStep + nSteps.reduce((ac: number, cv: any, i: number) => i < currentStep ? ac + cv.nSubsteps : currentStep === i ? ac + currentSubstep : ac, 0);
  dispatch(backSync());
  decoupledDispatch('Form.setFormField', { path: ['progress'] }, Math.ceil((currentStepProgress + 1) / totalSteps * 100))
  decoupledDispatch('Form.setRecallStep', { currentStep, currentSubstep, history, maxStepReached, currentComponent })
  decoupledDispatch('Form.setValidationErrors', {})
};

export const next = (preconditionData: any) => (dispatch: Dispatch<any>, getState: GetState) => {
  const {
    totalSteps,
    currentStep,
    currentSubstep,
    currentComponent,
    nSteps,
    history,
    maxStepReached,
  } = reducer(getState()[NewReportNamespace], nextSync(preconditionData));
  const currentStepProgress = currentStep + nSteps.reduce((ac: number, cv: any, i: number) => i < currentStep ? ac + cv.nSubsteps : currentStep === i ? ac + currentSubstep : ac, 0);
  dispatch(nextSync(preconditionData))
  decoupledDispatch('Form.setFormField', { path: ['progress'] }, Math.ceil((currentStepProgress + 1) / totalSteps * 100))
  decoupledDispatch('Form.setRecallStep', { currentStep, currentSubstep, history, maxStepReached, currentComponent })
};

export const selectors = ({
  customQuestions: path([NewReportNamespace, 'customQuestions]']),
  registeredSteps: path<Step[]>([NewReportNamespace, 'steps']),
  currentStep: pathOr<number>(0, [NewReportNamespace, 'currentStep']),
  currentSubstep: pathOr<number>(0, [NewReportNamespace, 'currentSubstep']),
  currentSubloop: pathOr<number>(0, [NewReportNamespace, 'currentSubloop']),
  currentComponent: path([NewReportNamespace, 'currentComponent']),
  nSteps: path<nStep[]>([NewReportNamespace, 'nSteps']),
  history: path([NewReportNamespace, 'history']),
  maxStepReached: path([NewReportNamespace, 'maxStepReached']),
  startOverDialogOpen: path([NewReportNamespace, 'startOverDialogOpen']),
  startOverDialogFormId: path([NewReportNamespace, 'startOverDialogFormId']),
  removeStudentDialogOpen: path([NewReportNamespace, 'removeStudentDialogOpen']),
  removeStudentDialogPersonId: path([NewReportNamespace, 'removeStudentDialogPersonId']),
  secondsSinceLastActivity: path([NewReportNamespace, 'secondsSinceLastActivity']),
  useLastLocation: path<boolean>([NewReportNamespace, 'useLastLocation']),
});

export default reducer;
