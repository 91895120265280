import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import * as Config from '@ducks/config';
import * as User from '@ducks/user';
import { decoupledDispatch } from '@utility/decoupledDispatch';
import { CircularProgress } from '@material-ui/core';
import { Header } from '@components/custom';
import { throttle } from 'lodash';
import { offlineConfig } from '@engine/dependencies/localforage';
import { assignRoute } from '@utility/assignRoute';
import { compose, has } from 'ramda';
import { startSessionCheck, stopSessionCheck } from '@utility/sessionChecker';
import { Role, RoleNumber } from '@ducks/constants';
import './AuthProvider.scss';
import { Path } from '@engine/ducks/types';

/* eslint-disable react/jsx-first-prop-new-line, react/jsx-max-props-per-line */

const throttledCheckSession = throttle(() => decoupledDispatch('User.checkSession'), 1000, {
  trailing: true,
  leading: false,
});
const throttledOnlineCheck = throttle(() => decoupledDispatch('Config.checkOnlineStatus'), 1000, {
  trailing: true,
  leading: false,
});

interface Props extends PropsFromRedux {
  getPageComponent: (componentPath: string) => React.ComponentType<any>;
  hasPath: ({
    path, assignedRoles, isWorkingOffline, breakpoint
  }: {
    path?: string;
    assignedRoles?: string[];
    isWorkingOffline?: boolean;
    breakpoint?: string;
  }) => void;
  isPath: (pathname: string, path: string) => Boolean;
  unauthPaths: Record<
    string,
    {
      path: string;
      component: string;
      allowOffline?: boolean;
      exact?: boolean;
    }
  >;
  paths: Path;
  location: { pathname: string };
}

export const AuthProvider = ({
  getPageComponent,
  hasPath,
  isPath,
  assignedRoles,
  unauthPaths,
  authenticated,
  checkSession,
  paths,
  online,
  breakpoint,
  location: { pathname },
}: Props) => {
  const [workOffline, setWorkOffline] = useState('unknown');
  const [loadedRequirements, setLoadedRequirements] = useState({ workOffline: false, cachedRoles: false });
  const [cachedRoles, setCachedRoles] = useState([]);
  const authRoutesFiltered = Object.values(paths)
    .filter(p => hasPath({ path: p, assignedRoles: assignedRoles ?? cachedRoles ?? [], isWorkingOffline: workOffline === 'true', breakpoint }))
  const authRoutes = authRoutesFiltered
    .map((p: any, idx: number) => <Route {...p} key={`Route-${p.path}-${idx}`} component={getPageComponent(p.component)} />);
  const unauthRoutes = Object.keys(unauthPaths)
    .map(k => unauthPaths[k])
    .map((p, idx) => <Route {...p} key={`Route-${p.path}-${idx}`} component={getPageComponent(p.component)} />);
  const redirectCondition = pathname === '/' || !authRoutesFiltered.some(({ path }: any) => isPath(pathname, path));
  const setOfflineStatus = () => offlineConfig.getItem('work-offline')
    .then((wo: any) => wo !== workOffline ? setWorkOffline(wo) : null).finally(() => !loadedRequirements.workOffline ? setTimeout(() => setLoadedRequirements(r => ({ ...r, workOffline: true })), 300) : null);
  const setCachedUserRoles = () => offlineConfig.getItem('user').then((user: any) => {
    const mappedRoles = user?.roles?.map((role: any) => RoleNumber[role]);
    return JSON.stringify(mappedRoles) !== JSON.stringify(cachedRoles) ? setCachedRoles(mappedRoles) : null;
  }).finally(() => !loadedRequirements.cachedRoles ? setTimeout(() => setLoadedRequirements(r => ({ ...r, cachedRoles: true })), 300) : null);
  const finishedInitialLoad = loadedRequirements.workOffline && loadedRequirements.cachedRoles
  const loadingRoute = <Route
    path="/"
    // eslint-disable-next-line react/no-unstable-nested-components
    component={() => <>
      {pathname.includes(unauthPaths.Login.path) ?
        <div className="auth-provider__loading">
          <CircularProgress
            className="auth-provider__loading-spinner"
            disableShrink
            size={80}
            thickness={4} />
        </div> : <Header title="Contact">
          <div className="auth-provider__loading">
            <CircularProgress
              className="auth-provider__loading-spinner"
              disableShrink
              size={80}
              thickness={4} />
          </div>
        </Header>}
    </>}
  />;

  window.authDebug = { workOffline, authenticated, online, pathname, loadedRequirements };

  useEffect(() => {
    setOfflineStatus()
    setCachedUserRoles()
  });
  useEffect(() => {
    if (authenticated === 'unknown') {
      throttledCheckSession();
    }
    if (online === 'unknown') {
      throttledOnlineCheck();
    }
  });

  if (authRoutes.length === 0) {
    paths = { NotAvailable: { path: '/not-available', default: true, exact: true } }
    // eslint-disable-next-line react/no-unstable-nested-components
    authRoutes.push(<Route {...paths.NotAvailable} component={() => <Header title="Contact">
      <div className="auth-provider__not-available">
        Please contact your administrator to assign roles to your account.
      </div>
    </Header>} />)
  }
  if (workOffline === 'unknown') {
    setOfflineStatus()
    return loadingRoute;
  }
  if (!finishedInitialLoad) {
    return loadingRoute;
  }
  if (String(workOffline) === 'true' && !pathname.includes(unauthPaths.Login.path)) {
    stopSessionCheck();
    if (redirectCondition && !has('NotAvailable', paths) || has('NotAvailable', paths) && !pathname.includes('/not-available')) {
      return <Redirect to={Object.values(paths).find((r: any) => r.default)?.path ?? unauthPaths.Login.path} />;
    }
    return authRoutes;
  }
  if (authenticated === 'failure') {
    if (!pathname.includes(unauthPaths.Login.path)) {
      assignRoute(unauthPaths.Login.path);
    }
    stopSessionCheck();
    return unauthRoutes;
  }

  if (redirectCondition && !isPath(pathname, '/not-available')) {
    if (authenticated === 'success') {
      stopSessionCheck();

      if (assignedRoles?.some(role => role === Role.Reviewer) && breakpoint !== 'xs' && !assignedRoles?.some(role => role === Role.SuperAdmin)) {
        return <Redirect to={paths.Review.path} />;
      }
      if (assignedRoles?.some(role => role === Role.Analyst) && breakpoint !== 'xs') {
        return <Redirect to={paths.Visualization.path} />;
      }
      if (assignedRoles?.some(role => role === Role.Admin)) {
        return <Redirect to={paths.Users.path} />;
      }
      if (assignedRoles?.[0] === Role.Reviewer && assignedRoles.length === 1 && breakpoint === 'xs') {
        return <Redirect to={paths.ReviewUnavailable.path} />;
      }
      return <Redirect to={authRoutesFiltered.find((r: any) => r.default)?.path ?? authRoutesFiltered?.[0]?.path ?? unauthPaths.Login.path} />;
    }
  }
  if (authenticated === 'success') {
    startSessionCheck(checkSession);
    return authRoutes;
  }

  return loadingRoute;
};

const mapStateToProps = (state: any) => ({
  authenticated: User.selectors.authenticated(state),
  online: Config.selectors.online(state),
  assignedRoles: User.selectors.roles(state),
  breakpoint: Config.selectors.breakpoint(state)
});

const mapDispatchToProps = {
  checkSession: User.checkSession,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(AuthProvider);
