import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { TextField } from '@material-ui/core';
import { ConfirmDialog, Switch, LabelDropdown, createSnackNotification, AlertLevel } from '@components/common';
import { RaceorEthnicityofOfficerSelect } from '@components/custom';
import { paths as Paths } from '@components/custom/Routes/paths';
import { isPath } from '@components/custom/Routes/Routes';
import env from '@engine/env';
import { onEnter } from '@utility/keypressHelpers'
import { isEmpty, throttle } from 'lodash';
import { isAfter2024, Role } from '@engine/ducks/constants';
import { Props, connector } from './props';
import './UserSettingsDialog.scss';

const requiredMessage = 'Cannot be blank.';

const UserSettingsDialog = ({ closeNav, userSettingsDialog, setUserSettingsDialogOpen, setYearsOfExperience,
  setTrainingMode, saveUserSettings, setTheme, pre2024, earlyPost2024, assignedRoles, testingBanner, stopDateTime, templateName, enforceOfficerDemographics, getFormTemplate, defaultTemplateName, userId, isUserSettingsValid }: Props) => {
  const { raceOfOfficer, yearsOfExperience, trainingMode, theme, open } = userSettingsDialog || {};
  const disableTrainingModeSwitch = isPath(location.pathname, Paths.NewReport.path);
  const [errors, setErrors] = useState<{
    raceOfOfficer?: string;
  }>();

  const { userSettingsRequiredFields } = env().REACT_APP_FEATURE_FLAGS;
  const isPost2024 = () => earlyPost2024 || pre2024 || isAfter2024(stopDateTime, templateName);

  useEffect(() => {
    if (defaultTemplateName && assignedRoles?.includes(Role.Officer)) {
      getFormTemplate();
    }
  }, [defaultTemplateName])

  useEffect(() => {
    const forceOpenFor2024 = throttle(() => {
      const isDashboard = location.href.includes(Paths.Dashboard.path);
      if (isDashboard && isPost2024() && enforceOfficerDemographics && (userId && !isUserSettingsValid) && !open) {
        setUserSettingsDialogOpen({ open: true })
      }
    }, 1000, { trailing: true, leading: false });

    forceOpenFor2024();
    return () => forceOpenFor2024.cancel?.()
  }, [pre2024, enforceOfficerDemographics, isUserSettingsValid, userId])

  useEffect(() => {
    if (userSettingsRequiredFields.includes('race') && raceOfOfficer && raceOfOfficer.length > 0) {
      setErrors({ ...errors, raceOfOfficer: undefined })
    }
  }, [raceOfOfficer])

  const isValid = (): boolean => {
    if (!enforceOfficerDemographics) {
      return true;
    }
    let valid = true;
    let errorMessages = {};
    if (userSettingsRequiredFields.includes('race') && raceOfOfficer?.length === 0 && assignedRoles?.includes(Role.Officer)) {
      errorMessages = { ...errorMessages, raceOfOfficer: requiredMessage };
      valid = false;
    }
    if (!valid) {
      setErrors(errorMessages);
    }
    return valid;
  }

  const updateTrainingMode = useCallback(
    throttle((isTrainingMode) => {
      setTrainingMode(isTrainingMode)
    }, 500, { trailing: false, leading: true }),
    [])

  return (
    <ConfirmDialog
      heading="User Settings"
      open={!!open}
      closeDialog={() => {
        if (isPost2024()) {
          if (isValid()) {
            closeNav();
            setUserSettingsDialogOpen({ open: false });
          } else {
            createSnackNotification(AlertLevel.Warning, 'Please fill missing fields', 'CA DOJ requires additional information per 2024 RIPA requirements');
          }
        } else {
          closeNav();
          setUserSettingsDialogOpen({ open: false });
        }
      }}
      negativeAction={() => {
        if (isPost2024()) {
          if (isValid()) {
            closeNav();
            setUserSettingsDialogOpen({ open: false });
          } else {
            createSnackNotification(AlertLevel.Warning, 'Please fill missing fields', 'CA DOJ requires additional information per 2024 RIPA requirements');
          }
        } else {
          closeNav();
          setUserSettingsDialogOpen({ open: false });
        }
      }}
      positiveAction={() => {
        if (isPost2024()) {
          if (isValid()) {
            closeNav();
            saveUserSettings();
          } else {
            createSnackNotification(AlertLevel.Warning, 'Please fill missing fields', 'CA DOJ requires additional information per 2024 RIPA requirements');
          }
        } else {
          closeNav();
          saveUserSettings();
        }
      }}
      positiveText="Save"
      negativeText="Cancel"
    >
      <div className="user-settings-dialog" data-testid="user-settings-dialog">
        <div className="user-settings-dialog__row">
          <LabelDropdown
            className="user-settings-dialog__theme"
            data-testid="user-settings-dialog-theme"
            label="Theme"
            values={['Device Theme', 'Dark', 'Light'].map((y) => ({ value: y, text: y }))}
            onChange={({ target: { value } }) => setTheme(value as string)}
            renderValue={(selected) => selected as ReactNode}
            value={theme}
          />
          {!assignedRoles?.includes(Role.SuperAdmin) && (
            <div className={cn('user-settings-dialog__training-mode', { 'is-disabled': disableTrainingModeSwitch })}>
              <div className="user-settings-dialog__training-mode-label">Training Mode</div>
              <div className="user-settings-dialog__training-mode-disabled-text">Exit current form to toggle</div>
              <Switch
                className="user-settings-dialog__training-mode-switch"
                data-testid="user-settings-dialog-training-mode-switch"
                onText="ON"
                offText="OFF"
                value={!!trainingMode}
                disabled={!!disableTrainingModeSwitch}
                onChange={updateTrainingMode}
                disableChangeOnMount={true}
              />
            </div>
          )}
        </div>

        {!assignedRoles?.includes(Role.SuperAdmin) && (
          <>
            <div className="user-settings-dialog__hr" />
            <div className="user-settings-dialog__row">
              <div className="user-settings-dialog__years-of-experience-container">
                <div className="user-settings-dialog__years-of-experience-label">Years of Experience</div>
                <TextField
                  className="user-settings-dialog__years-of-experience"
                  InputProps={{
                    endAdornment: (
                      <div className="user-settings-dialog__years-of-experience-controls">
                        <div
                          className="user-settings-dialog__years-of-experience-controls-up"
                          data-testid="user-settings-dialog-years-of-experience-controls-up"
                          role="button"
                          tabIndex={0}
                          onClick={() =>
                            (yearsOfExperience && yearsOfExperience <= 49) || yearsOfExperience === 0
                              ? setYearsOfExperience({
                                  yearsOfExperience: yearsOfExperience + 1,
                                })
                              : null
                          }
                          onKeyUp={onEnter(() =>
                            (yearsOfExperience && yearsOfExperience <= 49) || yearsOfExperience === 0
                              ? setYearsOfExperience({
                                  yearsOfExperience: yearsOfExperience + 1,
                                })
                              : null
                          )}
                        >
                          +
                        </div>
                        <div
                          className="user-settings-dialog__years-of-experience-controls-down"
                          data-testid="user-settings-dialog__years-of-experience-controls-down"
                          role="button"
                          tabIndex={0}
                          onClick={() =>
                            yearsOfExperience && yearsOfExperience > 1
                              ? setYearsOfExperience({
                                  yearsOfExperience: yearsOfExperience - 1,
                                })
                              : null
                          }
                          onKeyUp={onEnter(() =>
                            yearsOfExperience && yearsOfExperience > 1
                              ? setYearsOfExperience({
                                  yearsOfExperience: yearsOfExperience - 1,
                                })
                              : null
                          )}
                        >
                          -
                        </div>
                      </div>
                    ),
                  }}
                  value={yearsOfExperience}
                  variant="outlined"
                  type="number"
                />
              </div>
              {(testingBanner || pre2024 || isPost2024()) && assignedRoles?.includes(Role.Officer) && (
                <RaceorEthnicityofOfficerSelect
                  raceOfOfficer={raceOfOfficer ?? ['']}
                  className="user-settings-dialog__race-of-officer"
                  label="Race of Officer"
                  error={!isEmpty(errors?.raceOfOfficer)}
                  errorMessage={errors?.raceOfOfficer}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ConfirmDialog>
  );
}

export default connector(UserSettingsDialog);
