import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { clone, dissoc } from 'ramda';
import cn from 'classnames';
import { isEqual, throttle } from 'lodash';
import { TextField } from '@material-ui/core';
import { LabelField, Button, LabelDropdown, Switch, ConfirmDialog, AlertLevel, createSnackNotification } from '@components/common';
import { Header } from '@components/custom';
import { onEnter } from '@utility/keypressHelpers';
import { camelize } from '@utility/camelize';
import shortId from 'shortid';
import { getComponent } from '@pages/NewReport/stepComponentMap';
import { CustomQuestion, CustomQuestionSection, Organization } from '@engine/ducks/types';
import { Props, connector } from './props';
import './CustomQuestions.scss';

/* eslint-disable object-property-newline */
/* eslint-disable react/jsx-pascal-case */

const customQuestionOptions = [
  {
    value: 'DynamicSelectForm', text: 'Select Form', data: {
      props: {
        title: 'Select Question',
        options: ['Option 1', 'Option 2'],
        resultPath: 'selectQuestionStorage',
        boxWidth: 0,
        boxHeight: 0,
        boxPadding: 0,
        multiple: true
      },
      helperText: 'Helper text',
      disabled: false,
      disabledDate: '',
      required: true
    }
  },
  {
    value: 'DynamicNumericForm', text: 'Numeric Form', data: {
      props: {
        title: 'Numeric Question',
        resultPath: 'numericQuestionStorage',
        defaultValue: '',
        max: 50,
        min: 0,
        counterToggle: false
      },
      helperText: 'Helper text',
      disabled: false,
      disabledDate: '',
      required: true
    }
  }, {
    value: 'DynamicDropdownForm', text: 'Dropdown Form', data: {
      props: {
        title: 'Dropdown Question',
        options: ['Option 1', 'Option 2'],
        resultPath: 'numericDropdownStorage',
        placeholder: 'Select an option...',
        autocomplete: true
      },
      helperText: 'Helper text',
      disabled: false,
      disabledDate: '',
      required: true
    }
  }, {
    value: 'DynamicTextForm', text: 'Text Form', data: {
      props: {
        title: 'Text Question',
        resultPath: 'textStorage',
        defaultValue: '',
        placeholder: 'Type here...',
        format: '',
        maxLength: 50,
        multiline: false,
      },
      helperText: 'Helper text',
      disabled: false,
      disabledDate: '',
      required: true
    }
  }
]

export const CustomQuestions = ({
  saveJsonDialog,
  jsonDialogJson,
  jsonDialogOpen,
  allowJsonDialogButton,
  setJsonDialogJson,
  setJsonDialogOpen,
  setOrgId,
  json,
  orgId,
  getQuestions,
  setQuestions,
  getOrganizations,
  organizations
}: Props) => {
  const [addCCDrop, setAddCCDrop] = useState('DynamicSelectForm');
  const [jsonError, setJsonError] = useState(false);
  const [parsedJson, setParsedJson] = useState<any>(null);
  const [isLive, setIsLive] = useState(false);
  const [sections, setSections] = useState<CustomQuestionSection[]>([]);
  const [animationLock, setAnimationLock] = useState(false);
  const [renderCC, setRenderCC] = useState<any>('');
  const [selectedCC, setSelectedCC] = useState({ sectionKey: '', questionKey: '' });
  const [selectedQuestion, setSelectedQuestion] = useState<CustomQuestion>({} as CustomQuestion);
  const [draggingQuestionId, setDraggingQuestionId] = useState('');
  const [optionDialog, setOptionDialog] = useState({ open: false, heading: '', content: '', fieldOpts: { k: '', isProps: false } });
  const ExampleRender = getComponent(renderCC?.component ?? '');
  const blank = new Image();
  blank.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';
  const maxTextFormLength = 500;
  const onDragOverQuestion = (e: React.DragEvent<HTMLElement>, section: CustomQuestionSection) => {
    e.stopPropagation();
    if (animationLock) {
      return;
    }

    const dragoverElement = (e.target as HTMLElement)
    const draggingElementPositionY = e.pageY;
    const dragoverElementPositionTop = dragoverElement.getBoundingClientRect().top;
    const dragoverElementPositionBottom = dragoverElement.getBoundingClientRect().bottom;

    if (dragoverElementPositionTop <= draggingElementPositionY &&
      draggingElementPositionY <= dragoverElementPositionBottom &&
      dragoverElement.id !== draggingQuestionId &&
      dragoverElement.id && draggingQuestionId) {
      const newSections = sections.map(s => {
        if (section.key === s.key) {
          const qReorder = clone(s.questions);
          const draggingIndex = qReorder.findIndex(qu => `custom-questions-question-${qu.key}` === draggingQuestionId);
          const dragOverIndex = qReorder.findIndex(qu => `custom-questions-question-${qu.key}` === dragoverElement.id);

          if (draggingIndex !== -1 && dragOverIndex !== -1) {
            const draggingOrder = qReorder[draggingIndex].order;
            const dragOverOrder = qReorder[dragOverIndex].order;
            qReorder[dragOverIndex].order = draggingOrder;
            qReorder[draggingIndex].order = dragOverOrder;

            return { ...s, questions: qReorder };
          }

          return s;
        }

        return s;
      })

      if (JSON.stringify(newSections) !== JSON.stringify(sections)) {
        setSections(newSections);
        setAnimationLock(true);
        setTimeout(() => setAnimationLock(false), 300);
      }
    }
  }

  const onAddQuestion = (section: CustomQuestionSection) => {
    const newSections = clone(sections);
    const customQuestionFound = customQuestionOptions.find(opt => opt.value === addCCDrop);
    if (customQuestionFound && customQuestionFound.data && customQuestionFound.value) {
      const { data, value } = customQuestionFound;
      const key = shortId.generate();
      const sectionQuestions = newSections.find(s => s.key === section.key)?.questions;
      if (sectionQuestions) {
        sectionQuestions.push({
          component: value, ...data, id: shortId.generate(), props: {
            ...data.props, resultPath: `${data.props.resultPath}-${key}`
          }, key, order: sectionQuestions.length
        });
        setSections(newSections);
      }
    }
  }

  const onDeleteQuestion = (section: CustomQuestionSection, question: CustomQuestion) => {
    const newSections = clone(sections).reduce((acc: any, s: CustomQuestionSection) => {
      if (s.key === section.key) {
        const questionIndex = s.questions.findIndex(q => q.key === question.key);
        const removedQuestion = s.questions.splice(questionIndex, 1)[0]
        const newQuestions = s.questions.reduce((accQ: any, qu: CustomQuestion) => {
          if ((qu.order && removedQuestion.order) && qu.order > removedQuestion.order) {
            return [...accQ, { ...qu, order: qu.order - 1 }]
          }
          return [...accQ, qu]
        }, [])
        return [...acc, { ...s, questions: newQuestions }]
      }
      return [...acc, s]
    }, []);
    setSections(newSections);
  }

  const getJsonFromSections = () => {
    const sectionsJson = clone(sections).reduce((acc, _, si, clonedSections) => {
      let orderedSection = clonedSections.find(sec => sec.order === si);
      if (orderedSection) {
        const sectionKey = orderedSection.key;
        orderedSection = dissoc('key', orderedSection);
        orderedSection = dissoc('order', orderedSection);
        return {
          ...acc, [sectionKey || '']: {
            ...orderedSection,
            title: orderedSection.title,
            questions: orderedSection.questions.map((__: any, i: number) => {
              let orderedQuestion = orderedSection?.questions.find(qu => qu.order === i);
              if (orderedQuestion) {
                orderedQuestion = dissoc('key', orderedQuestion);
                orderedQuestion = dissoc('order', orderedQuestion);
              }
              return orderedQuestion;
            }),
          },
        };
      }
      return clonedSections;
    }, {});
    return JSON.stringify({ ...sectionsJson, isLive });
  }

  const onSetQuestions = () => {
    try {
      const newJson = getJsonFromSections();

      if (newJson !== json) {
        setQuestions(newJson);
      }
    } catch (e) {
      createSnackNotification(AlertLevel.Error, 'Error', 'Something went wrong while saving questions');
    }
  }

  const onDragOverQuestionThrottled = useCallback(
    throttle(onDragOverQuestion, 50, { trailing: true, leading: false }),
    [draggingQuestionId, sections, animationLock]);

  useEffect(() => {
    getOrganizations({
      searchAndFilter: { sort: { column: 'name', direction: 'asc' } },
      pagination: { currentPage: 1, pageSize: 1000 }
    })
  }, []);

  useEffect(() => {
    try {
      if (json) {
        const obj = JSON.parse(json);
        if (JSON.stringify(obj) !== JSON.stringify(parsedJson)) {
          setParsedJson(obj);
        }
      }
      setJsonError(false);
    } catch (e) {
      setJsonError(true);
      setParsedJson(null);
    }
  }, [json])

  useEffect(() => {
    try {
      const newSections = Object.entries(parsedJson).sort().reduce((acc: any, [key, section]: [key: string, section: any], si: number) => {
        if (key === 'isLive') {
          setIsLive(section);
          return acc;
        }
        return [...acc, { key, order: si, ...section, questions: section.questions.map((q: CustomQuestion, i: number) => ({ ...q, key: shortId.generate(), order: i })) }]
      }, []);
      setSections(newSections);
    } catch (e) {
      setRenderCC('');
      setIsLive(false);
      setSections([]);
    }
  }, [parsedJson]);

  useEffect(() => {
    if (JSON.stringify(renderCC) !== JSON.stringify(selectedQuestion)) {
      setRenderCC({ ...selectedQuestion, props: { ...selectedQuestion.props, isAll: selectedCC.sectionKey === 'all', hasHelper: selectedQuestion.helperText !== '' } });
    }
    const newSelectedQuestions = sections.find(s => s.key === selectedCC.sectionKey)?.questions.find(qu => qu.key === selectedCC.questionKey) ?? {};
    if (JSON.stringify(newSelectedQuestions) !== JSON.stringify(selectedQuestion)) {
      setSelectedQuestion(newSelectedQuestions as CustomQuestion);
    }
  }, [selectedCC, sections, selectedQuestion]);

  useEffect(() => {
    if (selectedQuestion && !json?.includes(selectedQuestion.id ?? '')) {
      const newSections = clone(sections);
      const newQuestion = newSections
        .find(s => s.key === selectedCC.sectionKey)?.questions
        ?.find(qu => qu.key === selectedCC.questionKey);
      if (newQuestion) {
        newQuestion.props.resultPath = `${camelize(selectedQuestion.props.title)}<${selectedQuestion.key}>`;
        setSections(newSections);
      }
    }
  }, [selectedQuestion]);

  const calculateSectionTop = (currentSection: CustomQuestionSection) =>
    sections.reduce((acc, sec) => {
      if ((sec.order !== undefined && currentSection.order !== undefined) && sec.order < currentSection.order) {
        return acc + sec.questions.length * 75 + 90 + 10;
      }
      return acc;
    }, 0);

  return (
    <Header title="Contact">
      <div className="custom-questions" data-testid="custom-questions">
        <ConfirmDialog
          heading="Question JSON"
          open={!!jsonDialogOpen}
          closeDialog={() => setJsonDialogOpen({ open: false })}
          negativeAction={() => setJsonDialogOpen({ open: false })}
          positiveAction={saveJsonDialog}
          positiveText="Save to Org"
          negativeText="Close"
        >
          <LabelField
            label="Custom Question JSON"
            multiline
            rows={15}
            className="custom-questions__json"
            onChange={({ target: { value } }) => setJsonDialogJson(value)}
            defaultValue={jsonDialogJson}
            inputProps={{
              'data-testid': 'custom-questions-json'
            }}
          />
          <div className="custom-questions__json-warning">
            Never remove saved questions from an org! Use the disable setting on the question.
          </div>
        </ConfirmDialog>
        <ConfirmDialog
          heading={optionDialog.heading}
          open={optionDialog.open}
          closeDialog={() => setOptionDialog(s => ({ ...s, open: false }))}
          negativeAction={() => setOptionDialog(s => ({ ...s, open: false }))}
          positiveAction={() => {
            const { isProps, k } = optionDialog.fieldOpts;
            const newSections = clone(sections);
            const newQuestion = newSections
              .find(s => s.key === selectedCC.sectionKey)?.questions
              .find(qu => qu.key === selectedCC.questionKey);
            const v = optionDialog.content.split('\n').filter(l => l.trim() !== '');
            if (newQuestion) {
              if (isProps) {
                newQuestion.props[k] = v;
              } else {
                (newQuestion as any)[k] = v;
              }
              setSections(newSections);
            }
            setOptionDialog(s => ({ ...s, open: false }))
          }}
          positiveText="Save"
          negativeText="Close"
        >
          <LabelField
            label="One value per line"
            multiline
            rows={15}
            className="custom-questions__json"
            onChange={({ target: { value } }) => setOptionDialog(s => ({ ...s, content: value }))}
            defaultValue={optionDialog.content}
            inputProps={{
              'data-testid': 'custom-questions-json'
            }}
          />
        </ConfirmDialog>
        <div className="custom-questions__org-button-container">
          <LabelDropdown
            label="Organization"
            value={orgId}
            values={organizations.map((org: Organization) => ({ value: String(org.id), text: org.name }))}
            className="custom-questions__org-id"
            onChange={({ target: { value } }) => {
              setOrgId(value as number);
              getQuestions();
            }}
            inputProps={{
              'data-testid': 'custom-questions-org-id'
            }}
          />
          <Button
            aria-label="Get organization's question data"
            data-testid="custom-questions-get"
            className="custom-questions__get"
            disabled={!orgId}
            onClick={getQuestions}
            onKeyUp={onEnter(getQuestions)}
          >
            Get Questions
          </Button>
          {/* Call window.allowJsonDialog() to show button in UI */}
          {allowJsonDialogButton && <Button
            aria-label="Set organization's question data"
            data-testid="custom-questions-get-json"
            className="custom-questions__get-json"
            disabled={!json && !jsonError}
            onClick={() => setJsonDialogOpen({ open: true, json: getJsonFromSections() })}
            onKeyUp={onEnter(() => setJsonDialogOpen({ open: true, json: getJsonFromSections() }))}
          >
            Get Question JSON
          </Button>}
          <Button
            aria-label="Set organization's question data"
            data-testid="custom-questions-set"
            className="custom-questions__set"
            disabled={!json && !jsonError}
            onClick={onSetQuestions}
            onKeyUp={onEnter(onSetQuestions)}
          >
            Save Questions
          </Button>
          <div className={cn('custom-questions_json-error', { error: jsonError })}>
            JSON Parse Error
          </div>
          <div className={cn('custom-questions__unsaved-changes', { error: !isEqual(JSON.parse(json || '{}'), JSON.parse(getJsonFromSections() || '{}')) && !!json })}>
            Unsaved Changes!
          </div>
        </div>
        <div className="custom-questions__editor">
          {(!!json || !!jsonError) && (
            <>
              <Switch
                className="custom-questions__cc-list-live-switch"
                data-testid="custom-questions-cc-list-live-switch"
                onText="LIVE"
                offText="DISABLED"
                value={isLive}
                disabled={isLive && parsedJson?.isLive}
                onChange={(value) => setIsLive(value)}
              />
              <LabelDropdown
                label="Add Custom Question Type"
                className="custom-questions__add-cc"
                data-testid="custom-questions-add-cc"
                values={customQuestionOptions}
                onChange={({ target: { value } }) => setAddCCDrop(value as string)}
                value={addCCDrop}
              />
            </>
          )}
          <div
            className="custom-questions__cc-list"
            style={{ height: sections.reduce((acc, s) => acc + s.questions.length * 75 + 90 + 10, 0) }}
          >
            {sections.map((section, secIdx) => <div
              className="custom-questions__cc-list-section"
              style={{ height: section.questions.length * 75 + 90, top: calculateSectionTop(section) }}
              id={`custom-questions-section-${section.key}`}
              key={`custom-questions-section-${section.key}`}
            >
              <TextField
                className="custom-questions__cc-list-section-title-input"
                key={`CustomQuestionsEditNumber-${section.key}`}
                onChange={({ target: { value } }) => {
                  const newSections = clone(sections);
                  const foundSection = newSections.find(sec => sec.key === section.key);
                  if (foundSection) {
                    foundSection.title = value;
                    setSections(newSections);
                  }
                }}
                value={section.title}
                inputProps={{
                  'data-testid': `custom-questions__cc-section-title-${section.key}`
                }}
              />
              {section.questions.map((q: CustomQuestion, i: number) =>
                <div
                  role="button"
                  aria-label="Custom Question"
                  className={cn('custom-questions__cc-list-section-question', { active: q.key === selectedCC.questionKey, disabled: q.disabled })}
                  id={`custom-questions-question-${q.key}`}
                  key={`custom-questions-question-${q.key}`}
                  data-testid={`custom-questions-question-${secIdx}-${q.key}`}
                  tabIndex={0}
                  draggable
                  style={{ top: (q?.order ?? 0) * 75 + 45 }}
                  onClick={() => setSelectedCC({ sectionKey: section.key || '', questionKey: q.key || '' })}
                  onKeyUp={onEnter(() => setSelectedCC({ sectionKey: section.key || '', questionKey: q.key || '' }))}
                  onDragStart={e => {
                    e.dataTransfer.setDragImage(blank, 0, 0);
                    setDraggingQuestionId((e.target as HTMLElement).id);
                  }}
                  onDragEnd={() => {
                    onDragOverQuestionThrottled.cancel();
                    setDraggingQuestionId('');
                  }}
                  onDragOver={e => onDragOverQuestionThrottled(e, section)}
                >
                  <div className="material-icons"> drag_indicator </div>
                  <div
                    role="button"
                    aria-label="Delete Custom Question"
                    className="material-icons custom-questions__cc-list-section-question-delete"
                    data-testid={`custom-question-cc-list-section-question-delete-${secIdx}-${i}`}
                    tabIndex={0}
                    style={{ display: ((draggingQuestionId || json?.includes(q.id || '')) && parsedJson.isLive ? 'none' : null) || undefined }}
                    onClick={() => onDeleteQuestion(section, q)}
                    onKeyUp={onEnter(() => onDeleteQuestion(section, q))}
                  >
                    delete
                    </div>
                  <div className="custom-questions__cc-list-section-question-container">
                    <div className="custom-questions__cc-list-section-question-title" data-testid={`custom-questions-question-title-${q.key}`}>
                      {q?.props?.title || `Question ${i}`}
                    </div>
                    <div className="custom-questions__cc-list-section-question-type">
                      {customQuestionOptions.find(o => o.value === q?.component)?.text || 'Unknown Form'}
                    </div>
                  </div>
                </div>)}
              <div
                role="button"
                aria-label="Add Question"
                tabIndex={0}
                className="custom-questions__cc-add-question"
                onClick={() => onAddQuestion(section)}
                onKeyUp={onEnter(() => onAddQuestion(section))}
                data-testid={`custom-questions-cc-add-question-${secIdx}`}
              >
                <div className="material-icons"> add </div>
                <div className="custom-questions__cc-add-question-text"> Add Question </div>
              </div>
            </div>
            )}
          </div>
          {selectedCC.questionKey && selectedCC.sectionKey && <div className="custom-questions__cc-edit">
            {Object.entries(selectedQuestion).map(kv => {
              const [qKey, qVal]: [qKey: string, qVal: any] = kv
              if (['order', 'key', 'component', 'id'].includes(qKey)) {
                return
              }
              const setQuestionData = (qK: string, v: any, isProps: boolean) => {
                const newSections = clone(sections);
                const newQuestion = newSections
                  .find((s: CustomQuestionSection) => s.key === selectedCC.sectionKey)?.questions
                  .find(qu => qu.key === selectedCC.questionKey);
                if (newQuestion) {
                  if (isProps) {
                    newQuestion.props[qK] = v;
                  } else {
                    if (qK === 'disabled') {
                      newQuestion.disabledDate = v ? dayjs().toISOString() : '';
                    }
                    (newQuestion as any)[qK] = v;
                  }
                  setSections(newSections);
                }
              }
              const renderFormField = (k: string, v: any, isProps: boolean) => {
                const isTextMaxLength = k === 'maxLength' && isProps;
                if (Array.isArray(v)) {
                  return <div className="custom-questions__cc-edit-array-container" key={`CustomQuestionsArrayContainer-${k}`}>
                    <div className="custom-questions__cc-edit-array-title">
                      {k}
                      <div className="custom-questions__cc-edit-array-controls">
                        <div
                          className="custom-questions__cc-edit-array-add"
                          role="button"
                          aria-label="Add option"
                          tabIndex={0}
                          onClick={() => {
                            const newArray = clone(v);
                            newArray.push('');
                            setQuestionData(k, newArray, isProps);
                          }}
                          onKeyUp={onEnter(() => {
                            const newArray = clone(v);
                            newArray.push('');
                            setQuestionData(k, newArray, isProps);
                          })}
                        >
                          <div className="material-icons"> add </div>
                          {`Add ${k}`}
                        </div>
                        <div
                          className="custom-questions__cc-edit-array-import"
                          role="button"
                          aria-label="Import option"
                          tabIndex={0}
                          onClick={() => setOptionDialog({ open: true, heading: `Import ${k}`, content: v.join('\n'), fieldOpts: { k, isProps } })}
                          onKeyUp={onEnter(() => setOptionDialog({ open: true, heading: `Import ${k}`, content: v.join('\n'), fieldOpts: { k, isProps } }))}
                        >
                          Import
                        </div>
                      </div>
                    </div>
                    {v.length > 0 && <div className="custom-questions__cc-edit-array">
                      {v.map((opt, optI) => <div className="custom-questions__cc-edit-array-option-container" key={`CustomQuestionsCcEditArrayOptionContainer-${optI}`}>
                        <div
                          className="material-icons"
                          role="button"
                          aria-label="Add Question"
                          tabIndex={0}
                          key={`CustomQuestionsEditArrayDelete-${optI}-${k}`}
                          onClick={() => {
                            const newArray = clone(v);
                            newArray.splice(optI, 1);
                            setQuestionData(k, newArray, isProps);
                          }}
                          onKeyUp={onEnter(() => {
                            const newArray = clone(v);
                            newArray.splice(optI, 1);
                            setQuestionData(k, newArray, isProps);
                          })}
                        >
                          close
                        </div>
                        <LabelField
                          key={`CustomQuestionsEditArray-${optI}-${k}`}
                          label={`${k} ${optI + 1}`}
                          className="custom-questions__cc-edit-array-option"
                          onChange={({ target: { value } }) => {
                            const newArray = clone(v);
                            newArray.splice(optI, 1, value);
                            setQuestionData(k, newArray, isProps);
                          }}
                          value={opt}
                          inputProps={{
                            'data-testid': `custom-questions__cc-array-${k}-${optI}`
                          }}
                        />
                      </div>)}
                    </div>}
                  </div>
                }
                if (typeof v === 'number') {
                  return <LabelField
                    label={!isTextMaxLength ? k : `${k} (max ${maxTextFormLength})`}
                    className="custom-questions__cc-edit-number"
                    key={`CustomQuestionsEditNumber-${k}`}
                    onChange={({ target: { value } }) => {
                      if (isTextMaxLength && Number(value) > maxTextFormLength) {
                        return;
                      }
                      setQuestionData(k, Number(value), isProps)
}}
                    value={v}
                    type="number"
                    inputProps={{
                      'data-testid': `custom-questions__cc-edit-number-${k}`,
                      ...(isTextMaxLength && { min: 0, max: maxTextFormLength })
                    }}
                  />
                }
                if (typeof v === 'string' && k !== 'disabledDate') {
                  return <LabelField
                    label={k}
                    className="custom-questions__cc-edit-string"
                    key={`CustomQuestionsEditString-${k}`}
                    onChange={({ target: { value } }) => setQuestionData(k, value, isProps)}
                    disabled={k === 'resultPath'}
                    value={v}
                    inputProps={{
                      'data-testid': `custom-questions__cc-edit-string-${k}`
                    }}
                  />
                }
                if (typeof v === 'boolean') {
                  return <div className="custom-questions__cc-edit-bool-container" key={`CustomQuestionsEditBool-${k}`}>
                    <div className="custom-questions__cc-edit-bool-title">
                      {k}
                    </div>
                    <Switch
                      className="custom-questions__cc-edit-bool"
                      data-testid={`custom-questions__cc-edit-bool-${k}`}
                      onText="YES"
                      offText="NO"
                      value={v}
                      onChange={value => setQuestionData(k, value, isProps)}
                    />
                  </div>
                }
              }
              if (qKey === 'props') {
                return Object.entries(qVal).map(kvp => {
                  const [pKey, pVal] = kvp
                  return renderFormField(pKey, pVal, true);
                })
              }
              return renderFormField(qKey, qVal, false);
            })}
          </div>}
          <div className="custom-questions__cc-render-title-container">
            {ExampleRender && <div className="custom-questions__cc-render-title">
              PREVIEW
            </div>}
            <div className="custom-questions__cc-render ripa-form-container">
              {ExampleRender && <ExampleRender {...(renderCC?.props ?? {})} />}
              {ExampleRender?.helper && renderCC?.helperText && <ExampleRender.helper HelperTextInfo={renderCC?.helperText} />}
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default connector(CustomQuestions);
