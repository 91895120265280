let sessionCheckInterval: NodeJS.Timer | undefined;

export const startSessionCheck = (checkSession: () => void) => {
  if (!sessionCheckInterval) {
    sessionCheckInterval = setInterval(() => {
      checkSession()
    }, 60000)
  }
}

export const stopSessionCheck = () => {
  clearInterval(sessionCheckInterval);
  sessionCheckInterval = undefined;
}
