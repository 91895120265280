import React, { Fragment, useState, useEffect, KeyboardEventHandler } from 'react';
import cn from 'classnames';
import { Switch } from '@components/common';
import { FormControl, TextField, Tooltip, Select, MenuItem } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { onEnter } from '@utility/keypressHelpers';
import offenseCode from '@utility/offenseCode';
import dayjs from 'dayjs';
import { Props, connector } from './props';
import './RipaResultOfStopForm.scss';

/* eslint-disable react/jsx-one-expression-per-line */

type Offense = ReturnType<typeof offenseCode>[0];

const RipaResultOfStopForm = ({
  resultOfStopEnum,
  setResultOfStopCodeSection,
  toggleResultOfStop,
  resultOfStop,
  anyResultOfStop,
  setAnyResultOfStop,
  currentSubloop,
  labels,
  student,
  numberOfPeople,
  useSameActionTakenForAll,
  resultOfStopCodeSection,
  useSameActionForAllPersonIndex,
  breakpoint,
  flags: { pre2024 },
}: Props) => {
  const [resultTabIndex, setResultTab] = useState<number>(0);
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;
  const personCodeSections = resultOfStopCodeSection?.[personIndex];

  const hideMobileKeyboard: KeyboardEventHandler = (e) => {
    if (e.keyCode === 13) {
      const el = document.getElementsByClassName('ripa-result-of-stop-form')[0];
      if (el instanceof HTMLElement) {
        el.click();
      }
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (resultOfStoplabel: any, index: number) => {
    const newCodeSections = [...resultOfStopCodeSection[personIndex][resultOfStoplabel]];
    newCodeSections.splice(index, 1);
    setResultOfStopCodeSection(resultOfStoplabel, newCodeSections, personIndex);
  };

  // handle click event of the Add button
  const handleAddClick = (resultOfStoplabel: any) => {
    const newCodeSections = [...resultOfStopCodeSection[personIndex][resultOfStoplabel], ''];
    setResultOfStopCodeSection(resultOfStoplabel, newCodeSections, personIndex);
  };

  useEffect(() => {
    // Convert old forms to use arrays for code sections per result type
    resultOfStopCodeSection?.forEach((cs, pi) => {
      if (cs) {
        Object.keys(cs).forEach((resultType) => {
          const codes = cs[resultType];
          if (!Array.isArray(codes)) {
            setResultOfStopCodeSection(resultType, [codes], pi);
          }
        });
      }
    });
  }, []);

  const filterOptions = createFilterOptions<Offense>({
    matchFrom: 'any',
    limit: 50,
  });

  return (
    <div className="ripa-result-of-stop-form" data-testid="ripa-result-of-stop-form">
      <div
        className={cn('ripa-result-of-stop-form__person', {
          'same-for-all': useSameActionTakenForAll,
        })}
      >
        <div className="material-icons">person</div>
        <div className="ripa-result-of-stop-form__person-label">{labels?.[currentSubloop] || currentSubloop + 1}</div>
        <div className="ripa-result-of-stop-form__person-same-for-all">Same For All</div>
        <div className="ripa-result-of-stop-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-result-of-stop-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-result-of-stop-form__title">What was the result of the stop?</div>
      <div className="ripa-result-of-stop-form__any-results">
        <div className="ripa-result-of-stop-form__any-results-line" />
        <div className="ripa-result-of-stop-form__any-results-text">ANY RESULTS</div>
        <Switch
          className="ripa-result-of-stop-form__any-results-switch"
          data-testid="ripa-result-of-stop-form-any-results-switch"
          onText="YES"
          offText="NO"
          value={anyResultOfStop?.[personIndex]}
          onChange={(v) => setAnyResultOfStop(v, personIndex)}
        />
        <div className="ripa-result-of-stop-form__any-results-line" />
      </div>
      <div
        className={cn('ripa-result-of-stop-form__tab-container', {
          hidden: !anyResultOfStop?.[personIndex],
        })}
      >
        <div className="ripa-result-of-stop-form__tab-container-line" />
        {(student?.[personIndex] ? ['CITATION', 'ARREST', 'OTHER', 'K-12'] : ['CITATION', 'ARREST', 'OTHER']).map((tab, tabIndex) => (
          <div
            role="button"
            aria-label={`Result of stop tab ${tab}`}
            tabIndex={0}
            className={cn('ripa-result-of-stop-form__action-tab', {
              active: tabIndex === resultTabIndex,
            })}
            key={`RipaActionTakenFormTab-${tabIndex}`}
            data-testid={`ripa-result-of-stop-form-action-tab-${tabIndex}`}
            onClick={() => setResultTab(tabIndex)}
            onKeyUp={onEnter(() => setResultTab(tabIndex))}
          >
            {tab}
          </div>
        ))}
        <FormControl
          variant="outlined"
          className={cn('ripa-result-of-stop-form__action-dropdown', {
            hidden: !anyResultOfStop?.[personIndex],
          })}
        >
          <Select
            value={resultTabIndex}
            onChange={(e) => {
              const { value } = e.target;
              return setResultTab(parseInt(value as string, 10));
            }}
            native={breakpoint === 'xs'}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {(student?.[personIndex] ? ['CITATION', 'ARREST', 'OTHER', 'K-12'] : ['CITATION', 'ARREST', 'OTHER']).map((tab, tabIndex) => {
              const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
              return (
                <CompName key={`RipaResultOfStopDropdownItem-${tabIndex}`} value={tabIndex}>
                  {tab}
                </CompName>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <div
        className={cn('ripa-result-of-stop-form__select-box-content', {
          hidden: !anyResultOfStop?.[personIndex],
        })}
      >
        {[
          [...(pre2024 && (resultOfStopEnum.VerbalWarning && resultOfStopEnum.WrittenWarning) ? [resultOfStopEnum.VerbalWarning, resultOfStopEnum.WrittenWarning] : [resultOfStopEnum.Warning]), resultOfStopEnum.Citation, resultOfStopEnum.InFieldCiteAndRelease],
          [resultOfStopEnum.CustodialArrestWithoutWarrant, resultOfStopEnum.CustodialArrestOutstandingWarrant],
          [
            resultOfStopEnum.ContactedParent,
            resultOfStopEnum.FieldInterviewCardCompleted,
            resultOfStopEnum.NoncriminalTransport,
            resultOfStopEnum.PsychiatricHold,
            resultOfStopEnum.ContactedHomelandSecurity,
          ],
          [resultOfStopEnum.ReferralToSchoolAdmin, resultOfStopEnum.ReferralToSchoolStaff],
        ][resultTabIndex].map(({ value }, resultOfStopIndex) => (
          <Fragment key={`RipaActionTakenFormBox-${resultTabIndex}-${resultOfStopIndex}`}>
            <div className="ripa-result-of-stop-form__result-container">
              <div
                className={cn('ripa-result-of-stop-form__select-box', {
                  active: resultOfStop?.[personIndex]?.includes(value),
                  'has-code-sections': resultTabIndex === 0 || (resultTabIndex === 1 && resultOfStopIndex === 0),
                  'variable-height': resultTabIndex === 2,
                })}
                onClick={() => {
                  toggleResultOfStop(value, personIndex);
                  setResultOfStopCodeSection(value, [''], personIndex);
                }}
                onKeyUp={onEnter(() => {
                  toggleResultOfStop(value, personIndex);
                  setResultOfStopCodeSection(value, [''], personIndex);
                })}
                data-testid={`ripa-result-of-stop-form-box-${resultTabIndex}-${resultOfStopIndex}`}
                role="button"
                tabIndex={0}
              >
                <div dangerouslySetInnerHTML={{ __html: value }} />
              </div>
              {Array.isArray(personCodeSections?.[value]) &&
                personCodeSections?.[value]?.map((v: any, i: number) => (
                  <div
                    className={cn('ripa-result-of-stop-form__button-container', {
                      enable: resultOfStop?.[personIndex]?.includes(value) && (resultTabIndex === 0 || (resultTabIndex === 1 && resultOfStopIndex === 0)),
                    })}
                    key={`RipaResultOfStopCodeSectionContainer-${i}`}
                  >
                    <div className="ripa-result-of-stop-form__code-autocomplete-container">
                      <Autocomplete<Offense>
                        autoHighlight
                        autoSelect
                        inputValue={v}
                        onClose={() => {
                          const el = document.getElementsByClassName('ripa-result-of-stop-form')[0];
                          if (el instanceof HTMLElement) {
                            el.click();
                          }
                        }}
                        className={cn('ripa-result-of-stop-form__code-autocomplete', {
                          'has-code-sections': resultTabIndex === 0 || (resultTabIndex === 1 && resultOfStopIndex === 0),
                          show: resultOfStop?.[personIndex]?.includes(value),
                        })}
                        classes={{ option: 'code' }}
                        data-testid={`ripa-result-of-stop-form-autocomplete-${resultTabIndex}-${resultOfStopIndex}`}
                        onInputChange={(_, vc) => {
                          const newCodeSections = [...personCodeSections[value]];
                          newCodeSections.splice(i, 1, vc);
                          setResultOfStopCodeSection(value, newCodeSections, personIndex);
                        }}
                        blurOnSelect={true}
                        defaultValue={offenseCode(dayjs()).find((vl) => vl.label === resultOfStopCodeSection?.[personIndex]?.[value]?.[i])}
                        getOptionLabel={o => o.label}
                        getOptionDisabled={o => (personCodeSections[value] || []).includes(o.label)}
                        renderOption={o => (
                          <>
                            {o.Repealed !== '' ? (
                              <Tooltip title={`Offense Code Repealed ${o.Repealed}`}>
                                <span data-type={o.TypeOfCharge ?? (o as any /* legacy field */).OffenseTypeofCharge} style={{ textDecoration: 'line-through' }}>
                                  {o.label}
                                  {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                                </span>
                              </Tooltip>
                            ) : (
                              <span data-type={o.TypeOfCharge ?? (o as any /* legacy field */).OffenseTypeofCharge}>
                                {o.label}
                                {o.TypeOfStatute ? ` (${o.TypeOfStatute})` : ''}
                              </span>
                            )}
                          </>
                        )}
                        options={offenseCode(dayjs())}
                        filterOptions={filterOptions}
                        disabled={!resultOfStop?.[personIndex]?.includes(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Code section(s) related..."
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              'data-testid': `ripa-result-of-stop-form-autocomplete-input-${resultTabIndex}-${resultOfStopIndex}`,
                            }}
                            onKeyDown={(e) => hideMobileKeyboard(e)}
                          />
                        )}
                      />
                    </div>
                    {i < personCodeSections?.[value]?.length - 1 && personCodeSections[value][i] !== '' && (
                      <div
                        className="ripa-result-of-stop-form__remove material-icons"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleRemoveClick(value, i)}
                        onKeyUp={onEnter(() => handleRemoveClick(value, i))}
                      >
                        close
                      </div>
                    )}
                    {i === personCodeSections[value]?.length - 1 && personCodeSections[value]?.length < 5 && personCodeSections[value][i] !== '' && (
                      <div className="ripa-result-of-stop-form__add" role="button" tabIndex={0} onClick={() => handleAddClick(value)} onKeyUp={onEnter(() => handleAddClick(value))}>
                        +
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div
              className={cn('ripa-result-of-stop-form__select-box-line', {
                'has-code-sections': resultTabIndex === 0 || (resultTabIndex === 1 && resultOfStopIndex === 0),
              })}
            />
          </Fragment>
        ))}
      </div>
      <div
        className={cn('ripa-result-of-stop-form__no-result', {
          hidden: anyResultOfStop?.[personIndex],
        })}
      >
        No Results
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select ALL that apply.</div>
  </div>
);

RipaResultOfStopForm.helper = Helper;
export default connector(RipaResultOfStopForm);
