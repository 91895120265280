import dayjs from 'dayjs'
import qs from 'qs'
import Papa from 'papaparse';
import { assoc, mergeRight, compose, map, path, pathOr, filter } from 'ramda'
import { forEachSeries } from 'p-iteration'
import { createSnackNotification, AlertLevel } from '@components/common'
import { httpErrorHandler } from '@utility/httpErrorHandler'
import { downloadCsv } from '@utility/downloadCSV';
import { customAlphabet } from 'nanoid'
import { UserNamespace, UsersNamespace, Role, RoleString, RoleNumber } from './constants'
import { Action, Dispatch, GetState, Pagination, Person, SearchAndFilter, User, ThunkDeps, ServerUsers, UserDialog, Loading as LoadingUserStatus } from './types'

export const SET_USERS = `${UsersNamespace}/SET_USERS`
export const SET_MORE_USERS = `${UsersNamespace}/SET_MORE_USERS`
export const SET_PAGE_META = `${UsersNamespace}/SET_PAGE_META`
export const SET_PAGINATION = `${UsersNamespace}/SET_PAGINATION`
export const CHECK_USERS_ROW = `${UsersNamespace}/CHECK_USERS_ROW`
export const SELECT_ALL_USERS_ROWS = `${UsersNamespace}/SELECT_ALL_USERS_ROWS`
export const EXPAND_ALL_USERS_ROWS = `${UsersNamespace}/EXPAND_ALL_USERS_ROWS`
export const SET_ROLE = `${UsersNamespace}/SET_ROLE`
export const SET_REVIEWER = `${UsersNamespace}/SET_REVIEWER`
export const SET_ADD_USER_DIALOG_OPEN = `${UsersNamespace}/SET_ADD_USER_DIALOG_OPEN`
export const SET_SEARCH = `${UsersNamespace}/SET_SEARCH`
export const SET_LOADING_USERS = `${UsersNamespace}/SET_LOADING_USERS`
export const SET_DISABLE_USER_DIALOG_OPEN = `${UsersNamespace}/SET_DISABLE_USER_DIALOG_OPEN`
export const SET_SORT = `${UsersNamespace}/SET_SORT`
export const SET_ADD_USER_FIELD = `${UsersNamespace}/SET_ADD_USER_FIELD`
export const SET_EDIT_USER_DIALOG_OPEN = `${UsersNamespace}/SET_EDIT_USER_DIALOG_OPEN`
export const SET_REMOVING_REVIEWER_DIALOG_OPEN = `${UsersNamespace}/SET_REMOVING_REVIEWER_DIALOG_OPEN`
export const SET_REVIEWERS = `${UsersNamespace}/SET_REVIEWERS`
export const SET_REVIEWEES = `${UsersNamespace}/SET_REVIEWEES`
export const SET_BULK_REVIEWER_SELECTION = `${UsersNamespace}/SET_BULK_REVIEWER_SELECTION`
export const SET_EDIT_USER_FIELD = `${UsersNamespace}/SET_EDIT_USER_FIELD`
export const SET_CHANGE_PASSWORD_DIALOG_FIELD = `${UsersNamespace}/SET_CHANGE_PASSWORD_DIALOG_FIELD`
export const SET_CHANGE_PASSWORD_DIALOG_OPEN = `${UsersNamespace}/SET_CHANGE_PASSWORD_DIALOG_OPEN`
export const SET_ADD_REVIEWERS_IN_PROGRESS = `${UsersNamespace}/SET_ADD_REVIEWERS_IN_PROGRESS`
export const SET_ADD_REVIEWERS_REMAINING = `${UsersNamespace}/SET_ADD_REVIEWERS_REMAINING`

const INITIAL_STATE = {
  changePasswordDialog: {
    userId: '',
    password: '',
    confirmPassword: '',
  },
  addUserDialog: {
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    officerId: '',
    password: '',
    yearsOfExperience: '',
    isOfficerRole: true,
    isReviewerRole: false,
    isAnalystRole: false,
    isAdminRole: false,
    isSuperAdminRole: false,
    assignedReviewers: [] as any[],
  },
  editUserDialog: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    officerId: '',
    yearsOfExperience: '',
    isOfficerRole: false,
    isReviewerRole: false,
    isAnalystRole: false,
    isAdminRole: false,
    assignedReviewers: [],
    reviewees: [],
    password: '',
    changePassword: '',
  },
  disableUserDialog: {
    id: '',
    firstname: '',
    lastname: '',
  },
  loading: {
    getUsers: false,
    addReviewers: false,
  },
  dialog: {
    addUserDialogOpen: false,
    editUserDialogOpen: false,
    removingReviewerDialogOpen: false,
    disableUserDialogOpen: false,
    changePasswordDialogOpen: false,
  },
  roleSelection: '-1',
  reviewerSelection: 'all',
  bulkReviewerSelection: [],
  selectAllChecked: false,
  checkedUsersRows: [],
  pagination: {
    totalPages: 1,
    totalCount: 10,
    currentPage: 1,
    pageSize: 10,
  },
  searchAndFilter: {
    search: undefined,
    sort: {
      column: undefined,
      direction: undefined,
    },
  },
  addReviewersRemaining: 0,
  users: [],
  reviewers: [],
}

export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action

  switch (type) {
    case SET_EDIT_USER_FIELD:
      return assoc('editUserDialog', { ...state.editUserDialog, ...payload.editUserFormFieldUpdates }, state)
    case SET_REVIEWEES:
      return mergeRight(state, {
        editUserDialog: { ...state.editUserDialog, reviewees: payload.reviewees },
      })
    case SET_REVIEWERS:
      return assoc('reviewers', payload.reviewers, state)
    case SET_ADD_USER_FIELD:
      return assoc('addUserDialog', { ...state.addUserDialog, ...payload.newUserFormFieldUpdates }, state)
    case SET_ADD_USER_DIALOG_OPEN:
      return mergeRight(state, {
        dialog: { ...state.dialog, addUserDialogOpen: payload.open },
        addUserDialog: payload.open ? INITIAL_STATE.addUserDialog : state.addUserDialog,
      })
    case SET_DISABLE_USER_DIALOG_OPEN: {
      const disableUser: any = state.users.find((u: User) => u.id === payload.id);
      return payload.closeOthers
        ? mergeRight(state, {
          dialog: { ...INITIAL_STATE.dialog, disableUserDialogOpen: payload.open },
          disableUserDialog: {
            id: payload.id,
            firstname: disableUser?.first_name,
            lastname: disableUser?.last_name,
          },
        })
        : mergeRight(state, {
          dialog: { ...state.dialog, disableUserDialogOpen: payload.open },
          disableUserDialog: {
            id: payload.id,
            firstname: disableUser?.first_name,
            lastname: disableUser?.last_name,
          },
        })
    }
    case SET_EDIT_USER_DIALOG_OPEN: {
      // TODO: editUser was typed as User, but that's not right if it's defaulting to editUserDialog - needs to be looked at
      const editUser: any = state.users.find((u: User) => u?.id.toString() === payload.id || u?.id === payload.id) ?? { ...state.editUserDialog };
      return mergeRight(state, {
        dialog: { ...state.dialog, editUserDialogOpen: payload.open },
        editUserDialog: {
          id: editUser?.id,
          firstname: editUser?.first_name,
          lastname: editUser?.last_name,
          email: editUser?.email,
          username: editUser?.username,
          officerId: editUser?.officer_id,
          yearsOfExperience: editUser?.years_of_experience,
          isOfficerRole: editUser?.roles?.includes(RoleString[Role.Officer]),
          isReviewerRole: editUser?.roles?.includes(RoleString[Role.Reviewer]),
          isAnalystRole: editUser?.roles?.includes(RoleString[Role.Analyst]),
          isAdminRole: editUser?.roles?.includes(RoleString[Role.Admin]),
          isSuperAdminRole: editUser?.roles?.includes(RoleString[Role.SuperAdmin]),
          // TODO: fix this any when type above is worked out
          assignedReviewers: editUser?.reviewers?.sort((a: User, b: User) => a.last_name.localeCompare(b.last_name, 'en', { sensitivity: 'base' })).map((r: any) => `${r?.id}`),
          reviewees: INITIAL_STATE.editUserDialog.reviewees,
          password: editUser?.password,
          confirmPassword: editUser?.confirmPassword,
          disabled: editUser?.['access_locked?'],
        },
      })
    }
    case SET_REMOVING_REVIEWER_DIALOG_OPEN:
      return mergeRight(state, {
        dialog: { ...state.dialog, removingReviewerDialogOpen: payload.open },
        removingReviewerDialogOpen: payload.open ? INITIAL_STATE.dialog.removingReviewerDialogOpen : state.dialog.removingReviewerDialogOpen,
      })
    case SET_ROLE:
      return assoc('roleSelection', payload.roleSelection, state)
    case SET_REVIEWER:
      return assoc('reviewerSelection', payload.reviewerSelection, state)
    case SET_BULK_REVIEWER_SELECTION:
      return assoc('bulkReviewerSelection', payload.bulkReviewerSelection, state)
    case SELECT_ALL_USERS_ROWS:
      return assoc('selectAllChecked', !state.selectAllChecked, state)
    case SET_USERS:
      return assoc('users', payload.users, state)
    case SET_MORE_USERS:
      return mergeRight(state, { users: [...state.users, ...payload.users] })
    case SET_PAGE_META:
      return mergeRight(state, { pagination: { ...state.pagination, totalPages: Number(payload.totalPages), totalCount: Number(payload.totalCount) } })
    case SET_PAGINATION:
      return mergeRight(state, { pagination: { ...state.pagination, currentPage: Number(payload.currentPage), pageSize: Number(payload.pageSize) } })
    case CHECK_USERS_ROW: {
      const { checkedUsersRows = [] as any[], bulkReviewerSelection, users = [] as User[], reviewers } = state
      if (!payload.checked) {
        checkedUsersRows.splice(
          checkedUsersRows.findIndex((r: any) => r === payload.id),
          1
        )
      } else if (!checkedUsersRows.includes(payload.id)) {
        checkedUsersRows.push(payload.id)
      }
      const checkedUsersReviewers: any[] = []
      checkedUsersRows.forEach((userId: number) => users?.find((u: User) => u?.id === userId)?.reviewers?.forEach((r: User) => checkedUsersReviewers.push(r.id)))
      const newBulkReviewerSelection = [...bulkReviewerSelection.map((b) => `${b}`)]
      checkedUsersReviewers.forEach((cr) => (newBulkReviewerSelection.includes(`${cr}`) || !reviewers.find((r: any) => r.id === cr) ? null : newBulkReviewerSelection.push(`${cr}`)))
      return mergeRight(state, { checkedUsersRows, bulkReviewerSelection: newBulkReviewerSelection })
    }
    case SET_SORT:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, sort: payload } })
    case SET_SEARCH:
      return mergeRight(state, { searchAndFilter: { ...state.searchAndFilter, search: payload.search } })
    case SET_LOADING_USERS:
      return mergeRight(state, { loading: { ...state.loading, getUsers: payload.isLoading } })
    case SET_CHANGE_PASSWORD_DIALOG_OPEN:
      return payload.closeOthers
        ? mergeRight(state, {
          changePasswordDialog: { ...state.changePasswordDialog, userId: payload.userId },
          dialog: { ...INITIAL_STATE.dialog, changePasswordDialogOpen: payload.open },
        })
        : mergeRight(state, {
          changePasswordDialog: { ...state.changePasswordDialog, userId: payload.userId },
          dialog: { ...state.dialog, changePasswordDialogOpen: payload.open },
        })
    case SET_CHANGE_PASSWORD_DIALOG_FIELD:
      return mergeRight(state, { changePasswordDialog: mergeRight(state.changePasswordDialog, payload.changePasswordDialogFieldUpdates) })
    case SET_ADD_REVIEWERS_IN_PROGRESS:
      return mergeRight(state, { loading: { ...state.loading, addReviewers: payload.addReviewersInProgress } })
    case SET_ADD_REVIEWERS_REMAINING:
      return mergeRight(state, { addReviewersRemaining: payload.addReviewersRemaining })
    default:
      return state
  }
}

export const setChangePasswordDialogOpen = ({ closeOthers, open, userId }: { closeOthers?: boolean; open?: boolean; userId?: number }) => ({
  type: SET_CHANGE_PASSWORD_DIALOG_OPEN,
  payload: { closeOthers, open, userId },
})

export const setChangePasswordDialogField = (changePasswordDialogFieldUpdates: any) => ({
  type: SET_CHANGE_PASSWORD_DIALOG_FIELD,
  payload: { changePasswordDialogFieldUpdates },
})

export const setAddUserField = (newUserFormFieldUpdates: any) => ({
  type: SET_ADD_USER_FIELD,
  payload: { newUserFormFieldUpdates },
})

export const setEditUserField = (editUserFormFieldUpdates: any) => ({
  type: SET_EDIT_USER_FIELD,
  payload: { editUserFormFieldUpdates },
})

// TODO: work out what the id type should be and fix globally
export const setDisableUserDialogOpen = ({ closeOthers, open, id }: { closeOthers?: boolean; open: boolean; id?: number | string }) => ({
  type: SET_DISABLE_USER_DIALOG_OPEN,
  payload: { closeOthers, open, id },
})

// TODO: work out what the id type should be and fix globally
export const setEditUserDialogOpen = ({ open, id }: { open: boolean; id?: number | string }) => ({
  type: SET_EDIT_USER_DIALOG_OPEN,
  payload: { open, id },
})

export const setRemovingReviewerDialogOpen = ({ open, id }: { open: boolean; id?: number }) => ({
  type: SET_REMOVING_REVIEWER_DIALOG_OPEN,
  payload: { open, id },
})

export const setAddUserDialogOpen = ({ open }: { open: boolean }) => ({
  type: SET_ADD_USER_DIALOG_OPEN,
  payload: { open },
})

export const setRole = (roleSelection: string) => ({
  type: SET_ROLE,
  payload: { roleSelection },
})

export const setReviewer = (reviewerSelection: string) => ({
  type: SET_REVIEWER,
  payload: { reviewerSelection },
})

export const setBulkReviewer = (bulkReviewerSelection: string[]) => ({
  type: SET_BULK_REVIEWER_SELECTION,
  payload: { bulkReviewerSelection },
})

export const selectAllUsersRows = () => ({
  type: SELECT_ALL_USERS_ROWS,
  payload: {},
})

export const checkUsersRow = ({ id, checked }: { id: number; checked: boolean }) => ({
  type: CHECK_USERS_ROW,
  payload: { id, checked },
})

export const setUsers = (users: ServerUsers['users']) => ({
  type: SET_USERS,
  payload: { users },
})

export const setMoreUsers = (users: ServerUsers['users']) => ({
  type: SET_MORE_USERS,
  payload: { users },
})

export const setPageMeta = ({ totalPages, totalCount }: { totalPages: number; totalCount: number }) => ({
  type: SET_PAGE_META,
  payload: { totalPages, totalCount },
})

export const setPagination = ({ currentPage, pageSize }: { currentPage: number; pageSize: number }) => ({
  type: SET_PAGINATION,
  payload: { currentPage, pageSize },
})

export const setSort = ({ column, direction }: { column: string; direction: string }) => ({
  type: SET_SORT,
  payload: { column: column === 'name' ? 'last_name' : column, direction },
})

export const setSearch = (search: string) => ({
  type: SET_SEARCH,
  payload: { search },
})

export const setLoadingUsers = (isLoading: boolean) => ({
  type: SET_LOADING_USERS,
  payload: { isLoading },
})

export const setReviewers = (reviewers: Person[]) => ({
  type: SET_REVIEWERS,
  payload: { reviewers },
})

export const setReviewees = (reviewees: Person[]) => ({
  type: SET_REVIEWEES,
  payload: { reviewees },
})

export const setAddReviewersInProgress = (addReviewersInProgress: any) => ({
  type: SET_ADD_REVIEWERS_IN_PROGRESS,
  payload: { addReviewersInProgress },
})

export const setAddReviewersRemaining = (addReviewersRemaining: number) => ({
  type: SET_ADD_REVIEWERS_REMAINING,
  payload: { addReviewersRemaining },
})

export const addUser =
  () =>
    (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
      const {
        firstname,
        lastname,
        email,
        username,
        officerId: enteredOfficerId,
        password,
        isOfficerRole,
        isReviewerRole,
        isAnalystRole,
        isAdminRole,
        assignedReviewers,
        yearsOfExperience,
      } = getState()[UsersNamespace].addUserDialog
      const organization_id = getState()[UserNamespace]?.user?.organization_id
      const officerId = enteredOfficerId || customAlphabet('1234567890qwertyuioplkjhgfdsazxcvbnm', 9)().toUpperCase()
      const newRoles = []
      if (isOfficerRole) {
        newRoles.push(Role.Officer)
      }
      if (isReviewerRole) {
        newRoles.push(Role.Reviewer)
      }
      if (isAnalystRole) {
        newRoles.push(Role.Analyst)
      }
      if (isAdminRole) {
        newRoles.push(Role.Admin)
      }

      if (!newRoles.length) {
        return createSnackNotification(AlertLevel.Error, 'Error', 'User must have at least one role')
      }

      return http
        .post('/users', {
          email,
          password,
          password_confirmation: password,
          years_of_experience: yearsOfExperience,
          username,
          first_name: firstname,
          last_name: lastname,
          officer_id: officerId,
          organization_id,
          roles: newRoles,
        })
        .then(({ data: { id } }: { data: any; id?: number }) => {
          createSnackNotification(AlertLevel.Success, 'Success', `User created </br> <small>${id}-${officerId}</small>`)

          return http
            .post('/reviewerships_by_writer', {
              reviewer_ids: assignedReviewers,
              writer_id: id,
            })
            .catch(httpErrorHandler('Failed to add reviewer to user'))
        })
        .then(() => {
          const { searchAndFilter, pagination } = getState()[UsersNamespace]
          dispatch(getUsers({ searchAndFilter, pagination }))
          dispatch(getAllReviewers())
          dispatch(setAddUserDialogOpen({ open: false }))
        })
        .catch(httpErrorHandler('Failed to get reviewees', ({
          response: {
            data: {
              error: { message },
              errors,
            },
          },
        }) => {
          createSnackNotification(AlertLevel.Error, 'Failed to create user', message)
          return errors
        }))
    }

export const submitChangePassword =
  () =>
    (__: any, getState: GetState, { http }: ThunkDeps) => {
      const { password, confirmPassword, userId } = getState()[UsersNamespace].changePasswordDialog
      return http
        .patch(`/users/${userId}`, { password, password_confirmation: confirmPassword })
        .then(() => {
          createSnackNotification(AlertLevel.Success, 'Success', 'Password updated')
        })
        .catch(httpErrorHandler('Failed to update password'))
    }

export const updateUser =
  () =>
    (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
      const {
        id,
        firstname,
        lastname,
        email,
        username,
        officerId,
        isReviewerRole,
        isOfficerRole,
        isAnalystRole,
        isAdminRole,
        isSuperAdminRole,
        assignedReviewers,
        yearsOfExperience,
        password,
        confirmPassword,
      } = getState()[UsersNamespace].editUserDialog
      const organization_id = getState()[UserNamespace]?.user?.organization_id
      const assignedRoles = getState()[UserNamespace]?.user?.roles?.map((r: string) => RoleNumber[r]) || []

      const newRoles = []
      if (isOfficerRole) {
        newRoles.push(Role.Officer)
      }
      if (isReviewerRole) {
        newRoles.push(Role.Reviewer)
      }
      if (isAdminRole) {
        newRoles.push(Role.Admin)
      }
      if (isAnalystRole) {
        newRoles.push(Role.Analyst)
      }
      if (isSuperAdminRole) {
        newRoles.push(Role.SuperAdmin)
      }

      if (!newRoles.length) {
        return createSnackNotification(AlertLevel.Error, 'Error', 'User must have at least one role')
      }

      return http
        .patch(`/users/${id}`, {
          password,
          password_confirmation: confirmPassword,
          email,
          username,
          first_name: firstname,
          years_of_experience: yearsOfExperience,
          last_name: lastname,
          officer_id: officerId,
          organization_id,
          roles: newRoles,
        })
        .then(({ data: { id: writerId } }: { data: any; id?: number }) => {
          createSnackNotification(AlertLevel.Success, 'Success', `User updated </br> <small>${writerId}-${officerId}</small>`)
          if (!assignedRoles?.includes(Role.SuperAdmin)) {
            return http
              .post('/reviewerships_by_writer', {
                reviewer_ids: assignedReviewers,
                writer_id: writerId,
              })
              .catch(httpErrorHandler('Failed to add reviewer to user'))
          }

        })
        .then(() => {
          const { searchAndFilter, pagination } = getState()[UsersNamespace]
          dispatch(getUsers({ searchAndFilter, pagination }))
          dispatch(getAllReviewers())
          dispatch(setEditUserDialogOpen({ open: false }))
          dispatch(setRemovingReviewerDialogOpen({ open: false }))
        })
        .catch(httpErrorHandler('Failed to get reviewees', ({
          response: {
            data: {
              error: { message },
              errors,
            },
          },
        }) => {
          createSnackNotification(AlertLevel.Error, 'Failed to update user', message)
          return errors
        }))
    }

export const getReviewees = () => (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
  const { id } = getState()[UsersNamespace].editUserDialog
  const batchSize = 300;
  const currentPage = 1;
  let totalReviewees: any[] = [];

  const fetchBatch:any = async (page: number) => {
    const query = qs.stringify(
      {
        page,
        limit: batchSize,
        reviewer_id: id,
      },
      { arrayFormat: 'brackets' }
    );
    return http
      .get(`/reviewerships?${query}`)
      .then(({ data }: any) => {
        if (data.reviewerships) {
          totalReviewees = totalReviewees.concat(data.reviewerships);

          if (data.pagination.pages > page) {
            return fetchBatch(page + 1);
          }
        }
      });
  };

  return fetchBatch(currentPage)
    .then(() => {
      dispatch(setReviewees(totalReviewees))
    })
    .catch(httpErrorHandler('Failed to get reviewees'))
}

export const bulkCreateReviewerships = () => async (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
  try {
    const { bulkReviewerSelection, checkedUsersRows } = getState()[UsersNamespace]

    if (checkedUsersRows.length === 0) {
      return createSnackNotification(AlertLevel.Info, 'Select Users', 'Users must be selected to assign to this reviewer')
    }
    if (checkedUsersRows.length >= 5) {
      dispatch(setAddReviewersRemaining(checkedUsersRows.length))
      dispatch(setAddReviewersInProgress(true))
    }
    await forEachSeries(checkedUsersRows, async (userId, i) => {
      dispatch(setAddReviewersRemaining(checkedUsersRows.length - i))
      await http
        .post('/reviewerships_by_writer', {
          reviewer_ids: bulkReviewerSelection,
          writer_id: userId,
        })
        .catch(httpErrorHandler('Failed to add reviewer to user'))
    })

    setTimeout(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Reviewerships updated')
      const { searchAndFilter, pagination } = getState()[UsersNamespace]
      dispatch(setAddReviewersInProgress(false))
      dispatch(getUsers({ searchAndFilter, pagination }))
      dispatch(getAllReviewers())
    }, 1500)
  } catch (e) {
    httpErrorHandler('Failed to set reviewers')(e)
  }
}

export const getAllReviewers = () => (dispatch: Dispatch<any>, _: any, { http }: ThunkDeps) => {
  dispatch(setLoadingUsers(true));

  const batchSize = 300;
  const currentPage = 1;
  let totalReviewers: any[] = [];

  const fetchBatch:any = async (page: number) => {
    const query = qs.stringify({
      simple: true,
      page,
      limit: batchSize,
      role: 'reviewer',
      order: 'last_name',
      dir: 'asc',
    }, { arrayFormat: 'brackets' });

    return http
      .get(`/users?${query}`)
      .then(({ data }: any) => {
        if (data.users) {
          totalReviewers = totalReviewers.concat(data.users);

          if (data.pagination.pages > page) {
            return fetchBatch(page + 1);
          }
        }
      });
  };

  return fetchBatch(currentPage)
    .then(() => {
      dispatch(setReviewers(totalReviewers));
    })
    .catch(httpErrorHandler('Failed to get reviewers'))
    .finally(() => {
      dispatch(setLoadingUsers(false));
    });
};

export const exportUsersToCsv = () => async (_dispatch: Dispatch<any>, _getState: GetState, { http }: ThunkDeps) => {
  const batchSize = 300;
  let currentPage = 1;
  let totalUsers: any[] = [];

  const fetchPage = async (page:number) => {
    const query = qs.stringify({
      page,
      limit: batchSize,
      order: 'created_at',
      dir: 'desc',
    }, { arrayFormat: 'brackets' });

    return http
      .get(`/users.csv?${query}`)
      .then(({ data }: { data: any }) => {
        if (data.users) {
          totalUsers = totalUsers.concat(data.users);
          return data.pagination.pages;
        }
      })
      .catch(httpErrorHandler('Failed to export users data'));
  }

  const hasMorePages:number | undefined = await fetchPage(currentPage);

  if (!hasMorePages) {
    return;
  }

  if (hasMorePages && hasMorePages > 1) {
    const fetchPromises = [];
    let errors = false;
    while (hasMorePages > currentPage) {
      currentPage = currentPage + 1;
      fetchPromises.push(fetchPage(currentPage));
    }

    await Promise.all(fetchPromises)
      .then((data) => {
        if (data.includes(undefined)) {
          errors = true;
        }
      });

    if (errors) {
      return;
    }
  }

  const csvData = {
    fields: ['ID', 'First Name', 'Last Name', 'Username', 'Officer ID', 'Email', 'Roles', 'Status', 'Years of Experience'],
    data: totalUsers.map((user: User) =>
      [user.id, user.first_name, user.last_name, user.username, user.officer_id,
        user.email, user.roles.join(' '), user?.['access_locked?'] ? 'Disabled' : 'Active', user.years_of_experience])
  };
  const filename = 'export-users.csv';
  downloadCsv(Papa.unparse(csvData), filename);
};

export const getUsers =
  ({ searchAndFilter, pagination, isFetchMore }: { searchAndFilter: SearchAndFilter; pagination: Pagination; isFetchMore?: boolean }) =>
    (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
      dispatch(setLoadingUsers(true))
      const { currentPage, pageSize } = pagination;
      const {
        search,
        sort: { column, direction },
      } = searchAndFilter;
      const { reviewerSelection, roleSelection } = getState()[UsersNamespace];

      const query = qs.stringify(
        {
          page: currentPage,
          limit: pageSize,
          order: column === 'created' ? 'created_at' : column,
          dir: direction,
          role: RoleString?.[roleSelection],
          reviewer_id: reviewerSelection === 'all' ? undefined : reviewerSelection,
          search,
        },
        { arrayFormat: 'brackets' }
      )

      return http
        .get<ServerUsers>(`/users?${query}`)
        .then(({ data }) => {
          if (data.users && data.pagination) {
            if (isFetchMore) {
              dispatch(setMoreUsers(data.users))
              dispatch(setPagination({ currentPage: Number(data.pagination.page), pageSize: INITIAL_STATE.pagination.pageSize }))
            } else {
              dispatch(setUsers(data.users))
              dispatch(setPageMeta({ totalPages: Number(data.pagination.pages), totalCount: Number(data.pagination.count) }))
            }
          }
        })
        .catch(httpErrorHandler('Failed to get users'))
        .finally(() => {
          setTimeout(() => dispatch(setLoadingUsers(false)), 100)
        })
    }

export const disableUser =
  () =>
    (dispatch: any, getState: GetState, { http }: ThunkDeps) => {
      const { id } = getState()[UsersNamespace].disableUserDialog

      return http
        .delete(`/users/${id}`)
        .then(() => {
          const { searchAndFilter, pagination } = getState()[UsersNamespace]
          dispatch(getUsers({ searchAndFilter, pagination }))
          dispatch(getAllReviewers())
          dispatch(setEditUserDialogOpen({ open: false }))
          dispatch(setDisableUserDialogOpen({ closeOthers: true, open: false }))
          createSnackNotification(AlertLevel.Success, 'Success', 'User Disabled')
        })
        .catch(httpErrorHandler('Failed to disable user'))
    }

export const setSortAndSearch =
  ({ column, direction }: { column: string; direction: string }) =>
    (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
      const { searchAndFilter, pagination } = reducer(getState()[UsersNamespace], setSort({ column, direction }))
      dispatch(setSort({ column, direction }))
      return getUsers({ searchAndFilter, pagination })(dispatch, getState, { http } as ThunkDeps)
    }

export const selectors = {
  addReviewersRemaining: path<typeof INITIAL_STATE['addReviewersRemaining']>([UsersNamespace, 'addReviewersRemaining']),
  changePasswordDialog: path<typeof INITIAL_STATE['changePasswordDialog']>([UsersNamespace, 'changePasswordDialog']),
  addUserDialog: path<typeof INITIAL_STATE['addUserDialog']>([UsersNamespace, 'addUserDialog']),
  editUserDialog: path<UserDialog>([UsersNamespace, 'editUserDialog']),
  disableUserDialog: path<UserDialog>([UsersNamespace, 'disableUserDialog']),
  dialog: path<typeof INITIAL_STATE['dialog']>([UsersNamespace, 'dialog']),
  loading: path<LoadingUserStatus>([UsersNamespace, 'loading']),
  searchAndFilter: path<SearchAndFilter>([UsersNamespace, 'searchAndFilter']),
  roleSelection: path([UsersNamespace, 'roleSelection']),
  reviewerSelection: path([UsersNamespace, 'reviewerSelection']),
  bulkReviewerSelection: path([UsersNamespace, 'bulkReviewerSelection']),
  selectAllChecked: path([UsersNamespace, 'selectAllChecked']),
  checkedUsersRows: path<any[]>([UsersNamespace, 'checkedUsersRows']),
  addUserDialogOpen: path([UsersNamespace, 'addUserDialogOpen']),
  hasCheckedRows: compose((r: any) => r.length > 0, path([UsersNamespace, 'checkedUsersRows'])),
  pagination: path<Pagination>([UsersNamespace, 'pagination']),
  users: path([UsersNamespace, 'users']),
  userRows: compose(
    map((user: User) => ({
      id: user.id as number,
      name: `${user.last_name}, ${user.first_name}`,
      username: user.username,
      officerId: user.officer_id,
      orgId: user.organization_id,
      reviewers: user.reviewers ? user.reviewers.map((r: User) => ({ id: r.id, name: `${r.first_name} ${r.last_name}` })).sort((a: any, b: any) => a.name.localeCompare(b.name)) : [],
      roles: user.roles.map((r: string) => (r === 'user' ? 'officer' : r.replace('_', ' '))).reverse() || [],
      created: dayjs(user.created_at) || '',
      disabled: !!user['access_locked?'],
      actions: '',
    })
    ),
    pathOr([], [UsersNamespace, 'users'])
  ),
  reviewerDropdowns: compose(
    (ary: Array<{ id: string; name: string }>) => ary.sort((a: { id: string; name: string }, b: { id: string; name: string }) => a.name.localeCompare(b.name)),
    map(({ id, first_name, last_name }: { id: number; first_name: string; last_name: string }) => ({
      id: String(id),
      name: `${last_name}, ${first_name}`,
    })),
    // Filter reviewers that aren't disabled
    filter((reviewers: User) => !reviewers['access_locked?']),
    pathOr([], [UsersNamespace, 'reviewers'])
  ),
}
