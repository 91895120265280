import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Form } from '@ducks';
import { ConfirmDialog } from '@components/common';
import { getInitialPerson } from '@ducks/form';
import { isEqual, times } from 'lodash';
import './RipaPeopleForm.scss';
import { Person } from '@engine/ducks/types';

interface Props {
  setNumberOfPeople: (value: number) => void;
  numberOfPeople: number;
  person: any;
  setPerson: (value: Person[]) => void;
  setUseSameGenderForAll: (value: boolean) => void;
  setUseSameRaceForAll: (value: boolean) => void;
}

const RipaPeopleForm = ({ setNumberOfPeople, numberOfPeople, person, setPerson, setUseSameGenderForAll, setUseSameRaceForAll }: Props) => {
  const [removePersonOpen, setRemovePersonOpen] = useState(false);

  const handlePeopleDecreased = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickDecrease()
    }
  }

  const handlePeopleIncreased = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setNumberOfPeople(numberOfPeople + 1);
    }
  }

  const onClickDecrease = () => {
    const isInitialPerson = isEqual(person[person.length - 1], getInitialPerson());
    if (numberOfPeople > 1 && !isInitialPerson) {
      setRemovePersonOpen(true);
    } else {
      setNumberOfPeople(numberOfPeople - 1);
    }
  }
  const onCloseDialog = () => setRemovePersonOpen(false);
  const confirmDelete = () => {
    onCloseDialog();
    setNumberOfPeople(numberOfPeople - 1);
  }

  useEffect(() => {
    if (numberOfPeople < person.length) {
      setPerson(person.slice(0, numberOfPeople));
    } else if (numberOfPeople > person.length) {
      const peopleAdded = numberOfPeople - person.length;
      setPerson(person.concat(times(peopleAdded, getInitialPerson)));
    }
    if (numberOfPeople === 1) {
      setUseSameGenderForAll(false);
      setUseSameRaceForAll(false);
      setPerson([
        {
          ...person[0],
          label: '',
        },
      ]);
    }
  }, [numberOfPeople]);

  return (
    <>
      <div className="ripa-people-form" data-testid="ripa-people-form">
        <div className="ripa-people-form__title">
          How many people were stopped by the officer(s)?
        </div>
        <div className="ripa-people-form__people-counter">
          <div
            className={cn('ripa-people-form__people-counter-minus material-icons', { enabled: numberOfPeople > 1 })}
            data-testid="ripa-people-form-people-counter-minus"
            onClick={() => numberOfPeople > 1 && onClickDecrease()}
            onKeyUp={(e: React.KeyboardEvent) => numberOfPeople > 1 && handlePeopleDecreased(e)}
            aria-label="Decrease the number of people stopped by the officer by one"
            tabIndex={0}
            role="button"
          >
            horizontal_rule
          </div>
          <div className="ripa-people-form__people-counter-number" data-testid="ripa-people-form-people-counter-number">
            {numberOfPeople}
          </div>
          <div
            className={cn('ripa-people-form__people-counter-plus material-icons', { enabled: numberOfPeople < 99 })}
            data-testid="ripa-people-form-people-counter-plus"
            onClick={() => numberOfPeople < 99 ? setNumberOfPeople(numberOfPeople + 1) : null}
            onKeyUp={(e: React.KeyboardEvent) => numberOfPeople < 99 ? handlePeopleIncreased(e) : null}
            aria-label="Increase the number of people stopped by the officer by one"
            tabIndex={0}
            role="button"
          >
            add
          </div>
        </div>
      </div>

      <ConfirmDialog
        heading="Remove Person"
        open={removePersonOpen}
        closeDialog={onCloseDialog}
        negativeAction={onCloseDialog}
        positiveAction={confirmDelete}
        positiveText="Remove Person"
        negativeText="Cancel"
        data-testid="confirm-delete-report-dialog"
      >
        <div>
          Are you sure you would like to remove a person this form? All information entered for
          {' '}
          {person[person.length - 1].label || `Person ${person.length}`}
          {' '}
          will be lost.
        </div>
      </ConfirmDialog>
    </>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      <b>{'Number of people stopped: '}</b>
      Only report the number of people stopped that require RIPA reporting. e.g. Peace officers do not always submit data elements for passengers in vehicles unless the passengers meet specific criteria during the stop.
      <br />
    </div>
  </div>
);

RipaPeopleForm.helper = Helper;

const mapStateToProps = (state: any) => ({
  numberOfPeople: Form.selectors.numberOfPeople(state),
  person: Form.selectors.person(state) as Person[],
});

const mapDispatchToProps = {
  setNumberOfPeople: Form.setFormField({ path: ['numberOfPeople'] }),
  setPerson: (value: Person[]) => Form.setFormField({ path: ['person'] })(value),
  setUseSameGenderForAll: (value: Boolean) => Form.setFormField({ path: ['useSameGenderForAll'] })(value),
  setUseSameRaceForAll: (value: Boolean) => Form.setFormField({ path: ['useSameRaceForAll'] })(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(RipaPeopleForm);
