import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { ReactElement, Component } from 'react';
import { User } from '@ducks';

const mapDispatchToProps = {
  next: NewReport.next,
  back: NewReport.back,
  checkValidation: Form.checkValidation,
};

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  currentSubstep: NewReport.selectors.currentSubstep(state),
  currentStep: NewReport.selectors.currentStep(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  nSteps: NewReport.selectors.nSteps(state),
  enumMap: Template.selectors.enumMap(state),
  flags: Form.selectors.flags(state),
  officerAssignment: Template.selectors.enumMap(state),
  isUserSettingsValid: User.selectors.isUserSettingsValid(state),
});

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  children: ReactElement;
  HelperDisplayText?: string;
  Helper?: typeof Component;
  WarningDisplayText?: string;
  Warning?: typeof Component;
};
