import React, { useLayoutEffect, useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import { DrawerManager, Loading, ConfirmDialog } from '@components/common';
import { Header, NewReportDrawer, LoginDialog, TrainingModeBanner } from '@components/custom';
import { RipaFormContainer } from '@components/form';
import { Config, NewReport as NewReportDuck, Template, Form, User, WatchLocation } from '@ducks';
import { DispatchAction, DispatchThunk, Step, CustomQuestionSections, Match } from '@ducks/types';
import { assignRoute } from '@utility/assignRoute';
import { watchLocation } from '@utility/location';
import { offlineConfig } from '@engine/dependencies/localforage'
import noElasticScroll from 'inobounce';
// import StepsFunction from './Steps';
import { getComponent } from './stepComponentMap';
import './NewReport.scss';

interface Props {
  currentSubstep: number;
  currentStep: number;
  formLoading: boolean;
  match: Match;
  breakpoint: string;
  startOverDialogOpen: boolean;
  startOverDialogFormId: string;
  removeStudentDialogOpen: boolean;
  template: object;
  isTemplateLoading: boolean;
  steps: Array<Step>;
  currentComponent: string;
  customQuestionAnswers: {
    person: Array<any>;
    [other: string]: any;
  };
  customQuestions: CustomQuestionSections;
  organizationId: string;
  trainingMode: boolean;
  online: boolean;
  setAvailable: DispatchAction<typeof WatchLocation.setAvailable>;
  setCoor: DispatchAction<typeof WatchLocation.setCoor>;
  removeStudentAndResetForm: DispatchThunk<typeof Form.removeStudentAndResetForm>;
  getForm: DispatchThunk<typeof Form.getForm>;
  startOver: DispatchThunk<typeof Form.startOver>;
  addFormTimeSpent: DispatchThunk<typeof Form.addFormTimeSpent>;
  getOrgCustomQuestions: DispatchThunk<typeof NewReportDuck.getOrgCustomQuestions>;
  recallStep: DispatchAction<typeof NewReportDuck.recallStep>;
  registerSteps: DispatchAction<typeof NewReportDuck.registerSteps>;
  setStartOverDialogOpen: DispatchAction<typeof NewReportDuck.setStartOverDialogOpen>;
  setRemoveStudentDialogOpen: DispatchAction<typeof NewReportDuck.setRemoveStudentDialogOpen>;
}

export const NewReport = ({
  steps,
  currentSubstep,
  currentStep,
  // registerSteps,
  formLoading,
  template,
  match,
  getForm,
  setRemoveStudentDialogOpen,
  breakpoint,
  startOverDialogOpen,
  startOver,
  isTemplateLoading,
  setStartOverDialogOpen,
  startOverDialogFormId,
  removeStudentDialogOpen,
  removeStudentAndResetForm,
  setCoor,
  setAvailable,
  addFormTimeSpent,
  recallStep,
  currentComponent,
  customQuestionAnswers,
  customQuestions,
  organizationId,
  getOrgCustomQuestions,
  trainingMode,
  online
}: Props) => {
  const {
    component: componentName,
    helperText,
    warningText,
    getProps,
    isCustom
  } = steps?.[currentStep]?.substeps?.[currentSubstep] ?? {};
  const shouldRender = steps?.length > 0 && !formLoading && !isTemplateLoading;
  const StepComponent = shouldRender ? getComponent(componentName) : null;
  const componentProps = shouldRender ? getProps?.(template) : {};
  const HelperDisplayText = shouldRender ? componentProps?.helperText ?? helperText : null;
  const HelperComponent = shouldRender ? StepComponent?.helper : null;
  const WarningDisplayText = shouldRender ? componentProps?.warningText ?? warningText : null;
  const WarningComponent = shouldRender ? StepComponent?.warning : null;
  const [isWorkingOffline, setIsWorkingOffline] = useState(false);

  window.shouldRenderForm = { steps, formLoading, isTemplateLoading };

  offlineConfig.getItem('work-offline')
    .then(wo => {
      if (String(wo) !== 'true') {
        watchLocation(setAvailable, setCoor);
      }
    });

  useEffect(() => {
    if (organizationId) {
      getOrgCustomQuestions(organizationId)
    }
  }, [organizationId]);

  useEffect(() => {
    const step = steps?.[currentStep]?.substeps?.[currentSubstep];
    const isCurrentComponent = step && step?.component === currentComponent;
    const isCustomQuestionsComplete = currentComponent === 'RipaReviewForm' && Object.keys(customQuestionAnswers).length ?
      customQuestions?.all?.questions.every(q => q?.disabled || !q?.required || customQuestionAnswers?.person?.every(p => p?.[q?.props?.resultPath] !== undefined)) &&
      customQuestions?.individual?.questions.every(q => q?.disabled || !q?.required || customQuestionAnswers?.[q?.props?.resultPath] !== undefined) : true;

    if ((!isCurrentComponent || !isCustomQuestionsComplete) && shouldRender && isCustomQuestionsComplete !== undefined) {
      recallStep({});
    }
  }, [currentStep, currentSubstep, shouldRender]);

  useEffect(() => {
    const detectWorkOfflineStatus = async () => {
      const workOfflineStatus = await offlineConfig.getItem('work-offline').then(wo => String(wo) === 'true')
      setIsWorkingOffline(workOfflineStatus)
    };

    detectWorkOfflineStatus();
  }, [online])

  useLayoutEffect(() => {
    const pathFormId = match?.params?.id;
    if (pathFormId) {
      getForm({ id: pathFormId });
    } else {
      assignRoute('/dashboard');
    }
    /* Disable 'no elastic scroll' on Safari iOS */
    noElasticScroll.disable();
    return () => {
      /* Enable 'no elastic scroll' on Safari iOS */
      noElasticScroll.enable();
    };
  }, []);

  const maxInactivity = 20000;
  const userActivityEvents = ['keydown', 'mousedown', 'mousemove', 'scroll', 'touchstart'];
  const pageContent = useRef<HTMLDivElement>(null);
  const millsSinceLastActivity = useRef(0);
  const lastActivityTime = useRef(Date.now());

  const userActivityCbs = userActivityEvents.reduce((acc: { [evnt: string]: () => void }, event: string) => ({
    ...acc,
    [event]: useCallback(throttle(() => {
      millsSinceLastActivity.current = Date.now() - lastActivityTime.current;
      if (millsSinceLastActivity.current < maxInactivity) {
        addFormTimeSpent({ addTimeSec: millsSinceLastActivity.current / 1000 })
      }
      lastActivityTime.current = Date.now()
    }, 2000, { trailing: false, leading: true }), [event])
  }), {});

  useEffect(() => {
    lastActivityTime.current = Date.now();
  }, [isCustom])

  useEffect(() => {
    if (shouldRender) {
      userActivityEvents.forEach((event: string) => {
        if (pageContent.current) {
          pageContent.current.addEventListener(event, userActivityCbs[event])
        }
      });
    }

    return () => {
      userActivityEvents.forEach((event: string) => {
        pageContent.current?.removeEventListener(event, userActivityCbs[event])
      });
    }
  }, [shouldRender])

  return (
    <Header title="Contact">
      <DrawerManager
        DrawerComponent={NewReportDrawer}
        contentMode="box"
        smallClose
        showBanner={trainingMode}
        bannerContent={!isWorkingOffline && <TrainingModeBanner breakpoint={breakpoint} />}
        drawerRef={pageContent}>
        <div className="new-report" data-testid="new-report">
          {shouldRender ?
            <RipaFormContainer
              Helper={HelperComponent}
              HelperDisplayText={HelperDisplayText}
              Warning={WarningComponent}
              WarningDisplayText={WarningDisplayText}
            >
              {StepComponent && <StepComponent {...componentProps} />}
            </RipaFormContainer> : <Loading />
          }
          <ConfirmDialog
            heading="Start Over"
            open={startOverDialogOpen}
            closeDialog={() => setStartOverDialogOpen({ open: false })}
            negativeAction={() => setStartOverDialogOpen({ open: false })}
            positiveAction={() => startOver({ formId: startOverDialogFormId })}
            positiveText="Start Over"
            negativeText="Cancel"
            data-testid="new-report-start-over-dialog"
          >
            <div>You will lose all progress on this form</div>
          </ConfirmDialog>
          <ConfirmDialog
            heading="Remove student"
            open={removeStudentDialogOpen}
            closeDialog={() => setRemoveStudentDialogOpen({ open: false })}
            negativeAction={() => setRemoveStudentDialogOpen({ open: false })}
            positiveAction={removeStudentAndResetForm}
            positiveText="Remove"
            negativeText="Cancel"
          >
            <div>
              Removing the student status will cause the form to partially reset. Are you sure you want to continue?
            </div>
          </ConfirmDialog>
          <LoginDialog />
        </div>
      </DrawerManager>
    </Header>
  );
};

export const mapDispatchToProps = {
  registerSteps: NewReportDuck.registerSteps,
  getForm: Form.getForm,
  startOver: Form.startOver,
  setStartOverDialogOpen: NewReportDuck.setStartOverDialogOpen,
  setRemoveStudentDialogOpen: NewReportDuck.setRemoveStudentDialogOpen,
  removeStudentAndResetForm: Form.removeStudentAndResetForm,
  setCoor: WatchLocation.setCoor,
  setAvailable: WatchLocation.setAvailable,
  addFormTimeSpent: Form.addFormTimeSpent,
  recallStep: NewReportDuck.recallStep,
  getOrgCustomQuestions: NewReportDuck.getOrgCustomQuestions
};

export const mapStateToProps = (state: any) => ({
  currentSubstep: NewReportDuck.selectors.currentSubstep(state),
  currentStep: NewReportDuck.selectors.currentStep(state),
  currentComponent: NewReportDuck.selectors.currentComponent(state),
  formLoading: Form.selectors.formLoading(state),
  breakpoint: Config.selectors.breakpoint(state),
  startOverDialogOpen: NewReportDuck.selectors.startOverDialogOpen(state),
  startOverDialogFormId: NewReportDuck.selectors.startOverDialogFormId(state),
  removeStudentDialogOpen: NewReportDuck.selectors.removeStudentDialogOpen(state),
  template: Template.selectors.template(state),
  isTemplateLoading: Template.selectors.isTemplateLoading(state),
  steps: NewReportDuck.selectors.registeredSteps(state),
  secondsSinceLastActivity: NewReportDuck.selectors.secondsSinceLastActivity(state),
  customQuestionAnswers: Form.selectors.customQuestionAnswers(state),
  customQuestions: NewReportDuck.selectors.customQuestions(state),
  organizationId: User.selectors.organizationId(state),
  trainingMode: User.selectors.trainingMode(state),
  online: Config.selectors.online(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewReport);
