const eventHistory: { eventName: string; eventData: any }[] = [];

const dispatchCustomEvent = <T = any>(eventName: string, eventData: T) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail: eventData }))
  if (eventHistory.length >= 10) {
    eventHistory.pop();
  }
  eventHistory.unshift({ eventName, eventData });
}

const clearEventHistory = () => {
  while (eventHistory.length > 0) {
    eventHistory.pop();
  }
}

export { dispatchCustomEvent, eventHistory, clearEventHistory }
