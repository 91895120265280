import cn from 'classnames';
import React from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { PiiTextField } from '@components/common';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import './RipaDescriptionForm.scss';

const RipaDescriptionForm = ({
  stopDescription,
  setStopDescription,
  labels,
  numberOfPeople,
  useSamePrimaryReasonForAll,
  currentSubloop,
}: PropsFromRedux) => {
  const subloopIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;
  return (
    <>
      <div className="ripa-description-form" data-testid="ripa-description-form">
        <div className={cn('ripa-description-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
          <div className="material-icons">
            person
          </div>
          <div className="ripa-description-form__person-label">
            {labels?.[subloopIndex] || subloopIndex + 1}
          </div>
          <div className="ripa-description-form__person-same-for-all">
            Same For All
          </div>
          <div className="ripa-description-form__person-progression">
            <b>{`${subloopIndex + 1}`}</b>
            {`/${numberOfPeople}`}
          </div>
          <div className="ripa-description-form__person-progression-all">
            ALL
          </div>
        </div>
        <div className="ripa-description-form__title">
          Brief description of the stop?
        </div>
        <div className="ripa-description-form__field-container">
          <PiiTextField
            // @ts-ignore
            className="ripa-description-form__field"
            placeholder="Enter brief, 250 character maximum, explanation..."
            onChange={({ target: { value } }) => value.length <= 250 ? setStopDescription(value, subloopIndex) : null}
            value={stopDescription[subloopIndex]}
            data-testid="ripa-description-form-input"
          />
          <div className="ripa-description-form__field-remaining" data-testid="ripa-description-form__field-remaining">
            <b>
              {`${250 - stopDescription[subloopIndex]?.length} `}
            </b>
            Remaining
          </div>
        </div>
      </div>
    </>
  );
}

const Helper = () => {
  const { pre2024 } = useSelector((state: any) => state.Form.flags);
  return (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      This explanation should include detail beyond what was selected previously.
      Do not include any PII of the person stopped or unique identifying information of any officer in this description.
      <br />
      {pre2024 ? ('If reason given for stop is different than actual reason for stop, briefly explain why. If no reason given to stopped person, briefly describe this scenario.') : ''}
    </div>
  </div>
)
}

const Warning = () => {
  const stopDuringWellnessCheck = useSelector((state: any) => state.Form.stopDuringWellnessCheck);
  if (!stopDuringWellnessCheck) {
    return <></>;
  }
  return (
    <div className="ripa-form-container__warning-box ripa-description-form__warning">
      <div className="material-icons">
        priority_high
      </div>
      <div className="ripa-form-container__warning-box-text">
        <b>Note: </b>
        Include the facts that formed the basis for conducting the welfare or wellness stop or the community caretaking function
      </div>
    </div>
  )
}

RipaDescriptionForm.helper = Helper;
RipaDescriptionForm.warning = Warning;

const mapStateToProps = (state: any) => ({
  stopDescription: Form.selectors.stopDescription(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
})

const mapDispatchToProps = {
  setStopDescription: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'stopDescription'] })(value)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaDescriptionForm)
