import { Role } from '@ducks/constants';
import { Path } from '@engine/ducks/types';

export const paths: Path = {
  Dashboard: {
    path: '/dashboard',
    requiredRole: [Role.Officer],
    excludedRole: [Role.SuperAdmin],
    allowMobile: true,
    component: 'Dashboard',
    title: 'Dashboard',
    default: true,
    allowOffline: true,
    exact: true,
  },
  NewReport: {
    path: '/new-report/:id',
    requiredRole: [Role.Officer],
    excludedRole: [Role.SuperAdmin],
    allowMobile: true,
    component: 'NewReport',
    title: 'New Report',
    allowOffline: true,
    exact: true,
  },
  Review: {
    path: '/review',
    requiredRole: [Role.Reviewer],
    excludedRole: [Role.SuperAdmin],
    allowMobile: false,
    component: 'Review',
    title: 'Review',
    exact: true,
  },
  ReviewUnavailable: {
    path: '/review/unavailable',
    requiredRole: [Role.Reviewer],
    excludedRole: [Role.SuperAdmin],
    allowMobile: true,
    component: 'ReviewUnavailable',
    title: 'Review Unavailable',
    exact: true,
  },
  Users: {
    path: '/admin/users',
    requiredRole: [Role.Admin, Role.SuperAdmin],
    allowMobile: true,
    component: 'Users',
    title: 'Users',
    exact: true,
  },
  Organizations: {
    path: '/organizations',
    requiredRole: [Role.SuperAdmin],
    allowMobile: true,
    component: 'Organizations',
  },
  Visualization: {
    path: '/visualization',
    requiredRole: [Role.Analyst],
    excludedRole: [Role.SuperAdmin],
    allowMobile: false,
    component: 'Visualization',
    title: 'Data Visualization',
    exact: true,
  },
  GlobalConfiguration: {
    path: '/admin/global_configuration',
    requiredRole: [Role.Admin],
    excludedRole: [Role.SuperAdmin],
    requiredState: ['CA'],
    allowMobile: false,
    component: 'GlobalConfiguration',
    title: 'Global Configuration',
    exact: true,
  },
  CustomQuestions: {
    path: '/custom_questions',
    requiredRole: [Role.SuperAdmin],
    allowMobile: true,
    component: 'CustomQuestions',
    title: 'Custom Questions Configuration',
    exact: true,
  },
};

export const unauthPaths = {
  Login: {
    path: '/log_in',
    component: 'Login',
    allowOffline: true,
    exact: true,
  },
  ResetPassword: {
    path: '/log_in/reset_password',
    component: 'ResetPassword',
    exact: true,
  },
};
