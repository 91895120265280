import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Config, Form, Template } from '@ducks';
import { onEnter } from '@utility/keypressHelpers';
import './RipaResponseToCallForm.scss';
import Grid, { GridSize } from '@material-ui/core/Grid';

interface Props extends PropsFromRedux {
  goToNext: () => void;
}

const RipaResponseToCallForm = ({ setResponseToCall, responseToCall, setOfficerWorksWithNonPrimaryAgency, officerWorksWithNonPrimaryAgency, setStopDuringWellnessCheck, stopDuringWellnessCheck, formTemplates, breakpoint, goToNext, flags: { pre2024: before2024, post2024 } }: Props) => {
  let pre2024 = before2024;
  if (post2024) {
    pre2024 = post2024;
  }
  const showContact2024Changes = ((pre2024) && ('OfficerWorksWithNonPrimaryAgency' in formTemplates) && ('StopDuringWellnessCheck' in formTemplates));
  // Responsive layout based on breakpoint values
  let colSizeTitle: GridSize = 8;
  let colSizeButtons: GridSize = 4;
  if (breakpoint === 'lg') {
    colSizeTitle = 8;
    colSizeButtons = 4;
  } else {
    colSizeTitle = 12;
    colSizeButtons = 12;
  }
  return (
    <div className={cn('ripa-response-to-call-form', { pre2024 })} data-testid="ripa-response-to-call-form">
      {showContact2024Changes === false && (
        <>
          <div className="ripa-response-to-call-form__title">
            Was it a response to a call?
          </div>
          <div className="ripa-response-to-call-form__select-box-container">
            <div
              className={cn('ripa-response-to-call-form__select-box', { active: responseToCall })}
              onClick={() => {
                  setResponseToCall(true);
                  goToNext?.();
                }}
              onKeyUp={onEnter(() => setResponseToCall(true))}
              role="button"
              tabIndex={0}
              data-testid="ripa-response-to-call-yes"
              >
                Yes
            </div>
            <div
              className={cn('ripa-response-to-call-form__select-box', { active: responseToCall === false })}
              onClick={() => {
                setResponseToCall(false);
                goToNext?.();
              }}
              onKeyUp={onEnter(() => setResponseToCall(false))}
              role="button"
              tabIndex={0}
            >
              No
            </div>
          </div>
        </>
      )}
      {showContact2024Changes && (
        <>
          <div className="ripa-response-to-call-form__title">
            Stop Context
          </div>
          <Grid container alignItems="center" spacing={0}>
            <Grid container item xs={colSizeTitle}>
              <div className="ripa-response-to-call-form__label">
                Was it a response to a call?
              </div>
            </Grid>
            <Grid container item xs={colSizeButtons}>
              <div className="ripa-response-to-call-form__select-box-container">
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: responseToCall })}
                  onClick={() => {
                    setResponseToCall(true);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setResponseToCall(true))}
                  role="button"
                  tabIndex={0}
                  data-testid="ripa-response-to-call-yes"
                >
                  Yes
              </div>
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: responseToCall === false })}
                  onClick={() => {
                    setResponseToCall(false);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setResponseToCall(false))}
                  role="button"
                  tabIndex={0}
                >
                  No
                </div>
              </div>
            </Grid>
            <Grid container item xs={colSizeTitle}>
              <div className="ripa-response-to-call-form__label">
                Officer works with the non-primary agency in a stop done in conjunction with an agency that is not
                subject to the reporting requirements of this chapter?
              </div>
            </Grid>
            <Grid container item xs={colSizeButtons}>
              <div className="ripa-response-to-call-form__select-box-container">
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: officerWorksWithNonPrimaryAgency })}
                  onClick={() => {
                    setOfficerWorksWithNonPrimaryAgency(true);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setOfficerWorksWithNonPrimaryAgency(true))}
                  role="button"
                  tabIndex={0}
                  data-testid="ripa-response-to-call-yes"
                >
                  Yes
              </div>
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: officerWorksWithNonPrimaryAgency === false })}
                  onClick={() => {
                    setOfficerWorksWithNonPrimaryAgency(false);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setOfficerWorksWithNonPrimaryAgency(false))}
                  role="button"
                  tabIndex={0}
                >
                  No
                </div>
              </div>
            </Grid>
            <Grid container item xs={colSizeTitle}>
              <div className="ripa-response-to-call-form__label">
                Stop made during the course of performing a welfare or wellness check or an officer&apos;s community
                caretaking function?
              </div>
            </Grid>
            <Grid container item xs={colSizeButtons}>
              <div className="ripa-response-to-call-form__select-box-container">
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: stopDuringWellnessCheck })}
                  onClick={() => {
                    setStopDuringWellnessCheck(true);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setStopDuringWellnessCheck(true))}
                  role="button"
                  tabIndex={0}
                  data-testid="ripa-response-to-call-yes"
                >
                  Yes
              </div>
                <div
                  className={cn('ripa-response-to-call-form__select-box', { active: stopDuringWellnessCheck === false })}
                  onClick={() => {
                    setStopDuringWellnessCheck(false);
                    goToNext?.();
                  }}
                  onKeyUp={onEnter(() => setStopDuringWellnessCheck(false))}
                  role="button"
                  tabIndex={0}
                >
                  No
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  responseToCall: Form.selectors.responseToCall(state),
  officerWorksWithNonPrimaryAgency: Form.selectors.officerWorksWithNonPrimaryAgency(state),
  stopDuringWellnessCheck: Form.selectors.stopDuringWellnessCheck(state),
  formTemplates: Template.selectors.enumMap(state),
  breakpoint: Config.selectors.breakpoint(state),
  flags: Form.selectors.flags(state),
})

const mapDispatchToProps = {
  setResponseToCall: Form.setFormField({ path: ['responseToCall'] }),
  setOfficerWorksWithNonPrimaryAgency: Form.setFormField({ path: ['officerWorksWithNonPrimaryAgency'] }),
  setStopDuringWellnessCheck: Form.setFormField({ path: ['stopDuringWellnessCheck'] }),
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaResponseToCallForm)
