import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Form, NewReport } from '@ducks';
import { onEnter } from '@utility/keypressHelpers';
import './RipaStoppedInsideResidenceForm.scss';

interface Props extends PropsFromRedux {
  goToNext: () => void;
}

const RipaStoppedInsideResidenceForm = ({ setStoppedInsideResidence, numberOfPeople, labels, stoppedInsideResidence, currentSubloop, goToNext }: Props) => {
  const subloopIndex = currentSubloop;
  return (
    <div className="ripa-stopped-inside-residence-form" data-testid="ripa-stopped-inside-residence-form">
      <div className={cn('ripa-stopped-inside-residence-form__person')}>
        <div className="material-icons">person</div>
        <div className="ripa-stopped-inside-residence-form__person-label" data-testid="ripa-stopped-inside-residence-form-person-label">{labels?.[subloopIndex] || subloopIndex + 1}</div>
        <div className="ripa-stopped-inside-residence-form__person-progression">
          <b>{`${subloopIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-stopped-inside-residence-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-stopped-inside-residence-form__title">
        Stopped Person is Inside a Residence?
      </div>
      <div className="ripa-stopped-inside-residence-form__select-box-container">
        <div
          className={cn('ripa-stopped-inside-residence-form__select-box', { active: stoppedInsideResidence[subloopIndex] })}
          onClick={() => {
            setStoppedInsideResidence(true, subloopIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setStoppedInsideResidence(true, subloopIndex))}
          role="button"
          tabIndex={0}
          data-testid="ripa-stopped-inside-residence-yes"
        >
            Yes
        </div>
        <div
          className={cn('ripa-stopped-inside-residence-form__select-box', { active: stoppedInsideResidence[subloopIndex] === false })}
          onClick={() => {
            setStoppedInsideResidence(false, subloopIndex);
            goToNext?.();
          }}
          onKeyUp={onEnter(() => setStoppedInsideResidence(false, subloopIndex))}
          role="button"
          tabIndex={0}
          data-testid="ripa-stopped-inside-residence-no"
        >
          No
        </div>
      </div>
    </div>
  );
}

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">
      Select if the stopped person was inside a residence, where an officer was executing a search or arrest warrant naming or identifying
      <b> ANOTHER </b>
      person, conducting a search pursuant to a condition of
      <b> ANOTHER </b>
      person’s parole,
      probation, PRCS, or mandatory supervision, or conducting a compliance check on
      <b> ANOTHER </b>
      person under home detention or house arrest.
    </div>
  </div>
);

RipaStoppedInsideResidenceForm.helper = Helper;

const mapStateToProps = (state: any) => ({
  stoppedInsideResidence: Form.selectors.stoppedInsideResidence(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  labels: Form.selectors.labels(state),
})

const mapDispatchToProps = {
  setStoppedInsideResidence: (value: boolean, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'stoppedInsideResidence'] })(value),
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaStoppedInsideResidenceForm)
