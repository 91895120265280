import React from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './RipaSeizureTypeForm.scss';

const RipaSeizureTypeForm = ({ typeSeized, seizedPropertyType, toggleSeizedPropertyType, currentSubloop, labels, numberOfPeople, useSameActionTakenForAll, useSameActionForAllPersonIndex }: Props) => {
  const personIndex = useSameActionTakenForAll ? useSameActionForAllPersonIndex : currentSubloop;

  return (
    <div className="ripa-seizure-type-form" data-testid="ripa-seizure-type-form">
      <div className={cn('ripa-seizure-type-form__person', { 'same-for-all': useSameActionTakenForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-seizure-type-form__person-label">{labels?.[currentSubloop] || currentSubloop + 1}</div>
        <div className="ripa-seizure-type-form__person-same-for-all">Same For All</div>
        <div className="ripa-seizure-type-form__person-progression">
          <b>{`${currentSubloop + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-seizure-type-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-seizure-type-form__title">What type of property was seized?</div>
      <div className="ripa-seizure-type-form__selection">
        <div className="ripa-seizure-type-form__select-box-content">
          {[
            typeSeized.Firearm,
            typeSeized.Drugs,
            typeSeized.Ammunition,
            typeSeized.DrugParaphernalia,
            typeSeized.Weapon,
            typeSeized.Alcohol,
            typeSeized.SuspectedStolenProperty,
            typeSeized.CellPhoneOrDevice,
            typeSeized.Money,
            typeSeized.Vehicle,
            typeSeized.Other,
          ].map(({ value }, si) => (
            <div
              className={cn('ripa-seizure-type-form__select-box', { active: seizedPropertyType?.[personIndex]?.includes(value) })}
              key={`RipaSeizureTypeFormBox-${si}`}
              onClick={() => toggleSeizedPropertyType(value, personIndex)}
              onKeyUp={onEnter(() => toggleSeizedPropertyType(value, personIndex))}
              data-testid={`ripa-seizure-type-form-box-${si}`}
              role="button"
              tabIndex={0}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select ALL that apply.</div>
  </div>
);

RipaSeizureTypeForm.helper = Helper;

export default connector(RipaSeizureTypeForm);
