import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { Button } from '@components/common';
import { TextField } from '@material-ui/core';
import { VeritoneColorSvg } from '@components/svg';
import { User } from '@ducks';
import { onEnter } from '@utility/keypressHelpers';
import { Snackbar } from '@components/common/Snackbar/Snackbar';
import Default_BG from '../Default_BG.png';
import './ResetPassword.scss';

export const ResetPassword = ({ loginBackground, changePassword }) => {
  const [errors, setErrors] = useState({});
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [validated, setValidated] = useState(false);

  const checkValidation = (type, value) => {
    if (type === 'pass1') {
      const condition = value.length >= 8;
      errors.password = { ...errors.password, hasEight: condition };
    }
    if (type === 'pass1') {
      const matchAry = value?.match(/[0-9]/g) ?? [];
      errors.password = { ...errors.password, hasNumber: matchAry.length !== 0 };
    }
    if (type === 'pass1') {
      const matchAry = value?.match(/[A-Z]/g) ?? [];
      errors.password = { ...errors.password, hasUppercase: matchAry.length !== 0 };
    }
    if (type === 'pass1') {
      const matchAry = value?.match(/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g) ?? []; //eslint-disable-line
      errors.password = { ...errors.password, hasSpecial: matchAry.length !== 0 };
    }
    if (type === 'pass2') {
      errors.unconfirmedPassword = pass1 !== value;
    }
    if (type === 'pass1') {
      if (value && pass2 && value === pass2 && errors.password.hasEight && errors.password.hasNumber && errors.password.hasUppercase && errors.password.hasSpecial) {
        setValidated(true);
      } else {
        setValidated(false);
      }
    }
    if (type === 'pass2') {
      if (value && pass1 && value === pass1 && errors.password.hasEight && errors.password.hasNumber && errors.password.hasUppercase && errors.password.hasSpecial) {
        setValidated(true);
      } else {
        setValidated(false);
      }
    }

    setErrors(errors);
    return errors;
  };

  return (
    <div className="reset-password" data-testid="reset-password">
      <Snackbar className="reset-password__snackbar" timeout={5000} />
      <div className="reset-password__bg">
        <img src={loginBackground || Default_BG} alt="Respresentation of the police department" />
      </div>
      <div className="reset-password__dialog">
        <div className="reset-password__title">
          Enter a New Password
        </div>
        <div className="reset-password__description">
          Enter a new password that is different from any of your previously-used passwords.
        </div>
        <div className="reset-password__new-password-label">
          <div className="reset-password__new-password-label-text">
            New Password
          </div>
          <div className="reset-password__new-password-label-required">
            REQUIRED
          </div>
        </div>
        <TextField
          className="reset-password__new-password-field"
          variant="outlined"
          type="password"
          error={Boolean(errors?.password?.any)}
          onChange={({ target: { value } }) => {
            setPass1(value)
            checkValidation('pass1', value)
          }}
          inputProps={{
            'data-testid': 'reset-password-new-password-field',
            'autoComplete': 'new-password' //eslint-disable-line
          }}
        />
        <div className="reset-password__retype-new-password-label">
          <div className="reset-password__retype-new-password-label-text">
            Retype New Password
          </div>
          <div className="reset-password__retype-new-password-label-required">
            REQUIRED
          </div>
        </div>
        <TextField
          className="reset-password__retype-new-password-field"
          variant="outlined"
          type="password"
          error={Boolean(errors?.password?.any)}
          onChange={({ target: { value } }) => {
            setPass2(value);
            checkValidation('pass2', value);
          }}
          onKeyUp={onEnter(() => {
            if (validated) {
              setValidated(false);
              changePassword({ password: pass1, password_confirmation: pass2 });
            }
          })}
          inputProps={{
            'data-testid': 'reset-password-retype-new-password-field',
            'autoComplete': 'new-password' //eslint-disable-line
          }}
        />
        <div className={cn('reset-password-confirm-error', { 'no-error': !errors?.unconfirmedPassword })}>
          <div className="material-icons">
            error
          </div>
          <div className="reset-password-confirm-error-text">
            Both passwords must match
          </div>
        </div>
        <div className="reset-password__divider" />
        <div className="reset-password__password-validation-text">
          Password must contain the following:
        </div>
        <div className="reset-password__password-validation-checks">
          <div className="reset-password__password-validation-check">
            <div
              className={cn('reset-password__box', { checked: errors?.password?.hasEight })}
              data-testid="reset-password-has-eight"
            >
              <div className="material-icons">
                {errors?.password?.hasEight ? 'done' : 'not_interested'}
              </div>
            </div>
            <div className="reset-password__password-validation-check-label">
              8 CHARACTERS
            </div>
          </div>
          <div className="reset-password__password-validation-check">
            <div
              className={cn('reset-password__box', { checked: errors?.password?.hasNumber })}
              data-testid="reset-password-has-number"
            >
              <div className="material-icons">
                {errors?.password?.hasNumber ? 'done' : 'not_interested'}
              </div>
            </div>
            <div className="reset-password__password-validation-check-label">
              1 NUMBER
            </div>
          </div>
          <div className="reset-password__password-validation-check">
            <div
              className={cn('reset-password__box', { checked: errors?.password?.hasUppercase })}
              data-testid="reset-password-has-uppercase"
            >
              <div className="material-icons">
                {errors?.password?.hasUppercase ? 'done' : 'not_interested'}
              </div>
            </div>
            <div className="reset-password__password-validation-check-label">
              1 UPPERCASE
            </div>
          </div>
          <div className="reset-password__password-validation-check">
            <div
              className={cn('reset-password__box', { checked: errors?.password?.hasSpecial })}
              data-testid="reset-password-has-special"
            >
              <div className="material-icons">
                {errors?.password?.hasSpecial ? 'done' : 'not_interested'}
              </div>
            </div>
            <div className="reset-password__password-validation-check-label">
              1 SPECIAL CHARACTER
            </div>
          </div>
        </div>
        <Button
          className="reset-password__submit"
          disabled={!validated}
          data-testid="reset-password-submit"
          onClick={() => {
            setValidated(false);
            changePassword({ password: pass1, password_confirmation: pass2 });
          }}
        >
          SET PASSWORD
        </Button>
      </div>
      <div className="reset-password__bs">
        <div className="reset-password__bs-text">
          &#169; 2021 Veritone, Inc. All Rights Reserved.
        </div>
        <div className="reset-password__bs-powered-by">
          POWERED BY
        </div>
        <div className="reset-password__bs-vtn">
          <VeritoneColorSvg />
        </div>
      </div>
    </div>
  )
};

ResetPassword.propTypes = {
  loginBackground: PropTypes.string,
  changePassword: PropTypes.func
};

const mapDispatchToProps = {
  changePassword: User.changePassword
};

const mapStateToProps = state => ({
  loginBackground: User.selectors.loginBackground(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
