import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { path, clone } from 'ramda';
import { onEnter } from '@utility/keypressHelpers';
import { Props, connector } from './props';
import './DynamicSelectForm.scss';

let helperRef: React.RefObject<HTMLDivElement>;

const DynamicSelectForm = ({ resultPath, isAll, title, options, multiple, form, currentPerson, boxHeight, boxWidth,
  onChange, setValidationErrors, style, goToNext, labels, numberOfPeople, boxPadding }: Props) => {
  let selected: string | string[];
  const [dynamicHeight, setDynamicHeight] = useState<number>(16);
  const [dynamicWidth, setDynamicWidth] = useState<number>(120);
  helperRef = useRef<HTMLDivElement>(null);

  if (isAll) {
    selected = path(['custom', 'person', currentPerson, resultPath], form) ?? [];
  } else {
    selected = path(['custom', resultPath], form) ?? [];
  }

  useEffect(() => {
    let height = 16;
    let width = 0;
    const padding = 20;

    options.forEach((option) => {
      const { length } = option;
      if (length * 14 > width) {
        width = length * 14 + padding;
      }
      if (width > 200) {
        height = (width / 200 >> 0) * 16 + padding;
        width = 200;
      }
    });

    setDynamicHeight(height);
    setDynamicWidth(width);
  });

  /* eslint-disable no-lonely-if */
  const setValueAndClearValidation = (value: string) => {
    let newSelected = clone(selected) ?? [];
    if (multiple) {
      if (typeof newSelected === 'string') {
        // This case only applies in the designer when someone changes the type
        newSelected = []
      }
      const index = selected.indexOf(value);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(value);
      }
    }
    if (isAll) {
      if (multiple) {
        onChange(newSelected, ['custom', 'person', currentPerson, resultPath]);
      } else {
        onChange(value, ['custom', 'person', currentPerson, resultPath]);
      }
    } else {
      if (multiple) {
        onChange(newSelected, ['custom', resultPath]);
      } else {
        onChange(value, ['custom', resultPath]);
      }
    }

    setValidationErrors?.({});
    goToNext?.();
  }

  return (
    <div
      className="dynamic-select-form"
      data-testid="dynamic-select-form"
      style={{ ...style, height: `calc(100% - ${helperRef.current?.clientHeight ?? 0}px)` }}
      key={`${resultPath}x${currentPerson}`}
    >
      {isAll && <div className="dynamic-select-form__person">
        <div className="material-icons">
          person
        </div>
        <div className="dynamic-select-form__person-label">
          {labels?.[currentPerson] || currentPerson + 1}
        </div>
        <div className="dynamic-select-form__person-same-for-all">
          Same For All
        </div>
        <div className="dynamic-select-form__person-progression">
          <b>{`${currentPerson + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="dynamic-select-form__person-progression-all">
          ALL
        </div>
      </div>}
      <div className="dynamic-select-form__title">
        {title}
      </div>
      <div className="dynamic-select-form__select-boxes">
        {options.map((option, gi) => {
          const opt = typeof option === 'string' ? option : option?.value;
          return (
            <div
              className={cn('dynamic-select-form__select-box', { active: multiple ? selected?.includes(opt) : selected === opt })}
              style={{
                height: boxHeight > 0 ? `${boxHeight}px` : `${dynamicHeight}px`,
                width: boxWidth > 0 ? `${boxWidth}px` : `${dynamicWidth}px`,
                padding: boxPadding > 0 ? `${boxPadding}px` : undefined
              }}
              onClick={() => setValueAndClearValidation(opt)}
              onKeyUp={onEnter(() => setValueAndClearValidation(opt))}
              key={`DynamicSelectForm-${gi}`}
              data-testid={`dynamic-select-form-box-${gi}`}
              role="button"
              tabIndex={0}
            >
              {opt}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const Helper = ({ HelperTextInfo }: { HelperTextInfo: string }) => {
  if (HelperTextInfo) {
    return (
      <div
        className="ripa-form-container__helper-box"
        ref={helperRef}
      >
        <div className="material-icons">
          help
        </div>
        <div className="ripa-form-container__helper-box-text">
          {HelperTextInfo}
        </div>
      </div>
    )
  }
  return null;
};

DynamicSelectForm.helper = Helper;

export default connector(DynamicSelectForm);
