import React from 'react';
import cn from 'classnames';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { range } from 'ramda';
import { Switch } from '@components/common';
import { Form, Template } from '@ducks';
import { onEnter } from '@utility/keypressHelpers';
import './RipaGenderForm.scss';

// interface Props {
//   Gender: any;
//   labels: string[];
//   numberOfPeople: number;
//   gender: string[];
//   useSameGenderForAll: boolean;
//   setUseSameGenderForAll: (value: boolean) => void;
//   nonConforming: boolean[];
//   setNonconforming: (personIndex: any, value: boolean) => void;
//   setGender: (personIndex: number, value: string) => void;
//   setLgbt: (personIndex: number, value: boolean) => void;
//   setValidationErrors: any;
// }

interface Props extends PropsFromRedux {
  Gender: any;
}

const RipaGenderForm = ({
  Gender,
  labels,
  numberOfPeople,
  gender,
  useSameGenderForAll,
  setUseSameGenderForAll,
  nonConforming,
  setNonconforming,
  setGender,
  setLgbt,
  setValidationErrors,
  flags,
}: Props) => {
  const genderVal = Gender?.Gender?.possibleValues || {};

  const handleSetUseSameGenderForAll = (useSame: any) => {
    if (useSame) {
      range(0, numberOfPeople).forEach(pi => {
        setGenderAndClearValidation(pi, gender?.[0])
      })
    }
    setUseSameGenderForAll(useSame)
  }
  const setGenderAndClearValidation = (i: number, value: string) => {
    const start = useSameGenderForAll ? 0 : i
    const end = useSameGenderForAll ? numberOfPeople : i + 1
    for (let pi = start; pi < end; pi += 1) {
      if (value.startsWith('Trans')) {
        setLgbt(pi, true);
      }
      setGender(pi, value);
    }
    setValidationErrors({});
  };

  const toggleNonConforming = (i: number, v: boolean) => {
    setNonconforming(i, v);
  };

  return (
    <div className="ripa-gender-form" data-testid="ripa-gender-form">
      <div className="ripa-gender-form__title">
        What gender were they perceived?
      </div>
      <div className="ripa-gender-form__use-same">
        <div className="ripa-gender-form__use-same-line" />
        <div className={cn('ripa-gender-form__use-same-text', { disabled: numberOfPeople === 1 })}>
          USE SAME FOR ALL
        </div>
        <Switch
          className="ripa-gender-form__use-same-switch"
          data-testid="ripa-gender-form-use-same-switch"
          onText="YES"
          offText="NO"
          value={useSameGenderForAll}
          onChange={handleSetUseSameGenderForAll}
          disabled={numberOfPeople === 1}
        />
        <div className="ripa-gender-form__use-same-line" />
      </div>
      <div className="ripa-gender-form__labels-container">
        <div className="ripa-gender-form__labels">
          {range(0, useSameGenderForAll ? 1 : numberOfPeople).map((_, i) => (
            <div className="ripa-gender-form__selection" key={`RipaGenderFormSelection-${i}`}>
              <div className="ripa-gender-form__label-container">
                <div className="ripa-gender-form__label">
                  <div className="material-icons">person</div>
                  <div
                    className="ripa-gender-form__label-text">
                    {useSameGenderForAll ? 'Everyone' : labels[i] || i + 1}
                  </div>
                </div>
                <div className="ripa-gender-form__gender-non-conforming-switch-container">
                  <Switch
                    className="ripa-gender-form__gender-non-conforming-switch"
                    data-testid="ripa-gender-form-gender-non-conforming-switch"
                    onText="YES"
                    offText="NO"
                    value={Boolean(nonConforming[i])}
                    onChange={v => toggleNonConforming(i, v)}
                  />
                  <div className="ripa-gender-form__gender-non-conforming-switch-text">
                    { flags?.pre2024 ? 'NONBINARY PERSON' : 'GENDER NONCONFORMING' }
                  </div>
                </div>
              </div>
              <div className="ripa-gender-form__select-boxes">
                {[genderVal.Male, genderVal.TransBoy, genderVal.Female, genderVal.TransGirl].map(({ value }, gi) => (
                  <div
                    className={cn('ripa-gender-form__select-box', {
                      active: gender?.[i] === value,
                    })}
                    onClick={() => setGenderAndClearValidation(i, gender?.[i] === value ? '' : value)}
                    onKeyUp={onEnter(() => setGenderAndClearValidation(i, gender?.[i] === value ? '' : value))}
                    key={`RipaGenderFormBox-${i}-${gi}`}
                    data-testid={`ripa-gender-form-box-${i}-${gi}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Helper = () => {
  const { pre2024 } = useSelector((state: any) => state.Form.flags);

  return <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      {`When applicable, you may select “${pre2024 ? 'Nonbinary Person' : 'Noncomforming'}” as the only value or in addition to one of the other
      values.`}
    </div>
  </div>
};

RipaGenderForm.helper = Helper;

const mapStateToProps = (state: any) => ({
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  gender: Form.selectors.gender(state),
  nonConforming: Form.selectors.nonConforming(state),
  useSameGenderForAll: Form.selectors.useSameGenderForAll(state),
  consentGiven: Form.selectors.consentGiven(state),
  Gender: Template.selectors.enumMap(state),
  flags: Form.selectors.flags(state),
});

const mapDispatchToProps = {
  setUseSameGenderForAll: Form.setFormField({ path: ['useSameGenderForAll'] }),
  setGender: (personIndex: number, value: string) => Form.setFormField({ path: ['person', personIndex, 'gender'] })(value),
  setLgbt: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'lgbt'] })(value),
  setNonconforming: (personIndex: number, value: boolean) => Form.setFormField({ path: ['person', personIndex, 'nonConforming'] })(value),
  setValidationErrors: Form.setValidationErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RipaGenderForm);
