import LogoSvg from './LogoSvg/LogoSvg'
import ReviewAllSvg from './ReviewAllSvg/ReviewAllSvg'
import ReviewExpandSvg from './ReviewExpandSvg/ReviewExpandSvg'
import VeritoneColorSvg from './VeritoneColorSvg/VeritoneColorSvg'

export {
  LogoSvg,
  ReviewAllSvg,
  ReviewExpandSvg,
  VeritoneColorSvg
}
