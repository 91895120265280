import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import * as NewReport from '@ducks/newReport';
import * as User from '@ducks/user';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  trafficViolation: Form.selectors.trafficViolation(state),
  trafficViolationCode: Form.selectors.trafficViolationCode(state),
  errors: Form.selectors.stepErrors(state),
  TrafficViolation: get(Template.selectors.enumMap(state), 'Trafficviolation.possibleValues', {}),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  clipboardCodeFeature: User.selectors.clipboardCodeFeature(state),
});

const mapDispatchToProps = {
  setTrafficViolation: (value: string, personIndex: any) => Form.setFormField({ path: ['person', personIndex, 'trafficViolation'] })(value),
  setTrafficViolationCode: (value: string, personIndex: any) => Form.setFormField({ path: ['person', personIndex, 'trafficViolationCode'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
