import React from 'react'
import cn from 'classnames'
import { range } from 'ramda'
import { connect } from 'react-redux'
import { Switch } from '@components/common'
import { onEnter } from '@utility/keypressHelpers'
import * as Form from '@ducks/form';
import * as Template from '@ducks/template';
import './RipaRaceForm.scss'

interface Props {
  RaceEnum: any;
  toggleRace: (personIndex: number, value: string) => void;
  setUseSameRaceForAll: (value: boolean) => void;
  useSameRaceForAll: boolean;
  race: string[];
  labels: string[];
  numberOfPeople: number;
}

const RipaRaceForm = ({ RaceEnum, toggleRace, setUseSameRaceForAll, useSameRaceForAll, race, labels, numberOfPeople }: Props) => {
  const raceEnumVal = RaceEnum.Race.possibleValues || {};
  return (
    <div className="ripa-race-form" data-testid="ripa-race-form">
      <div className="ripa-race-form__title">
        {'What\'s their perceived race or ethnicity?'}
      </div>
      <div className="ripa-race-form__use-same">
        <div className="ripa-race-form__use-same-line" />
        <div className={cn('ripa-race-form__use-same-text', { disabled: numberOfPeople === 1 })}>
          USE SAME FOR ALL
        </div>
        <Switch
          className="ripa-race-form__use-same-switch"
          data-testid="ripa-race-form-use-same-switch"
          onText="YES"
          offText="NO"
          disableChangeOnMount={true}
          value={useSameRaceForAll}
          onChange={setUseSameRaceForAll}
          disabled={numberOfPeople === 1}
        />
        <div className="ripa-race-form__use-same-line" />
      </div>
      <div className="ripa-race-form__labels-container">
        <div className="ripa-race-form__labels">
          {range(0, useSameRaceForAll ? 1 : numberOfPeople).map((_, pi) =>
            <div className="ripa-race-form__selection" key={`RipaAgeFormLabels-${pi}`}>
              <div className="ripa-race-form__label">
                <div className="material-icons">person</div>
                <div className="ripa-race-form__label-text" data-testid="ripa-race-form-label-text">
                  {useSameRaceForAll ? 'Everyone' : labels[pi] || pi + 1}
                </div>
              </div>
              <div className="ripa-race-form__select-box-content">
                {[raceEnumVal.Asian, raceEnumVal.Black, raceEnumVal.Hispanic, raceEnumVal.MiddleEastern, raceEnumVal.NativeAmerican,
                raceEnumVal.PacificIslander, raceEnumVal.White].map(({ value }, rvi) =>
                  <div
                    className={cn('ripa-race-form__select-box', { active: race[pi]?.includes(value) })}
                    key={`RipaRaceFormBox-${pi}-${rvi}`}
                    onClick={() => {
                      toggleRace(pi, value)
                    }}
                    onKeyUp={onEnter(() => toggleRace(pi, value))}
                    data-testid={`ripa-race-form-${pi}-${rvi}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Select all that apply.
    </div>
  </div>
)

RipaRaceForm.helper = Helper

const mapStateToProps = (state: any) => ({
  race: Form.selectors.race(state),
  useSameRaceForAll: Form.selectors.useSameRaceForAll(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  RaceEnum: Template.selectors.enumMap(state),
})

const mapDispatchToProps = {
  toggleRace: (personIndex: number, value: string) => Form.toggleRace({ personIndex, value }),
  setUseSameRaceForAll: Form.setFormField({ path: ['useSameRaceForAll'] })
}

export default connect(mapStateToProps, mapDispatchToProps)(RipaRaceForm)
