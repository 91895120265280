import env from '@engine/env';
import { capitalize } from 'lodash';

export const userSettingsErrorMessage = () => {
  const { userSettingsRequiredFields } = env().REACT_APP_FEATURE_FLAGS;
  if (!userSettingsRequiredFields || userSettingsRequiredFields.length === 0) {
    return 'Please check the required fields. No fields have been set'
  }

  let errorMessage = '';
  const isPlural = userSettingsRequiredFields.length > 1;
  for (let i = 0; i < userSettingsRequiredFields.length; i = i + 1) {
    errorMessage += i === 0 ? capitalize(userSettingsRequiredFields[i]) : userSettingsRequiredFields[i];
    if (i < userSettingsRequiredFields.length - 2) {
      errorMessage += ', '
    } else if (i === userSettingsRequiredFields.length - 2) {
      errorMessage += ' and '
    }
  }
  errorMessage += ` field${isPlural ? 's' : ''} ${isPlural ? 'are' : 'is'} required`
  return errorMessage;
};
