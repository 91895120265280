import { __, assoc, mergeRight, compose, map, path, pathOr } from 'ramda';
import { createSnackNotification, AlertLevel } from '@components/common';
import { downloadCsv } from '@utility/downloadCSV';
import { httpErrorHandler } from '@utility/httpErrorHandler';
import { CsvReportsNamespace } from './constants';
import { Action, CSVReport, Dispatch, GetState, ThunkDeps } from './types';

export const SET_CSV_REPORTS = `${CsvReportsNamespace}/SET_CSV_REPORTS`;
export const SET_LOADING_CSV_REPORTS = `${CsvReportsNamespace}/SET_LOADING_CSV_REPORTS`;
export const SET_REMOVE_CSV_REPORT_DIALOG_OPEN = `${CsvReportsNamespace}/SET_REMOVE_CSV_REPORT_DIALOG_OPEN`;

const INITIAL_STATE = {
  loading: {
    getCsvReports: false
  },
  csvReports: [],
  removeReportId: -1,
  removeCsvReportDialogOpen: false,
};

export const reducer = (state = INITIAL_STATE, action: Action<any>) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REMOVE_CSV_REPORT_DIALOG_OPEN:
      return mergeRight(state, { removeReportId: payload.id, removeCsvReportDialogOpen: payload.open });
    case SET_LOADING_CSV_REPORTS:
      return mergeRight(state, { loading: { ...state.loading, getCsvReports: payload.isLoading } });
    case SET_CSV_REPORTS: {
      payload.csvReports.sort((reportA: CSVReport, reportB: CSVReport) => new Date(reportB.created_at).valueOf() - new Date(reportA.created_at).valueOf());
      return assoc('csvReports', payload.csvReports, state);
    }
    default:
      return state;
  }
};

export const setRemoveCsvReportDialogOpen = ({ open, id }: {open: boolean; id?: number}) => ({
  type: SET_REMOVE_CSV_REPORT_DIALOG_OPEN,
  payload: { open, id },
})

export const setLoadingCsvReports = (isLoading: boolean) => ({
  type: SET_LOADING_CSV_REPORTS,
  payload: { isLoading },
});

export const setCsvReports = (csvReports: CSVReport[]) => ({
  type: SET_CSV_REPORTS,
  payload: { csvReports },
});

export const getExportCsvReports = () => (dispatch: Dispatch<any>, _getState: GetState, { http }: ThunkDeps) => {
  dispatch(setLoadingCsvReports(true));
  return http
    .get<{
      id: number;
      created_by_id: number;
      params: Record<string, any> & {
        exportType: string;
        start_time: string;
        end_time: string;
      };
      created_at: string;
      updated_at: string;
      complete: boolean;
    }[]>('/form_data_reports')
    .then(({ data }) => {
      dispatch(setCsvReports(data));
    })
    .catch(httpErrorHandler('Failed to Get CSV Report'))
    .finally(() => {
      dispatch(setLoadingCsvReports(false));
    });
};

export const downloadCsvReport = (reportId: number) => (_dispatch: Dispatch<any>, _getState: GetState, { http }: ThunkDeps) => http
  .get<string>(`/form_data_reports/${reportId}`)
  .then(({ data }) => {
    const filename = `csv-report-${reportId}.csv`;
    downloadCsv(data, filename);
  })
  .catch(httpErrorHandler('Failed to download CSV Report'));

  export const postExportCsvReports =
  ({ startDate, endDate, status, exportType, tz, filterType }: { startDate: string; endDate: string; status: string | string[]; exportType: string; tz?: string; filterType: string }) =>
  (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
    const requestData: {
      form_data_report: {
        start_time?: string;
        end_time?: string;
        stop_start_time?: string;
        stop_end_time?: string;
        status: string | string[];
        exportType: string;
        tz: string | undefined;
        relation: string;
      };
    } = {
      form_data_report: {
        start_time: startDate,
        end_time: endDate,
        status,
        exportType,
        tz,
        relation: 'all',
      }
    };

    if (filterType === 'stop') {
      requestData.form_data_report.stop_start_time = startDate;
      delete requestData.form_data_report.start_time;

      requestData.form_data_report.stop_end_time = endDate;
      delete requestData.form_data_report.end_time;
    }

    return http.post('/form_data_reports', requestData)
      .then(() => {
        createSnackNotification(AlertLevel.Success, 'Success', 'Created CSV Report');
        getExportCsvReports()(dispatch, getState, { http } as ThunkDeps);
      })
      .catch(httpErrorHandler('Failed to create CSV Report'));
  }

export const deleteCsvReport = () =>
  (dispatch: Dispatch<any>, getState: GetState, { http }: ThunkDeps) => {
    const { removeReportId } = getState()[CsvReportsNamespace];
    http.delete(`/form_data_reports/${removeReportId}`)
    .then(() => {
      createSnackNotification(AlertLevel.Success, 'Success', 'Deleted CSV Report');
      dispatch(setRemoveCsvReportDialogOpen({ open: false, id: -1 }))
      getExportCsvReports()(dispatch, getState, { http } as ThunkDeps);
    })
    .catch(httpErrorHandler('Failed to delete CSV Report'))
  }

export const selectors = {
  csvReportRows: compose(
    map((csvReportRow: CSVReport) => ({
      id: csvReportRow.id,
      createdAt: csvReportRow.created_at,
      createdId: csvReportRow.created_by_id,
      startTime: csvReportRow.params.stop_start_time ? csvReportRow.params.stop_start_time : csvReportRow.params.start_time,
      endTime: csvReportRow.params.stop_end_time ? csvReportRow.params.stop_end_time : csvReportRow.params.end_time,
      exportType: csvReportRow.params.exportType,
      status: csvReportRow.complete,
    })),
    pathOr([], [CsvReportsNamespace, 'csvReports'])
  ),
  removeCsvReportDialogOpen: path([CsvReportsNamespace, 'removeCsvReportDialogOpen']),
  loading: path([CsvReportsNamespace, 'loading']),
};
