import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';
import * as Template from '@ducks/template';
import { get } from 'lodash';

const mapStateToProps = (state: any) => ({
  primaryReason: Form.selectors.primaryReason(state),
  student: Form.selectors.student(state),
  ReasonsForStop: get(Template.selectors.enumMap(state), 'ReasonsForStop.possibleValues', {}),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  useSamePrimaryReasonForAll: Form.selectors.useSamePrimaryReasonForAll(state),
  currentSubloop: NewReport.selectors.currentSubloop(state),
  enumMap: Template.selectors.enumMap(state),
  typeOfStop: Form.selectors.typeOfStop(state),
  flags: Form.selectors.flags(state),
});

const mapDispatchToProps = {
  setUseSamePrimaryReasonForAll: Form.setFormField({ path: ['useSamePrimaryReasonForAll'] }),
  setPrimaryReason: (value: any, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'primaryReason'] })(value),
  setForceLastSubloop: NewReport.setForceLastSubloop,
  setCodeSection48900: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900'] })(value),
  setCodeSection48900Subdivision: (value: string, personIndex: number) => Form.setFormField({ path: ['person', personIndex, 'codeSection48900Subdivision'] })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
