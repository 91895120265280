import dayjs from 'dayjs';
import { AlertLevel } from '@components/common';
import emojiRegexRGI from 'emoji-regex/RGI_Emoji';
import { Form, Person, Step } from '@ducks/types';
import { LocationType, TemplateName2024, checkAfter2024 } from '@ducks/constants';
import { AssignmentEnums } from '@engine/ducks/constants';
import { isAlphanumeric } from '@utility/isAlphanumeric';
import { has, map } from 'lodash';

function StepsFunction(enums: any, flags: { pre2024: boolean; post2024: boolean; is2024Test?: boolean; earlyPost2024?: boolean }, form?:Form, templateName?:string, customQuestions?: any) {
  const { pre2024: before2024, post2024, is2024Test, earlyPost2024 } = flags;
  let pre2024 = before2024;
  if (post2024) {
    pre2024 = post2024;
  }

  const StepsArray: Step[] = [
    {
      title: 'Location & Time',
      substeps: [
        ...((is2024Test) ? [{
          // 1.0 2024 Test Case
          component: 'RipaTestCaseIdForm',
          validations: {
            testCaseId: [
              {
                hasError: ({ testCaseId: v }: { testCaseId: string }) => v === '' || v === 'TC#' || (parseInt(v.substring(3), 10) < 1 || parseInt(v.substring(3), 10) > 23),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Test Case Number required',
                  message: 'Enter a test case number 1-23',
                },
              }
            ]
          }
        }] : []),
        {
          // 1.1
          component: 'RipaCalendarForm',
          validations: {
            // The validation keys are paths with in the form. (e.g. key.subkey.subkey)
            // If the first key in the path is an array, all elements of the array are checked for the sub key ( not recursive ), e.g. ( person.gender would validate gender for all people )
            stopDateTime: [
              {
                hasError: ({ stopDateTime: v }) =>
                  dayjs(v.slice(0, 10), 'YYYY-MM-DD', 'en', true).isValid() &&
                  dayjs(v.slice(0, 10), 'YYYY-MM-DD', 'en', true) <
                  dayjs().subtract(1, 'year')
                    .month(0)
                    .date(0)
                    .hour(0)
                    .minute(0),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Date too old',
                  message: 'Enter a date on or greater then Jan 1 of last year.',
                },
              },
              {
                hasError: ({ stopDateTime: v }) =>
                  dayjs(v).isValid() && dayjs(v).isAfter(dayjs(), 'day'),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Future date',
                  message: 'Enter a date that is today or earlier',
                },
              },
              {
                hasError: ({ stopDateTime: v }) =>
                  !dayjs(v.slice(0, 10), 'YYYY-MM-DD', 'en', true).isValid(),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Invalid date',
                  message: 'Enter a valid date',
                },
              },
              {
                hasError: ({ stopDateTime: v }) => earlyPost2024 && !flags.is2024Test && checkAfter2024(v) ? templateName !== TemplateName2024 : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Invalid template',
                  message: 'Organization is not provisioned for 2024 forms. Please Contact Veritone.',
                }
              },
              {
                hasError: ({ stopDateTime, createdAt, offlineCreatedAt }) => {
                  const actualCreatedAt = offlineCreatedAt || createdAt;
                  return dayjs(stopDateTime).isAfter(dayjs(actualCreatedAt), 'day');
                },
                snack: {
                  level: AlertLevel.Error,
                  title: 'Error',
                  message: 'Stop date cannot be a date later than when this report was initially created',
                },
              },
            ],
          },
        },
        {
          component: 'RipaTimeForm',
          validations: {
            stopDateTime: [
              {
                hasError: ({ stopDateTime: v }) =>
                  dayjs(v).isValid() && dayjs(v) > dayjs(),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Future time',
                  message: 'Enter a time that is not in the future',
                },
              },
              {
                hasError: ({ stopDateTime: v }) => !dayjs(v).isValid(),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Invalid time',
                  message: 'Enter a valid time',
                },
              },
              {
                hasError: ({ stopDateTime, createdAt, offlineCreatedAt }) => {
                  const actualCreatedAt = offlineCreatedAt || createdAt;
                  return dayjs(stopDateTime).isAfter(dayjs(actualCreatedAt), 'minute');
                },
                snack: {
                  level: AlertLevel.Error,
                  title: 'Error',
                  message: 'Stop time cannot be a time later than when this report was initially created',
                },
              },
            ],
          },
        },
        {
          component: 'RipaResponseToCallForm',
          validations: {
            responseToCall: [
              {
                hasError: ({ responseToCall: r }) => r !== true && r !== false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Response required',
                  message: 'Please select an answer',
                },
              },
            ],
            ...((pre2024) && {
              officerWorksWithNonPrimaryAgency: [
                {
                  hasError: ({ officerWorksWithNonPrimaryAgency: r, enumMap }) =>
                    ('OfficerWorksWithNonPrimaryAgency' in enumMap) && r !== true && r !== false,
                  snack: {
                    level: AlertLevel.Error,
                    title: 'Response required',
                    message: 'Please select an answer',
                  },
                },
              ],
              stopDuringWellnessCheck: [
                {
                  hasError: ({ stopDuringWellnessCheck: r, enumMap }) =>
                    ('StopDuringWellnessCheck' in enumMap) && r !== true && r !== false,
                  snack: {
                    level: AlertLevel.Error,
                    title: 'Response required',
                    message: 'Please select an answer',
                  },
                },
              ],
            })
          },
        },
        {
          component: 'RipaDurationForm',
          validations: {
            duration: [
              {
                hasError: ({ duration: v }) => v === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Duration required',
                  message: 'Enter a duration',
                },
              },
              {
                hasError: ({ duration: v }) => v === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Must have duration',
                  message: 'Enter a duration greater then 0',
                },
              },
              {
                hasError: ({ duration: v }) => !Number.isInteger(v),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Must be an integer',
                  message: 'Enter an integer number of minutes',
                },
              },
            ],
          },
        },
        {
          component: pre2024 ? 'RipaTabbedLocationForm' : 'RipaLocationForm',
          validations: pre2024 ? {
            block: [
              {
                hasError: ({ block, locationType }) =>
                locationType === LocationType.Block ?
                block === null || `${block}`?.trim() === '' || !(`${block}`?.trim().length > 0 && `${block}`?.trim().length <= 8)
                : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Block required',
                  message: 'Enter a block',
                },
              },
              {
                hasError: ({ block, locationType }) =>
                  locationType === LocationType.Block && `${block}`?.trim().length > 0 ?
                  !(/^(?:\d{1,1}0|\d{1,6}00)$/.test(block))
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Block required',
                  message: 'Enter a valid block number',
                },
              },
            ],
            street: [
              {
                hasError: ({ street, locationType }) =>
                  locationType === LocationType.Block ?
                  !(street?.trim().length > 0 && street?.trim().length <= 50)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Street required',
                  message: 'Enter a street',
                },
              },
              {
                hasError: ({ street, locationType }) =>
                  locationType === LocationType.Block && street?.trim().length > 0 ?
                    !isAlphanumeric(street, 1, 50)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Street required',
                  message: 'Enter a street name with alphanumeric characters',
                },
              },
            ],
            city: [
              {
                hasError: ({ city: v }) => !v?.trim(),
                snack: {
                  level: AlertLevel.Error,
                  title: 'City required',
                  message: 'Enter a city',
                },
              },
              {
                hasError: ({ city: v }) =>
                  v && window.cities?.every((c) => c.City.localeCompare(v, 'en', { sensitivity: 'accent' }) !== 0),
                snack: {
                  level: AlertLevel.Error,
                  title: 'City required',
                  message: 'Enter a valid city',
                },
              },
            ],
            school: [
              {
                hasError: ({ school, locationType }) =>
                  locationType === LocationType.School ? !school?.trim() : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'School required if occurred at a school',
                  message: 'Enter a school',
                },
              },
            ],
            schoolAddress: [
              {
                hasError: ({ schoolAddress, locationType }) =>
                  locationType === LocationType.School ?
                  !(schoolAddress.trim().length >= 5 && schoolAddress.trim().length <= 150)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'School address required',
                  message: 'Enter the school address between 5 and 150 characters',
                },
              },
              {
                hasError: ({ schoolAddress, locationType }) =>
                  locationType === LocationType.School && schoolAddress?.trim().length >= 5 ?
                  !(isAlphanumeric(schoolAddress))
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'School address required',
                  message: 'Enter the school address with alphanumeric characters',
                },
              },
            ],
            crossStreet1: [
              {
                hasError: ({ crossStreet1, locationType }) =>
                  locationType === LocationType.Intersection ? !isAlphanumeric(crossStreet1, 1, 50) : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Intersection Cross Street 1 required',
                  message: 'Enter Cross Street 1',
                },
              },
            ],
            crossStreet2: [
              {
                hasError: ({ crossStreet2, locationType }) =>
                  locationType === LocationType.Intersection ? !isAlphanumeric(crossStreet2, 1, 50) : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Intersection Cross Street 2 required',
                  message: 'Enter Cross Street 2',
                },
              },
            ],
            highway: [
              {
                hasError: ({ highway, locationType }) =>
                  locationType === LocationType.HighwayExit ?
                  !(highway.trim().length > 0 && highway.trim().length <= 75)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Highway required',
                  message: 'Enter a highway',
                },
              },
              {
                hasError: ({ highway, locationType }) =>
                  locationType === LocationType.HighwayExit && highway?.trim().length > 0 ?
                  !(/^[a-zA-Z0-9 ]+$/.test(highway))
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Highway required',
                  message: 'Enter a highway with alphanumeric characters',
                },
              },
            ],
            exit: [
              {
                hasError: ({ exit, locationType }) =>
                  locationType === LocationType.HighwayExit ?
                  !(exit.trim().length > 0 && exit.trim().length <= 50)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Exit required',
                  message: 'Enter an exit',
                },
              },
              {
                hasError: ({ exit, locationType }) =>
                  locationType === LocationType.HighwayExit && exit?.trim().length > 0 ?
                  !(/^[a-zA-Z0-9 ]+$/.test(exit))
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Exit required',
                  message: 'Enter an exit with alphanumeric characters',
                },
              },
            ],
            otherLocation: [
              {
                hasError: ({ otherLocation, locationType }) =>
                  locationType === LocationType.Other ?
                  !(otherLocation.trim().length >= 5 && otherLocation.trim().length <= 150)
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Other location required',
                  message: 'Other location must bettween 5 and 150 characters',
                },
              },
              {
                hasError: ({ otherLocation, locationType }) =>
                  locationType === LocationType.Other && otherLocation?.trim().length >= 5 ?
                  !(isAlphanumeric(otherLocation))
                  : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Other location required',
                  message: 'Enter an approximate location with alphanumeric characters',
                },
              },
            ],
          } : {
            location: [
              {
                hasError: ({ location: v }) => v === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Location required',
                  message: 'Enter a location',
                },
              },
              {
                hasError: ({ location: v }) => v && v?.trim().length < 5,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Location must be at least 5 characters',
                  message: 'Enter a location',
                },
              },
            ],
            city: [
              {
                hasError: ({ city: v }) => !v?.trim(),
                snack: {
                  level: AlertLevel.Error,
                  title: 'City required',
                  message: 'Enter a city',
                },
              },
              {
                hasError: ({ city: v }) =>
                  v && window.cities?.every((c) => c.City.localeCompare(v, 'en', { sensitivity: 'accent' }) !== 0),
                snack: {
                  level: AlertLevel.Error,
                  title: 'City required',
                  message: 'Enter a valid city',
                },
              },
            ],
            school: [
              {
                hasError: ({ school, stopHappenedAtPublicSchool }) =>
                  stopHappenedAtPublicSchool ? !school?.trim() : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'School required if occurred at a school',
                  message: 'Enter a school',
                },
              },
            ],
          },
        }
      ],
    },
    {
      title: 'Description',
      substeps: [
        ...((pre2024) && 'OfficerTypeofAssignment' in enums && 'OffDuty' in enums.OfficerTypeofAssignment.possibleValues && 'ContractedByAnotherLEA' in enums.OfficerTypeofAssignment.possibleValues
          ? [
            {
              component: 'RipaTypeOfAssignmentForm',
              validations: {
                assignmentDescription: [
                  {
                    hasError: ({ assignment, assignmentDescription, typeOfAssignmentOfficer }: { assignment: string; assignmentDescription: string; typeOfAssignmentOfficer: string }) =>
                      validateAssignmentDescription(typeOfAssignmentOfficer, assignment) &&
                      assignmentDescription?.trim().length === 0,
                    snack: {
                      level: AlertLevel.Error,
                      title: 'Assignment description required',
                      message: 'Enter a assignment description',
                    },
                  },
                  {
                    hasError: ({ assignment, assignmentDescription, typeOfAssignmentOfficer }: { assignment: string; assignmentDescription: string; typeOfAssignmentOfficer: string }) =>
                      validateAssignmentDescription(typeOfAssignmentOfficer, assignment) &&
                      assignmentDescription?.trim().length > 0 &&
                      assignmentDescription?.trim().length < 3,
                    snack: {
                      level: AlertLevel.Error,
                      title: 'Assignment description required',
                      message: 'Enter at least 3 characters of assignment description',
                    },
                  },
                  {
                    hasError: ({ assignment, assignmentDescription, typeOfAssignmentOfficer }: { assignment: string; assignmentDescription: string; typeOfAssignmentOfficer: string }) =>
                      validateAssignmentDescription(typeOfAssignmentOfficer, assignment) &&
                      assignmentDescription?.trim().length > 3 &&
                      !!emojiRegexRGI().exec(assignmentDescription),
                    snack: {
                      level: AlertLevel.Error,
                      title: 'Disallowed characters detected',
                      message: 'Emoji are not allowed in this field',
                    },
                  },
                ],
              },
            },
          ]
          : []),
        ...(((pre2024) && 'TypeOfStop' in enums) ? [{
          component: 'RipaStopForm',
          validations: {
            typeOfStop: [
              {
                hasError: ({ typeOfStop }: { typeOfStop: string }) => typeOfStop === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Type of stop required',
                  message: 'Select a type of stop',
                },
              },
            ],
          },
        }] : []),
        { component: 'RipaPeopleForm' }, // 2.1
        {
          component: 'RipaLabelForm', // 2.2
          precondition: ({ numberOfPeople }) => numberOfPeople > 1,
        },
        {
          // 2.3
          component: 'RipaGenderForm',
          validations: {
            'person.gender': [
              {
                hasError: ({ person, useSameGenderForAll }, i = 0) =>
                  useSameGenderForAll
                    ? !person[0].gender && !person[0].nonConforming
                    : !person[i].gender && !person[i].nonConforming,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Gender required',
                  message: 'Select a gender for all people',
                },
              },
            ],
          },
        },
        {
          component: 'RipaAgeForm',
          validations: {
            'person.age': [
              {
                hasError: ({ person }, i = 0) => !person[i].age,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Must have age',
                  message: 'Enter an age greater then 0',
                },
              },
              {
                hasError: ({ person }, i = 0) =>
                  person[i].age && (person[i].age < 1 || person[i].age > 120),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Invalid Age',
                  message: 'Valid age ranges from 1 to 120',
                },
              },
            ],
          },
        }, // 2.4
        {
          component: 'RipaRaceForm',
          validations: {
            'person.race': [
              {
                hasError: ({ person, useSameRaceForAll }, i = 0) =>
                  useSameRaceForAll
                    ? !person[0].race || person[0].race.length === 0
                    : !person[i].race || person[i].race.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Race required',
                  message: 'Select a race for all people',
                },
              },
            ],
          },
        },
        {
          component: ((pre2024) && ('PerceivedToBeUnhoused' in enums)) ? 'RipaDisabilityForm2024' : 'RipaDisabilityForm',
          validations: {
            'person.sexualOrientation': [
              {
                hasError: ({ person }) =>
                  pre2024 && post2024 &&
                  person.filter(
                    (p: Person) =>
                      (!p.sexualOrientation)
                  ).length > 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Perceived Sexual Orientation required',
                  message: 'Select a perceived sexual orientation',
                },
              },
            ],
          }
        },
        {
          component: 'RipaDisabilityDetailsForm',
          precondition: ({ person }) =>
            person.map((p: Person) => p.disabled).includes(true),
          validations: {
            'person.disabilities': [
              {
                hasError: ({ person }) =>
                  person.filter(
                    (p: Person) =>
                      (p.disabled && !p.disabilities) ||
                      (p.disabled && p.disabilities.length === 0)
                  ).length > 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Disabilities required',
                  message: 'Select disabilities in contacts listed as disabled',
                },
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Reasons For Stop',
      loop: {
        iterations: 'numberOfPeople'
      },
      substeps: [
        {
          component: 'RipaPrimaryReasonForm', // 3.1
          precondition: ({ useSamePrimaryReasonForAll, currentSubloop }) => !(useSamePrimaryReasonForAll && currentSubloop > 0),
          validations: {
            'person.primaryReason': [
              {
                hasError: ({ person, currentSubloop }) => person?.[currentSubloop]?.primaryReason === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Primary reason required',
                  message: 'Select a primary reason',
                },
              },
            ],
            'person.k12': [
              {
                hasError: ({ person, useSamePrimaryReasonForAll, currentSubloop, enumMap }) =>
                useSamePrimaryReasonForAll ?
                  !person[0].student &&
                  (person[0].primaryReason.includes(enumMap?.ReasonsForStop.possibleValues.PossibleDisciplineEducationCode48900.value) ||
                  person[0].primaryReason.includes(enumMap?.ReasonsForStop.possibleValues.DetermineStudentViolatedSchoolPolicy.value))
                : !person[currentSubloop].student &&
                  (person[currentSubloop].primaryReason.includes(enumMap?.ReasonsForStop.possibleValues.PossibleDisciplineEducationCode48900.value) ||
                  person[currentSubloop].primaryReason.includes(enumMap?.ReasonsForStop.possibleValues.DetermineStudentViolatedSchoolPolicy.value)),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Primary reason K12',
                  message: 'Select a different primary reason. K12 value cannot be selected',
                },
              },
            ],
          },
        },
        {
          component: 'RipaTrafficViolationForm', // 3.2
          precondition: ({ person, currentSubloop, enumMap, useSamePrimaryReasonForAll }) => !(useSamePrimaryReasonForAll && currentSubloop > 0) && person?.[currentSubloop]?.primaryReason === enumMap?.ReasonsForStop?.possibleValues.TrafficViolation.value,
          validations: {
            trafficViolation: [
              {
                hasError: ({ person, currentSubloop }) => person?.[currentSubloop]?.trafficViolation === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Type of traffic violation required',
                  message: 'Select a traffic violation type',
                },
              },
            ],
            trafficViolationCode: [
              {
                hasError: ({ person, currentSubloop }) => person?.[currentSubloop]?.trafficViolationCode === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Traffic violation code required',
                  message: 'Select a traffic violation code',
                },
              },
            ],
          },
        },
        {
          component: 'RipaSuspicionForm', // 3.3
          precondition: ({ person, currentSubloop, enumMap, useSamePrimaryReasonForAll }) =>
          !(useSamePrimaryReasonForAll && currentSubloop > 0) && person?.[currentSubloop]?.primaryReason === enumMap?.ReasonsForStop?.possibleValues.ReasonableSuspicion.value,
          validations: {
            'form.reasonableSuspicion': [
              {
                hasError: ({ person, currentSubloop }) =>
                  person?.[currentSubloop]?.reasonableSuspicion.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Suspicion type required',
                  message: 'Select a suspicion type',
                },
              },
              {
                hasError: ({ person, currentSubloop, enumMap }) =>
                (pre2024 && !post2024 && person?.[currentSubloop]?.reasonableSuspicion.length === 1 &&
                  person?.[currentSubloop]?.reasonableSuspicion.includes(enumMap?.ReasonableSuspicion?.possibleValues.MatchedDescriptionSuspectVehicle.value)),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Suspicion type selection required',
                  message: 'Cannot be the only suspicion type selected',
                },
              },
            ],
          },
        },
        ...((pre2024) ? [{
          component: 'RipaProbableCauseForm',
          precondition: ({ person, currentSubloop, enumMap, useSamePrimaryReasonForAll }:{ person: Person[]; currentSubloop: number; enumMap: any; useSamePrimaryReasonForAll: boolean }) =>
          (!(useSamePrimaryReasonForAll && currentSubloop > 0) && pre2024 && enumMap?.ProbableCause?.possibleValues && person?.[currentSubloop]?.primaryReason === enumMap?.ReasonsForStop?.possibleValues.ProbableCauseToArrestOrSearch.value),
          validations: {
            'form.probableCause': [
              {
                hasError: ({ person, currentSubloop }:{ person: Person[]; currentSubloop: number}) =>
                  person?.[currentSubloop]?.probableCause.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Probable cause required',
                  message: 'Select a probable cause',
                },
              },
              {
                hasError: ({ person, currentSubloop, enumMap }:{ person: Person[]; currentSubloop: number; enumMap: any }) =>
                (pre2024 && !post2024 && person?.[currentSubloop]?.probableCause.length === 1 &&
                  person?.[currentSubloop]?.probableCause.includes(enumMap?.ReasonableSuspicion?.possibleValues.MatchedDescriptionSuspectVehicle.value)),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Probable cause selection required',
                  message: 'Cannot be the only probable cause selected',
                },
              },
            ],
            'form.probableCauseCode': [
              {
                hasError: ({ person, currentSubloop }:{ person: Person[]; currentSubloop: number}) =>
                  person?.[currentSubloop]?.probableCauseCode.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Probable cause code required',
                  message: 'Select a probable cause code',
                },
              }
            ]
          },
        }] : []),
        {
          component: 'RipaCodeSectionForm', // 3.4
          precondition: ({ person, currentSubloop, enumMap, useSamePrimaryReasonForAll }) =>
          !(useSamePrimaryReasonForAll && currentSubloop > 0) && person?.[currentSubloop]?.primaryReason === enumMap?.ReasonsForStop?.possibleValues.PossibleDisciplineEducationCode48900.value,
          validations: {
            codeSection48900: [
              {
                hasError: ({ person, currentSubloop }) => person?.[currentSubloop]?.codeSection48900 === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Code section required',
                  message: 'Select a code section',
                },
              },
            ],
            codeSection48900Subdivision: [
              {
                hasError: ({ person, currentSubloop }) =>
                  person?.[currentSubloop]?.codeSection48900.includes('select subsection') &&
                  person?.[currentSubloop]?.codeSection48900Subdivision === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Code section required',
                  message: 'Select a code section',
                },
              },
            ],
          },
        },
        ...(pre2024 ? [{
          component: 'RipaReasonGivenStoppedPersonForm',
          precondition: ({ useSamePrimaryReasonForAll, currentSubloop }: { currentSubloop: number; useSamePrimaryReasonForAll: boolean }) => !(useSamePrimaryReasonForAll && currentSubloop > 0),
          validations: {
            'form.reasonGivenStoppedPerson': [
              {
                hasError: ({ person, currentSubloop }: any) =>
                  person?.[currentSubloop]?.reasonGivenStoppedPerson.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Reason Given to Stopped Person required',
                  message: 'Select a reason given to stopped person',
                },
              },
            ],
          },
        }] : []),
        ...((pre2024 && 'TypeOfStop' in enums) ? [{
          component: 'RipaStoppedPassengerForm',
          precondition: ({ typeOfStop }: { typeOfStop: string }) => typeOfStop === enums?.TypeOfStop.possibleValues?.Vehicular.value,
        }] : []),
        ...((pre2024 && 'TypeOfStop' in enums) ? [{
          component: 'RipaStoppedInsideResidenceForm',
          precondition: ({ typeOfStop }: { person: Person[]; currentSubloop: number; typeOfStop: string }) =>
            typeOfStop === enums?.TypeOfStop.possibleValues?.Pedestrian.value
        }] : []),
        {
          // 3.5
          component: 'RipaDescriptionForm',
          precondition: ({ currentSubloop, useSamePrimaryReasonForAll }:{ currentSubloop: number; useSamePrimaryReasonForAll: boolean }) =>
            (!(useSamePrimaryReasonForAll && currentSubloop > 0)),
          validations: {
            stopDescription: [
              {
                hasError: ({ person, currentSubloop }) => person?.[currentSubloop]?.stopDescription?.trim() === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Stop description required',
                  message: 'Enter a stop description',
                },
              },
              {
                hasError: ({ person, currentSubloop }) =>
                  person?.[currentSubloop]?.stopDescription?.trim().length < 5,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Stop description required',
                  message: 'Enter at least 5 characters of stop description',
                },
              },
              {
                // generate new RegExp each time due to global flag being set
                hasError: ({ person, currentSubloop }) =>
                  !!emojiRegexRGI().exec(person?.[currentSubloop]?.stopDescription),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Disallowed characters detected',
                  message: 'Emoji are not allowed in this field',
                },
              },
            ],
          },
        },
      ],
    },
    {
      // 4.1
      title: 'Actions Taken',
      loop: {
        iterations: 'numberOfPeople'
      },
      substeps: [
        {
          component: 'RipaActionTakenForm',
          validations: {
            'person.actionTaken': [
              {
                hasError: ({ person, currentSubloop }) =>
                  person?.[currentSubloop]?.actionTakenBool
                    ? (!person?.[currentSubloop]?.actionTaken ||
                      person?.[currentSubloop]?.actionTaken.length === 0) &&
                    !person?.[currentSubloop]?.primaryReason.includes(
                      'Consensual Encounter resulting in a search'
                    )
                    : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Action taken required',
                  message: "Toggle 'No action taken' if there was no action",
                },
              },
              {
                hasError: ({ person, currentSubloop }) => {
                  let nonForceActionsCount = 0;
                  let forceActionsCount = 0;
                  let oldActionsCount = 0;
                  let sharedActionCount = 0;
                  if (pre2024) {
                    nonForceActionsCount = Object.values(enums?.NonForceActionsTaken.possibleValues)?.filter((pv: any) => person?.[currentSubloop]?.actionTaken.includes(pv.value)).length;
                    forceActionsCount = Object.values(enums?.ForceActionsTaken.possibleValues)?.filter((pv: any) => person?.[currentSubloop]?.actionTaken.includes(pv.value)).length;
                    oldActionsCount = Object.values(enums?.actionsTakenDuringStop.possibleValues)?.filter((pv: any) => person?.[currentSubloop]?.actionTaken.includes(pv.value)).length;
                    sharedActionCount = person?.[currentSubloop]?.actionTaken.filter((at: string) =>
                      (Object.values(enums?.NonForceActionsTaken.possibleValues)?.filter((pv: any) => pv.value === at).length > 0 || Object.values(enums?.ForceActionsTaken.possibleValues)?.filter((pv: any) => pv.value === at).length > 0) &&
                      Object.values(enums?.actionsTakenDuringStop.possibleValues)?.filter((pv: any) => pv.value === at).length > 0
                    ).length
                  }

                  return person?.[currentSubloop]?.actionTakenBool && pre2024 && !post2024 ?
                    (nonForceActionsCount + forceActionsCount - sharedActionCount > 0) && (oldActionsCount === 0)
                    : false
                },
                snack: {
                  level: AlertLevel.Error,
                  title: 'Action taken required',
                  message: 'Please select one non-2024 action',
                },
              },
            ],
            'person.consensualEncounter': [
              {
                hasError: ({ person, useSamePrimaryReasonForAll, currentSubloop }) =>
                  person?.[currentSubloop]?.primaryReason?.includes('Consensual Encounter resulting in a search') ||
                    (useSamePrimaryReasonForAll ? person.some((p: Person) => p.primaryReason.includes('Consensual Encounter resulting in a search')) : false)
                    ? !(
                      person?.[currentSubloop]?.actionTaken
                        ?.toString()
                        .includes('Search of person was conducted') ||
                      person?.[currentSubloop]?.actionTaken
                        ?.toString()
                        .includes('Search of property was conducted')
                    )
                    : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Action taken required',
                  message:
                    'When “Consensual Encounter resulting in a search” is selected, you must select a search action.',
                },
              },
            ],
          },
        },
        {
          component: 'RipaSearchBasisForm', // 4.2
          precondition: ({ person, currentSubloop, enumMap }) =>
            [
              enumMap?.actionsTakenDuringStop?.possibleValues
                .SearchPersonConducted,
              enumMap?.actionsTakenDuringStop?.possibleValues
                .SearchPropertyConducted,
            ].filter(({ value }) =>
              person?.[currentSubloop]?.actionTaken?.includes(value)
            ).length > 0 && person?.[currentSubloop]?.actionTakenBool,
          validations: {
            'person.searchBasis': [
              {
                hasError: ({ person, currentSubloop }) =>
                  !person[currentSubloop]?.searchBasis ||
                  person[currentSubloop].searchBasis.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Search basis required',
                  message: 'Select a search basis',
                },
              },
              {
                hasError: ({ person, currentSubloop, enumMap }) => (!person[currentSubloop]?.actionTaken?.includes(enumMap?.actionsTakenDuringStop?.possibleValues
                    .SearchPropertyConducted.value) && person[currentSubloop]?.searchBasis?.includes(enumMap?.basisForSearch?.possibleValues.VehicleInventory.value)),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Vehicle Inventory cannot be selected',
                  message: 'When search of property is not conducted, vehicle inventory cannot be selected',
                },
              },
            ],
            ...((pre2024) && {
              'person.consentType': [
                {
                  hasError: ({ person, useSameActionTakenForAll, enumMap, useSameActionForAllPersonIndex, currentSubloop }) =>
                    enumMap.typeOfSearchConsent
                      ? useSameActionTakenForAll
                        ? !((person[useSameActionForAllPersonIndex].searchBasis.includes('Consent given') &&
                          person[useSameActionForAllPersonIndex].consentType !== '' &&
                          Object.keys(enumMap?.typeOfSearchConsent?.possibleValues).includes(person[useSameActionForAllPersonIndex].consentType)) ||
                          (!person[useSameActionForAllPersonIndex].searchBasis.includes('Consent given') &&
                            person[useSameActionForAllPersonIndex].consentType === ''))
                        : !((person[currentSubloop].searchBasis.includes('Consent given') &&
                          person[currentSubloop].consentType !== '' &&
                          Object.keys(enumMap?.typeOfSearchConsent?.possibleValues).includes(person[currentSubloop].consentType)) ||
                          (!person[currentSubloop].searchBasis.includes('Consent given') &&
                            person[currentSubloop].consentType === ''))
                      : false,
                  snack: {
                    level: AlertLevel.Error,
                    title: 'Action taken required',
                    message: 'When "Consent given" is selected, you must select a consent type.',
                  },
                },
              ],
            })
          },
        },
        {
          component: 'RipaSearchDescriptionForm', // 4.3
          precondition: ({ person, currentSubloop, enumMap }) => {
            const initialReq =
              [
                enumMap?.actionsTakenDuringStop?.possibleValues
                  .SearchPersonConducted,
                enumMap?.actionsTakenDuringStop?.possibleValues
                  .SearchPropertyConducted,
              ].filter(({ value }) =>
                person?.[currentSubloop]?.actionTaken?.includes(value)
              ).length > 0 && person?.[currentSubloop]?.actionTakenBool;

            const conditionOfParole =
              enumMap?.basisForSearch?.possibleValues.ConditionOfParole.value;
            const paroleReq = !(
              person[currentSubloop]?.searchBasis?.includes(conditionOfParole) &&
              person[currentSubloop]?.searchBasis.length === 1
            ) || post2024;

            return initialReq && paroleReq;
          },
          validations: {
            'person.searchDescription': [
              {
                hasError: ({ person, currentSubloop }) =>
                  !person[currentSubloop]?.searchDescription ||
                  person[currentSubloop].searchDescription?.trim().length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Search description required',
                  message: 'Enter a search description',
                },
              },
              {
                hasError: ({ person, currentSubloop }) =>
                  !person[currentSubloop]?.searchDescription ||
                  person[currentSubloop].searchDescription?.trim().length < 5,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Search description required',
                  message: 'Enter at least 5 characters of search description',
                },
              },
              {
                // generate new RegExp each time due to global flag being set
                hasError: ({ person, currentSubloop }) =>
                  person[currentSubloop]?.searchDescription &&
                  !!emojiRegexRGI().exec(person[currentSubloop]?.searchDescription),
                snack: {
                  level: AlertLevel.Error,
                  title: 'Disallowed characters detected',
                  message: 'Emoji are not allowed in this field',
                },
              },
            ],
          },
        },
        {
          component: 'RipaSeizureBasisForm', // 4.4
          precondition: ({ person, currentSubloop, enumMap }) =>
            person?.[currentSubloop]?.actionTaken?.includes(
              enumMap?.actionsTakenDuringStop?.possibleValues.PropertySeized.value
            ) && person?.[currentSubloop]?.actionTakenBool,
          validations: {
            'person.seizedPropertyBasis': [
              {
                hasError: ({ person, currentSubloop }) =>
                  !person[currentSubloop]?.seizedPropertyBasis ||
                  person[currentSubloop].seizedPropertyBasis.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Seizure basis required',
                  message: 'Select a seizure basis',
                },
              },
            ],
          },
        },
        {
          component: 'RipaSeizureTypeForm', // 4.5
          precondition: ({ person, currentSubloop, enumMap }) =>
            person?.[currentSubloop]?.actionTaken?.includes(
              enumMap?.actionsTakenDuringStop?.possibleValues.PropertySeized.value
            ) && person?.[currentSubloop]?.actionTakenBool,
          validations: {
            'person.seizedPropertyType': [
              {
                hasError: ({ person, currentSubloop }) =>
                  !person[currentSubloop]?.seizedPropertyType ||
                  person[currentSubloop].seizedPropertyType.length === 0,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Seizure type required',
                  message: 'Select a seizure type',
                },
              },
            ],
          },
        },
        {
          component: 'RipaContrabandForm', // 4.6
          // precondition: ({ person, currentSubloop, enumMap }) =>
          //   person?.[currentSubloop]?.contrabandOrEvidenceDiscovered &&
          //   person?.[currentSubloop]?.actionTaken?.includes(
          //     enumMap?.actionsTakenDuringStop?.possibleValues.PropertySeized.value
          //   ) &&
          //   person?.[currentSubloop]?.actionTakenBool,
          validations: {
            'person.contraband': [
              {
                hasError: ({ person, currentSubloop }) =>
                  person?.[currentSubloop]?.anyContrabandFound
                    ? !person?.[currentSubloop]?.contraband ||
                    person?.[currentSubloop]?.contraband.length === 0
                    : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Contraband or Evidence type required',
                  message: 'Select a contraband or evidence type',
                },
              },
              {
                hasError: ({ person, currentSubloop, enumMap }) =>
                  person?.[currentSubloop]?.seizedPropertyBasis?.includes(
                    enumMap?.basisForPropertySeizure?.possibleValues.Evidence
                      .value
                  ) ||
                    person?.[currentSubloop]?.seizedPropertyBasis?.includes(
                      enumMap?.basisForPropertySeizure?.possibleValues.Contraband
                        .value
                    )
                    ? !person?.[currentSubloop]?.anyContrabandFound ||
                    !person?.[currentSubloop]?.contraband ||
                    person?.[currentSubloop]?.contraband.length === 0
                    : false,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Contraband or Evidence type required',
                  message: 'Select a contraband or evidence type',
                },
              },
            ],
          },
        },
        {
          // 4.7
          component: 'RipaResultOfStopForm',
          validations: {
            'person.resultOfStop': [
              {
                hasError: ({ person, useSameActionTakenForAll, useSameActionForAllPersonIndex, currentSubloop }) => {
                  const anyResultOfStop = person[currentSubloop]?.anyResultOfStop;
                  const resultOfStopIsEmpty = useSameActionTakenForAll
                    ? !person[useSameActionForAllPersonIndex]?.resultOfStop || person[useSameActionForAllPersonIndex]?.resultOfStop.length === 0
                    : !person[currentSubloop]?.resultOfStop || person[currentSubloop]?.resultOfStop.length === 0;
                  return anyResultOfStop && resultOfStopIsEmpty;
                },
                snack: {
                  level: AlertLevel.Error,
                  title: 'Result of stop required',
                  message: 'Toggle “No” on the top “Any Results” Toggle',
                },
              },
            ],
            resultOfStop: [
              {
                hasError: ({ person, currentSubloop, enumMap }) => {
                  const {
                    resultOfStop: { possibleValues },
                  } = enumMap;
                  const stopsThatNeedCodes = [
                    'CustodialArrestWithoutWarrant',
                    'Warning',
                    'VerbalWarning',
                    'WrittenWarning',
                    'Citation',
                    'InFieldCiteAndRelease',
                  ].map((k) => possibleValues[k]?.value);

                  const resultOfStop = person[currentSubloop]?.resultOfStop || [];
                  const filteredResultOfStop = resultOfStop.filter((code: string) =>
                    stopsThatNeedCodes.includes(code)
                  );
                  const resultOfStopCodeSection =
                    person[currentSubloop]?.resultOfStopCodeSection;
                  const anyResultOfStopBool =
                    person[currentSubloop]?.anyResultOfStop;
                  const validation = filteredResultOfStop.every((k: string) =>
                    resultOfStopCodeSection?.[k]?.some((v: string) => v.length > 0)
                  );

                  return !validation && anyResultOfStopBool;
                },
                snack: {
                  level: AlertLevel.Error,
                  title: 'Result of stop required',
                  message: 'Must add at least 1 code for each reason of stop',
                },
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Additional',
      substeps: [
        ...((!validateCustomQuestionExists(customQuestions, 'whenRaceGenderKnown') && validateFormKeyExists(form, 'whenRaceGenderKnown')) || !validateCustomQuestionExists(customQuestions, 'whenRaceGenderKnown') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.WhenRaceGenderKnown || {};
            return {
              title: template.description || 'When did you know the subject/suspect’s race/gender?',
              useSkip: false,
              skipText: '',
              selectBoxStyle: { height: '70px', width: 'calc(50% - 10px)' },
              options: template.possibleValues || [],
              resultPath: template.resultPath?.split('.') || [],
              helperText: template.helperText,
            };
          },
          hasMap: ({ enumMap }: any) => !!enumMap?.WhenRaceGenderKnown,
          validations: {
            whenRaceGenderKnown: [
              // whenRaceGenderKnown IS the resultPath !!
              {
                hasError: ({ whenRaceGenderKnown }: any) => !whenRaceGenderKnown,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'beat') && validateFormKeyExists(form, 'beat')) || !validateCustomQuestionExists(customQuestions, 'beat') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.Beat || {};
            return {
              title: template.description || 'What Beat was the stop in?',
              useSkip: false,
              skipText: '',
              DynamicSelectFormStyle: { paddingBottom: '70px' },
              options: template.possibleValues || [],
              resultPath: template.resultPath?.split('.') || [],
              helperText: template.helperText
            }
          },
          helperText:
            'Your MDT Call Screen will indicate the Beat of our call if the address has been validated.',
          hasMap: ({ enumMap }: any) => !!enumMap?.Beat,
          validations: {
            beat: [
              {
                hasError: ({ beat }: any) => !beat || beat === '',
                snack: {
                  level: AlertLevel.Error,
                  title: 'Beat required',
                  message: 'Select a beat',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'whyStopInitiated') && validateFormKeyExists(form, 'whyStopInitiated')) || !validateCustomQuestionExists(customQuestions, 'whyStopInitiated') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.WhyStopInitiated || {};
            return {
              title: template.description || 'Why did you initiate the stop?',
              selectBoxStyle: { height: '50px', width: 'calc(33% - 10px)' },
              useSkip: false,
              skipText: '',
              DynamicSelectFormStyle: { paddingBottom: '80px' },
              options: template.possibleValues || [],
              resultPath: template.resultPath?.split('.') || [],
              helperText: template.helperText
            }
          },
          helperText:
            'Routine Patrol (Just Driving Around), Directed Enforcement (Anaheim Anytime, Complaint, Crime Bulletin, Mission Sheet, MyAPD, Traffic Complaint), Call For Service (CAD Call)',
          hasMap: ({ enumMap }: any) => !!enumMap?.WhyStopInitiated,
          validations: {
            whyStopInitiated: [
              {
                hasError: ({ whyStopInitiated }: any) => !whyStopInitiated,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'stopMatchRaceGender') && validateFormKeyExists(form, 'stopMatchRaceGender')) || !validateCustomQuestionExists(customQuestions, 'stopMatchRaceGender') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.StopMatchRaceGender || {};
            return {
              title:
                enumMap?.StopMatchRaceGender?.description ||
                'Did your stop match the subject/suspect race/gender description in the call for service or directed enforcement?',
              useSkip: false,
              skipText: '',
              options: template.possibleValues || [],
              resultPath:
                template.resultPath?.split('.') || [],
              helperText: template.helperText
            }
          },
          hasMap: ({ enumMap }: any) => !!enumMap?.StopMatchRaceGender,
          validations: {
            stopMatchRaceGender: [
              {
                hasError: ({ stopMatchRaceGender }: any) => !stopMatchRaceGender,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'crimeAware') && validateFormKeyExists(form, 'crimeAware')) || !validateCustomQuestionExists(customQuestions, 'crimeAware') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.CrimeAware || {};
            return {
              title: template.description ||
                'If an observation stop, were you aware of recent crimes in the surrounding area and did the description match your stop?',
              useSkip: false,
              skipText: '',
              DynamicSelectFormStyle: { paddingBottom: '50px' },
              options: enumMap?.CrimeAware?.possibleValues || [],
              resultPath: enumMap?.CrimeAware?.resultPath?.split('.') || [],
              helperText: enumMap?.CrimeAware?.helperText
            }
          },
          helperText: 'Did the description match the person you stopped?',
          hasMap: ({ enumMap }: any) => !!enumMap?.CrimeAware,
          validations: {
            crimeAware: [
              {
                hasError: ({ crimeAware }: any) => !crimeAware,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'paroleProbation') && validateFormKeyExists(form, 'paroleProbation')) || !validateCustomQuestionExists(customQuestions, 'paroleProbation') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.ParoleProbation || {};
            return {
              title: template.description || 'Was the subject/suspect on parole or probation terms?',
              selectBoxStyle: template.selectBoxStyle,
              useSkip: false,
              skipText: '',
              options: template.possibleValues || [],
              resultPath: template.resultPath?.split('.') || [],
              helperText: template.helperText
            }
          },
          hasMap: ({ enumMap }: any) => !!enumMap?.ParoleProbation,
          validations: {
            paroleProbation: [
              {
                hasError: ({ paroleProbation }: any) => !paroleProbation,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
        ...((!validateCustomQuestionExists(customQuestions, 'knownGangMember') && validateFormKeyExists(form, 'knownGangMember')) || !validateCustomQuestionExists(customQuestions, 'knownGangMember') ? [{
          component: 'DynamicSelectFormLegacy',
          getProps: ({ enumMap }: any) => {
            const template = enumMap?.KnownGangMember || {};
            return {
              title: template.description || 'Was the subject/suspect a known gang member?',
              selectBoxStyle: template.selectBoxStyle,
              useSkip: false,
              skipText: '',
              options: template.possibleValues || [],
              resultPath: template.resultPath?.split('.') || [],
              helperText: template.helperText
            }
          },
          hasMap: ({ enumMap }: any) => !!enumMap?.KnownGangMember,
          validations: {
            knownGangMember: [
              {
                hasError: ({ knownGangMember }: any) => !knownGangMember,
                snack: {
                  level: AlertLevel.Error,
                  title: 'Answer required',
                  message: 'Select an answer',
                },
              },
            ],
          },
        }] : []),
      ],
    },
    {
      title: 'Review',
      substeps: [
        { component: 'RipaReviewForm' }, // 5.1
      ],
    },
  ];
  return StepsArray;
}

function validateAssignmentDescription(typeOfAssignmentOfficer: string, assignment: string) {
  return (typeOfAssignmentOfficer !== AssignmentEnums.offDuty && typeOfAssignmentOfficer !== AssignmentEnums.onDuty) ||
    (typeOfAssignmentOfficer === AssignmentEnums.onDuty && assignment === 'Other')
}

function validateCustomQuestionExists(customQuestions: any, resultPath: string) {
  const perStop = customQuestions?.individual?.questions ?? [];
  const perPerson = customQuestions?.all?.questions ?? [];
  const mapOfQuestion = map([...perStop, ...perPerson], (q) => q.props.resultPath.toLowerCase());
  return mapOfQuestion.includes(resultPath.toLowerCase())
}

function validateFormKeyExists(form:Form | undefined, formValue: string) {
  return has(form, formValue);
}

export default StepsFunction;
