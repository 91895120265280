import { connect, ConnectedProps } from 'react-redux';
import * as Form from '@ducks/form';
import * as NewReport from '@ducks/newReport';

const mapStateToProps = (state: any) => ({
  form: Form.selectors.form(state),
  labels: Form.selectors.labels(state),
  numberOfPeople: Form.selectors.numberOfPeople(state),
  currentPerson: NewReport.selectors.currentSubloop(state),
});

const mapDispatchToProps = {
  onChange: (value: string, resultPath: (string | number)[]) => Form.setFormField({ path: resultPath })(value),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector> & {
  title: string;
  resultPath: string;
  format: string;
  style: object;
  isAll: boolean;
  defaultValue: any;
  placeholder: string;
  maxLength?: number;
  multiline?: boolean;
};
