import env from '@engine/env'
import dayjs from 'dayjs'

export const DashboardNamespace = 'Dashboard'
export const UserNamespace = 'User'
export const UsersNamespace = 'Users'
export const FormNamespace = 'Form'
export const GlobalConfigurationNamespace = 'GlobalConfiguration'
export const NewReportNamespace = 'NewReport'
export const OrganizationsNamespace = 'Organizations'
export const TemplateNamespace = 'Template'
export const ConfigNamespace = 'Config'
export const ReviewNamespace = 'Review'
export const CsvReportsNamespace = 'CsvReports'
export const WatchLocationNamespace = 'WatchLocation'
export const CustomQuestionsNamespace = 'CustomQuestions'
/* eslint-disable quote-props */

export const TemplateName2024 = 'CA_2024_ripa'
export const DATE_POST_2024 = () => env().REACT_APP_FEATURE_FLAGS.previewPost2024 ?? '2024-01-01T08:00:00Z';
export const isAfter2024 = (stopDate?:string, templateName?:string) => templateName ? checkAfter2024(stopDate) && templateName === TemplateName2024 : checkAfter2024(stopDate);
export const checkAfter2024 = (stopDate?:string) => dayjs(stopDate).isAfter(window?.postDate2024 ?? DATE_POST_2024())

export const Role = {
  All: '-1', // 'All' Exists for Role Filter on Users
  SuperAdmin: '0',
  Admin: '1',
  Reviewer: '2',
  Officer: '3',
  Analyst: '4',
  Disabled: '9'
}

export const RoleString: ImplicitlyIndex = {
  '0': 'super_admin',
  '1': 'admin',
  '2': 'reviewer',
  '3': 'officer',
  '4': 'analyst',
  '9': 'disabled'
}

export const RoleNumber: ImplicitlyIndex = {
  'super_admin': '0',
  'admin': '1',
  'reviewer': '2',
  'officer': '3',
  'analyst': '4',
  'disabled': '9'
}

export const OnlineStatus = {
  Draft: 'draft',
  UnderReview: 'under_review',
  Rejected: 'rejected',
  Approved: 'approved', // Pending submission
  DeniedByDOJ: 'denied_by_doj',
  // AcceptedByDOJ: 'accepted_by_doj', // Does not currently exist
  SubmissionError: 'submission_error_from_doj',
  SubmittedToDOJ: 'submitted_to_doj',
  SubmittingToDOJ: 'submitting_to_doj',
}

export const OfflineStatus = {
  Syncing: 'syncing', // Client only
  Synced: 'synced', // Client only
  SyncFailed: 'syncfailed', // Client only
  Offline: 'offline', // Client only
}

export const Status = {
  ...OnlineStatus,
  ...OfflineStatus
}

export const StatusString: ImplicitlyIndex = {
  draft: 'Draft',
  under_review: 'Under Review',
  rejected: 'Changes Requested',
  approved: 'Approved', // Pending submission
  denied_by_doj: 'Denied by DOJ',
  // accepted_by_doj: '', // Does not currently exist
  submission_error_from_doj: 'DOJ Submission Error',
  submitting_to_doj: 'Submitting to DOJ',
  submitted_to_doj: 'Submitted to DOJ',
  syncing: 'Syncing', // Client/offline only
  synced: 'Synced', // Client/offline only
  syncfailed: 'Sync Failed', // Client/offline only
  offline: 'Offline', // Client/offline only
}

export const Filters = {
  CreatedToday: 0,
  DraftReports: 1,
  UnderReview: 2,
  RejectedForms: 3,
  ApprovedForms: 4
}

export const DashboardFilterValues: ImplicitlyIndex = {
  0: 'reports_today',
  1: Status.Draft,
  2: Status.UnderReview,
  3: Status.Rejected,
  4: Status.Approved
}

export const ReviewFilterValues: ImplicitlyIndex = {
  0: Status.UnderReview,
  1: Status.Rejected,
  2: Status.Approved,
  3: Status.DeniedByDOJ,
  4: Status.SubmittedToDOJ
}

export const AssignmentEnums = {
  onDuty: 'On Duty',
  offDuty: 'Off Duty - Working Private Event',
  contracted: 'Contracted by other agency',
  other: 'Other',
}

export const ConfigurationSubmissionFrequencies = {
  Daily: 'daily',
  Weekly: 'weekly',
  'Every Month': 'every_month',
  'Every 3 Months': 'every_3_months',
  'Every 6 Months': 'every_6_months'
}

export const ConfigurationSubmissionModes = {
  date: 'specific_date',
  recurring: 'frequency',
  disable: 'disabled'
}

export const LocationType = {
  GPS: 'GPS',
  Block: 'BLOCK',
  School: 'SCHOOL',
  Intersection: 'INTERSECTION',
  HighwayExit: 'HIGHWAY EXIT',
  Other: 'OTHER'
}

interface ImplicitlyIndex {
  [key: string]: string;
}
