/* eslint-disable quote-props */

export default {
  // Brand
  'veritone-blue': { light: '#003d7e', dark: '#007aff' },
  'veritone-blue-dark': { light: '#002b58', dark: '' },
  'veritone-blue-faded': { light: '#e5ebf2', dark: '#323537' },
  'veritone-blue-faded-2': { light: '#cbd8e5', dark: '' },
  'veritone-blue-faded-3': { light: '#668ab1', dark: '' },
  'veritone-blue-faded-4': { light: '#809ebf', dark: '' },
  'veritone-blue-faded-5': { light: '#4470a0', dark: '' },
  'veritone-blue-faded-6': { light: '#e2e7ef', dark: '' },
  'veritone-blue-select-box-background': { light: '#1858a7', dark: 'same' },
  'popper-background': { light: '#FFFFFF', dark: '#323537' },

  // Grey
  'grey-a': { light: '#fafafa', dark: '#161719' },
  'grey-b': { light: '#fbfbfb', dark: '#161719' },
  'grey-c': { light: '#fcfcfc', dark: '' },
  'grey-4': { light: '#f4f4f4', dark: '' },
  'grey-9': { light: '#919191', dark: '' },
  'grey-f9': { light: '#f9f9f9', dark: '' },
  'grey-ae': { light: '#aeaeae', dark: '' },
  'grey-text': { light: '##2A323C', dark: '#dedede' },
  'dark-grey': { light: '#3e3e3e', dark: '' },
  'blueish-grey': { light: '#D5DFE9', dark: '#3a4661' },

  // Fonts
  'font-color': { light: '#000000', dark: '#dedede' },
  'review-grey': { light: '#5C6269', dark: '#adadad' },

  // Other
  'white': { light: '#ffffff', dark: '#252729' },
  'black': { light: '#000000', dark: '#dedede' },
  'blue-disabled': { light: '#9ca8b4', dark: 'darkgray' },
  'blue-border': { light: '#b2c4d8', dark: '' },
  'blue-icon': { light: '#555f7c', dark: '' },
  'input-border': { light: '#717171', dark: '#8c8e8e' },
  'filter-border': { light: '#f4f6f8', dark: '' },
  'text-disabled': { light: '#7b7c7e', dark: 'same' },
  'text-terms': { light: '#5c6269', dark: '' },
  'step-graph-disabled': { light: '#e1e1e1', dark: '' },
  'close-button': { light: '#808080', dark: '' },
  'login-bg': { light: '#001337', dark: '' },
  'error': { light: '#f0585a', dark: 'same' },
  'table-border': { light: '#d5dee8', dark: 'same' },
  'number-white': { light: '#fff', dark: 'same' },
  'number-green': { light: '#219653', dark: 'same' },
  'number-orange': { light: '#f19000', dark: 'same' },
  'number-orange-faded': { light: '#cb7c0d', dark: 'same' },
  'number-red': { light: '#8d0707', dark: '#c70a0a' },
  'star': { light: '#f19000', dark: 'same' },
  'scrollbar-thumb': { light: '#888', dark: 'same' },
  'scrollbar-thumb-hover': { light: '#555', dark: 'same' },
  'scrollbar-thumb-track': { light: '#f1f1f1', dark: 'same' },
  'card-border': { light: '#d5dfe9', dark: '' },
  'bg-disabled': { light: '#b1bcc6', dark: '#1d6ec6' },
  'bg-disabled-inv': { light: '#1d6ec6', dark: '#b1bcc6' },
  'expand-disabled': { light: '#707070', dark: '' },
  'expand-enabled': { light: '#7d9bbc', dark: '' },
  'expand-all-enabled': { light: '#96b0cc', dark: '' },
  'officer-role': { light: '#0070d2', dark: 'same' },
  'reviewer-role': { light: '#cf4000', dark: 'same' },
  'admin-role': { light: '#5600eb', dark: 'same' },
  'org-role': { light: 'darkgoldenrod', dark: 'same' },
  'analyst-role': { light: '#219653', dark: 'same' },
  'nav-divider': { light: 'rgba(0, 61, 126, 0.3)', dark: '' },
  'row-error': { light: '#f0e2e2', dark: 'rgba(255, 0, 0, 0.30)' },
  'row-warning': { light: '#fbf1e2', dark: 'rgba(255, 153, 0, 0.16)' },
  'row-syncing': { light: '#f2f3f4', dark: 'same' },
  'alert-bg': { light: 'rgba(207, 64, 0, 0.1)', dark: 'same' },
  'radio-hover': { light: 'rgba(0, 61, 126, 0.1)', dark: '' },

  // Snackbar
  'red-60': { light: '#a73234', dark: 'same' },
  'red-50': { light: '#f0585a', dark: 'same' },
  'teal-50': { light: 'rgba(0, 178, 178, 1)', dark: 'same' },
  'teal-60': { light: 'rgba(0, 100, 99, 1)', dark: 'same' },
  'green-60': { light: '#306932', dark: '#3acf3f' },
  'green-20': { light: '#a0ec75', dark: 'same' },
  'green-30': { light: '#6ddb71', dark: 'same' },
  'yellow-40': { light: 'rgba(251, 195, 45, 1)', dark: 'same' },
  'yellow-80': { light: 'rgba(74, 54, 0, 1)', dark: 'darkgoldenrod' },
  'grey-60': { light: 'rgba(86, 86, 86, 1)', dark: 'same' },
}
