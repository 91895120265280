import { createMuiTheme } from '@material-ui/core/styles'
import theme from './global.module.scss'

export default () =>
  createMuiTheme(
    {
      overrides: {
        MuiFormControl: {
          root: {
            backgroundColor: theme.grey4
          }
        },
        MuiOutlinedInput: {
          notchedOutline: {
            border: 'solid 1px',
            borderColor: theme.inputBorder
          },
          adornedEnd: {
            paddingRight: 0
          }
        },
        MuiSelect: {
          icon: {
            color: theme.primaryColor
          }
        },
        MuiList: {
          root: {
            maxHeight: '180px'
          }
        },
        MuiInputBase: {
          root: {
            color: theme.primaryColor
          },
          input: {
            color: theme.primaryColor,
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px',
            fontWeight: 600
          }
        },
        MuiSvgIcon: {
          root: {
            color: theme.primaryColor
          }
        },
        MuiPickersDay: {
          daySelected: {
            backgroundColor: theme.primaryColor
          }
        },
        MuiPaper: {
          root: {
            backgroundColor: theme.popperBackground
          }
        },
        MuiMenuItem: {
          root: {
            color: theme.fontColor
          }
        },
        MuiAutocomplete: {
          option: {
            color: theme.fontColor
          }
        }
      }
    }
  )
