import {
  CustomQuestions, NewReport, Dashboard, Review, ReviewUnavailable,
  Users, Organizations, Visualization, Login, ResetPassword, GlobalConfiguration
} from '@pages/index';

export default (componentName: string) => {
  switch (componentName) {
    case 'NewReport':
      return NewReport
    case 'Dashboard':
      return Dashboard
    case 'Review':
      return Review
    case 'ReviewUnavailable':
      return ReviewUnavailable
    case 'Users':
      return Users
    case 'Organizations':
      return Organizations
    case 'Visualization':
      return Visualization
    case 'Login':
      return Login
    case 'ResetPassword':
      return ResetPassword
    case 'GlobalConfiguration':
      return GlobalConfiguration
    case 'CustomQuestions':
      return CustomQuestions
    default: {
      console.error('Could not find ', componentName)
      return null
    }
  }
}
