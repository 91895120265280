import React, { useEffect } from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import './RipaReasonGivenStoppedPersonForm.scss';
import { isEmpty } from 'lodash';
import { range } from 'ramda'
import { useSelector } from 'react-redux';
import { Props, connector } from './props';

const RipaReasonGivenStoppedPersonForm = ({
  student,
  reasonGivenStoppedPerson,
  toggleReasonGivenStoppedPerson,
  setReasonGivenStoppedPerson,
  currentSubloop,
  labels,
  numberOfPeople,
  reasonGivenStoppedPersonValues,
  useSamePrimaryReasonForAll,
  breakpoint,
}: Props) => {
  const isMobile = breakpoint === 'xs' || breakpoint === 'sm';
  const personIndex = useSamePrimaryReasonForAll ? 0 : currentSubloop;

  const selectReasonGivenValue = (value: string) => {
    const reasonNotCommunicatedValue = reasonGivenStoppedPersonValues.ReasonNotCommunicatedStoppedPerson.value;
    if (value === reasonNotCommunicatedValue) {
      // Must toggle the reasonNotCommunicated so check if selected or not prior
      setReasonGivenStoppedPerson(reasonGivenStoppedPerson?.[personIndex]?.includes(value) ? [] : [value], personIndex)
    } else {
      const isReasonNotCommunicatedSelected = reasonGivenStoppedPerson?.[personIndex]?.includes(reasonNotCommunicatedValue);
      if (isReasonNotCommunicatedSelected) {
        setReasonGivenStoppedPerson([value], personIndex);
      } else {
        toggleReasonGivenStoppedPerson(value, personIndex);
      }
    }
  };

  useEffect(() => {
    range(0, numberOfPeople).forEach(pi => {
      if (student[pi] === false && !isEmpty(reasonGivenStoppedPersonValues)) {
        if (reasonGivenStoppedPerson?.[pi]?.includes(reasonGivenStoppedPersonValues.PossibleDisciplineEducationCode48900.value)) {
          toggleReasonGivenStoppedPerson(reasonGivenStoppedPersonValues.PossibleDisciplineEducationCode48900.value, pi);
        }
        if (reasonGivenStoppedPerson?.[pi]?.includes(reasonGivenStoppedPersonValues.DetermineStudentViolatedSchoolPolicy.value)) {
          toggleReasonGivenStoppedPerson(reasonGivenStoppedPersonValues.DetermineStudentViolatedSchoolPolicy.value, pi);
        }
      }
    })
  }, [])
  return (
    <div className="ripa-reason-given-stopped-person-form" data-testid="ripa-reason-given-stopped-person-form">
      <div className={cn('ripa-reason-given-stopped-person-form__person', { 'same-for-all': useSamePrimaryReasonForAll })}>
        <div className="material-icons">person</div>
        <div className="ripa-reason-given-stopped-person-form__person-label">{labels?.[personIndex] || personIndex + 1}</div>
        <div className="ripa-reason-given-stopped-person-form__person-same-for-all">Same For All</div>
        <div className="ripa-reason-given-stopped-person-form__person-progression">
          <b>{`${personIndex + 1}`}</b>
          {`/${numberOfPeople}`}
        </div>
        <div className="ripa-reason-given-stopped-person-form__person-progression-all">ALL</div>
      </div>
      <div className="ripa-reason-given-stopped-person-form__title">What was the reason given to the stopped person?</div>
      <div className={cn({
        'ripa-reason-given-stopped-person-form__select-box-container': !isMobile,
        'ripa-reason-given-stopped-person-form__select-box-mobile-container': isMobile
      })}>
        <div className="ripa-reason-given-stopped-person-form__select-box-content">
          {
            !isEmpty(reasonGivenStoppedPersonValues) && [
              reasonGivenStoppedPersonValues.TrafficViolationMoving,
              reasonGivenStoppedPersonValues.TrafficViolationEquipment,
              reasonGivenStoppedPersonValues.TrafficViolationNonMoving,
              reasonGivenStoppedPersonValues.OfficerWitnessedCrime,
              reasonGivenStoppedPersonValues.MatchedSuspectDescription,
              reasonGivenStoppedPersonValues.MatchedSuspectDescriptionVehicle,
              reasonGivenStoppedPersonValues.WitnessedVictimStopped,
              reasonGivenStoppedPersonValues.CarryingSuspiciousObject,
              reasonGivenStoppedPersonValues.IndicativeCasingVictimLocation,
              reasonGivenStoppedPersonValues.SuspectActionLookout,
              reasonGivenStoppedPersonValues.IndicativeDrugTransaction,
              reasonGivenStoppedPersonValues.IndicativeEngagingViolentCrime,
              reasonGivenStoppedPersonValues.OtherSuspicion,
              reasonGivenStoppedPersonValues.ProbableCauseToArrestOrSearch,
              reasonGivenStoppedPersonValues.ProbableCauseCustodyUnder5150,
              reasonGivenStoppedPersonValues.KnownParole,
              reasonGivenStoppedPersonValues.KnownOutstandingArrest,
              reasonGivenStoppedPersonValues.InvestigationTruant,
              reasonGivenStoppedPersonValues.ConsensualEncounterSearch,
              ...(student?.[personIndex] ? [
                reasonGivenStoppedPersonValues.PossibleDisciplineEducationCode48900,
                reasonGivenStoppedPersonValues.DetermineStudentViolatedSchoolPolicy,
              ] : []),
              reasonGivenStoppedPersonValues.ReasonNotCommunicatedStoppedPerson,
            ].map(({ value }, si) => (
              <>
                {/* Student */}
                {(reasonGivenStoppedPersonValues.PossibleDisciplineEducationCode48900.value === value || reasonGivenStoppedPersonValues.DetermineStudentViolatedSchoolPolicy.value === value) ? (
                  <div
                    className={cn('ripa-reason-given-stopped-person-form__select-box k12', { active: reasonGivenStoppedPerson?.[personIndex]?.includes(value) })}
                    key={`RipaReasonGivenStoppedPersonK12Box-${si}`}
                    onClick={() => selectReasonGivenValue(value)}
                    onKeyUp={onEnter(() => selectReasonGivenValue(value))}
                    data-testid={`ripa-reason-given-stopped-person-form-box-k12-${si}`}
                    role="button"
                    tabIndex={0}
                  >
                    {value}
                    <div className="ripa-reason-given-stopped-person-form__select-box-k12">K-12</div>
                  </div>
                ) : (
                  <div
                    className={cn('ripa-reason-given-stopped-person-form__select-box', { active: reasonGivenStoppedPerson?.[personIndex]?.includes(value) })}
                    key={`RipaReasonGivenStoppedPersonKey-${si}}`}
                    onClick={() => selectReasonGivenValue(value)}
                    onKeyUp={onEnter(() => selectReasonGivenValue(value))}
                    data-testid={`ripa-reason-given-stopped-person-form-box-${si}`}
                    role="button"
                    tabIndex={0}
                  >
                    {/* Value is too long to render in UI */}
                    {reasonGivenStoppedPersonValues.ReasonNotCommunicatedStoppedPerson.value === value ? 'Reason not communicated to the stopped person' : value}
                  </div>
                )}
              </>
          ))}
        </div>
      </div>
    </div>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box center">
    <div className="material-icons">help</div>
    <div className="ripa-form-container__helper-box-text">Select ALL that apply and scroll to view all available options.</div>
  </div>
);

const Warning = () => {
  const reasonNotCommunicated = useSelector((state: any) => state.Template.enumMap?.reasonGivenStoppedPerson?.possibleValues?.ReasonNotCommunicatedStoppedPerson?.value);
  const currentSubloop = useSelector((state: any) => state.NewReport.currentSubloop);
  const reasonGivenStoppedPerson = useSelector((state: any) => state.Form.person[currentSubloop].reasonGivenStoppedPerson);
  const isReasonNotCommunicated = reasonGivenStoppedPerson.includes(reasonNotCommunicated);
  const { pre2024 } = useSelector((state: any) => state.Form.flags);
  if (!pre2024 || !isReasonNotCommunicated) {
    return <></>;
  }
  return (
    <div className="ripa-form-container__warning-box ripa-reason-given-stopped-person-form__warning">
      <div className="material-icons">
        priority_high
      </div>
      <div className="ripa-form-container__warning-box-text">
        <b>Note: </b>
        Reason was not communicated to the stopped person because the officer reasonably believed that withholding the reason for the stop was necessary to protect life or property from imminent threat.
      </div>
    </div>
  )
}

RipaReasonGivenStoppedPersonForm.helper = Helper;
RipaReasonGivenStoppedPersonForm.warning = Warning;

export default connector(RipaReasonGivenStoppedPersonForm);
