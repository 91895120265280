function getAddressFromComponents(addressComponents) {
  const componentTypes = {
    streetNumber: ['street_number'],
    zip: ['postal_code'],
    street: ['intersection', 'street_address', 'route'],
    state: [
      'administrative_area_level_1'
    ],
    county: [
      'administrative_area_level_2'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country'],
    premise: ['premise'],
  }
  const addressFormat = {
    streetNumber: '',
    zip: '',
    street: '',
    state: '',
    county: '',
    city: '',
    country: '',
    premise: '',
  }

  addressComponents?.forEach(component => {
    for (const typeOfComponent in componentTypes) {
      if (componentTypes[typeOfComponent].indexOf(component.types[0]) !== -1) {
        addressFormat[typeOfComponent] = component.long_name
      }
    }
  })

  return addressFormat
}

export default getAddressFromComponents
