export const oneloginTemplate = JSON.stringify({
  app_id: '<app_id>',
  auth_url: 'ldap',
  app_issuer: 'https://<subdomain>.contact.us-gov-2.veritone.com/api/v1/users/saml/metadata',
  app_acs_url: 'https://<subdomain>.contact.us-gov-2.veritone.com/api/v1/users/saml/auth',
  app_cert_algo: 'http://www.w3.org/2000/09/xmldsig#sha1',
  idp_entity_id: 'https://app.onelogin.com/saml/metadata/<app_id>',
  app_acs_logout_url: 'https://<subdomain>.contact.us-gov-2.veritone.com/api/v1/users/saml/idp_sign_out',
  idp_slo_target_url: 'https://<company>.onelogin.com/trust/saml2/http-redirect/slo/<number>',
  idp_sso_target_url: 'https://app.onelogin.com/trust/saml2/http-post/sso/<app_id>',
  app_cert_fingerprint: '',
}, null, '\t');

export const azureadTemplate = JSON.stringify({
  app_id: '<app_id>',
  auth_url: 'ldap',
  app_issuer: 'https://<subdomain>.contact.us-gov-2.veritone.com/id/<app_id>',
  app_acs_url: 'https://<subdomain>.contact.us-gov-2.veritone.com/api/v1/users/saml/auth',
  app_cert_algo: 'http://www.w3.org/2000/09/xmldsig#sha1',
  idp_entity_id: 'https://sts.windows.net/<app_id>/',
  app_acs_logout_url: 'https://<subdomain>.contact.us-gov-2.veritone.com/api/v1/users/saml/idp_sign_out',
  idp_slo_target_url: 'https://login.microsoftonline.us/<app_id>/saml2',
  idp_sso_target_url: 'https://login.microsoftonline.us/<app_id>/saml2',
  app_cert_fingerprint: '',
}, null, '\t');
