import React, { Children, Fragment, useState, useEffect, useRef, Dispatch, SetStateAction, MutableRefObject } from 'react';
import { Dialog, Button } from '@components/common';
import { onEnter } from '@utility/keypressHelpers';
import { uniqueId } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import * as Config from '@ducks/config';
import theme from '@theme/global.module.scss';
import './ConfirmDialog.scss';

function useStateRef<S>(initialValue: S): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>] {
  const [value, setValue] = useState<S>(initialValue);
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return [value, setValue, ref];
}

export const ConfirmDialog = (props: Props) => {
  const contentRef = useRef<HTMLInputElement>(null);
  const {
    open,
    heading,
    noActions,
    positiveText,
    negativeText,
    positiveAction,
    positiveActionTestId = 'confirm-dialog-yes-button',
    negativeAction,
    closeDialog,
    children,
    className,
    breakpoint,
    forceUpdateSeed,
    'data-testid': dataTestId,
    disabledPositive,
  } = props;

  const [childUids, setChildUids] = useState<string[]>([]);
  const [dialogHeight, setDialogHeight] = useStateRef<number>(0);

  const childActions = { positive: (next: () => void) => next(), negative: (next: () => void) => next() };
  const setPositiveAction = (f: () => void) => (childActions.positive = f);
  const setNegativeAction = (f: () => void) => (childActions.negative = f);
  const onPositive = () => childActions.positive(positiveAction ?? (() => null));
  const onNegative = () => childActions.negative(negativeAction ?? (() => null));

  function calculateAndSetHeight() {
    const height = contentRef.current?.getBoundingClientRect().height ?? -1;
    if (height > 0) {
      const totalPaddingHeight = 250; // The height of the buttons at the bottom of the dialog + the buttons at the top + top/bottom padding of the content
      const headerHeight = Number(theme.headerHeight.substring(-1, 2));
      const valueValue = breakpoint === 'xs' ? window.innerHeight - headerHeight : window.innerHeight * 0.9 - totalPaddingHeight > height ? 0 : window.innerHeight * 0.9;
      setDialogHeight(valueValue);
    }
  }

  useEffect(() => {
    calculateAndSetHeight();
    window.addEventListener('resize', calculateAndSetHeight);
    setChildUids(Children.toArray(children).map(() => uniqueId()));

    return () => {
      window.removeEventListener('resize', calculateAndSetHeight);
    };
  }, []);

  useEffect(() => {
    if (!open) return;
    calculateAndSetHeight();
  }, [open, breakpoint, forceUpdateSeed]);

  return (
    <Dialog open={open} onClickOff={closeDialog}>
      <div className={cn('confirm-dialog', className, { 'no-actions': noActions })} data-testid={dataTestId ?? 'confirm-dialog'} style={dialogHeight ? { height: dialogHeight } : {}}>
        <div className="confirm-dialog__header">
          <h3>{heading}</h3>
          <i role="button" tabIndex={0} id="confirm-dialog-close" className="material-icons" data-testid="confirm-dialog-close" onClick={closeDialog} onKeyUp={onEnter(closeDialog)}>
            close
          </i>
        </div>
        <div className="confirm-dialog__content">
          <div ref={contentRef}>
            {Children.toArray(children).map((child: any, i) => {
              let childProps = {};
              if (typeof child.type !== 'string') childProps = { setPositiveAction, setNegativeAction, calculateAndSetHeight, closeDialog };

              return <Fragment key={`ConfirmDialogContent-${childUids[i]}`}>{React.cloneElement(child, childProps)}</Fragment>;
            })}
          </div>
        </div>
        {!noActions && <div className="confirm-dialog__button-row">
          {!!negativeAction && (
            <Button priority="secondary" aria-label="Deny action" data-testid="confirm-dialog-no-button" className="confirm-dialog__no" onClick={onNegative}>
              {negativeText || 'Cancel'}
            </Button>
          )}
          <Button disabled={disabledPositive} aria-label="Confirm action" data-testid={positiveActionTestId} className="confirm-dialog__yes" onClick={onPositive}>
            {positiveText}
          </Button>
        </div>}
      </div>
    </Dialog>
  );
};

interface Props extends PropsFromRedux {
  open: boolean;
  noActions?: boolean;
  positiveText?: string;
  negativeText?: string;
  positiveAction?: () => void;
  negativeAction?: () => void;
  closeDialog?: () => void;
  heading: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  'data-testid'?: string;
  positiveActionTestId?: string;
  disabledPositive?: boolean;
}

const mapStateToProps = (state: any) => ({
  breakpoint: Config.selectors.breakpoint(state),
  forceUpdateSeed: Config.selectors.forceUpdateSeed(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfirmDialog);
