import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { connect, ConnectedProps } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import { Button, ConfirmDialog, LabelField } from '@components/common';
import { Header } from '@components/custom';
import { DateTimePicker } from '@material-ui/pickers';
import { User, GlobalConfiguration as GlobalConfigDuck } from '@ducks';
import { ConfigurationSubmissionModes, ConfigurationSubmissionFrequencies } from '@ducks/constants';
import { onEnter } from '@utility/keypressHelpers';
import './GlobalConfiguration.scss';

export const GlobalConfiguration = ({
  organization,
  orgCanSubmitToDOJ,
  getOrganization,
  setOrganization,
  uploadOrganization,
  confirmSubmitDialogOpen,
  setConfirmSubmitDialogOpen,
  confirmSftpSettingsDialogOpen,
  setConfirmSftpSettingsDialogOpen,
  confirmScheduleDialogOpen,
  setConfirmScheduleDialogOpen,
  submitApprovedToDoj,
}: PropsFromRedux) => {
  const {
    clipboardCodeFeature,
    sftp_credential: { hostname: sftpHostname, username: sftpUsername, key: sftpKey },
    job_submission_schedule: { start_date: submissionDate, frequency: submissionFrequency, mode: submissionMode },
    sftp_credentials_set
  } = organization;

  useEffect(() => {
    getOrganization();
  }, []);

  // Prevent the browser from activating the password manager
  // because it cannot guess which of values is the new password to use.
  const disablePasswordManager = (
    <div style={{ height: '0px', overflow: 'hidden', background: 'transparent' }}>
      <LabelField
        type="password"
      />
      <LabelField
        type="password"
      />
    </div>
  );

  return (
    <Header title="Contact">
      <div className="global-config" data-testid="global-config">
        <div className="global-config__dialog">
          <div className="global-config__title-area">
            <div className="global-config__title-text">GLOBAL CONFIGURATION</div>
          </div>
          {orgCanSubmitToDOJ &&
            <div className="global-config__doj-submission-settings">
              <div className="global-config__doj-submission-settings-title">DOJ Submission Settings</div>
              <Button
                className="global-config__doj-submission-settings-submit-button"
                onClick={() => setConfirmSubmitDialogOpen(true)}
              >
                SUBMIT NOW
              </Button>
              <FormControl className="global-config__doj-submission-settings-form">
                <div className="global-config__doj-submission-settings-left-half">
                  <div
                    className={cn('global-config__doj-submission-settings-date-picker',
                      { 'global-config__date-picker-full-width': ConfigurationSubmissionModes.disable === submissionMode })}
                    data-testid="global-config-doj-submission-settings-date-picker"
                  >
                    <FormLabel className="global-config__doj-submission-settings-date-picker-label">
                      DATA SUBMISSION START DATE
                    </FormLabel>
                    <DateTimePicker
                      disabled={submissionMode === ConfigurationSubmissionModes.disable}
                      className="global-config__doj-submission-settings-date-picker-picker"
                      disableToolbar
                      variant="inline"
                      ampm={false}
                      disablePast={true}
                      format={submissionMode === ConfigurationSubmissionModes.disable ? '--' : 'MM/dd/yyyy \'at\' hh:mm a'}
                      margin="normal"
                      id="date-picker-inline"
                      value={submissionDate}
                      onChange={(value: any) => setOrganization({ job_submission_schedule: { start_date: value.toISOString() } })}
                      InputProps={{
                        inputProps: {
                          'data-testid': 'global-config-doj-submission-settings-date-picker-picker'
                        },
                        disableUnderline: true,
                      }}
                      style={{ width: '90%', border: '1px solid gray', borderRadius: '4px', padding: '15px' }}
                    />
                  </div>
                  {submissionMode === ConfigurationSubmissionModes.recurring &&
                    <div
                      className="global-config__doj-submission-settings-frequency-selection"
                      data-testid="global-config-doj-submission-settings-frequency-selection">
                      <FormLabel className="global-config__doj-submission-settings-frequency-selection-label">
                        RECURRING FREQUENCY
                      </FormLabel>
                      <Select
                        className="global-config__doj-submission-settings-frequency-selection-selector"
                        value={submissionFrequency}
                        onChange={({ target: { value } }) => setOrganization({ job_submission_schedule: { frequency: value } })}
                        disableUnderline
                        style={{
                          width: '90%',
                          border: '1px solid gray',
                          borderRadius: '4px',
                          padding: '14px',
                          backgroundColor: '#f4f4f4',
                        }}
                      >
                        {Object.entries(ConfigurationSubmissionFrequencies).map(([selection, id]) => <MenuItem
                          key={id}
                          value={id}>
                          {selection}
                        </MenuItem>)}
                      </Select>
                    </div>
                  }
                </div>
                <div className="global-config__doj-submission-settings-radio-group">
                  <FormLabel className="global-config__doj-submission-settings-radio-group-title">
                    DOJ SUBMISSION MODE
                  </FormLabel>
                  <RadioGroup
                    row
                    className="global-config__doj-submission-settings-radio-group-group"
                    value={submissionMode ?? ConfigurationSubmissionModes.disable}
                    onChange={({ target: { value } }) => {
                      setOrganization({
                        job_submission_schedule: {
                          mode: value,
                          frequency: submissionFrequency || ConfigurationSubmissionFrequencies['Every Month']
                        }
                      });
                    }}
                  >
                    <FormControlLabel
                      value={ConfigurationSubmissionModes.recurring}
                      control={<Radio />}
                      label="Scheduled Recurring Frequency"
                      data-testid="global-config-doj-submission-settings-radio-button-scheduled-recurring"
                    />
                    <FormControlLabel
                      value={ConfigurationSubmissionModes.date}
                      control={<Radio />}
                      label="Scheduled Date"
                      data-testid="global-config-doj-submission-settings-radio-button-scheduled-date"
                    />
                    <FormControlLabel
                      value={ConfigurationSubmissionModes.disable}
                      control={<Radio />}
                      label="Disable"
                      data-testid="global-config-doj-submission-settings-radio-button-disable"
                    />
                  </RadioGroup>
                </div>
              </FormControl>
              <div className="global-config__doj-submission-settings-save-button-area">
                <Button
                  className="global-config__doj-submission-settings-save-button"
                  data-testid="global-config-doj-submission-settings-save-button"
                  onClick={() => {
                    setConfirmScheduleDialogOpen(true);
                  }}
                  onKeyUp={onEnter(() => {
                    setConfirmScheduleDialogOpen(true);
                  })}
                >
                  SAVE
                </Button>
              </div>
            </div>
          }
          {orgCanSubmitToDOJ &&
            <div className="global-config__doj-submission-info-area">
              <div className="material-icons">
                help
              </div>
              <div className="global-config__doj-submission-info">
                These settings control the frequency of data submission to the DOJ. Only reviewed and approved reports
                will be submitted.
              </div>
            </div>
          }
          <div className="global-config__sftp-settings">
            <LabelField
              label="Hostname"
              placeholder={sftp_credentials_set ? '(Hostname previously saved)' : 'Enter Hostname...'}
              className="global-config__sftp-settings-input"
              onChange={({ target: { value } }) => setOrganization({ sftp_credential: { hostname: value } })}
              value={sftpHostname}
              inputProps={{
                'data-testid': 'global-config-sftp-settings-input-hostname'
              }}
            />
            <LabelField
              label="SFTP Username"
              placeholder={sftp_credentials_set ? '(SFTP Username previously saved)' : 'Enter SFTP Username...'}
              className="global-config__sftp-settings-input"
              autoComplete="new-password"
              onChange={({ target: { value } }) => setOrganization({ sftp_credential: { username: value } })}
              value={sftpUsername}
              inputProps={{
                'data-testid': 'global-config-sftp-settings-input-username'
              }}
            />
            <LabelField
              label="SFTP Key"
              multiline
              placeholder={sftp_credentials_set ? '(SFTP key previously saved)' : 'PEM or PPK format SFTP Key...'}
              minRows={4}
              className="global-config__sftp-settings-input"
              autoComplete="new-password"
              onChange={({ target: { value } }) => setOrganization({ sftp_credential: { key: value } })}
              value={sftpKey}
              inputProps={{
                'data-testid': 'global-config-sftp-settings-input-key'
              }}
            />
            {disablePasswordManager}
            <LabelField
              label="SFTP Key Password"
              placeholder={sftp_credentials_set ? '(SFTP key password previously saved)' : 'Enter SFTP Key password (optional)...'}
              className="global-config__sftp-settings-input"
              type="password"
              autoComplete="new-password"
              onChange={({ target: { value } }) => setOrganization({ sftp_credential: { password: value } })}
              inputProps={{
                'data-testid': 'global-config-sftp-settings-input-password'
              }}
            />
          </div>

          <div className="global-config__org-flags">
            <div className="global-config__org-flags-clipboard-group">Add code used into users clipboard</div>
            <RadioGroup
              row
              className="global-config__org-flags-clipboard-group"
              value={clipboardCodeFeature}
              onChange={({ target: { value } }) => {
                setOrganization({
                  clipboardCodeFeature: value === 'true'
                });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="On"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Off"
              />
            </RadioGroup>
          </div>
          <div className="global-config__sftp-settings-save-button-area">
            <Button
              className="global-config__sftp-settings-save-button"
              data-testid="global-config-sftp-settings-save-button"
              onClick={() => {
                setConfirmSftpSettingsDialogOpen(true);
              }}
              onKeyUp={onEnter(() => {
                setConfirmSftpSettingsDialogOpen(true);
              })}
            >
              SAVE
            </Button>
          </div>
        </div>
      </div>
      <ConfirmDialog
        className="global-config__confirm-submission-dialog"
        heading="CONFIRM SUBMIT NOW"
        open={confirmSubmitDialogOpen}
        closeDialog={() => setConfirmSubmitDialogOpen(false)}
        negativeAction={() => setConfirmSubmitDialogOpen(false)}
        positiveAction={() => {
          setConfirmSubmitDialogOpen(false);
          submitApprovedToDoj();
        }}
        positiveText="SUBMIT NOW"
        negativeText="Cancel"
      >
        <div className="global-config__confirm-submission-dialog-body">
          Are you sure you&#39;d like to submit all approved records to the Cal DoJ? Once submitted the records cannot be recalled.
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        className="global-config__confirm-schedule-dialog"
        heading="CONFIRM DOJ SUBMISSION SCHEDULE"
        open={confirmScheduleDialogOpen}
        closeDialog={() => setConfirmScheduleDialogOpen(false)}
        negativeAction={() => setConfirmScheduleDialogOpen(false)}
        positiveAction={() => uploadOrganization('jobSchedule')}
        positiveText="CONFIRM SCHEDULE"
        negativeText="Cancel"
        data-testid="global-config-confirm-schedule-dialog"
      >
        <div className="global-config__confirm-schedule-dialog-body">
          Are you sure you&apos;d like to submit job schedule?
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        className="global-config__confirm-sftp-settings-dialog"
        heading="CONFIRM SFTP SETTINGS"
        open={confirmSftpSettingsDialogOpen}
        closeDialog={() => setConfirmSftpSettingsDialogOpen(false)}
        negativeAction={() => setConfirmSftpSettingsDialogOpen(false)}
        positiveAction={() => uploadOrganization('sftp')}
        positiveText="CONFIRM SETTINGS"
        negativeText="Cancel"
        data-testid="global-config-confirm-sftp-settings-dialog"
      >
        <div className="global-config__confirm-sftp-settings-dialog-body">
          Are you sure you&apos;d like to submit SFTP settings?
        </div>
      </ConfirmDialog>
    </Header>
  );
};

GlobalConfiguration.propTypes = {
  organization: PropTypes.object,
  orgCanSubmitToDOJ: PropTypes.bool,
  getOrganization: PropTypes.func,
  setOrganization: PropTypes.func,
  uploadOrganization: PropTypes.func,
  confirmSubmitDialogOpen: PropTypes.bool,
  setConfirmSubmitDialogOpen: PropTypes.func,
  confirmSftpSettingsDialogOpen: PropTypes.bool,
  setConfirmSftpSettingsDialogOpen: PropTypes.func,
  confirmScheduleDialogOpen: PropTypes.bool,
  setConfirmScheduleDialogOpen: PropTypes.func,
  submitApprovedToDoj: PropTypes.func,
};

const mapDispatchToProps = {
  getOrganization: GlobalConfigDuck.getOrganization,
  setOrganization: GlobalConfigDuck.setOrganization,
  uploadOrganization: GlobalConfigDuck.uploadOrganization,
  setConfirmSubmitDialogOpen: GlobalConfigDuck.setConfirmSubmitDialogOpen,
  setConfirmSftpSettingsDialogOpen: GlobalConfigDuck.setConfirmSftpSettingsDialogOpen,
  setConfirmScheduleDialogOpen: GlobalConfigDuck.setConfirmScheduleDialogOpen,
  submitApprovedToDoj: GlobalConfigDuck.submitApprovedToDoj
};

const mapStateToProps = (state: any) => ({
  organization: GlobalConfigDuck.selectors.organization(state),
  orgCanSubmitToDOJ: User.selectors.canSubmitToDOJ(state),
  confirmSubmitDialogOpen: GlobalConfigDuck.selectors.confirmSubmitDialogOpen(state),
  confirmSftpSettingsDialogOpen: GlobalConfigDuck.selectors.confirmSftpSettingsDialogOpen(state),
  confirmScheduleDialogOpen: GlobalConfigDuck.selectors.confirmScheduleDialogOpen(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(GlobalConfiguration);
