import React, { useEffect } from 'react';
import cn from 'classnames';
import { onEnter } from '@utility/keypressHelpers';
import { FormControl, MenuItem, Select, InputLabel, TextField } from '@material-ui/core';
import { AssignmentEnums } from '@engine/ducks/constants';
import { connector, Props } from './props';
import './RipaTypeOfAssignmentForm.scss';

const RipaTypeOfAssignmentForm = ({ typeOfAssignmentOfficer, setTypeOfAssignmentOfficer, assignment, setAssignment, officerAssignment, assignmentDescription, setAssignmentDescription, breakpoint }: Props) => {
  const officerAssignmentVal = officerAssignment?.OfficerTypeofAssignment?.possibleValues;
  const dropdownAssignmentOptions = [
    officerAssignmentVal?.Patrol.value,
    officerAssignmentVal?.Gang.value,
    officerAssignmentVal?.Compliance.value,
    officerAssignmentVal?.SpecialEvents.value,
    officerAssignmentVal?.RoadblockDUISobrietyCheckpoint.value,
    officerAssignmentVal?.Narcotics.value,
    officerAssignmentVal?.TaskForce.value,
    officerAssignmentVal?.K12.value,
    officerAssignmentVal?.Investigative.value,
  ];

  useEffect(() => {
    if (typeOfAssignmentOfficer === AssignmentEnums.onDuty) {
      if (localStorage.getItem('defaultAssignment')) {
        setAssignment(localStorage.getItem('defaultAssignment'))
      }
    } else if (typeOfAssignmentOfficer === AssignmentEnums.offDuty) {
      setAssignment(officerAssignmentVal?.OffDuty?.value);
    } else if (typeOfAssignmentOfficer === AssignmentEnums.contracted) {
      setAssignment(officerAssignmentVal?.ContractedByAnotherLEA?.value);
    } else {
      setAssignment(typeOfAssignmentOfficer);
    }
  }, []);

  const onSelectClick = (value: string) => {
    // Only update if change is necessary
    if (value !== typeOfAssignmentOfficer) {
      setTypeOfAssignmentOfficer(value);
      setAssignmentDescription('');

      if (value !== AssignmentEnums.onDuty) {
        if (value === AssignmentEnums.offDuty) {
          setAssignment(officerAssignmentVal?.OffDuty?.value);
        } else if (value === AssignmentEnums.contracted) {
          setAssignment(officerAssignmentVal?.ContractedByAnotherLEA?.value);
        } else {
          setAssignment(value);
          localStorage.setItem('defaultAssignment', value);
        }
      } else {
        localStorage.setItem('defaultAssignment', officerAssignmentVal?.Patrol.value);
        setAssignment(officerAssignmentVal?.Patrol.value)
      }
    }
  }
  const onAssignmentClick = (value: string) => {
    localStorage.setItem('defaultAssignment', value);
    setAssignment(value);
  }
  return (
    <div className="ripa-type-of-assignment-officer" data-testid="ripa-type-of-assignment-officer-form">
      <div className="ripa-type-of-assignment-officer__title">Type of assignment of Officer?</div>
      <div className="ripa-type-of-assignment-officer__select-box-container">
        <div className="ripa-type-of-assignment-officer__select-box-content">
          {Object.values(AssignmentEnums).map((typeLabel, vi) => (
            <>
              <div
                className={cn('ripa-type-of-assignment-officer__select-box', { active: typeLabel === typeOfAssignmentOfficer })}
                key={`RipaPrimaryReasonBox-${vi}}`}
                onClick={() => onSelectClick(typeLabel)}
                onKeyUp={onEnter(() => onSelectClick(typeLabel))}
                data-testid={`ripa-type-of-assignment-officer-box-${vi}`}
                role="button"
                tabIndex={0}
              >
                {typeLabel}
              </div>
              {typeLabel === typeOfAssignmentOfficer && (
                <>
                  {typeOfAssignmentOfficer === AssignmentEnums.onDuty && (
                    <FormControl
                      variant="outlined"
                      className="ripa-type-of-assignment-officer__assignment-dropdown"
                    >
                      <InputLabel>
                        Assignment Type
                      </InputLabel>
                      <Select
                        native={breakpoint === 'xs'}
                        value={assignment}
                        onChange={({ target: { value } }: any) => onAssignmentClick(value)}
                        label="assignment"
                        MenuProps={{
                          variant: 'menu',
                          getContentAnchorEl: null
                        }}
                        inputProps={{ 'data-testid': 'ripa-type-of-assignment-officer-assignment' }}
                      >
                        {dropdownAssignmentOptions.map((t, i) => {
                          const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
                          return (<CompName data-testid={`ripa-type-of-assignment-officer-assignment-option-${i}`} key={`assignment-option-${i}`} value={t}>
                            {t}
                          </CompName>);
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {((typeOfAssignmentOfficer !== AssignmentEnums.offDuty && typeOfAssignmentOfficer !== AssignmentEnums.onDuty)) && (
                    // <AssignmentDescription />
                    <div className="ripa-type-of-assignment-officer__field-container">
                      <TextField
                        variant="standard"
                        multiline
                        className="ripa-type-of-assignment-officer__field"
                        placeholder="Enter, 60 character maximum, description of assignment..."
                        onChange={({ target: { value } }) => value.length <= 60 ? setAssignmentDescription(value) : null}
                        value={assignmentDescription ?? ''}
                        data-testid="ripa-type-of-assignment-officer-assignment-description"
                        inputProps={{ maxlength: 60 }}
                      />
                      <div className="ripa-type-of-assignment-officer__field-remaining" data-testid="ripa-type-of-assignment-officer__field-remaining">
                        <b>
                          {`${60 - (assignmentDescription ?? '').length} `}
                        </b>
                        Remaining
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connector(RipaTypeOfAssignmentForm);
