import React, { useEffect } from 'react'
import cn from 'classnames'
import { onEnter } from '@utility/keypressHelpers'
import * as NewReport from '@ducks/newReport'
import { connect, ConnectedProps } from 'react-redux'
import './DrawerManager.scss'

interface Props extends PropsFromRedux {
  drawerRef?: React.LegacyRef<HTMLDivElement>;
  children?: React.ReactNode;
  bannerContent: React.ReactNode | false;
  showBanner: boolean;
  DrawerComponent?: React.ComponentType<any>;
  open?: boolean;
  contentMode: string;
  closeDrawer?: (e: any) => void;
  smallClose: boolean;
}

const DrawerManager = ({
  drawerRef,
  children,
  bannerContent,
  showBanner,
  DrawerComponent,
  open,
  contentMode,
  closeDrawer,
  smallClose,
  currentSubstep
}: Props) => {
  useEffect(() => {
    document.getElementById('drawer-manager')?.scrollTo?.(0, 0)
  }, [currentSubstep])

  return (
    <div id="drawer-manager" ref={drawerRef} className={cn('drawer-manager', { 'drawer-open': open, 'drawer-closed': !open })} data-testid="drawer-manager">
      <div
        className="drawer-manager__drawer-clickoff"
        data-testid="drawer-manager-drawer-clickoff"
        role="button"
        tabIndex={0}
        aria-label="Close drawer"
        onClick={closeDrawer}
        onKeyUp={onEnter(closeDrawer)}
      />
      <div
        className={cn('drawer-manager__drawer', { 'small-close': smallClose })}
        aria-expanded={open}
        aria-label="Contextual drawer"
        data-testid="drawer-manager-drawer"
      >
        {DrawerComponent && <DrawerComponent closeDrawer={closeDrawer} />}
      </div>
      <div
        className={cn('drawer-manager__page-content',
          {
            'full-content': contentMode === 'full',
            'box-content': contentMode === 'box',
            'has-banner': showBanner
          }
        )}
        data-testid="drawer-manager-page-content">
        <div className="drawer-manager__page-content-banner">
          {bannerContent}
        </div>
        <div className="drawer-manager__page-content-box">
          {children}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  currentSubstep: NewReport.selectors.currentSubstep(state),
})
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DrawerManager)
