import { connect, ConnectedProps } from 'react-redux';
import { Config, Form, WatchLocation, User } from '@engine/ducks';

const mapStateToProps = (state: any) => ({
  city: Form.selectors.city(state),
  block: Form.selectors.block(state),
  street: Form.selectors.street(state),
  errors: Form.selectors.stepErrors(state),
  school: Form.selectors.school(state),
  student: Form.selectors.student(state),
  stopHappenedAtPublicSchool: Form.selectors.stopHappenedAtPublicSchool(state),
  crossStreet1: Form.selectors.crossStreet1(state),
  crossStreet2: Form.selectors.crossStreet2(state),
  highway: Form.selectors.highway(state),
  exit: Form.selectors.exit(state),
  locationType: Form.selectors.locationType(state),
  watchLocation: WatchLocation.selectors.watchLocation(state),
  userState: User.selectors.state(state),
  otherLocation: Form.selectors.otherLocation(state),
  gpsAddress: Form.selectors.gpsAddress(state),
  schoolAddress: Form.selectors.schoolAddress(state),
  coordinates: Form.selectors.coordinates(state),
  stopDateTime: Form.selectors.stopDateTime(state),
  breakpoint: Config.selectors.breakpoint(state)
});

const mapDispatchToProps = {
  setSchool: Form.setFormField({ path: ['school'] }),
  setSchoolAddress: Form.setFormField({ path: ['schoolAddress'] }),
  setStopHappenedAtPublicSchool: Form.setFormField({ path: ['stopHappenedAtPublicSchool'] }),
  setCity: Form.setFormField({ path: ['city'] }),
  setCoordinates: Form.setFormField({ path: ['coordinates'] }),
  setLocationType: Form.setFormField({ path: ['locationType'] }),
  setBlock: Form.setFormField({ path: ['block'] }),
  setStreet: Form.setFormField({ path: ['street'] }),
  setCrossStreet1: Form.setFormField({ path: ['crossStreet1'] }),
  setCrossStreet2: Form.setFormField({ path: ['crossStreet2'] }),
  setHighway: Form.setFormField({ path: ['highway'] }),
  setExit: Form.setFormField({ path: ['exit'] }),
  setGpsAddress: Form.setFormField({ path: ['gpsAddress'] }),
  setOtherLocation: Form.setFormField({ path: ['otherLocation'] }),
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;
