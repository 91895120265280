import getIntersection from './getIntersection'
import ads from './addressToString'
import getAddressFromComponents from './getAddressFromComponents'

export async function coordsToAddress(
  { latitude, longitude }: { latitude: number; longitude: number; accuracy?: number},
  setLocation?: (address: string, city: string) => void,
  setLoading?: (loading: boolean) => void
) {
  try {
    const intersection = await getIntersection({ latitude, longitude }, setLoading)
    if (latitude !== 1 && longitude !== 1 && !intersection) {
      throw new Error('Intersection cannot be found with coordinates.');
    }
    if (intersection) {
      const { street1, street2, postalcode, adminCode1, adminName2, placename } = intersection;
      const city = window.cities?.find(c => c.City.localeCompare(placename, 'en', { sensitivity: 'base' }) === 0) ? placename : adminName2
      const addressComponents = { city, state: adminCode1, zip: postalcode, street: street2 }
      const address = `${street1}, ${ads.oneLine(addressComponents)}`

      setLocation?.(address, city)
    }
  } catch (error: any) {
    console.error('Could not get intersection:', error.message)
    if (window.google) {
      const geocoder = new google.maps.Geocoder();
      geocoder?.geocode?.({
        location: {
          lat: latitude,
          lng: longitude,
        }
      }, (results, status) => {
        if (status === 'OK' && results) {
          const addressObject = getAddressFromComponents(results[0].address_components)
          const formattedAddress = results[0].formatted_address;
          setLocation?.(ads.oneLine(addressObject) || formattedAddress, addressObject.city);
        }
      });
    }
  }
}

export function locate2024(
  setAvailable?: (status: string) => void,
  setCoordinates?: (coordinates: { latitude: number; longitude: number; accuracy: number } | undefined) => void
) {
  if (navigator?.geolocation?.getCurrentPosition) {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude, accuracy } }) => {
        setCoordinates?.({ latitude, longitude, accuracy })
        setAvailable?.('available')
      },
      error => {
        setAvailable?.('unavailable')
        console.error('Something went wrong while locating:', error)
      },
      { maximumAge: (window as any).gpsMaxAge ?? 30000, timeout: (window as any).gpsTimeout ?? 25000, enableHighAccuracy: true })
  } else {
    setAvailable?.('unavailable')
  }
}

export function locate(
  setAvailable?: (status: string) => void,
  setAccuracy?: (acc: number) => void,
  setLatitude?: (lat: number) => void,
  setLongitude?: (lon: number) => void
) {
  if (navigator?.geolocation?.getCurrentPosition) {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude, accuracy } }) => {
        setAccuracy?.(accuracy)
        setLatitude?.(latitude)
        setLongitude?.(longitude)
        setAvailable?.('available')
      },
      error => {
        setAvailable?.('unavailable')
        console.error('Something went wrong while locating:', error)
      },
      { maximumAge: (window as any).gpsMaxAge ?? 30000, timeout: (window as any).gpsTimeout ?? 10000, enableHighAccuracy: true })
  } else {
    setAvailable?.('unavailable')
  }
}

let watchId: number | null = null

export function watchLocation(
  setAvailable: (status: string) => void,
  setCoor: ({ latitude, longitude, accuracy }: GeolocationCoordinates) => void
) {
  if (navigator?.geolocation?.watchPosition) {
    if (!watchId) {
      watchId = navigator.geolocation.watchPosition(
        ({ coords }) => {
          setAvailable?.('available')
          setCoor?.(coords)
        },
        error => {
          setAvailable?.('unavailable')
          console.error('Something went wrong while locating:', error)
        },
        { maximumAge: (window as any).gpsMaxAge ?? 30000, timeout: (window as any).gpsTimeout ?? 120000, enableHighAccuracy: true })
    }
  } else {
    setAvailable?.('unavailable')
  }
}

export function clearLocationWatch() {
  if (navigator?.geolocation?.clearWatch) {
    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId)
    }
    watchId = null
  }
}
